import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { UserModel,UserOtpResponseModel } from 'src/app/dashboard/models/user.model';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  getDepartmentList() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/apis/department/getAllDepartments`
    );
  }

  getLocationList() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/apis/location/getAllLocations`
    );
  }

  constructor(private http: HttpClient) { }
  getAllUserList(){
    return this.http.get<any[]>(
      `${environment.baseUrl}/apis/users/getAllUsers`
    );
  }
  saveOrUpdateUser(user:UserModel) {
    return this.http.post<any[]>(
      `${environment.baseUrl}/apis/users/save`,
      user
    );
  }

  getUserById(id) {
    return this.http.get<UserModel>(
      `${environment.baseUrl}/apis/users/getUserAndRoleByUserId/${id}`
    );
  }

  getRoleListData(){
    return this.http.get<any>(
      `${environment.baseUrl}/apis/users/getAllRoles`
    );
  }

  changePassword(UserData){
    return this.http.post<any>(
      `${environment.baseUrl}/apis/users/changePassword`,UserData
    );
  }

  getAllChangeLog(changeLogData){
    return this.http.post<any>(
      `${environment.baseUrl}/apis/users/getAllChangeLog`,changeLogData
    );
  }

  getClientListData(){
    return this.http.get<any>(
      `${environment.baseUrl}/apis/client/getAll`
    );
  }

 
  verifyLoginOtp(userOtp){
    console.log("Service : verifyLoginOtp(otp) ",userOtp);
    return this.http.post<UserOtpResponseModel>(
      `${environment.baseUrl}/apis/users/verifyLoginOtp`,userOtp//send UserId here
    );
  }

  getAllChangeLogByClientId(changeLogData){
    return this.http.post<any>(
      `${environment.baseUrl}/apis/users/getChangeLogByClientId`,changeLogData
    );
  }


}
