import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { FormsyModule } from "../formsy/formsy.module";
import { UiModule } from "../ui/ui.module";
import { PickListModule } from "primeng/picklist";
import { FormsModule }   from '@angular/forms';
import { DataTransferHeaderComponent } from "./data-transfer-header/data-transfer-header.component";
import { DataTransferFormComponent } from "./data-transfer-form/data-transfer-form.component";
import { MultiSelectModule } from "primeng";
import { DataTransferListComponent } from "./data-transfer-list/data-transfer-list.component";

@NgModule({
    declarations: [
      DataTransferFormComponent,DataTransferHeaderComponent,DataTransferListComponent


    ],
    imports: [SharedModule, FormsyModule, UiModule, PickListModule,FormsModule,MultiSelectModule],
    exports: [
      DataTransferFormComponent,DataTransferHeaderComponent,DataTransferListComponent
    ],
  })
  export class DataTransferModule {}
