import { Component, OnInit ,ViewChild} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { differenceWith, isEqual } from "lodash";
import { FormsModel } from "src/app/models/forms.model";
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from "rxjs";
import { RoleService } from '../../services/role.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { UserService } from 'src/app/user/services/user.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { Console } from 'console';
import { DualListComponent} from 'src/app/roles-dual/component/dual-list/dual-list.component'
import { ActionDataModel } from '../../models/action.model';
import { Store } from '@ngrx/store';
import { storePermission, storeUser } from 'src/app/user/store/auth.action';
import { take } from 'rxjs/operators';
import { RoleMenuType } from '../role-menu-permission/role-menu'; // Pradeep Yadav
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { RoleParameterComponent } from 'src/app/roles-dual/component/role-parameter/role-parameter.component';
import { ClientPortalService } from '../../services/client-portal.service';
import { Checkbox } from 'primeng';
import { CheckboxComponent } from 'src/app/formsy/components/checkbox/checkbox.component';
import { SeverityService } from '../../services/severity.service';
import { ShockMasterService } from '../../services/shockMaster.service';

@Component({
  selector: 'app-severity',
  templateUrl: './severity.component.html',
  styleUrls: ['./severity.component.scss']
})
export class SeverityComponent implements OnInit {
  @ViewChild(DualListComponent, {read: false, static: false })
  dualListComponent:DualListComponent;
  @ViewChild(RoleParameterComponent, {read: false, static: false })
  roleParameterComponent:RoleParameterComponent;

  @ViewChild("pannelForm", {static: false})
  pannelForm!:SidePanelComponent;

  shockList: {key: any; value: any}[]=[];
  roles: { key: string | number; value: string }[];
  rolesCtrl = new FormControl(null);
  sourceData: { key: string | number; value: string }[];
  targetData: { key: string | number; value: string }[];
  
  stressKeyMetrics:{stressKeyMetricsId: number; status: boolean; colName:string}[]=[];
  stressSeverity:{stressSeverityId: number; status: boolean; colName:string}[]=[];   
    

  masterSourceData: Object;
  allActionData:ActionDataModel;
  roleName:string=null;
  roleLabel:string="Select Role";
  activeRoute$: Subscription;
  addRolePopup: boolean = false;
  actionList:any=[];
  clientLists:any=[];
  applicationLists:any=[];
  clientApplicationDataTypeList:any=[];
  roleId:any;
  rolePermission:any;
  dataValue:any;
  user:any;
  stressShocksList: any[];
  shockForm: FormGroup = null;
  shockSelect:string;
  shockSelectKey:any;
  colName:string;
  lable:string;
  menu:string;
  submenu:string;
  activeRoles:{roleId:any;roleName:any}[]=[];
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  newRoleModel: FormsModel = {
    controls: [
      {
        name: "roleName",
        type: "text",
        validators: [Validators.required],
        label: "Role Name",
      },
      {
        name: "roleDescription",
        type: "text",
        validators: [],
        label: "Role Description",
      },
      
    ],
  };
  roleForm: FormGroup;
  parameterForm:FormGroup;
  roleMenuType: RoleMenuType[]; // Pradeep Yadav
  state:string;
  tableData: any[];
  tableData1: any[];
  stressKeyMetricsStatus:number=0;
  stressKeyMetricsId:number=null;
  stressSevereStatus:number=0;
  stressSevereId:number=null;
  constructor(private http: HttpClient,
    private activeRoute: ActivatedRoute, private toastService:ToastService,
    private titleService: TitleService,private ShockMasterService:ShockMasterService,
    private toast: ToastService,
    private SeverityService: SeverityService,
    private loader:LoaderService,
    private store: Store<{ user: any }>,
    private router: Router) {
      this.shockForm = new FormGroup({
        stressShocksList: new FormControl(null, [Validators.required])
      });

      this.titleService.setTitle("Key Metrics & Stress Severity");

      this.parameterForm = new FormGroup({
        stressSeverityId:new FormControl(null),
        status:new FormControl(null),
       });
       this.menu=localStorage.getItem("menu");
       this.submenu=localStorage.getItem("submenu");
       this.state=localStorage.getItem("stateflag");
     }

  ngOnInit(): void {
    // this.getAllStressSevere();
    // this.getAllKeyStressSevere();
    this.getAllShockList();

    // this.shockForm.get("shockSelect").setValue({
    //   key: this.shockSelect,
    //   value: this.shockSelectKey
    // });
  //  this.buttonShowHidePermission("init");
 // this.callNgOnInitMethods();  
  //Start- Pradeep Yadav
   // this.getRolePageMenuData();
    //End- Pradeep Yadav
  }

  getAllStressSevere() {
    this.SeverityService.getAllStressSevere().subscribe(
      (data) => {
       // console.log("Table Data size: " + data.length);
       // console.log("table Data",data)
        this.tableData = data;
        for(var i=0;i<this.tableData.length;i++){
          this.stressSeverity.push({stressSeverityId: this.tableData[i].stressSeverityId,status: this.tableData[i].status,colName:this.tableData[i].colName})
          }
        console.log("tableData getAllStressSevere()",this.tableData);
        console.log("stresskeyMetricsList getAllStressSevere()",this.stressSeverity);
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
  }

  getAllKeyStressSevere() {
    this.SeverityService.getAllKeyStressSevere().subscribe(
      (data) => {
        console.log("Table Data size: " + data.length);
        console.log("table Data",data)
        this.tableData1 = data;
        for(var i=0;i<this.tableData1.length;i++){
          this.stressKeyMetrics.push({stressKeyMetricsId:this.tableData1[i].stressKeyMetricsId,status:this.tableData1[i].status,colName:this.tableData1[i].colName})
          }
        console.log("tableData1  getAllKeyStressSevere()",this.tableData1);
        console.log("stressSeverityList getAllKeyStressSevere()",this.stressKeyMetrics);
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
  }
 
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );


 
  }

  saveData(){
    let stresskeyrequired=true;
    let stressSeverityrequired = true;

    for(var i=0;i<this.stressKeyMetrics.length;i++){
      if(this.stressKeyMetrics[i].status==true){
        stresskeyrequired = false;
      }
      }
      for(var i=0;i<this.stressSeverity.length;i++){
        if(this.stressSeverity[i].status==true){
          stressSeverityrequired = false;
        }
        }

    if(stresskeyrequired){
      this.toast.showWarn("Al least one value must be true from Key Metrics ", "Info");
      
    }
    if(stressSeverityrequired){
      this.toast.showWarn("Al least one value must be true from Stress Severity ", "Info");
      
    }
    
    if(stresskeyrequired || stressSeverityrequired){
      return;
    }
    console.log("status On saveData",this.stressKeyMetrics);     
    const stressKeyMetricsSeverity={
      stressKeyMetricsSeverityList:this.stressKeyMetrics,      
      stressSeverityList: this.stressSeverity,
      lable:localStorage.getItem("submenu")
     
    }
 
     
     this.SeverityService.updateStressSeverityStatus(stressKeyMetricsSeverity).subscribe(

      (data) => {
        console.log("Table Data size: " + data.length);
        console.log("table Data",data)
        console.log("tableData1",this.tableData1);
        this.toast.showSuccess("Key Metrics & Stress Severity Saved Successfully", "success");       
        // this.getAllKeyStressSevere();
        // this.getAllStressSevere();
        this.getStressKeyMetricsData();
        this.getStressSeverityData();
      },
        err=>{
         this.toast.showError(err.error.message, "error");
        }
     );

    


  }

  


  setStatusForData(event,rowData){
    let status;
    console.log("event",event);
    console.log("rowData",rowData);
    this.stressKeyMetricsId=rowData.stressKeyMetricsId;
    
    if(event.target.checked){
      console.log("True");
      console.log("stressKeyMetricsId",this.stressKeyMetricsId)
      this.stressKeyMetricsStatus=1;
    }
    else{
      console.log("False");
      console.log("stressKeyMetricsId",this.stressKeyMetricsId)
      this.stressKeyMetricsStatus=0;
    }
    if(this.stressKeyMetrics != null){
      for(var i=0;i<this.stressKeyMetrics.length;i++){
        if(this.stressKeyMetrics[i].stressKeyMetricsId===rowData.stressKeyMetricsId){
          this.stressKeyMetrics[i].status=event.target.checked;
        }
        }
    }else{
      // this.stressKeyMetrics.push({stressKeyMetricsId:rowData.stressKeyMetricsId,status:event.target.checked,colName:rowData.colName});
    }

    console.log("this.stressKeyMetricsStatus",this.stressKeyMetrics);


   }

   setStatusForSevereData(event,rowData){
    console.log("rowData",rowData);
    this.stressSevereId=rowData.stressSeverityId;
    this.stressSevereStatus;
 
    if(event.target.checked){
      console.log("True");
      console.log("stressSevereId",this.stressSevereId)
      this.stressSevereStatus=1;
    }
    else{
      console.log("False");
      console.log("stressSevereId",this.stressSevereId)
      this.stressSevereStatus=0;
    }
    if(this.stressSeverity != null){
      for(var i=0;i<this.stressSeverity.length;i++){
        if(this.stressSeverity[i].stressSeverityId===rowData.stressSeverityId){
          this.stressSeverity[i].status=event.target.checked;
        }
        }
    }else{
      // this.stressSeverity.push({stressSeverityId:rowData.stressSeverityId,status:event.target.checked,colName:rowData.colName})
    }
    console.log("this.stressSeverity",this.stressSeverity);
   }
   
  // callNgOnInitMethods(){  
  //   console.log("this.stressKeyMetricsStatus",this.stressKeyMetrics);
  // } 
  getAllShockList(){
    
    this.ShockMasterService.getStressShockData().subscribe((data)=>{
      this.stressShocksList = data.filter(e =>e.filterFlag==true);
        
      for(var i=0;i<this.stressShocksList.length;i++){
          console.log("stressShocksList  ",this.stressShocksList[i].shockName)
          this.shockSelectKey=this.stressShocksList[0].colName
          this.shockSelect=this.stressShocksList[0].shockName
        }
        console.log("data of riskFactor",this.stressShocksList)
        this.shockForm.get("stressShocksList").setValue({
          key: this.shockSelectKey,
          value: this.shockSelect
        });

      // this.stressShocksList=data;
     
      this.stressShocksList = reMapForAutoComplete(this.stressShocksList, "colName", "shockName");
     console.log("this.stressShocksList",this.stressShocksList);
      this.getStressKeyMetricsData();
      this.getStressSeverityData();
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }
  getStressKeyMetricsData(){
    if (this.shockForm.valid) {
      this.shockSelect = this.shockForm.controls.stressShocksList.value.value;
      this.shockSelectKey = this.shockForm.controls.stressShocksList.value.key;
    this.SeverityService.getStressKeyMetricsDataByShocks(this.shockSelectKey).subscribe(
      data => {
        this.tableData1 = data;
        for(var i=0;i<this.tableData1.length;i++){
          this.stressKeyMetrics.push({stressKeyMetricsId:this.tableData1[i].stressKeyMetricsId,status:this.tableData1[i].status,colName:this.tableData1[i].colName})
          }
        console.log("getStressKeyMetricsData: " + data);
        // this.toast.showSuccess(this.shockSelectKey, "success");      
        // this.getAllKeyStressSevere();
        // this.getAllStressSevere();
      },
      err => {
        this.toastService.showError("failed to get shocks", "error");
      }
    );
  }
}

  getStressSeverityData(){
    if (this.shockForm.valid) {
      this.shockSelect = this.shockForm.controls.stressShocksList.value.value;
      this.shockSelectKey = this.shockForm.controls.stressShocksList.value.key;
    this.SeverityService.getStressSeverityDataByShocks(this.shockSelectKey).subscribe(
      data => {
        this.tableData = data;
        for(var i=0;i<this.tableData.length;i++){
          this.stressSeverity.push({stressSeverityId: this.tableData[i].stressSeverityId,status: this.tableData[i].status,colName:this.tableData[i].colName})
          }
        console.log("getStressSeverityData: " + data);
        // this.toast.showSuccess(this.shockSelectKey, "success");      
        // this.getAllKeyStressSevere();
        // this.getAllStressSevere();
      },
      err => {
        this.toastService.showError("failed to get shocks", "error");
      }
    );
  }

  }
  getByRiskFactor(){
    this.stressKeyMetrics.length=0;
    this.stressSeverity.length=0;
    console.log("riskFactorChange")
  }

  onLoad() {
    console.log("load work:");
   this.pannelForm.toggleSlider();

   this.getStressKeyMetricsData();
   this.getStressSeverityData();
  //  this.formReset();
  this.toast.showSuccess("Changes Saved", "Successfully");

    // if (this.shockForm.valid) {
    //   this.shockSelect = this.shockForm.controls.stressShocksList.value.value;
    //   this.shockSelectKey = this.shockForm.controls.stressShocksList.value.key;
    //   this.SeverityService.getFilterSeverityByShocksId(this.shockSelectKey).subscribe(
    //     data => {
    //       this.shockList = data;
    //       console.log("getFilterSeverityByShocksId: " + data);
    //       this.toast.showSuccess(this.shockSelectKey, "success");      
    //       // this.getAllKeyStressSevere();
    //       // this.getAllStressSevere();
    //     },
    //     err => {
    //       this.toastService.showError("failed to get shocks", "error");
    //     }
    //   );
    // }
  
 
  } 
  formReset(){
    this.shockForm.reset();
   
  }
  
}

