import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { GlHeadSelectorComponent } from '../glHead-selector/glHead-selector.component';

@Component({
  selector: 'app-glHead-header',
  templateUrl: './glHead-header.component.html',
  styleUrls: ['./glHead-header.component.scss']
})
export class GlHeadHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(GlHeadSelectorComponent, { read: false, static: false })
  GlHeadSelectorComponent: GlHeadSelectorComponent;

  
  @Input() editData;
  @Output() save=new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }


  saveGlHead(data){
this.save.emit(data);

  }

 

   addNewGlHead(){

     this.GlHeadSelectorComponent.formReset();
     this.panelForm.toggleSlider();

    }

    reset(){

     this.GlHeadSelectorComponent.formReset();
     //this.panelForm.toggleSlider();
    }

 openEdit(){
  this.panelForm.toggleSlider();
 }

}
