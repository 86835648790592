import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { CategoryModel } from "../models/category.model";
import { RwaPercentModel } from "../models/rwaPercent.model";
import { ShockMasterModel } from "../models/shockMaster.model";

@Injectable({
    providedIn: 'root'
  })
export class ShockMasterService {
  
  
  /**
   * @param  {HttpShockMaster} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  saveOrUpdate(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/stressTesting/createShock",data);
} 


getAllShockList(){
  return this.http.get<any[]>(environment.baseUrl + "/apis/getAllShock");
}

getStressShockData(){
  return this.http.get<any>(environment.baseUrl + "/apis/getAllStressShocksData");
}


deleteShockMasterId(data) {
  return this.http.post<any>(`${environment.baseUrl}/apis/deleteShocks/`,data);
  }

  getApplicationDataforRisk() {
   
      return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getApplicationDataforRisk");
    
  }

  getShockDetailsByShockCode(code){
    return this.http.get<any>(environment.baseUrl + "/apis/stressTesting/getShockDetails/"+code);
  }

  saveShockDetails(data){
    return this.http.post<any>(`${environment.baseUrl}/apis/stressTesting/saveShockDetails`,data);
  }
  
  getAllMultifactorCombination(data){
    return this.http.get<any[]>(environment.baseUrl + "/apis/getAllMultifactorCombination/"+data);
  }

  saveMultifactorCombination(data){
    return this.http.post<any>(`${environment.baseUrl}/apis/saveMultifactorCombinationList`,data);
  }

  deleteMultifactorCombination(id) {
    return this.http.delete<any>(environment.baseUrl + "/apis/deleteMultifactorCombinationList/"+id);
    
  }

  generateMultifactorReport(data){
    return this.http.post<any>(`${environment.baseUrl}/apis/multifactorOutput`,data);
  }

  downloadFileFormat(fileName){
    return this.http.get<any>(environment.baseUrl+"/apis/stressTesting/downloadFileFormat/"+fileName);
  }

  saveUpdateConfigureFilters(data){
    return this.http.post<any>(`${environment.baseUrl}/apis/saveConfigurFilters`,data);
  }

  getConfigureFilterData(){
    return this.http.get<any>(environment.baseUrl + "/apis/getAllConfigureFilters");
  }

  getBranchIndustryCreditRatingList(){
    return this.http.get<any>(environment.baseUrl + "/apis/selectFiltersList");
  }

  saveUpdateFiltersforClient(data){
    return this.http.post<any>(`${environment.baseUrl}/apis/stressTesting/saveFiltersinWorkflowParam`,data);
  }

  getReportAnalysis(data){
    return this.http.post<any>(environment.baseUrl + "/apis/reportAnalysis",data);
  }

}

