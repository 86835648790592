import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { RequestTableComponent } from './components/request-table/request-table.component';
import { RequestHeaderComponent } from './components/request-header/request-header.component';
import { RequestSelectorComponent } from './components/request-selector/request-selector.component';
import { RequestLogsTableComponent } from './components/request-logs-table/request-logs-table.component';




@NgModule({
  declarations: [
    RequestHeaderComponent,
    RequestSelectorComponent,
    RequestTableComponent,
    RequestLogsTableComponent
    
  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    RequestHeaderComponent,
    RequestSelectorComponent,
    RequestTableComponent,
    RequestLogsTableComponent
  ]
})
export class RequestCompModule { }
