import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-client-table',
  templateUrl: './client-table.component.html',
  styleUrls: ['./client-table.component.scss']
})
export class ClientTableComponent implements OnInit {
 @Input() clientList:any[];
 @Input() rolePermission:any;
 @Output() edit = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    //console.log("clientList on table: ",this.clientList);
  }

  editClient(rowData){
  this.edit.emit(rowData);
  }

}
