import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng';
import { take } from 'rxjs/operators';
import { MOCK_MENU } from 'src/app/constants/mock.data';
import { MenuService } from 'src/app/ui/services/menu.service';


@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent implements OnInit {
 // menuItems = MOCK_MENU;
  expandedIndex = -1;
  menuItems: MenuItem[];
  counter1: any;
  user: any;
  constructor(private menuService: MenuService, private store: Store<{ user: any }>) {}

  ngOnInit(): void {
      this.store.select('user').pipe(take(1))
      .subscribe(u => this.user = u)
    
   //   if(this.user.clientId===0 || this.user.clientId===null){
    //   this.menuService.getMenuItems().subscribe(
    //   data => (this.menuItems = data),
    //   err => console.log("error", err)
    // );
  }

  setActiveClass(event: Event, link: string | undefined, index: number) {
    if (!link) {
      const ele = event.target as HTMLAnchorElement;
      ele.classList.toggle('active');
      if (ele.classList.contains('active')) {
        this.expandedIndex = index;
      } else {
        this.expandedIndex = -1;
      }
    }
  }
}
