import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextBoxComponent),
      multi: true,
    },
  ],
})
export class TextBoxComponent {
  @Input() icon!: string;
  @Input() type: string = 'text';
  @Input() placeholder!: string;
  @Input() ctrl: FormControl;
  @Input() label!:string;
  onChange: any = () => {};
  onTouched: any = () => {};
  value = '';
  disabled = false;

  updateValue(event: Event) {
    const ele = event.target as HTMLInputElement;
    this.value = ele.value;
    this.onChange(this.value);
  }

  writeValue(value: any) {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn; // <-- save the function
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn; // <-- save the function
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
