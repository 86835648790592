import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { ReportHeadSelectorComponent } from './components/reportHead-selector/reportHead-selector.component';
import { ReportHeadHeaderComponent } from './components/reportHead-header/reportHead-header.component';
import { ReportHeadTableComponent } from './components/reportHead-table/reportHead-table.component';



@NgModule({
  declarations: [
    ReportHeadSelectorComponent,
    ReportHeadTableComponent,
    ReportHeadHeaderComponent
    

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    ReportHeadSelectorComponent,
    ReportHeadTableComponent,
    ReportHeadHeaderComponent
    ]
})
export class ReportHeadCompModule { }
