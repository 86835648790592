import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlMappingSelectorComponent } from './gl-mapping-selector/gl-mapping-selector.component';
import { GlMappingTableComponent } from './gl-mapping-table/gl-mapping-table.component';
import { UiModule } from 'src/app/ui/ui.module';
import { SharedModule } from 'src/app/shared/shared.module'; 
import { MultiSelectModule } from 'primeng';



@NgModule({
  declarations: [
    GlMappingSelectorComponent,
    GlMappingTableComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    UiModule,
    MultiSelectModule
  ],
  exports: [
    GlMappingSelectorComponent,
    GlMappingTableComponent
    ]
})
export class GlMappingCompModule { }
