import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientModel } from '../../models/client.model';
import { Binary } from '@angular/compiler';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { map } from 'rxjs/operators';
import { LayoutFilesModel } from '../../models/files.model';
import { getCurrentDate,formatDate } from "src/app/utils/dates";
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { DatePipe } from '@angular/common';
import { TransformPermission } from '../../models/transformPermission.model';
import { Store } from '@ngrx/store';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { ClientSelectorComponent } from 'src/app/clientComp/components/client-selector/client-selector.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { CategoryHeaderComponent } from 'src/app/categoryComp/components/category-header/category-header.component';
import { CategoryService } from '../../services/category.service';
import { CategoryModel } from '../../models/category.model';
import { VideoCategoryService } from '../../services/videocategory.service';
import { VideoCategoryHeaderComponent } from 'src/app/videoCategoryComp/components/videocategory-header/videocategory-header.component';
import { RwaPercentHeaderComponent } from 'src/app/rwaPercentComp/components/rwapercent-header/rwapercent-header.component';
import { RwaPercentService } from '../../services/rwaPercent.service';
import { RwaPercentModel } from '../../models/rwaPercent.model';

@Component({
  selector: 'app-rwapercent',
  templateUrl: './rwapercent.component.html',
  styleUrls: ['./rwapercent.component.scss']
})
export class RwapercentComponent implements OnInit {

  @ViewChild(RwaPercentHeaderComponent, { read: false, static: false })
  RwaPercentHeaderComponent: RwaPercentHeaderComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;
// @ViewChild('fromCal')
// fromCalVariable: ElementRef;
  rwaPercentList:RwaPercentModel[];
  editData:RwaPercentModel[];
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  state:string;
  
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService,private fileService:FileService,private toast:ToastService,
    private router: Router,private RwaPercentService:RwaPercentService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
     
    this.titleService.setTitle("Risk Factors");
    this. searchByCols = [
     
       { field: "provision", header: "Provision%	" },
       { field: "rwaAsPerProvision", header: "RWA % As Per Provision	" },
      
    ];

    this.state=localStorage.getItem("stateflag");
    }
  ngOnInit(): void {
   //this.buttonShowHidePermission();
this.getAllRwaPercentList();
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllRwaPercentList(){
    
    this.RwaPercentService.getAllRwaPercentList().subscribe((data)=>{
      this.rwaPercentList=data;
  
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }
  
  

  saveData(rwaForm){
    let RwaAsPerProvision=rwaForm.rwaAsPerProvision
    const model={
      stressRiskFactorId:rwaForm.stressRiskFactorId!=null?rwaForm.stressRiskFactorId:0,
      provision:rwaForm.provision,
      rwaAsPerProvision:Number(RwaAsPerProvision),
      validFrom:rwaForm.validFrom,
      validTo:rwaForm.validTo
    }
    
   
   this.RwaPercentService.saveOrUpdate(model).subscribe(
     (data) => {
  
    this.toast.showSuccess("RWA Saved Successfully", "success");
    this.RwaPercentHeaderComponent.reset();
    this.getAllRwaPercentList();
   
  
   
  },
  (err) => {
  
    this.toast.showError(err.error.message, "error");
  }

);
   
 
}


editRwaPercent(rowData){
    this.editData=rowData;
    this.RwaPercentHeaderComponent.openEdit();
    
  }


  deleteRWaPercentId(data) {
      //   const genericModel ={
      //    "videoCategoryId": data.videoCategoryId
      //  }
    
        //this.loader.showLoader();
        this.RwaPercentService.deleteRwaPercentId(data.stressRiskFactorId).subscribe(
          data => {
              this.toast.showSuccess('Success', 'RWA Per Provision deleted successfully');
              this.getAllRwaPercentList();
            });
      }

}
