import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { SchedulerLogModel } from 'src/app/dashboard/models/schedulerLog.model';

@Component({
  selector: 'app-scheduler-list-table',
  templateUrl: './scheduler-list-table.component.html',
  styleUrls: ['./scheduler-list-table.component.scss']
})
export class SchedulerListTableComponent implements OnInit {

  @Input() schedulerList;
  @Input() data:SchedulerLogModel[] = [];
  @Output() edit = new EventEmitter();
  @Output() view = new EventEmitter();
  @Output() setData = new EventEmitter();
  @Output() deleteData = new EventEmitter();
  @Output() deleteNotificationData = new EventEmitter();
  @Input() permission:any;
  @Output() logData = new EventEmitter();
  selectedIndex = -1;


  // schedulerList= [{'schedulerName': 'daily Schedule', 'schedulerType': 'Recurring','schedularTime': '2.30PM','createdAt':'10/8/2021','modifiedAt':''}];
  constructor(private titleService: TitleService) { }

  ngOnInit(): void {

    console.log("schedulerList  ", this.schedulerList);

  }


  editSchedular(data: any) {
    this.edit.emit(data);
  }

  viewScheduler(data: any) {
    this.view.emit(data);
  }


  SchedulerStatus(data: any) {
    console.log("dataforset  ", data)

    this.setData.emit(data);


  }


  DeleteScheduler(data) {

    this.deleteData.emit(data);
    
  }
  DeleteNotificationScheduler(notificationData){
    this.deleteNotificationData.emit(notificationData);

  }

  getLog(index: number, event: Event,data:any){
    const ele = event.target as HTMLInputElement;
    if (ele.checked) {
      this.selectedIndex = index;
    this.logData.emit(data);
  } else {
    this.selectedIndex = -1;
  }
}


}
