import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolelistTableComponent }  from './components/rolelist-table/rolelist-table.component';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { TableViewerListComponent } from './components/table-viewer-list/table-viewer-list.component';
import { TableViewerParameterComponent } from './components/table-viewer-parameter/table-viewer-parameter.component';
import { MultiSelectModule } from 'primeng';





@NgModule({
  declarations: [
    RolelistTableComponent, 
    TableViewerListComponent,
    TableViewerParameterComponent
    
  
  ],
  imports: [SharedModule,UiModule,MultiSelectModule],
  exports: [
    RolelistTableComponent, 
    TableViewerListComponent,
    TableViewerParameterComponent
    
  ],
  providers: []
  })
export class RolelistCompModule { }
