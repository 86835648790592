import { Component, OnInit, Input, SimpleChanges, Output,EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsModel } from 'src/app/models/forms.model';
import {
  LOOKUP_FIELDS,
  extraFields,
  uniqueFields
  
} from "../../model/lookup-fields";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ToastService } from 'src/app/core/services/toast.service';



@Component({
  selector: 'app-lookup-selector',
  templateUrl: './lookup-selector.component.html',
  styleUrls: ['./lookup-selector.component.scss']
})
export class LookupSelectorComponent implements OnInit {

  //@Input() rules: RuleTypesModel[] = [];
  @Input() editLookUp;
  @Input() newLookUp;
  @Input() tableName;
  @Input() columnDropDown;
  @Input() columnDropDown2;
  @Output() saveLookUp = new EventEmitter();
  @Input() permission:any;
  @Output() closePanel=new EventEmitter();
  enableEdit: boolean;
  lookUpForm: FormGroup = null;
  extraRulesForm: FormGroup = null;
  extraFormModel: FormsModel = null;
  extraFormFields: any[];
  
  constructor( private toast: ToastService,) { 
    this.lookUpForm = new FormGroup({
      code: new FormControl(null,{validators:[Validators.required]}),
      description: new FormControl(null,{validators:[Validators.required,Validators.minLength(5),Validators.maxLength(100)]})
    });
  }

  
  ngOnInit(): void {
    this.extraFormFields = extraFields();
    console.log(this.editLookUp,' child')
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.tableName && changes.tableName.currentValue) {
      console.log(changes.tableName.currentValue,' indise table changes') 
       // const lookUp=changes.editLookUp.currentValue;
       this.extraFormModel=null;
       this.lookUpForm.reset();
        this.checkType(changes.tableName.currentValue);
       // this.enableEdit = true;
       // this.checkForEdit() 
     }
   
    if (changes && changes.editLookUp && changes.editLookUp.currentValue) {
     console.log(changes.editLookUp.currentValue,' indise changes') 
       const lookUp=changes.editLookUp.currentValue;
       this.checkType(lookUp.tableName);
       this.enableEdit = true;
       this.checkForEdit() 
    }
    // if (changes && changes.newRule && changes.newRule.currentValue > 1) {
    //   this.resetForm();
    // }
  }

  checkForEdit() {
    if (this.editLookUp && this.enableEdit) {
     
     
      this.lookUpForm.patchValue({
        code: this.editLookUp.code,
        description: this.editLookUp.description,
       
      });
      
      this.getExtraFieldsToPatch(this.editLookUp.tableName);
     // this.enableEdit = false;
     
    }
  }

  checkType(t) {
    switch (t) {
      // compare
      case 'accrualbasis_code':
        LOOKUP_FIELDS.accrualbasis_code.controls[0].options=this.columnDropDown;
        this.extraFormModel = LOOKUP_FIELDS["accrualbasis_code"] as FormsModel;
        break;

      case 'call_report_code':
        console.log("this.columnDropDown data",this.columnDropDown);
        console.log("this.columnDropDown2 data",this.columnDropDown2);
        LOOKUP_FIELDS.call_report_code.controls[0].options=this.columnDropDown;
        LOOKUP_FIELDS.call_report_code.controls[1].options=this.columnDropDown2;
        this.extraFormModel = LOOKUP_FIELDS["call_report_code"] as FormsModel;
        break;
      case 'collateral_code':
        this.extraFormModel = LOOKUP_FIELDS["collateral_code"] as FormsModel;
        break;

      case 'line_of_credit_code':
        this.extraFormModel = LOOKUP_FIELDS["line_of_credit_code"] as FormsModel;
        break;

      case 'loan_type_code':
        this.extraFormModel = LOOKUP_FIELDS["loan_type_code"] as FormsModel;
        break;
      
      case 'non_accrual_code':
        this.extraFormModel = LOOKUP_FIELDS["non_accrual_code"] as FormsModel;
        break;
        
      case 'officer_code':
        this.extraFormModel = LOOKUP_FIELDS["officer_code"] as FormsModel;
        break;

      case 'payment_frequency_code':
        this.extraFormModel = LOOKUP_FIELDS["payment_frequency_code"] as FormsModel;
        break;

      case 'payment_type_code':
        this.extraFormModel = LOOKUP_FIELDS["payment_type_code"] as FormsModel;
        break;
      
      case 'purpose_code':
        this.extraFormModel = LOOKUP_FIELDS["purpose_code"] as FormsModel;
        break;

      case 'rate_change_frequency_code':
        LOOKUP_FIELDS.rate_change_frequency_code.controls[0].options=this.columnDropDown;
        this.extraFormModel = LOOKUP_FIELDS["rate_change_frequency_code"] as FormsModel;
        break;
      
      case 'reprice_index_code':
        LOOKUP_FIELDS.reprice_index_code.controls[1].options=this.columnDropDown;
        this.extraFormModel = LOOKUP_FIELDS["reprice_index_code"] as FormsModel;
        break;

      case 'risk_rating_code':
        this.extraFormModel = LOOKUP_FIELDS["risk_rating_code"] as FormsModel;
        break;

      case 'interest_payment_frequency_code':
        this.extraFormModel = LOOKUP_FIELDS["interest_payment_frequency_code"] as FormsModel;
        break;

        // rate_flag_code
        case 'rate_flag_code':
          this.extraFormModel = LOOKUP_FIELDS["rate_flag_code"] as FormsModel;
          break;

        // accrualbasis_borrow_code
        case 'accrualbasis_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["accrualbasis_borrow_code"] as FormsModel;
          break;

        // call_put_frequency_borrow_code
        case 'call_put_frequency_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["call_put_frequency_borrow_code"] as FormsModel;
          break;

        // call_put_index_borrow_code
        case 'call_put_index_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["call_put_index_borrow_code"] as FormsModel;
          break;

        // call_put_method_borrow_code
        case 'call_put_method_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["call_put_method_borrow_code"] as FormsModel;
          break;
          
        // call_report_borrow_code
        case 'call_report_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["call_report_borrow_code"] as FormsModel;
          break;

        // compound_frequency_borrow_code
        case 'compound_frequency_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["compound_frequency_borrow_code"] as FormsModel;
          break;

        // credit_rating_borrow_code
        case 'credit_rating_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["credit_rating_borrow_code"] as FormsModel;
          break;

        // interest_payment_frequency_borrow_code
        case 'interest_payment_frequency_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["interest_payment_frequency_borrow_code"] as FormsModel;
          break;

        // payment_frequency_borrow_code
        case 'payment_frequency_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["payment_frequency_borrow_code"] as FormsModel;
          break;

        // payment_type_borrow_code
        case 'payment_type_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["payment_type_borrow_code"] as FormsModel;
          break;

        // rate_change_frequency_borrow_code
        case 'rate_change_frequency_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["rate_change_frequency_borrow_code"] as FormsModel;
          break;

        // reprice_index_borrow_code
        case 'reprice_index_borrow_code':
          this.extraFormModel = LOOKUP_FIELDS["reprice_index_borrow_code"] as FormsModel;
          break;

          // "deposit_ownership_code",
          // "deposit_product_code",
        
        case 'deposit_ownership_code':
          this.extraFormModel = LOOKUP_FIELDS["deposit_ownership_code"] as FormsModel;
          break;

          case 'deposit_product_code':
            this.extraFormModel = LOOKUP_FIELDS["deposit_product_code"] as FormsModel;
            break;

        case 'state_code':
          LOOKUP_FIELDS.state_code.controls[0].options=this.columnDropDown;
          this.extraFormModel = LOOKUP_FIELDS["state_code"] as FormsModel;
          break;

        case 'general_ledger_code':
          this.extraFormModel = LOOKUP_FIELDS["general_ledger_code"] as FormsModel;
          break;

        case 'loan_class_code':
          this.extraFormModel = LOOKUP_FIELDS["loan_class_code"] as FormsModel;
          break;
        
        case 'credit_rating_code':
          this.extraFormModel = LOOKUP_FIELDS["credit_rating_code"] as FormsModel;
          break;
    
        case 'currency_code':
          this.extraFormModel = LOOKUP_FIELDS["currency_code"] as FormsModel;
          break;
        
        case 'country_code':
          this.extraFormModel = LOOKUP_FIELDS["country_code"] as FormsModel;
          break;      
     
    }
  }

  getForm(form: FormGroup) {
    this.extraRulesForm = form;
   
  }

  getExtraFieldsToPatch(type) {
    let data = null;
    switch (type) {
      // compare
      case 'accrualbasis_code':
        data = this.findField("accrualbasis_code");
        break;
      // format
      case 'call_report_code':
        data = this.findField("call_report_code");
        break;
      // manual intervention
      case 'collateral_code':
        data = this.findField("collateral_code");
        break;
      case 'line_of_credit_code':
        data = this.findField("line_of_credit_code");
        break;
      case 'loan_type_code':
        data = this.findField("loan_type_code");
        break;
      case 'non_accrual_code':
        data = this.findField("non_accrual_code");
        break;
      case 'officer_code':
        data = this.findField("officer_code");
        break;
      case 'payment_frequency_code':
        data = this.findField("payment_frequency_code");
        break;
      case 'payment_type_code':
        data = this.findField("payment_type_code");
        break;
      case 'purpose_code':
        data = this.findField("purpose_code");
        break;
      case 'rate_change_frequency_code':
        data = this.findField("rate_change_frequency_code");
        break;
      case 'reprice_index_code':
        data = this.findField("reprice_index_code");
        break;
      case 'risk_rating_code':
        data = this.findField("risk_rating_code");
        break;
      case 'interest_payment_frequency_code':
        data = this.findField("interest_payment_frequency_code");
        break;
//NEWTABLES
      case 'rate_flag_code':
        data = this.findField("rate_flag_code");
        break;

      case 'accrualbasis_borrow_code':
        data = this.findField("accrualbasis_borrow_code");
        break;

      case 'call_put_frequency_borrow_code':
        data = this.findField("call_put_frequency_borrow_code");
        break;

      case 'call_put_index_borrow_code':
        data = this.findField("call_put_index_borrow_code");
        break;

      case 'call_put_method_borrow_code':
        data = this.findField("call_put_method_borrow_code");
        break;

      case 'call_report_borrow_code':
        data = this.findField("call_report_borrow_code");
        break;

      case 'compound_frequency_borrow_code':
        data = this.findField("compound_frequency_borrow_code");
        break;

      case 'credit_rating_borrow_code':
        data = this.findField("credit_rating_borrow_code");
        break;

      case 'interest_payment_frequency_borrow_code':
        data = this.findField("interest_payment_frequency_borrow_code");
        break;

      case 'payment_frequency_borrow_code':
        data = this.findField("payment_frequency_borrow_code");
        break;

      case 'payment_type_borrow_code':
        data = this.findField("payment_type_borrow_code");
        break;

      case 'rate_change_frequency_borrow_code':
        data = this.findField("rate_change_frequency_borrow_code");
        break;

      case 'reprice_index_borrow_code':
        data = this.findField("reprice_index_borrow_code");
        break;

 //NEW DEPOSIT TABLES
 
      case 'deposit_ownership_code':
        data = this.findField("deposit_ownership_code");
        break;

      case 'deposit_product_code':
        data = this.findField("deposit_product_code");
        break;

      case 'state_code':
        data = this.findField("state_code");
        break;

      case 'general_ledger_code':
        data = this.findField("general_ledger_code");
        break;

      case 'loan_class_code':
        data = this.findField("loan_class_code");
        break;
      
      case 'credit_rating_code':
        data = this.findField("credit_rating_code");
        break;
      
      case 'country_code':
        data = this.findField("country_code");
        break;  

      case 'currency_code':
        data = this.findField("currency_code");
        break;
    
    }
    console.log("check1 ",data);
    console.log("type ",type);

     if(type!=='branch_code'){
      console.log("checking code");
    data = this.mapValuesToObject(data);
    console.log("check2"+data);
 
    this.extraRulesForm.patchValue(data);
    }
    // }
  }

  mapValuesToObject(obj) {
    let data = {};
  
    // for (let o in obj) {
     
    //     data[o] = this.editLookUp[o];
    
    // }
    for (let o in obj) {
      if (this.checkDropdownField(o)) {
        data[o] = this.reFormatValues(this.editLookUp[o]);   
      } else {
        data[o] = this.editLookUp[o];
      } 
      
      if(this.editLookUp.tableName==="call_report_code" && o==='field3'){
        data[o] = {key: this.editLookUp[o], value: this.editLookUp[o]}
        console.log("data[o]  test",data[o]);
      }
     
    }

   console.log("data", data);
  
    return data;
  }

  checkDropdownField(o) {
    
    if(this.editLookUp.tableName==='reprice_index_code'){
      return (
        o === "field2" 
        
      );
    }

    if(this.editLookUp.tableName==='call_report_code'){
      return (
        o === "field1" 
        
      );
    }
   
    if(this.editLookUp.tableName==='accrualbasis_code'){
      return (
        o === "field1" 
        
      );
    }

    if(this.editLookUp.tableName==='rate_change_frequency_code'){
      return (
        o === "field1" 
        
      );
    }

    if(this.editLookUp.tableName==='state_code'){
      return (
        o === "field1" 
        
      );
    }
  }

  reFormatValues(value) {
    return { key: value, value };
  }

  findField(t) {
    const { fields } = this.extraFormFields.find(d => d.key === t);
    return this.convertArrayToField(fields);
  }
  convertArrayToField(field) {
    let obj = {};
    for (let f of field) {
      obj[f] = null;
    }
    return obj;
  }


  saveData() {
   
    const extraRules=this.extraRulesForm!==null?this.extraRulesForm.value:'';
    console.log("lookup form on selector:",this.lookUpForm);
    if(this.lookUpForm.controls.code.status==="INVALID" && this.lookUpForm.controls.description.status==="INVALID"){
      this.toast.showError('error','Please Fill Required Fields');
      this.closePanel.emit();
      return;
    }
    if(this.lookUpForm.controls.code.status==="INVALID"){
      this.toast.showError('error','Code is required within limit');
      this.closePanel.emit();
      return;
    }
    if(this.lookUpForm.controls.description.status==="INVALID"){
      this.toast.showError('error','Name is required within limit');
      this.closePanel.emit();
      return;
    }
    let data = {
      ...this.removeKeyValueFormat(this.lookUpForm.value),
      
        ...this.removeKeyValueFormat(extraRules)
  
    };  
    //console.log(data)
   this.saveLookUp.emit(data);
   this.closePanel.emit();
  }

  removeKeyValueFormat(data) {
    let result = {};
    try {
      for (let o in data) {
        if(data[o]==null){
          result[o] = null;
        }
        else if (data[o].key && data[o]!=undefined && data[o]!=null) {        
          result[o] = data[o].key;   
        } else {         
          result[o] = data[o];         
        }
      }
      
      return result;
    } catch {
      return data;
    }
  }

  resetData(){
    this.closePanel.emit();
    this.lookUpForm.reset();
    this.enableEdit = false;
    this.editLookUp=null;
        if(this.extraRulesForm!==null){
        this.extraRulesForm.reset();
        }
  }
 

}
