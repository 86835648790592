import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { HomeHeaderComponent } from 'src/app/reports/containers/home-header/home-header.component';
import { HomeParameterComponent } from 'src/app/reports/containers/home-parameter/home-parameter.component';
import { ReportFormComponent } from 'src/app/reports/containers/report-form/report-form.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { LookUpListModel } from '../../models/lookup.model';
import { SspApplicationModel } from '../../models/selfServicePortal.model';
import { CategoryService } from '../../services/category.service';
import { LookupService } from '../../services/lookup.service';
import { SspApplicationService } from '../../services/ssp-application.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild(ReportFormComponent, { read: false, static: false })
  reportFormComponent: ReportFormComponent;
  
 
  @ViewChild(HomeParameterComponent, { read: false, static: false })
  homeParameterComponent:HomeParameterComponent;

  @ViewChild(HomeHeaderComponent,{read: false, static: false})
  homeHeaderComponent:HomeHeaderComponent;

  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  group:{key: any; value: any };
  dataVal: any;
  tableHeaders = [
    'Report Name',
    'Mode',
    'Category',
    'Status',
    'Report Period',
    'Report Frequency',
    'Published Date',
    'More',
    '',
  ];
  data = [];
  editAppDataValue: any = null;
  applicationData: any[] = [];
  allGroupData: {key: any; value: any }[]=[];
  appListData: {key: any; value: any }[]=[];
  allGenericData: {key: any; value: any }[]=[];
  allStatusData: {key: any; value: any }[]=[];
  activeRoute$: Subscription;
  parameterForm:FormGroup;
  searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String | number, header:String }[];
  categoryList: {key: any; value: any }[]=[];
  frequencyList: {key: any; value: any }[]=[];
  notificationList:any=[];
  activeNotifications:{notificationId:any;notificationName:any}[]=[];
  state:string;
  lookUpModelList: LookUpListModel[] = [];
  menu:string;
  submenu:string;
  constructor(private sspapplicationService: SspApplicationService,private toast:ToastService,
    private loader:LoaderService,private datePipe: DatePipe,private activeRoute: ActivatedRoute,private titleService: TitleService,private categoryService:CategoryService,private fileService: FileService,
    private lookupService: LookupService) {
      this.parameterForm = new FormGroup({
        groupData:new FormControl(null),
       });

       this. searchByCols = [
        { field: "appName", header: "Report Name" },
        { field: "reportMode", header: "Mode" }, 
      ];
      this.menu=localStorage.getItem("menu");
      this.submenu=localStorage.getItem("submenu");
      this.state=localStorage.getItem("stateflag");
    }

  ngOnInit(): void {
    //this.getApplicationData();
    this.setTitle();
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );

    this.getAllGroupData();
    this.getAllAppsData();
    this.getReportModeData();
    this.getStatusData();
    this.getAllCategory();
    this.getAllNotification();
    this.getAllFrequencyType();
    
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
        if (par.has("key")) {
          this.parameterForm.get("groupData").setValue({
            key: par.get("key"),
            value: par.get("key")
          });
          this.updateListingData(this.parameterForm);
        }else{
          this.getApplicationData();
        }
        }
    );
   
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getApplicationData() {
    // const appData={
    //   "appId":0
    // }
  //  this.loader.showLoader();
 
    this.sspapplicationService.getAppDataForReportPage().subscribe(
      (data) => {
     //   this.loader.hideLoader();
        this.applicationData = data;
        console.log('App Data Is:', this.applicationData);
        this.applicationData.forEach(item => {
          item.createdAt = this.datePipe.transform(item.createdAt, 'MM/dd/yyyy');
        });
      },
      (err) => {
     //   this.loader.hideLoader();
        this.toast.showError(err.error.message, 'error');
      });
      this.checkGroup(this.data);
  
  }

  getAllGroupData() {

    
    this.sspapplicationService.getAllGroupDataForGrouppage().subscribe((data) => {
      // this.allGroupData=data;
      this.allGroupData = reMapForAutoComplete(data, 'groupId', 'groupName');
    },
      (err) => {
        this.toast.showError(err.error.message, 'error');
      }
    );
  }

  getAllAppsData(){
    this.fileService.getAllApplicationsByUser().subscribe(
      (data) => {
        console.log("Applications",data);
        let tempAppList: any[]=[];
        for(var i=0;i<data.length;i++){
          if(data[i].flag == true){
            tempAppList.push(data[i]);
          }
        }
        this.appListData = reMapForAutoComplete(tempAppList, "applicationId", "name");
        console.log("appListData: ",this.appListData);
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  getReportModeData() {
    const type = 'report_mode';
    const dataVal = {
      type
    };
    this.sspapplicationService.getAllGenericDropDownData(dataVal)
      .subscribe(
        (data) => {
          this.allGenericData = reMapForAutoComplete(data, 'itemValue', 'itemText');
          console.log('generic data' + this.allGenericData);
        },
        (err) => {
          this.toast.showError(err.error.message, 'error');
        }
      );
  }


  getStatusData() {
    const type = 'status';
    const dataVal = {
      type
    };
    this.sspapplicationService.getAllGenericDropDownData(dataVal)
      .subscribe(
        (data) => {
          this.allStatusData = reMapForAutoComplete(data, 'itemValue', 'itemText');
          console.log('generic data' + this.allGenericData);
        },
        (err) => {
          this.toast.showError(err.error.message, 'error');
        }
      );
  }


  createApplicationData(data) {
    console.log("data for save ",data);
    const appData = {
      appId: data.appId,
      appName: data.appName,
      isActive: data.appStatus.key === 'Active' ? true : false,
      applicationGroup: data.groupData,
      // "embeddedUrl":data.reportUrl,
      applicationId: data.appListData.key,
      reportMode: data.reportMode.key,
      appDesc:data.description,
      categoryMap:data.category,
      frequency:data.frequency.value,
      outtableName:data.outtableName
    };

    console.log('AppData', appData);
    console.log('Before Create Application:', data);
    const createAppData = {
      applicationData: appData,
      instanceList: data.viewList,
      notificationGroupList: data.notificationType,
      countryList: data.countryList,
      companyType: data.companyType

    };

    this.sspapplicationService.createApplicationData(createAppData).toPromise().then(
      () => {
        this.toast.showSuccess('Success', 'Report Created Successfully');
        this.resetForm();
        this.homeHeaderComponent.reportFormComponent.viewList = [];
        if(this.homeParameterComponent.parameterForm.value.groupData.value==null || this.homeParameterComponent.parameterForm.value.groupData.value==undefined){
       this.getApplicationData();
    }else{
       this.updateListingData(this.homeParameterComponent.parameterForm);
        this.getAllGroupData();
      }
      },
      err => {
        this.loader.hideLoader();
        // this.toast.showError('error', 'Error Creating App');
        this.toast.showError(err.error.message, "error");
      });


  }
  

  resetForm() {
    this.homeHeaderComponent.reportFormComponent.resetForm();
    //  this.editAppDataValue = null;
    //this.getApplicationData();

  }

  updateListingData(data){
    if(data.controls!=null){
      console.log("report controls "+data);
      this.group=data.controls.groupData.value.value;
    }else{
    console.log("report listing "+data.groupData.value);
    this.group=data.groupData.value;
    }
    //this.loader.showLoader();
    this.sspapplicationService.getReportDataWithGroup(this.group).subscribe(
      (data) => {
       // this.loader.hideLoader();
        this.applicationData = data;
        console.log("App Data Is:", this.applicationData);
        this.applicationData.forEach(item => {
          item.createdAt = this.datePipe.transform(item.createdAt, "MM/dd/yyyy");
        });
        // this.homeParameterComponent.parameterForm.get("groupData").setValue({
        //   key: this.applicationData[0].groupId,
        //   value: this.group
        // });
      },
      (err) => {
     //   this.loader.hideLoader();
        this.toast.showError(err.error.message, "error");
      });
  }

  deleteById(data) {
    const genericModel ={
      "appId": data.appId
    }

    this.loader.showLoader();
    this.sspapplicationService.deleteById(genericModel)
      .subscribe(
        data => {
          this.loader.hideLoader();
          this.toast.showSuccess('Success', 'Application Data Deleted successfully');
          this.resetForm();
          if(this.homeParameterComponent.parameterForm.value.groupData.value==null || this.homeParameterComponent.parameterForm.value.groupData.value==undefined){
            this.getApplicationData();
         }else{
          this.getAllGroupData();
            this.updateListingData(this.homeParameterComponent.parameterForm);
            console.log("this.updateListing delete data",this.updateListingData(data))
             
           }
        },
        err => {
          this.loader.hideLoader();
          this.toast.showError('Error', 'Error Deleting data');
        });
  }

  editAppData(data) {
    this.editAppDataValue = data;
    this.homeHeaderComponent.panelForm.toggleSlider();
  }

  getAllCategory(){

    this.categoryService.getAllCategoryList().subscribe(
      (data)=>{
    this.categoryList=reMapForAutoComplete(data,"categoryId","categoryName");
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  
  }

  getAllNotification(){


    this.fileService.getActiveNotificationListByUser().subscribe(
      (data) => {
        console.log("notificationList",data);
       this.notificationList=data;
       // this.loader.hideLoader();
        this.notificationList = this.reMapForNotificationTypeMultiSelect( this.notificationList, "notificationId", "notificationName");
        
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      }  
  
    )
  }

  reMapForNotificationTypeMultiSelect(

    data: any[],
    keyCol: string,
    valCol: string
  ): {notificationId: string | number; notificationName: string }[] {
    return data.map(d => ({ notificationId: d[keyCol], notificationName: d[valCol] }));


  }

  setStatusForReport(data){
console.log("main Status",data);
    const model={
      appId:data.appId,
      isActive:data.status
    }
     console.log("Model for Status",model);
    this.fileService.setStatusData(model).subscribe(

      res=>{

        if(data.status==false){
          this.toast.showSuccess('Successfully','Deactivated');
          }
          else{
            this.toast.showSuccess('Successfully','Activated');
          }
         
          this.getAllGroupData();
          // this.getApplicationData();
          this.updateListingData;
          //this.getAllAppsData();
         // this.getReportModeData();
         // this.getStatusData();
         // this.getAllCategory();
         // this.getAllNotification();        
      },
       err=>{
        this.toast.showError(err.error.message, "error");
       }
    )


   }

   checkGroup(data){
    if(data.controls!=null){
      console.log("report controls "+data);
      this.group=data.controls.groupData.value.key;
    }else{
    console.log("report listing "+data.groupData.value);
    this.group=data.groupData.value;
    }
   }

   getAllFrequencyType(){
    const lookupModel = {
      "tableName":"frequency",
      "clientId": 139
    }
    this.lookupService.getLookUpDataByTableAndClient(lookupModel)
     .subscribe(
       data=>{
         this.lookUpModelList=data;
        this.frequencyList=reMapForAutoComplete(this.lookUpModelList,"description","description");
        console.log(this.frequencyList);
       },
       err=>{
        this.toast.showError(err.error.message, "error");
        //this.loader.hideLoader();
      }
     )
    
   }

}
