import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  ActivatedRoute
} from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  router$: Subscription;
  hideHeaders: boolean;
  activeRoute$: Subscription;
  constructor(private router: Router,private activeRoute: ActivatedRoute) {
    this.hideHeaders = true;
  }
  ngOnInit() {
    
    this.router$ = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let urlArray= event.url.split("/");
        console.log("---display--1",event.url)
        if (event.url === "/login" || event.url === "/" || event.url.startsWith("/login")) {
          console.log("this.hideHeaders --2")
          this.hideHeaders = true;
        }
        else if(event.url.includes("/display")) {

          //this.hideHeaders = false;
          this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
            par => {
              if (par.has("instanceUrl")) {
              
                let url=par.get("instanceUrl");

                console.log("instanceurl  ",url)
                console.log("---display--1",event.url)
                localStorage.setItem("pageView","false");
                let page = "/display";
                const fileData={
                  reportUrl:url
                }
                this.router.navigate([page], {
                 queryParams: {...fileData},
               });
  
              }
            }
          );

         
        }
       
        else if(event.url.includes("swagger-ui")) { 
          console.log("Hi This swagger...");
          console.log("Url Array",urlArray)
          if(event.url.includes("swagger-ui") && urlArray.length<=2) { 
            let swaggerUrl = environment.baseUrl+"/swagger-ui/index.html?configUrl=/api-docs/swagger-config#/";
            console.log("swagger Url",swaggerUrl);            
            window.location.href=swaggerUrl;
          }
        }
        else{
          this.hideHeaders = false;
          console.log("this.hideHeaders --")
         
        }
      }
    });

   // console.log("NavigationEnd",NavigationEnd)
  }
  ngOnDestroy() {
    this.router$.unsubscribe();
  }
}
