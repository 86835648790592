import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { DataTransferHeaderComponent } from 'src/app/data-transfer/data-transfer-header/data-transfer-header.component';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { ClientService } from '../../services/client.service';
import { DbconnectionService } from '../../services/dbconnection.service';
import { TableViewerService } from '../../services/table-viewer.service';

@Component({
  selector: 'app-data-transfer',
  templateUrl: './data-transfer.component.html',
  styleUrls: ['./data-transfer.component.scss']
})
export class DataTransferComponent implements OnInit {
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;

  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(DataTransferHeaderComponent, {static: false })
  DataTransferHeaderComponent:DataTransferHeaderComponent;
  //@Output() dataTransferList.emit();

  headerForm:FormGroup=null;
  companyId:any;
  DataTransferList:any;
  companyName:String;
  clientsList:any[];
  dbConnectionDataList:any[];
  databaseNameList:any[];
  destinationDatabaseNameList:any[];
  sourceConnectionDetails:any=null;
  destinationDetails:any=null;
  editData:any;
  tableNameList:any[];
  destinationTableNameList:any[];
  storForm:FormGroup=null;
  adminFlag:String;

  columnsList:any;
  destColumns:any;
  columnNames:string[]=[];
  saveColName:string="";
  state:string;
  sourceDBConnectionId:any;
  destinationDBConnectionId:any;
  constructor(private activeRoute:ActivatedRoute,
    private tableViewerService:TableViewerService,
    private dbconnectionService:DbconnectionService,
    private clientService: ClientService,
    private loader: LoaderService,
    private cookieService: CookieService,
    private titleService: TitleService,
    private fileService: FileService,
    private toast: ToastService,
    )
    {
      titleService.setTitle("Data Transfer")
      this.headerForm = new FormGroup({
        company: new FormControl(null),
      });
      this.state=localStorage.getItem("stateflag"); 
    }

  ngOnInit(){
    this.getClients();

    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
        for(var i=0;i<data.length;i++){
          console.log("data  ",data[i].clientId)
          this.companyId=data[i].clientId
          this.companyName=data[i].clientName
        }
        this.headerForm.get("company").setValue({
          key: this.companyId,
          value: this.companyName
        });

        this.getAllDbCoonByComapnyId( this.headerForm.controls.company.value.key);
      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
      );

      this.adminFlag=this.cookieService.get("adminFlag");

  }





  getClients() {
   // this.loader.showLoader();
    this.clientService.getAllClients().subscribe(
      (data) => {
        this.loader.hideLoader();
        this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
      },
      (err) => {
        this.loader.hideLoader();
        this.toast.showError(err.error.message, "error");
      }
    );
  }

  getAllDbCoonByComapnyId(companyId){
    const requestModel={
      "companyId":companyId
    }
     this.dbconnectionService.getAllDbConnectionListByCompanyId(requestModel)
     .subscribe(
       data=>{
      this.dbConnectionDataList=data;
      console.log("this.dbConnectionDataList   ",this.dbConnectionDataList)
      this.dbConnectionDataList=reMapForAutoComplete(data, "serviceName", "serviceName");
      this.dataTransferList();
       },
       error=>{

       }
     )
  }


  detailByService(data){
    const model={
      companyId:this.headerForm.controls.company.value.key,
      serviceName:data.serviceName,
      source:data.source=true
    }
    if(data.flag=="source"){
    this.dbconnectionService.getServiceByClient(model).subscribe(
        res=>{
      
       console.log("this.res   ",res)
       this.sourceDBConnectionId=res.dbConnectionId;
       
      const serviceModel={
        sourceDBConnectionId:res.dbConnectionId,
        companyId:this.headerForm.controls.company.value.key,
       //connectionUrl:res.connectionUrl,
      //userName:res.username,
       //password:res.password,
       connectionType:res.connectionType,
       source:res.source=true
               }
      this.sourceConnectionDetails=serviceModel;
             
        this.getAllDataBaseName(serviceModel,data.flag);
        },
        error=>{
        // this.toast.showError("api failed","error");
        }
    )
      }
    if(data.flag=="destination"){
    this.dbconnectionService.getServiceByClient(model).subscribe(
      res=>{
    
     console.log("this.res   ",res)
     this.destinationDBConnectionId=res.dbConnectionId;
    
    const serviceModel={
      sourceDBConnectionId:res.dbConnectionId,
      companyId:this.headerForm.controls.company.value.key,
      //connectionUrl:res.connectionUrl,
     //userName:res.username,
     //password:res.password,
     connectionType:res.connectionType
             }
      this.destinationDetails=serviceModel;
      this.getAllDataBaseName(serviceModel,data.flag);
      },
      error=>{
      // this.toast.showError("api failed","error");
      }
    )
    }
      }

  getAllDataBaseName(dataModel,flag){
    this.dbconnectionService.getAllDataBaseNameList(dataModel).subscribe(
      data=>{
            if(flag=="source"){
            const databaseNameList=data.databaseNames;
            let databaseList=[]; 
            try {
              databaseNameList.forEach(element => {
                // if(element.includes("datalake")){
                // }else{
                  databaseList.push(element);
               // }
            });
            } catch (error) {}            
          console.log("database list",databaseList)
            this.databaseNameList=databaseList.map((t) => ({ key: t, value: t }));
            console.log("this.databaseNameList  after ",this.databaseNameList)
            }

            if(flag=="destination"){  
            const destinationDatabaseNameList=data.databaseNames;
            let destinationDatabaseList=[];
            try {
              destinationDatabaseNameList.forEach(element => {
                // if(element.includes("datalake")){
                  destinationDatabaseList.push(element);
                // }
              });
            } catch (error) {}
            this.destinationDatabaseNameList=destinationDatabaseList.map((t) => ({ key: t, value: t }));
            console.log("this.destinationDatabaseNameList  after ",this.destinationDatabaseNameList)

            }


         },
         error=>{
          if(error.error.message.includes("Access denied for user")){
            this.DataTransferHeaderComponent.panelForm.toggleSlider();
            this.toast.showError("Username or Password entered for selected Service Name is Incorrect","error");
        }if(error.error.message.includes("Communications link failure")){
          this.DataTransferHeaderComponent.panelForm.toggleSlider();
          this.toast.showError("Connection Url entered for selected Service Name is incorrect","error");
        }
        // else{
        //   this.toast.showError(error.error.message,"error");
        // }
         }

    )

  }

 
  getAllTableByDatabaseName(data){

    if(data.flag=="source"){
      if(this.sourceConnectionDetails!=null){
        const dataModel={
          sourceDBConnectionId:this.sourceDBConnectionId,
          databaseName:data.databaseName,
          companyId:this.headerForm.controls.company.value.key,
          //connectionUrl:this.sourceConnectionDetails.connectionUrl,
          // userName:this.sourceConnectionDetails.userName,
          // password:this.sourceConnectionDetails.password,
          connectionType:this.sourceConnectionDetails.connectionType
         }
console.log("dataModel   ",dataModel)
         this.dbconnectionService.getAllTableByDb(dataModel).subscribe(
           res=>{
             console.log("all tables  ",res)
             const tableNameList=res.databaseTableList;
             this.tableNameList=tableNameList.map((t) => ({ key: t, value: t }));
           },
           error=>{


           }
           )
      }
    }

    if(data.flag=="destination"){
      if(this.destinationDetails!=null){
        const dataModel={
          destinationDBConnectionId:this.destinationDBConnectionId,
          databaseName:data.databaseName,
          companyId:this.headerForm.controls.company.value.key,
         // connectionUrl:this.destinationDetails.connectionUrl,
          // userName:this.destinationDetails.userName,
          // password:this.destinationDetails.password,
          connectionType:this.destinationDetails.connectionType
         }
console.log("dataModel   ",dataModel)
         this.dbconnectionService.getAllTableByDb(dataModel).subscribe(
           res=>{
             console.log("all tables  ",res)
             const destinationTableNameList=res.databaseTableList;
             this.destinationTableNameList=destinationTableNameList.map((t) => ({ key: t, value: t }));
           },
           error=>{


           }
           )
      }
    }


  }

  transferDataToSave(data){
    console.log("Saving Data: ",data);
    this.columnNames=[];
    for(var i=0;i<data.selectSourceColumnList.length;i++){
    this.columnNames.push(data.selectSourceColumnList[i].column);
    }
    if (data.isMap) {
      console.log("Mapping is enable: "+data);
      for (var i = 0; i < this.columnNames.length; i++) {
        let colName = this.columnNames[i] + " as " + data[this.columnNames[i]].value;
        if (i != (this.columnNames.length - 1)) {
          this.saveColName = this.saveColName + colName + ",";
        } else {
          this.saveColName = this.saveColName + colName;
        }
      }
    } else {
      for (var i = 0; i < this.columnNames.length; i++) {
        if (i != (this.columnNames.length - 1)) {
          this.saveColName = this.saveColName + this.columnNames[i] + ",";
        } else {
          this.saveColName = this.saveColName + this.columnNames[i];
        }
      }
    }
    data.cols=this.saveColName;
    if(this.sourceConnectionDetails!=null && this.destinationDetails!=null){
      const transferModel={
        sourceDBConnectionId:this.sourceDBConnectionId,
        destinationDBConnectionId:this.destinationDBConnectionId,
        //sourceServiceName:data.sourceServiceName.value,
        databaseName:data.sourceDatabaseName.value,
        tableName:data.sourceTableName.value,
        //connectionUrl:this.sourceConnectionDetails.connectionUrl,
        //userName:this.sourceConnectionDetails.userName,
        //password:window.btoa(this.sourceConnectionDetails.password),
        //connectionType:this.sourceConnectionDetails.connectionType,
        columnNames:data.cols,
        selectedRowLimit:data.sourcerowLimit,
        companyId: this.headerForm.controls.company.value.key,
        //destinationUserName:this.destinationDetails.userName,
        //destinationPassword:window.btoa(this.destinationDetails.password),
        //destinationServiceName:data.destinationServiceName.value,
        destinationDatabaseName:data.destinationDatabaseName.value,    
        destinationTableName:data.destinationTableName.value,
        //destinationConnectionUrl:this.destinationDetails.connectionUrl
       }
       console.log("Transfer Model",transferModel);
   
       this.dbconnectionService.saveTransferTableData(transferModel).subscribe(
        res=>{
          console.log("res  ",res.executeStatus);
          this.saveColName="";
          if(res.executeStatus){   
           this.toast.showSuccess('Data Transfer successfully','success');
            this.dataTransferList();
                }
             },
        error=>{
          this.saveColName="";
          console.log("error message: ",error);
          if(error.error!= null || error.error!= "undefined"){
            this.toast.showError(error.error,"error")
          }else{
            this.toast.showError("Failed TranserData Api","error")

          }
        }
        )
         console.log("transferData ",data);
  }


  
}



dataDumpInNewTableAndTransfer(data){
  console.log("data  ",data);
  console.log("data  ",data.destinationTableName);
  if(this.sourceConnectionDetails!=null){
  const newTableModel={
    databaseName:data.destinationDatabaseName.value,
    tableName:data.sourceTableName.value,
    connectionUrl:this.sourceConnectionDetails.connectionUrl,
    userName:this.sourceConnectionDetails.userName,
    password:this.sourceConnectionDetails.password,
    connectionType:this.sourceConnectionDetails.connectionType,
  }
  console.log("newTableModel  ",newTableModel)
  this.dbconnectionService.createStatementforTable(newTableModel).subscribe(
    res=>{
  console.log("create statement ",res.createStatement);
  this.columnNames=[];
  for(var i=0;i<data.selectSourceColumnList.length;i++){
    this.columnNames.push(data.selectSourceColumnList[i].column);
  }
  console.log("columnNames  ",this.columnNames);
  const obj={
    statement:res.createStatement,
    destinationDatabaseName:data.destinationDatabaseName.value,
    tableName:res.tableName,
    sourceColumnList:this.columnNames,
    databaseName:data.sourceDatabaseName.value,
    destinationServiceName:data.destinationServiceName.value,
    sourceServiceName:data.sourceServiceName.value
  }
  data.destinationTableName=data.destinationTableName;
  this.executeQueryStatement(obj,data);
  this.toast.showSuccess("success","Data transfer successfully");     
  this.dataTransferList();
  },
  error=>{
   // this.toast.showError("error","Data transfer Failed");  
  })
}


}


executeQueryStatement(obj,formValues){
  console.log("formValues: ", formValues);
  if(this.destinationDetails!=null){
    const reqObj={
  destinationUserName:this.destinationDetails.userName,
  destinationPassword:this.destinationDetails.password,
  destinationDatabaseName:obj.destinationDatabaseName, 

  destinationConnectionUrl:this.destinationDetails.connectionUrl,
  
  connectionType:this.destinationDetails.connectionType,
  createStatement:obj.statement,
  tableName:obj.tableName,
  sourceColumnList:obj.sourceColumnList,
  databaseName:obj.databaseName,
  sourceServiceName:obj.sourceServiceName,
  destinationServiceName:obj.destinationServiceName,
  //clientId:this.headerForm.controls.company.value
  }
  console.log("reqObj: ",reqObj);
  this.dbconnectionService.executeStatement(reqObj).subscribe(
    data=>{
console.log("data  ",data.executeStatus);
     if(data.executeStatus){
      console.log("form Values  ",formValues);
     // formValues.cols=data.columnNames;
      this.columnNames=[];
      this.transferDataToSave(formValues);

       }
       else{
      //  this.toast.showError("Failed In Create New Table","error");

       }
  },
  error=>{
   // this.toast.showError("Failed TranserData Api","error");
   }
  )
  }
}

  callDestinationTableColumnsData(data) {
    if (this.destinationDetails != null) {
      const model = {
        databaseName: data.databaseName,
        tableName: data.tableName,
        connectionUrl:this.destinationDetails.connectionUrl,
        userName:this.destinationDetails.userName,
        password:this.destinationDetails.password,
        connectionType:this.destinationDetails.connectionType,
        companyId:this.headerForm.controls.company.value.key,
        destinationDBConnectionId:this.destinationDBConnectionId,
      }
      this.dbconnectionService.getColumnsByTable(model).subscribe(
        data => {
          this.destColumns = data["columnListing"].map((item) => ({key:item.column, value:item.column}));
          console.log("destination columns: "+this.destColumns);
        }, err => { });
    }
  }



  callTableColumnsData(data){

    if(this.sourceConnectionDetails!=null){
      const sourceModel={
        databaseName:data.databaseName,
        tableName:data.tableName,
        sourceDBConnectionId:this.sourceDBConnectionId,
        companyId:this.headerForm.controls.company.value.key,
        destinationDBConnectionId:this.destinationDBConnectionId,
        //connectionUrl:this.sourceConnectionDetails.connectionUrl,
        //userName:this.sourceConnectionDetails.userName,
        //password:this.sourceConnectionDetails.password,
        //connectionType:this.sourceConnectionDetails.connectionType,
      }
    this.dbconnectionService.getColumnsByTable(sourceModel).subscribe(
      data =>{
        this.columnsList=data["columnListing"];
        console.log(this.columnsList);
       // this.selectedColumns=[];
       // this.tableData=data["tableData"];
        //this.paginationCount=data["countSet"];
        //console.log(this.dataValues)
        // const dataValues={
        //     "form":this.parameterForm.controls,
        //     "tableColumns":this.dataValues,
        //     "tableData":this.tableData,
        //     "paginationCount":this.paginationCount
        // }
        // this.fetch.emit(dataValues);
      },
      error=>{
  
      }
  
    )
  
  
  }
  
  }
  onLoad(){
  this.companyName=this.headerForm.controls.company.value.value;
  this.getAllDbCoonByComapnyId(this.headerForm.controls.company.value.key);

  this.panelFilter.toggleSlider();

  }


  dataTransferList()
  {
    this.dbconnectionService.getDataTransferList(this.companyId).subscribe(
      (data) => {
        this.DataTransferList=data;
      
        console.log("Data Transfer from backend ",data)
      },
      (err) =>{
        //this.toast.showError(err.error.message, "error");
      } 
      
    );
  
   
  }

  editJob(rowData){
    console.log("editjob List of data transfer ",rowData);
    
    this.editData=rowData;
    this.DataTransferHeaderComponent.toggled();
  }


  
 
 
}
