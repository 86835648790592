import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppWithInstanceModel, InstanceModel, SspApplicationModel } from '../model/selfServicePortal.model';

@Injectable({
  providedIn: 'root'
})
export class SspApplicationService {

  /**
 * @param  {HttpClient} privatehttp
 */
  constructor(private http: HttpClient) { }

  getApplicationData(data) {
    const genericRequestData={
      "appId":0
    }
    return this.http.get<SspApplicationModel[]>(environment.baseUrl + "/apis/ssp/getApplicationData");
  }

  getAppDataWithInstance(data) {
    const genericRequestData={
      "appId":0
    }
    return this.http.post<AppWithInstanceModel[]>(environment.baseUrl + "/apis/ssp/getAppDataWithInstance",genericRequestData);
  }

  getAppDataForReportPage() {
    return this.http.get<AppWithInstanceModel[]>(environment.baseUrl + "/apis/ssp/getAllApplicationData");
  }


  createApplicationData(data) {
    return this.http.post(`${environment.baseUrl}/apis/ssp/createApplicationData`, data);
  }

createGroupData(data) {
  return this.http.post(`${environment.baseUrl}/apis/ssp/saveAppGroup`, data);
}

deleteById(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/ssp/deleteApplicationById`,data
      );
  }

  getAllInstance(data) {
    return this.http.post<InstanceModel[]>(environment.baseUrl + "/apis/ssp/getAllInstance1",data);
  }

  getInstanceByAppId(data) {
    return this.http.post<InstanceModel[]>(
      `${environment.baseUrl}/apis/ssp/getInstanceByAppId`,data
    );
  }

  getAllReports(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getApplicationData");
  }
  
  getAllReportsForConfig(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getReportList");
  }

  getAllGroupData(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getAllReportGroupForUser");
  }

  getAllGroupDataForGrouppage(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getAllReportGroup");
  }

  getAllGroupDataWithStatus(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getAllReportGroupWithStatus");
  }

  deleteGroup(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/ssp/deleteGroup`,data
      );
  }

  getApplicationList(){
  return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getAllApplicationDataForUser");
   }

   getAllGenericDropDownData(data) {
    return this.http.post<any>(environment.baseUrl + "/apis/ssp/getGenericDropdownData",data);
  }

  getTableDropDownbyId() {
    return this.http.get<any>(environment.baseUrl + "/apis/ssp/getTableDropDownbyId");
  }

  getReportDataWithGroup(groupId) {
    return this.http.post<AppWithInstanceModel[]>(environment.baseUrl + "/apis/ssp/getReportDataWithGroup",groupId);
  }


  getCurrencyCodeData(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getCurrencyCodeData");

  }

  getCountryCodeData(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getCountryCodeData");
  }

  getStateCodeData(code){
    return this.http.get<any>(environment.baseUrl + "/apis/ssp/getStatesByCountryCode?countryCode="+code);
  }

  getCompanyTypeByCountryCode(countryCode){
    return this.http.get<any>(environment.baseUrl + "/apis/ssp/getCompanyTypeByCountryCode?countryCode="+countryCode);
  }

  getReportByCountryCodeAndCompanyType(countryCode, companyTypeId){
    return this.http.get<any>(environment.baseUrl + "/apis/ssp/getReportByCountryCodeAndCompanyType?countryCode="+countryCode+"&companyTypeId="+companyTypeId);
  }

  getReportGroupByClientId(selectedClients){
    return this.http.post<any>(environment.baseUrl + "/apis/ssp/getReportGroupByClientId",selectedClients);
  }

  getReportByClientId(selectedClients){
    return this.http.post<any>(environment.baseUrl + "/apis/report/getReportByClientId",selectedClients);
  }
  
  getCompanType(){
    return this.http.get<any>(environment.baseUrl + "/apis/ssp/getAllCompanyType");
}

}
