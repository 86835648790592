import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api/menuitem";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private http: HttpClient) {}

  // getMenuItems() {
  //   return this.http.get<MenuItem[]>("/assets/menu.json");
  // }
  getCompanyLogo() {
    return this.http.get<any[]>(`${environment.baseUrl}/apis/menu/getCompanyLogo`);
  }

  getMenuItems(state) {

    const formData = new FormData();
    localStorage.setItem("applicationId",state);
      formData.append('applicationId',state); 
       return this.http.get<MenuItem[]>(`${environment.baseUrl}/apis/menu/getAllMenu?applicationId=`+state);
     }

  getStressMenuItems() {
      return this.http.get<MenuItem[]>(`${environment.baseUrl}/apis/menu/getAllStressMenu`);
    }
 
  
  getClientMenuItems() {
    return this.http.get<MenuItem[]>("/assets/client-menu.json");
  }
}
