import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-interestRateRiskScenario-selector',
  templateUrl: './interestRateRiskScenario-selector.component.html',
  styleUrls: ['./interestRateRiskScenario-selector.component.scss']
})
export class InterestRateRiskScenarioSelectorComponent implements OnInit {
  
  @Output() save=new EventEmitter();
  @Output() closePanel=new EventEmitter();
  interestRateRiskScenarioForm: FormGroup = null;
  @Input() editData:any[];
  
  constructor(private toast:ToastService,private datePipe: DatePipe) {

    this.interestRateRiskScenarioForm=new FormGroup({
      irr_scenario_id:new FormControl(null),
      scenario: new FormControl(null,{validators:[Validators.required,Validators.minLength(5),Validators.maxLength(100)]}),
      interestRateRiskType: new FormControl(null,{validators:[Validators.required,Validators.minLength(5),Validators.maxLength(50)]}),
      statusMap: new FormControl(null,{validators:[Validators.required]}),   
      bucket1: new FormControl(null),
    bucket2: new FormControl(null),
    bucket3: new FormControl(null),
    bucket4: new FormControl(null),
    bucket5: new FormControl(null),
    bucket6: new FormControl(null),
    bucket7: new FormControl(null),
    bucket8: new FormControl(null),
    bucket9: new FormControl(null),
    bucket10: new FormControl(null), 
    });
  
   }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
  this.interestRateRiskScenarioForm.get('statusMap').setValue(defaultMap);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("editData",this.editData)
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);
  
      this.interestRateRiskScenarioForm.patchValue(
        {
          irr_scenario_id:rowData.irr_scenario_id,
          scenario:rowData.scenario,
          interestRateRiskType:rowData.type,
          bucket1:rowData.bucket1,
          bucket2:rowData.bucket2,
          bucket3:rowData.bucket3,
          bucket4:rowData.bucket4,
          bucket5:rowData.bucket5,
          bucket6:rowData.bucket6,
          bucket7:rowData.bucket7,
          bucket8:rowData.bucket8,
          bucket9:rowData.bucket9,
          bucket10:rowData.bucket10
                   
        });

        const map1 = {
          key: "Active",
          value: "Active",
        };
      
        const map2 = {
          key: "Inactive",
          value: "Inactive",
        };
      
      rowData.status==true? this.interestRateRiskScenarioForm.controls.statusMap.setValue(map1):this.interestRateRiskScenarioForm.controls.statusMap.setValue(map2);

        
    }
  
  }
  
  saveInterestRateRiskScenario(flag){
  console.log("this.report configuration form value ",this.interestRateRiskScenarioForm.value)
   if(flag=='onLoad'){

    if(this.interestRateRiskScenarioForm.valid){
      let statusMap=this.interestRateRiskScenarioForm.controls.statusMap.value;
       

      console.log("statusMap",statusMap)
      if (statusMap=="Select Status *") {
        this.toast.showError("Please Fill Required Fields","error");
      }
      if(statusMap!=="Select Status *"){
      console.log("stressScenarioFormValid",this.interestRateRiskScenarioForm.valid);
           this.save.emit(this.interestRateRiskScenarioForm.value);
      }
             }
               else{
                 this.toast.showError("Please Fill Required Fields","error");
             }
   
  this.closePanel.emit();
  
  
    }

    }

    formReset(){
      this.interestRateRiskScenarioForm.reset();
      const defaultMap = {
        key: "Active",
        value: "Active",
      };
     this.interestRateRiskScenarioForm.get('statusMap').setValue(defaultMap);
    }

    get status() {
      const status = [
        "Active",
        "Inactive",
      ];
     return status.map((t) => ({ key: t, value: t }));
    }
    
 
}
