
import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { LookUpListModel } from 'src/app/dashboard/models/lookup.model';

@Component({
  selector: 'app-lookup-list',
  templateUrl: './lookup-list.component.html',
  styleUrls: ['./lookup-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LookupListComponent implements OnInit {

  @Input() data: LookUpListModel[] = [];
  @Input() hidebutton:boolean;
  @Output() edit=new EventEmitter();
  @Output() delete= new EventEmitter();
  @Input() permission:any;

  // @Input() cols: { field: string; header: string }[];
  @Input() tableName;
  @Output() changeTableName=new EventEmitter();


  visible:boolean=false;
  dataValue:any;

  cols: { field: string; header: string }[];
  constructor() { 
    this.cols = [
      { field: "code", header: "Code" },
      { field: "description", header: "Description" },
      { field: "createdAt", header: "Created At" },
      { field: "modifiedAt", header: "Modified At" }
    ];
  }

  ngOnInit(): void {
  }

  editLookUp(data){
    console.log("hello",data);
    this.edit.emit(data);

  }

  deleteLookup(data){
    // console.log('table data ',data)
       console.log("###printing child delete variable comp"+data.tableName);
     this.delete.emit(data);
   }


   hidePopup(e){
    this.visible=false;
    this.dataValue=null;

  }

  showPopup(data){
    this.dataValue=data;
    this.visible=true;
  }
 
  actionDo(str){
    //console.log("yes delete do "+this.dataValue);
    this.deleteLookup(this.dataValue);
    this.dataValue=null;
    this.visible=false;
     
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.tableName && changes.tableName.currentValue) {
      console.log(changes.tableName.currentValue,' indise table change changes') 
       // const lookUp=changes.editLookUp.currentValue;
this.changeTableName.emit(changes.tableName.currentValue);
      // this.cols=null;
      // this.dataValue.resetForm();

      //  this.extraFormModel=null;
      //  this.lookUpForm.reset();

       if(changes.tableName.currentValue==='accrualbasis_code'){
         
        this.cols = [
          { field: "code", header: "Accrual Code" },
          { field: "description", header: "Accrual Description" },
          { field: "field1", header: "Accrual Basis" }, 
          { field: "createdAt", header: "Created At" },
          { field: "modifiedAt", header: "Modified At" }
        ];
       }


       if(changes.tableName.currentValue==='branch_code'){
        this.cols = [
          { field: "code", header: "Branch Code" },
          { field: "description", header: "Branch" },
          { field: "createdAt", header: "Created At" },
          { field: "modifiedAt", header: "Modified At" }
        ];
       }

      

       if(changes.tableName.currentValue==='call_report_code'){
        // this.cols = [
        //   { field: "code", header: "Code" },
        //   { field: "description", header: "Description" },
        //   { field: "createdAt", header: "Created At" },
        //   { field: "modifiedAt", header: "Modified At" }
        // ];
        this.cols = [
          { field: "code", header: "R Call Report Code" },
          // { field: "description", header: "Call Report Name Code" },
          { field: "description", header: "Description" },
          { field: "field1", header: "Call Report Code" },
          { field: "field2", header: "Product Type" },
          { field: "field3", header: "Big Cd" },
          { field: "createdAt", header: "Created At" },
          { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='collateral_code'){
        this.cols = [
          { field: "code", header: "Collateral Code" },
          { field: "description", header: "Collateral Description" },
          { field: "field1", header: "Collateral Grouping" },
          { field: "field2", header: "Collateral Category1" },
          { field: "field3", header: "Collateral Category2" },
          { field: "field4", header: "Profit Numeric" },
          { field: "createdAt", header: "Created At" },
          { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='line_of_credit_code'){
        this.cols = [
          { field: "code", header: "Line Of Credit Code" },
          { field: "description", header: "Loc Bank Description" },
          { field: "field1", header: "Loc Flag" },
          { field: "field2", header: "Loc Flag Profit" },
          { field: "field3", header: "Loc Type Profit" },
          { field: "field4", header: "Loan Type Name" },
          { field: "createdAt", header: "Created At" },
          { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='loan_type_code'){
        this.cols = [
        { field: "code", header: "Loan Type Code" },
        { field: "description", header: "Loan Type Code Bank Description" },
        { field: "field1", header: "Loan Type Name" },
         { field: "field2", header: "Loan Term Type" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='non_accrual_code'){
        this.cols = [
        { field: "code", header: "Non Accrual Code" },
        { field: "description", header: "Non Accrual Code Description" },
        { field: "field1", header: "Non Accrual Flag" },
        { field: "field2", header: "Non Accrual Flag1" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='officer_code'){
        this.cols = [
       { field: "code", header: "Officer Code" },
        { field: "description", header: "Officer Name" },
        { field: "field1", header: "Divison" },
        { field: "field2", header: "Department" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='owner_occupied_code'){
         this.cols = [
       { field: "code", header: "Owner Occupied Code" },
        { field: "description", header: "Standard Owner Occupied Code" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='payment_frequency_code'){
         this.cols = [
       { field: "code", header: "Payment Frequency Code" },
        { field: "description", header: "Payment Frequency Description" },
        { field: "field1", header: "Payment Frequency" },
        { field: "field2", header: "Payment Frequency Bank Description" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='payment_type_code'){
        this.cols = [
       { field: "code", header: "Payment Type Code" },
        { field: "description", header: "Payment Type Description" },
        { field: "field1", header: "Payment Type" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='purpose_code'){
         this.cols = [
       { field: "code", header: "Loan Purpose Code" },
        { field: "description", header: "Loan Purpose" },
        { field: "field1", header: "Product Type" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='rate_change_frequency_code'){
        // this.cols = [
        //   { field: "code", header: "Code" },
        //   { field: "description", header: "Description" },
        //   { field: "createdAt", header: "Created At" },
        //   { field: "modifiedAt", header: "Modified At" }
        // ];
         this.cols = [
       { field: "code", header: "Rate Change Frequency Code" },
        { field: "description", header: "Rate Change Frequency Bank Description" },
        { field: "field1", header: "Rate Change Frequency" },
        { field: "field2", header: "Rate Change Frequency Pcbb Description" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='reprice_index_code'){
        // this.cols = [
        //   { field: "code", header: "Code" },
        //   { field: "description", header: "Description" },
        //   { field: "createdAt", header: "Created At" },
        //   { field: "modifiedAt", header: "Modified At" }
        // ];
         this.cols = [
       { field: "code", header: "Reprice Index Code" },
        { field: "description", header: "Reprice Index Description" },
        { field: "field1", header: "Rate Flag" },
        { field: "field2", header: "Reprice Index" },
        { field: "field3", header: "Rate Flag Desc" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='risk_rating_code'){
         this.cols = [
       { field: "code", header: "Risk Rating Code" },
        { field: "description", header: "Risk Rating" },
        { field: "field1", header: "Risk Rating Substandard" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='interest_payment_frequency_code'){
         this.cols = [
       { field: "code", header: "Interest Payment Frequency Code" },
        { field: "description", header: "Interest Payment Frequency Description" },
        { field: "field1", header: "Interest Payment Frequency" },
        { field: "field2", header: "Interest Payment Frequency Bank Description" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='rate_flag_code'){
         this.cols = [
       { field: "code", header: "Rate Flag Code" },
        { field: "description", header: "Rate Flag Description" },
        { field: "field1", header: "Rate Flag" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='accrualbasis_borrow_code'){
         this.cols = [
       { field: "code", header: "Code" },
        { field: "description", header: "Description" },
        { field: "field1", header: "Accural Basis" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='call_put_frequency_borrow_code'){
         this.cols = [
       { field: "code", header: "Call Put Frequency Code" },
        { field: "description", header: "Call Put Frequency Description" },
        { field: "field1", header: "Call PutFrequency" },
        { field: "field2", header: "Call Put Frequency Bank Description" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='call_put_index_borrow_code'){
         this.cols = [
       { field: "code", header: "Call Put Index Code" },
        { field: "description", header: "Call Put Index Description" },
        { field: "field1", header: "Rate Flag" },
        { field: "field2", header: "Call Put Index" },
        { field: "field3", header: "Rate Flag Description" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='call_put_method_borrow_code'){
         this.cols = [
       { field: "code", header: "Call Put Method Code" },
        { field: "description", header: "Call Put Method Code Bank Description" },
        { field: "field1", header: "Call Put Method Name" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='call_report_borrow_code'){
         this.cols = [
       { field: "code", header: "R Call Report Code" },
        { field: "description", header: "Call Report Name Code" },
        { field: "field1", header: "Call Report Code" },
        { field: "field2", header: "Risk Weight" },
        { field: "field3", header: "Product Type" },
        { field: "field4", header: "Big Cd" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='compound_frequency_borrow_code'){
         this.cols = [
       { field: "code", header: "Compound Frequency Code" },
        { field: "description", header: "Compound Frequency Description" },
        { field: "field1", header: "Compound Frequency" },
        { field: "field2", header: "Compound Frequency Bank Description" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='credit_rating_borrow_code'){
         this.cols = [
       { field: "code", header: "Credit Rating Code" },
        { field: "description", header: "Credit Rating" },
        { field: "field1", header: "Credit Rating Substandard" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='interest_payment_frequency_borrow_code'){
         this.cols = [
       { field: "code", header: "Interest Payment Frequency Code" },
        { field: "description", header: "Interest Payment Frequency Description" },
        { field: "field1", header: "Interest Payment Frequency" },
        { field: "field2", header: "Interest Payment Frequency Bank Description" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='payment_frequency_borrow_code'){
         this.cols = [
       { field: "code", header: "Payment Frequency Code" },
        { field: "description", header: "Payment Frequency Description" },
        { field: "field1", header: "Payment Frequency" },
        { field: "field2", header: "Payment Frequency Bank Description" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='payment_type_borrow_code'){
         this.cols = [
       { field: "code", header: "Payment Type Code" },
        { field: "description", header: "Payment Type Description" },
        { field: "field1", header: "Payment Type" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='rate_change_frequency_borrow_code'){
         this.cols = [
       { field: "code", header: "Rate Change Frequency Code" },
        { field: "description", header: "Rate Change Frequency Bank Description" },
        { field: "field1", header: "Rate Change Frequency" },
        { field: "field2", header: "Rate Change Frequency Pcbb Description" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='reprice_index_borrow_code'){
         this.cols = [
       { field: "code", header: "Reprice Index Code" },
        { field: "description", header: "Reprice Index Description" },
        { field: "field1", header: "Rate Flag" },
        { field: "field2", header: "Reprice Index" },
        { field: "field3", header: "Rate Flag Description" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='deposit_ownership_code'){
         this.cols = [
       { field: "code", header: "Application Code" },
        { field: "description", header: "Bank Description" },
        { field: "field1", header: "Ownership Code" },
        { field: "field2", header: "Alm Ownership Category" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='deposit_product_code'){
         this.cols = [
       { field: "code", header: "Application Code" },
        { field: "description", header: "Bank Description" },
        { field: "field1", header: "Product Code" },
        { field: "field2", header: "Alm Product Category" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='state_code'){
        // this.cols = [
        //   { field: "code", header: "Code" },
        //   { field: "description", header: "Description" },
        //   { field: "createdAt", header: "Created At" },
        //   { field: "modifiedAt", header: "Modified At" }
        // ];
        this.cols = [
       { field: "code", header: "State Code" },
        // { field: "description", header: "wrfrf" },
        { field: "description", header: "Description" },
        { field: "field1", header: "State" },
        { field: "field2", header: "Region1" },
        { field: "field3", header: "Region2" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       
       if(changes.tableName.currentValue==='general_ledger_code'){
         this.cols = [
        { field: "code", header: "GL Account Number(Code)" },
        { field: "description", header: "GL Description" },
        { field: "field9", header: "BS/IS" },
        { field: "field10", header: "Alm Mapping" },
        { field: "field11", header: "Future Mapping1" },
        { field: "field12", header: "Future Mapping2" },
        { field: "field13", header: "Future Mapping3" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       if(changes.tableName.currentValue==='loan_class_code'){
        this.cols = [
        { field: "code", header: "Loan Class Code" },
        { field: "description", header: "Loan Class Code Bank Description" },
        { field: "field1", header: "Loan Class Name" },
        { field: "createdAt", header: "Created At" },
        { field: "modifiedAt", header: "Modified At" }
        ];
       }

       if(changes.tableName.currentValue==='credit_rating_code'){
        this.cols = [
      { field: "code", header: "Credit Rating Code" },
       { field: "description", header: "Credit Rating" },
       { field: "field1", header: "Credit Rating Substandard" },
       { field: "createdAt", header: "Created At" },
       { field: "modifiedAt", header: "Modified At" }
       ];
      }
      
      if(changes.tableName.currentValue==='country_code'){
         
        this.cols = [
          { field: "code", header: "Country Code" },
          { field: "description", header: "Country Description" },
          { field: "field1", header: "Valid From" }, 
          { field: "field2", header: "Valid To" }, 
          { field: "createdAt", header: "Created At" },
          { field: "modifiedAt", header: "Modified At" }
        ];
       }

         if(changes.tableName.currentValue==='currency_code'){
         
        this.cols = [
          { field: "code", header: "Currency Code" },
          { field: "description", header: "Currency Description" },
          { field: "field1", header: "Currency conversion unit" }, 
          { field: "field2", header: "Valid From" }, 
          { field: "field3", header: "Valid To" }, 
          { field: "createdAt", header: "Created At" },
          { field: "modifiedAt", header: "Modified At" }
        ];
       }

       if(changes.tableName.currentValue==='frequency'){
         
        this.cols = [
          { field: "code", header: "Frequency Code" },
          { field: "description", header: "Frequency Description" },
        ];
       }

      // =======================================================
       //  if(changes.tableName.currentValue==='table_name'){
       //   this.cols = [
       // { field: "code", header: "sfrefrfe" },
        // { field: "description", header: "wrfrf" },
        // { field: "field1", header: "erfer" },
        // { field: "field2", header: "ergetg" },
        // { field: "field3", header: "grbetg" },
        // { field: "field4", header: "aefrrf" },
        // { field: "field4", header: "aefrrf" },
        // { field: "field4", header: "aefrrf" },
        // { field: "field4", header: "aefrrf" },
        // { field: "field4", header: "aefrrf" },
        // { field: "field4", header: "aefrrf" },
        // { field: "createdAt", header: "Created At" },
        // { field: "modifiedAt", header: "Modified At" }
      //   ];
      //  }
      
      //  this.enableEdit = true;
      //  this.checkForEdit() 
     }
   
  
   
  }

  

}