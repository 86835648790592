import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ReportGroupModel } from '../../models/report-group.model';
import { ComputeReportModel } from '../../models/compute-report.models';
import { environment } from 'src/environments/environment';
import { SspApplicationService } from '../../services/ssp-application.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() disabled: boolean=false;
 // @Input() statusIcon: string = 'check';
  @Input() reportList:ReportGroupModel[]=[];
  @Input() permission:any;
  @Input() showsetHomeScree:any;
  dataTransferModel:ReportGroupModel;
  computeReportModel:ComputeReportModel;
  visiblePopUp:boolean=false;
  visibleDesignPopUp:boolean=false;
  visibleRunReportPopUp:boolean=false;
  visibleDataFiltersPopUp:boolean=false;
  visibleLogsPopUp:boolean=false;
  headerString:string=null;
  showReportInfo:boolean=false;
  showWorkflowInfo:boolean=false;
  isView:boolean=false;
  showDesignInfo:boolean=false;
  @Output() viewRepotPage=new EventEmitter;
  @Output() closePanel=new EventEmitter();
  @Output() viewReportMappedWorkflow=new EventEmitter();
  @Output() viewReportMappedLogs=new EventEmitter();
  //@Output() showRunReport=new EventEmitter();
  @Output() getreportMappedWorkflows=new EventEmitter();
  designurl:string;
  @Output() dataFilterMapped=new EventEmitter();
  @Input() state:string;
  constructor(private router: Router,private sspapplicationService:SspApplicationService,private toast:ToastService) { }

  ngOnInit(): void {
  
  }

  viewReportList(data){
    console.log("here");
      this.dataTransferModel={
        clientId:data.clientId,
        clientName:data.clientName,
        appId:data.appId,
        appName:data.appName,
        groupId:data.groupId,
        groupName:data.groupName,
        instanceId:data.instanceId,
        instanceName:data.instanceName,
        instanceUrl:data.instanceUrl,
        reportHeading:data.reportHeading,
        asOfDate:data.asofdate

      }

      this.viewRepotPage.emit(this.dataTransferModel);
    }

    hidePopup(){
     // this.dataValue=null;
      this.visiblePopUp=false;
      this.visibleDesignPopUp=false;
      this.visibleRunReportPopUp=false;
      this.visibleDataFiltersPopUp=false;
      this.visibleLogsPopUp=false;
    }

    showInfoPopup(data,rowData){
     // this.dataValue=data;
     this.showReportInfo=false;
     this.showWorkflowInfo=false;
     if(data==='reportInfo'){
       this.headerString="Report Info";
       this.showReportInfo=true;
     }else if(data==='runReport'){
      //  this.headerString="Run Report";
      //  this.visibleRunReportPopUp=true;
      this.dataTransferModel={
        clientId:rowData.clientId,
        clientName:rowData.clientName,
        appId:rowData.appId,
        appName:rowData.appName,
        groupId:rowData.groupId,
        groupName:rowData.groupName,
        instanceId:rowData.instanceId,
        instanceName:rowData.instanceName,
        instanceUrl:rowData.instanceUrl,
        reportHeading:rowData.rowData,
        asOfDate:data.asofdate
      }

      this.getreportMappedWorkflows.emit(this.dataTransferModel);
     }else if(data==='workflow'){
      //  this.headerString="Workflow";
      //  this.visiblePopUp=true;
      console.log("data  ",data);
      this.dataTransferModel={
        clientId:rowData.clientId,
        clientName:rowData.clientName,
        appId:rowData.appId,
        appName:rowData.appName,
        groupId:rowData.groupId,
        groupName:rowData.groupName,
        instanceId:rowData.instanceId,
        instanceName:rowData.instanceName,
        instanceUrl:rowData.instanceUrl,
        reportHeading:rowData.reportHeading,
        asOfDate:data.asofdate
        
      }
      console.log("dataTransferModel ",this.dataTransferModel)
      this.viewReportMappedWorkflow.emit(this.dataTransferModel);
     }
     else if(data==='design-report'){
       console.log("data  ",data)
       if(rowData.reportId!=null){
       
        this.designurl=environment.novaWorkspaceUrl+rowData.workspaceId+"/edit/"+rowData.reportId;
        window.open(this.designurl);
       }
       else{
      this.headerString="Design Report";
      this.visibleDesignPopUp=true;
       }
    }
    else if(data==='dataFilter'){
      // this.headerString="Data & Filters";
      // this.visibleDataFiltersPopUp=true;
      console.log("rowData ",rowData);
      this.dataFilterMapped.emit(rowData);
    }
    else if(data==='logs'){
      // this.headerString="Logs";
      // this.visibleLogsPopUp=true;
      this.dataTransferModel={
        clientId:rowData.clientId,
        clientName:rowData.clientName,
        appId:rowData.appId,
        appName:rowData.appName,
        groupId:rowData.groupId,
        groupName:rowData.groupName,
        instanceId:rowData.instanceId,
        instanceName:rowData.instanceName,
        instanceUrl:rowData.instanceUrl,
        reportHeading:rowData.rowData,
        asOfDate:data.asofdate
        
      }
      this.viewReportMappedLogs.emit(this.dataTransferModel);
    }
      
  
    }
    
    setHome(data){
      console.log("here");
      const appId=data.appId;
      console.log("App Id:",appId);
  
       this.sspapplicationService.setHome(appId).subscribe(
         res=>{
           console.log("Response:",res);
           
          if(typeof res.success !='undefined' && res.success != null){
            this.toast.showSuccess(res.success, "success");
           }
          else{
            console.log("Error message:",res);
            this.toast.showError(res.error, "error");
          }
          
        
          }
          );
          
          // this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          // this.router.navigate(['home-screen']);
        // }); 
      }

      computeReport(data){
        console.log("here");
        console.log("Log ClientId:",data.clientId);
        
        this.computeReportModel={
          clientId:139,
          appId:data.appId
        }
       // console.log(this.computeReportModel);
        
        //const clientId=data.clientId;

        console.log("computeReport model:",this.computeReportModel);
        //console.log("Client Id:",clientId);
    
         this.sspapplicationService.computeReportData(this.computeReportModel).subscribe(
           res=>{
             console.log("Response:",res);   
             if(res){
               this.toast.showSuccess("Compute Report succesfully","");
             } else{
              this.toast.showError("Compute Report fail !","");
              
             }                          
            }
            
            );
                       
        }

        buttonsCheck(reportList) {
          const data = {
            appId: reportList.appId,
            appName: reportList.appName
          };
          this.sspapplicationService.isView(data).subscribe(
            res => {
              this.isView=Boolean(res);
            }, err => {
              // Nothing
            }
          )
        }
  }
