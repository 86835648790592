import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { DbConnDataModel } from 'src/app/dashboard/models/dbconnection.model';

@Component({
  selector: 'app-db-conn-selector',
  templateUrl: './db-conn-selector.component.html',
  styleUrls: ['./db-conn-selector.component.scss']
})
export class DbConnSelectorComponent implements OnInit {

  dbConnForm: FormGroup = null;
  @Input() editDbConnection:DbConnDataModel;
  @Output() saveDbConn= new EventEmitter();
  @Output() closePanel=new EventEmitter();

  enableEdit: boolean;

  constructor(private toast:ToastService) {
    this.dbConnForm = new FormGroup({
      serviceName: new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(50)]),
      databaseType: new FormControl(null),
      connectionUrl: new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(500)]),
      username: new FormControl(null,{ validators: [Validators.required]}),
      password: new FormControl(null),

    });


  }

  dataBaseType= [
    { key: 'MySql', value: "MySql" },
    { key: 'Oracle', value: "Oracle" },
    { key: 'MSSql Server', value: "MSSql Server" },
    { key: 'Postgresql', value: "Postgresql" },


  ];

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {


    if (changes && changes.editDbConnection && changes.editDbConnection.currentValue) {
     console.log(changes.editDbConnection.currentValue,' indise changes')
       const lookUp=changes.editDbConnection.currentValue;

       this.enableEdit = true;
       this.checkForEdit()
    }

  }

  checkForEdit() {

      this.dbConnForm.patchValue({
        serviceName:this.editDbConnection.serviceName,
        databaseType:{key:this.editDbConnection.connectionType,value:this.editDbConnection.connectionType},
        connectionUrl:this.editDbConnection.connectionUrl,
        // username:this.editDbConnection.username,
        // password:this.editDbConnection.password,
      });


  }



  saveData(){
    console.log("dbConnForm  ",this.dbConnForm)

    if(this.dbConnForm.invalid){
      if(this.dbConnForm.controls.username.invalid){
        this.toast.showWarn('username mandatory & should be in email format','error');
        this.closePanel.emit();
        return;
      }
      this.toast.showWarn('All fields are mandatory ','error');
      this.closePanel.emit();
      return;
    }

    if(this.dbConnForm.value.serviceName===null || this.dbConnForm.value.databaseType===null || this.dbConnForm.value.connectionUrl===null || this.dbConnForm.value.username===null || this.dbConnForm.value.password===null){
      this.toast.showError("Please Fill Required Fields","error");
      this.closePanel.emit();
    }
   
    const dbConnectionData={
      "dbConnectionId":this.editDbConnection!=null?this.editDbConnection.dbConnectionId:0,
      "serviceName":this.dbConnForm.controls.serviceName.value,
      "databaseType":this.dbConnForm.controls.databaseType.value.value,
      "connectionUrl":this.dbConnForm.controls.connectionUrl.value,
      "username":this.dbConnForm.controls.username.value,
      "password":this.dbConnForm.controls.password.value,
    }

    this.saveDbConn.emit(dbConnectionData);
    this.closePanel.emit();
  }

}
