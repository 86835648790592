import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { DbConnHeaderComponent } from 'src/app/dbconnenctionComp/db-conn-header/db-conn-header.component';
import { DbConnSelectorComponent } from 'src/app/dbconnenctionComp/db-conn-selector/db-conn-selector.component';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { DbConnDataModel } from '../../models/dbconnection.model';
import { ClientService } from '../../services/client.service';
import { DbconnectionService } from '../../services/dbconnection.service';

@Component({
  selector: 'app-dbconnection-master',
  templateUrl: './dbconnection-master.component.html',
  styleUrls: ['./dbconnection-master.component.scss']
})
export class DbconnectionMasterComponent implements OnInit {

  @ViewChild(DbConnSelectorComponent, { read: false, static: false })
  dbConnSelectorComponent: DbConnSelectorComponent;

  @ViewChild(DbConnHeaderComponent, { read: false, static: false })
  dbConnHeaderComponent:DbConnHeaderComponent;

  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  dbConnectionDataList:DbConnDataModel[]=[];
  dbConnDataModel:DbConnDataModel=null;
  dbConnMainForm: FormGroup = null;
  clientsList:any[];
  companyName:String;
  companyId:any;
  adminFlag:String;
  searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String | number, header:String }[];
  activeRoute$:Subscription;
  state:string;
  menu:string;
  submenu:string;
  constructor(private dbconnectionService:DbconnectionService,
    private clientService: ClientService,
    private loader: LoaderService,
    private fileService: FileService,
    private cookieService: CookieService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private toast: ToastService,
    ) {

    this.dbConnMainForm = new FormGroup({
      company: new FormControl(null),


    });

    this. searchByCols = [
      { field: "serviceName", header: "Service Name" },
      { field: "connectionType", header: "Database" },
      { field: "connectionUrl", header: "Connection Url" },
      
    ];
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag"); 
   }

  ngOnInit(): void {
    this.setTitle();
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );

   this.loadData();
   this.adminFlag=this.cookieService.get("adminFlag");
   this.getClients();
    //this.getAllDbCoonByComapnyId(1);

    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
        for(var i=0;i<data.length;i++){
          console.log("data  ",data[i].clientId)
          this.companyId=data[i].clientId
          this.companyName=data[i].clientName
        }
        this.dbConnMainForm.get("company").setValue({
          key: this.companyId,
          value: this.companyName
        });

        this.getAllDbCoonByComapnyId( this.dbConnMainForm.controls.company.value.key);

      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
      );



  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

onLoad()
{
 
  console.log(" companyId : ", this.dbConnMainForm.controls.company.value.key);
  this.companyName= this.dbConnMainForm.controls.company.value.value;
  this.getAllDbCoonByComapnyId( this.dbConnMainForm.controls.company.value.key);
  this.panelFilter.toggleSlider();
}

  fetchAllDbCoonection(data){
    console.log("data :",data);
    this.getAllDbCoonByComapnyId(data.key);
  }

   getAllDbCoonByComapnyId(companyId){
     const requestModel={
       "companyId":companyId
     }
      this.dbconnectionService.getAllDbConnectionListByCompanyId(requestModel)
      .subscribe(
        data=>{
       this.dbConnectionDataList=data;
        },
        error=>{

        }
      )
   }

   saveData(data){
     console.log("main comp ",data)
     const dbconnData={
      "dbConnectionId":data.dbConnectionId,
      "companyId":this.dbConnMainForm.controls.company.value.key,
      "serviceName":data.serviceName,
      "connectionType":data.databaseType,
      "connectionUrl":window.btoa(data.connectionUrl),
      "username":window.btoa(data.username),
      "password":window.btoa(data.password),

     }

     this.dbconnectionService.saveDbConnection(dbconnData)
     .subscribe(
       data=>{
             console.log("data saved ",data);
             this.dbConnDataModel=null;
           //  this.dbConnSelectorComponent.dbConnForm.reset();
             this.toast.showSuccess('success','Connection Created Successfully'); 
             this.dbConnHeaderComponent.createNewDBCon();
             this.getAllDbCoonByComapnyId(this.dbConnMainForm.controls.company.value.key)

       },
       err=>{
        this.toast.showError(err.error.message, "error");

       }
     )
   }

   getConnectionByID(data){

    this.dbconnectionService.getByConnectionId(data)
    .subscribe(
      data=>{
         this.dbConnDataModel=data;
      }
    )

   }

   editDbConnection(data){
     console.log("edit data======@@@@@@@@@ ",data);
     this.dbConnDataModel=data;
    this.dbConnHeaderComponent.panelForm.toggleSlider();
   }

   loadData(){
     console.log("get db connn");
     this.dbconnectionService.loadData("string")
     .subscribe(
       (data)=>{
         console.log("data");
       }
     )
   }

   getClients() {
    this.loader.showLoader();
    this.clientService.getAllClients().subscribe(
      (data) => {
        this.loader.hideLoader();
        this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
      },
      (err) => {
        this.loader.hideLoader();
        this.toast.showError(err.error.message, "error");
      }
    );
  }

  onClickForm(){

    this.panelForm.toggleSlider();
  }
  onClickFilter(){

    this.panelFilter.toggleSlider();
  }

}
