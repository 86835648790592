import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { StressScenarioModel } from "../models/stressScenario.model";

@Injectable({
    providedIn: 'root'
  })
export class StressScenarioService {
  
  
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  saveOrUpdate(data){
    return this.http.post<StressScenarioModel[]>(environment.baseUrl + "/apis/createStressScenario",data);
} 


getAllStressScenarioList(){
  return this.http.get<StressScenarioModel[]>(environment.baseUrl + "/apis/getAllStressScenarios");
  // return this.http.get<DepartmentModel[]>(environment.baseUrl + "/apis/department/getDepartmentAll");
}

deleteStressScenarioById(stressScenarioId) {
    return this.http.delete<StressScenarioModel[]>(environment.baseUrl + "/apis/deleteStressScenarioId/"+stressScenarioId);
  }




  }

