import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import {trim} from 'lodash';

@Component({
  selector: 'app-job-list-selector',
  templateUrl: './job-list-selector.component.html',
  styleUrls: ['./job-list-selector.component.scss']
})
export class JobListSelectorComponent implements OnInit {

  @Input() jobTypeValue;
  @Input() editJob;
  @Input() clientMasterList;
  @Output() saveJob=new EventEmitter();
  @Output() resetForm=new EventEmitter();
  @Input()  rolePermission;
  enableEdit: boolean;
  @Output() closePanel=new EventEmitter();
  jobSelectorForm: FormGroup = null;
  constructor(private toast:ToastService) { 
    this.jobSelectorForm = new FormGroup({
      jobName: new FormControl(null),
      description: new FormControl(null),
      masterFile:new FormControl(null),
      apiMethod:new FormControl(null)
    });
  }

  apiMethodList=[
    { key: "POST", value: "POST" },
    { key: "GET", value: "GET" }
 
  ]


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.jobTypeValue && changes.jobTypeValue.currentValue) {
      console.log(changes.jobTypeValue.currentValue,' indise table changes') 
       // const lookUp=changes.editLookUp.currentValue;
      
       this.jobSelectorForm.reset();
      
      
     }
   
    if (changes && changes.editJob && changes.editJob.currentValue) {
       console.log(changes.editJob.currentValue,' indise changes') 
       const lookUp=changes.editJob.currentValue;
       
       this.enableEdit = true;
       this.checkForEdit() 
    }
   
  }

  checkForEdit() {
    if (this.editJob && this.enableEdit) {
     
     
     

      if(this.jobTypeValue==='Ingest'){
       // console.log("Ingest")
       
        this.jobSelectorForm.patchValue({
          jobName: this.editJob.jobName,
          description: this.editJob.description,
          masterFile:{key:this.editJob.masterFileId,value:this.editJob.masterFile},
        });
      }

      if(this.jobTypeValue==='Custom'){
        console.log("custom")
        this.jobSelectorForm.patchValue({
          jobName: this.editJob.jobName,
          description: this.editJob.description,
          apiMethod:{key:this.editJob.apiMethod,value:this.editJob.apiMethod}
        });
      }
      
      
     
    }
  }

  saveJobData(){
    this.closePanel.emit();
    let masterFileId=0;
  // console.log("job data ",this.jobSelectorForm.controls.masterFile.value.key)
   const jobName=this.jobSelectorForm.controls.jobName.value;
   if(this.jobTypeValue===null || this.jobTypeValue===undefined){
    this.toast.showInfo('error','job type is required');
    return;
   }
   
   if(this.jobSelectorForm.controls.jobName && this.jobSelectorForm.controls.jobName.value===null){
      this.toast.showInfo('error','job name is required');
      return;
   }

   if(this.jobSelectorForm.controls.jobName && this.jobSelectorForm.controls.jobName.value!==null && trim(this.jobSelectorForm.controls.jobName.value).length===0){
    this.toast.showInfo('error','job name is required');
    return;
 }
   if(this.jobTypeValue==='Ingest'){
    if(this.jobSelectorForm.controls.masterFile && this.jobSelectorForm.controls.masterFile.value===null ){
      this.toast.showInfo('error','Master File is required');
      return;
      }
    if(this.jobSelectorForm.controls.masterFile && this.jobSelectorForm.controls.masterFile.value.key===undefined ){
    this.toast.showInfo('error','Master File is required');
    return;
     }

     if(this.jobSelectorForm.controls.masterFile && this.jobSelectorForm.controls.masterFile.value.key===0 ){
      this.toast.showInfo('error','Master File is required');
      return;
       }
      //console.log("value ",this.jobSelectorForm.controls.masterFile.value)
      masterFileId=this.jobSelectorForm.controls.masterFile.value.key;
   }
  //console.log('this.jobSelectorForm.controls.apiMethod' ,this.jobSelectorForm.controls.apiMethod);
   const jobData={
    "jobName":this.jobSelectorForm.controls.jobName.value,
    "description":this.jobSelectorForm.controls.description.value,
    "masterFileId":masterFileId,
    "apiMethod":this.jobSelectorForm.controls.apiMethod.value!==null?this.jobSelectorForm.controls.apiMethod.value.value:'GET'
  }

  this.saveJob.emit(jobData);
  this.jobSelectorForm.reset();
  
    
  }

  resetFormData(){
    this.jobSelectorForm.reset();
    this.resetForm.emit("reset");
  }

 

}
