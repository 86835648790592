import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { DepartmentModel } from 'src/app/dashboard/models/department.model';

@Component({
  selector: 'app-department-selector',
  templateUrl: './department-selector.component.html',
  styleUrls: ['./department-selector.component.scss']
})
export class DepartmentSelectorComponent implements OnInit {

  @Output() save=new EventEmitter();
 
  @Output() closePanel=new EventEmitter();
  departmentForm: FormGroup = null;
  @Input() editData:DepartmentModel;
  constructor(private toast:ToastService,private datePipe: DatePipe) {
    this.departmentForm=new FormGroup({
      departmentId:new FormControl(null),
      departmentName: new FormControl(null,[Validators.required,Validators.minLength(5),Validators.maxLength(250)]),
      statusMap: new FormControl(null,{validators:[Validators.required]}),
      description:new FormControl(null,[Validators.required,Validators.minLength(5),Validators.maxLength(250)]),
      validFromDate:new FormControl(null,{validators:[Validators.required]}),
      validToDate:new FormControl(null,{validators:[Validators.required]}),
      
    });
  
   }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.departmentForm.get('statusMap').setValue(defaultMap);
  }

  ngOnChanges(changes: SimpleChanges) {
    
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);
      this.departmentForm.patchValue(
        {departmentId:rowData.departmentId,
          departmentName:rowData.departmentName,
           description:rowData.description,
           validFromDate:this.datePipe.transform(rowData.validFromDate,"yyyy-MM-dd"),
           validToDate:this.datePipe.transform(rowData.validToDate,"yyyy-MM-dd")
          
        });
        
          const map1 = {
            key: "Active",
            value: "Active",
          };
        
          const map2 = {
            key: "Inactive",
            value: "Inactive",
          };
        
        rowData.status==true? this.departmentForm.controls.statusMap.setValue(map1):this.departmentForm.controls.statusMap.setValue(map2);

          
            

          
    }
  
  }
  

  // onValueChanged(data?: any) {
  //   if (!this.clientForm) { return; }
  //   const form = this.clientForm;
  //   for (const field in this.formErrors) {
  //    // clear previous error message (if any)
  //    this.formErrors[field] = '';
  //    const control = form.get(field);
  //    if (control && control.invalid && control.dirty) {
  //      const messages = this.validationMessages[field];
  //      for (const key in control.errors) {
  //       // this.formErrors[field] += messages[key] + ' ';
  //        this.errorMessage=messages;
  //         this.toast.showError('error',this.errorMessage);
  //         console.log(this.errorMessage);
  //       }
  //     }
  //    }
  //  }

 

  saveDepartment(){
    if(this.departmentForm.invalid){
      if(this.departmentForm.controls.departmentName.invalid){
        this.toast.showWarn('Department Name is mandatory','error');
        this.closePanel.emit();
        return;
      }
    }
  console.log("this.departmentForm.value ",this.departmentForm.value)
  let statusMap=this.departmentForm.controls.statusMap.value
  console.log("statusMap",statusMap)
  if (statusMap=="Select Status *") {
    this.toast.showError("Please Fill Required Fields","error");
  }
  if(statusMap!=="Select Status *"){

  let validate=this.validationOnDate(this.departmentForm.controls.validFromDate.value,this.departmentForm.controls.validToDate.value);
  if(validate===true){
    console.log("this.glHeadform on save.value ",this.departmentForm.value)
    if(this.departmentForm.valid){ 
      this.save.emit(this.departmentForm.value);
        }
          else{
            this.toast.showError("Please Fill Required Fields","error");
        }

  }

  if(validate===false){
    this.toast.showWarn('error','date range incorrect')
    
  }
}
  this.closePanel.emit();
    }

    formReset(){
      this.departmentForm.reset();
     
      
      const defaultMap = {
       key: "Active",
       value: "Active",
     };
    this.departmentForm.get('statusMap').setValue(defaultMap);
    }

    


    get status() {
      const status = [
        "Active",
        "Inactive",
      ];
     return status.map((t) => ({ key: t, value: t }));
    }

    validationOnDate(validFromDate,validToDate){
      if(validFromDate && validToDate){
      if(new Date(validFromDate)>new Date(validToDate)){
      
          return false;
        }
        return true;
        
      }else{
        return false;
      }
    }



}
