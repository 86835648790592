import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rwapercent-table',
  templateUrl: './rwapercent-table.component.html',
  styleUrls: ['./rwapercent-table.component.scss']
})
export class RwaPercentTableComponent implements OnInit {
 @Input() rwaPercentList:any[];
 @Input() rolePermission:any;
 @Output() edit = new EventEmitter();
 @Output() delete = new EventEmitter();
 dataValue:any;
 visibleDelete: boolean = false;


  constructor() { }

  ngOnInit(): void {
  }

  editRwaPercent(rowData){
  this.edit.emit(rowData);
  }


  showPopupDelete(rowData) {
        this.dataValue = rowData;
        this.visibleDelete = true;
      }
    
      hidePopup() {
        debugger;
        this.dataValue = null;
        this.visibleDelete = false;
      }
    
      actionDo(str){
        this.deleteRwaPercentId(this.dataValue);
        this.dataValue=null;
        this.visibleDelete=false;
      }
    
      deleteRwaPercentId(rowData){
        this.delete.emit(rowData);
      }
}
