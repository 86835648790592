import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { TitleService } from "src/app/core/services/title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { FormGroup, FormControl } from "@angular/forms";
import { FileService } from "src/app/files/services/file.service";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { environment } from "src/environments/environment";
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { currentMonthRange } from "src/app/utils/dates";
import { CookieService } from "ngx-cookie-service";
import { Store } from "@ngrx/store";
import { TransformPermission } from "../../models/transformPermission.model";
import { ToastService } from "src/app/core/services/toast.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { ClientService } from "../../services/client.service";
import { SidePanelComponent } from "src/app/ui/components/side-panel/side-panel.component";


@Component({
  selector: "app-transform",
  templateUrl: "./transform.component.html",
  styleUrls: ["./transform.component.scss"],
})
export class TransformComponent implements OnInit, OnDestroy {
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;

  activeRoute$: Subscription;
  showBackButton:boolean=false;
  dataTransferModel:DataTransferModel;
  pageName:string;
  headerForm = new FormGroup({
    client: new FormControl(),
    // application: new FormControl(),
  });
  envUrl: string = null;
  jobId:any=null;
  applicationList: { key: string | number; value: string }[];
  userId:any=1;
  transformPermission:TransformPermission;
  clientsList:any[];
  company:String;
  applicationName:String;
  clientId:any;
  adminFlag:String;
  state:string;
  constructor(
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private fileService: FileService,
    private router: Router,
    public observalbleDataService:ObservalbleDataService,
    private cookieService: CookieService,
    private clientService: ClientService,
    private loader: LoaderService,
    private toast:ToastService,
    private store: Store<{ user: any }>
  ) {
    this.state=localStorage.getItem("stateflag");
  }

  ngOnInit(): void {
    this.setTitle();
    this.getClients();
   // this.getPreviousState();
   this.adminFlag=this.cookieService.get("adminFlag");
   console.log("adminFlag   ",this.adminFlag)

   // this.formatUrl();
   
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
        if (par.has("clientId") && par.has("clientName")) {
          this.headerForm.get("client").setValue({
            key: par.get("clientId"),
            value: par.get("clientName")
          });
          console.log("par.getclientName ",par.get("clientName"))
          this.company=par.get("clientName");
         
        }
      //   if ( par.has("applicationId") && par.has("applicationName")) {
     
         
      //     const applicationData = {
      //       key: par.get("applicationId"),
      //       value: par.get("applicationName")
      //     };
      //     this.headerForm.get("application").setValue(applicationData);
      //     this.applicationName=par.get("applicationName");
        
      // }
      if ( par.has("jobId") ) {
     
        this.jobId= par.get("jobId")   
        
    }
    if(par.has("pageName")){
      this.pageName=par.get("pageName")
      this.showBackButton=true;
    }
    },
      err => {}
    );
    if(this.showBackButton===false){
      this.getPreviousState();
    }

   this.fileService.getClientByUserId().subscribe(
    (data) =>{
    
      for(var i=0;i<data.length;i++){
        console.log("data  ",data[i].clientId)
        this.clientId=data[i].clientId
        this.company=data[i].clientName
      }
      this.headerForm.get("client").setValue({
        key: this.clientId,
        value: this.company
      });
      
    console.log("clientId  ",this.clientId)
      this.formatUrlByRoute(this.clientId);
    },
   (err) => {
     this.toast.showError(err.error.message, "error");
   
   }
   );

   // this.formatUrlByRoute();
    this.fileService
      .getAllApplicationByUser()
      .subscribe(
        (data) =>
          (this.applicationList = reMapForAutoComplete(
            data,
            "applicationId",
            "name"
          )),
          (err) => {
            this.toast.showError(err.error.message, "error");
          
          }
      );
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  formatUrlByRoute(clientId) {
   if(this.transformPermission==undefined || this.transformPermission.viewTransform){
    this.userId=this.cookieService.get("userId");
    let data=null;
    this.store.select("user").subscribe((u) => {
        data=u;
      });
    // if(this.userId===undefined || this.userId===null){
    //   this.userId=1;
    // }
    //const { client, application } = this.headerForm.value;
    // let id = client && client.key ? client.key : null;
    // let id = clientId ? clientId : null;
    // if(clientId===0){
    //       id=0;
    // }
    //let app = application && application.key ? application.key : null;
    // if(application && application.key===0){
    //   app=0;
    //   }
   let app=0;
   let id=clientId;
   const jobid=this.jobId!=null?'/'+this.jobId:""
    const url = `${environment.baseUrl}/etl${jobid}?client_id=${id}&application=${app}&version=1&user=${this.userId}&token=${data.token}`;
    this.envUrl = url; 
    console.log(this.envUrl+' env url check')
  }else{
    this.toast.showError("User is not Authorised to perform this action. Please contact to Admin",'error');
  }
}

  formatUrl(event, loadType) {

    console.log("enter into function")
    if(this.transformPermission==undefined || this.transformPermission.viewTransform){
    //const { client, application } = this.headerForm.value;
    const { client} = this.headerForm.value;
    this.userId=this.cookieService.get("userId");
    let data=null;
    this.store.select("user").subscribe((u) => {
        data=u;
      });      
    
      if(this.userId===undefined || this.userId===null){
      this.userId=1;
    }
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    let id = client && client.key ? client.key : null;
    if(client && client.key===0){
          id=0;
    }
    // let app = application && application.key ? application.key : null;   
    // if(application && application.key===0){
    //   app=0;
    //   }
    let app=0;
    const url = `${environment.baseUrl}/etl?client_id=${id}&application=${app}&version=1&user=${this.userId}&token=${data.token}`;
    this.envUrl = url; 
    console.log(this.envUrl+' env url');
    
    // if(loadType!=='onLoad'){
    //   this.maintainClientState(event);
    // }
    }else{
      this.toast.showError("User is not Authorised to perform this action. Please contact to Admin",'error');
    }
  }

 
  ngOnDestroy() {
    try{
      this.activeRoute$.unsubscribe();
    }catch(ex){
      
    }
    
  }
  goToPage(){
    if(this.showBackButton){
      this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
       this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
      this.dataTransferModel={
             clientFileId:0,
             clientId: this.headerForm.controls.client.value.key,
             isRedirected:1,
             applicationId:0,
             toDate:null,
             fromDate:null,
             clientName:this.headerForm.controls.client.value.value,
             page:this.pageName,
             fileName:null,
             workflowName:null,
            workflowId:0,
            dataType:null,
            dataTypeId:0
      }
     // console.log(this.dataTransferModel);
      this.observalbleDataService.addToInventory(this.dataTransferModel);
      this.router.navigate([this.pageName]);
    
    }
  }

  maintainClientState(client){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  getPreviousState() {
    const userId=this.cookieService.get('userId');
    console.log("userId identify ",userId)
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
               const clientModel = {
                        key: this.headerForm.controls.client.value.key,
                        value: this.headerForm.controls.client.value.value
                      }
                      this.formatUrl(clientModel, 'onLoad');
    }

    
  }

  fetchCompanyAppName(){
  
    this.formatUrl(event,event);
    this.company=this.headerForm.controls.client.value.value;
    //this.applicationName=this.headerForm.controls.application.value.value;
    this.panelFilter.toggleSlider();

  }

  getClients() {
    this.loader.showLoader();
    this.clientService.getAllClients().subscribe(
      (data) => {
        this.loader.hideLoader();
        this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
      },
      (err) => {
        this.loader.hideLoader();
        this.toast.showError(err.error.message, "error");
      }
    );
    
  }

}
