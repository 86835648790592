import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { InterestRateRiskScenarioTableComponent } from './components/interestRateRiskScenario-table/interestRateRiskScenario-table.component';
import { InterestRateRiskScenarioSelectorComponent } from './components/interestRateRiskScenario-selector/interestRateRiskScenario-selector.component';
import { InterestRateRiskScenarioHeaderComponent } from './components/interestRateRiskScenario-header/interestRateRiskScenario-header.component';


@NgModule({
  declarations: [
    InterestRateRiskScenarioSelectorComponent,
    InterestRateRiskScenarioTableComponent,
    InterestRateRiskScenarioHeaderComponent
    
  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    InterestRateRiskScenarioSelectorComponent,
    InterestRateRiskScenarioTableComponent,
    InterestRateRiskScenarioHeaderComponent
    ]
})
export class InterestRateRiskScenarioCompModule { }
