import { NgModule } from "@angular/core";

import { DashboardRoutingModule } from "./dashboard-routing.module";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { SharedModule } from "../shared/shared.module";
import { UiModule } from "../ui/ui.module";
import { ExcelModule } from "../excel/excel.module";
import { OrganizationOpsComponent } from "./pages/organization-ops/organization-ops.component";
import { TransformComponent } from "./pages/transform/transform.component";
import { ClientFileComponent } from "./pages/client-file/client-file.component";
import { ClientService } from "./services/client.service";
import { MappingComponent } from "./pages/mapping/mapping.component";
import { FilesModule } from "../files/files.module";
import { RulesComponent } from "./pages/rules/rules.component";
import { IngestionRulesModule } from "../ingestion-rules/ingestion-rules.module";
import { TransformListComponent } from "./pages/transform-list/transform-list.component";
import { FileLayoutComponent } from "./pages/file-layout/file-layout.component";
import { ClientComponent } from './pages/client/client.component';
import { LayoutFileListComponent } from './pages/layout-file-list/layout-file-list.component';
import { UserComponent } from './pages/user/user.component';
import { UserModule } from '../user/user.module';
import { LookupComponent } from './pages/lookup/lookup.component';
import { LookupService } from './services/lookup.service';
import { DatePipe } from '@angular/common';
import { LookUpTableModule } from '../lookup-tables/lookup-tables.module';
import {UserCompModule} from '../userComp/userComp.module';
import { RolesComponent } from './pages/roles/roles.component';
import { DualListModule } from '../roles-dual/roles-dual.module';
import { ChangeLogComponent } from './pages/change-log/change-log.component';
import { LookupActionsComponent } from './pages/lookup-actions/lookup-actions.component';
import { WorkflowSetupComponent } from './pages/workflow-setup/workflow-setup.component';
import { WorkflowModule } from '../workflows/workflow.module';
import { SetupCustomComponent } from './pages/setup-custom/setup-custom.component';
import { WorkflowComponent } from './pages/workflow/workflow.component';
import { WorkflowJobComponent } from './pages/workflow-job/workflow-job.component';
import { ListingComponent } from './pages/listing/listing.component';
import {ListingModule} from '../listingComp/listing.module';
import { LookupColumnComponent } from './pages/lookup-column/lookup-column.component';
import { LookupColumnModule } from '../lookupColumn/lookupColumn.module';
import { ClientFileMasterComponent } from './pages/client-file-master/client-file-master.component';
import { ClientFileMasterModule } from '../client-master/clientFileMaster.module';
import { SetupJobComponent } from './pages/setup-job/setup-job.component';
import { DeleteDataComponent } from './pages/delete-data/delete-data.component';
import { ClientPortalDataComponent } from './pages/client-portal-data/client-portal-data.component';
import { ClientPortalModule } from '../client-portal/client-portal.module';
import { ClientPortalFormDataComponent } from './pages/client-portal-form-data/client-portal-form-data.component';
import { ArchiveFileComponent } from './pages/archive-file/archive-file.component';
import { CashflowListComponent } from './pages/cashflow-list/cashflow-list.component';
import { ValidationRulePageComponent } from './pages/validation-rule-page/validation-rule-page.component';
import { ValidationRulesModule } from '../validation-rules/validation-rules.module';
import { AuditHomeComponent } from './pages/audit-home/audit-home.component';
import { AuditTabModule } from '../audit-tabs/audittab.module';
import { CleanDataConfigComponent } from './pages/clean-data-config/clean-data-config.component';
import { CleanDataConfigModule } from '../cleanDataConfig/cleanDataConfig.module';
import { TransformOutputFilesComponent } from './pages/transform-output-files/transform-output-files.component';
import { StatisticDataModule } from "../statistic-data/statistic-data.module";
import { StatisticsComponent } from "./pages/statistics/statistics.component";
import { WorkflowQueueComponent } from './pages/workflow-queue/workflow-queue.component';
import { WorkflowJobListComponent } from './pages/workflow-job-list/workflow-job-list.component';
import { AddonUnmatchedAcctFilesComponent } from './pages/addon-unmatched-acct-files/addon-unmatched-acct-files.component';
import { SchedulerComponent } from './pages/scheduler/scheduler.component';
import { SchedulerCompModule } from "../schedulerComp/scheduler-comp.module";
import { RoleListComponent } from './pages/role-list/role-list.component';
import { RolelistCompModule } from "../rolelistComp/rolelist-comp.module";
import { SchedulerListComponent } from './pages/scheduler-list/scheduler-list.component';
import { AccordionModule } from 'primeng/accordion';
import { RoleMenuComponent } from './pages/role-menu-permission/role-menu-permission.component';
import { FormsModule } from "@angular/forms";
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { NotificationCompModule } from "../notificationComp/notification-comp.module";
import { ReportDashboardComponent } from './pages/report-dashboard/report-dashboard.component';
import { ZohoAnalyticsComponent } from './pages/zoho-analytics/zoho-analytics.component';
import { GenericFileUploadComponent } from "./pages/generic-file-upload/generic-file-upload.component";
import { GenericDataModule } from "../generic-data/generic-data.module";
import { ExportReportComponent } from './pages/export-report/export-report.component';
import { MultiSelectModule } from "primeng/multiselect";
import { DBConnModule } from "../dbconnenctionComp/dbconn.module";
import { DbconnectionMasterComponent } from "./pages/dbconnection-master/dbconnection-master.component";
import { SparkLauncherComponent } from './pages/spark-launcher/spark-launcher.component';
import { MainDashboardComponent } from './pages/main-dashboard/main-dashboard.component';
import { CardComponent } from './components/card/card.component';
import { HomeComponent } from "./pages/home/home.component";
import { ReportsModule } from "../reports/reports.module";
import { MainReportDashboardComponent } from './pages/main-report-dashboard/main-report-dashboard.component';
import { MainExportReportComponent } from './pages/main-export-report/main-export-report.component';
import { ClientCompModule } from "../clientComp/client-comp.module";
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { WorkflowTabbedComponent } from "./pages/workflow-tabbed/workflow-tabbed.component";
import { TableViewerComponent } from "./pages/table-viewer/table-viewer.component";
import { TableViewerService } from "./services/table-viewer.service";
import { DataTransferComponent } from "./pages/data-transfer/data-transfer.component";
import { DataTransferModule } from "../data-transfer/data-transfer.module";
import { IngestTabbedComponent } from "./pages/ingest-tabbed/ingest-tabbed.component";
import { HomeScreenComponent } from './pages/home-screen/home-screen.component';
import { DepartmentComponent } from "./pages/department/department.component";
import { DepartmentCompModule } from "../departmentComp/departmentComp.module";
import { CategoryComponent } from "./pages/category/category.component";
import { CategoryCompModule } from "../categoryComp/categoryComp.module";
import { GlHeadComponent } from "./pages/gl_head/glHead.component";
import { GlHeadCompModule } from "../glHeadComp/glHeadComp.module";
import { ReportHeadComponent } from "./pages/report-head/reportHead.component";
import { ReportHeadCompModule } from "../reportHeadComp/reportHeadComp.module";
import { DisplayReportComponent } from './pages/display-report/display-report.component';
import { GlMappingComponent } from './pages/gl-mapping/gl-mapping.component';
import { GlMappingCompModule } from "../glMappingComp/components/gl-mapping-comp.module";
import { ReportConfigurationCompModule } from "../reportConfigurationComp/reportConfigurationComp.module";
import { ReportConfigurationComponent } from "./pages/report-configuration/reportConfigurationComponent.component";
import { VideoUploadPortalComponent } from './pages/video-upload-portal/video-upload-portal.component';
import { VideoUploadCompModule } from "../videoUploadComp/video-upload-comp.module";
import { HelpComponent } from './pages/help/help.component';
import { HelpCardComponent } from './components/help-card/help-card.component';
import { VideoCategoryComponent } from "./pages/video-category/videocategory.component";
import { VideoCategoryCompModule } from "../videoCategoryComp/videoCategoryComp.module";
import { StressTestingComponent } from "./pages/stress-testing/stress-testing.component";
import { HQLAComponent } from "./pages/hqla/hqla.component";
import { StressScenarioCompModule } from "../stressScenarioComp/stressScenarioComp.module";
import { StressScenarioComponent } from "./pages/stress-scenario/stressScenario.component";
import { YeildAdvancesComponent } from "./pages/yeild-advances/yeild-advances.component";
import { RwapercentComponent } from "./pages/rwa/rwapercent.component";
import { RwaPercentCompModule } from "../rwaPercentComp/rwaPercentComp.module";
import { StressScenarioValuesComponent } from "./pages/stress-scenario-Values/stressScenarioValues.component";
import { StressScenarioValuesCompModule } from "../stressScenarioValuesComp/stressScenarioValuesComp.module";
import { ShockMasterCompModule } from "../shockMasterComp/shockMasterComp.module";
import { ShockMasterComponent } from "./pages/shock-master/shockMaster.component";
import { StressKeyMetricsComponent } from "./pages/stress-key-metrics/stressKeyMetrics.component";
import { StressKeyMetricsCompModule } from "../stressKeyMetricsComp/stressKeyMetricsComp.module";
import { SeverityComponent } from "./pages/severity/severity.component";
import { ShockConfigurationComponent } from './pages/shock-configuration/shock-configuration.component';
import { InterestRateRiskScenarioCompModule } from "../interestRateRiskScenarioComp/interestRateRiskScenarioComp.module";
import { InterestRateRiskScenarioComponent } from "./pages/interest-rate-risk-scenario/interestRateRiskScenario.component";
import { MultifactorRiskComponent } from "./pages/multifactor-risk/multifactor-risk.component";
import { FinancialAccountingComponent } from "./pages/financial-accounting/financial-accounting.component";
import { ReportAnalysisComponent } from "./pages/report-analysis/report-analysis.component";
import { ConfigureFiltersComponent } from "./pages/configure-filters/configure-filters.component";
import { HeadLiquidityStatmentMapping } from "./pages/head-liquidity-statment-mapping/head-liquidity-statment-mapping";
import { HeadLiquidityStatementMappingModule } from "../HeadLiquidityStatmentMappingComp/head-liquidity-statement-mapping.module";
import { RateSensitivityStatmentMapping } from "./pages/rate-sensitivity-statment-mapping/rate-sensitivity-statment-mapping";
import { RateSensitivityStatementMappingModule } from "../RateSensitivityStatmentMappingComp/rate-sensitivity-statement-mapping.module";
import { RequestComponent } from "./pages/request/request.component";
import { RequestCompModule } from "../requestComp/requestComp.module";




// import { CreateVariablesComponent } from "../regression/pages/create-variables/create-variables.component";
// import { CreateModelComponent } from "../regression/pages/create-model/create-model.component";

//import { GroupReportParameterComponent } from './components/group-report-parameter/group-report-parameter.component';



@NgModule({
  declarations: [
    MainPageComponent,
    OrganizationOpsComponent,
    TransformComponent,
    ClientFileComponent,
    MappingComponent,
    RulesComponent,
    TransformListComponent,
    FileLayoutComponent,
    ClientComponent,
    LayoutFileListComponent,
    UserComponent,
    LookupComponent,
    RolesComponent,
    ChangeLogComponent,
    LookupActionsComponent,
    WorkflowSetupComponent,
    SetupCustomComponent,
    WorkflowComponent,
    WorkflowJobComponent,
    ListingComponent,
    LookupColumnComponent,
    ClientFileMasterComponent,
    SetupJobComponent,
    DeleteDataComponent,
    ClientPortalDataComponent,
    ClientPortalFormDataComponent,
    ArchiveFileComponent,
    CashflowListComponent,
    ValidationRulePageComponent,
    AuditHomeComponent,
    CleanDataConfigComponent,
    TransformOutputFilesComponent,
    StatisticsComponent,
    WorkflowQueueComponent,
    WorkflowJobListComponent,
    AddonUnmatchedAcctFilesComponent,
    SchedulerComponent,
    RoleListComponent,
    SchedulerListComponent,
    RoleMenuComponent,
    NotificationsComponent,
    ReportDashboardComponent,
    ZohoAnalyticsComponent,
    GenericFileUploadComponent,
    ExportReportComponent,
    DbconnectionMasterComponent,
    SparkLauncherComponent,
    MainDashboardComponent,
    CardComponent,
    HomeComponent,
    MainReportDashboardComponent,
    MainExportReportComponent,
    ChangePasswordComponent,
    WorkflowTabbedComponent,
    TableViewerComponent,
    DataTransferComponent,
    IngestTabbedComponent,
    HomeScreenComponent,
    DepartmentComponent,
    CategoryComponent,
    GlHeadComponent,
    ReportHeadComponent,
    DisplayReportComponent,
    GlMappingComponent,
    VideoUploadPortalComponent,
    ReportConfigurationComponent,
    HelpComponent,
    HelpCardComponent,
    VideoCategoryComponent,
    StressTestingComponent,
    HQLAComponent,
    StressScenarioComponent,
    YeildAdvancesComponent,
    RwapercentComponent,
    StressScenarioValuesComponent,
    ShockMasterComponent,
    StressKeyMetricsComponent,
    SeverityComponent,
    ShockConfigurationComponent,
    InterestRateRiskScenarioComponent,
    MultifactorRiskComponent,
    FinancialAccountingComponent,
    ReportAnalysisComponent,
    ConfigureFiltersComponent,
    // CreateVariablesComponent,
    // CreateModelComponent
    HeadLiquidityStatmentMapping,
    RateSensitivityStatmentMapping,
    RequestComponent
  ],
  imports: [
    SharedModule,
    UiModule,
    //DashboardRoutingModule,
    ExcelModule,
    FilesModule,
    IngestionRulesModule,
    LookUpTableModule,
    UserModule,
    UserCompModule,
    DualListModule,
    WorkflowModule,
    ListingModule,
    LookupColumnModule,
    ClientFileMasterModule,
    ClientPortalModule,
    ValidationRulesModule,
    AuditTabModule,
    CleanDataConfigModule,
    StatisticDataModule,
    SchedulerCompModule,
    RolelistCompModule,
    AccordionModule, // Pradeep Yadav
    FormsModule, // Pradeep Yadav
    NotificationCompModule,
    GenericDataModule,
    MultiSelectModule,
    DBConnModule,
    ReportsModule,
    ClientCompModule,
    DataTransferModule,
    DepartmentCompModule,
    CategoryCompModule,
    GlHeadCompModule,
    ReportHeadCompModule,
    GlMappingCompModule,
    VideoUploadCompModule,
    ReportConfigurationCompModule,
    VideoCategoryCompModule,
    StressScenarioCompModule,
    RwaPercentCompModule,
    StressScenarioValuesCompModule,
    ShockMasterCompModule,
    StressKeyMetricsCompModule,
    InterestRateRiskScenarioCompModule,
    HeadLiquidityStatementMappingModule,
    RateSensitivityStatementMappingModule,
    RequestCompModule

  ],
  providers: [ClientService,LookupService,DatePipe,TableViewerService]
})
export class DashboardModule {}
