import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements AfterViewInit, OnDestroy {
  @Input('position') sliderPosition: string = 'right';
  @ViewChild('slider') slider!: ElementRef<HTMLDivElement>;
  @ViewChild('overlay') overlay!: ElementRef<HTMLDivElement>;

  constructor() {}

  @HostListener('window:click', ['$event'])
  onOverlayClose(event: Event) {
    // this.toggleSlider();
  }

  ngAfterViewInit(): void {
    this.overlay.nativeElement.addEventListener('click', this.toggleSlider);
  }
  toggleSlider = () => {
    this.overlay.nativeElement.classList.toggle('show');
    this.slider.nativeElement.classList.toggle('open');
  };
  ngOnDestroy(): void {
    this.overlay.nativeElement.removeEventListener('click', this.toggleSlider);
  }
}
