import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { ReportFormComponent } from 'src/app/reports/containers/report-form/report-form.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { WorkflowJobTableComponent } from 'src/app/workflows/components/workflow-job-table/workflow-job-table.component';
import { LookUpListModel } from '../../models/lookup.model';
import { GroupModel, ReportGroupModel } from '../../models/report-group.model';
import { WorkflowResponseModel } from '../../models/workflow.model';
import { CategoryService } from '../../services/category.service';
import { DbconnectionService } from '../../services/dbconnection.service';
import { LookupService } from '../../services/lookup.service';
import { ReportDashboardService } from '../../services/report-dashboard.service';
import { SspApplicationService } from '../../services/ssp-application.service';
import { WorkflowJobComponent } from '../workflow-job/workflow-job.component';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit {
  //groupList:ReportGroupModel[]=[];
  @ViewChild(ReportFormComponent, { read: false, static: false })
  reportFormComponent: ReportFormComponent;

  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild("subPanelFilter", {static: false})
  subPanelFilter!:SidePanelComponent;

  @ViewChild(WorkflowJobTableComponent, { read: false, static: false })
  workflowJobTableComponent: WorkflowJobTableComponent;

  @ViewChild(WorkflowJobComponent, { read: false, static: false })
  workflowJobComponent:WorkflowJobComponent;
 
  groupList: {key: any; value: any}[]=[];
  groupListFilter:{key: any; value: any}[]=[]
  valueLists:[];
  activeRoute$: Subscription;
  dataTransformationModel:GroupModel;
  allGroupData: {key: any; value: any }[]=[];
  allGenericData: {key: any; value: any }[]=[];
  allStatusData: {key: any; value: any }[]=[];
  groupName:String;
  rolePermission:any;
  showWorkflowTable:boolean;
  headerForm: FormGroup = null;
  categorySelect:string;
  categorySelectKey:any;
  //allCategoryData: {key: any; value: any }[]=[];
  categoryList:  {key: any; value: any }[]=[];
  notificationList:any=[];
  workflowResponseModel:WorkflowResponseModel[]=[];
  state:string;
  setHomeScreePermission:boolean;
  appListData: {key: any; value: any }[]=[];
  frequencyList: {key: any; value: any }[]=[];
  lookUpModelList: LookUpListModel[] = [];
  asofdate:string=null;
  companyId:number=0;
  currentReportId:number;
  constructor(private activeRoute: ActivatedRoute,
    private dbconnectionService:DbconnectionService,
    private toastService:ToastService,
    private reportDashboardService:ReportDashboardService,private router: Router,private fileService:FileService,
    private sspapplicationService:SspApplicationService,private toast:ToastService,private loader:LoaderService,
    private datePipe:DatePipe,private categoryService:CategoryService, private lookupService: LookupService) {
      this.headerForm = new FormGroup({
        category: new FormControl(null, [Validators.required])
      });

      this.state=localStorage.getItem("stateflag");
      this.companyId=Number(localStorage.getItem("companyId"));
    }

  ngOnInit(): void {
    this.showRunReport();
    this.getAllAppsData();
   this.buttonShowHidePermission();
   this.showSetHomeScreen();
   this.getAllFrequencyType();
  }


  getAllAppsData(){
    this.fileService.getAllApplicationsByUser().subscribe(
      (data) => {
        console.log("Applications",data);
        let tempAppList: any[]=[];
        for(var i=0;i<data.length;i++){
          if(data[i].flag == true){
            tempAppList.push(data[i]);
          }
        }
        this.appListData = reMapForAutoComplete(tempAppList, "applicationId", "name");
        console.log("appListData: ",this.appListData);
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  onLoad() {
    console.log("load work:");
    this.subPanelFilter.toggleSlider();
    if (this.headerForm.valid) {
      this.categorySelect = this.headerForm.controls.category.value.value;
      this.categorySelectKey = this.headerForm.controls.category.value.key;
      this.reportDashboardService.getFilterGroupReportDataByCategoryId(this.categorySelectKey,this.state,this.companyId).subscribe(
        data => {
          this.groupList = data;
          console.log("getFilterGroupReportDataByCategoryId: " + data);
        },
        err => {
          this.toastService.showError("failed to get groups", "error");
        }
      );
    }
  }

  getAllCategory() {
    this.categoryService.getAllCategoryList().subscribe(
      (data) => {
        this.categoryList = reMapForAutoComplete(data, "categoryId", "categoryName");
      }, (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
  }

  getGroupReportData(){

    this.reportDashboardService.getGroupReportData(this.state,this.companyId).subscribe(
      res => {
        // this.loader.hideLoader();

        this.groupList=res;
        console.log("groupList ",this.groupList)


       // console.log(this.groupList," group list data ");
      },
        err => {
         // console.log('url',err);
         this.toastService.showError("failed to get groups", "error");

        }
    )

    }

    navigatePage(data){
     console.log(data);
     this.dataTransformationModel=data;
     this.dataTransformationModel.asOfDate=data.asofdate;
     if(this.asofdate!=null && this.asofdate!=undefined && this.currentReportId==data.appId){
     this.dataTransformationModel.asOfDate=this.asofdate;
     }
     let page = '/report/report-dashboard/view';
         this.router.navigate([page], {
          queryParams: {...this.dataTransformationModel},
        });
    }

    navigateToWorkflow(data){
      console.log(data);
      this.dataTransformationModel=data;
      let page = '/workflow/manage/workflow';
         this.router.navigate([page],{
          queryParams: {...this.dataTransformationModel},
        } );

    }


    navigateToLogs(data){
      console.log(data);
      this.dataTransformationModel=data;
      let page = '/workflow/logs';
         this.router.navigate([page],{
          queryParams: {...this.dataTransformationModel},
        } );


    }

    dataFilterMapped(data){

      console.log(data);
      this.dataTransformationModel=data;
      let page = 'report/report-form-builder/build-form';
         this.router.navigate([page],{
          queryParams: {...this.dataTransformationModel},
        } );

    }


    viewReportList(){

     // this.groupList=[];
     if(this.categorySelectKey==null || this.categorySelectKey==undefined){
      this.getGroupReportData();
     }
      
      // let page = '/home';
      // this.dataTransformationModel=data;
      // this.router.navigate([page], {
      // queryParams: {...this.dataTransformationModel},
      // });
      if(this.categorySelectKey!=null || this.categorySelectKey!=undefined){
        this.reportDashboardService.getFullReportByCategoryId(this.categorySelectKey,this.state,this.companyId).subscribe(
          data => {
            this.groupList = data;
            console.log("getFilterGroupReportDataByCategoryId: " + data);
          },
          err => {
            this.toastService.showError("failed to get all report by category groups", "error");
          }
        );
       }
     
    }

    getAllGroupData() {

      this.sspapplicationService.getAllGroupDataForGrouppage().subscribe((data) => {
        // this.allGroupData=data;
        this.allGroupData = reMapForAutoComplete(data, 'groupId', 'groupName');
      },
        (err) => {
          this.toast.showError(err.error.message, 'error');
        }
      );
    }

     getReportModeData() {
    const type = 'report_mode';
    const dataVal = {
      type
    };
    this.sspapplicationService.getAllGenericDropDownData(dataVal)
      .subscribe(
        (data) => {
          this.allGenericData = reMapForAutoComplete(data, 'itemValue', 'itemText');
          console.log('generic data' + this.allGenericData);

          const defaultReportMode={
            key:'readytouse',
            value:'Ready to use'
          }
          this.reportFormComponent.createAppForm.patchValue({
            reportMode:defaultReportMode,
          });

        },
        (err) => {
          this.toast.showError(err.error.message, 'error');
        }
      );
  }


  getStatusData() {
    const type = 'status';
    const dataVal = {
      type
    };
    this.sspapplicationService.getAllGenericDropDownData(dataVal)
      .subscribe(
        (data) => {
          this.allStatusData = reMapForAutoComplete(data, 'itemValue', 'itemText');
          console.log('generic data' + this.allGenericData);
        },
        (err) => {
          this.toast.showError(err.error.message, 'error');
        }
      );
  }

  createApplicationData(data) {
    console.log("data save report", data);
    const appData = {
      appId: data.appId,
      appName: data.appName,
      isActive: data.appStatus.key === 'Active' ? true : false,
      applicationGroup: data.groupData,
      // "embeddedUrl":data.reportUrl,
      applicationId: data.appListData.key,
      reportMode: data.reportMode.key,
      appDesc:data.description,
      categoryMap:data.category,
      frequency:data.frequency.value,
      outtableName:data.outtableName
    };

    console.log('Before Create Application:', data);
    const createAppData = {
      applicationData: appData,
      instanceList: data.viewList,
      notificationGroupList: data.notificationType

    };

    this.sspapplicationService.createApplicationData(createAppData).toPromise().then(
      () => {
        this.toast.showSuccess('Success', 'Report Created Successfully');
        this.resetForm();
        this.reportFormComponent.viewList = [];
       // this.getApplicationData();
       this.getGroupReportData();
      },
      err => {
        this.loader.hideLoader();
        this.toast.showError('error', 'error');
      });


  }

  resetForm() {
    this.reportFormComponent.resetForm();
    //  this.editAppDataValue = null;
  }


  getFilteredGroupReportData(flag){
    this.reportDashboardService.getFilterGroupReportData(this.state,this.companyId).subscribe(
      res => {

        this.groupList=res;
        console.log("filtergroupList ",this.groupList)

        

       // console.log(this.groupList," group list data ");
      },
        err => {
         // console.log('url',err);
         this.toastService.showError("failed to get groups", "error");

        }
    )

  }
  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};

    showSetHomeScreen(){
      this.reportDashboardService.showSetHomeScreen().subscribe(
        (res) =>{ 
             this.setHomeScreePermission = res  
             
     }
      )};
  
    callNgOnInitMethods(){
    this.getFilteredGroupReportData(this.state);
    this.getAllGroupData();
    this.getReportModeData();
    this.getStatusData();
    this.getAllCategory();
    this.getAllNotification();
    }

    showRunReport(){

this.showWorkflowTable=false;
    }


    getreportMappedWorkflows(data){
this.currentReportId=data.appId;
console.log("data.appId  ",data.appId)
      this.fileService.getWorkflowListByReportId(data.appId).subscribe(
        data =>{
         this.loader.hideLoader();
      console.log("reportmappedworkflows ",data)
      
      this.workflowResponseModel=data

      if(this.workflowResponseModel.length==0){
        console.log("toast messege",this.workflowResponseModel.length);
        this.workflowResponseModel=[];
        this.toast.showWarn('no Workflow is linked ','error');
        return;
      }
      
      this.workflowResponseModel.forEach(item=>{
      // console.log("item",item.isActive===1);
      
       item.isActiveDisplay=item.isActive===1?'Yes':'No'
       item.workflowTypeDisplay=item.workflowType===1?'System':'Custom'
       item.asOfDate=item.asOfDate!==null?this.datePipe.transform(item.asOfDate,'MM-dd-yyyy'):""
      
                                             })
                                      
      this.workflowJobTableComponent.executeWorkflow(this.workflowResponseModel[0]);

                                             
          },
        error => {
              this.toast.showError(error.error.message, "error");
                  }
      
        )
                }

      executeworkflow(data){
        console.log("data ",data)
        this.workflowJobComponent.executeWorkflow(data);
                }

                getAllNotification(){


                  this.fileService.getActiveNotificationListByUser().subscribe(
                    (data) => {
                      console.log("notificationList",data);
                     this.notificationList=data;
                     // this.loader.hideLoader();
                      this.notificationList = this.reMapForNotificationTypeMultiSelect( this.notificationList, "notificationId", "notificationName");
                      
                    },
                    (err) =>{
                      this.toast.showError(err.error.message, "error");
                    }  
                
                  )
                }
              
                reMapForNotificationTypeMultiSelect(
              
                  data: any[],
                  keyCol: string,
                  valCol: string
                ): {notificationId: string | number; notificationName: string }[] {
                  return data.map(d => ({ notificationId: d[keyCol], notificationName: d[valCol] }));
              
              
                }

                getAllFrequencyType(){
                  const lookupModel = {
                    "tableName":"frequency",
                    "clientId": 139
                  }
                  this.lookupService.getLookUpDataByTableAndClient(lookupModel)
                   .subscribe(
                     data=>{
                       this.lookUpModelList=data;
                      this.frequencyList=reMapForAutoComplete(this.lookUpModelList,"description","description");
                      console.log("Inside Repord Dasboard",this.frequencyList);
                     },
                     err=>{
                      this.toast.showError(err.error.message, "error");
                      //this.loader.hideLoader();
                    }
                   )
                  
                 }

                 dateEmit(date){
                  this.asofdate=date;
                   }
}
