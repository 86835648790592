import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientSelectorComponent } from './components/client-selector/client-selector.component';
import { ClientTableComponent} from './components/client-table/client-table.component';
import { SharedModule } from '../shared/shared.module';
import { ClientHeaderComponent } from './components/client-header/client-header.component';
import { UiModule } from '../ui/ui.module';
import { MultiSelectModule } from 'primeng';


@NgModule({
  declarations: [
    ClientSelectorComponent,
    ClientTableComponent,
    ClientHeaderComponent
    

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule,MultiSelectModule
  ],
  exports: [ClientSelectorComponent,
    ClientTableComponent,
    ClientHeaderComponent
    ]
})
export class ClientCompModule { }
