import { Component, OnInit, Output,EventEmitter, SimpleChanges, Input } from '@angular/core';
import { FileService } from 'src/app/files/services/file.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-client-file-form',
  templateUrl: './client-file-form.component.html',
  styleUrls: ['./client-file-form.component.scss']
})
export class ClientFileFormComponent implements OnInit {
  @Output() create = new EventEmitter();
  @Input() editClientMaster; 
  @Input() fileType;
  @Input() fileDataType;
  @Input() rolePermission;
  @Input() frequencyList: {key: any; value: any }[]=[];
  
  clientMasterForm: FormGroup = null;
  errorMessage: any;
  //fileDataTypeList: any = [];
  //fileTypeList: any = [];
  @Output() closePanel=new EventEmitter();


  constructor(private fileService: FileService, private toast: ToastService) {
    this.clientMasterForm = new FormGroup({
      name: new FormControl(null,[Validators.required,Validators.minLength(5),Validators.maxLength(100)]),
      fileNamePrefix:new FormControl(null,[Validators.required,Validators.minLength(3),Validators.maxLength(100)]),
      desc: new FormControl(null,[Validators.minLength(5),Validators.maxLength(500)]),
      fileTypeId: new FormControl(null, { validators: [Validators.required]}),
      // email:new FormControl(null, [Validators.required,),
      fileDataTypeId: new FormControl(null, [Validators.required]),
      frequency: new FormControl(null,{ validators: [Validators.required]}),
    });
  // this.clientMasterForm.valueChanges.subscribe(data => this.onValueChanged(data));

  }

  // onValueChanged(data?: any) {
  //   if (!this.clientMasterForm) { return; }
  //   const form = this.clientMasterForm;
  //   for (const field in this.formErrors) {
  //     // clear previous error message (if any)
  //     this.formErrors[field] = '';
  //     const control = form.get(field);
  //     if (control && control.invalid && control.dirty) {
  //       const messages = this.validationMessages[field];
  //       for (const key in control.errors) {
  //         // this.formErrors[field] += messages[key] + ' ';
  //         this.errorMessage = messages;
  //         this.toast.showError('error', this.errorMessage);
  //         console.log(this.errorMessage);
  //       }
  //     }
  //   }
  //   }


  // clientMasterForm: FormGroup = null;
  // errorMessage: any;
  // // fileDataTypeList: any = [];
  // // fileTypeList: any = [];
  

  // formErrors = {
  //   'name': '',
  //   'description': '',
  // };
 
  // validationMessages = {
  //   'name': 'Name is invalid',
  //   'description':'Description is invalid'
  //     }
  
      createMasterData() {
    if(this.clientMasterForm.valid){
    this.create.emit(this.clientMasterForm.value);
    console.log(this.clientMasterForm.value);

   

  }else{
 this.toast.showError("All fields are mandatory","error");
  }
  this.closePanel.emit();
}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.editClientMaster && changes.editClientMaster.currentValue) {
      const d = changes.editClientMaster.currentValue;
     // console.log("RULE" + d.status);
     const fileDataType = {
      key: this.editClientMaster.fileDataType.clientApplicationFileDataType,
      value:this.editClientMaster.fileDataType.type,
    };
    const fileType = {
      key: this.editClientMaster.fileType.clientApplicationFileTypeId,
      value:this.editClientMaster.fileType.type,
    };
    const frequency = {
      key: d.frequency,
      value:d.frequency,
    };
     this.clientMasterForm.patchValue({
        name: d.name,
        fileNamePrefix:d.fileNamePrefix,
        desc: d.desc,
        fileTypeId:fileType,
        fileDataTypeId: fileDataType,
        frequency: frequency
      });
    }

  }
  

  ngOnInit(): void {
    const data$ = this.clientMasterForm.valueChanges.subscribe((data) => {
    });
    // this.getApplicationFileType();
    // this.getClientApplicationFileTypeData();
    }
  ngAfterViewInit() {
   // console.log("I am userops" + this.user);
  }

  get getFrequency(){
    const frequency = [
"Monthly",
"Quarterly"

    ];
    return frequency.map((t) => ({ key: t, value: t }));
  }

//   getApplicationFileType(){
//     this.fileService.getApplicationFileDataType().subscribe(
//    (data) => {
//      const fileDataType=data;
//      for(var a=0;a<fileDataType.length;a++){
//      // console.log("type "+fileDataType[a].type);
//        this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
//      }
//    },
//    (err) => {
//    //  this.toast.showSuccess("Status Not Changed", "error");
//    }
 
//  );
    
//  }
 
//  getClientApplicationFileTypeData(){
//    this.fileService.getClientApplicationFileType().subscribe(
//      (data) => {
//        const clientApplicationFileType=data;
//        for(var a=0;a<clientApplicationFileType.length;a++){
//        //  console.log("type "+clientApplicationFileType[a].type);
//          this.fileTypeList.push({ key: clientApplicationFileType[a].clientApplicationFileTypeId, value: clientApplicationFileType[a].type });
//        }
//      },
//      (err) => {
//      //  this.toast.showSuccess("Status Not Changed", "error");
//      }
   
//    );
 
// }
formReset(){
  this.clientMasterForm.reset();
}
}
