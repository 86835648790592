import { Component, OnInit ,Input} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-help-card',
  templateUrl: './help-card.component.html',
  styleUrls: ['./help-card.component.scss']
})
export class HelpCardComponent implements OnInit {

  @Input() dataVal;
  mailText:string = "";
  generatedUrl:string = "";
  body:string = "";
  constructor() { }

  ngOnInit(): void {
  }

  mailMe(data){

    this.generatedUrl=environment.baseUrl+"/help?videoId="+data.id;
    let subject="Reference Video for "+data.title;
    this.body=" Hi Sir/Madam,%0D%0A%0D%0A"+data.description+"%0D%0A%0D%0A"+this.generatedUrl+"%0D%0A%0D%0A"+"Thanks,%0D%0ANova Support Team";

    this.mailText = "mailto:?subject="+subject+"&body="+this.body; // add the links to body
    window.location.href = this.mailText;
  }

}
