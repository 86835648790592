import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadLiquidityStatementMappingTableComponent } from './components/head-liquidity-statement-mapping-table/head-liquidity-statement-mapping-table.component';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';



@NgModule({
  declarations: [
    HeadLiquidityStatementMappingTableComponent

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    HeadLiquidityStatementMappingTableComponent
    ]
})
export class HeadLiquidityStatementMappingModule { }
