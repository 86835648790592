import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TitleService } from "src/app/core/services/title.service";
import { RulesModel, RuleTypesModel } from "../../models/rules.model";
import { LoaderService } from "src/app/loader/services/loader.service";
import { FileService } from "src/app/files/services/file.service";
import { RulesService } from "src/app/ingestion-rules/services/rules.service";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { TemplateModel } from "../../models/template.model";
import { ToastService } from "src/app/core/services/toast.service";
import { RulesSelectorComponent } from "src/app/ingestion-rules/components/rules-selector/rules-selector.component";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import {  RuleTemplateVersion } from '../../models/ruleTemplate.model';
import { ClientFileIngetRuleModel } from '../../models/clientFileIngestRule.model';
import { ObservalbleDataService} from 'src/app/dashboard/services/observalble-data.service';
import {DataTransferModel} from 'src/app/dashboard/models/dataTransfer.model';
import {trim} from 'lodash';
import { currentMonthRange } from "src/app/utils/dates";
import { CookieService } from "ngx-cookie-service";
import { Store } from '@ngrx/store';
import { SidePanelComponent } from "src/app/ui/components/side-panel/side-panel.component";

@Component({
  selector: "app-rules",
  templateUrl: "./rules.component.html",
  styleUrls: ["./rules.component.scss"]
})
export class RulesComponent implements OnInit, OnDestroy {


  
  @ViewChild(RulesSelectorComponent, { read: false, static: false })
  rulesSelectorComponent: RulesSelectorComponent;
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;
  
  headerForm: FormGroup = null;
  activeRoute$: Subscription;
  clientId: number = null;
  ingestionRules: RulesModel[] = [];
  templates: TemplateModel[] = [];
  allRules: RuleTypesModel[] = [];
  excelTemplate = null;
  fileLayout: any;
  fieldMappings: any[];
  selectedRule: any;
  newRule = 1;
  visible: boolean;
  predefinedRules: RulesModel[] = [];
  ruleTypes: { key: string | number; value: string }[];
  ruleNames: { key: string | number; value: string }[];
  typeAutocomplete = new FormControl();
  nameAutocomplete = new FormControl();
  showRulesForm = false;
  rawData: boolean = false;
  outputData: boolean = false;
  excelOutputTemplate: any[];
  clientFileIngestRuleId:number=null
  ingestRuleId:number=null
  saveAsTemplate:boolean
  saveAsVersion:boolean
  showTemplate:boolean
  showDownloadButton: boolean = false;
  
  ruleTemplateVersion:any[]=[];
  templateList: any;
  showTemplateDropdown: boolean=false;
  dataTransferModel:DataTransferModel;
  showBackButton:boolean=false;
  clientFileIngestRuleModel:ClientFileIngetRuleModel[]=[];
  toDate:string;
  fromDate:string;
  pageName:string;
  uniqueDates:any[]
  uniqueDatesData:any[]=[];
  loadLibraryRule:boolean=false;
  fromDt: any;
  toDt: any;
  jsonData: any;
  rolePermission:any;
  headerFormVal:any;
  fileName:string=null;
  client:string=null;
  adminFlag:String;

  searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String | number, header:String }[];
  @Input() rulesHeader:boolean=true;
  @Input() genericrulesFiles:boolean=false;
  state:string;
  constructor(
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private ruleService: RulesService,
    private loader: LoaderService,
    private toast: ToastService,
    private fileService: FileService,
    public observalbleDataService:ObservalbleDataService,
    private router: Router,
    private cookieService: CookieService,
    private storePermission: Store<{ role: any }>
  ) {

    this. searchByCols = [
      { field: "ruleName", header: "Name of Rule" },
      { field: "appliesto", header: "Applied To" },
      { field: "userName", header: "Created By" },
      { field: "status", header: "Status" },
      
    ];

    this.headerForm = new FormGroup({
      client: new FormControl(),
      file: new FormControl(),
      template:new FormControl(),
      version:new FormControl()
    });

    this.state=localStorage.getItem("stateflag");
  }
  getRuleName(id) {
    return this.allRules.find(d => d.ingestruletype_id === id).displayName;
  }
  ngOnInit(): void {
    this.adminFlag=this.cookieService.get("adminFlag");

    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );

   this.callNgOnInitMethods();
  }

  getPredefinedRules() {
    this.loader.showLoader();
    // this.ruleService.getAllIngestRules().subscribe(
    //   data => {
    //     this.predefinedRules = data;
    //     this.ruleNames = reMapForAutoComplete(
    //       this.predefinedRules,
    //       "",
    //       "ruleName"
    //     );
        
    //     this.loader.hideLoader();
    //   },
    //   err => this.loader.hideLoader()
    // );
    this.ruleService.getAllLibrarayRules().subscribe(
      data => {
        this.predefinedRules = data;
        this.ruleNames = reMapForAutoComplete(
          this.predefinedRules,
          "",
          "ruleName"
        );
        
        this.loader.hideLoader();
      },
      // err => this.loader.hideLoader()
      (err) => {
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
  }
  getTemplateNRules() {
    this.allRules = this.templates = [];   
    const rules = this.ruleService
      .getAllRulesType()
      .pipe(catchError(err => of(err.status)));
    const templates = this.ruleService
      .getAllTemplates()
      .pipe(catchError(err => of(err.status)));
    forkJoin([rules, templates]).subscribe(
      res => {
        this.allRules = res[0];
        this.templates = res[1];
        this.templateList=reMapForAutoComplete(this.templates,'id','name');
        
        this.ruleTypes=reMapForAutoComplete(
          this.allRules,
          "ingestruletype_id",
          "displayName"
        );
        
      },
      () => this.toast.showError("Server Error", "Unable to fetch data"),
      () => this.loader.hideLoader()
    );
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      data => this.titleService.setTitle(data["title"]),
      err => console.log("error", err)
    );
  }
  getFiles() {
   // this.maintainClientState();
    this.clientId = this.headerForm.value.client.key;
    this.ingestionRules=[];
    this.ingestRuleId=null;
    this.excelTemplate=null;
    this.showTemplateDropdown=false
    this.ruleTemplateVersion=[];
    this.createRule();
    this.headerForm.get("file").reset();
    this.getListOfUniqueDate(this.clientId);
    this.cookieService.set('clientId',this.headerForm.value.client.key);
    this.cookieService.set('clientName',this.headerForm.value.client.value);
  }
  getRules(data) {
    this.headerFormVal=data;
    this.fileName=this.headerFormVal.file.value;
    this.client=this.headerFormVal.client.value;
   // this.loader.showLoader();
    this.ingestRuleId=null;
    this.ingestionRules=[]
    this.showTemplateDropdown=false
    this.createRule();
    this.ruleService.getIngestionRulesById(this.headerFormVal.file.key).subscribe(
      data => {
        if(data!==null && data.length>0){
        this.showTemplateDropdown=false
        this.ingestionRules = data;
        this.ruleTemplateVersion=[];
        //this.showDownloadButton = true;
       
        }else{
         // this.showTemplateDropdown=true
         this.showTemplateDropdown=false
        }
        const { client, file } = this.headerFormVal;      
        this.getFileLayout(client.key, file.key);
        this.getRawExcelData(client.key, file.key);
       // this.loader.hideLoader();
      },
      err => {
        console.log(err);
     //   this.loader.showLoader();
      }
    );
  }
  /**
   * @param  {} client
   * @param  {} file
   */
  getRawExcelData(client, file) {
    this.loader.showLoader();
    this.excelTemplate=null;
    this.excelOutputTemplate=null;
    this.rawData = false;
    this.outputData=false;
    this.ruleService.getRawData(client, file).subscribe(
      data => {
        this.rawData = true;
        this.excelTemplate = Object.values(data);
        //this.excelOutputTemplate = Object.values(data);
        this.loader.hideLoader();
      },
      err => {
       // console.log(err.error.message);
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
  }
  getFileLayout(client, file) {
    this.loader.showLoader();
    this.fileService.getFileLayoutByClientFile(client, file).subscribe(
      data => {
        if (data === null) {
          this.showTemplateDropdown=false;
          this.toast.showInfo("No Layout defined", "Select another file");
        } else {
          this.fileLayout = data;
          this.getFieldMapping(this.fileLayout.clientApplicationFileId);
        }
        this.loader.hideLoader();
      },
      err => {
        this.loader.hideLoader();
      }
    );
  }
  getFieldMapping(id) {
    this.loader.showLoader();
    this.fileService.getFieldMappingsById(id,'Rules').subscribe(
      data => {
        this.fieldMappings = data;
        this.loader.hideLoader();
      },
      err => {
        this.loader.hideLoader();
      }
    );
  }
  editRule(data) {
    this.panelForm.toggleSlider();
    this.selectedRule = null;
    this.visible = false;
    this.showRulesForm = true;
    this.createRule();
    this.loader.showLoader();
   // console.log(" data :::",data);
    let clientfileIngetRuleLoadId=null;
    if(data.clientFileIngestRuleId===null || data.clientFileIngestRuleId===undefined || data.clientFileIngestRuleId===0){
     //load from library
      clientfileIngetRuleLoadId=0;
      this.loadLibraryRule=true; 
    }
    if(data.clientFileIngestRuleId!==null && data.clientFileIngestRuleId!==undefined  && data.clientFileIngestRuleId>0){
      this.loadLibraryRule=false;
      this.clientFileIngestRuleId=data.clientFileIngestRuleId;
    }
   // this.ingestRuleId=data.ingestruleId;
   if(this.loadLibraryRule){
    this.ruleService.getIngestionRuleLibraryByRuleId(data.ingestruleId).subscribe(
      res => {
        this.selectedRule = res;
        if(clientfileIngetRuleLoadId!==null){      
          this.loadLibraryRule=true;    
         // this.toast.showWarn('Update in rule will update rule library and its associated file rule','warning');        
        }
       // console.log(this.selectedRule)
        this.loader.hideLoader();
        this.excelOutputTemplate=null;
        this.outputData=false;
      },
      err => {
        this.ingestRuleId=null;
        this.toast.showError(err.error.message,"error");
        this.loader.hideLoader();
      }
    );
   }else {
    this.ruleService.getIngestionRuleByRuleId(data.ingestruleId).subscribe(
      res => {
        this.selectedRule = res;
        if(clientfileIngetRuleLoadId!==null){      
          this.loadLibraryRule=true;    
         // this.toast.showWarn('Update in rule will update rule library and its associated file rule','warning');        
        }
       // console.log(this.selectedRule)
        this.loader.hideLoader();
        this.excelOutputTemplate=null;
        this.outputData=false;
      },
      err => {
        this.ingestRuleId=null;
        this.toast.showError(err.error.message,"error");
        this.loader.hideLoader();
      }
    );

   }
  }
  showPopup() {
    this.visible = true;
    this.showRulesForm = false;
    this.getPredefinedRules();
    this.ingestRuleId=null; 
  }
  createRule() {
    this.loadLibraryRule=false;
    this.visible = false;
    this.showRulesForm = true;
    this.newRule = this.newRule + 1;
    this.clientFileIngestRuleId=null;
    if(this.showTemplateDropdown){
      this.ingestionRules=[];
      this.showTemplateDropdown=false;
      this.templateList=[];
      this.ruleTemplateVersion=[]
    }

//console.log(this.loadLibraryRule+"    load librayry");
    
  }
  hidePopup(type) {
    this.visible = false; 
  }

  saveRuleData(data) {

    // console.log("Data :",data);
    // console.log("save To Librarat :",data.saveToLibrary)
    
    const { client, file } = this.headerFormVal;
   
    if(file.key===null){
      this.toast.showWarn('error','no file selected');
      return;
    }
    
    if(data.dataValue.ingestruletypeId===null || data.dataValue.ruleName===null || data.dataValue.appliesTo===null || data.dataValue.appliesTo.length===0||trim(data.dataValue.ruleName).length<1 ){
      this.toast.showWarn('error','rule type,name & applies to mandatory fields');
      return;
    }
    // if(data.dataValue.ingestruletypeId===null || data.dataValue.ruleName===null || data.dataValue.appliesTo===null || data.dataValue.appliesTo.length===0||trim(data.dataValue.ruleName).length<1 ||trim(data.dataValue.appliesTo).length<1||trim(data.dataValue.ingestruletypeId).length<1 ){
    //   this.toast.showWarn('error','rule type,name & applies to mandatory fields');
    //   return;
    // }
	//validation changed/vc/
  //   if(data.dataValue.ingestruletypeId===null ||trim(data.dataValue.ingestruletypeId).length<1 ){
  //     this.toast.showWarn('error','ingest rule type Id type mandatory field');
  //     return;
  //   }
	
	// 		if(data.dataValue.appliesTo.length===0||trim(data.dataValue.appliesTo).length<1 ){
  //     this.toast.showWarn('error','applies to mandatory fields');
  //     return;
  //   }
	
	// if(data.dataValue.ruleName===null ||trim(data.dataValue.ruleName).length<1 ){
  //     this.toast.showWarn('error','rule name  mandatory fields');
  //     return;
  //   }
////vc////
// if(data.dataValue.definition.errorValue===null || data.dataValue.definition.errorValue.length===0||trim(data.dataValue.definition.errorValue).length<1){
//   this.toast.showWarn('error','error value required');
//   return;
// }
    if(data.dataValue.definition.errorValue===null || data.dataValue.definition.errorValue.length===0){
      this.toast.showWarn('error','error value required');
      return;
    }
  
   let validate= this.validationByRuleType(data);

   if(!validate){
     return;
   }
   
    if(data.ingestruleId==undefined){
      data.ingestruleId=null;  
    }
    
    this.loader.showLoader();
    const ingestionruleData={
      "ingestruletypeId":data.dataValue.ingestruletypeId,
      "ruleName":data.dataValue.ruleName,
      "appliesTo":data.dataValue.appliesTo,
      "ingestruleId": data.ingestruleId||0,
      "clientFileIngestRuleId":this.clientFileIngestRuleId!==null?this.clientFileIngestRuleId:null,
      "saveToLibraray":data.saveToLibrary!==null?data.saveToLibrary:0,
      "definition":{
        "compareTo":data.dataValue.definition.compareTo,
        "asOfDate":data.dataValue.definition.asOfDate,
        "operator":data.dataValue.definition.operator,
        "errorValue":data.dataValue.definition.errorValue,
        "updateValue":data.dataValue.definition.updateValue,
        "commentValue":data.dataValue.definition.commentValue,
        "rangeValue1":data.dataValue.definition.rangeValue1,
        "replaceValue":data.dataValue.definition.replaceValue,
        "operatedvalue":data.dataValue.definition.operatedvalue,
        "falseConditionValue":data.dataValue.definition.falseConditionValue,
        "updationValue":data.dataValue.definition.updationValue,
        "fieldType":data.dataValue.definition.fieldType,
        "minValue":data.dataValue.definition.minValue,
        "maxValue":data.dataValue.definition.maxValue,
        "appliesTo":data.appliesTo.value
      }
    }

    if(this.loadLibraryRule){

      this.ruleService.mapLibraryRuleToClientFile(ingestionruleData,file.key).subscribe(
        res => {
          this.toast.showSuccess('success', 'rule saved');
          this.loader.hideLoader();
          this.resetChild();
          this.excelOutputTemplate=[];
          this.getRules(this.headerFormVal);
          this.ingestRuleId=null;
          this.showExcel('raw');
          
          
        },
        err => {
          this.toast.showError(err.error.message,"error");
          this.loader.hideLoader();
        }
      );
    }else{
      

    this.ruleService.saveRule(ingestionruleData,file.key).subscribe(
      res => {
        this.toast.showSuccess('success', 'rule saved');
        this.loader.hideLoader();
        this.resetChild();
        this.excelOutputTemplate=[];
        this.getRules(this.headerFormVal);
        this.ingestRuleId=null;
        this.showExcel('raw');
        this.clientFileIngestRuleId=null;
      },
      err => {
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
    }
  }

  applyRule(data) {
    this.showExcel('raw')
    const clientFileModel = {
      clientApplicationFileId: this.headerFormVal.file.key,
      clientId: this.headerFormVal.client.key,
      ingestRuleId: data.ingestruleId,
      logflag: true
    };
   
    this.excelOutputTemplate=[]
    this.loader.showLoader();
    this.ruleService.applyRule(clientFileModel).subscribe(
      res => {
        this.loader.hideLoader();
        this.outputData=true;
        this.rawData = false;
        this.excelOutputTemplate = Object.values(res);
        this.toast.showSuccess("Rule applied", "success");
        this.resetChild();
      },
      err =>{
        this.toast.showError('error', 'error while applying rule');
        this.loader.hideLoader()
      } 
    );
  }

  deleteRule(data) {
    this.loader.showLoader();
    const clientFileModel={
      "clientApplicationFileId":this.headerFormVal.file.key,
      "ingestRuleId":data.ingestruleId,
      "clientFileIngestRuleId":data.clientFileIngestRuleId
    }
    this.ruleService.deleteIngestRule(clientFileModel).subscribe(
      res => {
        this.toast.showSuccess("Rule deleted", "success");
        this.loader.hideLoader();
        this.resetChild();
        this.excelOutputTemplate=[];
        this.showExcel('raw');
        this.getRules(this.headerFormVal);
      },
      err =>{
        this.toast.showError('error',err.error.message );
        this.loader.hideLoader();

      } 
    );
  }

  resetChild(): void {
    this.showRulesForm = false;
    
  }

  showExcel(data) {
    if (data == "raw") {
      this.rawData = true;
      this.outputData = false;
    } else {
      this.rawData = false;
      this.outputData = true;
    }
  }

 
  changeSaveTemplate(e){
    this.saveAsTemplate=e.target.checked;
  }

  changeSaveVersion(e){
    this.saveAsVersion=e.target.checked;
  }

  saveTemplate(){

    if(!this.showTemplateDropdown){
      if(!this.saveAsTemplate){
        this.toast.showError('check save as template','error')
        return;
      }
    }

    if(this.ingestionRules.length===0){
      this.toast.showError('no rules in the list','error')
      return;
    }

    if(this.saveAsTemplate){
      const item={	
        createNewTemplate:true,
        ingestruleList:[],
        clientId:this.headerForm.controls.client.value.key,
        clientApplicationFileId:this.headerForm.controls.file.value.key,
        createNewVersion:true
       }	

      for(let i=0;i<this.ingestionRules.length;i++){	
        let templateData=this.ingestionRules[i].ingestruleId;	
        item.ingestruleList.push(templateData);	
       }	
  
       this.ruleService.saveDataForTemplate(item)
       .subscribe(
         res=>{
           this.toast.showSuccess('Template saved','success')
           console.log(res);
           this.resetChild();
          
           this.saveAsTemplate=false;
        this.excelOutputTemplate=[];
        this.getRules(this.headerForm.controls.file.value.key);
        this.ingestRuleId=null;
        this.showExcel('raw');
         }
       );
       
    }else if(this.saveAsVersion){
      const item={	
        templateId:1,
        createNewTemplate:false,
        ingestruleList:[],
        clientId:this.headerForm.controls.client.value.key,
        clientApplicationFileId:this.headerForm.controls.file.value.key,
        createNewVersion:true
       }	
       for(let i=0;i<this.ingestionRules.length;i++){	
        let templateData=this.ingestionRules[i].ingestruleId;	
        item.ingestruleList.push(templateData);	
       }	

       this.ruleService.saveDataForTemplate(item)
       .subscribe(
         res=>{
          this.toast.showSuccess('Template saved','success');
          this.resetChild();
          this.saveAsVersion=false;
          this.showTemplateDropdown=false;
          this.excelOutputTemplate=[];
          this.getRules(this.headerForm.controls.file.value.key);
          this.ingestRuleId=null;
          this.showExcel('raw');
         }
       );

    }else if(this.showTemplateDropdown){

    let arry=[]
    if(this.ingestionRules.length==0) {
     this.toast.showError('min one rule required','error')
     return;
    }
  for(let i=0;i<this.ingestionRules.length;i++){
    let idValue=this.ingestionRules[i].ingestruleId;
    arry.push(idValue);	
   }

   this.ruleService.saveTemplate(arry,this.headerForm.controls.file.value.key)
   .subscribe(
     res=>{
       this.toast.showSuccess('template saved','success');
       this.resetChild();
       this.showTemplateDropdown=false;
       this.excelOutputTemplate=[];
       this.getRules(this.headerForm.controls.file.value.key);
       this.ingestRuleId=null;
       this.showExcel('raw');
     },
     err=>this.toast.showError(err.error.message, "error")
   )

    }



  }

 
  getVersion(id){
    this.loader.showLoader();
    this.ruleTemplateVersion=[]
    this.ingestionRules=[]
    this.ruleService.getTemplateVersionById(id)
    .subscribe(
      res=>{
        this.ruleTemplateVersion=reMapForAutoComplete(res,'ingestruletemplateversionId','versionNo')
        console.log(this.ruleTemplateVersion,' rule temp version')
        this.loader.hideLoader();
      }
    )
  }

  getRulesByVersion(id){
    this.ingestionRules=[];
    this.loader.showLoader();
    this.ruleService.getRulesByVersionId(id)
    .subscribe(
      res=>{
        this.ingestionRules=res;
        this.loader.hideLoader();
      }
      
    )

  }

  showLoad(e){
    console.log(e.target.checked);
  }

  validationByRuleType(data){
//format
    if(data.dataValue.ingestruletypeId===5){
      
       if(data.dataValue.definition.updateValue===null ||trim(data.dataValue.definition.updateValue).length===0){
         this.toast.showWarn('error','format field required')
         return false;
       }
    }
//delete
    if(data.dataValue.ingestruletypeId===3){
      if( data.dataValue.definition.operator===null || trim(data.dataValue.definition.operator).length===0 ){
        this.toast.showWarn('error','operator field required')
        return false;
      }
      if(data.dataValue.definition.rangeValue1===null ||trim(data.dataValue.definition.rangeValue1).length===0 ){
        this.toast.showWarn('error','compare value field required')
        return false;
      }
    }

    if(data.dataValue.ingestruletypeId===8){
      if(data.dataValue.definition.replaceValue===null || trim(data.dataValue.definition.replaceValue).length===0){
        this.toast.showWarn('error','value field required')
        return false;
      }
    }
//update
    if(data.dataValue.ingestruletypeId===2){
      if(data.dataValue.definition.operator===null || trim(data.dataValue.definition.operator).length===0 ){
        this.toast.showWarn('error','operator field required')
        return false;
      }
      if( data.dataValue.definition.rangeValue1===null || trim(data.dataValue.definition.rangeValue1).length===0){
        this.toast.showWarn('error','compare to value field required')
        return false;
      }
      if( data.dataValue.definition.operatedvalue===null || trim(data.dataValue.definition.operatedvalue).length===0){
        this.toast.showWarn('error','true value field required')
        return false;
      }
    }
//update predefined
    if(data.dataValue.ingestruletypeId===9){
      if(data.dataValue.definition.replaceValue===null || trim(data.dataValue.definition.replaceValue).length===0){
        this.toast.showWarn('error','value  field required')
        return false;
      }
      if(data.dataValue.definition.updationValue===null || trim(data.dataValue.definition.updationValue).length===0){
        this.toast.showWarn('error','update to field required')
        return false;
      }
    }
//  update all
    if(data.dataValue.ingestruletypeId===7 ){   
      if(data.dataValue.definition.updationValue===null || trim(data.dataValue.definition.updationValue).length===0 ){
        this.toast.showWarn('error','update to field required')
        return false;
      }
    } 
//validation
    if(data.dataValue.ingestruletypeId===13){
      if(data.dataValue.definition.fieldType===null || trim(data.dataValue.definition.fieldType).length===0){
        this.toast.showWarn('error','field type is required')
        return false;
      }
      if( data.dataValue.definition.minValue===null || trim(data.dataValue.definition.minValue).length===0 ){
        this.toast.showWarn('error','min value is required')
        return false;
      }
      if( data.dataValue.definition.maxValue===null || trim(data.dataValue.definition.maxValue).length===0){
        this.toast.showWarn('error','max value si required')
        return false;
      }
    }
//compare
    if(data.dataValue.ingestruletypeId===4){
      if(data.dataValue.definition.operator===null || trim(data.dataValue.definition.operator).length===0 ){
        this.toast.showWarn('error','operator field required')
        return false;
      }
      if(data.dataValue.definition.asOfDate===null || trim(data.dataValue.definition.asOfDate).length===0 ){
        this.toast.showWarn('error','asofDate field required')
        return false;
      }
      if(data.dataValue.definition.compareTo===null ||trim(data.dataValue.definition.compareTo).length===0 ){
        this.toast.showWarn('error','compareTo field required')
        return false;
      }
    }

    if(data.dataValue.ingestruletypeId===16){
      console.log(data.dataValue.definition);
      

      if(data.dataValue.definition.operatedvalue!==null && trim(data.dataValue.definition.operatedvalue).length!==0 ){

       if(isNaN(data.dataValue.definition.operatedvalue)){
        this.toast.showWarn('error','no of footer rows should be numeric')
        return false;
       }

       if(data.dataValue.definition.operatedvalue.includes('-')){
        this.toast.showWarn('error','no of footer rows should be numeric')
        return false;
       }

       if(data.dataValue.definition.operatedvalue.includes('.')){
        this.toast.showWarn('error','no of footer rows should be numeric')
        return false;
       }

       if(data.dataValue.definition.operatedvalue.includes('+')){
         console.log("inside +");
        this.toast.showWarn('error','no of footer rows should be numeric')
        return false;
       }

         

        
      }

      if(data.dataValue.definition.rangeValue1!==null && trim(data.dataValue.definition.rangeValue1).length!==0 ){

        if(isNaN(data.dataValue.definition.rangeValue1)){
         this.toast.showWarn('error','no of header rows should be numeric')
         return false;
        }
 
        if(data.dataValue.definition.rangeValue1.includes('-')){
         this.toast.showWarn('error','no of header rows should be numeric')
         return false;
        }
 
        if(data.dataValue.definition.rangeValue1.includes('.')){
         this.toast.showWarn('error','no of header rows should be numeric')
         return false;
        }

        if(data.dataValue.definition.rangeValue1.includes('+')){
          this.toast.showWarn('error','no of header rows should be numeric')
          console.log("inside +");
          return false;
         }
 
                
       }
    
      
    }

    return true;
  }


  onRowChange(data){
   
    for(let i=0;i<data.length;i++)
    {
        const clientFileIngestRule={
          "id":data[i].clientFileIngestRuleId,
          "clientApplicationFileId": this.headerForm.controls.file.value.key,
          "ingestrule_id": data[i].ingestruleId,
          "appliesto": data[i].appliesto,
          "sequenceno": i+1,
          "comments":"",
          "createdBy":1,
          "createdAt":null,
          "modifiedBy":1,
          "modifiedAt":null,
          "status":data[i].status==='Active'?1:0     
        }
        this.clientFileIngestRuleModel.push(clientFileIngestRule)
    }
    this.loader.showLoader();
    this.ruleService.saveClientFileIngestRule(this.clientFileIngestRuleModel)
    .subscribe(
      res=>{
       
        this.toast.showSuccess('Rules Sequence changed','Succcess');
        this.resetChild();
        this.excelOutputTemplate=[];
        this.loader.hideLoader();
        this.ingestRuleId=null;
        this.getRules(this.headerForm.controls.file.value.key);
        this.clientFileIngestRuleModel=[];
        this.showExcel('raw');
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
      
    )
    


  }

  goToPage(){
    if(this.showBackButton){
    this.dataTransferModel={
      clientFileId:this.headerForm.controls.file.value.key,
      clientId: this.headerForm.controls.client.value.key,
           isRedirected:1,
           applicationId:0,
           toDate: this.toDate,
           fromDate:this.fromDate,
           clientName:this.headerForm.controls.client.value.value,
           page:this.pageName,
           fileName:this.headerForm.controls.file.value.value,
           workflowName:null,
          workflowId:0,
          dataType:null,
          dataTypeId:0
    }
   // console.log(this.dataTransferModel);
    this.observalbleDataService.addToInventory(this.dataTransferModel);
    this.router.navigate([this.pageName]);
  
  }
}

getListOfUniqueDate(clientId){
  this.ruleService.getListOfUniqueDate(clientId).subscribe(
    data=>{
      this.uniqueDates=data;
       for(let i=0;i<this.uniqueDates.length;i++){
      this.uniqueDatesData.push({key:this.uniqueDates[i],value:this.uniqueDates[i]})
       }
      console.log(this.uniqueDatesData)
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
     // this.loader.hideLoader();
    }
  );

}

deleteLibrarayRule(data){
  //console.log("Library rule data :",data)
  this.loader.showLoader();
  const clientFileModel={
    "ingestRuleId":data.ingestruleId
  }

  this.ruleService.deleteIngestRuleLibrary(clientFileModel)
  .subscribe(
    (data)=>{
      this.toast.showSuccess('success','library rule deleted')
      this.loader.hideLoader();
      this.showPopup();
    },
    (err)=>{
      this.loader.hideLoader();
    }
  )
}

deactivateRule(data){
  // this.loader.showLoader();
    const clientFileModel={
      "clientApplicationFileId":this.headerFormVal.file.key,
      "ingestRuleId":data.ingestruleId,
      "clientFileIngestRuleId":data.clientFileIngestRuleId,
      "status" :data.status
    }
    this.ruleService.deactivateIngestRule(clientFileModel).subscribe(
      res => {
        if(data.status==='Active'){
        this.toast.showSuccess("Rule deactivated", "success");
        }else{
          this.toast.showSuccess("Rule activated", "success");
        }
        // this.loader.hideLoader();
        this.resetChild();
        this.excelOutputTemplate=[];
        this.showExcel('raw');
        this.getRules(this.headerFormVal);
      },
      err =>{
        this.toast.showError('error',err.error.message );
        // this.loader.hideLoader();

      } 
    );
  }

  getPreviousState() {

    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
    
    if(clientIdC!==undefined && clientIdC!==null && clientIdC!==""){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
      this.getFiles();
    }
    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //     }
    //     this.clientId = this.dataTransferModel.clientId;
    //     this.ingestionRules = [];
    //     this.ingestRuleId = null;
    //     this.excelTemplate = null;
    //     this.showTemplateDropdown = false
    //     this.ruleTemplateVersion = [];
    //     this.createRule();
    //     this.headerForm.get("file").reset();
    //     this.getListOfUniqueDate(this.clientId);
    //   });
  }

  maintainClientState(){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: this.headerForm.value.client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: this.headerForm.value.client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId:0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  downloadRulesAsCSV(){
    console.log(this.headerForm.value.file.key, this.headerForm.value.file.value );
    this.ruleService.getRulesAsCSV(this.headerForm.value.file.key, this.headerForm.value.file.value).subscribe(
      data => {
        this.jsonData = data;
         console.log(this.jsonData); 
        //console.log("JSON Data Is:", JSON.parse(this.jsonData));
        this.ruleService.downloadFile(this.jsonData, 'jsontocsv');
      });
      
  }

  ngOnDestroy() {
    try{
      this.activeRoute$.unsubscribe();
    }catch(ex){
      console.log(ex);
    }
   
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
       this.setTitle();
   // this.getPreviousState();
    this.getTemplateNRules();
    this.getPredefinedRules();
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
        if (par.has("clientId") && par.has("clientName")) {
          this.headerForm.get("client").setValue({
            key: par.get("clientId"),
            value: par.get("clientName")
          });
          this.getFiles();
        }
        if (par.has("fileName") && par.has("clientFile")) {
          const fileData = {
            key: par.get("clientFile"),
            value: par.get("fileName")
          };
          this.headerForm.get("file").setValue(fileData);
          console.log("headerForm values ",this.headerForm.value)
          this.getRules(this.headerForm.value);
         
        }

        if(par.has("toDate") && par.has("fromDate")){
          this.toDate=par.get("toDate")
          this.fromDate=par.get("fromDate")
          this.showBackButton=true;
        }
        if(par.has("pageName")){
          this.pageName=par.get("pageName")
          
        }
      },
      err => {}
    );

    if(!this.showBackButton){
    // this.getPreviousState();
    }
    }

    
}
