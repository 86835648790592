import { Component, OnInit,Input, SimpleChanges,Output,EventEmitter} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { LoaderService } from 'src/app/loader/services/loader.service';

@Component({
  selector: 'app-head-liquidity-statement-mapping-table',
  templateUrl: './head-liquidity-statement-mapping-table.component.html',
  styleUrls: ['./head-liquidity-statement-mapping-table.component.scss']
})
export class HeadLiquidityStatementMappingTableComponent implements OnInit {
  @Input() data:any[];
  @Input() fields: { key: number | string; value: string }[] = [];
  fieldsArray = new FormGroup({ fields: new FormArray([]) });
  loadPage: boolean = false;
  filteredData: any[];
  editStatus = [];
  @Output() onSelect=new EventEmitter();
  constructor(private loader: LoaderService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue) {
      this.loader.showLoader();
      this.loadPage = false;
      this.addArrayFields(changes.data.currentValue.length);
    }
  }

  get fieldArray() {
    return this.fieldsArray.get("fields") as FormArray;
  }


  addArrayFields(length) {
    this.fieldArray.clear();
    for (let i = 0; i < length; i++) {
      const arr = this.fieldArray;
      const data = this.findField(this.data[i].liquidityStatementMasterId);
      arr.push(new FormControl(data));
      this.editStatus.push(false);
    }
    this.loadPage = true;
   this.loader.hideLoader();
  }

  findField(id) {
    return this.fields.find((d) => d.key === id);
  }


  filterData(event) {
    let query = event.query;
    this.filteredData = this.fields.filter(
      (d) => d.value.toLowerCase().indexOf(query.toLowerCase()) === 0
    );
  }


  updateField(id, particularName, particularsId) {
    console.log("particularsId ",particularsId)
    if (particularsId === undefined) {
      particularsId = null;
    }
    this.onSelect.emit({
      liquidityStatementMasterId: id,
      particularName: particularName,
      particularsId: particularsId
      
    });
  }

}
