import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-export-table',
  templateUrl: './export-table.component.html',
  styleUrls: ['./export-table.component.scss'],
})
export class ExportTableComponent implements OnChanges{
  @Input() columns: string[] = [];
  @Input() data: any[] = [];
  @Input() searchByCols:string[]=[];
  @Output() selected = new EventEmitter();
  @Output() reportType = new EventEmitter();
  constructor(private cdr:ChangeDetectorRef) {}

  emitSelected(data: any, event: Event) {
    const ele = event.target as HTMLInputElement;
    this.selected.emit({ status: ele.checked, data });
  }
  actionSelected(actionType: any,rowData: any) {
    console.log("acctionType",actionType);
    const data={
      "actionType":actionType,
      "reportData":rowData
    }
    this.reportType.emit(data)
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("report table ",changes) 
     if(changes && changes.data && changes.data.currentValue){
       this.cdr.detectChanges();
     }
    }
}
