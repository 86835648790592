import { Component, OnInit,Input,OnChanges,SimpleChanges,Output,EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientPortalDataComponent } from 'src/app/dashboard/pages/client-portal-data/client-portal-data.component';
import { ClientPortalService } from 'src/app/dashboard/services/client-portal.service';
import { FileService } from 'src/app/files/services/file.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';


@Component({
  selector: 'app-client-portal-header',
  templateUrl: './client-portal-header.component.html',
  styleUrls: ['./client-portal-header.component.scss']
})
export class ClientPortalHeaderComponent implements OnInit,OnChanges {
  @ViewChild("ClientPortalDataComponent", {static: false })
  clientPortalDataComponent!:ClientPortalDataComponent;

  showClientFilePopUp:boolean=false;
  showClientAppFilePopUp:boolean=false;
  visible:boolean=false;
  isZipUpload:boolean=false;
  headerForm:FormGroup;
  currentFileUpload:FileList;
  currentFileUploadList:FileList;
  fileList:any=[];
  popUpName:string;
addHeader:string="Add";
deleteHeader:string="Remove Layout";
visibleLayoutPopUp:boolean=false;
popUpForm:FormGroup;
dropDownList:any[]=[];
action:string=null;
clientFileId:any=null;
clientFileUpdate:any=null;
deleteVisible:boolean=false;
rolePermission:any;
clientsList : {key: any; value: any }[]=[];
clientApplicationFileForm:FormGroup;
myInputVariable: ElementRef;
@ViewChild('myInput2')
myInputVariable2: ElementRef;
showLoading:boolean=false;
fieldSeparatorList:{ key: string ; value: string }[];
layoutFieldList: any = [];
dependentUponList: any = [];
fileTypeList: any = [];
fileDataTypeList: any = [];
clientId:Number=0;
@Input() formData:any;

@Output() fetchListing=new EventEmitter();

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;

@ViewChild("panelForm2", {static: false })
panelForm2!:SidePanelComponent;

@ViewChild("panelForm1", {static: false })
panelForm1!:SidePanelComponent;

formValues:any;
@Input() state:string=localStorage.getItem('stateflag');
stateVar:string;
@Input() hideLogo:boolean=false;

@Output() downloadfileFormat=new EventEmitter<string>();
  constructor(private toast:ToastService,private fileService:FileService,private clientPortalService:ClientPortalService) {
    this.headerForm = new FormGroup({
      client: new FormControl(null),
      toDate: new FormControl(null),
      fromDate: new FormControl(null),
      asOfDate: new FormControl(null),
      state:new FormControl(null),
      isTransposeOnUploadFile: new FormControl(null)
    });
    this.clientApplicationFileForm = new FormGroup({
      fileType: new FormControl(null),
      fileDataType: new FormControl(null),
      layoutFieldId: new FormControl(null),
      dependentUpon: new FormControl(null),
      fieldSeparator: new FormControl(null),
      schedule: new FormControl(null),
      state:new FormControl(null)

    });
    this.stateVar=localStorage.getItem('stateflag');

   }

  ngOnInit(): void {
    console.log("form value",this.formData);
    this.getApplicationFileType();
    this.getClientApplicationFileTypeData();
    this.getAllSeparators();
  }

  ngOnChanges(changes: SimpleChanges) {
  //  console.log("on changes form client-portal-header",this.headerForm.value)
   if (changes && changes.formData && changes.formData.currentValue) {
       const d: any = changes.formData.currentValue;
      console.log("enter inside on changes");
       this.headerForm.patchValue({
         client:  { key: d.client.key, value: d.client.value },
          fromDate: d.fromDate,
          toDate:  d.toDate
       });

       this.formValues=this.headerForm.value;
       console.log("default variable",this.formValues)
       console.log("on changes form client-portal-header",this.headerForm.value,this.formValues)
      this.getDependentUponData(this.headerForm.controls.client.value.key);
      this.getLayoutField(this.headerForm.controls.client.value.key);
      } 
     console.log("on changes form client-portal-header outside",this.headerForm.value)
  }
   
   // console.log("on changes form",this.parameterForm.value)


  saveClientApplicationFile(data){
    this.currentFileUpload=data.file;
    let isTransposeOnLayout=data.form.isTransposeOnLayout;
      this.clientApplicationFileForm.patchValue({
        fileType: { key: data.form.fileType.key, value: data.form.fileType.value },
        fileDataType: { key: data.form.fileDataType.key, value: data.form.fileDataType.value },
        // layoutFieldId: { key: data.form.layoutFieldId.key, value: data.form.layoutFieldId.value },
        // dependentUpon:{ key: data.form.dependentUpon.key, value: data.form.dependentUpon.value },
        fieldSeparator: { key: data.form.fieldSeparator.key, value: data.form.fieldSeparator.value },
      });
    if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
      this.toast.showWarn('no file selected','error');
      return;
    }
    if(this.clientId===0){
      this.clientId=this.headerForm.controls.client.value.key;
       }

      const ClientAppliactionFileConst = {
       "clientId": this.clientId,
       "clientApplicationFileTypeId": this.clientApplicationFileForm.controls.fileType.value.key,
       "isTransposeOnLayout":isTransposeOnLayout,
       "dependentUpon":this.clientApplicationFileForm.controls.dependentUpon.value,
       "schedule":this.clientApplicationFileForm.controls.schedule.value,
       "fieldSeparator":this.clientApplicationFileForm.controls.fieldSeparator.value,
       "clientApplicationFileDataTypeId":this.clientApplicationFileForm.controls.fileDataType.value.key,
       "layoutFieldId":this.clientApplicationFileForm.controls.layoutFieldId.value,
       "genericFileUpload":0,
       "state":this.state
       
     };
     console.log("ClientAppliactionFileConst",ClientAppliactionFileConst);
     this.fileService.saveClientApplicationFile(this.currentFileUpload,ClientAppliactionFileConst,this.state) .subscribe(
      data=>{
        this.toast.showSuccess('Success','File Uploaded Successfully');
        this.currentFileUpload=null;
       // this.myInputVariable.nativeElement.value = "";
        this.visibleLayoutPopUp=false;
        this.clientApplicationFileForm.reset();
       // this.loader.hideLoader();

       
      },
      error=>{
        this.toast.showError(error.error.message, "error")
      //  this.loader.hideLoader();
      },
     
    );
  //  }else{
  //   this.toast.showError('Please Fill the Mandatory Fields','Error')
  //  }
  }

  selectClientApplicationFile(event){
    this.currentFileUpload = event.target.files;
  } 

  saveClientFile(data){
    console.log("main value get start methods",this.formValues);
    console.log("data on save clientFile",data);
    this.currentFileUpload=data.file;
  //  this.headerForm=data.form;
    this.headerForm.patchValue({
      asOfDate: data.form.asOfDate,
    });
    this.headerForm.patchValue({
      state: data.form.state,
    });
    this.headerForm.patchValue({
      isTransposeOnUploadFile: data.form.isTransposeOnUploadFile,
    });
    console.log("main value get",this.headerForm.value);
    if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
      this.toast.showWarn('no file selected','error');
      return;
    }
    //if(this.modalForm.controls.asOfDate.value===null || this.modalForm.controls.file.value===null){
    if(this.headerForm.controls.asOfDate.value===null){
     this.toast.showWarn('ApplicationFile and AsOfDate are mandatory','error');
     return;
    }
    // if(this.headerForm.conrtrols.client.value.key!=0){
    //   this.headerForm.patchValue({
    //     client: this.headerFormVal.client,
    //   });
      
    // }
    // this.loader.showLoader();
     this.showLoading=true;
     //this.visible=false;
  if(this.isZipUpload===false){
     this.clientPortalService.saveClientFile(this.currentFileUpload,this.headerForm,this.state)
     .subscribe(
       data=>{
         this.toast.showSuccess('Success','File Uploaded Successfully');
         //this.clientId=null;
         this.currentFileUpload=null;
        // this.myInputVariable.nativeElement.value = "";
         this.visible=false;
         //this.headerForm.reset();
        // this.headerForm.controls.asofDate.setValue(null);
       //  this.loader.hideLoader();
         this.showLoading=false;
         this.showClientFilePopUp=false;
         this.fetchListing.emit(this.headerForm.value);
        
       },
       error=>{
         this.toast.showError(error.error.message, "error");
      //   this.loader.hideLoader();
         this.visible=false;
         this.showLoading=false;
       },
      
     );
      }
      if(this.isZipUpload===true){
        this.clientPortalService.saveZipClientFile(this.currentFileUpload,this.headerForm,this.state)
     .subscribe(
       data=>{
         this.toast.showSuccess('Success','File Uploaded Successfully');
         //this.clientId=null;
         this.currentFileUpload=null;
        // this.myInputVariable2.nativeElement.value = "";
         this.visible=false;
         //this.headerForm.reset();
         //this.headerForm.controls.asofDate.setValue(null);
        // this.loader.hideLoader();
         this.showLoading=false;
         this.isZipUpload=false;
         this.showClientFilePopUp=false;
         this.fetchListing.emit(this.headerForm.value);
       },
       error=>{
         this.toast.showError(error.error.message, "error");
       //  this.loader.hideLoader();
         this.visible=false;
         this.showLoading=false;
       },
      
     );
      }
     
   }


   selectFile(event) {
  this.currentFileUpload=null;
    this.currentFileUploadList = event.target.files;
    for(let i=0;i<this.currentFileUploadList.length;i++){
      this.fileList.push(this.currentFileUploadList[i]);
      }
    this.currentFileUpload=this.fileList;
  console.log(" file 1 :",this.currentFileUpload);

  }
  selectFile2(event) {
    const file=event.target.files[0];
    console.log("zipupload ",file.type);
    this.currentFileUpload=null;
    if(file.type!=='application/x-zip-compressed'){
      this.toast.showError('error','select zip file');
      this.myInputVariable2.nativeElement.value="";
      event.target=null;
      return;
    }
  
   
    this.currentFileUploadList = event.target.files;
    for(let i=0;i<this.currentFileUploadList.length;i++){
      this.fileList.push(this.currentFileUploadList[i]);
      }
   this.currentFileUpload=event.target.files;
    console.log(" file 2 :",this.currentFileUpload);

  }


  showPopup(){
    // this.popUpHeader="Add Client File";
     this.showClientFilePopUp=true; 
      this.showClientAppFilePopUp=false;
      this.visible=true;
      this.isZipUpload=false;
      // if(this.headerForm.controls.zipUpload && this.headerForm.controls.zipUpload.value===true){
      //   this.isZipUpload=true;
      // }else{
      //   this.isZipUpload=false;
      // }
    
    }
  
    showZipPopup(){
      // this.popUpHeader="Add Client File";
       this.showClientFilePopUp=true; 
        this.showClientAppFilePopUp=false;
        this.visible=true;
        this.isZipUpload=true;
        // if(this.headerForm.controls.zipUpload && this.headerForm.controls.zipUpload.value===true){
        //   this.isZipUpload=true;
        // }else{
        //   this.isZipUpload=false;
        // }
      
      }
  
    doActionData(type){
      if (type === "yes") {
      //  console.log("helloo");
        this.visible = false;
        this.currentFileUpload=null;
        console.log("myinput var ",this.myInputVariable)
        if(this.myInputVariable!==undefined){
        this.myInputVariable.nativeElement.value = "";
        }
        if(this.myInputVariable2!==undefined){
          this.myInputVariable2.nativeElement.value = "";
          }
        
        this.visible=false;
     // this.headerForm.reset();
        this.fileList=[];
        this.currentFileUpload=null;
        this.currentFileUploadList=null;
      }else{
        this.visible = true;
      }
    }

    removeFile(index){
      console.log(index);
      this.fileList=[];
      if(index===0 && this.currentFileUpload.length===1){
        event=null;
        return this.currentFileUpload=null;
      }
      for(let i=0;i<this.currentFileUpload.length;i++){
        this.fileList.push(this.currentFileUpload[i]);
        }
      console.log("before list "+this.fileList)
     // this.fileList = this.fileList.filter(obj => obj !== this.fileList[index]);
     this.fileList.splice(index,1);
     console.log("after list "+this.fileList)
     this.currentFileUpload=this.fileList;
    
    }

    showPopupAddFile(data) {
      this.showClientAppFilePopUp=true;
      this.showClientFilePopUp=false; 
      this.popUpName="Upload Layout File";
      this.visibleLayoutPopUp = true;
     
      if(this.headerForm.controls.client.value>0){
        this.clientId=this.headerForm.controls.client.value;
         }else{
          this.clientId=this.headerForm.controls.client.value.key;
         }
      this.getLayoutField(this.clientId);
      this.getDependentUponData(this.clientId);   
    }
  
    doAddFileAction(type){
      if (type === "yes") {
        console.log("helloo");
        this.visibleLayoutPopUp = false;
      //  this.modalForm.reset();
      this.clientApplicationFileForm.reset();
      this.currentFileUpload=null;
      this.myInputVariable.nativeElement.value = "";
      }else{
        this.clientApplicationFileForm.reset();
        this.visibleLayoutPopUp = false;
      }
    }
    
    onDateChange(e){
      // console.log(e);
      // const asOfDate1=this.datePipe.transform(e,'yyyy-MM-dd');
      // console.log(asOfDate1);
      this.headerForm.controls.asOfDate.setValue(e);
    }
  
    getAllSeparators(){
      this.fieldSeparatorList= [
       { key: ',', value: "Comma" },
       { key: 'space', value: "Space" },
       { key: '|', value: "Pipe(|)" },
       { key: '"', value: "Quotes" },
       { key: ';', value: "Semicolon" },
       { key: ':', value: "Colon"},
       { key: '\t', value: "Tab" }
     ];
   // this.clientApplicationFileForm.get('fieldSeparator').setValue(fieldSeparatorList);
    }
  
    getLayoutField(clientId){
      this.fileService.getAppFilesByClientId(clientId).subscribe(
        (data) => {
          const layoutFieldFile=data;
          for(var a=0;a<layoutFieldFile.length;a++){
          // console.log("type "+layoutFieldFile[a].fileName);
            this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
          }
        },
        (err) => {
        //  this.toast.showSuccess("Status Not Changed", "error");
        }
      
      );
    
    }
    
    getDependentUponData(clientId){
      this.fileService.getDependentUpon(clientId).subscribe(
        (data) => {
          const dependentUpon=data;
          for(var a=0;a<dependentUpon.length;a++){
            console.log("type "+dependentUpon[a].fileName);
            this.dependentUponList.push({ key: dependentUpon[a].clientApplicationFileId, value: dependentUpon[a].fileName });
          }
        },
        (err) => {
        //  this.toast.showSuccess("Status Not Changed", "error");
        }
      
      );
    
    }
          
    getClientApplicationFileTypeData(){
      this.fileService.getClientApplicationFileType(this.state).subscribe(
        (data) => {
          const clientApplicationFileType=data;
          console.log("ClientApplicationFileType",clientApplicationFileType)
          for(var a=0;a<clientApplicationFileType.length;a++){
           // console.log("type "+clientApplicationFileType[a].type);
            this.fileTypeList.push({ key: clientApplicationFileType[a].clientApplicationFileTypeId, value: clientApplicationFileType[a].type });
          }
        },
        (err) => {
        //  this.toast.showSuccess("Status Not Changed", "error");
        this.toast.showError(err.error.message, "error");
        }
      
      );
    
    }

    getApplicationFileType(){
      this.fileService.getApplicationDataTypeByUser().subscribe(
     (data) => {
      console.log("fileDataTypeList main data:",data);
       const fileDataType=data.slice();
      //  fileDataType.splice(15,1);
      //  fileDataType.splice(17,1);
      //  fileDataType.splice(20,1);

       console.log("fileDataTypeList main data after slice:",fileDataType);
       
       for(var a=0;a<fileDataType.length;a++){
        console.log("type////////// "+fileDataType[a].type); 
        this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
        
         
 }
     },
     (err) => {
     //  this.toast.showSuccess("Status Not Changed", "error");
     this.toast.showError(err.error.message, "error");
     }
   
   );
      
   }

   doAction(){
    this.visible=false;
    this.clientFileUpdate=null;
    this.action=null;
    this.deleteVisible=false;
    this.popUpForm.reset();
 }

 downloadFileFormat(){
  this.downloadfileFormat.emit("true");
 }


}
