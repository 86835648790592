import { Component, OnInit, ViewChild, ElementRef,Input,Output,EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { StressScenarioValuesModel } from '../../models/stressScenarioValues.model';
import { StressScenarioValuesService } from '../../services/stressScenarioValues.service';
import { StressScenarioValuesHeaderComponent } from 'src/app/stressScenarioValuesComp/components/stressScenarioValues-header/stressScenarioValues-header.component';
import { StressScenarioService } from '../../services/stressScenario.service';
import { StressScenarioModel } from '../../models/stressScenario.model';

@Component({
  selector: 'app-stressScenarioValues',
  templateUrl: './stressScenarioValues.component.html',
  styleUrls: ['./stressScenarioValues.component.scss']
})
export class StressScenarioValuesComponent implements OnInit {
  // @ViewChild(ClientSelectorComponent, { read: false, static: false })
  // clientSelectorComponent: ClientSelectorComponent;
  @ViewChild(StressScenarioValuesHeaderComponent, { read: false, static: false })
  StressScenarioValuesHeaderComponent: StressScenarioValuesHeaderComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;
// @ViewChild('fromCal')
// fromCalVariable: ElementRef;
stressScenarioList:StressScenarioModel[];
stressScenarioValuesList:StressScenarioValuesModel[];

  //editData:StressScenarioValuesModel[];
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
  setValue: boolean = false;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  state:string;
  @Input() header=true;
  @Input() editSetValueData:StressScenarioValuesModel[];

  @Output() refreshScenario=new EventEmitter();
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService, private sspapplicationService: SspApplicationService,private fileService:FileService,private toast:ToastService,
    private router: Router,private stressScenarioValuesService:StressScenarioValuesService,private stressScenarioService:StressScenarioService,  private applicationService: SspApplicationService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
     
    this.titleService.setTitle("Stress Severity");
    this. searchByCols = [
     
      { field: "stressScenarioName", header: "Stress Scenario Name" },
      { field: "baseline", header: "Baseline" },
      { field: "medium", header: "Medium" },
      { field: "severe", header: "Severe" },
      
    ];

    this.state=localStorage.getItem("stateflag");

    }
  ngOnInit(): void {
   //this.buttonShowHidePermission();
    // this.getAllStressScenarioList();
    this.getAllStressScenarioValuesList();

   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllStressScenarioList(){
    
    this.stressScenarioService.getAllStressScenarioList().subscribe((data)=>{
      this.stressScenarioList=data;
  console.log("stressScenarioList", this.stressScenarioList);
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }


  getAllStressScenarioValuesList(){
    
    this.stressScenarioValuesService.getAllStressScenarioValuesList().subscribe((data)=>{
      this.stressScenarioValuesList=data;
  console.log("stressScenarioValuesList", this.stressScenarioValuesList);
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }
  
  

  saveStressScenarioValues(stressScenarioValuesForm){
    console.log("test",stressScenarioValuesForm)
    const model={
      stressScenarioValueId:stressScenarioValuesForm.stressScenarioValueId!==null?stressScenarioValuesForm.stressScenarioValueId:0,
      stressScenarioId:stressScenarioValuesForm.stressScenarioId,
      stressScenarioName:stressScenarioValuesForm.stressScenarioName,
      status:stressScenarioValuesForm.statusMap.key=='Active'?true:false,
      baseline:stressScenarioValuesForm.baseline,
      medium:stressScenarioValuesForm.medium,
      severe:stressScenarioValuesForm.severe,
      validFromDate:stressScenarioValuesForm.validFromDate,
      validToDate:stressScenarioValuesForm.validToDate
    }

   this.stressScenarioValuesService.saveOrUpdate(model).subscribe(
     (data) => {
  
    //this.toast.showSuccess("Stress Scenario Values Saved Successfully", "success");
    this.toast.showSuccess('Your Change Request Sent for Approval','');
    this.StressScenarioValuesHeaderComponent.reset();
    
    this.getAllStressScenarioValuesList();
    this.refreshScenario.emit();
   
  },
  (err) => {
  
    this.toast.showError(err.error.message, "error");

  }

);
   
 
}


editStressScenarioValues(rowData){
    this.setValue=true;
    this.editSetValueData=rowData;
    this.StressScenarioValuesHeaderComponent.openEdit();
    
  }


  // deleteStressScenarioValuesId(data) {
  //       this.stressScenarioValuesService.deleteStressScenarioValuesById(data.stressScenarioId).subscribe(
  //         data => {
  //             this.toast.showSuccess('Success', 'Stress Scenario Value deleted successfully');
  //             this.getAllStressScenarioValuesList();
  //           },
  //           err => {
  //             //this.toast.showWarn('This Category is already linked with Reports','error');
  //             this.toast.showError(err.error.message, "error");
  //           });
  //     }

}
