import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { StressKeyMetricsSelectorComponent } from '../stressKeyMetrics-selector/stressKeyMetrics-selector.component';

@Component({
  selector: 'app-stressKeyMetrics-header',
  templateUrl: './stressKeyMetrics-header.component.html',
  styleUrls: ['./stressKeyMetrics-header.component.scss']
})
export class StressKeyMetricsHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(StressKeyMetricsSelectorComponent, { read: false, static: false })
  StressKeyMetricsSelectorComponent: StressKeyMetricsSelectorComponent;

  
  @Input() editData;
  @Output() save=new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }


  saveStressKeyMetrics(data){
    console.log("data",data);
this.save.emit(data);

  }

 

  addNewStressKeyMetrics(){

     this.StressKeyMetricsSelectorComponent.formReset();
     this.panelForm.toggleSlider();

    }

    reset(){

     this.StressKeyMetricsSelectorComponent.formReset();
     //this.panelForm.toggleSlider();
    }

 openEdit(){
  
  this.panelForm.toggleSlider();
 }

}
