import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-videocategory-table',
  templateUrl: './videocategory-table.component.html',
  styleUrls: ['./videocategory-table.component.scss']
})
export class VideoCategoryTableComponent implements OnInit {
 @Input() categoryList:any[];
 @Input() rolePermission:any;
 @Output() edit = new EventEmitter();
 @Output() delete = new EventEmitter();
 dataValue:any;
 visibleDelete: boolean = false;


  constructor() { }

  ngOnInit(): void {
  }

  editCategory(rowData){
  this.edit.emit(rowData);
  }


  showPopupDelete(rowData) {
        this.dataValue = rowData;
        this.visibleDelete = true;
      }
    
      hidePopup() {
        debugger;
        this.dataValue = null;
        this.visibleDelete = false;
      }
    
      actionDo(str){
        this.deleteCategoryId(this.dataValue);
        this.dataValue=null;
        this.visibleDelete=false;
      }
    
      deleteCategoryId(rowData){
        this.delete.emit(rowData);
      }
}
