import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { CategoryModel } from "../models/category.model";
import { RwaPercentModel } from "../models/rwaPercent.model";

@Injectable({
    providedIn: 'root'
  })
export class RwaPercentService {
  
  
  /**
   * @param  {HttpRwaPercent} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  saveOrUpdate(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/stressTesting/createRwaPercent",data);
} 


getAllRwaPercentList(){
  return this.http.get<any[]>(environment.baseUrl + "/apis/getRwaPerPercentAll");
}

deleteRwaPercentId(stressRiskFactorId) {
    return this.http.delete<RwaPercentModel[]>(environment.baseUrl + "/apis/deleteRwaPerProvision/"+stressRiskFactorId);
  }




  }

