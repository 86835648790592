import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { WorkflowService } from '../../services/workflow.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { CookieService } from "ngx-cookie-service";
import { FileService } from 'src/app/files/services/file.service';
import { ClientService } from '../../services/client.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SchedulerLogModel } from '../../models/schedulerLog.model';
import { DatePipe } from "@angular/common";
import { currentMonthRange, dateBetween } from "src/app/utils/dates";
import {isEmpty} from 'lodash';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { SspApplicationService } from '../../services/ssp-application.service';
import { SchedulerWorkFlow } from '../scheduler/scheduler.model';
import { LookupService } from '../../services/lookup.service';
import { LookUpListModel } from '../../models/lookup.model';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-scheduler-list',
  templateUrl: './scheduler-list.component.html',
  styleUrls: ['./scheduler-list.component.scss']
})
export class SchedulerListComponent implements OnInit {
  @ViewChild("panelQueueFilter", {static: false })
  panelQueueFilter!:SidePanelComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  activeRoute$: Subscription;
  schedulerList: any = [];
  editSchedulerValue: any = [];
  viewMode: boolean = false;
  editMode: boolean = false;
  frequencyDisabled: string = null;
  rolePermission:any;
  clientData:any;
  workflowData:any;
  headerForm: FormGroup = null;
  logData:SchedulerLogModel[];
  companyName:String;
  fromDateValue;
  toDateValue;
  workflowDropDown: any[] = [];
  applicationData: any[] = [];
  reportDropDown: any[] = [];
  workflowId: number;
  workflowName: string;
  reportId: number;
  reportName: string;
  schedulerWorkFlow: SchedulerWorkFlow;
  enableWorkFlow: number;
  scheduleWorkFlowValue:string= "Yes";
  radioScheduleWorkflowSelected:string;
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  viewWorkflowScheduler:boolean=true;
  companyId:any;
  adminFlag:String;
  state:string;
  lookUpModelList: LookUpListModel[] = [];
  frequencyList: {key: any; value: any }[]=[];
  reportFrequency:string;
  constructor(private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private workflowService: WorkflowService,
    private toast: ToastService,
    private router: Router,
    private cookieService: CookieService, private fileService: FileService,private clientService:ClientService,private datePipe: DatePipe,private sspApplicationService:SspApplicationService,
    private lookupService: LookupService) { 


      this.headerForm = new FormGroup({
        client: new FormControl(null, [Validators.required]),
        //dateRange: new FormControl(currentMonthRange(), [Validators.required]),
        workflow: new FormControl(null),
        report: new FormControl(null),
        fromDate: new FormControl(null, [Validators.required]),
        toDate: new FormControl(null, [Validators.required]),
        viewSchedulars:new FormControl(true)
      });
      
      this.searchByCols = [
     
        { field: "schedulerName", header: "Scheduler Name" },
        { field: "scheduleType", header: "Scheduler Type" },
        { field: "freequency", header: "Frequency" },
        // { field: "status", header: "Status" }
        
      ];
      this.state=localStorage.getItem("stateflag");
    }

  ngOnInit(): void {
   this.adminFlag=this.cookieService.get("adminFlag");
   this.buttonShowHidePermission();
   this.getAllFrequencyType();
   //this.getWorkFlowScheduler();
   //this.getAllWorkflow();
   //this.getAllReportData();
   //this.getSchedulerData;
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  }

  onLoad(flag){
    if(flag==="onLoad"){
    this.panelQueueFilter.toggleSlider();
    }
    this.companyName=this.headerForm.controls.client.value.value;
    this.fromDateValue=this.datePipe.transform(this.headerForm.controls.fromDate.value,"MM/dd/yyyy");
    this.toDateValue=this.datePipe.transform(this.headerForm.controls.toDate.value,"MM/dd/yyyy");
    if(this.viewWorkflowScheduler){
    this.getSchedulerData(this.headerForm.controls.client.value, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    else{
      this.getRepotSchedulersByClient(this.headerForm.controls.client.value, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

 getWorkFlowScheduler() {

    this.workflowService.getScheduler().subscribe((data) => {
        this.schedulerList = data;
        //this.toast.showSuccess(' successfully','success');
},
(err) => {
  //this.toast.showError(err.error.message, "error");
  
}
);
}

  editScheduler(data: any) {
    // this.getClient(data.clientId);
    // this.getWorkflowById(data);
    
    this.panelForm.toggleSlider();
    this.viewMode = false;
    this.editMode = true;
    this.cookieService.set('schedulerId', data.schedulerId);
    this.cookieService.set('viewMode', this.viewMode.toString());
    this.cookieService.set('editMode', this.editMode.toString());


     const fileData = {
      clientId: data.clientId,
      clientName:data.clientName,
     };
    //  let page = 'scheduler';
    //  this.router.navigate([page], {
    //   queryParams: {...fileData},
    // });
          this.headerForm.get("client").setValue({
            key: fileData.clientId,
            value: fileData.clientName
          });
         
          if (this.editMode || this.viewMode) {
            // if (this.scheduleWorkflow) {
              if(this.viewWorkflowScheduler){
                this.cookieService.set('workflowId', data.workflowId);
                this.cookieService.set('workflowName', data.workflowName);
                this.cookieService.set('reportId', data.reportId);
                this.cookieService.set('reportName', data.reportName);
                this.enableWorkFlow = 1;
                this.workflowId = data.workflowId
              //this.scheduleWorkFlowValue = 'Yes';
                this.radioScheduleWorkflowSelected = 'Yes';
                this.getAllWorkflow();
                   this.getCookies('workflowScheduler');
              }
              else{
                this.cookieService.set('reportId', data.reportId);
                this.cookieService.set('reportName', data.reportName);
                this.reportId = data.reportId
                // this.enableWorkFlow = 2;
                this.radioScheduleWorkflowSelected = 'No';
                this.getAllReportData();
                this.getCookies('reportScheduler');

              }
          }
         
       
          
       
          // this.headerForm.get("workflow").setValue({
          //   key: fileData.workflowId,
          //   value: fileData.workflowName,
          // });
          //this.getAllWorkflow();

  }

  viewScheduler(data: any) {
    // this.getClient(data.clientId);
    // this.getWorkflowById(data);
    this.panelForm.toggleSlider();
    this.viewMode = true;
    this.editMode = false;;
    this.cookieService.set('schedulerId', data.schedulerId);
    this.cookieService.set('viewMode', this.viewMode.toString());
    this.cookieService.set('editMode', this.editMode.toString());
    const fileData = {
      clientId: data.clientId,
      clientName:data.clientName
     };
    // let page = 'scheduler';
    // this.router.navigate([page], {
    //   queryParams: {...fileData},
    // });
    this.headerForm.get("client").setValue({
      key: fileData.clientId,
      value: fileData.clientName
    });
   
    if (this.editMode || this.viewMode) {
      // if (this.scheduleWorkflow) {
        if(this.viewWorkflowScheduler){
          this.cookieService.set('workflowId', data.workflowId);
          this.cookieService.set('workflowName', data.workflowName);
          this.cookieService.set('reportId', data.reportId);
          this.cookieService.set('reportName', data.reportName);
          this.enableWorkFlow = 1;
          this.workflowId = data.workflowId
        //this.scheduleWorkFlowValue = 'Yes';
          this.radioScheduleWorkflowSelected = 'Yes';
          this.getAllWorkflow();
             this.getCookies('workflowScheduler');
        }
        else{
          this.cookieService.set('reportId', data.reportId);
          this.cookieService.set('reportName', data.reportName);
          this.reportId = data.reportId
          // this.enableWorkFlow = 2;
          this.radioScheduleWorkflowSelected = 'No';
          this.getAllReportData();
          this.getCookies('reportScheduler');

        }
    }
  }


  navigate() {
    this.cookieService.delete('schedulerId');
    this.cookieService.delete('viewMode');
    this.cookieService.delete('editMode');
    let page = 'scheduler';
    if((this.cookieService.get('clientId')==='') || (this.cookieService.get('clientId')===undefined)){
    this.router.navigate([page], {
      queryParams: {},
    });
  }else{
    const fileData = {
      clientId:this.cookieService.get('clientId'),
      clientName:this.cookieService.get('clientName'),
     };
    this.router.navigate([page], {
      queryParams: {...fileData},
    });
  }
  }



  setStatus(data) {

    const setdata = {
      isActive: data.isActive,
      schedulerId:data.schedulerId
    };
    if(this.viewWorkflowScheduler){
    this.workflowService.setSchedulerStatus(setdata).subscribe(
      (data) => {


       // this.getWorkFlowScheduler();
       this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
       this.viewWorkflowScheduler=true;
       this.headerForm.get('viewSchedulars').setValue(true);
       
       this.toast.showSuccess("Done", "successfully");
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
    }
    else if(!this.viewWorkflowScheduler){
      this.workflowService
      .setNotificationSchedulerStatus(setdata)
      .subscribe((data) => {
       
      
        this.getRepotSchedulersByClient(this.headerForm.controls.client.value, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  
       this.viewWorkflowScheduler=false;
       this.headerForm.get('viewSchedulars').setValue(false);
       this.toast.showSuccess("Done", "successfully");
      },
        (err) => {
          this.toast.showError(err.error.error, "error");
        }
      );
    }

  }


  deleteScheduler(data) {
    if(this.viewWorkflowScheduler){
    console.log("data  ", data)
    this.workflowService.deleteScheduler(data.schedulerId).subscribe(
      (data) => {


        //this.getWorkFlowScheduler();
        this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        this.toast.showSuccess("Delete", "successfully");
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }

    );
  }
  }

  deleteNotificationScheduler(notificationData) {
    if(!this.viewWorkflowScheduler){
    console.log("NotificationData  ", notificationData)
    this.workflowService.deleteNotificationScheduler(notificationData.schedulerId).subscribe(
      (notificationData) => {


        //this.getWorkFlowScheduler();
        this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        this.toast.showSuccess("Delete", "successfully");
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }

    );
  }
  }


  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.setTitle();
      this.loadDate();

      this.fileService.getClientByUserId().subscribe(
        (data) =>{
        
          for(var i=0;i<data.length;i++){
            console.log("data  ",data[i].clientId)
            this.companyId=data[i].clientId
            this.companyName=data[i].clientName
          }
          this.headerForm.get("client").setValue({
            key: this.companyId,
            value: this.companyName
          });

           this.getAllWorkflow();
           this.getAllReportData();
           console.log(this.headerForm.controls.client.value.key,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);

           this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
           this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
  
         // this.getAllDbCoonByComapnyId( this.headerForm.controls.company.value.key);
  
         console.log("this.headerForm.controls.fromDate.value ",this.headerForm.value)
  
         //this.onClientChange(this.headerForm.controls.client.value);
          this.onLoad('onInit');
  
        },
        (err)=>{
          this.toast.showError(err.error.message, "error");
        }
        );

      //this.getPreviousState();
      
    }

    getClient(clientId){
      this.clientService.getClientsById(clientId).subscribe((data) => {
        this.clientData=data;
        const mapVal={
          "clientId":this.clientData.clientId,
          "clientName":this.clientData.clientName
        }
      //   this.headerForm.controls.client.setValue(mapVal);
       
        },
      (err) =>{
        this.toast.showError(err.error.message, "error");
       } 
      ); 
      }

      getWorkflowById(data){
        const workflowModelConst={ 
          "workflowId":data.workflowId,
         }
        this.workflowService.getWorkflowById(workflowModelConst)
        .subscribe(
          data=>{
             this.workflowData=data;
          },
          err=>{
         //   this.workflowJobListEdit=data;
          }
        )
      }


      onClientChange(event){
        //this.headerForm.controls.workflow.reset();
        this.getAllWorkflow();
        this.getAllReportData();
        console.log(this.headerForm.controls.client.value.key,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        // this.getSchedulerData(this.headerForm.controls.client.value, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
          this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
          this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
        //this.maintainClientState(event,this.headerForm.controls.fromDate.value, this.headerForm.controls.toDate.value);
      }


      changeToDate(e){
        this.headerForm.controls.toDate.setValue(e.target.value);
        console.log(this.headerForm.controls.toDate.value)
        let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        if(validate===true){
          // this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
          this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
          this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
        //this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        }
        if(validate===false){
          this.toast.showWarn('error','date range incorrect')
          
        }
        //console.log(e,'  todate');
        //this.maintainStateOnDateChange(e,'toDateChange');
      }
    
      changeFromDate(e){
    
        this.headerForm.controls.fromDate.setValue(e.target.value);
        console.log( this.headerForm.controls.fromDate.value)
        let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        if(validate===true){
          this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
          this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
          // this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        }
        if(validate===false){
         this.toast.showWarn('error','date range incorrect')
          
        }
        //this.maintainStateOnDateChange(e,'fromDateChange');
      }

      validationOnDate(fromdate,todate){
        if(fromdate && todate){
        if(new Date(fromdate)>new Date(todate)){
        
            return false;
          }
          return true;
          
        }else{
          return false;
        }
      }


      getSchedulerData(client,fromDate,toDate){

        
       this.workflowService.getSchedulersByClientId(client.key, fromDate,toDate).subscribe(
          data=>{
             this.schedulerList=data;
             this.schedulerList.forEach(item=>{
              item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
              item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM/dd/yyyy")
            
             })
          },
          err=>{
         //   this.workflowJobListEdit=data;
            this.toast.showError(err.error.message, "error");
          }
        )

      }

      getLogData(data){
        this.workflowService.getSchedulerLogData(data.schedulerId)
        .subscribe(
          data=>{
             this.logData=data;
             console.log("data"+this.logData);
          },
          err=>{
         //   this.workflowJobListEdit=data;
          }
        )
      }

      getPreviousState() {
        const clientIdC=this.cookieService.get('clientId');
        const clientNameC=this.cookieService.get('clientName');
        //console.log("cookies check ",this.cookieService.get('toDaaaate')===null)
        //console.log("cookies check ",this.cookieService.get('toDaaaate'))
        // console.log(clientIdC);
        // console.log(clientNameC);
        // console.log("from date ",fromDatec);
        // console.log("todate ",toDatec);
        if(clientIdC!==undefined && clientIdC!==null){
          this.headerForm.get("client").patchValue({
                   key: clientIdC,
                   value: clientNameC,
                   });
                   this.getAllWorkflow();
                  
        }

        if(!isEmpty(this.headerForm.get("client").value.key)){
          this.getSchedulerData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
        }
       
      }



      loadDate(){
        let currentDateRange=currentMonthRange();
        //console.log(currentDateRange,'  current date range');
        
        this.headerForm.controls.fromDate.setValue(this.datePipe.transform(currentDateRange[0],"yyyy-MM-dd"));
        this.headerForm.controls.toDate.setValue(this.datePipe.transform(currentDateRange[1],"yyyy-MM-dd"));
       console.log(this.headerForm.controls.fromDate.value,'  current date range ',this.headerForm.controls.toDate.value);
        
      }
      
      getAllWorkflow() {
        // console.log("getting called");
       // document.getElementById("workflowDropdownValue").style.display="";
        this.workflowDropDown = [];
    
        // this.setupJobForm.controls.workflow.reset();
        const requestModel = {
          "clientId": this.headerForm.controls.client.value.key
        }
    
        this.workflowService.getAllWorkflow(requestModel)
          .subscribe(
            (data) => {
              this.workflowData = data;
    
              this.workflowData.forEach(item => {
                this.workflowDropDown.push({ key: item.workflowId, value: item.workflowName })
              })
              if(this.editMode || this.viewMode){
               
                

              this.headerForm.get("workflow").setValue({
                key:  this.cookieService.get('workflowId'),
                value: this.cookieService.get('workflowName')
              });

              this.headerForm.get('report').setValue({
                key: this.schedulerWorkFlow.reportId,
                value: this.cookieService.get('reportName')
              })

              this.getWorkflowByReportId(this.cookieService.get('workflowId'));
            }
    
            },
            (err) => {
    
              this.toast.showError('error', err.error.message);
            }
          )
    
      }

      getAllReportData() {
        this.sspApplicationService.getAppDataForReportPage().subscribe(
          (data) => {
         //   this.loader.hideLoader();
            this.applicationData = data;
            console.log('App Data Is:', this.applicationData);
            this.applicationData.forEach(item => {
              if(item.isActive && item.reportMode==="readytouse"){
              item.createdAt = this.datePipe.transform(item.createdAt, 'MM/dd/yyyy');
              this.reportDropDown.push({ key: item.appId, value: item.appName })
              }
            });

            if(this.editMode || this.viewMode){
                this.headerForm.get('report').setValue({
                  key:this.cookieService.get('reportId'),
                  value:this.cookieService.get('reportName')
                })

            }
            console.log("ReportData: ",this.reportDropDown);
          },
          (err) => {
         //   this.loader.hideLoader();
            this.toast.showError(err.error.message, 'error');
          });
      }

      getWorkflowByReportId(appId){
        if(this.enableWorkFlow!= 1){
          return;
        }
        this.workflowService.getWorkflowByReportId(appId).subscribe(
          (data) => {
            this.headerForm.get("workflow").setValue({
              key:  data[0].workflowId,
              value: data[0].workflowName
            });
            this.workflowId = data[0].workflowId;
          },
          (err) => {
         //   this.loader.hideLoader();
            this.toast.showError(err.error.message, 'error');
          });
      }

      getReportFrequency(appId){
        this.applicationData.forEach(item => {
          if(item.appId==appId){
            console.log("Report Frequency: ", item.frequency);
            if(item.frequency!= null){
              this.frequencyDisabled = "true";
            }
            else{
              this.frequencyDisabled = null;
            }
            console.log("frequencyDisabled: ",this.frequencyDisabled);
           return this.reportFrequency = item.frequency;
          }
        });
      }

      getWorkflow(event) {
        this.workflowId = this.headerForm.controls.workflow.value.key
        console.log("id  ", this.workflowId)
        this.workflowName = this.headerForm.controls.workflow.value.value
        console.log("name  ", this.workflowName)
      }

      getReport(event){
        this.reportId = this.headerForm.controls.report.value.key
        console.log("id  ", this.reportId)
        this.getWorkflowByReportId(this.reportId);
        this.getReportFrequency(this.reportId);
        this.workflowName = this.headerForm.controls.workflow.value.value
        console.log("name  ", this.reportName)
      }

      addScheduler(data: any) {
        //this.panelForm.toggleSlider();

        console.log("this.workflowId ",this.headerForm.controls.workflow.value)
        console.log("header form", this.headerForm);
        if((this.workflowId==null || this.workflowId==undefined || this.workflowId==0) && (this.reportId==null || this.reportId==undefined || this.reportId==0)){
          this.toast.showWarn('Select Workflow or Report is  mandatory ','error');
          return;
        }
    
    
        if (data.scheduleType === 'Not Recurring') {
          data.startDate = data.startDate;
        }
        const schedulerModel = {
          schedulerId: this.schedulerWorkFlow !== null ? this.schedulerWorkFlow?.schedulerId : 0,
          workflowId: this.workflowId,
          reportId: this.reportId,
          schedulerName: data.schedulerName,
          scheduleType: data.scheduleType,
          freequency: data.occurs,
          dailyFreequencyOccurOnlyOnce: data.dailyFrequencyOccurOnlyOnce,
    
          dailyFreequencyCount: data.dailyFrequencyCount,
          dailyFreequency: data.dailyFrequency,
          dailyFreequencyStartTime: data.dailyFrequencyStartTime,
          dailyFreequencyEndTime: data.dailyFrequencyEndTime,
          weekDaysList: data.onWeeks,
          weekDays: null,
    
          weekCount: data.recursEveryWeeks,
    
          // monthlyDayCount: data.monthlyDayCount,
          // monthlyMonthNumber: data.monthlyMonthNumber,
          // monthlyDayPosition: data.monthlyDayPosition,
          // monthlyWeekDay: data.monthlyWeekDay,
          startingMonthlyDate: data.startingMonthlyDate,
    
          startDate: data.startDate,
          endDate: data.endDate,
          isActive: true,
          dailyDayCount: data.recursEveryDays,
          clientId:this.headerForm.controls.client.value.key,
          endDateEnable:data.endDateEnable,
    
        }
    
        console.log("extract inputs ", schedulerModel)
        if(schedulerModel.workflowId!=null && schedulerModel.workflowId!=undefined && schedulerModel.workflowId!=0 && this.enableWorkFlow == 1){
        this.workflowService
          .saveScheduler(schedulerModel)
          .subscribe((data) => {
            // this.userSelectorComponent.userForm.reset();
            this.schedulerWorkFlow = null;
            
            this.toast.showSuccess('saved successfully', 'success');
            this.getSchedulerData(this.headerForm.controls.client.value, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
            //this.router.navigate(['schedulerlist']);
            this.viewWorkflowScheduler=true;
            this.headerForm.get('viewSchedulars').setValue(true);
          },
            (err) => {
              this.toast.showError(err.error.error, "error");
            }
          );
        }
    
        else if(schedulerModel.reportId!=null && schedulerModel.reportId!=undefined && schedulerModel.reportId!=0){
          this.workflowService
          .saveNotificationScheduler(schedulerModel)
          .subscribe((data) => {
            // this.userSelectorComponent.userForm.reset();
            this.schedulerWorkFlow = null;
            
            this.toast.showSuccess('saved successfully', 'success');
            this.getRepotSchedulersByClient(this.headerForm.controls.client.value, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
           // this.router.navigate(['schedulerlist']);
           this.viewWorkflowScheduler=false;
           this.headerForm.get('viewSchedulars').setValue(false);
          },
            (err) => {
              this.toast.showError(err.error.error, "error");
            }
          );
        }
        this.resetScheduling('true');
    
      }

      getCookies(type) {
        const sheduleId = this.cookieService.get('schedulerId')
        const viewModeString = this.cookieService.get('viewMode')
        const editModeString = this.cookieService.get('editMode')
        if (viewModeString === 'true') {
          this.viewMode = true;
        }
        if (editModeString === 'true') {
          this.editMode = true;
        }
        console.log("sheduleIdcookie ", sheduleId);
        if(type==="workflowScheduler"){

          if (sheduleId) {
            this.workflowService.getSchedulerById(sheduleId).subscribe(
              (data: SchedulerWorkFlow) => {
                //this.editSchedulerValue = data;
                this.schedulerWorkFlow = data;
                // this.getClient(data.clientId);
                // console.log(" client data",  this.headerForm.controls.client.value);
                this.workflowId=data.workflowId;
                console.log(" this.SchedulerWorkFlow ", this.schedulerWorkFlow);
              },
              (err) => {
                this.toast.showError(err.error.message, "error");
              }
            );
          }

        }

        if(type==="reportScheduler"){
          if (sheduleId) {
            this.workflowService.getNotificationSchedulerById(sheduleId).subscribe(
              (data: SchedulerWorkFlow) => {
                //this.editSchedulerValue = data;
                this.schedulerWorkFlow = data;
                // this.getClient(data.clientId);
                // console.log(" client data",  this.headerForm.controls.client.value);
                this.workflowId=data.workflowId;
                console.log(" this.SchedulerWorkFlow ", this.schedulerWorkFlow);
              },
              (err) => {
                this.toast.showError(err.error.message, "error");
              }
            );
          }

        }
       
      }

      showHideOccursOnceRadioButton(data: SchedulerWorkFlow) {
        if (this.editMode || this.viewMode) {
          // if (this.scheduleWorkflow) {
            this.enableWorkFlow = 1;
            this.scheduleWorkFlowValue = 'Yes';
            this.radioScheduleWorkflowSelected = 'Yes';
        }
      }

      callonce() {
        this.enableWorkFlow = 1;
        if (this.editMode) {
          // this.schedulerForm.patchValue({
          //   dailyFrequencyOccurOnlyOnce: true,
          //   dailyFrequencyCount: this.schedulerWorkFlow.dailyFreequencyCount,
          //   dailyFrequency: this.schedulerWorkFlow.dailyFreequency,
          //   dailyFrequencyStartTime: this.schedulerWorkFlow.dailyFreequencyStartTime,
          //   dailyFrequencyEndTime: this.schedulerWorkFlow.dailyFreequencyEndTime,
      
          // });
        }
        else {
          // this.schedulerForm.patchValue({
          //   dailyFrequencyOccurOnlyOnce: true,
          //   dailyFrequencyCount: null,
          //   dailyFrequency: null,
          //   dailyFrequencyStartTime: null,
          //   dailyFrequencyEndTime: null,
      
          // });
        }
      }

      callevery() {
        // this.enableWorkFlow = 2;
        if (this.editMode) {
          // this.schedulerForm.patchValue({
          //   dailyFrequencyOccurOnlyOnce: false,
          //   dailyFrequencyStartTime: this.schedulerWorkFlow.dailyFreequencyStartTime,
          // });
        }
        else {
          // this.schedulerForm.patchValue({
          //   dailyFrequencyOccurOnlyOnce: false,
          //   dailyFrequencyStartTime: null,
          //   dailyFrequency: "hour",
          // });
        }
      }


      actionDo(){
      
       
       if(this.headerForm.get('viewSchedulars').value){
        this.viewWorkflowScheduler=true;
       }
       else{
        this.viewWorkflowScheduler=false;
       }
       console.log("checkbox status", this.viewWorkflowScheduler);
      }


      getRepotSchedulersByClient(client,fromDate,toDate){

        this.workflowService.getReportSchedulersByClientId(client.key, fromDate,toDate).subscribe(
          data=>{
             this.schedulerList=data;
             this.schedulerList.forEach(item=>{
              item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
              item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM/dd/yyyy")
            
             })
          },
          err=>{
         //   this.workflowJobListEdit=data;
            this.toast.showError(err.error.message, "error");
          }
        )
      }


      getAllFrequencyType(){
        const lookupModel = {
          "tableName":"frequency",
          "clientId": 139
        }
        this.lookupService.getLookUpDataByTableAndClient(lookupModel)
         .subscribe(
           data=>{
             this.lookUpModelList=data;
            this.frequencyList=reMapForAutoComplete(this.lookUpModelList,"primaryId","description");
            console.log("Inside Scheduler: ",this.frequencyList);
           },
           err=>{
            this.toast.showError(err.error.message, "error");
            //this.loader.hideLoader();
          }
         )
        
       }

       resetScheduling(value: string){
        // this.enableWorkFlow = 0;
        this.headerForm.get("report").patchValue(
          {
            key: null,
            value: null,
          }
        );
        this.viewMode = false;
        this.radioScheduleWorkflowSelected = '';
       }

      
      
}
