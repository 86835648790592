import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { RwaPercentHeaderComponent } from 'src/app/rwaPercentComp/components/rwapercent-header/rwapercent-header.component';
import { ShockMasterService } from '../../services/shockMaster.service';
import { ShockMasterModel } from '../../models/shockMaster.model';
import { ShockMasterHeaderComponent } from 'src/app/shockMasterComp/components/shockMaster-header/shockMaster-header.component';
import { StressScenarioService } from '../../services/stressScenario.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SspApplicationService } from '../../services/ssp-application.service';

@Component({
  selector: 'app-shockMaster',
  templateUrl: './shockMaster.component.html',
  styleUrls: ['./shockMaster.component.scss']
})
export class ShockMasterComponent implements OnInit {

  @ViewChild(ShockMasterHeaderComponent, { read: false, static: false })
  ShockMasterHeaderComponent: ShockMasterHeaderComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;

  stressShocksList: any[];
  editData: ShockMasterModel=null;
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  state:string;
  stressScenarioList:any=[];
  reportLists:any=[];
  menu:string;
  submenu:string;
  
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService,private toast:ToastService,
    private router: Router,private ShockMasterService:ShockMasterService,private sspapplicationService:SspApplicationService,private stressScenarioService:StressScenarioService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
     
    this.titleService.setTitle("Risk Factors");
    this. searchByCols = [
     
       { field: "shockName", header: "Shock Name" },
       { field: "description", header: "Description" }
      
    ];
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag");
    }
  ngOnInit(): void {
   //this.buttonShowHidePermission();
   this.getAllStressScenarioList();
this.getAllShockList();
this.getAllReportsforShock();
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllShockList(){
    
    this.ShockMasterService.getAllShockList().subscribe((data)=>{
      this.stressShocksList=data;
  
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }

saveData(shockForm){

  let stressShocksScenarioList = shockForm.stressScenarioList;


  let stressShocks={
    //stressShocksId:shockForm.stressShocksId!=null?shockForm.stressShocksId:0,
    update:shockForm.update,
    stressShocksCode:shockForm.shockCode,
    shockName:shockForm.shockName,
    status:shockForm.statusMap.key=='Active'?true:false,
    description:shockForm.description,
    reportId:shockForm.report.key,
    validFrom:shockForm.validFrom,
    validTo:shockForm.validTo,
    
  }
  console.log("stressShocks",stressShocks)

const ShocksData={
  "stressShocks": stressShocks,
  "stressShocksScenarioList": stressShocksScenarioList

}
console.log("ShocksData Model: ",ShocksData);
this.ShockMasterService.saveOrUpdate(ShocksData).subscribe(
  (data) => {
 this.toast.showSuccess("Shock Saved Successfully", "success");
 this.ShockMasterHeaderComponent.reset();
 this.getAllShockList();
},
(err) => {
  if(err.status===208){
    this.toast.showInfo(err.error.text, "Info");
  }else{
    this.toast.showError(err.error.text, "error");
  }
 
}

);


console.log("ShocksData123  ",ShocksData)


}


editShock(rowData){
  console.log("Main Component EditShock");
    this.editData=rowData;
    this.ShockMasterHeaderComponent.openEdit();
    
  }


  deleteShockMasterId(data) {
        const shocksModel ={
         "stressShocksCode": data.stressShocks.stressShocksCode
       }
    console.log("delete data",data);
        //this.loader.showLoader();
        this.ShockMasterService.deleteShockMasterId(shocksModel).subscribe(
          data => {
              this.toast.showSuccess( data.message,"Success");
              this.getAllShockList();
            },
            (err) => {
              this.toast.showError(err.error.message,"error");
              
            });
      }

      getAllStressScenarioList(){
    
        this.stressScenarioService.getAllStressScenarioList().subscribe((data)=>{
          this.stressScenarioList=this.reMapForReportAutoCompleteMultiSelect(data, "stressScenarioId", "stressScenarioName");
      console.log("stressScenarioList", this.stressScenarioList);
         
        },
        (err) => {
          this.toast.showError(err.error.message, "error");
          
        }
        );
      }
      reMapForReportAutoCompleteMultiSelect(
        data: any[],
        keyCol: string,
        valCol: string
      ): { stressScenarioId: string | number; stressScenarioName: string }[] {
        return data.map(d => ({ stressScenarioId: d[keyCol], stressScenarioName: d[valCol] }));
      }

      getAllReports(){
  
        this.sspapplicationService.getAllReports().subscribe(
          (data) => {
           // console.log("Applications",data);
            this.reportLists = reMapForAutoComplete(data, "appId", "appName");
            console.log("Applications",this.reportLists);
          },
          (err) =>{
          //  this.toast.showError(err.error.message, "error");
          } 
        );
      }

      getAllReportsforShock(){
        this.ShockMasterService.getApplicationDataforRisk().subscribe(
          (data) => {
           // console.log("Applications",data);
            this.reportLists = reMapForAutoComplete(data, "appId", "appName");
            console.log("Applications",this.reportLists);
          },
          (err) =>{
          //  this.toast.showError(err.error.message, "error");
          } 
        );
      }

}
