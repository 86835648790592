import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { CalendarModule } from "primeng/calendar";
import { TableModule } from "primeng/table";

import { AutoCompleteModule } from "primeng/autocomplete";
import { RowDirective } from "./directives/row.directive";
import { ColumnDirective } from "./directives/column.directive";
import { ValidatorsModule } from "../validators/validators.module";
import { FormsyModule } from "../formsy/formsy.module";
import { ClientListComponent } from "./components/client-list/client-list.component";
import { ModalPopupComponent } from "./components/modal-popup/modal-popup.component";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { ModalPopup2Component } from './components/modal-popup2/modal-popup2.component';
import { IconComponent } from './components/icon/icon.component';
import { AccordionFormComponent } from "./components/accordion-form/accordion-form.component";
import { PersonalizeFormComponent } from "./components/personalize-form/personalize-form.component";
import { SelectComponent } from "./components/select/select.component";
import { PaginateButtonsComponent } from "./components/paginate-buttons/paginate-buttons.component";
import { PaginateTextComponent } from "./components/paginate-text/paginate-text.component";
import { MenuBarComponent } from "./components/menu-bar/menu-bar.component";
import { MenuSetComponent } from "./components/menu-set/menu-set.component";
import { MainLogoComponent } from "./components/main-logo/main-logo.component";
import { MenuSetSwitchComponent } from "./components/menu-set-switch/menu-set-switch.component";
import { MenuSetSwitchItemComponent } from "./components/menu-set-switch-item/menu-set-switch-item.component";
import { MenuSetSwitchActionItemComponent } from "./components/menu-set-switch-action-item/menu-set-switch-action-item.component";
import { FilterByPipe } from "./pipes/filter-by.pipe";
import { HeaderStepperComponentComponent } from "./components/header-stepper-component/header-stepper-component.component";
import { RouterModule } from "@angular/router";




@NgModule({
  declarations: [
    RowDirective,
    ColumnDirective,
    ClientListComponent,
    ModalPopupComponent,
    TruncatePipe,
    SafePipe,
    ModalPopup2Component,
    IconComponent,
    AccordionFormComponent,
    PersonalizeFormComponent,
    SelectComponent,
    PaginateButtonsComponent,
    PaginateTextComponent,
    MenuBarComponent,
    MenuSetComponent,
    MainLogoComponent,
    MenuSetSwitchComponent,
    MenuSetSwitchItemComponent,
    MenuSetSwitchActionItemComponent,
    FilterByPipe,
    HeaderStepperComponentComponent

  ],
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    DialogModule,
    CalendarModule,
    AutoCompleteModule,
    ValidatorsModule,
    FormsyModule,
    TableModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    CalendarModule,
    AutoCompleteModule,
    ValidatorsModule,
    ColumnDirective,
    RowDirective,
    FormsyModule,
    ClientListComponent,
    TableModule,
    ModalPopupComponent,
    SafePipe,
    ModalPopup2Component,
    IconComponent,
    AccordionFormComponent,
    PersonalizeFormComponent,
    SelectComponent,
    PaginateButtonsComponent,
    PaginateTextComponent,
    MenuBarComponent,
    MenuSetComponent,
    MainLogoComponent, 
    MenuSetSwitchComponent,
    MenuSetSwitchItemComponent,
    MenuSetSwitchActionItemComponent,
    FilterByPipe,
    HeaderStepperComponentComponent,
    
    
  ],
})
export class SharedModule {}
