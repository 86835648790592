import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientService } from 'src/app/dashboard/services/client.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { FileService } from 'src/app/files/services/file.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { formatDate } from 'src/app/utils/dates';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-client-portal-parameter',
  templateUrl: './client-portal-parameter.component.html',
  styleUrls: ['./client-portal-parameter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientPortalParameterComponent {
  @Input() clientsList: {key: any; value: any }[]=[];
  @Output() fetch = new EventEmitter();
  @Output() closePanel=new EventEmitter();
  parameterForm:FormGroup;
  url:string=null;
  env:any;
  viewList:any=[];
  companyId:any;
  companyName:String;
  adminFlag:String;
  constructor(private reportDashboardService:ReportDashboardService,private fileService: FileService, private cookieService: CookieService,
    private sspApplicationService: SspApplicationService,private toast:ToastService) {
      this.parameterForm = new FormGroup({
        client: new FormControl(null),
        toDate: new FormControl(null),
        fromDate: new FormControl(null)
      });


  }

  ngOnInit(): void {
    this.adminFlag=this.cookieService.get("adminFlag");

    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
        for(var i=0;i<data.length;i++){
          console.log("data  ",data[i].clientId)
          this.companyId=data[i].clientId
          this.companyName=data[i].clientName
        }
        this.parameterForm.get("client").setValue({
          key: this.companyId,
          value: this.companyName
        });

       // this.getAllDbCoonByComapnyId( this.headerForm.controls.company.value.key);
       this.parameterForm.controls.fromDate.setValue(formatDate(new Date()))
       this.parameterForm.controls.toDate.setValue(formatDate(new Date()))
      //  this.fromDateValue=this.headerForm.controls.fromDate.value;
      //  this.toDateValue=this.headerForm.controls.toDate.value;

       console.log("this.parameterForm",this.parameterForm.value)
       this.fetchReportData('onInit');
      
      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
      );
    
  }


  
  fetchReportData(flag) {
    if(this.parameterForm.controls.client.value.key!=undefined || this.parameterForm.controls.client.value.key!=null)
    if(this.parameterForm.valid){
      console.log("inside fetch")
      this.fetch.emit(this.parameterForm.value);
  } else{
  }

  if(flag=='onLoad'){
    let validate=this.validationOnDate(this.parameterForm.controls.fromDate.value,this.parameterForm.controls.toDate.value);
    if(validate===true){
      
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
  this.closePanel.emit();
}
}

changeToDate(){
 // this.headerForm.controls.toDate.setValue(e);
  let validate=this.validationOnDate(this.parameterForm.controls.fromDate.value,this.parameterForm.controls.toDate.value);
  if(validate===true){
    //this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
    this.fetch.emit(this.parameterForm.value);
  }
  if(validate===false){
    this.toast.showWarn('error','date range incorrect')
    
  }
}

validationOnDate(fromdate,todate){
  if(fromdate && todate){
  if(new Date(fromdate)>new Date(todate)){
  
      return false;
    }
    return true;
    
  }else{
    return false;
  }
}


}
