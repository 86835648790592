import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[toUpperCase]'
})
export class upperCaseDirective {

  @Input() validationFieldsType: string;

  constructor(private el: ElementRef) { }
 
 
 @HostListener('input', ['$event']) onKeyUp(event) {
    event.target['value'] = event.target['value'].toUpperCase();
  }
}






