import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';

@Component({
  selector: 'app-client-file-table',
  templateUrl: './client-file-table.component.html',
  styleUrls: ['./client-file-table.component.scss']
})
export class ClientFileTableComponent implements OnInit {



@Input() clientFiles:any[];
@Input() rolePermission:any;
@Output() edit=new EventEmitter();
@Output() mapping=new EventEmitter();
@Output() ingest=new EventEmitter();
@Output() testAll=new EventEmitter();
@Output() Log=new EventEmitter();
@Output() download=new EventEmitter();
@Output() deleteFile=new EventEmitter();
@Output() cleanData=new EventEmitter();
cols: { field: string; header: string }[];
 @Input() tabbedBoolean:boolean=false;

 @Output() redirectToTableViewerPage=new EventEmitter();
 @Output() createMasterAndSave=new EventEmitter();
 @Input() workflowExist:boolean=false;
 @Output() redirectToWorkflowPage=new EventEmitter();
  constructor() {
    this.cols = [
      { field: "fileName", header: "File Name" },
      { field: "applicationFileName", header: "Layout File" },
    ];
   }

  ngOnInit(): void {
  }

  showEditFile(rowData){
    this.edit.emit(rowData);
  }

  navigateToPage(page,rowData){
    // console.log("page",page);
    // console.log("rowData",rowData)
    let data={
      pageItem:page,
      dataItem:rowData
    }
    console.log("data  ",data)
this.mapping.emit(data);
  }


  ingestRuleBySequence(rowData){
this.ingest.emit(rowData);

  }

  testAllRules(rowData){
this.testAll.emit(rowData);
  }

  getLog(clientFileid){
console.log("clientfileid ",clientFileid);
    this.Log.emit(clientFileid);
  }

  downloadAnyFile(clientFileid){

this.download.emit(clientFileid)
  }

  showDeletePopup(clientFileid){
this.deleteFile.emit(clientFileid);
  }

  showCleanDataPopup(rowData){

this.cleanData.emit(rowData);
  }
  


  rediectToTableViewer(data){
  this.redirectToTableViewerPage.emit(data);

  }

  toggleCreateWorkflow(rowData){
   this.createMasterAndSave.emit(rowData);
  }

  redirectToWorkflow(data){

    this.redirectToWorkflowPage.emit(data);
  }

}
