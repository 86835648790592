import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { UserService } from '../user/services/user.service';

import {UserSelectorComponent } from './components/user-selector/user-selector.component';
import { UserTableComponent } from './components/user-table/user-table.component';
import { ChangelogTableComponent } from './components/changelog-table/changelog-table.component';
import { MultiSelectModule } from "primeng/multiselect";
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { UiModule } from "../ui/ui.module";

@NgModule({
  declarations: [
    
    UserSelectorComponent,
    UserTableComponent,
    ChangelogTableComponent,
    UserHeaderComponent
  
  ],
  imports: [SharedModule,MultiSelectModule,UiModule],
  exports: [
    UserTableComponent,
    UserSelectorComponent,
    ChangelogTableComponent,
    UserHeaderComponent
   
  ],
  providers: [UserService]
})
export class UserCompModule {}
