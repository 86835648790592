import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { StressScenarioSelectorComponent } from './components/stressScenario-selector/stressScenario-selector.component';
import { StressScenarioTableComponent } from './components/stressScenario-table/stressScenario-table.component';
import { StressScenarioHeaderComponent } from './components/stressScenario-header/stressScenario-header.component';

@NgModule({
  declarations: [
    StressScenarioSelectorComponent,
    StressScenarioTableComponent,
    StressScenarioHeaderComponent
    
  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    StressScenarioSelectorComponent,
    StressScenarioTableComponent,
    StressScenarioHeaderComponent
    ]
})
export class StressScenarioCompModule { }
