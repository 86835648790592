import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';

@Component({
  selector: 'app-client-selector',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss']
})
export class ClientSelectorComponent implements OnInit {
  @Input() rolePermission:any;
  @Input() editData=[];
  @Input() allGenericTimeZoneData;
  @Input() allGenericCurrencyData;
  @Output() save=new EventEmitter();
  @Output() emitfileupload=new EventEmitter();
  @Output() closePanel=new EventEmitter();
  clientForm: FormGroup = null;
  errorMessage:any;
  companyLogoName: string = null;
  currentFileUpload: File=null;
  statesList:any[];
  timeZoneList:any[];
  @Input() allCountryData:any[];
  reportList:any=[];
  selectedReportType: {key: any; value: any}[]=[];
  allCompanyTypeData: any[];
  constructor(private toast:ToastService, private sspapplicationService: SspApplicationService) {
    this.clientForm=new FormGroup({
      clientId:new FormControl(null),
      clientName: new FormControl(null,{validators:[Validators.required,Validators.minLength(5),Validators.maxLength(250)]}),
      shortCd: new FormControl(null,[Validators.required,Validators.minLength(5),Validators.maxLength(50)]),
      cert: new FormControl(null,[Validators.required,Validators.minLength(5),Validators.maxLength(250)]),
      statusMap: new FormControl(null,{validators:[Validators.required]}),
      email: new FormControl(null,{validators:[Validators.email,Validators.minLength(5),Validators.maxLength(50)]}),
      phone: new FormControl(null,{validators:[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),Validators.maxLength(15)]}),
      fax: new FormControl(null,{validators:[Validators.pattern("^[0-9]*$"),Validators.maxLength(10)]}),
      contactPerson: new FormControl(null,[Validators.maxLength(50)]),
      address1: new FormControl(null,[Validators.minLength(5),Validators.maxLength(50)]),
      address2: new FormControl(null,[Validators.minLength(5),Validators.maxLength(250)]),
      city: new FormControl(null,[Validators.required,Validators.maxLength(10)]),
      stateMap: new FormControl(null,[Validators.required]),
      zip: new FormControl(null, {validators:[Validators.pattern("^[0-9]*$"),Validators.required,Validators.maxLength(10)]}),
      // subClassCecl: new FormControl(false),
      // subClassAll: new FormControl(false),
      // callReportNameCecl: new FormControl(false),
      // callReportNameAll: new FormControl(false),
      // creditUnion: new FormControl(false),
      // cleanData:new FormControl(false),
      currencyMap: new FormControl(null,[Validators.required]),
      timeZoneMap: new FormControl(null,[Validators.required]),
      companyLogo: new FormControl(null),
      countryMap: new FormControl(null,[Validators.required]),
      companyType: new FormControl(null,[Validators.required]),
      reportType: new FormControl(null),
      
    });
   //this.clientForm.valueChanges.subscribe(data => this.onValueChanged(data));
   }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.clientForm.get('statusMap').setValue(defaultMap);
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);
      this.clientForm.patchValue(
        {clientId:rowData.clientId,
          clientName: rowData.clientName,
          shortCd: rowData.shortCd,
          cert: rowData.cert,
          email: rowData.email,
          phone: rowData.phone,
          fax: rowData.fax,
          contactPerson: rowData.contactPerson,
          address1: rowData.address1,
          address2: rowData.address2,
          city: rowData.city,
          zip: rowData.zip,
          subClassAll:rowData.subClassAll,
          subClassCecl: rowData.subClassCecl,
          callReportNameCecl: rowData.callReportNameCecl,
          callReportNameAll: rowData.callReportNameAll,
          creditUnion: rowData.creditUnion,
          cleanData: rowData.cleanData,
          companyLogo:rowData.companyLogo
          
        });
        
          const map1 = {
            key: "Active",
            value: "Active",
          };
        
          const map2 = {
            key: "Inactive",
            value: "Inactive",
          };
        
        rowData.status==true? this.clientForm.controls.statusMap.setValue(map1):this.clientForm.controls.statusMap.setValue(map2);

          
            const mapData = {
              key: rowData.state,
              value: rowData.state,
            };
            this.clientForm.controls.stateMap.setValue(mapData);

            const mapDataCurrency = {
              key: rowData.currency,
              value: rowData.currency,
            };
            this.clientForm.controls.currencyMap.setValue(mapDataCurrency);

            const mapDataTimeZone = {
              key: rowData.timeZone,
              value: rowData.timeZone,
            };

            const mappedCountry = {
              key: rowData.country,
              value: rowData.country,
            };
            this.clientForm.controls.countryMap.setValue(mappedCountry);
            this.getstates();
            const mappedState = {
              key: rowData.state,
              value: rowData.state
            };
            this.clientForm.controls.stateMap.setValue(mappedState);
            this.clientForm.controls.timeZoneMap.setValue(mapDataTimeZone);
            this.companyLogoName =rowData.companyLogo;
            console.log("logo"+this.companyLogoName);

            const companyType = {
              key: rowData.companyTypeId,
              value: rowData.companyTypeId,
            };
            //console.log("this.clientForm on selector: ",this.clientForm);
            this.clientForm.controls.companyType.setValue(companyType);
            this.companyTypeChange();

            let reportEditList=rowData.reportType;
            this.selectedReportType=[];
            for (var i = 0; i < reportEditList.length; i++) {
             
           
              this.selectedReportType.push({ key: parseInt(reportEditList[i].key), value: reportEditList[i].value });
            }
            //console.log("selected Report on edit: ",this.selectedReportType);
            this.clientForm.patchValue({
              reportType:this.selectedReportType,
            });
    }
  }

  

  onValueChanged(data?: any) {
    if (!this.clientForm) { return; }
    const form = this.clientForm;
    for (const field in this.formErrors) {
     // clear previous error message (if any)
     this.formErrors[field] = '';
     const control = form.get(field);
     if (control && control.invalid && control.dirty) {
       const messages = this.validationMessages[field];
       for (const key in control.errors) {
        // this.formErrors[field] += messages[key] + ' ';
         this.errorMessage=messages;
          this.toast.showError('error',this.errorMessage);
          console.log(this.errorMessage);
        }
      }
     }
   }

   formErrors = {
    'email': '',
    'phone': '',
    'zip':'',
    'clientName':'',
    'fax':'',
    'contactPerson':'',
  };
  
  validationMessages = {
    'email':'Email is invalid',
   'phone':'Phone Number Should Not Exceed More Than 10 Digits',
   'zip':'Must Be Numeric Only',
    'clientName':'Must Be Alphabets Only',
    'fax':'Must Be Numeric Only',
    'contactPerson':'Must Be Alphabets Only',
  
    }

    saveClient(){
  console.log("this.clientForm:",this.clientForm)
  let statusMap=this.clientForm.controls.statusMap.value
      console.log("statusMap",statusMap)
      if(this.clientForm.controls.clientName.status==="INVALID" && this.clientForm.controls.clientName.value===null && this.clientForm.controls.shortCd.status==="INVALID" && this.clientForm.controls.shortCd.value===null && this.clientForm.controls.cert.status==="INVALID" && this.clientForm.controls.cert.value===null && this.clientForm.controls.city.status==="INVALID" && this.clientForm.controls.city.value===null && this.clientForm.controls.stateMap.status==="INVALID" && this.clientForm.controls.stateMap.value===null && this.clientForm.controls.zip.status==="INVALID" && this.clientForm.controls.zip.value===null && this.clientForm.controls.currencyMap.status==="INVALID" && this.clientForm.controls.currencyMap.value===null && this.clientForm.controls.timeZoneMap.status==="INVALID" && this.clientForm.controls.timeZoneMap.value===null && this.clientForm.controls.companyLogo.status==="INVALID" && this.clientForm.controls.companyLogo.value===null && this.clientForm.controls.countryMap.status==="INVALID" && this.clientForm.controls.countryMap.value===null){
        this.toast.showError("Please Fill Required Fields","error");
        this.closePanel.emit();
        return;
      }
      if (statusMap=="Select Status *") {
        this.toast.showError("Status must be select","error");
        this.closePanel.emit();
        return;
      }
      if(this.clientForm.controls.clientName.status==="INVALID" && (this.clientForm.controls.clientName.value!=null || this.clientForm.controls.clientName.value===null)){
        this.toast.showWarn("Company Name is required with its Specific Limits.","error");
        this.closePanel.emit();
        return;
      }
       if(this.clientForm.controls.shortCd.status==="INVALID" && (this.clientForm.controls.shortCd.value!=null || this.clientForm.controls.shortCd.value===null)){
        this.toast.showWarn("Company Short Name is required with its Specific Limits.","error");
        this.closePanel.emit();
        return;
      }
      if(this.clientForm.controls.cert.status==="INVALID" && (this.clientForm.controls.cert.value!=null || this.clientForm.controls.cert.value===null)){
        this.toast.showWarn("Company Number is required with its Specific Limits.","error");
        this.closePanel.emit();
        return;
      }
      if(this.clientForm.controls.currencyMap.status==="INVALID" && (this.clientForm.controls.currencyMap.value!=null || this.clientForm.controls.currencyMap.value===null)){
        this.toast.showWarn("Currency is required to select","error");
        this.closePanel.emit();
        return;
      }
      if(this.clientForm.controls.countryMap.status==="INVALID" && (this.clientForm.controls.countryMap.value!=null || this.clientForm.controls.countryMap.value!=null)){
        this.toast.showWarn("Country is required to select","error");
        this.closePanel.emit();
        return;
      }
      if(this.clientForm.controls.stateMap.status==="INVALID" && (this.clientForm.controls.stateMap.value!=null || this.clientForm.controls.stateMap.value===null)){
        this.toast.showWarn("State is required to select","error");
        this.closePanel.emit();
        return;
      }
      if(this.clientForm.controls.city.status==="INVALID" && (this.clientForm.controls.city.value!=null || this.clientForm.controls.city.value===null)){
        this.toast.showWarn("City is required with its Specific Limits.","error");
        this.closePanel.emit();
        return;
      }
      if(this.clientForm.controls.zip.status==="INVALID" && (this.clientForm.controls.zip.value!=null || this.clientForm.controls.zip.value===null)){
        this.toast.showWarn("Zip Code is required with its Specific Limits.","error");
        this.closePanel.emit();
        return;
      }
      if(this.clientForm.controls.timeZoneMap.status==="INVALID" && (this.clientForm.controls.timeZoneMap.value!=null || this.clientForm.controls.timeZoneMap.value===null)){
        this.toast.showWarn("Time Zone is required to select","error");
        this.closePanel.emit();
        return;
      }
      if(this.currentFileUpload===null && (this.clientForm.controls.companyLogo.value===null)){
        this.toast.showWarn("Company Logo is required to upload","error");
        this.closePanel.emit();
        return;
      }
     
      if(statusMap!=="Select Status *"){
  if(this.clientForm.valid && this.clientForm.controls.stateMap.value.key!=null && this.clientForm.controls.currencyMap.value.key!=null && this.clientForm.controls.countryMap.value.key!=null){ 
    this.clientForm.patchValue({
      reportType:this.selectedReportType,
    });
    this.save.emit(this.clientForm.value);
  }
}
    else{
      this.toast.showError("Please Fill Required Fields","error");
  }
  this.closePanel.emit();
    }

    formReset(){
      this.clientForm.reset();
      this.currentFileUpload=null;
      this.companyLogoName=null;
      this.emitfileupload.emit(this.currentFileUpload);
      const defaultMap = {
       key: "Active",
       value: "Active",
     };
    this.clientForm.get('statusMap').setValue(defaultMap);
    this.selectedReportType=[];
    }

    getstates(){
      const key = this.clientForm.value.countryMap.key;
      this.sspapplicationService.getStateCodeData(key).subscribe((data) => {
        //console.log("data for api getstates: ",data)
      this.statesList = reMapForAutoComplete(data['states'],"stateCode", "state");
      this.timeZoneList = data['timeStamps'].map(it => {
        let item = JSON.parse(it);
        const temp = item['abbreviation'] + ': '+item['zoneName'] + ' (' + item['gmtOffsetName'] + ')';
        return { key: temp, value: temp};
      });
    });
    //console.log("getStates key: ",key);
    this.getCompanyType();
  }

  getCompanyType(){
    const countryCode = this.clientForm.value.countryMap.key;
    this.sspapplicationService.getCompanyTypeByCountryCode(countryCode).subscribe((data) => {
      //console.log("countryType Data: ",data);
      if(data===null || data.length===0){
        this.reportList=[];
      }
      this.allCompanyTypeData=data;
    });

   

  }

  companyTypeChange(){
    const countryCode = this.clientForm.value.countryMap.key;
    const companyTypeId = this.clientForm.value.companyType.key;
    //console.log("companyType Selected: ",companyTypeId);
    this.sspapplicationService.getReportByCountryCodeAndCompanyType(countryCode, companyTypeId).subscribe((data) => {
      
      this.reportList=data;
      //console.log("ReportList Data: ",this.reportList);
    });

  }


    get status() {
      const status = [
        "Active",
        "Inactive",
      ];
     return status.map((t) => ({ key: t, value: t }));
    }

    addCompanyLogo(){
      this.companyLogoName=null;
    }

    selectFile(event) {
      this.currentFileUpload = event.target.files;
      this.emitfileupload.emit(this.currentFileUpload);
      //console.log("currentFileUpload  ",this.currentFileUpload)
      //  this.fileService.checkForFileExistence(this.currentFileUpload).subscribe(
      //       (data) => {
      //        this.fileExist=data;
      //        console.log("FileExist"+this.fileExist);
      //        if(this.fileExist){
      //          this.currentFileUpload=null;
      //          this.toast.showError("Please rename the companyLogo File", "error");
      //        }
      //       },
      //       (err) => {
      //         this.loader.hideLoader();
      //         this.toast.showError("Please rename the companyLogo File", "error");
      //       }
          
      //     );
    }

    multiReportTypeSelectChange(eventData){
      this.selectedReportType = eventData.value;
      //console.log("SelectedReportType Value Updated Is:", this.selectedReportType);
  
    }

}
