import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { RwaPercentSelectorComponent } from './components/rwapercent-selector/rwapercent-selector.component';
import { RwaPercentTableComponent } from './components/rwapercent-table/rwapercent-table.component';
import { RwaPercentHeaderComponent } from './components/rwapercent-header/rwapercent-header.component';

@NgModule({
  declarations: [
    RwaPercentSelectorComponent,
    RwaPercentTableComponent,
    RwaPercentHeaderComponent
    

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    RwaPercentSelectorComponent,
    RwaPercentTableComponent,
    RwaPercentHeaderComponent
    ]
})
export class RwaPercentCompModule { }
