import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder,FormControl, FormGroup,Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, TimeoutError } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { DbconnectionService } from '../../services/dbconnection.service';
import {FinancialAccounitingService} from '../../services/financial-accouniting.service';
import { FileService } from 'src/app/files/services/file.service';

@Component({
  selector: 'app-financial-accounting',
  templateUrl: './financial-accounting.component.html',
  styleUrls: ['./financial-accounting.component.scss']
})

export class FinancialAccountingComponent implements OnInit {

  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;
  mainForm: FormGroup=null;
  mainForm1: FormGroup=null;
  dataTypemainForm: FormGroup=null;
  accountStatementform:any;
  accountStatementform1:any;
  dataTypeList:any[]; 
  activeRoute$:Subscription;
  state:string;   
  tableData: any[];
  tableData1: any[];
  dataType:any;  
  companyId: any;
  menu:string;
  submenu:string;
  constructor(
    private financialAccounitingService: FinancialAccounitingService,
    private loader: LoaderService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private toast: ToastService,
    private _fb: FormBuilder,
    private datePipe:DatePipe,
    private fileService: FileService,
  ) { 
    this.dataTypemainForm = new FormGroup({
      dataType: new FormControl(null), 
      asOfDate:new FormControl(null, [Validators.required]),
    });
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag"); 
   }
   ngOnInit(): void {
    this.getDataType();     
    this.dataTypemainForm.get("asOfDate").setValue(this.datePipe.transform(new Date(),"yyyy-MM-dd"));
    this.dataType="Assets"
    this.setTitle();

    this.fileService.getClientByUserId().subscribe(
      (data) =>{      
        for(var i=0;i<data.length;i++){
          console.log("data  ",data[i].clientId)
          this.companyId=data[i].clientId         
        }
        this.loadAssetData( this.dataTypemainForm.controls.asOfDate.value);
      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
      );

  
    
   
  }

  // changeasoFdate(e){
  //   const valt=this.datePipe.transform(e,'yyyy-MM-dd');
  //   //console.log("date val ",valt);
  //   this.dataTypemainForm.controls.asOfDate.value;
  // }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

onLoad()
{
 
  console.log(" companyId : ", this.dataTypemainForm.controls.dataType.value.key);
 
  this.panelFilter.toggleSlider();
  this.dataType=this.dataTypemainForm.controls.dataType.value.value
  if(this.dataType==='Assets'){
   this.loadAssetData(this.dataTypemainForm.controls.asOfDate.value);
  }else{
    this.loadLiablityData(this.dataTypemainForm.controls.asOfDate.value);
  }
}

loadAssetData(date){
  this.financialAccounitingService.getAssetsStatements(this.companyId,date).subscribe(
    (data) =>{
      for(var i=0;i<data.length;i++){
        console.log("data",data)

        this.tableData =data;
        this.buildform(this.tableData);
      }
    },
    (err)=>{
      this.toast.showError(err.error, "error");
      this.tableData =[];
      this.buildform(this.tableData);
    }

  );
}

loadLiablityData(date){
  this.financialAccounitingService.getLiabilitiesStatements(this.companyId,date).subscribe(
    (data) =>{
      for(var i=0;i<data.length;i++){
        console.log("data  ",data)

        this.tableData1 =data;
        this.buildform1(this.tableData1);
      }
    },
    (err)=>{
      this.toast.showError(err.error, "error");
      this.tableData1 =[];
      this.buildform1(this.tableData1);
    }

  );
}
  enableDay(){

  }


  saveAssetData(){
    this.accountStatementform.forEach(element => {
        if(element.cashAndBank==='true1'){
          element.cashAndBank=true
        }
        if(element.creditFacilities === 'true2'){
          element.creditFacilities=true
        }
        if(element.billsBondsInvestments === 'true3'){
          element.billsBondsInvestments= true
        }
        if(element.others==='true4'){
          element.others=true
        }
        element.asOfDate=this.dataTypemainForm.controls.asOfDate.value
      });

      console.log("request body:",this.accountStatementform)
      this.financialAccounitingService.saveAssetsStatements(this.accountStatementform).subscribe( 
        (data) =>{
          this.toast.showSuccess("Data saved Succesfully","Succesfully")
          this.loadAssetData(this.dataTypemainForm.controls.asOfDate.value);
      },
      (err)=>{
        this.toast.showError(err.error, "error");
      }
    );
    
  }
 
  saveLiabilitiesData(){
    this.accountStatementform1.forEach(element => {
      if(element.cASA==="true1"){
        element.cASA=true
      }
      if(element.timeDeposits==="true2"){
        element.timeDeposits=true
      }
      if(element.interbankDeposits==="true3"){
        element.interbankDeposits=true
      }
      if(element.customerDeposits==="true4"){
        element.customerDeposits=true
      }
      if(element.others==="true5"){
        element.others=true
      }
      element.asOfDate=this.dataTypemainForm.controls.asOfDate.value
    });
    console.log("request body:",this.accountStatementform1)
    this.financialAccounitingService.saveLitiesStatements(this.accountStatementform1).subscribe( 
      (data) =>{
        this.toast.showSuccess("Data saved Succesfully","Succesfully")
        this.loadLiablityData(this.dataTypemainForm.controls.asOfDate.value);
    },
    (err)=>{
      this.toast.showError(err.error, "error");
    }
  );
 

  }

  //  loadData(){
  //    console.log("get db connn");
  //    this.dbconnectionService.loadData("string")
  //    .subscribe(
  //      (data)=>{
  //        console.log("data");
  //      }
  //    )
  //  }

   getDataType() {
    
     this.financialAccounitingService.getDataTypeList().subscribe(
      (data) => {
        this.loader.hideLoader();
        this.dataTypeList = reMapForAutoComplete(data, "key", "value");
        this.dataTypemainForm.get("dataType").setValue({ key: 1, value: "Assets"});
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
  }

  onClickForm(){

    this.panelForm.toggleSlider();
  }
  onClickFilter(){

    this.panelFilter.toggleSlider();
  }

  cashAndBank(rowData){
    console.log("multifactorId cashAndBank",this.accountStatementform);
    console.log("multifactorId cashAndBank",rowData);
    this.accountStatementform.forEach(item =>{
      if(rowData.categoryGrouping===item.categoryGrouping){
        item.cashAndBank="true1",
        item.creditFacilities=false,
        item.billsBondsInvestments=false,
        item.others=false
      }
    })
    console.log("multifactorId cashAndBank",this.accountStatementform);
  }
  creditFacilities(rowData){
    console.log("multifactorId creditFacilities",rowData);
    this.accountStatementform.forEach(item =>{
      if(rowData.categoryGrouping===item.categoryGrouping){
        item.cashAndBank=false,
        item.creditFacilities="true2",
        item.billsBondsInvestments=false,
        item.others=false
      }
    })
    console.log("multifactorId creditFacilities",this.accountStatementform);
  }
  billsBondsInvestments(rowData){
    console.log("multifactorId billsBondsInvestments",rowData);
    this.accountStatementform.forEach(item =>{
      if(rowData.categoryGrouping===item.categoryGrouping){
        item.cashAndBank=false,
        item.creditFacilities=false,
        item.billsBondsInvestments="true3",
        item.others=false
      }
    })
    console.log("multifactorId billsBondsInvestments",this.accountStatementform);
  }
  assegtothers(rowData){
    console.log("multifactorId assegtothers",rowData);
    this.accountStatementform.forEach(item =>{
      if(rowData.categoryGrouping===item.categoryGrouping){
        item.cashAndBank=false,
        item.creditFacilities=false,
        item.billsBondsInvestments=false,
        item.others="true4"
      }
    })
    console.log("multifactorId assegtothers",this.accountStatementform);
  }


  private buildform(list: any) {

    list.forEach(element => {
      
      if(element.cashAndBank){
        element.cashAndBank="true1"
      }
      if(element.creditFacilities){
        element.creditFacilities="true2"
      }
      if(element.billsBondsInvestments){
        element.billsBondsInvestments="true3"
      }
      if(element.others){
        element.others="true4"
      }
    });

    console.log("buildForm list",list)
    let arr: any = [];
      list.forEach(item => {
        arr.push(this.buildFormDynamic(item))
      });
      this.mainForm = this._fb.group({
       multifactorData: this._fb.array(arr)
      })
      this.accountStatementform=this.mainForm.value.multifactorData;
console.log("multifactorArrayForm",this.mainForm);
  }

  private buildFormDynamic(item): FormGroup {
    //console.log("buildFormDynamic check",item)
    return this._fb.group({
      // multifactorRiskId:[item.multifactorRiskId],
      categoryGrouping:[item.categoryGrouping],
      //shockName:[item.shockName],
    
      billsBondsInvestments: [item.billsBondsInvestments],
      creditFacilities:  [item.creditFacilities],
      others:  [item.others],
      cashAndBank:  [item.cashAndBank],
      asset: [item.asset],

    })
   
  }


  private buildform1(list: any) {

    list.forEach(element => {
      
      if(element.cASA){
        element.cASA="true1"
      }
      if(element.timeDeposits){
        element.timeDeposits="true2"
      }
      if(element.interbankDeposits){
        element.interbankDeposits="true3"
      }
      if(element.customerDeposits){
        element.customerDeposits="true4"
      }
      if(element.others){
        element.others="true5"
      }
    });

    console.log("buildForm list",list)
    let arr: any = [];
      list.forEach(item => {
        arr.push(this.buildFormDynamic1(item))
      });
      this.mainForm1 = this._fb.group({
       multifactorData: this._fb.array(arr)
      })
      this.accountStatementform1=this.mainForm1.value.multifactorData;
    console.log("multifactorArrayForm",this.mainForm1);
  }

  private buildFormDynamic1(item): FormGroup {
    return this._fb.group({
      categoryGrouping:[item.categoryGrouping],
     
      cASA: [item.cASA],
      timeDeposits:  [item.timeDeposits],
      interbankDeposits:  [item.interbankDeposits],
      customerDeposits:  [item.customerDeposits],
      others: [item.others],
      asset: [item.asset],

    })
   
  }

  cASA(rowData){
       this.accountStatementform1.forEach(item =>{
      if(rowData.categoryGrouping===item.categoryGrouping){
        item.cASA="true1",
        item.timeDeposits=false,
        item.interbankDeposits=false,
        item.customerDeposits=false,
        item.others=false
      }
    })
   
  }
  timeDeposits(rowData){
       this.accountStatementform1.forEach(item =>{
      if(rowData.categoryGrouping===item.categoryGrouping){
        item.cASA=false,
        item.timeDeposits="true2",
        item.interbankDeposits=false,
        item.customerDeposits=false,
        item.others=false
      }
    })
   
  }
  interbankDeposits(rowData){
       this.accountStatementform1.forEach(item =>{
      if(rowData.categoryGrouping===item.categoryGrouping){
        item.cASA=false,
        item.timeDeposits=false,
        item.interbankDeposits="true3",
        item.customerDeposits=false,
        item.others=false
      }
    })
  
  }

  customerDeposits(rowData){
    
    this.accountStatementform1.forEach(item =>{
      if(rowData.categoryGrouping===item.categoryGrouping){
        item.cASA=false,
        item.timeDeposits=false,
        item.interbankDeposits=false,
        item.customerDeposits="true4",
        item.others=false
      }
    })
   
  }


  others(rowData){
   
    this.accountStatementform1.forEach(item =>{
      if(rowData.categoryGrouping===item.categoryGrouping){
        item.cASA=false,
        item.timeDeposits=false,
        item.interbankDeposits=false,
        item.customerDeposits=false,
        item.others="true5"
      }
    })
   
  }
}