import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { ReportConfigurationSelectorComponent } from './components/reportConfiguration-selector/reportConfiguration-selector.component';
import { ReportConfigurationTableComponent } from './components/reportConfiguration-table/reportConfiguration-table.component';
import { ReportConfigurationHeaderComponent } from './components/reportConfiguration-header/reportConfiguration-header.component';


@NgModule({
  declarations: [
    ReportConfigurationSelectorComponent,
    ReportConfigurationTableComponent,
    ReportConfigurationHeaderComponent
    

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    ReportConfigurationSelectorComponent,
    ReportConfigurationTableComponent,
    ReportConfigurationHeaderComponent
    ]
})
export class ReportConfigurationCompModule { }
