import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { WorkflowQueueDataModel } from '../../models/workflow.model';
import { WorkflowService } from '../../services/workflow.service';
import { currentMonthRange,getCurrentDate } from 'src/app/utils/dates';
import * as moment from "moment";
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { FileService } from 'src/app/files/services/file.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';

@Component({
  selector: 'app-workflow-queue',
  templateUrl: './workflow-queue.component.html',
  styleUrls: ['./workflow-queue.component.scss']
})
export class WorkflowQueueComponent implements OnInit {

  @ViewChild("panelQueueFilter", {static: false })
  panelQueueFilter!:SidePanelComponent;

  activeRoute$: Subscription;
  workflowQueueDataModel:WorkflowQueueDataModel[];
  id:any;
  queueForm: FormGroup = null;
  visible:boolean=false;
  headerData="Do you want to stop Workflow From Queue";
  rolePermission:any;
  StatusName:String;
  fromDateValue;
  toDateValue;
  searchByCols:{field:String, header:String }[];
  searchQuery!: any;
  searchCol:String;
  state:string;
  constructor(private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private workflowService: WorkflowService,
    private toast:ToastService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>,private fileService: FileService) { 

      this.queueForm=new FormGroup({
        statusMap:new FormControl(null),
        fromDate: new FormControl(null),
        toDate: new FormControl(null)
      });


      this. searchByCols = [
        { field: "workflowName", header: "Workflow" },
        { field: "clientName", header: "Company/Job Name" }, 
        { field: "statusMsg", header: "Status/Msg" },
        { field: "userName", header: "Created By" },
      ];

      this.state=localStorage.getItem("stateflag");

    }

  ngOnInit(): void {

    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );
    this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }



  onLoad(){
    this.panelQueueFilter.toggleSlider();
    if(this.queueForm.controls.statusMap.value.value==null || this.queueForm.controls.statusMap.value.value==undefined){
      return this.toast.showError("Please select the status","error");
    }
    this.StatusName=this.queueForm.controls.statusMap.value.value;
    this.fromDateValue=this.queueForm.controls.fromDate.value;
    this.toDateValue=this.queueForm.controls.toDate.value;
    if((this.fromDateValue===null || this.fromDateValue===undefined) && (this.toDateValue===null || this.toDateValue===undefined)){
      console.log("Indside null check of date");
      return this.toast.showError("Please select the From date and To date","error");
    }
    let validate=this.validationOnDate(this.queueForm.controls.fromDate.value,this.queueForm.controls.toDate.value);
    if(validate===true){
     
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect');
      
    }
    this.getSelected(event);
    this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,this.queueForm.controls.toDate.value);
    
  }

  getAllWorkflowQueue(data,fromDate,toDate){
    //console.log("getall queueu,",data);
    const fromdate1=this.datePipe.transform(fromDate,'yyyy-MM-dd');
    let toDatePlus1 = moment(toDate).add(1, 'days');
    const toDate1=this.datePipe.transform(toDatePlus1,'yyyy-MM-dd');
    const requestModel={
      "toDate":toDate1,
      "fromDate":fromdate1,
      "queueStatus":data
    }
    
    this.workflowService.getAllWorkflowQueue(requestModel)
    .subscribe(
      data=>{
        console.log("all logs ",data)
        this.workflowQueueDataModel=data;
      },
      error=>{
       // this.toast.showError(error.error.message, "error");
        this.toast.showSuccess("No Workflow is Present", "success");

      }
    )
  }

  disableJobData(data){
    const requestModel={
      "workflowJobLogId":data.queueId,
      "workflowId":data.workflowId,
      "workflowDetailId":data.workflowDetailId
    }

   this.workflowService.disableWorkflowJobFromQueue(requestModel)
   .subscribe(
     res=>{
       this.toast.showInfo('Success','Job disabled');
       this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value
        );
     },
     err=>{
      this.toast.showInfo('error',err.error.message);
     }
   )

  }

  removeWorkflow(data){
    const requestModel={
      "workflowLogId":data.queueId,
      "workflowId":data.workflowId
    }

    this.workflowService.removeWorkflowFromQueue(requestModel)
    .subscribe(
      res=>{
        this.toast.showInfo('Success','Workflow Removed from queue');
        this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
          this.queueForm.controls.toDate.value
          );
      },
      error=>{
        this.toast.showInfo('error','This workflow cannot be removed because it came into Running Stage');
      }
    )
  }

  statusMap=[
    {
      "key":"QUEUED",
      "value":"RUNNING/QUEUED"
    },
    {
      "key":"COMPLETED",
      "value":"COMPLETED/REMOVED"
    },
  ]
  
  getSelected(e){
  
    this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
      this.queueForm.controls.toDate.value
      );
  }

  setValueOnLoad(){
    //console.log('setting default value');
    this.loadDate();
    this.queueForm.get("statusMap").patchValue({
      key:"QUEUED",
      value: "RUNNING/QUEUED",
      });

      this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value
        );
  }

  ngOnDestroy() {
    // this.activeRoute$.unsubscribe();
     if (this.id) {
       clearInterval(this.id);
     }
   }

   changeFromDate(){

    //this.queueForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.queueForm.controls.fromDate.value,this.queueForm.controls.toDate.value);
    if(validate===true){
      // this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
      //   this.queueForm.controls.toDate.value
      //   );
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
  }

  changeToDate(){
    //this.queueForm.controls.toDate.setValue(e);
    let validate=this.validationOnDate(this.queueForm.controls.fromDate.value,this.queueForm.controls.toDate.value);
    if(validate===true){
      // this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
      //   this.queueForm.controls.toDate.value
      //   );
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //console.log(e,'  todate');
  }

  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }

  loadDate(){
    
    let currentDateRange=currentMonthRange();
    
    this.queueForm.controls.fromDate.setValue(currentDateRange[0])
    this.queueForm.controls.toDate.setValue(currentDateRange[1])
    
  }

  stopWorkflow(){
    this.workflowService.stopWorkflowFromQueue()
    .subscribe(
      res=>{
        this.toast.showInfo('Success','Workflow stoped from queue');
        this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
          this.queueForm.controls.toDate.value
          );
      },
      error=>{
        this.toast.showInfo(error.error.message,'error');
      }
    )
  }


  hidePopup(data){
     this.visible=false;
  }

  actionDo(data){
       this.stopWorkflow();
       this.hidePopup("data");
  }

  showPopUp(data){
    this.visible=true;
    
    
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.setTitle();
    this.setValueOnLoad();
    
    this.id=setInterval(() => {
      this.getAllWorkflowQueue(this.queueForm.controls.statusMap.value.key,this.queueForm.controls.fromDate.value,
        this.queueForm.controls.toDate.value
        );
    }, 30000);//30 sec
    }

}
