import { ComponentPortal, Portal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit {
  @Input() noSearchBar: boolean = false;
  @Input() noHeader: boolean = false;
  rightPortal!: Portal<any>;
  constructor(private _viewContainerRef: ViewContainerRef) {}

  ngAfterViewInit(): void {
    // this.rightPortal=new ComponentPortal();
  }
}
