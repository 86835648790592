import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit,Input,OnChanges,SimpleChanges,Output,EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { WorkflowResponseModel } from 'src/app/dashboard/models/workflow.model';
import { ClientPortalDataComponent } from 'src/app/dashboard/pages/client-portal-data/client-portal-data.component';
import { RolesComponent } from 'src/app/dashboard/pages/roles/roles.component';
import { ClientPortalService } from 'src/app/dashboard/services/client-portal.service';
import { RoleService } from 'src/app/dashboard/services/role.service';
import { WorkflowService } from 'src/app/dashboard/services/workflow.service';
import { FileService } from 'src/app/files/services/file.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/loader/services/loader.service';


@Component({
  selector: 'app-workflow-parameter',
  templateUrl: './workflow-parameter.component.html',
  styleUrls: ['./workflow-parameter.component.scss']
})
export class WorkFlowParameterComponent implements OnInit {
@Input() clientsList1:any;
@Input() applicationList:any;
@Output() roleForm=new EventEmitter();
@Output() closePanel=new  EventEmitter();
@Output() fetch=new EventEmitter();
parameterForm:FormGroup;
@ViewChild("panelFormRole", {static: false })
panelFormRole!:SidePanelComponent;
roles:any;
formValues:any;
workflowResponseModel:WorkflowResponseModel[]=[];
clientId:any;
company:String;
activeRoute$: Subscription;
workflowsListByReport:WorkflowResponseModel[]=[];
@Output() workflowsByReport=new EventEmitter();
nevigateForReport:boolean=false;
@Input() showWorkflowInterFace:boolean=true;
  constructor(private toast:ToastService,private fileService:FileService, private loader:LoaderService,
    private clientPortalService:ClientPortalService,private workflowService:WorkflowService,private datePipe:DatePipe,private activeRoute: ActivatedRoute) {
    this.parameterForm = new FormGroup({
      client: new FormControl(null),
      // clientApplicationFileDataType: new FormControl(null),
      isActive: new FormControl(null),
      
    });
   
   }

  ngOnInit(): void {
    console.log("form value on init");

    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
    
        if (par.has("groupId") && par.has("groupName")) {
          // this.headerForm.get("GroupData").setValue({
          //   key: par.get("groupId"),
          //   value: par.get("groupName")
          // });
       
        }
        if (par.has("appId") && par.has("appName")) {
          this.nevigateForReport=true;
          // this.headerForm.get("ReportData").setValue({
          //   key: par.get("appId"),
          //   value: par.get("appName")
          // });
          if(this.nevigateForReport){
            this.workflowsByReport.emit(par.get("appId"));
       
               
    }
  }
}
      );

if(!this.nevigateForReport && this.showWorkflowInterFace){
    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
        for(var i=0;i<data.length;i++){
          console.log("data  ",data[i].clientId)
          this.clientId=data[i].clientId
          this.company=data[i].clientName
        }
        this.parameterForm.get("client").setValue({
          key: this.clientId,
          value: this.company
        });
        this.onDataTypeChange('onInit');
        
      },
     (err) => {
       //this.toast.showError(err.error.message, "error");
     
     }
     );
    }

  }
  addRole(data){
    console.log(data)
    this.roleForm.emit(data);
    // const roleData={
    //    "roleName":data.roleName,
    //    "roleDescription":data.roleDescription
    // }
    // this.rolesService.saveRole(roleData).subscribe(
    //   (data) => {
    //    // this.addRolePopup = false;
    //   //  this.roleFormComponent.roleForm.reset();
    //     this.getRoles();
    //     this.toast.showSuccess("Role SuccesFully Saved", "success");
    //   },
    //   (err) =>{
    //     this.toast.showError(err.error.message, "error");
    //   } 
    // );
  }

  onClientChange(event){
    // this.cookieService.set('clientId',this.workflowJobForm.controls.client.value.key);
    // this.cookieService.set('clientName',this.workflowJobForm.controls.client.value.value);
    //this.maintainClientState(event);
  
  //  this.worklogDataInworkflowId=[];
    
    //this.workflowResponseModel=[];
   // this.resetOnChange(); 
    this.getAllWorkflow();
  }
  
  getAllWorkflow(){
    //this.worklogDataInworkflowId=[]; 
    const workflowResponseModel1={
      "clientId":this.parameterForm.controls.client.value.key,
      "clientApplicationFileDataType":this.parameterForm.controls.clientApplicationFileDataType.value.key
    }
    this.workflowService.getWorkflowByClientNameDataType(workflowResponseModel1).subscribe(
     data=>{
     // this.loader.hideLoader();
      this.workflowResponseModel=data;
      
      this.workflowResponseModel.forEach(item=>{
        // console.log("item",item.isActive===1);
        item.clientId=this.parameterForm.controls.client.value.key;
        item.isActiveDisplay=item.isActive===1?'Yes':'No'
        item.workflowTypeDisplay=item.workflowType===1?'System':'Custom'
        item.asOfDate=item.asOfDate!==null?this.datePipe.transform(item.asOfDate,'MM-dd-yyyy'):""
      
      })
     },
     error => {
      this.toast.showError(error.error.message, "error");
    }
    )
  }

    onDataTypeChange(flag){
     
      if(this.parameterForm.valid){
        console.log("inside fetch")
        this.fetch.emit(this.parameterForm.value);
    } else{
     // this.toast.showError("All Fields Are Mandatory", "error");
    }
    if(flag=='onLoad'){
    this.closePanel.emit();
    }
    
    }
  
  

}
