import { Component, OnInit } from "@angular/core";
import { ToastService } from "src/app/core/services/toast.service";
import { GlHeadModel } from "../../models/glHead.model";
import { GlHeadService } from "../../services/glHead.service";
import { SearchBoxComponent } from "src/app/reports/components/search-box/search-box.component";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { TitleService } from "src/app/core/services/title.service";

@Component({
  selector: "app-hqla",
  templateUrl: "./hqla.component.html",
  styleUrls: ["./hqla.component.scss"],
})
export class HQLAComponent implements OnInit {
  hqlaList: GlHeadModel[];
  activeRoute$: Subscription;
  searchQuery!: any;
  searchCol:String;
  searchByCols: { field: String; header: String }[];
  state:string;
  menu:string;
  submenu:string;

  constructor(
    private toast: ToastService,private titleService: TitleService,
    private glHeadService: GlHeadService,private activeRoute: ActivatedRoute
  ) {
    this.titleService.setTitle("HQLA");
    this.searchByCols = [
      { field: "glHeadNname", header: "GlHead Name" },
      { field: "glAcct", header: "GL Account No." },
    ];
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag");
  }
  ngOnInit(): void {

    this.getAllHQLAList();
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );
  }

  getAllHQLAList() {
    this.glHeadService.getAllHqlaList().subscribe(
      (data) => {
        console.log("Table Call");
        this.hqlaList = data;
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
  }
}
