import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PickListModule } from "primeng/picklist";
import { ButtonModule } from "primeng/button";
import { DualListComponent } from './component/dual-list/dual-list.component';
import { MultiSelectModule } from "primeng/multiselect";
import { FormsModule } from '@angular/forms';
import { RoleParameterComponent } from "./component/role-parameter/role-parameter.component";
import { SharedModule } from "../shared/shared.module";
import { RoleHeaderComponent } from "./component/role-header/role-header.component";
import { RoleFormComponent } from "./component/role-form/role-form.component";
import { UiModule } from "../ui/ui.module";
import { RoleService } from "../dashboard/services/role.service";

@NgModule({
  declarations: [DualListComponent,RoleParameterComponent,RoleHeaderComponent,RoleFormComponent],
  imports: [CommonModule, PickListModule, ButtonModule, MultiSelectModule,FormsModule,SharedModule,UiModule],
  exports: [DualListComponent,RoleParameterComponent,RoleHeaderComponent,RoleFormComponent],
})
export class DualListModule {}