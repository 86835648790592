import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ToastService } from "src/app/core/services/toast.service";
import {
  FieldLayoutModel,
  FileLayoutModel,
} from "src/app/dashboard/models/file-layout.model";
import { FileService } from "src/app/files/services/file.service";

@Component({
  selector: "app-fields-layout-form",
  templateUrl: "./fields-layout-form.component.html",
  styleUrls: ["./fields-layout-form.component.scss"],
})
export class FieldsLayoutFormComponent implements OnInit, OnChanges {
  fieldLayoutForm: FormGroup = null;
  @Input() value: FieldLayoutModel;
  @Input() fileLayout: FileLayoutModel;
  @Output() create = new EventEmitter();
  @Output() firstRow = new EventEmitter();
  @Input() rolePermission;
  @Output() closePanel=new EventEmitter();
  constructor(private fileService: FileService,private toast:ToastService) {
    this.initalizeForm();
  }

  initalizeForm() {
    this.fieldLayoutForm = new FormGroup({
      fieldName: new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(100)]),
      fieldType: new FormControl(null, [Validators.required]),
      fieldFormat: new FormControl(null),
      fieldSize:new FormControl(null, [Validators.maxLength(10)]),
    });
  }
  get fieldTypes() {
    const types = ["STRING", "NUMERIC", "Date"];
    return types.map((t) => ({ key: t, value: t }));
  }
  get dateFormats() {
    const formats = [
      "MM/dd/yy",
      "dd/MM/yy",
      "MMddyyyy",
      "MM/dd/yyyy",
      "MM-dd-yyyy",
      "yyyyMMdd",
      "yyyyMd",
      "MMddyy",
      "Mddyy",
      "Mddyyyy",
      "dd-MMM-yyyy",
      "yyyyDDD",
      "MM/dd/yyyy HH:mm:ss",
      "MM-dd-yyyy HH:mm:ss",
      "MMM dd yyyy h:mma",
      "yyyy-MM-dd HH:mm:ss",
      "yyyy-MM-dd",
      "MM/dd/yyyy HH:mm"
    ];
    return formats.map((t) => ({ key: t, value: t }));
  }
  get dateFieldChecked() {
    return (
      !!this.fieldLayoutForm.get("fieldType").value &&
      this.fieldLayoutForm.get("fieldType").value.key === "Date"
    );
  }
  ngOnInit(): void {
    const data$ = this.fieldLayoutForm.valueChanges.subscribe((data) => {
      this.firstRow.emit({
        fieldName: data.fieldName,
        fieldType: data.fieldType ? data.fieldType.key : null,
        fieldFormat: data.fieldFormat ? data.fieldFormat.key : null,
        fieldSize: data.fieldSize,
      });
      // data$.unsubscribe();
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.value && changes.value.currentValue) {
      const d: FieldLayoutModel = changes.value.currentValue;
      this.fieldLayoutForm.patchValue({
        fieldName: d.fieldName,
        fieldType: { key: d.fieldType, value: d.fieldType },
        fieldFormat: { key: d.fieldFormat, value: d.fieldFormat },
        fieldSize: d.fieldSize,
      });
    }
  }
  addField() {
    this.closePanel.emit();
   if(!this.fieldLayoutForm.valid){
     return this.toast.showError("Please fill the mandatory fields","error");
   }
    const { fieldName, fieldType, fieldFormat, fieldSize } = this.fieldLayoutForm.value;
    this.create.emit({
      fieldName,
      fieldType: fieldType.key,
      fieldFormat: fieldFormat ? fieldFormat.key : null,
      fieldSize,
    });

 
  }

  resetForm(){
    this.fieldLayoutForm.reset();
    //this.cleanDataConfigListEdit=null;
  }
}
