import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlMappingService {

  /**
   * @param  {HttpDepartment} privatehttp
   */
   constructor(private http: HttpClient) {}


   getAllGlMappingList(id){
   
    return this.http.get<any>(
    `${environment.baseUrl}/apis/getGLMappingsById/${id}`
  );
}

getGLHeadListByClienId(id){
   
  return this.http.get<any>(
  `${environment.baseUrl}/apis/glhead/getGLHeadById/${id}`
);
}

getAllKeyMeasureHeadList(){
   
  return this.http.get<any>(
  `${environment.baseUrl}/apis/glMapping/getAllKeyMeasureHead`
);
}

saveGlMappingsOrUpdate(data){
  return this.http.post<any[]>(environment.baseUrl + "/apis/glMapping/createGlMapping",data);
}



deleteGLMappingById(id){
   
  return this.http.get<any>(
  `${environment.baseUrl}/apis/glMapping/deleteGlMapping/${id}`
);
}







}
