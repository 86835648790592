import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { ClientSelectorComponent } from 'src/app/clientComp/components/client-selector/client-selector.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { GlHeadHeaderComponent } from 'src/app/glHeadComp/components/glHead-header/glHead-header.component';
import { GlHeadSelectorComponent } from 'src/app/glHeadComp/components/glHead-selector/glHead-selector.component';
import { GlHeadModel } from '../../models/glHead.model';
import { GlHeadService } from '../../services/glHead.service';
import { ClientService } from '../../services/client.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-glHead',
  templateUrl: './glHead.component.html',
  styleUrls: ['./glHead.component.scss']
})
export class GlHeadComponent implements OnInit {

   @ViewChild(GlHeadSelectorComponent, { read: false, static: false })
   clientSelectorComponent: ClientSelectorComponent;

   @ViewChild(GlHeadHeaderComponent, { read: false, static: false })
   GlHeadHeaderComponent: GlHeadHeaderComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;
@ViewChild("panelFilter", {static: false })
panelFilter!:SidePanelComponent;
// @ViewChild('fromCal')
// fromCalVariable: ElementRef;
  glHeadList:GlHeadModel[];
  editData:GlHeadModel[];
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  clientsList:{key: any; value: any }[]=[];
  companyName:string=null;
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  companyId:any;
  state:string;
  adminFlag:String;
  menu:string;
  submenu:string;
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService,private fileService:FileService,private toast:ToastService,private cookieService: CookieService,
    private router: Router,private glHeadService:GlHeadService,private clientService:ClientService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
     
    this.titleService.setTitle("GlHead");
    this. searchByCols = [
     
      { field: "glHeadNname", header: "GlHead Name" },
      { field: "glAcct", header: "GL Account No." },
      { field: "glHeadType", header: "GL Head Type" },
    ];

    this.modalForm = new FormGroup({
      client: new FormControl(),
    });
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag");

    }
  ngOnInit(): void {
   //this.buttonShowHidePermission();
 //this.getAllGlHeadList();
 this.adminFlag=this.cookieService.get("adminFlag");
 this.getClients();
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );

 
   this.fileService.getClientByUserId().subscribe(
     (data) =>{
    
       for(var i=0;i<data.length;i++){
         console.log("data  ",data[i].clientId)
         this.companyId=data[i].clientId
         this.companyName=data[i].clientName
       }
       this.modalForm.get("client").setValue({
         key: this.companyId,
         value: this.companyName
       });

   

      console.log("this.headerForm",this.modalForm.value)
      this.clientSelected(this.modalForm.controls.client.value,'toggleOnInit')
     
    
     },
     (err)=>{
       this.toast.showError(err.error.message, "error");
     }
     );

  this.clientSelected(this.modalForm.controls.client.value,'toggleOnInit')
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllGlHeadList(id){
    
    this.glHeadService.getGLHeadByClientId(id).subscribe((data)=>{
      console.log("Table Call");
      this.glHeadList=data;
  
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }
  
  

   saveData(modalForm){
    
    console.log("ModalForm on Save",modalForm);
     const model={
      glheadId:modalForm.glHeadId!==null?modalForm.glHeadId:0,
      glHeadNname:modalForm.glHeadNname,
      clientId:this.modalForm.controls.client.value.key,
      liveFromDate:modalForm.liveFromDate,
      liveToDate:modalForm.liveToDate,
      glAcct:modalForm.glAcct,
      glHeadShortName:modalForm.glHeadShortName,
      glHeadType:modalForm.glHeadType.value,
      glGroupingHead:modalForm.glGroupingHead,
      gl_schedule:modalForm.gl_schedule,
      isHQLA: modalForm.isHQLA == null ? false : modalForm.isHQLA
     }
    
   
    this.glHeadService.saveOrUpdate(model).subscribe(
      (data) => {
  
     this.toast.showSuccess("GLHead Saved Successfully", "success");
     this.GlHeadHeaderComponent.reset();
     this.getAllGlHeadList(this.modalForm.controls.client.value.key);
   
  
   
   },
   (err) => {
  
     this.toast.showError(err.error.message, "error");
   }

 );
   
 
 }


 editglHead(rowData){
     this.editData=rowData;
     this.GlHeadHeaderComponent.openEdit();
    
   }


   deleteGlHeadId(data) {
        //  const genericModel ={
        //   "glHeadId": data.glheadId
        // }
    
         //this.loader.showLoader();
         this.glHeadService.deleteGlHeadId(data.glheadId).subscribe(
           data => {
            console.log("Delete GL Head ID1 called");
               this.toast.showSuccess('Success', 'GLHead deleted successfully');
               this.getAllGlHeadList(this.modalForm.controls.client.value.key);
             },
             err => {
              console.log("Delete GL Head ID called",data);
               this.toast.showError('Error', 'Error deleting data');
             });
       }


       clientSelected(data,toggleFlag) {
        if(toggleFlag=='toggleOnLoad'){
          this.panelFilter.toggleSlider();
        }
        let clientId=data.key;
        console.log("on hit..........",clientId)
        if(clientId==null || clientId==undefined || clientId==""){
            this.glHeadList=[];
            this.companyName="";
            this.toast.showInfo("Info","Please Select Company")
            return;
        }

        this.cookieService.set('clientId',this.modalForm.controls.client.value.key);
        this.cookieService.set('clientName',this.modalForm.controls.client.value.value);
      if(this.modalForm.controls.client.value.key!=undefined || this.modalForm.controls.client.value.key!=null){
        this.companyName=this.modalForm.controls.client.value.value;
        this.getAllGlHeadList(data.key);
      }
        
      }



       getClients() {  
          this.clientService.getAllClients().subscribe(
  
            (data) => {  
              this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
  
              console.log(this.clientsList)
  
            },
  
            (err) => {
  
              this.toast.showError(err.error.message, "error");
  
            }
  
          );
  
        }
    
 }
