import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { EventEmitter } from 'events';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';

@Component({
  selector: 'app-changelog-table',
  templateUrl: './changelog-table.component.html',
  styleUrls: ['./changelog-table.component.scss']
})
export class ChangelogTableComponent implements OnInit {

  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;
  @Input() data = [];
  @Input() permission:any;
 // @Output() showPopUp = new EventEmitter();
 previousList:any;
 newList:any;
 visible:boolean=false;
  userList:any[];
  
  cols: { field: string; header: string }[];
  constructor() { 
    this.cols = [
      { field: "changeTypeName", header: "Change Type" },
      { field: "clientName", header: "Company Name"},
      { field: "changeName", header: "Change Name" },
      { field: "changeList", header: "Change List" },
      { field: "modifiedUsername", header: "Modified By" },
      { field: "createdTime", header: "Modified At" }
    ];
  }

  
  ngOnInit(): void {
  }
  

  showPopup1(rowData){
   this.panelForm.toggleSlider();
     this.visible=true;  
    this.previousList= JSON.parse(rowData.previousValue);
    this.newList=JSON.parse(rowData.newValue);   

  }

  hidePopup(){
    this.visible=false;
    this.previousList= null;
    this.newList=null; 
  }
 


}
