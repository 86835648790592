import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { UserSelectorComponent } from '../user-selector/user-selector.component';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(UserSelectorComponent, { read: false, static: false })
   userSelectorComponent: UserSelectorComponent;

  @Input() editUserValue
  @Output() saveUser=new EventEmitter();
  @Input() roleLists;
  @Input() clientLists;
  @Input() applicationLists;
  @Input() clientApplicationDataTypeList;
  @Input() applicationData;
  @Input() allGroupData;
  @Input() applicationDataValues;
  @Input() notificationList;
  @Input() rolePermission;
  @Input() allReportViewData;
  @Input() allReportViewDataList;
  @Input() allStatusData;

  @Output() nullEditValues=new EventEmitter();
  @Input() workSpaceList;
  @Input() locationList;
  @Input() departmentList;
  constructor() { }

  ngOnInit(): void {
  }


  newUser(){
    this.nullEditValues.emit();
    this.userSelectorComponent.resetForm();
      this.userSelectorComponent.resetCheckedFlag();
      document.getElementById("disable").style.display="none"
      document.getElementById("enable").style.display=""
    this.panelForm.toggleSlider();
  }

  addUser(data){
this.saveUser.emit(data);

  }

}
