import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { DbconnectionService } from 'src/app/dashboard/services/dbconnection.service';


@Component({
  selector: 'app-data-transfer-list',
  templateUrl: './data-transfer-list.component.html',
  styleUrls: ['./data-transfer-list.component.scss']
})
export class DataTransferListComponent implements OnInit {

  @Input() DataTransferList:any;
  selectedIndex=-1;
  // @Input() hidebutton:boolean;
  // @Output() DataTransferList=new EventEmitter<any>();
  // @Output() delete= new EventEmitter();
  // @Input() permission:any;
  cols: { field: string; header: string }[];
  logCols: { field: string; header: string } [];
  DataTransferLogList:any[];
    
    constructor(private toast:ToastService,private dbconnectionService:DbconnectionService) {
    this.cols = [
      { field: "sourceServiceName", header: "Source Service Name" },
      { field: "sourceDbName", header: "Source DataBase Name" },
      { field: "sourceTableName", header: "Source Table Name" },
      {field:"destinationServiceName", header:"Destination Service Name"},
      { field: "destinationDbName", header: "Destination Database Name" },
      { field: "destinationTableName", header: "Destination Table Name" },
      { field: "createdAt", header: "Created At" }
    ];
    this.logCols = [
      { field: "changeName", header: "Status" },
      { field: "changeList", header: "Columns" },
      { field: "previousValue", header: "From" },
      { field: "newValue", header: "To" },
      { field: "modifiedUsername", header: "By" },
      { field: "createdTime", header: "Transferred on" }
    ];
  }

  ngOnInit(): void {
   // this.dataTransferList();
  }

  viewLogs(rowData) {
    this.dbconnectionService.getDataTransferLogs(rowData.dataTransferId).subscribe(
      (data) => {
        this.DataTransferLogList=data;
      
        console.log("Data Transfer Logs from backend ",data)
      },
      (err) =>{
        //this.toast.showError(err.error.message, "error");
      });
  }

  showLogTable(index: number, event: Event,rowData:any) {
    const ele = event.target as HTMLInputElement;
    if (ele.checked) {
      this.selectedIndex = index;
      this.viewLogs(rowData);
    } else {
      this.selectedIndex = -1;
    }
  }

  // editClick(data){
  //   console.log("edit data ***********",data);
  //   this.editData.emit(data);
  // }



}
