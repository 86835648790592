import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
//import { EventEmitter } from 'protractor';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { ReportConfigurationSelectorComponent } from '../reportConfiguration-selector/reportConfiguration-selector.component';

//import { DepartmentSelectorComponent } from '../department-selector/department-selector.component';

@Component({
  selector: 'app-reportConfiguration-header',
  templateUrl: './reportConfiguration-header.component.html',
  styleUrls: ['./reportConfiguration-header.component.scss']
})
export class ReportConfigurationHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(ReportConfigurationSelectorComponent, { read: false, static: false })
  ReportConfigurationSelectorComponent: ReportConfigurationSelectorComponent;

  
  @Input() editData;
  @Input() currencyData;
  @Input() reportLists;
  @Output() save=new EventEmitter();
  @Input() frequencyList: {key: any; value: any }[]=[];
  
  constructor() { }

  ngOnInit(): void {
  }


  saveReportConfiguration(data){
    console.log("data",data);
this.save.emit(data);

  }

 

  addNewReportConfiguration(){

     this.ReportConfigurationSelectorComponent.formReset();
     this.panelForm.toggleSlider();

    }

    reset(){

     this.ReportConfigurationSelectorComponent.formReset();
     //this.panelForm.toggleSlider();
    }

 openEdit(){
  this.panelForm.toggleSlider();
 }

}
