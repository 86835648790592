import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
//import { EventEmitter } from 'protractor';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { ClientSelectorComponent } from '../client-selector/client-selector.component';

@Component({
  selector: 'app-client-header',
  templateUrl: './client-header.component.html',
  styleUrls: ['./client-header.component.scss']
})
export class ClientHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(ClientSelectorComponent, { read: false, static: false })
  clientSelectorComponent: ClientSelectorComponent;

  @Input() rolePermission:any;
  @Input() allGenericCurrencyData;
  @Input() editData;
  @Output() save=new EventEmitter();
  @Output() emitfileupload=new EventEmitter();
  @Input() allCountryData;
  constructor() { }

  ngOnInit(): void {
  }


  saveClient(data){
this.save.emit(data);

  }

  emitfile(data){
this.emitfileupload.emit(data);
  }

  addNewCompany(){

    this.clientSelectorComponent.formReset();
    this.panelForm.toggleSlider();

   }

   reset(){

    this.clientSelectorComponent.formReset();
    //this.panelForm.toggleSlider();
   }

 openEdit(){
  this.panelForm.toggleSlider();
 }

}
