import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stressScenarioValues-table',
  templateUrl: './stressScenarioValues-table.component.html',
  styleUrls: ['./stressScenarioValues-table.component.scss']
})
export class StressScenarioValuesTableComponent implements OnInit {
 @Input() stressScenarioValuesList:any[];
 @Input() rolePermission:any;
 @Output() edit = new EventEmitter();
 @Output() setValue = new EventEmitter();
 @Output() delete = new EventEmitter();
 dataValue:any;
 visibleDelete: boolean = false;

 cols: { field: string; header: string }[];
  constructor() {
    this.cols = [
      
    ];
   }

  ngOnInit(): void {
  }

  editStressScenarioValues(rowData){
  this.edit.emit(rowData);
  }

  showPopupDelete(rowData) {
        this.dataValue = rowData;
        this.visibleDelete = true;
      }
    
      hidePopup() {
        debugger;
        this.dataValue = null;
        this.visibleDelete = false;
      }
    
      actionDo(str){
        this.deleteStressScenarioValuesId(this.dataValue);
        this.dataValue=null;
        this.visibleDelete=false;
      }
    
      deleteStressScenarioValuesId(rowData){
        this.delete.emit(rowData);
        console.log("deleteStressScenarioValuesID", rowData);
      }
}
