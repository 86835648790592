import { Component, OnInit, Input, SimpleChanges, Output ,EventEmitter, OnChanges} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { mainModule } from 'process';
import { ToastService } from 'src/app/core/services/toast.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { formatDate } from "src/app/utils/dates";
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';


@Component({
  selector: 'app-workflow-job-selector',
  templateUrl: './workflow-job-selector.component.html',
  styleUrls: ['./workflow-job-selector.component.scss']
})
export class WorkflowJobSelectorComponent implements OnInit,OnChanges {
 
  selectorForm: FormGroup = null;
  @Input() editWorkflowJobColumns;
  @Output() saveWorkflowColumns = new EventEmitter();
  @Output() resetWorkflow = new EventEmitter();
  @Input() permission:any;
  @Input() notificationList;
  placeHolder:any="mm.dd.yyyy"
  // reportList: { appId: any; appName: any }[] = [];
  reportList: { key: any; value: any }[] = [];
  selectedNotificationType: {notificationId: any;notificationName: any}[]=[];
  selectedReportType: { key: any; value: any };
  // @Output() saveWorkflowColumns = new EventEmitter();
  enableEdit=false;
  @Output() closePanel=new EventEmitter();
  @Input() groupList:any;
  reportListData:any;
  @Input() clientId:any;
  constructor(private reportDashboardService:ReportDashboardService,private toast:ToastService) { 

    this.selectorForm = new FormGroup({
      workflowName: new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(250)]),
      isActive: new FormControl(null),
      workflowType: new FormControl(null),
      asOfDate:new FormControl(null),
      notificationType: new FormControl(null),
      groupData: new FormControl(null),
      reports:new FormControl(null)
       });

  }

  isActive=[
    { key: '1', value: "Active" },
    { key: '0', value: "Inactive" },
 
  ]

  workflowType=[
    { key: '1', value: "System" },
    { key: '2', value: "Custom" },
  ]

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.editWorkflowJobColumns && changes.editWorkflowJobColumns.currentValue) {
   console.log(changes.editWorkflowJobColumns.currentValue,' inside changes') 
       const workflowJob=changes.editWorkflowJobColumns.currentValue;
       this.enableEdit = true;
       let notificationEditList=changes.editWorkflowJobColumns.currentValue.notificationMappingList
       let reportEditList=changes.editWorkflowJobColumns.currentValue.reportData
       let groupEditList=changes.editWorkflowJobColumns.currentValue.groupValue
       this.selectedNotificationType=[];
       this.selectedReportType=null;
       for (var i = 0; i < notificationEditList.length; i++) {
         this.selectedNotificationType.push({ notificationId: notificationEditList[i].notificationId, notificationName: notificationEditList[i].notificationName });
       }
       for (var i = 0; i < reportEditList.length; i++) {
        this.selectedReportType={ key: reportEditList[i].appId, value: reportEditList[i].appName };
        break;
      }
     if(groupEditList!=null){
      const defaultMap = {
        key: groupEditList.groupId,
        value: groupEditList.groupName,
      };
      this.selectorForm.patchValue({
        "groupData":defaultMap
      })
    }
    // this.onGroupChange(event)

     const dataVal={
      "groupId":this.selectorForm.controls.groupData.value.key,
      "reportMode":"readytouse",
      "clientId":this.clientId,
    }
  
    this.reportDashboardService.getWorkflowReportByGroupId(dataVal).subscribe(
  
      res => {
        // this.loader.hideLoader();
        
        
        console.log("this.reportList  ",this.reportList);
        this.reportList=reMapForAutoComplete( res,"appId","appName");
      // this.reportList.push(this.reportListData);  
      
       for (var i = 0; i < reportEditList.length; i++) {
       // this.selectedReportType=null;
        this.reportList.push({ key: reportEditList[i].appId, value: reportEditList[i].appName });
        break;
      }
        console.log("all report  ", this.reportList);

         this.selectorForm.patchValue({
           reports:this.selectedReportType,
         })
         console.log("selected report  ", this.selectorForm.value);
        },
        err => {
         // console.log('url',err);
        // this.toast.showError("failed to get groups", "error");
  
        }
    )
       this.checkForEdit() 
       this.selectorForm.patchValue({
        notificationType:this.selectedNotificationType,
      });
      }
     
   console.log("values......",this.selectorForm.value)
    }

    checkForEdit() {
      if (this.editWorkflowJobColumns && this.enableEdit) { 
        this.selectorForm.patchValue({
          workflowName: this.editWorkflowJobColumns.workflowName,
          isActive:{key:this.editWorkflowJobColumns.isActive,value:this.editWorkflowJobColumns.isActive==='1'?'Yes':'No'},
          workflowType:{key:this.editWorkflowJobColumns.workflowType,value:this.editWorkflowJobColumns.workflowType==='1'?'System':'Custom'},
          reports:this.selectedReportType,
          
        });
        this.enableEdit = false;
      }
     
    }


    saveWfData(){
      if(this.selectorForm.invalid){
        if(this.selectorForm.controls.workflowName.invalid){
          this.toast.showWarn('Workflow Name is Mandatory in given Format mandatory ','error');
          this.closePanel.emit();
          return;
        }
      }
      this.closePanel.emit();
      const data={
      
        "workflowName":this.selectorForm.controls.workflowName.value,
        "isActive":this.selectorForm.controls.isActive.value.key,
        "workflowType":this.selectorForm.controls.workflowType.value.key,
        "asofdate":this.selectorForm.controls.asOfDate.value!==null && this.selectorForm.controls.asOfDate.value!==undefined?this.selectorForm.controls.asOfDate.value:null,
        "notificationType": this.selectedNotificationType,
        "group":this.selectorForm.controls.groupData.value,
        "reports":this.selectedReportType
      }
      this.saveWorkflowColumns.emit(data);
      this.resetData();
      //this.closePanel.emit();
     // this.selectedNotificationType=[];
    }

    

  resetData(){
    
  //  this.resetWorkflow.emit(true);
  this.selectedNotificationType=[];  
  this.selectedReportType=null;
  console.log("this.selectedReportType  ",this.selectedReportType)
  this.reportList=[];
  this.selectorForm.reset();
  
   
    this.enableEdit = false;
    this.editWorkflowJobColumns=null;
        if(this.selectorForm!==null){
        this.selectorForm.reset();
        }
  }

  multiNotificationTypeSelectChange(eventData){
    this.selectedNotificationType = eventData.value;
  //  console.log("Datatype Value Updated Is:", this.selectedNotificationType);

  }

  onGroupChange(event){

    console.log("evetData  ",event.target.value)
   // this.headerForm.controls.ReportData.reset();
    //this.headerForm.controls.viewData.reset();
    //this.env=null;
    //this.viewList=[];
    const dataVal={
      "groupId":this.selectorForm.controls.groupData.value.key,
      "reportMode":"readytouse",
      "clientId":this.clientId,
    }
  
    this.reportDashboardService.getWorkflowReportByGroupId(dataVal).subscribe(
  
      res => {
        // this.loader.hideLoader();
        this.selectedReportType=null;
        //this.reportList=res;
        console.log("this.reportList  ",this.reportList);
        this.reportList=reMapForAutoComplete(res,"appId","appName");
      // this.reportList.push(this.reportListData);  
        console.log("all report  ", this.reportList);
        if(this.reportList.length==0){
          console.log("show error");
          this.toast.showError('error','No Reports Found');
        }
  
        },
        err => {
         // console.log('url',err);
        // this.toast.showError("failed to get groups", "error");
  
        }
    )
  
  
  }

  multiReportTypeSelectChange(eventData){
    // this.selectedReportType = eventData.value;
    this.selectedReportType=null;
    this.selectedReportType={key:this.selectorForm.get("reports").value.key,value:this.selectorForm.get("reports").value.value};
  //  console.log("Datatype Value Updated Is:", this.selectedNotificationType);

  }

}
