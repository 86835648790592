import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-export-actions',
  templateUrl: './export-actions.component.html',
  styleUrls: ['./export-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportActionsComponent {
  actions = [
    { text: 'CSV', icon: 'design-report.svg' },
  //  { text: 'Excel', icon: 'lead_to_another_page.svg' },
    { text: 'PDF', icon: 'lead_to_another_page.svg' },
  ];
  @Output() selectedAction = new EventEmitter();

}
