import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from '../../../ui/components/side-panel/side-panel.component';
import { StressScenarioValuesSelectorComponent } from '../stressScenarioValues-selector/stressScenarioValues-selector.component';

@Component({
  selector: 'app-stressScenarioValues-header',
  templateUrl: './stressScenarioValues-header.component.html',
  styleUrls: ['./stressScenarioValues-header.component.scss']
})
export class StressScenarioValuesHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(StressScenarioValuesSelectorComponent, { read: false, static: false })
  StressScenarioValuesSelectorComponent: StressScenarioValuesSelectorComponent;

  
  @Input() editData;
  // @Input() stressScenarioList;
  @Output() save=new EventEmitter();
  @Input() header=true;
  constructor() { }

  ngOnInit(): void {
  }


  saveStressScenarioValues(data){
    console.log("data",data);
this.save.emit(data);

  }

 

  addNewStressScenarioValues(){

     this.StressScenarioValuesSelectorComponent.formReset();
     this.panelForm.toggleSlider();

    }

    reset(){

     this.StressScenarioValuesSelectorComponent.formReset();
     //this.panelForm.toggleSlider();
    }

 openEdit(){
  this.panelForm.toggleSlider();
 }

}
