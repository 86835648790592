import { Component, OnInit ,ViewChild} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { differenceWith, isEqual } from "lodash";
import { FormsModel } from "src/app/models/forms.model";
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from "rxjs";
import { RoleService } from '../../services/role.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { UserService } from 'src/app/user/services/user.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { Console } from 'console';
import { DualListComponent} from 'src/app/roles-dual/component/dual-list/dual-list.component'
import { ActionDataModel } from '../../models/action.model';
import { Store } from '@ngrx/store';
import { storePermission, storeUser } from 'src/app/user/store/auth.action';
import { take } from 'rxjs/operators';
import { RoleMenuType } from '../role-menu-permission/role-menu'; // Pradeep Yadav
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { RoleParameterComponent } from 'src/app/roles-dual/component/role-parameter/role-parameter.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  @ViewChild(DualListComponent, {read: false, static: false })
  dualListComponent:DualListComponent;
  @ViewChild(RoleParameterComponent, {read: false, static: false })
  roleParameterComponent:RoleParameterComponent;

  roles: { key: string | number; value: string }[];
  rolesCtrl = new FormControl(null);
  sourceData: { key: string | number; value: string }[];
  targetData: { key: string | number; value: string }[];
  masterSourceData: Object;
  allActionData:ActionDataModel;
  roleName:string=null;
  roleLabel:string="Select Role";
  activeRoute$: Subscription;
  addRolePopup: boolean = false;
  actionList:any=[];
  clientLists:any=[];
  applicationLists:any=[];
  clientApplicationDataTypeList:any=[];
  roleId:any;
  rolePermission:any;
  dataValue:any;
  user:any;
  activeRoles:{roleId:any;roleName:any}[]=[];
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  newRoleModel: FormsModel = {
    controls: [
      {
        name: "roleName",
        type: "text",
        validators: [Validators.required],
        label: "Role Name",
      },
      {
        name: "roleDescription",
        type: "text",
        validators: [],
        label: "Role Description",
      },
      
    ],
  };
  roleForm: FormGroup;
  parameterForm:FormGroup;
  roleMenuType: RoleMenuType[]; // Pradeep Yadav
  state:string;
  constructor(private http: HttpClient,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private rolesService: RoleService,
    private toast: ToastService,
    private fileService: FileService,
    private userService: UserService,
    private loader:LoaderService,
    private store: Store<{ user: any }>,
    private router: Router) {
      this.parameterForm = new FormGroup({
        role:new FormControl(null),
       });

       this.state=localStorage.getItem("stateflag");
     }

  ngOnInit(): void {
  //  this.buttonShowHidePermission("init");
  this.callNgOnInitMethods();  
  //Start- Pradeep Yadav
   // this.getRolePageMenuData();
    //End- Pradeep Yadav
  }

  getAllRules(data) {
    console.log(data);
    // this.http.get(`${environment.baseUrl}/users/getAllRoles`);
  this.roleName=data.role.value.value;
    this.roleId=data.role.value.key;
   this.getRolePageMenuData(data);
   this.getAllActionsForRole(this.roleId);

      // console.log("enter into roles  ",this.roleId)

      //   this.rolesService.getMappedRolesById(this.roleId)
      //   .subscribe(
      //     data=>{
      //       this.editRoleValue =data;
      //       console.log("return mapped values  ",this.editRoleValue)  
      //     })
        
  }
  loadMissingToSource() {
    const sourceData = reMapForAutoComplete(
      this.masterSourceData as any[],
      "pageName",
      "actionGroup"
    );
    this.sourceData = differenceWith(sourceData, this.targetData, isEqual);
  }
  getSelectedData(data) {
    console.log("role id" +this.roleId);
    const actionListData=data;
    for(var a=0;a<actionListData.length;a++){
        this.actionList.push(actionListData[a].value);
      }

      const PageMenu = this.getSelectedPageId();
    console.log("pageMenu-", PageMenu);
    const ActionData={
     "roleId":this.roleId,
     "actionGroup":this.actionList

    }

    const rolePageRequestModel={
      "actionsModel":ActionData,
      "roleMenuRequestModelList":PageMenu
    }
    this.rolesService.saveAllActions(rolePageRequestModel).subscribe(
      (data) => {
        this.actionList=[];
        this.toast.showSuccess("Action SuccesFully Saved", "success");
        // this.store.select('user').pipe(take(1))
        // .subscribe(u => this.user = u);
        //   this.getUserDetails()
        
         this.buttonShowHidePermission("onChange");
           window.location.reload();
         
           
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

// Start - Pradeep Yadav
//  getSelectedPageId() {
//   const PageMenu = [];
//   if (this.roleMenuType && this.roleMenuType.length > 0) {
//     this.roleMenuType.forEach((x) => {
//       if (x.menuPageAssignList && x.menuPageAssignList.length > 0) {
//         x.menuPageAssignList.forEach((a) => {
//           if (a.assinged) {
//             PageMenu.push({ 'pageName': a.pageName, 'pageId': a.pageId })
//           }
//         })
//       }
//       else {
//         x.subMenuAssigList.forEach((a) => {
//           if (a.menuPageAssignList && a.menuPageAssignList.length > 0) {
//             a.menuPageAssignList.forEach((b) => {
//               if (b.assinged) {
//                 PageMenu.push({ 'pageName': b.pageName, 'pageId': b.pageId })
//               }
//             })
//           }
//         })
//         }
//       })
//     }
//   return PageMenu;
// }

getSelectedPageId() {
  const PageMenu = [];
  if (this.roleMenuType && this.roleMenuType.length > 0) {
    //this.roleMenuType.forEach((x) => {
    for(let i=0;i<this.roleMenuType.length;i++){
      if (this.roleMenuType[i].submenu && this.roleMenuType[i].submenu.length > 0) {
        for(let j=0;j<this.roleMenuType[i].submenu.length;j++){
        //x.submenu.forEach((a) => {
        if(this.roleMenuType[i].submenu[j].submenu===null){
          if (this.roleMenuType[i].submenu[j].assinged) {
            PageMenu.push({ 'pageName': this.roleMenuType[i].submenu[j].pageName, 'pageId': this.roleMenuType[i].submenu[j].pageId })
          }
        } else{
          for(let k=0;k<this.roleMenuType[i].submenu[j].submenu.length;k++){
            if (this.roleMenuType[i].submenu[j].submenu[k].assinged && this.roleMenuType[i].submenu[j].submenu[k].pageId>0) {
              PageMenu.push({ 'pageName': this.roleMenuType[i].submenu[j].submenu[k].pageName, 'pageId': this.roleMenuType[i].submenu[j].submenu[k].pageId })
            }
          }
        }
      }
      }
      // else {
      //   x.subMenuAssigList.forEach((a) => {
      //     if (a.menuPageAssignList && a.menuPageAssignList.length > 0) {
      //       a.menuPageAssignList.forEach((b) => {
      //         if (b.assinged) {
      //           PageMenu.push({ 'pageName': b.pageName, 'pageId': b.pageId })
      //         }
      //       })
      //     }
      //   })
      //   }
      }
    }
  return PageMenu;
}



//End - Pradeep Yadav
  getMappedRolesData(data){

    console.log("role id" +this.roleId);
  
  let clientsSelectedList = data.clients;
  let applicationSelectedList = data.applications;
  let fileDataTypeSelectList = data.fileDataType;
  
  const mappedData = {
        roleId:this.roleId,
        clientsMappingList:clientsSelectedList,
        applicationMappingList:applicationSelectedList,
        datatypeMappingList:fileDataTypeSelectList
  };console.log("mappedData ",mappedData);

  this.rolesService.saveMappingForRole(mappedData).subscribe(
    (data) => {
     // this.dualListComponent.resetForm();
      this.toast.showSuccess("Data Mapping SuccesFully Saved", "success");
    },
    (err) =>{
      this.toast.showError(err.error.message, "error");
    } 
  );


  }



  showPopup() {
    this.addRolePopup = true;
  }
  doAction(type: string) {
    if (type.toLowerCase() === "yes") {
      this.roleForm.reset();
    }
    this.addRolePopup = false;
    this.roleForm.reset();
  }
  getForm(form: FormGroup) {
    this.roleForm = form;
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  addRole(data){
    console.log(data)
    const roleData={
      "roleName":data.roleName,
      "roleDescription":data.roleDescription
    }
    this.rolesService.saveRole(roleData).subscribe(
      (data) => {
        //this.addRolePopup = false;
        //this.roleForm.reset();
        this.getRoles();
        this.toast.showSuccess("Role SuccesFully Saved", "success");
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  getRoles(){
    this.http
    .get(`${environment.baseUrl}/users/getAllRoles`)
    .subscribe((res) => {
      this.roles = reMapForAutoComplete(res as any[], "roleId", "roleName");
    });
  }



  getClientList(){
    this.userService.getClientListData().subscribe(
      (data) => {
     //   this.clientLists=data;
    //    this.clientLists=this.formatClientFields(this.clientLists);
        this.loader.hideLoader();
        this.clientLists = this.reMapForclientCompleteMultiSelect(data, "clientId", "clientName");
        
        //console.log("clientLists comp role list" +this.clientLists);
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  reMapForclientCompleteMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { clientId: string | number; clientName: string }[] {
    return data.map(d => ({ clientId: d[keyCol], clientName: d[valCol] }));
  }
  

  getAllApplications(){
    this.fileService.getAllApplications().subscribe(
      (data) => {
        console.log("Applications",data);
        this.loader.hideLoader();
        this.applicationLists = this.reMapForApplicationMultiSelect(data, "applicationId", "name");
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  reMapForApplicationMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { applicationId: string | number; name: string }[] {
    return data.map(d => ({ applicationId: d[keyCol], name: d[valCol] }));
  }

  getAllApplicationFileDataType(){
    this.fileService.getAllApplicationFileDataType().subscribe(
      (data) => {
        console.log("DataTypes",data);
        this.loader.hideLoader();
        this.clientApplicationDataTypeList = this.reMapForFileDataTypeMultiSelect(data, "clientApplicationFileDataType", "type");
        
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  reMapForFileDataTypeMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { clientapplicationfiledatatypeId: string | number; type: string }[] {
    return data.map(d => ({ clientapplicationfiledatatypeId: d[keyCol], type: d[valCol] }));
  }


  buttonShowHidePermission(data){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
         if(data==="init"){
           this.callNgOnInitMethods();
         }
           //  const permission=this.rolePermission;
          //  this.store.dispatch(storePermission({permission: this.rolePermission})); 
          //  this.store.select(x => this.dataValue = x).subscribe();
          //  console.log(this.dataValue)
   });
  }

    

    getUserDetails(){
      this.rolesService.getUserData().subscribe(
        (res) =>{
          const data=res;
        this.store.dispatch(storeUser({ data: data }));
        console.log(data);
        }
      )
    }

    //Start- Pradeep Yadav
  getRolePageMenuData(data) {
    this.roleMenuType=[];
    let clientId = data.role.value.key;
    this.rolesService.getRolePageMenuData(clientId).subscribe(
      (response: RoleMenuType[]) => {
        if (response.length > 0) {
          this.roleMenuType = response;
          console.log('RoleMenuType-', response);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  //End- Pradeep Yadav
  callNgOnInitMethods(){
  this.setTitle();
  // this.http
  // .get(`${environment.baseUrl}/users/getAllRoles`)
  this.rolesService.getAllRoles()
  .subscribe((res) => {

    for(var k in res){
     if(res[k].roleStatus==true){
      this.activeRoles.push({ roleId: res[k].roleId, roleName: res[k].roleName })
     }
     
    }
    console.log("filter list ",this.activeRoles);
    this.roles = reMapForAutoComplete(this.activeRoles as any[], "roleId", "roleName");
  },
  (err) => {
    this.toast.showError(err.error.message, "error");
  }
  
  );
// this.http
//   .get(`${environment.baseUrl}/users/getAllActions`)
this.rolesService.getAllActions()
  .subscribe((res) => {
    this.masterSourceData = res;
  },(err) => {
    this.toast.showError(err.error.message, "error");
  }
  );


//  this.http
//   .get<ActionDataModel>(`${environment.baseUrl}/users/getAllActionList`)
this.rolesService.getAllActionList()
  .subscribe((res) => {
    this.allActionData = res;
    console.log("mastersourcejson list ",this.allActionData);
  },(err) => {
    this.toast.showError(err.error.message, "error");
  }
  );  

  
  this.getClientList();
  this.getAllApplications();
  this.getAllApplicationFileDataType();

   this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
    params => {
        if (params.has("roleId")) {
          this.parameterForm.get("role").setValue({
            key: params.get("roleId"),
            value: params.get("roleName")
          });
        //  this.roleId=par.get("roleId");
         // this.roleName=params.get("roleName");
          this.getAllRules(this.parameterForm.controls);
          // this.roleParameterComponent.parameterForm.get("role").setValue({
          //   key: this.parameterForm.controls.role.value.key,
          //   value: this.parameterForm.controls.role.value.value
          // });
        }
        }
    );


  } 

  getAllActionsForRole(data){
    // this.http
    // .post(`${environment.baseUrl}/apis/users/getAllActionsByRoleId`, {
    //   roleId: data.role.key,
    // })
    const role={
      "roleId": data
    }
    this.rolesService.getAllActionListForRole(role).subscribe((res) => {
      this.targetData = reMapForAutoComplete(
        res as any[],
        "pageName",
        "actionGroup"
      );
      this.loadMissingToSource();
    });
  }
  
}

