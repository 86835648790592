import { Component, EventEmitter,Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header-stepper-component',
  templateUrl: './header-stepper-component.component.html',
  styleUrls: ['./header-stepper-component.component.scss']
})
export class HeaderStepperComponentComponent implements OnInit {

@Output() setpsOut=new EventEmitter();

  
  @Input() steps;
  // steps = [
  //   { text: 'manage', link: '' },
  //   { text: 'setup', link: '' },
    
  // ];
  currentStep: number = 1;
  constructor(private activeRoute: ActivatedRoute,
    private router:Router) {}

  getStatus(index: number) {
   // console.log("data",this.currentStep);
    const d = index + 1;
    this.setpsOut.emit(this.currentStep);
   // console.log("Hello ======================");
    if (d < this.currentStep) {
      return 'completed';
    } else if (d === this.currentStep) {
      return 'ongoing';
    } else {
      return null;
    }
  }

  ngOnInit(): void {
    this.activeRoute.queryParamMap.subscribe((params) => {
      if (params.has('step')) {
        this.currentStep = Number(params.get('step'));
      }
    });
  }

}
