import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-set',
  templateUrl: './menu-set.component.html',
  styleUrls: ['./menu-set.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MenuSetComponent {
  @Input('data') menuItems: any[] = [];
  expandedIndex: number = -1;

  activeRoute$: Subscription;
  linkSplit:string[];
  @Input() menuLable: string;
  linkArr:string[];
  url:string;
  constructor(private router: Router,private activeRoute: ActivatedRoute) {}
  

  setActiveClass(event: Event, link: string | undefined, index: number,applicationId:any,label:string) {
    // console.log("submenu",label+"/"+submenu+"/"+index);
    localStorage.setItem("submenu",label)
    localStorage.setItem("menu",this.menuLable)
      console.log("submenu",label)
      console.log("menu",this.menuLable)

    if(applicationId!=null && applicationId!=undefined){
        localStorage.setItem("stateflag",applicationId) 
        }
    if (!link) {
     
      const ele = event.target as HTMLAnchorElement;
      ele.classList.toggle('active');
      if (ele.classList.contains('active')) {
        this.expandedIndex = index;
        
      } else {
        this.expandedIndex = -1;
      }
    }else{
      this.router.navigate([link]);
    }
    

console.log("link   ",link)
    if(link.includes("/view-rawData")){
      console.log("link1   ",link)
      let getReportId;
      localStorage.setItem("runWorkflow","false");
      this.linkArr=link.split("=");
      if(link.includes("reportId")){
         
        localStorage.setItem("menuAddedreportId",this.linkArr[2]);
        this.url=this.linkArr[1].replace("&reportId","");
        localStorage.setItem("runWorkflow","true");
      }
      else{
        this.url=this.linkArr[1].replace("&","");
        localStorage.setItem("menuAddedreportId","0");
      }
           
    
             localStorage.setItem("pageView","true");
  
            let page = "/view-rawData";
            const fileData={
              reportUrl:this.url,
            }
            this.router.navigate([page], {
             queryParams: {...fileData},
           });
  
          }
        }
      // );

    
  
}
