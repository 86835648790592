import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { MenuItem } from 'primeng';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MENU_ITEMS } from 'src/app/constants/menu.constants';
import { AuthService } from 'src/app/user/services/auth.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-main-sidebar',
  templateUrl: './main-sidebar.component.html',
  styleUrls: ['./main-sidebar.component.scss'],
})
export class MainSidebarComponent implements OnInit{
 // menuExtraItems = MENU_ITEMS;
  menuItems: MenuItem[];
  counter1: any;
  user: any;
  @Input() noBranding: boolean = false;
  expandedIndex: number = -1;
  addMenuItem: { additem: string; routerLink: string; icon:string }[];
  @Input() state:string;
  stateVar:string;
  activeRoute$: Subscription;
  linkSplit:string[];
  linkArr:string[];
  url:string;
  multipleApplication:string;
  constructor(private menuService: MenuService,private authService: AuthService,private cookieService: CookieService, private store: Store<{ user: any }>, private router: Router,private activeRoute: ActivatedRoute) {

    this.stateVar=localStorage.getItem('stateflag');
    this.multipleApplication=localStorage.getItem('assignMultipleApplication');
  }

  ngOnInit(): void {
    this.store.select('user').pipe(take(1))
    .subscribe(u => this.user = u)
  
 //   if(this.user.clientId===0 || this.user.clientId===null){
    this.menuService.getMenuItems(this.state).subscribe(
    data => (this.menuItems = data),
    err => console.log("error", err)
  );

  this.addMenuItem=[
    { additem: "Notifications", routerLink: "",icon:"/assets/icons/notification.svg" },
    { additem: "Help/FAQ", routerLink: "/help",icon:"/assets/icons/help.png" },
    { additem: "Change Password", routerLink: "/change-password",icon:"/assets/icons/password.svg" },
  //  { additem: "Logout", routerLink: "/login",icon:"/assets/icons/logout.svg" },
  ];
  
}


  setActiveClass(event: Event, link: string | undefined, index: number,label:string, submenu:string) {
    localStorage.setItem("allActiveMenuList", JSON.stringify(this.menuItems));
    console.log("event",event)
    // localStorage.setItem("menu",label)
    console.log("Menulable",label);
    console.log("submenu",submenu);
    if(submenu.length===0){
      localStorage.setItem("menu",label);
      localStorage.setItem("submenu","");
    }

    if (!link) {
      const ele = event.target as HTMLAnchorElement;
      ele.classList.toggle('active');
      
      if (ele.classList.contains('active')) {
        this.expandedIndex = index;
      } else {
        this.expandedIndex = -1;
      }
    }

    if(link!=null){
      if(link.includes("/view-rawData")){
        console.log("link1   ",link)
        let getReportId;
        localStorage.setItem("runWorkflow","false");
        this.linkArr=link.split("=");
        if(link.includes("reportId")){
           
          localStorage.setItem("menuAddedreportId",this.linkArr[2]);
          console.log("set menuAddedreportId: ",this.linkArr[2]);
          this.url=this.linkArr[1].replace("&reportId","");
          localStorage.setItem("runWorkflow","true");
        }
        else{
          this.url=this.linkArr[1].replace("&","");
          localStorage.setItem("menuAddedreportId","0");
        }

        
               localStorage.setItem("pageView","true");
                
              let page = "/view-rawData";
              const fileData={
                reportUrl:this.url,
              }
              this.router.navigate([page], {
               queryParams: {...fileData},
             });
    
            
          }
  }
  }

  logout(){
    this.cookieService.deleteAll();
    this.authService.signOut();
  }

  BackToNova(){

    this.store.select('user').pipe(take(1))
    .subscribe(
      u => {this.user = u;
        console.log("user on back to Nova",u.home);
        localStorage.setItem("stateflag",'6');
       
        this.cookieService.set("userId",u.userId); 
        console.log("userId",u.userId);
        if(u.home){
          this.router.navigate(['/home-screen']);
        }else{
          this.router.navigate(['/report/dashboard']);
          if(location.pathname === "/report/dashboard"){
            location.reload();
          }
        }
        console.log("Page: ",location.pathname);
       
      }) ;
  }



}
