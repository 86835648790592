import { formatDate, getCurrentDate } from 'src/app/utils/dates';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ToastService } from 'src/app/core/services/toast.service';
import { currentMonthRange } from 'src/app/utils/dates';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ClientPortalService } from '../../services/client-portal.service';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { FileService } from 'src/app/files/services/file.service';
import { ClientService } from '../../services/client.service';
import {isEmpty,trim} from 'lodash';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { CookieService } from 'ngx-cookie-service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';


@Component({
  selector: 'app-client-portal-data',
  templateUrl: './client-portal-data.component.html',
  styleUrls: ['./client-portal-data.component.scss']
})
export class ClientPortalDataComponent implements OnInit {
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;
  @ViewChild("panelForm1", {static: false })
  panelForm1!:SidePanelComponent;

clientPortalList:any;
user:any;
showClient:boolean;
headerForm:FormGroup;
fromDt:any;
toDt:any;
dataTransferModel:DataTransferModel;
activeRoute$: Subscription;
clientData:any;
clientId:number=0;
clientIdVal:any;
client:string=null;
fromDate:string=null;
toDate:string=null;
//cols: { field: string; header: string }[];
headerFormVal:any;
currentFileUpload:FileList;
currentFileUploadList:FileList;
showClientFilePopUp:boolean=false; 
showClientAppFilePopUp:boolean=false;
cols = [
  { field: "filename", header: "File Name" },
  { field: "clientApplicationFile", header: "Layout File" },
  { field: "clientFileMaster", header: "Master File" },
  { field: "asOfDate", header: "AsOfDate" },
  { field: "uploadMessage", header: "Upload Status" },
  { field: "userName", header: "Created By" },
  { field: "createdAt", header: "Created At" }
];
popUpName:string;
addHeader:string="Add";
deleteHeader:string="Remove Layout";
visible:boolean=false;
visibleLayoutPopUp:boolean=false;
popUpForm:FormGroup;
dropDownList:any[]=[];
action:string=null;
clientFileId:any=null;
clientFileUpdate:any=null;
deleteVisible:boolean=false;
rolePermission:any;
clientsList : {key: any; value: any }[]=[];
clientApplicationFileForm:FormGroup;
myInputVariable: ElementRef;
@ViewChild('myInput2')
myInputVariable2: ElementRef;
fileList:any=[];
showLoading:boolean=false;
isZipUpload:boolean=false;
fieldSeparatorList:{ key: string ; value: string }[];
layoutFieldList: any = [];
dependentUponList: any = [];
fileTypeList: any = [];
fileDataTypeList: any = [];
adminFlag:String;
searchQuery!: any;
searchCol:String;
searchByCols:{field:String | number, header:String }[];
 state:string;
constructor(private clientPortalService: ClientPortalService,
            private toast:ToastService,
            private store: Store<{ user: any }>,
            private activeRoute: ActivatedRoute, 
            private titleService:TitleService,
            private loader:LoaderService,
            private clientService:ClientService,
            private fileService:FileService,
            public observalbleDataService: ObservalbleDataService,
            private cookieService: CookieService,
            private storePermission: Store<{ role: any }>
           
            ) {
         
      this.headerForm = new FormGroup({
        client: new FormControl(null),
        toDate: new FormControl(null),
        fromDate: new FormControl(null),
        asOfDate: new FormControl(null)
      });

      this.popUpForm = new FormGroup({
        fileData: new FormControl(null),
        deleteMaster:new FormControl(false),
       
      });

      this.clientApplicationFileForm = new FormGroup({
        fileType: new FormControl(null),
        fileDataType: new FormControl(null),
        layoutFieldId: new FormControl(null),
        dependentUpon: new FormControl(null),
        fieldSeparator: new FormControl(null),
        schedule: new FormControl(null)
      })
      
    this. searchByCols = [
      { field: "filename", header: "File Name" },
      { field: "clientApplicationFile", header: "Layout File" },
      { field: "clientFileMaster", header: "Master File" },
      
    ];
    this.state=localStorage.getItem("stateflag"); 
    }



  ngOnInit(): void {
    this.adminFlag=this.cookieService.get("adminFlag");

    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );

     this.buttonShowHidePermission();

     //this.storePermission.select(x => this.rolePermission = x).subscribe();
    }

    clientSelected(event){
      console.log("event :",event);
      this.clientId=event.key;
      this.cookieService.set('clientId',event.key);
      this.cookieService.set('clientName',event.value);
    //  this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
     // this.maintainClientState(event,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }

  getAllclientPortalData(data){
   // console.log("form data from child",data);
    this.client=data.client.value;
    this.fromDate=formatDate(data.fromDate);
    this.toDate=formatDate(data.toDate);
    this.headerFormVal=data;
    console.log("form data from child header client-portal",this.headerFormVal);
    const ClientPortal={
      "clientId":data.client.key,
      "fromDate":formatDate(data.fromDate),
      "toDate":formatDate(data.toDate),
      "applicationId":this.state
    }
  //  this.loader.showLoader();
   // console.log("Called");
this.clientPortalService.getAllclientPortalData(ClientPortal)
.subscribe((data) => {
  //console.log("client-portal data "+data)
  this.clientPortalList=data;
  //this.loader.hideLoader();
  },
(err) =>{
  this.toast.showError(err.error.message, "error");
  //this.loader.hideLoader();
} 
); 
}

setTitle() {
  this.activeRoute$ = this.activeRoute.data.subscribe(
    (data) => this.titleService.setTitle(data["title"]),
    (err) => console.log("error", err)
  );
}

changeToDate(e){
  this.headerForm.controls.toDate.setValue(e);
  let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  if(validate===true){
    this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
   // this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  }
  if(validate===false){
    this.toast.showWarn('error','date range incorrect')
    
  }
  //console.log(e,'  todate');
  //this.maintainStateOnDateChange(e,'toDateChange');
}

changeFromDate(e){
  console.log("Hello Shail" , e);
  this.headerForm.controls.fromDate.setValue(e);
  let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  if(validate===true){
    this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
   // this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  }
  if(validate===false){
    this.toast.showWarn('error','date range incorrect')
    
  }
  //this.maintainStateOnDateChange(e,'fromDateChange');
}

validationOnDate(fromdate,todate){
  if(fromdate && todate){
  if(new Date(fromdate)>new Date(todate)){
  
      return false;
    }
    return true;
    
  }else{
    return false;
  }
}

loadDate(){
  let currentDateRange=currentMonthRange();
  //console.log(currentDateRange,'  current date range');
  this.headerForm.controls.fromDate.setValue(currentDateRange[0]);
  this.headerForm.controls.toDate.setValue(currentDateRange[1]);
   //console.log(this.headerForm.controls.fromDate.value,'  from Date range');
   //console.log(this.headerForm.controls.toDate.value,'  To Date date range');
   this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
 
}

getClient(clientId){
  this.clientService.getClientsById(clientId).subscribe((data) => {
    this.clientData=data;
    },
  (err) =>{
   // this.toast.showError(err.error.message, "error");
   } 
  ); 
  }

  addLayout(data){
    this.panelForm.toggleSlider();
     console.log("main layout",data);
     this.clientFileId=null;
     this.action=null;
     this.addHeader="Add Layout : "+data.filename;
     this.action='Layout';
     this.clientFileId=data.clientFileId;
     this.clientFileUpdate=data;
     this.getAllClientLayoutFiles(data);
     
  }

  addMaster(data){
    this.panelForm1.toggleSlider();
    console.log(data)
    console.log("main layout",data);
     this.clientFileId=null;
     this.action=null;
     this.addHeader="Add Master : "+data.filename;
     this.action='Master';
     this.clientFileId=data.clientFileId;
     this.clientFileUpdate=data;
    this.getAllClientMasterFile(data);
  }

  updateCall(data){

  }

  doAction(){
     this.visible=false;
     this.clientFileUpdate=null;
     this.action=null;
     this.deleteVisible=false;
     this.popUpForm.reset();
  }

  getAllClientLayoutFiles(data){
     this.dropDownList=[];
    this.fileService.getFilesByClientIdModel(this.headerFormVal.client.key)
    .subscribe(
      data=>{
        console.log(data);
        data.forEach(item=>
          this.dropDownList.push({'key':item.clientApplicationFileId,'value':item.fileName})
         
        )
        this.visible=true;
        console.log("dropdownlost",this.dropDownList);
      },
      error => {
        this.toast.showError(error.error.message, "error");
      }
    )

  }

  getAllClientMasterFile(data){
    this.dropDownList=[];
    this.fileService.getClientFileMasterListByClient(this.headerFormVal.client.key)
    .subscribe(
      data=>{
        console.log(data);
        data.forEach(item=>
          this.dropDownList.push({'key':item.clientfilemasterId,'value':item.name})
         
        )
        this.visible=true;

      },
      error=>{
        this.toast.showError(error.error.message, "error"); 
      }
    )

  }



  updateAddClientFile(){
    //console.log("error check ",this.popUpForm.controls.filedata);
   // console.log("error check ",this.clientFileUpdate)
  
    try{
   
     const fileId=this.popUpForm.controls.fileData.value.key;
   }catch{     
       this.toast.showInfo('error','select file');
       return;
   }

   
    if(this.action==='Layout'){
      this.panelForm.toggleSlider();
      const requestModel={
        "clientApplicationFileId":this.popUpForm.controls.fileData.value.key,
        "clientFileId":this.clientFileUpdate.clientFileId,
        "fileName":this.clientFileUpdate.filename,
        "clientId":this.headerFormVal.client.key,
        "fromDate":this.clientFileUpdate.asOfDate,
        "clientPortalId":this.clientFileUpdate.clientPortalId,
        "state":this.state
      }

      this.clientPortalService.updateClientFile(requestModel)
      .subscribe(
        data=>{
          this.toast.showSuccess('success','client file updated');
          this.visible=false;
          this.clientFileUpdate=null;
          this.action=null;
          this.getAllclientPortalData(this.headerFormVal);
        }
      )
    }
    if(this.action==='Master'){
      this.panelForm1.toggleSlider();
      const requestModel={
        "clientFileMasterId":this.popUpForm.controls.fileData.value.key,
        "clientFileId":this.clientFileUpdate.clientFileId,
        "fileName":this.clientFileUpdate.filename,
        "clientId":this.headerFormVal.client.key,
        "fromDate":this.clientFileUpdate.asOfDate,
        "clientPortalId":this.clientFileUpdate.clientPortalId
      }

      this.clientPortalService.updateClientFilMaster(requestModel)
      .subscribe(
        data=>{
          this.toast.showSuccess('success','client file updated');
          this.visible=false;
          this.clientFileUpdate=null;
          this.action=null;
         this.getAllclientPortalData(this.headerFormVal);
        }
      )
    }
       
  }


  // getColsData(isClient){
  //   // if(isClient){
  //   //   this.cols = [
  //   //   { field: "filename", header: "File Name" },
  //   //   { field: "asOfDate", header: "AsOfDate" },
  //   //   { field: "uploadMessage", header: "Upload Status" },
  //   //   { field: "userName", header: "Created By" },
  //   //   { field: "createdAt", header: "Created At" }
  //   // ];
  //   // }else{
  //     this.cols = [
  //       { field: "filename", header: "File Name" },
  //       { field: "clientApplicationFile", header: "Layout File" },
  //       { field: "clientFileMaster", header: "Master File" },
  //       { field: "asOfDate", header: "AsOfDate" },
  //       { field: "uploadMessage", header: "Upload Status" },
  //       { field: "userName", header: "Created By" },
  //       { field: "createdAt", header: "Created At" }
  //     ];
  //   //}
    
  // }

  deleteFiles(data){
    //console.log("delete data :",data);
    if(data.clientFileId===0){
      this.toast.showInfo('info','no layout linked');
      return;
    }
    this.deleteHeader="Remove Layout : "+data.filename;
    this.clientFileId=data.clientFileId;
    this.clientFileUpdate=data;
    this.deleteVisible=true;
  }

  deleteFileLayout(){
    //console.log(this.clientFileId);
    //console.log(this.popUpForm.controls.deleteMaster.value);

    const requestModel={
      "clientPortalId":this.clientFileUpdate.clientPortalId,
      "clientFileId":this.clientFileId,
      "deleteLayout":this.popUpForm.controls.deleteMaster.value===true?false:true,
      "deleteMaster":this.popUpForm.controls.deleteMaster.value
    }

    this.clientPortalService.deleteFileLayout(requestModel)
    .subscribe(
      data=>{
        this.toast.showSuccess('success','removed');
        this.doAction();
       this.getAllclientPortalData(this.headerFormVal);

      },
      err=>{
        this.doAction();
        this.toast.showError('error',err.error.message);
      }
    )

   

  }

  maintainClientState(client,fromDate,todate){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: todate,
      fromDate: fromDate,
      clientName: client.value,
      page: '',
      fileName:'',
      workflowName:'',
      workflowId:0,
      dataType:'',
      dataTypeId:0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  /*maintainStateOnDateChange(newDate,typeOfRequest){
    if(typeOfRequest==='fromDateChange'){
      this.dataTransferModel.fromDate = newDate;
      this.observalbleDataService.addToInventory(this.dataTransferModel);
    }
    if(typeOfRequest==='toDateChange'){
      this.dataTransferModel.toDate = newDate;
      this.observalbleDataService.addToInventory(this.dataTransferModel);
    }
  }*/


  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    const fromDatec=this.cookieService.get('fromDate');
    const toDatec=this.cookieService.get('toDate');
    console.log(clientIdC);
    console.log(clientNameC);
    console.log(fromDatec);
    console.log(toDatec);
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
      this.clientId=this.headerForm.controls.client.value.key;
    }
    if(fromDatec!==undefined && fromDatec!==null && toDatec!==undefined && toDatec!==null && 
      !isEmpty(fromDatec) && !isEmpty(toDatec)){
        let frm=new Date(fromDatec);
        let toDt=new Date(toDatec);
       this.headerForm.controls.fromDate.setValue(frm)
       this.headerForm.controls.toDate.setValue(toDt)
    }else{
      this.loadDate();
    }
    if(!isEmpty(this.headerForm.get("client").value.key)){
   // this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }

    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     this.fromDt=new Date(this.dataTransferModel.fromDate);
    //     this.toDt=new Date(this.dataTransferModel.toDate);
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && isNaN(this.fromDt)===false && isNaN(this.toDt)===false) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.headerForm.patchValue({
    //         fromDate: this.fromDt,
    //         toDate: this.toDt,
    //       });
    //       this.getAllclientPortalData(this.dataTransferModel.clientId,this.fromDt,this.toDt);
    //     }

    //     else if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && (isNaN(this.fromDt)===true || isNaN(this.toDt)===true)) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.loadDate();
    //       this.getAllclientPortalData(this.dataTransferModel.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    //     }

    //     else{
    //       this.loadDate();
    //     }
        
    //   });
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};


    callNgOnInitMethods(){
  
      this.getClients();
      // this.getApplicationFileType();
      // this.getClientApplicationFileTypeData();
      // this.getAllSeparators();
     // this.getPreviousState();
      this.setTitle();
    
      //this.loadDate();
     
      // this.store.select('user').pipe(take(1))
      //   .subscribe(u => this.user = u)
      //  // this.getAllclientPortalData(this.user.clientId);
      //  if(this.user.clientId!==0 && !this.user.clientId!==null){
      //   this.showClient=true;
      //   this.getColsData(this.showClient);
      //   this.clientId=this.user.clientId;
      //   this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value); 
      //   this.getClient(this.clientId);
      // }else{
      //   this.showClient=false;
      //   this.getColsData(this.showClient);
      //   this.clientIdVal=this.headerForm.controls.client.value.key;
      //   this.getAllclientPortalData(this.clientId,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value); 
      //  if(this.clientIdVal!==null && this.clientIdVal!==""){
      //   this.getClient(this.clientIdVal);
      //  }
      //  }



    }

    getClients() {
      //  this.loader.showLoader();
        this.clientService.getAllClients().subscribe(
          (data) => {
           // this.loader.hideLoader();
            this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
            console.log(this.clientsList)
          },
          (err) => {
       //     this.loader.hideLoader();
            this.toast.showError(err.error.message, "error");
          }
        );
      }

      // saveClientApplicationFile(){
      //   if(this.clientApplicationFileForm.valid){
      //   if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
      //     this.toast.showWarn('no file selected','error');
      //     return;
      //   }
      //   if(this.clientId===0){
      //     this.clientId=this.headerFormVal.client.key;
      //      }
    
      //     const ClientAppliactionFileConst = {
      //      "clientId": this.clientId,
      //      "clientApplicationFileTypeId": this.clientApplicationFileForm.controls.fileType.value.key,
      //      "dependentUpon":this.clientApplicationFileForm.controls.dependentUpon.value,
      //      "schedule":this.clientApplicationFileForm.controls.schedule.value,
      //      "fieldSeparator":this.clientApplicationFileForm.controls.fieldSeparator.value,
      //      "clientApplicationFileDataTypeId":this.clientApplicationFileForm.controls.fileDataType.value.key,
      //      "layoutFieldId":this.clientApplicationFileForm.controls.layoutFieldId.value
      //    };
      //    this.fileService.saveClientApplicationFile(this.currentFileUpload,ClientAppliactionFileConst) .subscribe(
      //     data=>{
      //       this.toast.showSuccess('Success','File Uploaded Successfully');
      //       this.currentFileUpload=null;
      //      // this.myInputVariable.nativeElement.value = "";
      //       this.visibleLayoutPopUp=false;
      //       this.clientApplicationFileForm.reset();
      //       this.loader.hideLoader();
    
           
      //     },
      //     error=>{
      //       this.toast.showError(error.error.message, "error")
      //       this.loader.hideLoader();
      //     },
         
      //   );
      //  }else{
      //   this.toast.showError('Please Fill the Mandatory Fields','Error')
      //  }
      // }
    
      // selectClientApplicationFile(event){
      //   this.currentFileUpload = event.target.files;
      // } 

      // saveClientFile(){
      //   if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
      //     this.toast.showWarn('no file selected','error');
      //     return;
      //   }
      //   //if(this.modalForm.controls.asOfDate.value===null || this.modalForm.controls.file.value===null){
      //   if(this.headerForm.controls.asOfDate.value===null){
      //    this.toast.showWarn('ApplicationFile and AsOfDate are mandatory','error');
      //    return;
      //   }
      //   if(this.headerFormVal.client.key!=0){
      //     this.headerForm.patchValue({
      //       client: this.headerFormVal.client,
      //     });
          
      //   }
      //   // this.loader.showLoader();
      //    this.showLoading=true;
      //    //this.visible=false;
      // if(this.isZipUpload===false){
      //    this.clientPortalService.saveClientFile(this.currentFileUpload,this.headerForm)
      //    .subscribe(
      //      data=>{
      //        this.toast.showSuccess('Success','File Uploaded Successfully');
      //        //this.clientId=null;
      //        this.currentFileUpload=null;
      //       // this.myInputVariable.nativeElement.value = "";
      //        this.visible=false;
      //        //this.headerForm.reset();
      //       // this.headerForm.controls.asofDate.setValue(null);
      //      //  this.loader.hideLoader();
      //        this.showLoading=false;
      //        this.showClientFilePopUp=false;
      //        this.getAllclientPortalData(this.headerFormVal);
            
      //      },
      //      error=>{
      //        this.toast.showError(error.error.message, "error");
      //     //   this.loader.hideLoader();
      //        this.visible=false;
      //        this.showLoading=false;
      //      },
          
      //    );
      //     }
      //     if(this.isZipUpload===true){
      //       this.clientPortalService.saveZipClientFile(this.currentFileUpload,this.headerForm)
      //    .subscribe(
      //      data=>{
      //        this.toast.showSuccess('Success','File Uploaded Successfully');
      //        //this.clientId=null;
      //        this.currentFileUpload=null;
      //       // this.myInputVariable2.nativeElement.value = "";
      //        this.visible=false;
      //        //this.headerForm.reset();
      //        //this.headerForm.controls.asofDate.setValue(null);
      //       // this.loader.hideLoader();
      //        this.showLoading=false;
      //        this.isZipUpload=false;
      //        this.showClientFilePopUp=false;
      //        this.getAllclientPortalData(this.headerFormVal);
      //      },
      //      error=>{
      //        this.toast.showError(error.error.message, "error");
      //        this.loader.hideLoader();
      //        this.visible=false;
      //        this.showLoading=false;
      //      },
          
      //    );
      //     }
         
      //  }


      //  selectFile(event) {
      //   //this.currentFileUploadList=[];
      //  // this.fileList=[];
      // //  console.log("event target ",event.target.files[0])
      // //  const file=event.target.files[0];
      // //  console.log("zipupload ",this.headerForm.controls.zipUpload.value);
      // //  //if(this.headerForm.controls.zipUpload.value)
      // this.currentFileUpload=null;
      // //  console.log(file.type)
      //   this.currentFileUploadList = event.target.files;
      //  // this.currentFileUpload.push(this.currentFileUploadList);
      //   for(let i=0;i<this.currentFileUploadList.length;i++){
      //     this.fileList.push(this.currentFileUploadList[i]);
      //     }
      //   this.currentFileUpload=this.fileList;
      // // this.currentFileUpload=event.target.files;
      // console.log(" file 1 :",this.currentFileUpload);
      //   // for(let i=0;i<this.currentFileUploadList.length;i++){
      //   //   this.currentFileUpload=this.currentFileUploadList[i];
      //   //   }
    
      // }
      // selectFile2(event) {
      //   //this.currentFileUploadList=[];
      //  // this.fileList=[];
      // //  console.log("event target ",event.target.files[0])
      //   const file=event.target.files[0];
      //   console.log("zipupload ",file.type);
      //   this.currentFileUpload=null;
      //   if(file.type!=='application/x-zip-compressed'){
      //     this.toast.showError('error','select zip file');
      //     this.myInputVariable2.nativeElement.value="";
      //     event.target=null;
      //     return;
      //   }
      // //  //if(this.headerForm.controls.zipUpload.value)
      
       
      //   this.currentFileUploadList = event.target.files;
      //  // this.currentFileUpload.push(this.currentFileUploadList);
      //   for(let i=0;i<this.currentFileUploadList.length;i++){
      //     this.fileList.push(this.currentFileUploadList[i]);
      //     }
      //  // this.currentFileUpload=this.fileList;
      //  this.currentFileUpload=event.target.files;
      //   console.log(" file 2 :",this.currentFileUpload);
      //   // for(let i=0;i<this.currentFileUploadList.length;i++){
      //   //   this.currentFileUpload=this.currentFileUploadList[i];
      //   //   }
    
      // }
    
    
      // showPopup(){
      //   // this.popUpHeader="Add Client File";
      //    this.showClientFilePopUp=true; 
      //     this.showClientAppFilePopUp=false;
      //     this.visible=true;
      //     this.isZipUpload=false;
      //     // if(this.headerForm.controls.zipUpload && this.headerForm.controls.zipUpload.value===true){
      //     //   this.isZipUpload=true;
      //     // }else{
      //     //   this.isZipUpload=false;
      //     // }
        
      //   }
      
      //   showZipPopup(){
      //     // this.popUpHeader="Add Client File";
      //      this.showClientFilePopUp=true; 
      //       this.showClientAppFilePopUp=false;
      //       this.visible=true;
      //       this.isZipUpload=true;
      //       // if(this.headerForm.controls.zipUpload && this.headerForm.controls.zipUpload.value===true){
      //       //   this.isZipUpload=true;
      //       // }else{
      //       //   this.isZipUpload=false;
      //       // }
          
      //     }
      
      //   doActionData(type){
      //     if (type === "yes") {
      //     //  console.log("helloo");
      //       this.visible = false;
      //       this.currentFileUpload=null;
      //       console.log("myinput var ",this.myInputVariable)
      //       if(this.myInputVariable!==undefined){
      //       this.myInputVariable.nativeElement.value = "";
      //       }
      //       if(this.myInputVariable2!==undefined){
      //         this.myInputVariable2.nativeElement.value = "";
      //         }
            
      //       this.visible=false;
      //    // this.headerForm.reset();
      //       this.fileList=[];
      //       this.currentFileUpload=null;
      //       this.currentFileUploadList=null;
      //     }else{
      //       this.visible = true;
      //     }
      //   }

      //   removeFile(index){
      //     console.log(index);
      //     this.fileList=[];
      //     if(index===0 && this.currentFileUpload.length===1){
      //       event=null;
      //       return this.currentFileUpload=null;
      //     }
      //     for(let i=0;i<this.currentFileUpload.length;i++){
      //       this.fileList.push(this.currentFileUpload[i]);
      //       }
      //     console.log("before list "+this.fileList)
      //    // this.fileList = this.fileList.filter(obj => obj !== this.fileList[index]);
      //    this.fileList.splice(index,1);
      //    console.log("after list "+this.fileList)
      //    this.currentFileUpload=this.fileList;
        
      //   }

      //   showPopupAddFile(data) {
      //     this.showClientAppFilePopUp=true;
      //     this.showClientFilePopUp=false; 
      //     this.popUpName="Upload Layout File";
      //     this.visibleLayoutPopUp = true;
         
      //     if(this.headerForm.controls.client.value>0){
      //       this.clientId=this.headerForm.controls.client.value;
      //        }else{
      //         this.clientId=this.headerForm.controls.client.value.key;
      //        }
      //     this.getLayoutField(this.clientId);
      //     this.getDependentUponData(this.clientId);   
      //   }
      
      //   doAddFileAction(type){
      //     if (type === "yes") {
      //       console.log("helloo");
      //       this.visibleLayoutPopUp = false;
      //     //  this.modalForm.reset();
      //     this.clientApplicationFileForm.reset();
      //     this.currentFileUpload=null;
      //     this.myInputVariable.nativeElement.value = "";
      //     }else{
      //       this.clientApplicationFileForm.reset();
      //       this.visibleLayoutPopUp = false;
      //     }
      //   }
        
      //   onDateChange(e){
      //     // console.log(e);
      //     // const asOfDate1=this.datePipe.transform(e,'yyyy-MM-dd');
      //     // console.log(asOfDate1);
      //     this.headerForm.controls.asOfDate.setValue(e);
      //   }
      
      //   getAllSeparators(){
      //     this.fieldSeparatorList= [
      //      { key: ',', value: "Comma" },
      //      { key: 'space', value: "Space" },
      //      { key: '|', value: "Pipe(|)" },
      //      { key: '"', value: "Quotes" },
      //      { key: ';', value: "Semicolon" },
      //      { key: ':', value: "Colon"},
      //      { key: '\t', value: "Tab" }
      //    ];
      //  // this.clientApplicationFileForm.get('fieldSeparator').setValue(fieldSeparatorList);
      //   }
      
      //   getLayoutField(clientId){
      //     this.fileService.getAppFilesByClientId(clientId).subscribe(
      //       (data) => {
      //         const layoutFieldFile=data;
      //         for(var a=0;a<layoutFieldFile.length;a++){
      //         // console.log("type "+layoutFieldFile[a].fileName);
      //           this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
      //         }
      //       },
      //       (err) => {
      //       //  this.toast.showSuccess("Status Not Changed", "error");
      //       }
          
      //     );
        
      //   }
        
      //   getDependentUponData(clientId){
      //     this.fileService.getDependentUpon(clientId).subscribe(
      //       (data) => {
      //         const dependentUpon=data;
      //         for(var a=0;a<dependentUpon.length;a++){
      //           console.log("type "+dependentUpon[a].fileName);
      //           this.dependentUponList.push({ key: dependentUpon[a].clientApplicationFileId, value: dependentUpon[a].fileName });
      //         }
      //       },
      //       (err) => {
      //       //  this.toast.showSuccess("Status Not Changed", "error");
      //       }
          
      //     );
        
      //   }
              
      //   getClientApplicationFileTypeData(){
      //     this.fileService.getClientApplicationFileType().subscribe(
      //       (data) => {
      //         const clientApplicationFileType=data;
      //         for(var a=0;a<clientApplicationFileType.length;a++){
      //          // console.log("type "+clientApplicationFileType[a].type);
      //           this.fileTypeList.push({ key: clientApplicationFileType[a].clientApplicationFileTypeId, value: clientApplicationFileType[a].type });
      //         }
      //       },
      //       (err) => {
      //       //  this.toast.showSuccess("Status Not Changed", "error");
      //       this.toast.showError(err.error.message, "error");
      //       }
          
      //     );
        
      //   }

      //   getApplicationFileType(){
      //     this.fileService.getApplicationDataTypeByUser().subscribe(
      //    (data) => {
      //      const fileDataType=data;
      //      for(var a=0;a<fileDataType.length;a++){
      //      // console.log("type "+fileDataType[a].type);
      //        this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
      //        }
      //    },
      //    (err) => {
      //    //  this.toast.showSuccess("Status Not Changed", "error");
      //    this.toast.showError(err.error.message, "error");
      //    }
       
      //  );
          
      //  }
    

}
