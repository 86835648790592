import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { currentMonthRange, getCurrentDate} from "src/app/utils/dates";
import { AuditClientFileModel, AuditWorkflow, LayoutFileMappingModel } from '../../models/audit.model';
import { AuditHomeService } from '../../services/audit-home.service';
import { formatDate } from "src/app/utils/dates";
import { WorkflowJobDetails } from '../../models/workflow.model';
import { WorkflowService } from '../../services/workflow.service';
import { FileLogModel } from '../../models/filelog.model';
import { JobsLogModel } from '../../models/tranform.model';
import { FileService } from 'src/app/files/services/file.service';
import { RulesModel, RuleTypesModel } from '../../models/rules.model';
import { RulesService } from 'src/app/ingestion-rules/services/rules.service';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from "src/app/core/services/toast.service";
import { Store } from '@ngrx/store';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { ClientService } from '../../services/client.service';
import { DatePipe } from '@angular/common';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-audit-home',
  templateUrl: './audit-home.component.html',
  styleUrls: ['./audit-home.component.scss']
})
export class AuditHomeComponent implements OnInit {
  @ViewChild("panelQueueFilter", {static: false })
  panelQueueFilter!:SidePanelComponent;

  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild("panelMappingForm", {static: false })
  panelMappingForm!:SidePanelComponent;

  @ViewChild("panelIngestionForm", {static: false })
  panelIngestionForm!:SidePanelComponent;

  @ViewChild("panelTransformForm", {static: false })
  panelTransformForm!:SidePanelComponent;

  activeRoute$: Subscription;
  headerForm: FormGroup;

  auditClientFileModel:AuditClientFileModel[]=[]; 
  auidtWorkflowModel:AuditWorkflow[]=[];
  auidtTrasformWorkflowModel:AuditWorkflow[]=[];
  workflowIngestJoblogData:FileLogModel[]=[];
  workflowTransformJoblogData: JobsLogModel[] = [];
  predefinedRules: RulesModel[] = [];
  allRules: RuleTypesModel[] = [];
  layoutFileMappingModel:LayoutFileMappingModel[]=[];

  dataTransferModel: DataTransferModel;
  ingestVisible:boolean=false;
  transformVisible:boolean=false;
  rulesVisible:boolean=false;
  mappingVisible:boolean=false;

  clientsList : {key: any; value: any }[]=[];
  companyId:any;
  companyName:string;

  fromDt:any;
  toDt:any;
  rolePermission:any;

  ingestHeader:string="Ingest Log";
  transformHeader:string="Transform Log";
  layoutHeader:string="Layout";
  state:string;
  adminFlag:String;
  constructor( private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private auditHomeService:AuditHomeService,
    private workflowService: WorkflowService,
    private fileService: FileService,
    public observalbleDataService: ObservalbleDataService,
    private ruleService: RulesService,
    private cookieService: CookieService, private clientService:ClientService, private datePipe:DatePipe,
    private toast:ToastService,private storePermission: Store<{ role: any }>) { 
    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      asOfDate: new FormControl(null, [Validators.required])
    });

    this.state=localStorage.getItem("stateflag");
  }

  ngOnInit(): void {
    this.buttonShowHidePermission();
  
     
       this.fileService.getClientByUserId().subscribe(
        (data) =>{
        
          for(var i=0;i<data.length;i++){
            console.log("data  ",data[i].clientId)
            this.companyId=data[i].clientId
            this.companyName=data[i].clientName
          }
         console.log("this.companyId",this.companyId);
         console.log("this.companyName",this.companyName);
          this.headerForm.get("client").setValue({
            key: this.companyId,
            value: this.companyName
          });
         console.log("header form ngOnInit",this.headerForm);
        },
        (err)=>{
          this.toast.showError(err.error.message, "error");
        }
        );
        this.adminFlag=this.cookieService.get("adminFlag");
        
      }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  onClientChange(event){
    //console.log(event.key);
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
     this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    this.getClientFileByAsOfDate();
    this.getWorkflowJobsByClient();
    this.getWorkflowTransformJobsByClient();
    //this.maintainClientState(event);
    this.panelQueueFilter.toggleSlider();
  
  }

  changeAsOfDate(event){
    this.getClientFileByAsOfDate();
    this.getWorkflowJobsByClient();
    this.getWorkflowTransformJobsByClient();
  }

  getClientFileByAsOfDate(){
    
    const auditRequestModel={
      "clientId":this.headerForm.controls.client.value.key,
      "asOfDate":formatDate(this.headerForm.controls.asOfDate.value)
    }
    console.log("HeaderForm",this.headerForm);
    console.log("auditHomeComponent",auditRequestModel);

    this.auditHomeService.getClientFileByAsOfDate(auditRequestModel)
    .subscribe(
      (data)=>{
         this.auditClientFileModel=data;
         console.log(this.auditClientFileModel);
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
    }

  getWorkflowJobsByClient(){
    
    const auditRequestModel={
      "clientId":this.headerForm.controls.client.value.key,
      "asOfDate":formatDate(this.headerForm.controls.asOfDate.value)
    }

    this.auditHomeService.getJobAuditLogs(auditRequestModel)
    .subscribe(
      (data)=>{
         this.auidtWorkflowModel=data;
         console.log(this.auidtWorkflowModel) 
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
    }
    
  getWorkflowTransformJobsByClient(){
    
    const auditRequestModel={
      "clientId":this.headerForm.controls.client.value.key,
      "asOfDate":formatDate(this.headerForm.controls.asOfDate.value)
    }

    this.auditHomeService.getTransformJobAuditLogs(auditRequestModel)
    .subscribe(
      (data)=>{
         this.auidtTrasformWorkflowModel=data;
         console.log(this.auidtWorkflowModel)   
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
    }

  closePopup(){
    this.ingestVisible=false;
    this.transformVisible=false;
    this.rulesVisible=false;
    this.mappingVisible=false;
  }

  viewJobLogs(data){
   // this.ingestVisible=true;
   console.log("vie log data ",data);
   if(data.type==='Ingest'){
    // this.panelIngestionForm.toggleSlider();
     this.transformHeader="Transform";
     this.ingestHeader=data.jobName;
    this.getWorkflowIngestJobLog(data.workflowJobLogId);
   }
   
   if(data.type==='Transform'){
    // this.panelTransformForm.toggleSlider();
    this.ingestHeader="Ingest Log";
    this.transformHeader=data.jobName;
   
    this.getTransformLogs(data.workflowJobLogId);
   }
 
  }

  getWorkflowIngestJobLog(data){
    
   const requestModel = {
    "workflowjoblogId":data,
    // "workflowjoblogId":1,
    "fromDate":null,
     "toDate":null,
     
  }
    // keep this hardcoded//uncomment it
  this.workflowService.getIngestRuleLogsByWorkflowJobLogId2(requestModel).subscribe(
    (data)=>{
      this.workflowIngestJoblogData=data;
      this.ingestVisible=true;
      console.log("in main workflowIngestJoblogData",this.workflowIngestJoblogData);
    },
      (err) => {
      this.toast.showError(err.error.message, "error");
    }
  );
  }

  getTransformLogs(id) {
    this.workflowTransformJoblogData = [];
    this.ingestVisible=false;
    //this.loader.showLoader();
    const requestModel={
      "workflowjoblogId":id
    }
    this.fileService.getTransformLogByJobLogId(requestModel).subscribe(
      (data) => {
        this.workflowTransformJoblogData = data;
        this.transformVisible=true;
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
    }

  getRuleName(id) {
    return this.allRules.find(d => d.ingestruletype_id === id).displayName;
  // return 'ruletype';
  }

  viewRules(data){
    this.panelForm.toggleSlider();
    this.getAllRuleTypes();
   this.layoutHeader= data.layoutFileName;
     this.getLayoutFileRules(data);
  }

  getLayoutFileRules(data){
    const reuestData={
      'layoutFileId':data.layoutFileId
    }

    this.auditHomeService.getLayoutFileRules(reuestData)
    .subscribe(
      (data)=>{
        this.predefinedRules=data;
        this.rulesVisible=true;
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
    }

  getAllRuleTypes(){
    this.ruleService.getAllRulesType()
    .subscribe(
      (data)=>{
        this.allRules=data;
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
  }

  viewMapping(data){
    this.panelMappingForm.toggleSlider();
    this.getLayoutFileMappings(data);

  }

  getLayoutFileMappings(data){
    const reuestData={
      'layoutFileId':data.layoutFileId
    }

    this.auditHomeService.getLayoutFileMapping(reuestData)
    .subscribe(
      (data)=>{
        this.layoutFileMappingModel=data;
        this.mappingVisible=true;
        console.log('layout file Mapping data ',this.layoutFileMappingModel);
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
    }

  getPreviousState() {
  
    this.loadDate();
    // const clientIdC=this.cookieService.get('clientId');
    // const clientNameC=this.cookieService.get('clientName');

    const clientIdC=this.headerForm.controls.client.value.key;
    const clientNameC=this.headerForm.controls.client.value.value;
    
    console.log("const clientIdC:",clientIdC);
    console.log("const clientNameC:",clientNameC);
   
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
           this.onClientChange(null);     
                     
    }
  }
  
  loadDate(){
    // let currentDateRange=currentMonthRange();
    // this.headerForm.controls.asOfDate.setValue(currentDateRange[0]);
    let today=new Date();
    console.log("today",today);
    this.headerForm.controls.asOfDate.setValue(this.datePipe.transform(today,"yyyy-MM-dd"));
  }

  maintainClientState(client){    
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel);
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getPreviousState();
      this.setTitle();
      this.getClients();
      //this.loadDate();
      this.panelQueueFilter.toggleSlider();
    }

    getClients() {

      //  this.loader.showLoader();
  
        this.clientService.getAllClients().subscribe(
  
          (data) => {
  
           // this.loader.hideLoader();
  
            this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
  
            console.log(this.clientsList)
  
          },
  
          (err) => {
  
       //     this.loader.hideLoader();
  
            this.toast.showError(err.error.message, "error");
  
          }
  
        );
  
  }
    
}
