import { Component, EventEmitter, Input, OnInit, Output, ViewChild,SimpleChanges } from '@angular/core';
import { FormControl, FormGroup,Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { InterestRateRiskScenarioService } from 'src/app/dashboard/services/interestRateRiskScenario.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { InterestRateRiskScenarioSelectorComponent } from '../interestRateRiskScenario-selector/interestRateRiskScenario-selector.component';
//import { InterestRateRiskScenarioSelectorComponent } from '../interestRateRiskScenario-selector/interestRateRiskScenario-selector.component';

@Component({
  selector: 'app-interestRateRiskScenario-header',
  templateUrl: './interestRateRiskScenario-header.component.html',
  styleUrls: ['./interestRateRiskScenario-header.component.scss']
})
export class InterestRateRiskScenarioHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild("panelForm2", {static: false })
panelForm2!:SidePanelComponent;

  @ViewChild(InterestRateRiskScenarioSelectorComponent, { read: false, static: false })
  InterestRateRiskScenarioSelectorComponent: InterestRateRiskScenarioSelectorComponent;

  
  @Input() editData:any[];
  @Output() save=new EventEmitter();
  @Input() interestRateRiskScenarioList:any[];
  interestRateRiskScenarioValuesForm: FormGroup = null;
  @Input() editSetValueData:any[];

  constructor(private interestRateRiskScenarioService:InterestRateRiskScenarioService,private toast:ToastService) { 

  this.interestRateRiskScenarioValuesForm=new FormGroup({
    irr_scenario_id:new FormControl(null),
    scenario: new FormControl(null),
    interestRateRiskType: new FormControl(null),
    statusMap: new FormControl(null,{validators:[Validators.required]}),
    bucket1: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
    bucket2: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
    bucket3: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
    bucket4: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
    bucket5: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
    bucket6: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
    bucket7: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
    bucket8: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
    bucket9: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
    bucket10: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] })
  });
}
  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
  this.interestRateRiskScenarioValuesForm.get('statusMap').setValue(defaultMap);
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editSetValueData && changes.editSetValueData.currentValue) {
     let rowData=changes.editSetValueData.currentValue;
     console.log("rowData",rowData);
  
      this.interestRateRiskScenarioValuesForm.patchValue(
        {
          irr_scenario_id:rowData.irr_scenario_id,
          scenario:rowData.scenario,
          interestRateRiskType:rowData.type,
         // status:rowData.status,
          bucket1:rowData.bucket1,
          bucket2:rowData.bucket2,
          bucket3:rowData.bucket3,
          bucket4:rowData.bucket4,
          bucket5:rowData.bucket5,
          bucket6:rowData.bucket6,
          bucket7:rowData.bucket7,
          bucket8:rowData.bucket8,
          bucket9:rowData.bucket9,
          bucket10:rowData.bucket10
          

        });

        const map1 = {
          key: "Active",
          value: "Active",
        };
      
        const map2 = {
          key: "Inactive",
          value: "Inactive",
        };
      console.log("Status called",this.interestRateRiskScenarioValuesForm)
        rowData.status==true? this.interestRateRiskScenarioValuesForm.controls.statusMap.setValue(map1):this.interestRateRiskScenarioValuesForm.controls.statusMap.setValue(map2);
        
    }
  
  }


  saveInterestRateRiskScenario(data){
    console.log("data",data);
this.save.emit(data);

  }

 

  addNewInterestRateRiskScenario(){

     this.InterestRateRiskScenarioSelectorComponent.formReset();
     this.panelForm.toggleSlider();
    }

    reset(){

     this.InterestRateRiskScenarioSelectorComponent.formReset();
     //this.panelForm.toggleSlider();
    }

 openEdit(){
  this.panelForm.toggleSlider();
 }

 saveInterestRateRiskScenarioValues(){
  let request = this.interestRateRiskScenarioValuesForm.value;
  console.log("IRR scenario form:",this.interestRateRiskScenarioValuesForm)
if(this.interestRateRiskScenarioValuesForm.status==="VALID"){
  this.save.emit(this.interestRateRiskScenarioValuesForm.value);
}  else{
  this.toast.showError("Please Fill Required Fields","error");
}
  this.panelForm2.toggleSlider();
}

getAllInterestRateRiskScenarioList(){

this.interestRateRiskScenarioService.getAllInterestRateRiskScenarioList().subscribe((data)=>{
this.interestRateRiskScenarioList=data;
console.log("interestRateRiskScenarioList", this.interestRateRiskScenarioList);

},
(err) => {
this.toast.showError(err.error.message, "error");

}
);
}
openSetValues(){
  console.log("called");
  this.panelForm2.toggleSlider();
}

get status() {
  const status = [
    "Active",
    "Inactive",
  ];
 return status.map((t) => ({ key: t, value: t }));
}

}
