import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
//import { EventEmitter } from 'protractor';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { CategorySelectorComponent } from '../category-selector/category-selector.component';
//import { DepartmentSelectorComponent } from '../department-selector/department-selector.component';

@Component({
  selector: 'app-category-header',
  templateUrl: './category-header.component.html',
  styleUrls: ['./category-header.component.scss']
})
export class CategoryHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(CategorySelectorComponent, { read: false, static: false })
  CategorySelectorComponent: CategorySelectorComponent;

  
  @Input() editData;
  @Output() save=new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }


  saveCategory(data){
this.save.emit(data);

  }

 

   addNewCategory(){

     this.CategorySelectorComponent.formReset();
     this.panelForm.toggleSlider();

    }

    reset(){

     this.CategorySelectorComponent.formReset();
     //this.panelForm.toggleSlider();
    }

 openEdit(){
  this.panelForm.toggleSlider();
 }

}
