import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { SparkLauncherSelectorComponent } from 'src/app/dbconnenctionComp/spark-launcher-selector/spark-launcher-selector.component';
import { DbconnectionService } from '../../services/dbconnection.service';

@Component({
  selector: 'app-spark-launcher',
  templateUrl: './spark-launcher.component.html',
  styleUrls: ['./spark-launcher.component.scss']
})
export class SparkLauncherComponent implements OnInit {

  @ViewChild(SparkLauncherSelectorComponent, { read: false, static: false })
  sparkLauncherSelector: SparkLauncherSelectorComponent;
  constructor(private dbconnectionService:DbconnectionService,
    private toastService:ToastService) { }

  ngOnInit(): void {
    this.loadData();
  }

  callSparkLauncher(data){
    console.log("here is the data ",data);
    this.dbconnectionService.launchSpark(data)
    .subscribe(
      (data)=>{
        console.log("after called ");
        this.toastService.showInfo("info",data.status);
        this.sparkLauncherSelector.sparkSelectorForm.reset();
      },
      (error)=>{
        console.log("error ");
        this.toastService.showError("error","Error launching spark");
      }
    )
  }


  loadData(){
    console.log("get db connn");
    this.dbconnectionService.loadData("string")
    .subscribe(
      (data)=>{
        console.log("data");
      }
    )
  }

}
