import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
  })
export class FinancialAccounitingService {
  
  
  /**
   * @param  {HttpShockMaster} privatehttp
   */
  constructor(private http: HttpClient) {}
 
 
getDataTypeList(){
  return this.http.get<any[]>(environment.baseUrl + "/apis/getDataTypes");
}

getLiabilitiesStatements(clientId,date){
  return this.http.get<any[]>(environment.baseUrl + "/apis/getLiabilitiesStatements/"+clientId+"/"+date);
}

getAssetsStatements(clientId,date){
  return this.http.get<any[]>(environment.baseUrl + "/apis/getAssetsStatements/"+clientId+"/"+date);
}

saveAssetsStatements(data){
  return this.http.post<any[]>(environment.baseUrl + "/apis/saveAssetsStatements",data);
}

saveLitiesStatements(data){
  return this.http.post<any[]>(environment.baseUrl + "/apis/saveLitiesStatements",data);
}
    
}

