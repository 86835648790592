import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { DataTransferModel } from 'src/app/dashboard/models/dataTransfer.model';
import { FieldLayoutModel, FileLayoutModel } from 'src/app/dashboard/models/file-layout.model';
import { ClientService } from 'src/app/dashboard/services/client.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-file-layout-parameter',
  templateUrl: './file-layout-parameter.component.html',
  styleUrls: ['./file-layout-parameter.component.scss'],
})
export class FileLayoutParameterComponent {
  @Output() loadFields=new EventEmitter();
  @Output() loadSavedFields=new EventEmitter();
  @Output() closePanel=new EventEmitter();
  headerForm: FormGroup = null;
  activeRoute$: Subscription;
  clientId: number = null;
  sheets: { key: string | number; value: string }[] = [];
  fileLayout:any;
  //fileLayout: { key: any; value: any }[] = [];
  clientsList: { key: any; value: any }[] = [];
  layoutFields: FieldLayoutModel[];
  findSheet:FileLayoutModel;
  headerRows = [];
  fileLayoutList:FileLayoutModel[];
  editFieldValue: FieldLayoutModel = null;
  showEditButton:boolean=false
  endRow:string="End row"
  startRow:string="Start row"
  dataTransferModel:DataTransferModel;
  fromDt: any;
  toDt: any;
  showBackButton:boolean=false;
  rolePermission:any;
  fileLayoutData:{ key: any; value: any }[] = [];
  filesList:{ key: any; value: any }[] = [];
  companyId:any;
  companyName:String;
  clientApplicationFileId:any;
  fileName:String;
  adminFlag:String;
  constructor(private reportDashboardService:ReportDashboardService,
    private sspApplicationService: SspApplicationService,private cookieService:CookieService,
    private fileService:FileService,private toast:ToastService, private clientService:ClientService) {
      this.headerForm = new FormGroup({
        client: new FormControl(null, [Validators.required]),
        file: new FormControl(null, [Validators.required]),
        header: new FormControl(null, [Validators.required]),
        headerRow: new FormControl(null, [Validators.required]),
        startRowNo:new FormControl(null, [Validators.required]),
        sheet: new FormControl(null),
      });
      this.createHeaderRows(11);

  }

  ngOnInit(): void {
  //  this.getAllFiles();
  this.adminFlag=this.cookieService.get("adminFlag");
    this.getClients();

    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
           for(var i=0;i<data.length;i++){
              console.log("data  ",data[i].clientId)
              this.companyId=data[i].clientId
              this.companyName=data[i].clientName
               }
           this.headerForm.get("client").setValue({
              key: this.companyId,
              value: this.companyName
              });
  
    
      // console.log("this.headerForm",this.headerForm.value)
       this.fileService.getFilesByClientId(this.headerForm.controls.client.value.key).subscribe(
            (data) => {
                     this.filesList = reMapForAutoComplete(
                     data,
                    "clientApplicationFileId",
                    "fileName"
                               );
                   //  this.showType=true;
                  //   this.loader.hideLoader();
                     for(var i=0;i<this.filesList.length;i++){
                        console.log("this.fileList  ",this.filesList[i])
                       this.clientApplicationFileId=this.filesList[i].key
                       this.fileName=this.filesList[i].value
                       }
  
             this.headerForm.get("file").setValue({
                  key:this.clientApplicationFileId,
                  value:this.fileName
                  })

         console.log("this.headerForm.file.value  ",this.headerForm.value)
        // this.getFilesData('onInit');
        //this.getSheetHeaders('onInit');
        this.getFileLayout(this.headerForm.controls.file.value.key);
        },
        (err) => {
          //this.showType=false;
          this.toast.showError(err.error.message, "error");
          //this.loader.hideLoader()
        }
      );
      
      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
  
      );
  }

  createHeaderRows(count) {
    for (let i = 0; i < count; i++) {
      this.headerRows.push({ key: i, value: i });
    }
  }



  getAllFiles() {
    if(this.headerForm.controls.client.value===null){
      return this.toast.showWarn("select Client first","warn");
    }
    if(this.headerForm.controls.client.value.key!=undefined || this.headerForm.controls.client.value.key!=null)
    this.fileService.getFilesByClientId(this.headerForm.controls.client.value.key).subscribe(
      (data) => {
        this.filesList = reMapForAutoComplete(
          data,
          "clientApplicationFileId",
          "fileName"
        );
     //   this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
     // this.loader.hideLoader()
      }
    );
  }

  // getFieldLayout(id) {
  // //  this.loader.showLoader();
  //   this.fileService.getFileLayoutById(id).subscribe(
  //     (data) => {
  //       this.layoutFields = data;
  //       this.fieldListing.emit(this.layoutFields);
  //       console.log("before" + data);
  //       console.log(this.layoutFields);
  //    //   this.loader.hideLoader();
  //     },
  //     (err) => {
  //       this.toast.showError(err.error.message, "error");
  //     //  this.loader.hideLoader();
  //     }
  //   );
  // }

  getSheets(client, id) {
    this.sheets = [];
  //  this.loader.showLoader();
    this.fileService.getFileSheets(client, id).subscribe(
      (data) => {
        console.log(data);
        this.sheets = reMapForAutoComplete(data, "sheetName", "sheetName");
        // if(this.sheets.length>0){
        //  while(this.sheets.length>1){
        //    this.sheets.pop();
        //  }
        //   this.headerForm.get("sheet").patchValue({
        //     key: this.sheets[0].key,
        //     value: this.sheets[0].value,
        //     });
        // }
    //    this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
  //      this.loader.hideLoader();
      }
    );
  }

  getSheetHeaders(flag) {

    console.log("headerForm  ",this.headerForm.value)
    if(this.headerForm.controls.client.value.key!=undefined || this.headerForm.controls.client.value.key!=null)
    if (this.headerForm.valid) {
     // this.loader.showLoader();
      const { client, file, header, headerRow, startRowNo,sheet } = this.headerForm.value;
      const dataVal={
        "form":this.headerForm.value
      }
      this.loadFields.emit(dataVal);
    }
    if(flag=='onLoad'){
    this.closePanel.emit();
    }
    }
  //     this.fileService
  //       .getSheetHeaders({
  //         clientApplicationFileId: file.key,
  //         clientId: client,
  //         headerRows: +header,
  //         noOfHeaders: headerRow.key,
  //         startRowNo:startRowNo.key,
  //         sheetName: sheet.key,
  //       })
  //       .subscribe(
  //         (data) => {
  //           this.layoutFields = data;
  //         //  this.loader.hideLoader();
  //         },
  //         (err) => {
  //           this.toast.showError(err.error.message, "error");
  //        //   this.loader.hideLoader();
  //         }
  //       );
  //   } else {
  //     this.toast.showError("Form Error", "Please select all the fields");
  //   }
  // }

  getFileLayout(id) {
    // if(this.dataTransferModel.isRedirected===0){
    //   this.fieldLayoutComp.fieldLayoutForm.reset();
    // }
   
    this.showEditButton=false;
    const { client,file } = this.headerForm.value;
    this.headerForm.patchValue({
      header: 0,
      headerRow: null,
      startRowNo:null,
      sheet:null,
    });
    
    this.sheets = this.layoutFields = [];
    this.fileLayout = null;
    //this.loader.showLoader();
    this.fileService.getFileLayout(client.key, file.key).subscribe(
      (data) => {
        if (data === null) {
          this.getSheets(client.key, file.key);
          this.toast.showInfo("No Layout defined", "Select a sheet");
        } else {
          this.fileLayout = data;
          this.showEditButton=true
          this.headerForm.patchValue({
            header: this.fileLayout.hasHeaders,
            headerRow: {
              key: this.fileLayout.noOfHeaders,
              value: this.fileLayout.noOfHeaders,
            },
            startRowNo: {
              key: this.fileLayout.startRowNo,
              value: this.fileLayout.startRowNo,
            },
          });
          //this.getFieldLayout(this.fileLayout.clientFileLayoutId);
          const dataVal={
            "id":this.fileLayout.clientFileLayoutId,
            "form":this.headerForm.value,
            "fileLayout":this.fileLayout
          }
          console.log("dataVal......",dataVal);
          this.loadSavedFields.emit(dataVal);
        }
       // this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
     //   this.loader.hideLoader();
      }
    );
  }     
     


  getClients() {
  //  this.loader.showLoader();
    this.clientService.getAllClients().subscribe(
      (data) => {
       // this.loader.hideLoader();
        this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
      },
      (err) => {
   //     this.loader.hideLoader();
        this.toast.showError(err.error.message, "error");
      }
    );
  }
}
