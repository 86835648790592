import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
//import { EventEmitter } from 'protractor';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { VideoCategorySelectorComponent } from '../videocategory-selector/videocategory-selector.component';
//import { DepartmentSelectorComponent } from '../department-selector/department-selector.component';

@Component({
  selector: 'app-videocategory-header',
  templateUrl: './videocategory-header.component.html',
  styleUrls: ['./videocategory-header.component.scss']
})
export class VideoCategoryHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(VideoCategorySelectorComponent, { read: false, static: false })
  videoCategorySelectorComponent: VideoCategorySelectorComponent;

  
  @Input() editData;
  @Output() save=new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }


  saveCategory(data){
this.save.emit(data);

  }

 

   addNewCategory(){

     this.videoCategorySelectorComponent.formReset();
     this.panelForm.toggleSlider();

    }

    reset(){

     this.videoCategorySelectorComponent.formReset();
     //this.panelForm.toggleSlider();
    }

 openEdit(){
  this.panelForm.toggleSlider();
 }

}
