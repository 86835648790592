import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { LookupService } from '../../services/lookup.service';
import { exportCSVFile } from "src/app/utils/excel";
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import { TransformPermission } from '../../models/transformPermission.model';
import { FileService } from 'src/app/files/services/file.service';
import { Store } from '@ngrx/store';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { ClientService } from '../../services/client.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';


@Component({
  selector: 'app-lookup-actions',
  templateUrl: './lookup-actions.component.html',
  styleUrls: ['./lookup-actions.component.scss']
})
export class LookupActionsComponent implements OnInit {
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;

  activeRoute$: Subscription;
  lookupTables:any[];
  lookupTypes:{ key: string | number; value: string }[];
  lookupForm: FormGroup = null;
  visible=false;
  currentFileUpload: File;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  popUp=false;
  modalForm: FormGroup=null;
  lookUpData:any;
  headerData:string;
  lookupTable:string;
  obj:any;
  lookupList:{rightTableName:String}[]=[];
  showType:boolean=false;
  tableName=null;
  dataTransferModel: DataTransferModel;
  rolePermission:TransformPermission;
  rolePermissionVal:any;

columns: string[] = [
  "S no.",
  "Lookup Table",
  "Actions",
];
  lookupColumnSelectorComponent: any;
  lookupColumnListEdit: any;
  lookupListName: any;
  clientName: string;
clientsList : {key: any; value: any }[]=[];
companyId:any;
company:String;
lookUpName:String;
getClientbyUserId:any;
adminFlag:String;
searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String | number, header:String }[]=[];
  state:string;
  constructor(private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private lookupService: LookupService,
    public observalbleDataService: ObservalbleDataService,
    private toast:ToastService,
    private router: Router,
    private loader: LoaderService,
    private datePipe:DatePipe,
    private cookieService: CookieService,private fileService:FileService,private storePermission: Store<{ role: any }>,
    private clientService:ClientService) {

      this.lookupForm=new FormGroup({
        lookupMap:new FormControl(null) ,
        client:new FormControl(null,[Validators.required])
      });

      this.modalForm = new FormGroup({
        client_id:new FormControl(null),
        asofdate:new FormControl(null,[Validators.required]),
      });


      this.searchByCols = [
        { field: "rightTableName", header: "Lookup Table" },
       
       
       
        
      ];
      this.state=localStorage.getItem("stateflag");
  }


  ngOnInit(){
    this.adminFlag=this.cookieService.get("adminFlag");

    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );
   this.buttonShowHidePermission();

   this.fileService.getClientByUserId().subscribe(
    (data) =>{
        this.getClientbyUserId=data;
       // console.log("lookupForm   ",this.lookupForm.value)
      
       for(var i=0;i<data.length;i++){
         console.log("data  ",data[i].clientId)
         this.companyId=data[i].clientId
         this.company=data[i].clientName
          }
          this.lookupForm.get("client").setValue({
            key: this.companyId,
            value: this.company
            });

            this.lookupForm.get("lookupMap").setValue({
              key: 'investments',
              value: "Investments" 
               });
               console.log("lookup form before",this.lookupForm.value) 
               this.getSelected(this.lookupForm.controls.lookupMap.value);
            this.clientSelected(event);
            this.lookUpName=this.lookupForm.controls.lookupMap.value.value; 
               console.log("lookup form ",this.lookupForm.value)  


          },
          (err)=>{
            this.toast.showError(err.error.message, "error");
          }
      
          );
        
        
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  onLoad(){
    console.log("e.key",this.lookupForm.value);
    console.log("inside get selected");
    this.panelFilter.toggleSlider();
    if((this.lookupForm.controls.client.value.value==null || this.lookupForm.controls.client.value.value==undefined) && (this.lookupForm.controls.lookupMap.value.value==null || this.lookupForm.controls.lookupMap.value.value==undefined)){
      return this.toast.showError("Please select the company name and any lookup type","error");
    }
    if(this.lookupForm.controls.client.value.value==null || this.lookupForm.controls.client.value.value==undefined){
      
      return this.toast.showError("Please select the company name","error");
    } 
    if(this.lookupForm.controls.lookupMap.value.value==null || this.lookupForm.controls.lookupMap.value.value==undefined){
      return this.toast.showError("Please select any lookup type","error");
    }
    this.lookUpName=this.lookupForm.controls.lookupMap.value.value;  
    this.lookupListName=this.lookupForm.controls.lookupMap.value.value;
    this.getSelected(this.lookupForm.controls.lookupMap.value);
    this.clientName=this.lookupForm.controls.client.value.value;
    this.clientSelected(event);
    }

    
  getLookupColumnDataByTable() {
    throw new Error('Method not implemented.');
  }

  showFileModalPopUp(rowData){
    if(this.lookupForm.valid){
    this.visible=true;
    //this.lookupTable=rowData;
    if(rowData=="all"){
    this.headerData="Import All File";
    this.tableName='all';;
   }else{
    this.headerData="Import File";
    this.tableName=rowData;
   }
  }else{
    this.toast.showError("Please select the client first","error");
  }
}

    doActionFile(type){
      if (type === "yes") {
        //this.modalForm.get("client_id").reset();
        this.visible=false;
        this.currentFileUpload=null;
        this.myInputVariable.nativeElement.value = "";
        this.modalForm.reset();
      }
     // this.modalForm.reset();
  }

  selectFile(event){
    this.currentFileUpload = event.target.files;
  }

  downloadAll(){
 // console.log("client "+this.lookupForm.controls.client.value.key);
  if(this.lookupForm.valid){
  this.loader.showLoader();
  let downloadSuccess:boolean=false;
  var link=document.createElement('a');
  //link.href="https://janus-dev.vivit.co/api/etl/lookup/download/?table=all&connection_id=2&client_id="+this.lookupForm.controls.client.value.key;
  link.href=environment.baseUrl+"/api/etl/lookup/download/?table=all&connection_id=1&client_id="+this.lookupForm.controls.client.value.key;
  link.click();
  this.toast.showSuccess('success','Your download will begin shortly');
  this.loader.hideLoader();
  }else{
    this.toast.showError("Please select the client first","error");
  }
}

showModalPopUp(){
  if(this.lookupForm.valid){
  //   const mapValue={
  //     "key":this.lookupForm.controls.client.value.key,
  //     "value":this.lookupForm.controls.client.value.value
  //   }
  //   this.modalForm.get("client_id").setValue(mapValue);
    this.popUp=true;
   }else{
   this.toast.showError("Please select the client first","error");
 }
 }

 doAction(type) {
  if (type === "yes") {
    this.modalForm.get("client_id").reset();
     this.modalForm.get("asofdate").reset();
    this.popUp=false;
  }
}

 getLookupExcel() {
 if(this.rolePermission==undefined || this.rolePermission.lookupAdminValidateAll){
  if(this.lookupForm.valid){
  if(this.modalForm.valid){
  var newDate=this.datePipe.transform(this.modalForm.controls.asofdate.value, 'yyyy/MM/dd');
  const modelVal={
   client_id:this.lookupForm.controls.client.value.key,
   asofdate:newDate,
   lookupDataType:this.lookupForm.controls.lookupMap.value.key,
  }
 // console.log(modelVal);
   this.lookupService.getLookupExcel(modelVal).subscribe(
     (data)=>{
       console.log(data);
       this.lookUpData=data;
      if(data && data.length > 0){
       exportCSVFile(null, data, "lookupfile");
       this.toast.showSuccess('success','Excel Returned');
       this.modalForm.reset();
       this.modalForm.get("asofdate").reset();
       this.lookupForm.controls.lookupMap.reset;
     }else{
      this.modalForm.get("asofdate").reset();
       this.toast.showError('error','No Data Found');
     }

     },
     (err)=>{
       this.toast.showError(err.error.message, "error");
     }

   )
   this.popUp = false;
  }else{
   this.toast.showError('error',"All Fields Are Mandatory");
  }
}else{
  this.toast.showError("Please select the client first","error");
}
}else{
  this.toast.showError("User is not Authorised to perform this action. Please contact to Admin",'error');
}
}


 downloadLookupFile(rowData){
  if(this.lookupForm.valid){
  this.loader.showLoader();
  let downloadSuccess:boolean=false;
  var link=document.createElement('a');
  //link.href="https://janus-dev.vivit.co/api/etl/lookup/download/?table="+rowData+"&connection_id=2&client_id="+this.lookupForm.controls.client.value.key;
  link.href=environment.baseUrl+"/api/etl/lookup/download/?table="+rowData+"&connection_id=2&client_id="+this.lookupForm.controls.client.value.key+"&lookup_datatype="+this.lookupForm.controls.lookupMap.value.key;
  link.click();
  this.toast.showSuccess('success','Your download will begin shortly');
  this.loader.hideLoader();
}else{
  this.toast.showError("Please select the client first","error");
}
}

downloadAllLookupFile(rowData){
  if(this.lookupForm.valid){
  this.loader.showLoader();
  let downloadSuccess:boolean=false;
  var link=document.createElement('a');
  let change_url=environment.baseUrl;
  //link.href="https://janus-dev.vivit.co/api/etl/lookup/download/?table=all&connection_id=2&client_id=0";
  //
  //change_url = 'http://127.0.0.1:8000';
  link.href=change_url+"/api/etl/lookup/download/?table=all&connection_id=2&client_id="+this.lookupForm.controls.client.value.key+"&lookup_datatype="+this.lookupForm.controls.lookupMap.value.key;
  link.click();
  this.toast.showSuccess('success','Your download will begin shortly');
  this.loader.hideLoader();
}else{
  this.toast.showError("Please select the client first","error");
}
}

saveLookupExcel(){
  if(this.rolePermission==undefined || this.rolePermission.lookupAdminValidateAll){
 if(this.lookupForm.valid){
  if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
    this.toast.showWarn('no file selected','error');
    return;
  }

  if(this.lookupForm.valid){
  const lookupData={
    "client_id":this.lookupForm.controls.client.value.key,
    "table":this.tableName,
    "lookup_dataType":this.lookupForm.controls.lookupMap.value.key
     }
  this.lookupService.saveLookupExcelData(lookupData,this.currentFileUpload).subscribe(
  data=>{
     this.obj=data;
    if(this.obj.status==200){
    this.toast.showSuccess("Successfully Updated", "success");
    //this.lookupForm.reset();
    this.visible=false;
    this.currentFileUpload=null;
    this.myInputVariable.nativeElement.value = "";
    this.lookupForm.controls.lookupMap.reset;
   }else{
    this.toast.showError(this.obj.msg, "error");
   }
  },
    error=>{
      this.toast.showError(error.error.message, "error");
    }
)
}else{
  this.toast.showError("All Fields Are Mandatory", "error");
}
}else{
  this.toast.showError("Please select the client first","error");
}
}else{
  this.toast.showError("User is not Authorised to perform this action. Please contact to Admin",'error');
}
}

getLookupTypes(){
  this.lookupTypes= [
    
    { key: 'loans', value: "Loan" },
    { key: 'borrowings', value: "Borrowings" },
    { key: 'deposit', value: "Deposit" },
    { key: 'general_ledger', value: "General Ledger" },
    { key: 'investments', value: "Investments" }

  ];
    return this.lookupTypes;
    }

    getSelected(e){
      console.log("lookupid ",e.key);
     // let value=e.target.value;
      // const lookupTypes=[
      //  "lookupType":value,
      //   ]
      this.lookupService.getLookuMappingListByType(e.key)
      .subscribe(
          (data)=>{
          this.lookupList=[];
           this.lookupTables=data;
            for(var i=0;i<data.length;i++){
              if(i==0){
                //this.lookupList.push("All Tables");
                this.lookupList.push({rightTableName:"All Tables"})
              
               // this.lookupList.push(this.lookupTables[i].rightTableName);
                this.lookupList.push({rightTableName:this.lookupTables[i].rightTableName})
              }else{
              //  this.lookupList.push(this.lookupTables[i].rightTableName);
                this.lookupList.push({rightTableName:this.lookupTables[i].rightTableName})
              }
            }

          console.log("list check "+this.lookupList);
          },
          (err)=>{
            this.toast.showError(err.error.message, "error");

          },
        )
     }
clientSelected(e){
  this.showType=true;
  this.cookieService.set('clientId',this.lookupForm.controls.client.value.key);
  this.cookieService.set('clientName',this.lookupForm.controls.client.value.value);
}

getPreviousState() {
  const clientIdC=this.cookieService.get('clientId');
  const clientNameC=this.cookieService.get('clientName');
  console.log(clientIdC);
  console.log(clientNameC);
 
  
  if(clientIdC!==undefined && clientIdC!==null){
    this.lookupForm.get("client").patchValue({
             key: clientIdC,
             value: clientNameC,
             });
             this.showType=true;     
                   
  }

}

maintainClientState(client){

  this.dataTransferModel = {
    clientFileId: 0,
    clientId: client.key,
    isRedirected: 1,
    applicationId: 0,
    toDate: '',
    fromDate: '',
    clientName: client.value,
    page: '',
    fileName: '',
    workflowName: '',
    workflowId: 0,
    dataType: '',
    dataTypeId: 0
  }
  this.observalbleDataService.addToInventory(this.dataTransferModel); 
}

goToLookup(){
  this.router.navigate(['lookup']);
}


buttonShowHidePermission(){
  this.fileService.buttonShowHidePermission().subscribe(
    (res) =>{ 
         this.rolePermissionVal = res  
         this.callNgOnInitMethods();
 }
  )};

  callNgOnInitMethods(){
    this.getPreviousState();
    this.setTitle();
    this.getClients();
  }

  getClients() {

    //  this.loader.showLoader();

      this.clientService.getAllClients().subscribe(

        (data) => {

         // this.loader.hideLoader();

          this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");

          console.log(this.clientsList)

        },

        (err) => {

     //     this.loader.hideLoader();

          this.toast.showError(err.error.message, "error");

        }

      );

}
}
