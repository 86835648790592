import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from "@angular/core";
import { RulesModel } from "src/app/dashboard/models/rules.model";

@Component({
  selector: "app-rules-table",
  templateUrl: "./rules-table.component.html",
  styleUrls: ["./rules-table.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class RulesTableComponent implements OnInit {
  @Input() data: RulesModel[] = [];
  @Input() hidebutton:boolean
  @Output() edit = new EventEmitter();
  @Output() apply = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() rulesListData =new EventEmitter();
  @Output() deactivate = new EventEmitter(); 
  cols: { field: string; header: string }[];
  visibleDelete:boolean=false;
  dataValue:any;
  headerData:any;
  status;
 // @Input() rolePermission;
  constructor() {
    this.cols = [
      { field: "ruleName", header: "Name of Rule" },
      { field: "appliesto", header: "Applied To" },
      { field: "userName", header: "Created By" },
      { field: "dateval", header: "Date" },
      { field: "status", header: "Status" },
      {field:"action",header:"Action" }
    ];
    this.status=["Active","Inactive"].map((d)=> ({key:d,value:d}));
  }

  ngOnInit(): void {}

  applyRule(data: RulesModel) {
    console.log(data)
    this.apply.emit(data);
  }

  editRule(data: RulesModel) {
    this.edit.emit(data);
  }

  deleteRule(data: RulesModel) {
    this.delete.emit(data);
  }

  onRowChange(e){
    
     if(this.data.length>1 && this.hidebutton===false){
     this.rulesListData.emit(this.data)
     }
   }

   hidePopup(e){
    this.visibleDelete=false;
    this.dataValue=null;

  }

  showPopup(str,data){
    if(str=="delete"){
    this.headerData="Do you want to delete?";
    this.dataValue=data;
    this.visibleDelete=true;
    }else{
     if(data.status==="Inactive"){
      this.headerData="Do you want to Activate?";
      this.visibleDelete=true;
      this.dataValue=data;
     }else{ 
    this.headerData="Do you want to Deactivate?";
    this.visibleDelete=true;
    this.dataValue=data;
     }  
  }
  }
 
  actionDo(str,rowdata){
    //console.log("yes delete do "+this.dataValue);
   if(str==='Do you want to delete?'){
    this.deleteRule(this.dataValue);
    this.dataValue=null;
    this.visibleDelete=false;
   }
   if(str=='active-deactivate'){
    console.log("enter ",rowdata)
    this.deactivateRule(rowdata);
    this.dataValue=null;
    this.visibleDelete=false;
   }
  }

  deactivateRule(data){
    this.deactivate.emit(data);
  }
}
