import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { FormsModel } from 'src/app/models/forms.model';
import {trim} from 'lodash';
import { ToastService } from 'src/app/core/services/toast.service';
//import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
//@UntilDestroy()
@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientFormComponent implements OnInit,OnChanges {
  currentFileUpload:FileList;
  currentFileUploadList:FileList;
  fileList:any=[];
  @ViewChild('myInput2')
  myInputVariable2: ElementRef;
  clientFileForm:FormGroup;
  clientApplicationFileForm:FormGroup;
  @Output() clientFileData=new EventEmitter();
  @Input() isZipUpload:any;
  @Input() formData:any
  @Input() showLoading:any;
  @Output() closePanel=new EventEmitter(); 
  constructor(private router: Router, private activeRoute: ActivatedRoute,private toast:ToastService) {
    this.clientFileForm = new FormGroup({
      client: new FormControl(null),
      toDate: new FormControl(null),
      fromDate: new FormControl(null),
      asOfDate: new FormControl(null),
      isTransposeOnUploadFile: new FormControl(false)
    });
    this.clientApplicationFileForm = new FormGroup({
      fileType: new FormControl(null),
      fileDataType: new FormControl(null),
      layoutFieldId: new FormControl(null),
      dependentUpon: new FormControl(null),
      fieldSeparator: new FormControl(null),
      schedule: new FormControl(null)
    })
  }
  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log("enter inside on changes",this.formData,changes);
    // if (changes && changes.formData.currentValue && changes.formData.firstChange) {
    //   const d: any = changes.formData.currentValue;
    //   console.log("enter inside on changes");
    //   this.clientFileForm.patchValue({
    //     client:  { key: d.client.key, value: d.client.value },
    //      fromDate: d.fromDate,
    //      toData:  d.toDate,
    //   });
    // //  console.log("on changes form client-portal-header",this.headerForm.value)
    // }
  }

  selectFile(event) {
      this.currentFileUpload=null;
      this.currentFileUploadList = event.target.files;
      for(let i=0;i<this.currentFileUploadList.length;i++){
        this.fileList.push(this.currentFileUploadList[i]);
        }
      this.currentFileUpload=this.fileList;
      console.log(" file 1 :",this.currentFileUpload);
  
    }
    selectFile2(event) {
      const file=event.target.files[0];
      console.log("zipupload ",file.type);
      this.currentFileUpload=null;
      if(file.type!=='application/x-zip-compressed'){
        this.toast.showError('error','select zip file');
        this.myInputVariable2.nativeElement.value="";
        event.target=null;
        return;
      }
    
     
      this.currentFileUploadList = event.target.files;
      for(let i=0;i<this.currentFileUploadList.length;i++){
        this.fileList.push(this.currentFileUploadList[i]);
        }
     this.currentFileUpload=event.target.files;
      console.log(" file 2 :",this.currentFileUpload);
  
    }
      
    saveClientFile(){
      const data={
        "file":this.currentFileUpload,
        "form":this.clientFileForm.value
  }
  console.log("data on ClientForm",data);
      this.clientFileData.emit(data);
      this.closePanel.emit();
    }

cancelButton(){
    this.clientFileForm.reset();
    this.fileList=[];
    this.currentFileUpload=null;
    this.closePanel.emit();
    
}

    removeFile(index){
      console.log(index);
      this.fileList=[];
      if(index===0 && this.currentFileUpload.length===1){
        event=null;
        return this.currentFileUpload=null;
      }
      for(let i=0;i<this.currentFileUpload.length;i++){
        this.fileList.push(this.currentFileUpload[i]);
        }
      console.log("before list "+this.fileList)
     // this.fileList = this.fileList.filter(obj => obj !== this.fileList[index]);
     this.fileList.splice(index,1);
     console.log("after list "+this.fileList)
     this.currentFileUpload=this.fileList;
    
    }

  }
