import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { GlHeadModel } from "../models/glHead.model";
//import { DepartmentModel } from '../models/Department.model';

@Injectable({
  providedIn: "root",
})
export class GlHeadService {
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}

  saveOrUpdate(data) {
    return this.http.post<any[]>(
      environment.baseUrl + "/apis/glhead/createGlHead",
      data
    );
  }

  getAllGlHeadList() {
    return this.http.get<any[]>(environment.baseUrl + "/apis/getGlHeadAll");
  }

  getAllHqlaList() {
    return this.http.get<any[]>(environment.baseUrl + "/apis/getHqla");
  }

  deleteGlHeadId(glHeadId) {
    return this.http.get<GlHeadModel[]>(
      environment.baseUrl + "/apis/deleteGlHeadId/" + glHeadId
    );
  }

  getGLHeadByClientId(id){
    return this.http.get<GlHeadModel[]>(
      environment.baseUrl + "/apis/glhead/getGLHeadById/" + id
    );
  }

}
