import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { RateSensitivityStatementMappingTableComponent } from './components/rate-sensitivity-statement-mapping-table/rate-sensitivity-statement-mapping-table.component';



@NgModule({
  declarations: [
    RateSensitivityStatementMappingTableComponent

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    RateSensitivityStatementMappingTableComponent
    ]
})
export class RateSensitivityStatementMappingModule { }
