import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss'],
 // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTableComponent implements OnChanges{
  @Input() columns: string[] = [];
  @Input() data: any[] = [];
  @Output() selected = new EventEmitter();
  @Output() statusChanged = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter();

  visibleDelete: boolean = false;
  dataValue: any;
  constructor(private cdr:ChangeDetectorRef) {}

  emitSelected(data: any, event: Event) {
    const ele = event.target as HTMLInputElement;
    this.selected.emit({ status: ele.checked, data });
  }
   onStatusChanged(data: any, event: Event,rowData) {
     const ele = event.target as HTMLInputElement;
     this.statusChanged.emit({ status: ele.checked, data ,appId:rowData.appId});
     console.log("data",data);
   }

  ngOnChanges(changes: SimpleChanges): void {
  // console.log("report table ",changes) 
   if(changes && changes.data && changes.data.currentValue){
     this.cdr.detectChanges();
   }
  }

  showPopupDelete(data) {
    this.dataValue = data;
    this.visibleDelete = true;
  }


  hidePopup() {
    debugger;
    this.dataValue = null;
    this.visibleDelete = false;
  }

  actionDo(str){
    this.deleteApplicationData(this.dataValue);
    this.dataValue=null;
    this.visibleDelete=false;
  }

  deleteApplicationData(data){
     this.delete.emit(data);
   }

   editAppData(data){
    this.edit.emit(data);
  }

  // onStatusChanged(data){

  //   this.statusChanged.emit(data);
  //   console.log("Table daa for status",data);
  // }

}
