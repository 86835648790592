import { Component, OnInit,AfterViewChecked, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { WorkflowJobSelectorComponent } from 'src/app/workflows/components/workflow-job-selector/workflow-job-selector.component';
import { SetupJobComponent } from '../setup-job/setup-job.component';
import { WorkflowJobComponent } from '../workflow-job/workflow-job.component';


@Component({
  selector: 'app-workflow-tabbed',
  templateUrl: './workflow-tabbed.component.html',
  styleUrls: ['./workflow-tabbed.component.scss']
})
export class WorkflowTabbedComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(WorkflowJobComponent, { read: false, static: false })  
  workflowJobComponent: WorkflowJobComponent;

  @ViewChild(SetupJobComponent, { read: false, static: false }) 
  setupJobComponent:SetupJobComponent;

  data1:boolean=false;
  data2:boolean=false;
  // data3:boolean=false;
  // data4:boolean=false;
  // data5:boolean=false;
  steps = [
            { text: 'manage', link: '' },
            { text: 'setup', link: '' },
          ];
  constructor(private readonly changeDetectorRef: ChangeDetectorRef,private activeRoute: ActivatedRoute) { }
  viewData1:boolean=false;
  viewData2:boolean=false;
  manageBoolean:boolean=false;
  setupHeader:String;
  activeRoute$: Subscription;
  redirectToWorkflow:boolean=false;
  ngOnInit(): void {
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
    
        if (par.has("groupId") && par.has("groupName")) {
          this.redirectToWorkflow=true;
          this.steps = [
            { text: 'manage', link: '' },
          ];
          
      
        }
  })
}
  

  getComponent(data){
    if(data===1){
      this.data1=true;
      this.data2=false;
      // this.data3=false;
      // this.data4=false;
      // this.data5=false;
      if(!this.redirectToWorkflow){
      document.getElementById("header_manage").style.display = 'none';
      document.getElementById("setup_buttons").style.display = 'none';
       document.getElementById("manage_buttons").style.display = '';
      }
      if(this.redirectToWorkflow){
        document.getElementById("header_manage").style.display = 'none';
      document.getElementById("setup_buttons").style.display = 'none';
      document.getElementById("manage_buttons").style.display = 'none';
      }
    }
    if(data===2){
      this.data1=false;
      this.data2=true;
 
      this.setupHeader="false";
      // document.getElementById("header_setup").style.display = 'none';
      document.getElementById("setup_buttons").style.display = '';
      document.getElementById("manage_buttons").style.display = 'none';
    
     
    }
    //  if(data===3){
    //   this.data1=false;
    //   this.data2=false;
    //   this.data3=true;
    //   this.data4=false;
    //   this.data5=false;
    // }
    // if(data===4){
    //   this.data1=false;
    //   this.data2=false;
    //   this.data3=false;
    //   this.data4=true;
    //   this.data5=false;
    // }
    // if(data===5){
    //   this.data1=false;
    //   this.data2=false;
    //   this.data3=false;
    //   this.data4=false;
    //   this.data5=true;
    // }



   // console.log("data recieved here ",data);

  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  toggleManageForm(){

this.workflowJobComponent.callManageToggle();

  }

}
