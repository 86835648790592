import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { FormsModel } from 'src/app/models/forms.model';
import {trim} from 'lodash';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { LookupService } from 'src/app/dashboard/services/lookup.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
//import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
//@UntilDestroy()
@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFormComponent implements OnInit,OnChanges {
  @Input() searchBy: string[] = [];
  @Input() groupData: {key: any; value: any }[]=[];
  @Input() appListData: {key: any; value: any }[]=[];
  @Input() reportMode: {key: any; value: any }[]=[];
  @Input() statusData:  {key: any; value: any }[]=[];
  @Input() notificationList;
  @Output() closePanel=new EventEmitter();
  search = new FormControl();
  selectedNotificationType: {notificationId: any;notificationName: any}[]=[];
  createAppForm: FormGroup = null;
  reportModel: FormsModel = null;
  todoObjview:any;
  viewList:any;
  // allCountryData: {key: any; value: any }[]=[];
  allCountryData:any=[];
  selectedCountryList: {key: any; value: any }[]=[];
  companyData:any=[];
  selectedCompanyTypeList: {key: any; value: any }[]=[];
  @Output() save = new EventEmitter();
  @Input() editAppData;
  myArray: any;
  enableEdit: boolean = false;
   // viewReset:boolean=false;
   @Input() categoryList: {key: any; value: any }[]=[];
   @Input() frequencyList: {key: any; value: any }[]=[];
   
   
   
  constructor(
    private loader:LoaderService, private lookupService: LookupService, private sspapplicationService: SspApplicationService,
    private router: Router, private activeRoute: ActivatedRoute,private toast:ToastService) {
    this.createAppForm = new FormGroup({
      appId: new FormControl(null),
      appName: new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(100)]),
      appStatus: new FormControl(null,[Validators.required]),
      groupData:new FormControl(null, [Validators.required]),
      reportUrl:new FormControl(null),
      viewName: new FormControl(null),
      viewUrl: new FormControl(null),
      viewList: new FormControl(null,[Validators.required]),
      reportMode:new FormControl(null,[Validators.required]),
      description: new FormControl(null,[Validators.maxLength(100)]),
      category:new FormControl(null,[Validators.required]),
      notificationType: new FormControl(null),
      frequencyList:new FormControl(null),
      frequency:new FormControl(null),
      outtableName:new FormControl(null),
      appListData:new FormControl(null,[Validators.required]),
      countryList: new FormControl(null),
      companyType:new FormControl(null)

    });

    this.viewList=[];
    
  }
  ngOnInit(): void {
    console.log("on nginit");
    const defaultReportMode={
      key:'readytouse',
      value:'Ready to use'
    }
    this.createAppForm.patchValue({
      reportMode:defaultReportMode,
    });
    this.getCountryCode();
    this.getCompany();
  
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.editAppData && changes.editAppData.currentValue) {
      console.log("Selector Changes Are:", changes);
      const currentChangeValue = changes.editAppData.currentValue;
      console.log("currentChangeValue:", currentChangeValue)
      let notificationEditList=changes.editAppData.currentValue.notificationList
      this.selectedNotificationType=[];
       for (var i = 0; i < notificationEditList.length; i++) {
         this.selectedNotificationType.push({ notificationId: notificationEditList[i].notificationId, notificationName: notificationEditList[i].notificationName });
       }
       //console.log("selected Notification on edt: ",this.selectedNotificationType);
       let countryEditList = changes.editAppData.currentValue.countryList;
       this.selectedCountryList=[];
       for(var i=0; i<countryEditList.length; i++){
        this.selectedCountryList.push({key: countryEditList[i].key, value: countryEditList[i].value});
       }
       let companyTypeEditList = changes.editAppData.currentValue.companyType;
       this.selectedCompanyTypeList=[];
       for(var i=0; i<companyTypeEditList.length; i++){
        this.selectedCompanyTypeList.push({key: parseInt(companyTypeEditList[i].key), value: companyTypeEditList[i].value});
       }
       this.createAppForm.patchValue({
        companyType : this.selectedCompanyTypeList
       });
       this.createAppForm.patchValue({
        countryList : this.selectedCountryList,
       });
       //console.log("selected Country on edt: ",this.selectedCountryList);
       //console.log("selected companyType on edt: ",this.selectedCompanyTypeList);

      console.log("editAppData  ",this.editAppData)
      let status = {};
      currentChangeValue.isActive === true ? status = { key: 'Active', value: 'Active' } : status = { key: 'Inactive', value: 'Inactive' };
     // this.selectedGroup.push({ key: currentChangeValue.groupData.groupId, value: currentChangeValue.groupData.groupName });
      const selectedGroupData = {
        key: currentChangeValue.groupData.groupId,
        value:currentChangeValue.groupData.groupName,
      };
      const selectedAppData = {
        key: currentChangeValue.applicationId,
        value: currentChangeValue.applicationId,
      };
      console.log("selectedAppData: ",selectedAppData);
      const selectedReportMode={
        key: currentChangeValue.genericDropDownDataVal.itemValue,
        value:currentChangeValue.genericDropDownDataVal.itemText,
      }

      this.myArray =currentChangeValue.instance;
     // this.instanceList =[];
      this.viewList =[];
      this.myArray.forEach(x => {
        if(x.length !== 0){
        this.viewList.push({"viewName": x.instanceName,"viewUrl":x.instanceUrl,"completed": true})
        }
     });
      console.log("this.viewList: ", this.viewList);
      // console.log("currentChangeValue ", currentChangeValue);
      if(currentChangeValue.categoryId!=null){
        console.log("enter.........")
      // this.createAppForm.get('category').setValue({
      //    key:currentChangeValue.categoryName,
      //    value:currentChangeValue.categoryName
      // })

      const mappedCountry = {
        key:currentChangeValue.categoryId,
        value:currentChangeValue.categoryName
      };
      this.createAppForm.controls.category.setValue(mappedCountry);
    }
    else{
      this.createAppForm.get('category').setValue("")
    }

    this.createAppForm.get("frequency").setValue({
      key:currentChangeValue.frequency,
      value:currentChangeValue.frequency
     });

      this.createAppForm.patchValue({
        appId: currentChangeValue.appId,
        appName: currentChangeValue.appName,
        appStatus: status,
        viewList: this.viewList,
        groupData:selectedGroupData,
        appListData:selectedAppData,
        reportUrl:currentChangeValue.embeddedUrl,
        reportMode:selectedReportMode,
        description:currentChangeValue.appDesc,
        outtableName: currentChangeValue.outtableName,       
      });

      
      console.log("edit  ",this.createAppForm.controls.reportMode.value)
      
     
      this.enableEdit = true;
      this.createAppForm.patchValue({
        notificationType:this.selectedNotificationType
      });
      //console.log(" this.createAppForm  ", this.createAppForm.value)
   
    }
    //this.editAppData() 
     

  }

  // frequencyType(){
  //   const frequencyList=[
  //     "Daily",
  //     "Weekly",
  //     "Monthly",
  //     "Quaterly",
  //     "Half Yearly",
  //     "Yearly",
      
  //   ];
  //   return frequencyList.map((t) => ({ key: t, value: t }));

  // }

  multiNotificationTypeSelectChange(eventData){
    this.selectedNotificationType = eventData.value;
    console.log("Datatype Value Updated Is:", this.selectedNotificationType);

  }
  multiCountryTypeSelectChange(eventData){
    this.selectedCountryList = eventData.value;
    console.log("selected country list:", this.selectedCountryList);

  }

  multiCompanyTypeSelectChange(eventData){
    this.selectedCompanyTypeList = eventData.value;
    console.log("selected company type:", this.selectedCompanyTypeList);

  }

  

  addTodoView(event) {
    console.log("event ",event.target.value);
    let viewName= this.createAppForm.get('viewName').value;
    let viewUrl= this.createAppForm.get('viewUrl').value;
    if(trim(viewName).length>0 && trim(viewUrl).length>0){
    this.todoObjview = {
      viewName: trim(viewName),
      viewUrl: trim(viewUrl),
      completed: false
    }
      // completed: false
      let compare = this.viewList.filter(x=>x.viewName.toUpperCase() === this.todoObjview.viewName.toUpperCase());
      //  console.log("compare: ",compare);
      if(compare.length===0){
        this.viewList.push(this.todoObjview);
        this.createAppForm.patchValue({
          viewName: '',
          viewUrl: ''
        });
        this.createAppForm.patchValue({
          viewList: this.viewList
        });
        event.preventDefault();
      }else{
        this.toast.showError("This instance is already added", "error");
        this.createAppForm.patchValue({
          viewName: '',
          viewUrl: ''
        });
      }
    }
      }

   
      deleteTodoView(index) {
        this.viewList.splice(index, 1);
        console.log("this.viewList ",this.viewList)
      }

      createAppData() {
        if(this.createAppForm.controls.reportMode.value.value==='Design Mode'){
          this.viewList.push({"viewName": null,"viewUrl":null,"completed": false})
          this.createAppForm.patchValue({
            viewList: this.viewList
          });
        }
        this.createAppForm.patchValue({
     
          notificationType: this.selectedNotificationType,
          countryList: this.selectedCountryList,
          companyType : this.selectedCompanyTypeList,

        }); 

        
        if(this.createAppForm.valid){
      // if(this.createAppForm.controls.description.value.length>=256){
      //   this.toast.showInfo("Description Size Should be 255 Or Less","Info");
      //   this.closePanel.emit();
      //   return;
      // }
      //console.log("this.createAppForm ",this.createAppForm.value)

      this.save.emit(this.createAppForm.value);
      this.resetForm();
    } else{
      //console.log("this.createAppForm in case of invalid ",this.createAppForm.value)
        this.toast.showError("All Fields Are Mandatory", "error");
      }
      this.closePanel.emit();
      
    }

    resetForm() {
      this.viewList=[];
     // this.viewReset=true;
      this.createAppForm.reset(); 
      const defaultReportMode={
        key:'readytouse',
        value:'Ready to use'
      }
      this.createAppForm.patchValue({
        reportMode:defaultReportMode,
      });
      console.log("reportMode   ",this.createAppForm.controls.reportMode.value)
     
      this.selectedNotificationType=[];
      this.selectedCompanyTypeList=[];
      this.selectedCountryList=[];
    }

    getCountryCode(){
      const lookupModel={
        "tableName":"country_code",
        "clientId": 139
      };
      this.lookupService.getLookUpDataByTableAndClient(lookupModel).subscribe(
        data=>{
          this.allCountryData = reMapForAutoComplete(data,"code", "description");
        },
        err=>{
         this.toast.showError(err.error.message, "error");
       });
       console.log("contry data ", this.allCountryData);
     }
  
     getCompany(){
     
      this.sspapplicationService.getCompanType().subscribe(
        data=>{
          this.companyData = reMapForAutoComplete(data, "companyTypeId","companyTypeName" )
          console.log("company data" ,this.companyData);
        }

        )

         
     
  
}

   
}
