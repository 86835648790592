import { Component, OnInit, Output,EventEmitter, SimpleChanges, Input } from '@angular/core';
import { FileService } from 'src/app/files/services/file.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent implements OnInit {
  @Output() create = new EventEmitter();
  clientMasterForm: FormGroup = null;
  errorMessage: any;
  //fileDataTypeList: any = [];
  //fileTypeList: any = [];
  @Output() closePanel=new EventEmitter();
  roleForm:FormGroup;

  constructor(private fileService: FileService, private toast: ToastService) {
    this.roleForm = new FormGroup({
      roleName: new FormControl(null,[Validators.required,Validators.minLength(4),Validators.maxLength(10)]),
      roleDescription:new FormControl(null,[Validators.maxLength(100)]),
     });
 
  }

  
  addRole() {
    if(this.roleForm.valid){
    this.create.emit(this.roleForm.value);
  }else{
  this.toast.showError("All fields are mandatory","error");
  }
  this.closePanel.emit();
  this.roleForm.reset();
}
  

  ngOnInit(): void {
  }
  formReset(){
  this.clientMasterForm.reset();
}
}
