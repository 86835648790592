import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from "rxjs";
import { FileService } from 'src/app/files/services/file.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { ClientService } from "src/app/dashboard/services/client.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';

@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.scss']
})
export class ReportDashboardComponent implements OnInit {

  env:string=null;
  env1:any;
  groupList:any=[];
  reportList:any=[];
  viewList:any=[];
  clientId:number;
  url:string=null;
  headerForm:FormGroup=null;
  activeRoute$: Subscription;
  clientsList: any[];
  constructor(
    private titleService: TitleService,
    private fileService: FileService, 
    private reportDashboardService:ReportDashboardService,
    private  toast: ToastService,
    private activeRoute: ActivatedRoute,
    private clientService: ClientService,
    private loader:LoaderService,
    private sspApplicationService :SspApplicationService
  ) {
    this.headerForm = new FormGroup({
      GroupData: new FormControl(null),
      ReportData: new FormControl(null),
      client: new FormControl(null),
      viewData:new FormControl(null)

    });



   }

  ngOnInit(): void {
    this.setTitle();
    // this.callDashboardURL();
    // this.getFilteredDashboard();
    this.getClients();
    this.getAllGroups();


  }


  getClients() {
    this.loader.showLoader();
    this.clientService.getAllClients().subscribe(
      (data) => {
        this.loader.hideLoader();
        this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
      },
      (err) => {
        this.loader.hideLoader();
        this.toast.showError(err.error.message, "error");
      }
    );
  }



  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }


  onClientChange(data){
    console.log("client data ",data)
    this.clientId=data.key;
    console.log("this.clientId",this.clientId)

    // this.env=this.url+"?INCLUDETITLE=false&ZOHO_CRITERIA=%22company_id%22%3D"+this.clientId;
    // if (!this.env.includes('https://zoho-server.uventure.in/open-view/')) { 
    //   this.env=null;
    
    // }
    console.log('url',this.env);
    

  }



  onGroupChange(data){

    console.log("evetData  ",data)
    this.headerForm.controls.ReportData.reset();
    this.headerForm.controls.viewData.reset();
    this.env=null;
    this.viewList=[];
    const dataVal={
      "groupId":data.key,
      "reportMode":"readytouse"
    }

    this.reportDashboardService.getReportByGroupId(dataVal).subscribe(

      res => {
        // this.loader.hideLoader();
        
        this.reportList=res;
        console.log("this.reportList  ",this.reportList);
        this.reportList=reMapForAutoComplete( this.reportList,"appId","appName");
         console.log("all report  ", this.reportList);
  
        },
        err => {
         // console.log('url',err);
         this.toast.showError("failed to get groups", "error");
  
        }
    )


  }


  getreport(data){
    this.url=data.key;
    console.log("client id is",this.clientId)
    this.env= data.key+"?INCLUDETITLE=false";
    
    // if(this.clientId==0 || this.clientId!=undefined || this.clientId!=null){
    //   this.env= data.key+"?INCLUDETITLE=false&ZOHO_CRITERIA=%22company_id%22%3D"+this.clientId;
    // }
   
    if (!this.env.includes('https://zoho-server.uventure.in/open-view/')) { 
      this.env=null;
    
    }
    console.log('url',this.env);

  }

  getAllGroups(){

  this.reportDashboardService.getAllGroups().subscribe(
    res => {
      // this.loader.hideLoader();
     
      this.groupList=res;
      this.groupList=reMapForAutoComplete( this.groupList,"groupId","groupName");
        console.log("all gropus  ", this.groupList);

      },
      err => {
       // console.log('url',err);
       this.toast.showError("failed to get groups", "error");

      }
  )

  }

  // callDashboardURL(){
  //   console.log("data url first ")
  //   this.fileService.getDashboardUrl().subscribe(
  //     // (data)=>{
  //     //   console.log("data url ",data)
  //     // this.env=data;
  //     //          },

  //     res => {
  //       // this.loader.hideLoader();
  //       console.log('url',res);
  
  //         this.env= res.msg;
  //         console.log('url',this.env);
  
  //       },
  //       err => {
  //        // console.log('url',err);
  //        this.toast.showError("failed to get url", "error");
  
  //       }

  //     // (err)=>{
  //     //      this.toast.showError("failed to get url", "error");
  //     //      }
  //   )

    
  // }

  // getFilteredDashboard(){


  //   console.log("data url first ")
  //   this.fileService.getFilteredDashboard().subscribe(
  //     // (data)=>{
  //     //   console.log("data url ",data)
  //     // this.env=data;
  //     //          },

  //     res => {
  //       // this.loader.hideLoader();
  //       console.log('url',res);
  
  //         this.env1= res.msg;
  //         console.log('url',this.env1);
  
  //       },
  //       err => {
  //        // console.log('url',err);
  //        this.toast.showError("failed to get url", "error");
  
  //       }

  //     // (err)=>{
  //     //      this.toast.showError("failed to get url", "error");
  //     //      }
  //   )




  // }

  getViewList(data){

     
    console.log("data  ",data)
    this.headerForm.controls.viewData.reset();
    this.env=null;
    const reportId={
      appId:data.key

    };
    this.sspApplicationService.getInstanceByAppId(reportId).subscribe(

      data=>{
      this.viewList=data
      console.log("this.viewList  ",this.viewList)
      this.viewList=reMapForAutoComplete( this.viewList,"instanceUrl","instanceName");
         console.log("all viewList  ", this.viewList);

         console.log("viewList[0]  ",this.viewList[0])
    this.headerForm.patchValue({
      viewData: {key:this.viewList[0].key ,value:"Default"}

    })
    this.getreport(this.viewList[0]);

      },
      err=>{
          // console.log('url',err);
          this.toast.showError("failed to get views", "error");
   
         }
     
    )
    // console.log("viewList  ",this.viewList[1])
    // this.headerForm.patchValue({
    //   viewData: this.viewList[1]

    // })

  }





 


}
