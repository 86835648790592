import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { LookupColumnSelectorComponent } from '../lookup-column-selector/lookup-column-selector.component';

@Component({
  selector: 'app-lookup-column-header',
  templateUrl: './lookup-column-header.component.html',
  styleUrls: ['./lookup-column-header.component.scss']
})
export class LookupColumnHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(LookupColumnSelectorComponent)
  lookupColumnSelectorComponent:LookupColumnSelectorComponent;

  @Input() lookUpColumnTable;
  @Input() lookupColumnListEdit;
  @Output() saveLookupColumn=new EventEmitter();
  @Input() rolePermission;

  @Output() nullEditValues=new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  saveData(data){

    this.saveLookupColumn.emit(data);
  }

  addLookupData(){
this.lookupColumnSelectorComponent.resetData();
this.nullEditValues.emit();
    this.panelForm.toggleSlider();
  }

}
