import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-department-table',
  templateUrl: './department-table.component.html',
  styleUrls: ['./department-table.component.scss']
})
export class DepartmentTableComponent implements OnInit {
 @Input() departmentList:any[];
 @Input() rolePermission:any;
 @Output() edit = new EventEmitter();
 @Output() delete = new EventEmitter();
 dataValue:any;
 visibleDelete: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  editDepartment(rowData){
  this.edit.emit(rowData);
  }

  showPopupDelete(rowData) {
    this.dataValue = rowData;
    this.visibleDelete = true;
  }

  hidePopup() {
    debugger;
    this.dataValue = null;
    this.visibleDelete = false;
  }

  actionDo(str){
    this.deleteDepartmentId(this.dataValue);
    this.dataValue=null;
    this.visibleDelete=false;
  }

  deleteDepartmentId(rowData){
    this.delete.emit(rowData);
  }

}
