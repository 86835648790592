import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PopUpModel, LookUpListModel, LookUpField, LookupModel } from '../models/lookup.model';

@Injectable()
export class LookupService {
  constructor(private http: HttpClient) {}
  /**
   * @param  {number} id
   */
  created_by: number;

  getLookupExcel(modalForm){
   // console.log("modalForm " +modalForm.created_by);
   var payload = new FormData();
   payload.append("created_by", "1")
   payload.append("insert_data","0");
   payload.append("connection_id","2");
   payload.append("client_id",modalForm.client_id);
   payload.append("check_only","0");
   payload.append("details","0");
   payload.append("asofdate",modalForm.asofdate);
   payload.append("lookup_datatype",modalForm.lookupDataType);
    return this.http.post<PopUpModel[]>(
      `${environment.baseUrl}/api/etl/lookup/validation/`,payload
    );
  }

  getLookUpDataByTableAndClient(lookUpData){

    return this.http.post<LookUpListModel[]>(
      `${environment.baseUrl}/apis/lookup/getLookupDataList`,lookUpData
    );

  }

  getLookUpById(lookUpData){

    return this.http.post<LookUpField>(
      `${environment.baseUrl}/apis/lookup/getLookupById`,lookUpData
    );

  }

  saveLookUpData(lookUpData){
    console.log(lookUpData,' service')
    return this.http.post<LookUpField>(
      `${environment.baseUrl}/apis/lookup/saveLookUp`,lookUpData
    );
  }

saveLookupExcelData(lookupForm,file){
  var payload = new FormData();
  payload.append("client_id",lookupForm.client_id);
  payload.append("table",lookupForm.table);
  payload.append("connection_id","2");
  payload.append("file",file[0]);
  payload.append("lookup_datatype",lookupForm.lookup_dataType);
   return this.http.post<any[]>(
     `${environment.baseUrl}/api/etl/lookup/update/`,payload
   );
 }

 getLookuMappingListByType(lookupModel){
  return this.http.post<any[]>(
    `${environment.baseUrl}/apis/lookup/getLookupMappingByType`,lookupModel
  );
 }

deleteLookup(data){
  return this.http.post<any>(environment.baseUrl + "/apis/lookup/deleteLookupById",data);
}

}
