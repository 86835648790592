import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
//import { EventEmitter } from 'protractor';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';

@Component({
  selector: 'app-generic-file-header',
  templateUrl: './generic-file-header.component.html',
  styleUrls: ['./generic-file-header.component.scss']
})
export class GenericFileHeaderComponent implements OnInit,OnChanges {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;
  @Output() emitfileupload=new EventEmitter();
  fieldSeparatorList:{ key: string ; value: string }[];
  layoutFieldList: any = [];
  dependentUponList: any = [];
  fileTypeList: any = [];
  fileDataTypeList: any = [];
  currentFileUpload:FileList;
  clientApplicationFileForm: FormGroup;
  @Input() clientId:number;
  @Input() value:any;
  @Output() getFileHeaders:any;
  clientValue:number=0;
  headerForm: FormGroup;
  genericClient:any;

  @Input() showFileUploadHeader:boolean=true;
  @Input() state:string;
    constructor(private fileService:FileService,private toast:ToastService,private cookieService:CookieService) { 
    this.clientApplicationFileForm = new FormGroup({
      fileType: new FormControl(null),
      fileDataType: new FormControl(null),
      layoutFieldId: new FormControl(null),
      dependentUpon: new FormControl(null),
      fieldSeparator: new FormControl(null),
      schedule: new FormControl(null)
    });
    this.headerForm= new FormGroup({
      client: new FormControl(null),
          });
  }

  ngOnInit(): void {
    this.getApplicationFileType();
    this.getClientApplicationFileTypeData();
    this.getAllSeparators();
  }

  ngOnChanges(changes: SimpleChanges) {
     console.log("on changes form client-portal-header",this.headerForm.value)
     if (changes && changes.clientId && changes.clientId.currentValue) {
         const d: any = changes.clientId.currentValue;
        console.log("enter inside on changes");
         this.headerForm.patchValue({
           client:  { key: d.client.key, value: d.client.value },
         });
  
        this.getDependentUponData(this.headerForm.controls.client.value.key);
        this.getLayoutField(this.headerForm.controls.client.value.key);
        } 
       console.log("on changes form client-portal-header outside",this.headerForm.value)
    }
    
  addLayoutFile(){
    this.panelForm.toggleSlider();

   }


  getApplicationFileType(){
    this.fileService.getApplicationDataTypeByUser().subscribe(
   (data) => {
     const fileDataType=data;
     for(var a=0;a<fileDataType.length;a++){
     // console.log("type "+fileDataType[a].type);
     if(fileDataType[a].clientApplicationFileDataType==15){
      this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });

      this.clientApplicationFileForm.get('fileDataType').setValue({
        key: fileDataType[a].clientApplicationFileDataType,
        value: fileDataType[a].type
       });
     }
       }
   },
   (err) => {
   //  this.toast.showSuccess("Status Not Changed", "error");
   this.toast.showError(err.error.message, "error");
   }
 
 );
    
 }

 getLayoutField(clientId){
  this.fileService.getAppFilesByClientId(clientId).subscribe(
    (data) => {
      const layoutFieldFile=data;
      for(var a=0;a<layoutFieldFile.length;a++){
      // console.log("type "+layoutFieldFile[a].fileName);
        this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }
  
  );

}

getDependentUponData(clientId){
  this.fileService.getDependentUpon(clientId).subscribe(
    (data) => {
      const dependentUpon=data;
      for(var a=0;a<dependentUpon.length;a++){
        console.log("type "+dependentUpon[a].fileName);
        this.dependentUponList.push({ key: dependentUpon[a].clientApplicationFileId, value: dependentUpon[a].fileName });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }
  
  );

}
      
getClientApplicationFileTypeData(){
  this.fileService.getClientApplicationFileType(this.state).subscribe(
    (data) => {
      const clientApplicationFileType=data;
      for(var a=0;a<clientApplicationFileType.length;a++){
       // console.log("type "+clientApplicationFileType[a].type);
       if(clientApplicationFileType[a].visible){
        this.fileTypeList.push({ key: clientApplicationFileType[a].clientApplicationFileTypeId, value: clientApplicationFileType[a].type });
       

       this.clientApplicationFileForm.get('fileType').setValue({
        key: clientApplicationFileType[a].clientApplicationFileTypeId,
        value: clientApplicationFileType[a].type
       });
      }
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    this.toast.showError(err.error.message, "error");
    }
  
  );

}

getAllSeparators(){
  this.fieldSeparatorList= [
   { key: ',', value: "Comma" },
   { key: 'space', value: "Space" },
   { key: '|', value: "Pipe(|)" },
   { key: '"', value: "Quotes" },
   { key: ';', value: "Semicolon" },
   { key: ':', value: "Colon"},
   { key: '\t', value: "Tab" }
 ];
}

saveClientApplicationFile(data){
  console.log(this.clientId);
  this.genericClient=this.cookieService.get('genericClientId');
  this.currentFileUpload=data.file;
    this.clientApplicationFileForm.patchValue({
      fileType: { key: data.form.fileType.key, value: data.form.fileType.value },
      fileDataType: { key: data.form.fileDataType.key, value: data.form.fileDataType.value },
      // layoutFieldId: { key: data.form.layoutFieldId.key, value: data.form.layoutFieldId.value },
      // dependentUpon:{ key: data.form.dependentUpon.key, value: data.form.dependentUpon.value },
      fieldSeparator: { key: data.form.fieldSeparator.key, value: data.form.fieldSeparator.value },
    });
  if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
    this.toast.showWarn('no file selected','error');
    return;
  }
  // if(this.clientValue===0){
  //   this.clientId=this.clientId;
  //    }

    const ClientAppliactionFileConst = {
     "clientId": this.genericClient,
     "clientApplicationFileTypeId": this.clientApplicationFileForm.controls.fileType.value.key,
     "dependentUpon":this.clientApplicationFileForm.controls.dependentUpon.value,
     "schedule":this.clientApplicationFileForm.controls.schedule.value,
     "fieldSeparator":this.clientApplicationFileForm.controls.fieldSeparator.value,
     "clientApplicationFileDataTypeId":this.clientApplicationFileForm.controls.fileDataType.value.key,
     "layoutFieldId":this.clientApplicationFileForm.controls.layoutFieldId.value,
     "genericFileUpload":1,
     "state":this.state
    };
   this.fileService.saveClientApplicationFile(this.currentFileUpload,ClientAppliactionFileConst,this.state) .subscribe(
    data=>{
      this.toast.showSuccess('Success','File Uploaded Successfully');
    // this.clientApplicationFileForm.controls.file.value.key;
      this.currentFileUpload=null;
     // this.myInputVariable.nativeElement.value = "";
     // this.visibleLayoutPopUp=false;
      this.clientApplicationFileForm.reset();
     // this.loader.hideLoader();
      window.location.reload();
     
     
    },
    error=>{
      this.toast.showError(error.error.message, "error")
    //  this.loader.hideLoader();
    },
   
  );
  }
}
