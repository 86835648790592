import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { UserService } from 'src/app/user/services/user.service';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit {

  @Input() data = [];
  @Output() edit = new EventEmitter();
  userList:any[];
  @Input()  rolePermission;

  cols: { field: string; header: string }[];
  constructor(
    private loader:LoaderService,
    private userService:UserService,
    private storePermission: Store<{ role: any }>
  ) {

    this.cols = [
      { field: "userName", header: "User Name" },
      { field: "firstName", header: "First Name" },
      { field: "lastName", header: "Last Name" },
      { field: "roleName", header: "Role Name" },
      { field: "status", header: "Status" }
    ];
   }

  ngOnInit(): void {

   // this.storePermission.select(x => this.rolePermission = x).subscribe();
  }

  editUser(data) {
    //console.log("I am in edit");
    //console.log(data);
     document.getElementById("disable").style.display=""
     document.getElementById("enable").style.display="none"
    this.edit.emit(data);
  }

}
