import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LookupColumnListModel } from '../models/lookupColumn.model';
import { environment } from 'src/environments/environment';
import { LookupColumnField } from '../models/lookupColumn.model';

@Injectable(
 {   providedIn: 'root'}
  )
export class LookupColumnService {

  constructor(private http: HttpClient) {}


  getLookupColumnDataByTable(lookupColumnData){
    console.log(lookupColumnData,' service getLookupColumnDataByTable')
    return this.http.post<LookupColumnListModel[]>(
      `${environment.baseUrl}/apis/lookup/getLookupListData`,lookupColumnData
    );  

  }

  getLookupColumnById(lookupColumnData){
    console.log(lookupColumnData,' service getLookColumnById')
    return this.http.post<LookupColumnField>(
      `${environment.baseUrl}/apis/lookup/getLookupListById`,lookupColumnData
    );  

  }

  saveLookupColumnData(lookupColumnData){
    console.log(lookupColumnData,' service saveLookupColumnData')
    return this.http.post<LookupColumnField>(
      `${environment.baseUrl}/apis/lookup/saveLookupList`,lookupColumnData
    );  
  }

  deleteLookupColumn(lookupColumnData){
    console.log("inside  deleteLookupColumn");
    return this.http.post<any>(environment.baseUrl + "/apis/lookup/deleteLookupListById",lookupColumnData);
  }

}
