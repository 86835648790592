import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestService } from 'src/app/dashboard/services/request.service';

@Component({
  selector: 'app-request-table',
  templateUrl: './request-table.component.html',
  styleUrls: ['./request-table.component.scss']
})
export class RequestTableComponent implements OnInit {
 @Input() requestList:any[];
 @Input() messageList: any;
 @Input() rolePermission:any;
 @Output() showMoreInfo = new EventEmitter();
 @Output() approveReject = new EventEmitter();
 @Output() getMessages = new EventEmitter();

 dataValue:any;
 visibleApprove: boolean = false;
 visibleReject: boolean = false;
 selectedIndex = -1;
 userRequestRow: any;

  constructor() { }

  ngOnInit(): void {
    
  }

  showMore(rowData){
    //console.log("more info on table: ",JSON.parse(rowData.requestPayload));

    this.showMoreInfo.emit(rowData);
  }

  showPopupApprove(rowData) {
    this.dataValue = rowData;
    this.visibleApprove = true;
  }

  showPopupReject(rowData) {
    this.dataValue = rowData;
    this.visibleReject = true;
  }

  hidePopup() {
    this.dataValue = null;
    this.visibleApprove = false;
    this.visibleReject = false;
  }

  approve(data){
    const model = {
      "userRequestId" : data.userRequestId,
      "requestStatusTypeId" : 2
    }
    this.approveRejectRequest(model);
    this.dataValue=null;
    this.visibleApprove=false;
  }

  reject(data){
    const model = {
      "userRequestId" : data.userRequestId,
      "requestStatusTypeId" : 3
    }
    this.approveRejectRequest(model);
    this.dataValue=null;
    this.visibleReject=false;
  }

  approveRejectRequest(model){
    this.approveReject.emit(model);
  }

  // vieworkflowLogs(data){
  //   this.viewworkflowLog.emit(data);

  // }

  showLogTable(index: number, event: Event,rowData:any) {
    const ele = event.target as HTMLInputElement;
    if (ele.checked) {
      this.selectedIndex = index;
      this.userRequestRow=rowData;
      this.getMessages.emit(rowData.userRequestId);
    } else {
      this.selectedIndex = -1;
      this.userRequestRow='';
    }
  }

  replyMessage(data){
    console.log("data received on reply: ",data);
    this.showMore(data);

  }

}
