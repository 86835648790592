import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
//import { EventEmitter } from 'protractor';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { RequestSelectorComponent } from '../request-selector/request-selector.component';


@Component({
  selector: 'app-request-header',
  templateUrl: './request-header.component.html',
  styleUrls: ['./request-header.component.scss']
})
export class RequestHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(RequestSelectorComponent, { read: false, static: false })
  requestSelectorComponent: RequestSelectorComponent;

  @Input() selectedRow;
  @Input() previousList;
  @Input() newList;
  @Input() approveRejectVisible;
  @Input() messageBoxVisible;

  @Output() approveReject = new EventEmitter();
  @Output() getAllMessage = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  approveRejectFun(data){
    this.approveReject.emit(data);
    this.panelForm.toggleSlider();
  }

 openForm(){
  console.log("On header file this.previousList: ",this.previousList);
  console.log("On header file this.newList: ",this.newList)
  this.panelForm.toggleSlider();
 }

 getAllMessageFun(data){
  this.getAllMessage.emit(data);
 }

}
