import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableViewerService {
/**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http:HttpClient) { }

  getTables(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/table/getTables",data);
  }
  getAllTables(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/table/getAllTables",data);
  }
  getData(data){ 
    return this.http.post<any>(environment.baseUrl + "/apis/table/getTableColumnsAndData",data);
  }
  getColumns(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/table/getTableColumns",data);
  }
}
