import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SeverityService {
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}

  getAllKeyStressSevere(){
    return this.http.get<any[]>(
      environment.baseUrl + "/apis/getAllKeySevere"
    );
  }
  getAllStressSevere() {
    return this.http.get<any[]>(
      environment.baseUrl + "/apis/getAllSevere"
    );
  }

  updateStressSeverityStatus(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/updateKeySevere`, data
    );
  }
  getStressKeyMetricsDataByShocks(shockSelectKey){
    return this.http.get<any[]>(
      `${environment.baseUrl}/apis/getAllKeySevere?colName=`+shockSelectKey);
  }

  getStressSeverityDataByShocks(shockSelectKey){
    return this.http.get<any[]>(
      `${environment.baseUrl}/apis/getAllSevere?colName=`+shockSelectKey);
  }


}