import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormGroup,FormBuilder, FormControl,Validators } from '@angular/forms';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { Store } from '@ngrx/store';
import { SspApplicationModel } from 'src/app/selfServicePortal/model/selfServicePortal.model';
import { TableViewerService } from 'src/app/dashboard/services/table-viewer.service';
import { reMapForAutoCompleteMultiSelect } from 'src/app/utils/re-mapper-multiselect';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';


@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit,OnChanges {

  @Input() editUser; 
  @Output() saveUser = new EventEmitter();
  @Input() roleList;
  // @Input() clientLists;
  @Input() clientLists;
  @Input() applicationLists;
  @Input() clientApplicationDataTypeList;
  @Input() notificationList;
  @Input() reportList;
  @Input() groupList;
  @Input() applicationDataValues;
  @Input() reportView;
  @Input() reportViewDataList;
  @Input() allStatusData;
  @Input() locationList;
  @Input() departmentList;
 
  @Output() closePanel=new EventEmitter();
  databaseLists: {id:string; name:string} [] = [
    {id: "datamart", name:"DataMart"},
    {id: "datalake", name:"DataLake"}
  ];
  isChecked:boolean=false;
  uncheck:boolean;
  form:any=null; 
  myGroup :FormGroup;
  myGroup1 :FormGroup;
  myGroup2 :FormGroup;
  myGroup3 :FormGroup;
  myGroup4 :FormGroup;
  myGroup5 :FormGroup;
  isClientChecked:boolean=false;
  isApplicationChecked:boolean=false;
  isFileTypeChecked:boolean=false;
  isGroupChecked:boolean=false;
  isReportChecked:boolean=false;
  isReportViewChecked:boolean=false;
  

  @Input()   rolePermission;
  userForm: FormGroup=null;
  enableEdit:boolean=false;
  roleLists:{ key: string | number; value: string }[];
  clientList:{ key: string | number; value: string }[];
  roleId:number;
  selectedClients: { clientId: any; clientName: any }[] = [];
  selectedApplication: { applicationId: any; name: any }[] = [];
  selectedDatabases: { id: any; name: any }[] = [];
  selectedTablesList: {id:string, name:string} [] = [];
  selectedDataType: { clientapplicationfiledatatypeId: any; type: any }[] = [];
  selectedGroup: { groupId: any; groupName: any }[] = [];
  selectedReport: { appId: any; appName: any }[] = [];
  selectedReportView: { instanceId: any; instanceName: any }[] = [];
  selectedNotificationType: {notificationId: any;notificationName: any}[]=[];
  applicationList: { appId: any; appName: any }[] = [];
  reportViewList: { instanceId: any; instanceName: any }[] = [];
  applicationData:any[]=[];
  reportViewData:any[]=[];
  applicationDataValue:any[]=[];
  location: {locationId:string; locationName:string} [] = [];
  department: {departmentId:string; departmentName:string} [] = [];
  database: {id:string; name:string} [] = [];
  tablesList: {id:any, name:string} [] = [];
  reportListByClientId: any[];
  filteredReportList: any[];

  @Input() workSpaceList;
  
  constructor(private toast: ToastService,private sspApplicationService: SspApplicationService,private fb: FormBuilder,private storePermission: Store<{ role: any }>,private tableViewerService:TableViewerService) { 
    this.userForm = new FormGroup({
      userName: new FormControl(null,{ validators: [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(50)]}),
      firstName: new FormControl(null,{ validators: [Validators.required, Validators.minLength(5), Validators.maxLength(250)]}),
      lastName:new FormControl(null,{ validators: [Validators.required, Validators.minLength(5), Validators.maxLength(250)]}),
      roleId:new FormControl(null,Validators.required),
      statusMap: new FormControl(null,Validators.required),
      clients: new FormControl(null),
      applications: new FormControl(null),
      tables: new FormControl(null),
      fileDataType: new FormControl(null),
      group:new FormControl(null),
      report:new FormControl(null),
      reportView:new FormControl(null),
      noticationType: new FormControl(null),
     // userFlag:new FormControl(null),
      clientFlag:new FormControl(null),
      applicationFlag:new FormControl(null),
      fileTypeFlag:new FormControl(null),
      groupFlag:new FormControl(null),
      reportFlag:new FormControl(null),
      reportViewFlag:new FormControl(null),
      workspace:new FormControl(null,Validators.required),
      location:new FormControl(null,Validators.required),
      department:new FormControl(null,Validators.required),
    });
  }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.userForm.get('statusMap').setValue(defaultMap);


   this.myGroup = new FormGroup({
    checkClientFlag: new FormControl()
 });
 this.myGroup1 = new FormGroup({
  checkApplicationFlag: new FormControl()
});
this.myGroup2 = new FormGroup({
  checkFileTypeFlag: new FormControl()
});

this.myGroup3 = new FormGroup({
  checkGroupFlag: new FormControl()
});

this.myGroup4 = new FormGroup({
  checkReportFlag: new FormControl()
});

this.myGroup5 = new FormGroup({
  checkReportViewFlag: new FormControl()
});


this.setGroupFalse();
//this.storePermission.select(x => this.rolePermission = x).subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
  this.databaseLists = [
    {id: "datamart", name:"DataMart"},
    {id: "dataLake", name:"DataLake"}
  ];
  this.tableViewerService.getAllTables(changes.editUser.currentValue.database).subscribe(
    data => {
      this.tablesList = data.map(d => ({ id: d['tableDescription'], name: d['tableName'] }));
    },
    err => {
      console.log(err);
    }
  );
   
    if (changes && changes.editUser && changes.editUser.currentValue) {
     console.log(changes.editUser.currentValue,' indise changes') 
       const lookUp=changes.editUser.currentValue;
       this.enableEdit = true;
       this.checkForEdit() 
    }
       if (changes && changes.roleList && changes.roleList.currentValue) {
        this.roleLists = this.formatFields(changes.roleList.currentValue);  
        console.log("roles" +this.roleLists)  
   }

   if (changes && changes.clientLists && changes.clientLists.currentValue) {
    this.clientList = this.formatClientFields(changes.clientLists.currentValue);  
    console.log("client" +this.clientList)  
}

if (changes && changes.editUser && changes.editUser.currentValue) {
  this.userForm.get('workspace').setValue({
      key:changes.editUser.currentValue.workspace,
      value:changes.editUser.currentValue.workspace
  })
  console.log("client" +this.clientList)  
}
if (changes && changes.editUser && changes.editUser.currentValue) {
  this.userForm.get('department').setValue({
      key:changes.editUser.currentValue.userDepartmentMapping.key,
      value:changes.editUser.currentValue.userDepartmentMapping.value
  })
  console.log("client" +this.clientList)  
}
if (changes && changes.editUser && changes.editUser.currentValue) {
  this.userForm.get('location').setValue({
      key:changes.editUser.currentValue.userLocationMapping.key,
      value:changes.editUser.currentValue.userLocationMapping.value
  })
  console.log("client" +this.clientList)  
}


if (changes && changes.editUser && changes.editUser.currentValue) {
  let currentChangeValue = changes.editUser.currentValue;
  let clientEditList = currentChangeValue.clientsMappingList
  let applicationEditList = currentChangeValue.applicationMappingList
  let dataTypeEditList = currentChangeValue.datatypeMappingList
  let groupEditList = currentChangeValue.groupMappingList
  let reportEditList = currentChangeValue.reportMappingList
  let reportViewEditList = currentChangeValue.reportViewMappingList
  let notificationEditList=currentChangeValue.notificationMappingList
  let databasesEditList=currentChangeValue.database;
  let tableEditList=currentChangeValue.tables;
  console.log("clientEditList  ",clientEditList)
   this.selectedClients = [];
   this.selectedApplication=[];
   this.selectedDataType=[];
   this.selectedGroup=[];
   this.selectedReport=[];
   this.selectedReportView=[];
   this.selectedNotificationType=[];
   this.selectedDatabases=[];
   this.selectedTablesList=[];
  for (var i = 0; i < clientEditList.length; i++) {
    this.selectedClients.push({ clientId: clientEditList[i].clientId, clientName: clientEditList[i].clientName });
    console.log("Selected Clients Are:", this.selectedClients);
  }

  for (var i = 0; i < applicationEditList.length; i++) {
    this.selectedApplication.push({ applicationId: applicationEditList[i].applicationId, name: applicationEditList[i].name });
    console.log("selectedApplication Are:", this.selectedApplication);
  }
  for (var i = 0; i < databasesEditList.length; i++) {
    let name="DataLake";
    if(databasesEditList[i]!="dataLake") {
      name="DataMart";
    }
    this.selectedDatabases.push({ id: databasesEditList[i], name: name });
  }
  console.log("selectedDatabases Are:", this.selectedDatabases);
  for (var i = 0; i < tableEditList.length; i++) {
    this.selectedTablesList.push({ id: tableEditList[i].id, name: tableEditList[i].name });
  }
  console.log("selectedTablesList Are:", this.selectedTablesList);

  for (var i = 0; i < dataTypeEditList.length; i++) {
    this.selectedDataType.push({ clientapplicationfiledatatypeId: dataTypeEditList[i].clientapplicationfiledatatypeId, type: dataTypeEditList[i].type });
    console.log("selectedDataType Are:", this.selectedDataType);
  }

  if(groupEditList!==null){
  for (var i = 0; i < groupEditList.length; i++) {
    this.selectedGroup.push({ groupId: groupEditList[i].groupId, groupName: groupEditList[i].groupName });
    console.log("selectedDataType Are:", this.selectedGroup);
  }
  this.getApplicationData();
}
if(reportEditList!==null){
  for (var i = 0; i < reportEditList.length; i++) {
    this.selectedReport.push({ appId: reportEditList[i].appId, appName: reportEditList[i].appName });
    console.log("selectedDataType Are:", this.selectedReport);
  }
  this.updateReportViewData(this.reportView,this.selectedReport);
}

if(reportViewEditList!==null){
  for (var i = 0; i < reportViewEditList.length; i++) {
    this.selectedReportView.push({ instanceId: reportViewEditList[i].instanceId, instanceName: reportViewEditList[i].instanceName });
    console.log("selectedDataType Are:", this.selectedReport);
  }
}

  // let getAssignAll=currentChangeValue.assignAll
  // if(getAssignAll===true){
  //   this.isChecked=true;
  //   this.myGroup.get('checkFlag').setValue(true);
  // }
  // else{
  //   this.isChecked=false;
  //   this.myGroup.get('checkFlag').setValue(false)
  // }
 
  for (var i = 0; i < notificationEditList.length; i++) {
    this.selectedNotificationType.push({ notificationId: notificationEditList[i].notificationId, notificationName: notificationEditList[i].notificationName });
    console.log("selectedNotificationType Are:", this.selectedNotificationType);
  }



  let getAssignAllClient=currentChangeValue.assignAllClients
  let getAssignAllApplications=currentChangeValue.assignAllApplications
  let getAssignAllDataType=currentChangeValue.assignAllDataTypes
  let getAssignAllGroup=currentChangeValue.assignAllGroups
  let getAssignAllReport=currentChangeValue.assignAllReports
  let getAssignAllReportView=currentChangeValue.assignAllReportViews

  if(getAssignAllClient===true){
    this.isClientChecked=true;
    this.myGroup.get('checkClientFlag').setValue(true);

  }
  else{
    this.isClientChecked=false;
    this.myGroup.get('checkClientFlag').setValue(false);
  }

  if(getAssignAllApplications===true){
    this.isApplicationChecked=true;
    this.myGroup1.get('checkApplicationFlag').setValue(true);

  }
  else{
    this.isApplicationChecked=false;
    this.myGroup1.get('checkApplicationFlag').setValue(false);
  }


  if(getAssignAllDataType===true){
    this.isFileTypeChecked=true;
    this.myGroup2.get('checkFileTypeFlag').setValue(true);

  }
  else{
    this.isFileTypeChecked=false;
    this.myGroup2.get('checkFileTypeFlag').setValue(false);
  }

  if(getAssignAllGroup===true){
    this.isGroupChecked=true;
    this.myGroup3.get('checkGroupFlag').setValue(true);
    this.applicationList=this.reportList
    this.reportViewList=[];
    this.reportViewData=[] 
   this.ViewReportListOnTrue(this.isGroupChecked,this.isReportChecked);

  }
  else{
    this.isGroupChecked=false;
    this.myGroup3.get('checkGroupFlag').setValue(false);
    this.reportViewList=[];
    this.reportViewData=[];
    this.ViewReportListOnGroupNotTrue(this.isGroupChecked,this.isReportChecked);
  }

   if(getAssignAllReport===true){
    this.isReportChecked=true;
    this.myGroup4.get('checkReportFlag').setValue(true);
    this.reportViewList=[];
    this.reportViewData=[]; 
   this.ViewReportListOnTrue(this.isGroupChecked,this.isReportChecked);

  }
  else{
    this.isReportChecked=false;
    this.myGroup4.get('checkReportFlag').setValue(false);
    this.reportViewList=[];
    this.reportViewData=[];
    if(this.selectedReport!=null){
      this.viewReportOnTrueGroupFalseReport(this.isGroupChecked,this.isReportChecked,this.selectedReport)

    } 
  }

  if(getAssignAllReportView===true){
    this.isReportViewChecked=true;
    this.myGroup5.get('checkReportViewFlag').setValue(true);

  }
  else{
    this.isReportViewChecked=false;
    this.myGroup5.get('checkReportViewFlag').setValue(false);
  }

  this.userForm.patchValue({
    
     clients: this.selectedClients,
     applications: this.selectedApplication,
     fileDataType: this.selectedDataType,
     group:this.selectedGroup,
     report:this.selectedReport,
     reportView:this.selectedReportView,
     noticationType:this.selectedNotificationType,
     tables: this.selectedTablesList
   });
  }


    }
    // if (changes && changes.newRule && changes.newRule.currentValue > 1) {
    //   this.resetForm();
    // }
  

    multiClientSelectChange(eventData) {
      this.selectedClients = eventData.value;
      console.log("Client Value Updated Is:", eventData.value);
      this.groupsByClientId(this.selectedClients);
      this.reportsByClientId(this.selectedClients);
    }

    reportsByClientId(selectedClients){
      this.sspApplicationService.getReportByClientId(selectedClients).subscribe((data) => {
        //console.log("report list by clientId: ",data);
        this.reportList=data;
        
      });
    }

    groupsByClientId(selectedClients){
      this.sspApplicationService.getReportGroupByClientId(selectedClients).subscribe((data) => {
        //console.log("group list by clientId: ",data);
        this.groupList= this.reMapForGroupeMultiSelect( data, "groupId", "groupName");
      });
    }

    reMapForGroupeMultiSelect(

      data: any[],
      keyCol: string,
      valCol: string
    ): {groupId: string | number; groupName: string }[] {
      return data.map(d => ({ groupId: d[keyCol], groupName: d[valCol] }));
  
  
    }
  
    multiApplicationSelectChange(eventData) {
      this.selectedApplication = eventData.value;
      console.log("Application Value Updated Is:", this.selectedApplication);
    }

    multiTablesSelectChange(eventData) {
      this.selectedTablesList = eventData.value;
      console.log("tables Value Updated Is:", this.selectedTablesList);
    }

    multiDatabaseSelectChange(eventData) {
      this.selectedDatabases = eventData.value;
      let db = [];
      this.selectedTablesList=[];
      this.selectedDatabases.forEach((value) => db.push(value.id));
      console.log("Databases:", db);
      this.tableViewerService.getAllTables(db).subscribe(
        data => {
          this.tablesList = data.map(d => ({ id: d['tableDescription'], name: d['tableName'] }));
        },
        err => {
          console.log(err);
        }
      );
    }
  
    multiDataTypeSelectChange(eventData) {
      this.selectedDataType = eventData.value;
      console.log("Datatype Value Updated Is:", this.selectedDataType);
    }

    multiGroupSelectChange(eventData) {
      // if(this.selectedGroup==null){
      //   this.selectedGroup = eventData.value;
      // }
      // this.selectedReport=[];
      // this.selectedReportView=[];
      // this.updateReportDate(this.reportList,this.selectedGroup);
      // this.reportViewList=[];
      // this.reportViewData=[]; 
      // this.ViewReportListOnGroupNotTrue(this.isGroupChecked,this.isReportChecked);
      // console.log("Group Value Updated Is:", this.selectedDataType);
      this.selectedGroup=eventData.value;
      //console.log("this.selectedGroup: ",this.selectedGroup);
      this.applicationList = this.reportList.filter(report =>{
        return this.selectedGroup.some(group => group.groupId===report.groupId);
      });
      
    }

    multiReportSelectChange(eventData) {
      if(this.selectedReport==null){
        this.selectedReport = eventData.value;
      }
      this.selectedReportView=[];
      this.updateReportViewData(this.reportView,this.selectedReport);
      console.log("Report Value Updated Is:", this.selectedReport);
    }

    multiReportViewSelectChange(eventData) {
      this.selectedReportView = eventData.value;
      console.log("Report Value Updated Is:", this.selectedReportView);
    }

    multiNotificationTypeSelectChange(eventData){
      this.selectedNotificationType = eventData.value;
      console.log("Datatype Value Updated Is:", this.selectedNotificationType);

    }


  checkForEdit() {
    const RoleData = {
      key: this.editUser.roleId,
      value:this.editUser.roleName,
    };

    const ClientData = {
      key: this.editUser.clientId,
      value:this.editUser.clientName,
    };

    const statusActive = {
      key: "Active",
      value:"Active",
    };
    const statusInactive = {
      key: "Inactive",
      value:"Inactive",
    };
    if (this.editUser && this.enableEdit) { 
      //console.log("edit user data"+this.editUser)
     if(this.editUser.status==0){
      this.userForm.patchValue({
        userName: this.editUser.userName,
        firstName: this.editUser.firstName,
        lastName: this.editUser.lastName,
        roleId: RoleData,
        statusMap:statusInactive,
        clientId:ClientData
      });
     }else{
      this.userForm.patchValue({
        userName: this.editUser.userName,
        firstName: this.editUser.firstName,
        lastName: this.editUser.lastName,
        roleId: RoleData,
        statusMap:statusActive,
        clientId:ClientData
      });
    }
     
      this.enableEdit = false;
     
    }
  }

  createUser(){
  console.log("user form:",this.userForm)
  this.closePanel.emit();
  if(this.userForm.controls.userName.status==="INVALID" && this.userForm.controls.userName.value===null && this.userForm.controls.firstName.status==="INVALID" && this.userForm.controls.firstName.value===null && this.userForm.controls.lastName.status==="INVALID" && this.userForm.controls.lastName.value===null && this.userForm.controls.statusMap.status==="INVALID" && this.userForm.controls.statusMap.value===null && this.userForm.controls.roleId.status==="INVALID" && this.userForm.controls.roleId.value===null && this.userForm.controls.workspace.status==="INVALID" && this.userForm.controls.workspace.value===null && this.userForm.controls.location.status==="INVALID" && this.userForm.controls.location.value===null && this.userForm.controls.department.status==="INVALID" && this.userForm.controls.department.value===null){
    this.toast.showError('Please Fill Required Fields','error');
    return;
  }
  if(this.userForm.controls.userName.status==="INVALID" && (this.userForm.controls.userName.value!=null || this.userForm.controls.userName.value===null)){
    this.toast.showWarn('User Name is mandatory & should be in email format','error');
    return;
  }
  if(this.userForm.controls.firstName.status==="INVALID" && (this.userForm.controls.firstName.value!=null || this.userForm.controls.firstName.value===null)){
    this.toast.showWarn('First Name is required with its Specific Limits.','error');
    return;
  }
  if(this.userForm.controls.lastName.status==="INVALID" && (this.userForm.controls.lastName.value!=null || this.userForm.controls.lastName.value===null)){
    this.toast.showWarn('Last Name is required with its Specific Limits.','error');
    return;
  }
  if(this.userForm.controls.statusMap.value==="Select Status *"){
    this.toast.showWarn('Status is required to select','error');
    return;
  }
  if(this.userForm.controls.roleId.value==="Select Role *"){
    this.toast.showWarn('Role is required to select','error');
    return;
  }
  if(this.userForm.controls.workspace.value==="Select Workspace *"){
    this.toast.showWarn('Workspace is required to select','error');
    return;
  }
  if(this.userForm.controls.location.value==="Select Location *"){
    this.toast.showWarn('Location is required to select','error');
    return;
  }
  if(this.userForm.controls.department.value==="Select Department *"){
    this.toast.showWarn('Department is required to select','error');
    return;
  }
  if(this.userForm.controls.statusMap.status==="INVALID" && (this.userForm.controls.statusMap.value!=null || this.userForm.controls.statusMap.value===null)){
    this.toast.showWarn('Status is required to select','error');
    return;
  }
  if(this.userForm.controls.roleId.status==="INVALID" && (this.userForm.controls.roleId.value!=null || this.userForm.controls.roleId.value===null)){
    this.toast.showWarn('Role is required to select','error');
    return;
  }
  if(this.userForm.controls.workspace.status==="INVALID" && (this.userForm.controls.workspace.value!=null || this.userForm.controls.workspace.value===null)){
    this.toast.showWarn('Workspace is required to select','error');
    return;
  }
  if(this.userForm.controls.location.status==="INVALID" && (this.userForm.controls.location.value!=null || this.userForm.controls.location.value===null)){
    this.toast.showWarn('Location is required to select','error');
    return;
  }
  if(this.userForm.controls.department.status==="INVALID" && (this.userForm.controls.department.value!=null || this.userForm.controls.department.value===null)){
    this.toast.showWarn('Department is required to select','error');
    return;
  }

  if(this.userForm.invalid){
    if(this.userForm.controls.userName.invalid){
      this.toast.showWarn('username mandatory & should be in email format','error');
      return;
    }
    this.toast.showWarn('All fields are mandatory ','error');
    return;
  }


// this.userForm.patchValue({

//   userFlag:false,

// });

this.userForm.patchValue({

  clientFlag:this.myGroup.get('checkClientFlag').value,

});

this.userForm.patchValue({

  applicationFlag:this.myGroup1.get('checkApplicationFlag').value,

});

this.userForm.patchValue({

  fileTypeFlag:this.myGroup2.get('checkFileTypeFlag').value,

});

this.userForm.patchValue({

groupFlag:this.myGroup3.get('checkGroupFlag').value,

});

this.userForm.patchValue({

  reportFlag:this.myGroup4.get('checkReportFlag').value,

});

this.userForm.patchValue({

  reportViewFlag:this.myGroup5.get('checkReportViewFlag').value,

});

  this.userForm.patchValue({
     
    clients: this.selectedClients,
   
  });

  this.userForm.patchValue({
   
    applications: this.selectedApplication,
   
  });

  this.userForm.patchValue({
     
    fileDataType: this.selectedDataType,
   
  }); 

  this.userForm.patchValue({
     
    group: this.selectedGroup,
   
  }); 

   this.userForm.patchValue({
     
    report: this.selectedReport,
   
  }); 

  this.userForm.patchValue({
     
    reportView: this.selectedReportView,
   
  }); 

  this.userForm.patchValue({
     
    noticationType: this.selectedNotificationType,
   
  }); 

  this.userForm.patchValue({
    tables: this.selectedTablesList
  });

  
    this.saveUser.emit(this.userForm.value)
   

  }

  formatFields(roleList) {
    return reMapForAutoComplete(roleList, "roleId", "roleName");
  }

  formatClientFields(clientList) {
    return reMapForAutoComplete(clientList, "clientId", "clientName");
  }

  get status() {
    const status = [
      "Active",
      "Inactive",
    ];
   return status.map((t) => ({ key: t, value: t }));
  }

  resetForm() {
    console.log("reset is working ")
    this.userForm.reset();
     this.selectedClients = [];
     this.selectedApplication=[];
     this.selectedDataType=[];
     this.selectedGroup=[];
     this.selectedReport=[];
     this.selectedReportView=[];
     this.selectedNotificationType=[];
     this.selectedDatabases=[];
     this.selectedTablesList=[];
  }

  // checkedFlag(event){

  
  //   console.log("is checked  ",event.target.checked)
  //   if(event.target.checked){

  //   this.isChecked=true;

  //   this.selectedClients = [];
  //   this.selectedApplication=[];
  //   this.selectedDataType=[];

  //   }
  //   else{
  //     this.isChecked=false;

    
  //   }
  //   console.log("this.isChecked ",this.isChecked)
  // }

  resetCheckedFlag(){


    this.isClientChecked=false;
    this.isApplicationChecked=false;
    this.isFileTypeChecked=false;
    this.isGroupChecked=false;
    this.isReportChecked=false;
    this.isReportViewChecked=false;
    // this.myGroup.get('checkFlag').setValue(false)
    this.myGroup.get('checkClientFlag').setValue(false);
    this.myGroup1.get('checkApplicationFlag').setValue(false);
    this.myGroup2.get('checkFileTypeFlag').setValue(false);
    this.myGroup3.get('checkGroupFlag').setValue(false);
    this.myGroup4.get('checkReportFlag').setValue(false);
    this.myGroup5.get('checkReportViewFlag').setValue(false);

    
  }

  checkedClientFlag(event){
    if(event.target.checked){
    this.isClientChecked=true;
    this.selectedClients = this.clientLists;
    this.groupsByClientId(this.selectedClients);
    this.reportsByClientId(this.selectedClients);
  }
  else{
    this.isClientChecked=false;
    this.selectedClients = [];
    this.groupList=[];
    this.applicationList=[];

  }
}

  checkedApplicationFlag(event)
  {
    if(event.target.checked){
    this.isApplicationChecked=true;
    this.selectedApplication=[];
    }
    else{
      this.isApplicationChecked=false;
    }

  }

  checkedFileTypeFlag(event){
    if(event.target.checked){
    this.isFileTypeChecked=true;
    this.selectedDataType=[];

    }
    else{
      this.isFileTypeChecked=false;
    }

  }

  checkedGroupFlag(event){
    if(event.target.checked){
    this.isGroupChecked=true;
    this.selectedGroup=[];
   this.applicationList=this.reportList
   this.reportViewList=[];
    this.reportViewData=[] 
   this.ViewReportListOnTrue(this.isGroupChecked,this.isReportChecked);
    }
    else{
      this.isGroupChecked=false;
      this.reportViewList=[];
      this.reportViewData=[];
      this.applicationList=[]; 
    }

  }

  checkedReportFlag(event){
    if(event.target.checked){
    this.isReportChecked=true;
    this.selectedReport=[];
    this.reportViewList=[];
    this.reportViewData=[] 
   this.ViewReportListOnTrue(this.isGroupChecked,this.isReportChecked);
   this.ViewReportListOnGroupNotTrue(this.isGroupChecked,this.isReportChecked);
    }
    else{
      this.isReportChecked=false;
      if(this.isGroupChecked){
        console.log("this.reportList   ",this.reportList)
      this.applicationList=this.reportList;
      }
      else{
        if(this.selectedGroup===null){
        this.applicationList=[]; 
      }
      }
     // this.updateReportViewData(this.reportView,this.selectedReport);
     this.reportViewList=[];
     this.reportViewData=[]
 
    }

  }

  ViewReportListOnTrue(isGroupChecked,isReportChecked){

    if(isGroupChecked){
      if(isReportChecked){
        console.log("this.applicationList   ",this.reportList)
        console.log("this.reportViewDataList   ",this.reportViewDataList)
      for(let j=0;j<this.reportList.length;j++){
        for(let i=0;i<this.reportViewDataList.length;i++){
            if(this.reportList[j].appId===this.reportViewDataList[i].appId){
              this.reportViewData.push({ instanceId: this.reportViewDataList[i].instanceId, instanceName: this.reportViewDataList[i].instanceName });
            }
        }
        }
        this.reportViewList=this.reportViewData;
        console.log("this.reportViewList   ",this.reportViewList)
      }
    }


  }
  ViewReportListOnGroupNotTrue(isGroupChecked,isReportChecked){

    if(!isGroupChecked){
      if(this.selectedGroup!=null){
      if(isReportChecked){
        this.applicationData=[];this.applicationList=[];
        for(let j=0;j<this.selectedGroup.length;j++){
        for(let i=0;i<this.applicationDataValues.length;i++){
            if(this.selectedGroup[j].groupId===this.applicationDataValues[i].groupId){
              this.applicationData.push({ appId: this.applicationDataValues[i].appId, appName: this.applicationDataValues[i].appName });
            }
        }
        }
        this.applicationList=this.applicationData;
    
        // this.userForm.patchValue({


        console.log("this.applicationList   ",this.applicationList)
        console.log("this.reportViewDataList   ",this.reportViewDataList)
      for(let j=0;j<this.applicationList.length;j++){
        for(let i=0;i<this.reportViewDataList.length;i++){
            if(this.applicationList[j].appId===this.reportViewDataList[i].appId){
              this.reportViewData.push({ instanceId: this.reportViewDataList[i].instanceId, instanceName: this.reportViewDataList[i].instanceName });
            }
        }
        }
        this.reportViewList=this.reportViewData;
        console.log("this.reportViewList   ",this.reportViewList)
      }
    }
    }


  }


   viewReportOnTrueGroupFalseReport(isGroupChecked,isReportChecked,SelectReport){
    if(isGroupChecked || !isGroupChecked){
    
      if(!isReportChecked){
        if(SelectReport!=null){

          console.log("this.applicationList   ",SelectReport)
          console.log("this.reportViewDataList   ",this.reportViewDataList)
        for(let j=0;j<SelectReport.length;j++){
          for(let i=0;i<this.reportViewDataList.length;i++){
              if(SelectReport[j].appId===this.reportViewDataList[i].appId){
                this.reportViewData.push({ instanceId: this.reportViewDataList[i].instanceId, instanceName: this.reportViewDataList[i].instanceName });
              }
          }
          }
          this.reportViewList=this.reportViewData;
          console.log("this.reportViewList   ",this.reportViewList)

        }

      }}

   }
   



  checkedReportViewFlag(event){
    if(event.target.checked){
    this.isReportViewChecked=true;
    this.selectedReportView=[];

    }
    else{
      this.isReportViewChecked=false;
    }

  }




  setGroupFalse(){

    this.myGroup.get('checkClientFlag').setValue(false);
    this.myGroup1.get('checkApplicationFlag').setValue(false);
    this.myGroup2.get('checkFileTypeFlag').setValue(false);
    this.myGroup3.get('checkGroupFlag').setValue(false);
    this.myGroup4.get('checkReportFlag').setValue(false);

  }

  updateReportDate(reportList,selectedGroup){
   this.applicationData=[];this.applicationList=[];
    for(let j=0;j<this.selectedGroup.length;j++){
    for(let i=0;i<this.applicationDataValues.length;i++){
        if(this.selectedGroup[j].groupId===this.applicationDataValues[i].groupId){
          this.applicationData.push({ appId: this.applicationDataValues[i].appId, appName: this.applicationDataValues[i].appName });
        }
    }
    }
    this.applicationList=this.applicationData;

    // this.userForm.patchValue({
    //   report:this.selectedReport,
    // });
    
    console.log("report on change"+this.applicationList);
  }
  

getApplicationData(){
  this.applicationDataValue=[];this.applicationList=[];
  for(let j=0;j<this.selectedGroup.length;j++){
  for(let i=0;i<this.applicationDataValues.length;i++){
      if(this.selectedGroup[j].groupId===this.applicationDataValues[i].groupId){
        this.applicationDataValue.push({ appId: this.applicationDataValues[i].appId, appName: this.applicationDataValues[i].appName });
      }
  }
  }
  this.applicationList=this.applicationDataValue;
} 
 

updateReportViewData(reportViewList,selectedReport){
  this.reportViewData=[];this.reportViewList=[];
   for(let j=0;j<selectedReport.length;j++){
   for(let i=0;i<this.reportViewDataList.length;i++){
       if(selectedReport[j].appId===this.reportViewDataList[i].appId){
         this.reportViewData.push({ instanceId: this.reportViewDataList[i].instanceId, instanceName: this.reportViewDataList[i].instanceName });
       }
   }
   }
   this.reportViewList=this.reportViewData;

   // this.userForm.patchValue({
   //   report:this.selectedReport,
   // });
   
   console.log("report on change"+this.applicationList);
 }


}
