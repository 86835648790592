import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from "src/app/core/services/title.service";
import { Subscription } from "rxjs";
import { FormsModel } from "src/app/models/forms.model";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { ToastService } from "src/app/core/services/toast.service";
import { AuthService } from "../../services/auth.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { Store } from "@ngrx/store";
import { storePermission, storeUser } from "../../store/auth.action";
import { take } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { UserOtpModel,UserOtpResponseModel,UserOtpRequestModel } from 'src/app/dashboard/models/user.model';
import { isNgTemplate } from '@angular/compiler';
import { CookieService } from "ngx-cookie-service";
import { FileService } from "src/app/files/services/file.service";
import { ButtonPermissionModel } from "src/app/dashboard/models/button-permission.model";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  loginModel: FormsModel = null;
  activeRoute$: Subscription;
  visibleCheck:Boolean=false;
  visible:Boolean=false;
  dataValue:any;
  otpForm: FormGroup = null;
  userOtpRequestModel: UserOtpRequestModel;
  userOtpModel:UserOtpModel[]=[];
  userOtpResponseModel:UserOtpResponseModel;
  rolePermission:ButtonPermissionModel[];
  redirectPage:any;
  loginForm:FormGroup;
  isForgot:boolean=false;

 // uService$: Subscription;
  /**
   * @param  {TitleService} privatetitleService
   * @param  {ActivatedRoute} privateactiveRoute
   * @param  {Router} privaterouter
   * @param  {ToastService} privatetoast
   */
  constructor(
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService:UserService,
    private toast: ToastService,
    private store: Store<{ user: any }>,
    private cookieService:CookieService,
    private fileService:FileService,
    private storePermission: Store<{ role: any }>,
  ) {

    this.loginForm=new FormGroup({
email:new FormControl(null,{ validators: [Validators.required, Validators.email], updateOn: "blur" }),
password:new FormControl(null)

    })

    this.otpForm = new FormGroup({
      loginOtp: new FormControl(null),
    });

    this.loginModel = {
      controls: [
        {
          label: "Email",
          type: "email",
          name: "email",
          validators: [Validators.required],
        },
        {
          label: "Password",
          type: "password",
          name: "password",
          validators: [Validators.required],
        },
      ],
    };
  }

 

  ngOnInit(): void {
    this.setTitle();
    // this.checkUserStatus();///activate later
    // console.log("ngOnInit");
  }

  setTitle() {
    // console.log("setTitle");
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  checkUserStatus() {
    this.store.select("user").subscribe((u) => {
      // console.log("checkUserStatus u: ",u);
      if (u) {
       // console.log("user check 2",u)
        this.cookieService.set("userId",u); 
        this.redirectPage=u.routerLink;
        if (u.otpActiveStatus==1){
         //console.log("checkUserStatus :u true,printed : ", u.otpActiveStatus);
        this.visible=true;
        }else{
          //this.router.navigate(["/client-file"]); 
          this.router.navigate([this.redirectPage]);      
        }
          
      }
    });
  }
  /**
   * @param  {object} data
   */
  user:any;
  async doLogin() {
    //data: { email: string; password: string }
    console.log("this.loginForm.emial  ",this.loginForm.controls.email.value);
    console.log("this.loginForm.emial  ",this.loginForm.controls.password.value);
   console.log("this.loginForm.valid ",this.loginForm.valid)
   if(this.loginForm.valid){

      if(this.loginForm.controls.password.value == null || this.loginForm.controls.password.value == undefined){
        this.toast.showWarn('Please Enter Password',"");
        return;
      }
      
    try {
      // console.log("doLogin");

     // this.loader.showLoader();
      const { email, password } = this.loginForm.value;
      const u = await this.authService.emailLogin(email, password);
      console.log("U Recieved Is: " , u);
      this.checkUserStatus();//check and fix later
     // this.buttonShowHidePermission(u);
      // this.visible=true;//
      this.store.dispatch(storeUser({ data: u }));   
      this.store.select('user').pipe(take(1))
      .subscribe(
        u => {this.user = u;
          this.cookieService.set("userId",u.userId); 

          localStorage.setItem("asofdate",u.asofdate);
          localStorage.setItem("companyId",u.clientId);

          localStorage.setItem("stateflag",u.applicationId);
          localStorage.setItem("assignMultipleApplication",u.assignMultipleApplication);
          if(u.home){
            this.router.navigate(['/home-screen']);
          }else{
            this.router.navigate(['/report/dashboard']);
          }


          
         
         // this.router.navigate(['/dashboard']);
         // console.log("user check ",this.user)
          // this.visible=true;
          // console.log("inside doLogin suscribe");
        }) ;
        this.getUserDetails();
      // console.log("outside doLogin suscribe method");


     // Activate later
      // if(this.user.clientId===0){
      //   this.router.navigate(["client-file"]);
      // }
      // if(this.user.clientId!==0){
      //   this.router.navigate(["client-portal"]);
      // }
        
     // await this.authService.emailLogin(email, password);
     // this.router.navigate(["client-file"]);
     //  this.loader.hideLoader();
    // localStorage.setItem("stateflag",'true');
    } catch (e) {
      this.toast.showError("Login Error", e.error.message);
     // this.loader.hideLoader();
    }
  }
  }
  
  ngOnDestroy() {
    this.activeRoute$.unsubscribe();
   // this.uService$.unsubscribe();
  }

  toggleIsForgot() {
    this.isForgot = !this.isForgot;
  }

   forgotPassword() {
      const email = this.loginForm.controls.email.value;
      const userData={
        "userName":email,
        "email":email
       }
      this.authService.forgotPassword(userData).subscribe(
        (data) => {
          const dataVlue=data;
          this.toast.showSuccess("success", "Password has been reset and sent to your email address. Please check your mail for new password");      
          this.isForgot=false;
        },
        (err) => 
        this.toast.showError("error", err.error.message)
      );
      
      // this.store.dispatch(storeUser({ data: u }));
      // this.router.navigate(["client-file"]);
      // // await this.authService.emailLogin(email, password);
      // this.router.navigate(["client-file"]);
     //  this.loader.hideLoader();
    
  }

  showForgetPopUp(data){
    console.log("dataForget ",data);
    this.dataValue=data;
    this.visibleCheck=true;
  }

  hidePopup(){
    this.dataValue=null;
    this.visibleCheck=false;
  }

  hidePopup2(){
    this.dataValue=null;
    this.visible=false;
    this.otpForm.reset();
  }

  verifyLoginOtp(){
    //  this.userOtpModel=[];
    this.userOtpRequestModel;
    if(this.otpForm.controls.loginOtp.value===null){
      this.toast.showInfo('error','enter otp')
      return;
    }
    
    const userOtp={
        "loginOtp":this.otpForm.controls.loginOtp.value,
       }

       let OtpStatus=0;
    // console.log("verify OTP button clicked",userOtp);

      this.userService.verifyLoginOtp(userOtp)
      .subscribe(
        data=>{
          // console.log("data: apicall Suscription",data);
          // console.log(" data.status   ",data.status );
          // this.userOtpResponseModel=data;

          if(data.status==1){
             console.log("OTP validated");
            this.toast.showSuccess('success','Login successful');
            if(this.user.clientId===0){
              this.router.navigate([this.redirectPage]);
            }
            if(this.user.clientId!==0){
              this.router.navigate([this.redirectPage]);
            }
           // this.router.navigate(["/client-file"]);       
          }else{
              this.toast.showError('error',data.msg.toString());
          }
        }, 
        err=>{
          this.toast.showError("error", err.error.message);
          // this.userOtpResponseModel=data;
        }
        
      );

  }

  onEnterPress(e){
    // console.log(e);
    // console.log(this.otpForm.controls.loginOtp.value)
    
    if(e.keyCode === 13){
      if(this.otpForm.controls.loginOtp.value===null){
        this.toast.showInfo('error','enter otp')
        return;
      }
      this.verifyLoginOtp();
   }
  }


  getUserDetails(){

    this.fileService.getUserById().subscribe(
      (res) =>{ 
        this.cookieService.set("adminFlag",res.adminFlag);
      },
        err=>{
          //this.toast.showError("error", err.error.message);
          // this.userOtpResponseModel=data;
        }

    )
  }

  buttonShowHidePermission(user){
    this.fileService .buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           const permission=this.rolePermission;
           this.storePermission.dispatch(storePermission({permission: this.rolePermission})); 
           this.storePermission.select(x => this.dataValue = x).subscribe();
           console.log(this.dataValue)
   }
    )};
}
