import { Component, OnInit ,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { ClientService } from '../../services/client.service';
import { Subscription } from 'rxjs';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlMappingService } from '../../services/gl-mapping.service';
import { DatePipe } from '@angular/common';
import { GlMappingSelectorComponent } from 'src/app/glMappingComp/components/gl-mapping-selector/gl-mapping-selector.component';
import { FileService } from 'src/app/files/services/file.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';

@Component({
  selector: 'app-gl-mapping',
  templateUrl: './gl-mapping.component.html',
  styleUrls: ['./gl-mapping.component.scss']
})
export class GlMappingComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;


  @ViewChild(GlMappingSelectorComponent, { read: false, static: false })
  glMappingSelectorComponent: GlMappingSelectorComponent;

  activeRoute$: Subscription;
  clientsList:{key: any; value: any }[]=[];
  modalForm: FormGroup = null;
  companyName:String;
  adminFlag:String;
  glMappingList:any[];
  glHeadList:{key:string|number;value:string}[]=[];
  keyMeasureHeadList:{key:string|number;value:string}[]=[];

  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  editGlMappings:any[];
  companyId:any;
  calculationTypeList: {key: any; value: any }[]=[];
  isCalculationList: {key: any; value: any }[]=[];
  KeymeasuresDataList: {key: any; value: any }[]=[];
  state:string;
  menu:string;
  submenu:string;  
  constructor(private titleService: TitleService,
    private activeRoute: ActivatedRoute,private router: Router,private clientService:ClientService
    ,private toast:ToastService,private cookieService: CookieService,private glMappingService:GlMappingService, private datePipe: DatePipe,
    private fileService: FileService,private sspapplicationService:SspApplicationService) {


      this.modalForm = new FormGroup({
        client: new FormControl(null,{validators:[Validators.required]}),
      });

      this. searchByCols = [
     
        { field: "glHeadName", header: "Gl Head Name" },
        { field: "keymeasuresheadName", header: "Key Measure Head" },
        { field: "calculationType", header: "calculation_type" },
      ];
      this.menu=localStorage.getItem("menu");
      this.submenu=localStorage.getItem("submenu");
      this.state=localStorage.getItem("stateflag");
     }

  ngOnInit(): void {
    this.adminFlag=this.cookieService.get("adminFlag");
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );
  
    this.setTitle();
    this.getClients();
    this.getAllKeyMeasureHead();
    this.getcalculationTypeList();
    this.iscalculatedList();
    this.getKeymeasuresDataList();

    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
        for(var i=0;i<data.length;i++){
          console.log("data  ",data[i].clientId)
          this.companyId=data[i].clientId
          this.companyName=data[i].clientName
        }
        this.modalForm.get("client").setValue({
          key: this.companyId,
          value: this.companyName
        });

        

       console.log("this.headerForm.controls.fromDate.value ",this.modalForm.value)

       //this.onClientChange(this.headerForm.controls.client.value);
        this.clientSelected('onInit');

      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
      );


  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  editMapping(data){
  this.editGlMappings=data;
this.panelForm.toggleSlider();
  }


  addNewGlHead(){

   this.panelForm.toggleSlider();
  }


  getClients() {  
    this.clientService.getAllClients().subscribe(

      (data) => {  
        this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");

        console.log(this.clientsList)

      },

      (err) => {

        this.toast.showError(err.error.message, "error");

      }

    );

  }

  clientSelected(flag) {
   
    this.cookieService.set('clientId',this.modalForm.controls.client.value.key);
    this.cookieService.set('clientName',this.modalForm.controls.client.value.value);
  if(this.modalForm.controls.client.value.key!=undefined || this.modalForm.controls.client.value.key!=null){
    this.companyName=this.modalForm.controls.client.value.value;
   // this.getAllGlHeadList();
    this.getGlMappIngDataByClientId(this.modalForm.controls.client.value.key);
    this.getGlHeadDataByClientId(this.modalForm.controls.client.value.key);
  }else{
    this.toast.showInfo("Info", "Please Select Company");
  }
    if(flag=='onLoad'){
      this.panelFilter.toggleSlider();
    }
  }


  getGlMappIngDataByClientId(clientId){

    this.glMappingService.getAllGlMappingList(clientId).subscribe(

      data=>{
        this.glMappingList=data;
        this.glMappingList.forEach(item=>{
          item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
          item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM/dd/yyyy")
         })
        console.log("this.glMappingList-----",this.glMappingList)
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        
      }
    )

  }

  
  getGlHeadDataByClientId(clientId){

    this.glMappingService.getGLHeadListByClienId(clientId).subscribe(

      data=>{
        this.glHeadList=reMapForAutoComplete(data,"glheadId","glHeadNname");
      
        console.log("this.glHeadList-----",this.glHeadList)
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        
      }
    )

  }



  getAllKeyMeasureHead(){

    this.glMappingService.getAllKeyMeasureHeadList().subscribe(

      data=>{
        this.keyMeasureHeadList=reMapForAutoComplete(data,"keymeasuresheadId","name");
      
        console.log("this.keyMeasureHeadList-----",this.keyMeasureHeadList)
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        
      }
    )

  }

  // calculatedList() {
  //   const iscalculatedList = [
  //     "Y",
  //     "N",
  //   ];
  //   return iscalculatedList.map((t) => ({ key: t, value: t }));
  // }

  
  //  KeymeasuresDataList() {
  //   const isKeymeasuresDataList = [
  //     "Y",
  //     "N",
  //   ];
  //   return isKeymeasuresDataList.map((t) => ({ key: t, value: t }));
  // }

   getcalculationType() {
    const calculationTypeList = [
      "EXPR",
      "SP",
    ];
    return calculationTypeList.map((t) => ({ key: t, value: t }));
  }

  createGLMapping(data){

    if(this.modalForm.controls.client.value.key==null || this.modalForm.controls.client.value.key==undefined || this.modalForm.controls.client.value.key==0){
        this.toast.showWarn('Select Client is mandatory','error');
        return;
      
    }
    const model={
      clientId:this.modalForm.controls.client.value.key,
      glMappingId:data.glMappingId!==null?data.glMappingId:0,

      glHeadId:data.isKeymeasuresData.key==='Y' || data.calculationType.key==='SP'?644:data.glHead.key,
      keymeasuresheadId:data.keyMeasureHead.key,
      isCalculated:data.iscalculated.key,
      iskeymeasuresData:data.isKeymeasuresData.key,
      calculationType:data.calculationType.key,
      calculation:data.calculation
  }

  this.glMappingService.saveGlMappingsOrUpdate(model).subscribe(

    data=>{

    console.log("saved data-----",data)
    this.toast.showSuccess("GL Mapping Saved Successfully", "success");
    this.glMappingSelectorComponent.resetForm('onSave');
    this.getGlMappIngDataByClientId(this.modalForm.controls.client.value.key);
    },
    err=>{
      this.toast.showError(err.error.message, "error");
      
    }
  )


  }


  deleteMapping(data){

    this.glMappingService.deleteGLMappingById(data.glMappingId).subscribe(

      data=>{
        this.toast.showSuccess("GL Mapping Delete Successfully", "success");
        this.getGlMappIngDataByClientId(this.modalForm.controls.client.value.key);
       
      },
      err=>{
        this.toast.showError(err.error.message, "error");
        
      }
    )


  }


  getcalculationTypeList(){
    let type="calculation_type";
    const dataVal={
      "type" :type
     }
       this.sspapplicationService.getAllGenericDropDownData(dataVal)
       .subscribe(
      (data)=> {
         this.calculationTypeList = reMapForAutoComplete(data,"itemValue", "itemText");
         //console.log("generic data"+this.allGenericData);
         const defaultMap = {
          key: "EXPR",
          value: "EXPR",
        };
         this.glMappingSelectorComponent.glMappinForm.get('calculationType').setValue(defaultMap);
      },
     (err) => {
       this.toast.showError(err.error.message, "error");
     }
     );
   }


   iscalculatedList(){
    let type="is_calculation";
    const dataVal={
      "type" :type
     }
       this.sspapplicationService.getAllGenericDropDownData(dataVal)
       .subscribe(
      (data)=> {
         this.isCalculationList = reMapForAutoComplete(data,"itemText", "itemValue");
         //console.log("generic data"+this.allGenericData);
         const defaultMap = {
          key: "Y",
          value: "Yes",
        };
         this.glMappingSelectorComponent.glMappinForm.get('iscalculated').setValue(defaultMap);
      },
     (err) => {
       this.toast.showError(err.error.message, "error");
     }
     );

   }


   getKeymeasuresDataList(){
    let type="is_calculation";
    const dataVal={
      "type" :type
     }
       this.sspapplicationService.getAllGenericDropDownData(dataVal)
       .subscribe(
      (data)=> {
         this.KeymeasuresDataList = reMapForAutoComplete(data,"itemText", "itemValue");
         //console.log("generic data"+this.allGenericData);
         const defaultMap = {
          key: "Y",
          value: "Yes",
        };
         this.glMappingSelectorComponent.glMappinForm.get('isKeymeasuresData').setValue(defaultMap);
      },
     (err) => {
       this.toast.showError(err.error.message, "error");
     }
     );


   }


  



}
