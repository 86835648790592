import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { ClientSelectorComponent } from 'src/app/clientComp/components/client-selector/client-selector.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { ClientService } from '../../services/client.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { CookieService } from 'ngx-cookie-service';
import { ReportHeadSelectorComponent } from 'src/app/reportHeadComp/components/reportHead-selector/reportHead-selector.component';
import { ReportHeadModel } from '../../models/reportHead.model';
import { ReportHeadService } from '../../services/reportHead.service';
import { ReportHeadHeaderComponent } from 'src/app/reportHeadComp/components/reportHead-header/reportHead-header.component';
import { SspApplicationService } from '../../services/ssp-application.service';

@Component({
  selector: 'app-reportHead',
  templateUrl: './reportHead.component.html',
  styleUrls: ['./reportHead.component.scss']
})
export class ReportHeadComponent implements OnInit {

   @ViewChild(ReportHeadSelectorComponent, { read: false, static: false })
   clientSelectorComponent: ClientSelectorComponent;

   @ViewChild(ReportHeadHeaderComponent, { read: false, static: false })
   ReportHeadHeaderComponent: ReportHeadHeaderComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;
@ViewChild("panelFilter", {static: false })
panelFilter!:SidePanelComponent;
// @ViewChild('fromCal')
// fromCalVariable: ElementRef;
  reportHeadList:ReportHeadModel[];
  editData:ReportHeadModel[];
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  clientsList:{key: any; value: any }[]=[];
  companyName:string=null;
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  companyId:any;
  reportLists:any=[];
  state:string;
  adminFlag:String;
  menu:string;
  submenu:string;
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute, private sspapplicationService: SspApplicationService,
    private loader:LoaderService,private fileService:FileService,private toast:ToastService,private cookieService: CookieService,
    private router: Router,private reportHeadService:ReportHeadService,private clientService:ClientService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
     
    this.titleService.setTitle("Report Head");
    this. searchByCols = [
     
      { field: "reportHeadId", header: "ReportHead Id" },
      { field: "reportHeadName", header: "ReportHead Name" },
  
    ];

    this.modalForm = new FormGroup({
      client: new FormControl(),
    });
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag");
    this.adminFlag=this.cookieService.get("adminFlag");
    }
  ngOnInit(): void {
   //this.buttonShowHidePermission();
 //this.getAllReportHeadList();
 this.getClients();
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  this.getAllReports();

  this.fileService.getClientByUserId().subscribe(
    (data) =>{
   
      for(var i=0;i<data.length;i++){
        console.log("data  ",data[i].clientId)
        this.companyId=data[i].clientId
        this.companyName=data[i].clientName
      }
      this.modalForm.get("client").setValue({
        key: this.companyId,
        value: this.companyName
      });

  

     console.log("this.headerForm",this.modalForm.value)
     this.clientSelected(this.modalForm.controls.client.value,'toggleOnInit')
    
   
    },
    (err)=>{
      this.toast.showError(err.error.message, "error");
    }
    );

  this.clientSelected(this.modalForm.controls.client.value,'toggleOnInit')
  }
  getAllReports(){
  
    this.sspapplicationService.getAllReports().subscribe(
      (data) => {
       // console.log("Applications",data);
        this.reportLists = reMapForAutoComplete(data, "appId", "appName");
        console.log("Applications",this.reportLists);
      },
      (err) =>{
      //  this.toast.showError(err.error.message, "error");
      } 
    );
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllReportHeadList(id){
    this.reportHeadService.getAllReportHeadList(id).subscribe((data)=>{
      console.log("Table Call", data);
      this.reportHeadList=data;
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }
  
  

   saveData(modalForm){
    console.log("testModel",modalForm);
     const model={
      reportheadId:modalForm.reportHeadId!==null?modalForm.reportHeadId:0,
      reportHeadName:modalForm.reportHeadName,
      clientId:this.modalForm.controls.client.value.key,
      appId:modalForm.report.key,
      reportShortCode:modalForm.reportShortCode,
      riskWeight:modalForm.riskWieghtRequired===true?modalForm.riskWeight.key:"0.00",
    //  riskWeight:modalForm.riskWeight.key,
      riskWieghtRequired:modalForm.riskWieghtRequired,
      conversionFactorFlag:modalForm.conversionFactorFlag,
      conversionFactor:modalForm.conversionFactorFlag===true?modalForm.conversionFactor.key:"0.00",
      // conversionFactor:modalForm.conversionFactor.key,
      parent:modalForm.parent,
      levelNo:modalForm.levelNo,
      liveFromDate:modalForm.liveFromDate,
      liveToDate:modalForm.liveToDate,
      

     }
    
   
    this.reportHeadService.saveOrUpdate(model).subscribe(
      (data) => {
  
     this.toast.showSuccess("Report Head Saved Successfully", "success");
     this.ReportHeadHeaderComponent.reset();
     this.getAllReportHeadList(this.modalForm.controls.client.value.key);
     this.getAllReports();
   
  
   
   },
   (err) => {
  
     this.toast.showError(err.error.message, "error");
   }

 );
   
 
 }


 editReportHead(rowData){
  console.log("rowData value",rowData);
     this.editData=rowData;
     this.ReportHeadHeaderComponent.openEdit();
    
   }


   deleteReportHeadId(data) {
        //  const genericModel ={
        //   "reportHeadId": data.reportheadId
        // }
    
         //this.loader.showLoader();
         this.reportHeadService.deleteReportHeadId(data.reportheadId).subscribe(
           data => {
            console.log("Delete Report Head ID1 called");
               this.toast.showSuccess('Success', 'ReportHead deleted successfully');
               this.getAllReportHeadList(this.modalForm.controls.client.value.key);
             },
             err => {
              console.log("Delete Report Head ID called",data);
               this.toast.showError('Error', 'Error deleting data');
             });
       }


       clientSelected(data,toggleFlag) {
        if(toggleFlag=='toggleOnLoad'){
          this.panelFilter.toggleSlider();
        }
        let clientId=data.key;
        console.log("on hit..........",clientId)
        if(clientId==null || clientId==undefined || clientId==""){
            this.reportHeadList=[];
            this.companyName="";
            this.toast.showInfo("Info","Please Select Company")
            return;
        }
        // if (loadType !== 'onLoad') {
        // }
      this.cookieService.set('clientId',this.modalForm.controls.client.value.key);
        this.cookieService.set('clientName',this.modalForm.controls.client.value.value);
       if(this.modalForm.controls.client.value.key!=undefined || this.modalForm.controls.client.value.key!=null){
       this.companyName=this.modalForm.controls.client.value.value;
        this.getAllReportHeadList(data.key);
        this.getAllReports();
       }
       
      }



       getClients() {  
          this.clientService.getAllClients().subscribe(
  
            (data) => {  
              this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
  
              console.log(this.clientsList)
  
            },
  
            (err) => {
  
              this.toast.showError(err.error.message, "error");
  
            }
  
          );
  
        }
    
 }
