import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { FieldsLayoutFormComponent } from '../fields-layout-form/fields-layout-form.component';

@Component({
  selector: 'app-file-layout-header',
  templateUrl: './file-layout-header.component.html',
  styleUrls: ['./file-layout-header.component.scss']
})
export class FileLayoutHeaderComponent implements OnInit {

  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(FieldsLayoutFormComponent)
  fieldsLayoutFormComponent:FieldsLayoutFormComponent;

  @Input() editFieldValue;
  @Output() create=new EventEmitter(); 
  @Output() firstRow=new EventEmitter(); 
  @Input() fileLayout;
  @Input() rolePermission;

  @Output() nullEditValue=new EventEmitter(); 
  constructor() { }

  ngOnInit(): void {
  }



  addNewField(){
    this.nullEditValue.emit();
this.fieldsLayoutFormComponent.resetForm();
this.panelForm.toggleSlider();
  }


  addField(data){

    this.create.emit(data);
  }

  getFirstField(data){
this.firstRow.emit(data);
  }

  

}
