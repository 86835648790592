import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { DbConnSelectorComponent } from '../db-conn-selector/db-conn-selector.component';

@Component({
  selector: 'app-db-conn-header',
  templateUrl: './db-conn-header.component.html',
  styleUrls: ['./db-conn-header.component.scss']
})
export class DbConnHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(DbConnSelectorComponent,{static: false })
  dbConnSelectorComponent:DbConnSelectorComponent;

@Input() editDbConnection;
@Output() saveDbConn=new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  saveData(data){
    this.saveDbConn.emit(data);

  }

  createNewDBCon(){
    this.dbConnSelectorComponent.dbConnForm.reset();
    this.panelForm.toggleSlider();
  }

}
