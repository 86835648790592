import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from "rxjs";
import { FileService } from 'src/app/files/services/file.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-zoho-analytics',
  templateUrl: './zoho-analytics.component.html',
  styleUrls: ['./zoho-analytics.component.scss']
})
export class ZohoAnalyticsComponent implements OnInit {
  activeRoute$: Subscription;
  zohoUrl:string;
  constructor(private titleService: TitleService,
    private fileService: FileService, 
    private reportDashboardService:ReportDashboardService,
    private  toast: ToastService,
    private activeRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    this.setTitle();
    this.callZohoAnalytics();
  }



  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  callZohoAnalytics(){
 //  this.zohoUrl="https://zoho-server.uventure.in/iam/signin?servicename=ZROP&output=embed&newtheme=true&serviceurl=http%3A%2F%2Fzoho-server.uventure.in%2FZDBHome.cc%3FLOGIN_PAGE%3Dtrue"
   this.zohoUrl="https://nova-analytics.uventure.in";
   //https://ip-10-10-2-158.ap-south-1.compute.internal:8447/open-view/8524/129ad323062e80977c9f4b14d97617fd
   console.log("this.zohoUrl  ",this.zohoUrl);
  }

}
