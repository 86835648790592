import { HttpClient } from '@angular/common/http';
import { Component, OnInit,Input,OnChanges,SimpleChanges,Output,EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientPortalDataComponent } from 'src/app/dashboard/pages/client-portal-data/client-portal-data.component';
import { RolesComponent } from 'src/app/dashboard/pages/roles/roles.component';
import { ClientPortalService } from 'src/app/dashboard/services/client-portal.service';
import { RoleService } from 'src/app/dashboard/services/role.service';
import { FileService } from 'src/app/files/services/file.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { environment } from 'src/environments/environment';
import { RoleFormComponent } from '../role-form/role-form.component';


@Component({
  selector: 'app-role-header',
  templateUrl: './role-header.component.html',
  styleUrls: ['./role-header.component.scss']
})
export class RoleHeaderComponent implements OnInit {
@ViewChild("RoleFormComponent", {static: false })
roleFormComponent!:RoleFormComponent;

@Output() roleForm=new EventEmitter();
headerForm:FormGroup;
@ViewChild("panelFormRole", {static: false })
panelFormRole!:SidePanelComponent;
roles:any;
formValues:any;
  constructor(private toast:ToastService,private fileService:FileService,
    private clientPortalService:ClientPortalService) {
    this.headerForm = new FormGroup({
      client: new FormControl(null),
      toDate: new FormControl(null),
      fromDate: new FormControl(null),
      asOfDate: new FormControl(null)
    });
   
   }

  ngOnInit(): void {
    console.log("form value on init");
  }
  addRole(data){
    console.log(data)
    this.roleForm.emit(data);
    // const roleData={
    //    "roleName":data.roleName,
    //    "roleDescription":data.roleDescription
    // }
    // this.rolesService.saveRole(roleData).subscribe(
    //   (data) => {
    //    // this.addRolePopup = false;
    //   //  this.roleFormComponent.roleForm.reset();
    //     this.getRoles();
    //     this.toast.showSuccess("Role SuccesFully Saved", "success");
    //   },
    //   (err) =>{
    //     this.toast.showError(err.error.message, "error");
    //   } 
    // );
  }


}
