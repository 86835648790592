import { NgModule } from "@angular/core";
import { FilesLogComponent } from "./components/files-log/files-log.component";
import { SharedModule } from "../shared/shared.module";
import { FilesListComponent } from "./components/files-list/files-list.component";
import { FileService } from "./services/file.service";
import { TransformLogComponent } from "./components/transform-log/transform-log.component";
import { TransformTableComponent } from "./components/transform-table/transform-table.component";
import { FieldsLayoutTableComponent } from "./components/fields-layout-table/fields-layout-table.component";
import { FieldsLayoutFormComponent } from "./components/fields-layout-form/fields-layout-form.component";
import { FieldMappingsTableComponent } from "./components/field-mappings-table/field-mappings-table.component";
import { ClientFileMasterListComponent } from './components/client-file-master-list/client-file-master-list.component';
import { FileLayoutParameterComponent } from "./components/file-layout-parameter/file-layout-parameter.component";
import { ClientFileTableComponent } from './components/client-file-table/client-file-table.component';
import { FileLayoutHeaderComponent } from './components/file-layout-header/file-layout-header.component';
import { UiModule } from "../ui/ui.module";

@NgModule({
  declarations: [
    FilesLogComponent,
    FilesListComponent,
    TransformLogComponent,
    TransformTableComponent,
    FieldsLayoutTableComponent,
    FieldsLayoutFormComponent,
    FieldMappingsTableComponent,
    ClientFileMasterListComponent,
    FileLayoutParameterComponent,
    ClientFileTableComponent,
    FileLayoutHeaderComponent,
  ],
  imports: [SharedModule,UiModule],
  exports: [
    FilesLogComponent,
    FieldsLayoutTableComponent,
    FilesListComponent,
    TransformLogComponent,
    TransformTableComponent,
    FieldsLayoutFormComponent,
    FieldMappingsTableComponent,
    ClientFileMasterListComponent,
    FileLayoutParameterComponent,
    ClientFileTableComponent,
    FileLayoutHeaderComponent
  ],
  providers: [FileService]
})
export class FilesModule {}
