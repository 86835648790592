import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-master',
  templateUrl: './layout-master.component.html',
  styleUrls: ['./layout-master.component.scss']
})
export class LayoutMasterComponent implements OnInit {
  @Input() noBranding: boolean = false;
  @Input() noSearchBar: boolean = false;
  @Input() noHeader: boolean = false;
  @Input() state:string;
  constructor() { }

  ngOnInit(): void {
  }

}
