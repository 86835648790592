import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DbConnDataModel } from '../models/dbconnection.model';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DbconnectionService {

  constructor(private http: HttpClient) { }

  getAllDbConnectionListByCompanyId(data){
    return this.http.post<DbConnDataModel[]>(environment.baseUrl + "/apis/dbConn/getAllByCompanyId",data);
  }

  saveDbConnection(data){
    return this.http.post<any>(environment.baseUrl + "/apis/dbConn/savedbconn",data);
  }

  getByConnectionId(data){
    return this.http.post<DbConnDataModel>(environment.baseUrl + "/apis/dbConn/getById",data);
  }

  launchSpark(data){
    return this.http.post<any>(environment.baseUrl + "/apis/sparkLauncher/launchSparkApp",data);
  }

  loadData(data){
    return this.http.get<any>(environment.baseUrl + "/apis/sparkLauncher/loadData");
  }


  getServiceByClient(data){
    return this.http.post<DbConnDataModel>(environment.baseUrl + "/apis/dbConn/getService",data);

  }

  getAllDataBaseNameList(data){
    return this.http.post<DbConnDataModel>(environment.baseUrl + "/apis/spark/getAllDatabases",data);
  }


  getAllTableByDb(data){
return this.http.post<DbConnDataModel>(environment.baseUrl + "/apis/spark/getAllDatabaseTables",data);
  }

  saveTransferTableData(data){
    return this.http.post<DbConnDataModel>(environment.baseUrl + "/apis/spark/dumpData",data).pipe(
      timeout(300000) //5 minutes(1000 = 1sec)
  );

  }


  createStatementforTable(data){
    return this.http.post<DbConnDataModel>(environment.baseUrl + "/apis/spark/getCreateStatement",data);
  }


  executeStatement(data){
    return this.http.post<DbConnDataModel>(environment.baseUrl + "/apis/spark/executeStatement",data);
  }

  getColumnsByTable(data){
    return this.http.post<DbConnDataModel>(environment.baseUrl + "/apis/spark/getTableColumns",data); 
  }

  getDataTransferList(id){
    return this.http.get<any>(
      `${environment.baseUrl}/apis/spark/dataTransferList?id=`+id
    );
  }

  getDataTransferLogs(id) {
    return this.http.get<any>(
      `${environment.baseUrl}/apis/spark/dataTransferLogList/${id}`
    );
  }
}
