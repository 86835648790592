import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
// @UntilDestroy()
@Component({
  selector: 'app-paginate-text',
  templateUrl: './paginate-text.component.html',
  styleUrls: ['./paginate-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginateTextComponent implements OnInit {
  currentPage: number = 1;
  @Input() count: number = 0;
  constructor(
    private activeRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParamMap
      .pipe()
      .subscribe((par) => {
        this.currentPage = Number(par.get('page')) || 1;
        this.cdr.detectChanges();
      });
  }
}
