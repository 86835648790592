import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-spark-launcher-selector',
  templateUrl: './spark-launcher-selector.component.html',
  styleUrls: ['./spark-launcher-selector.component.scss']
})
export class SparkLauncherSelectorComponent implements OnInit {

  @Output() saveSparkLauncher= new EventEmitter();
  sparkSelectorForm: FormGroup = null;
  constructor() {
    this.sparkSelectorForm = new FormGroup({
      appResource: new FormControl(null),
      appMainClass: new FormControl(null),
      masterUrl: new FormControl(null),
      appHome: new FormControl(null)
    });
  }

  ngOnInit(): void {
  this.sparkSelectorForm.controls.appResource.setValue('/opt/bitnami/spark/examples/jars/spark-0.0.1-SNAPSHOT.jar');
  this.sparkSelectorForm.controls.appMainClass.setValue('com.example.spark.SparkApplication');
  this.sparkSelectorForm.controls.masterUrl.setValue('spark://13.234.186.10:32449');
  }

  callSparkLauncher(){
    console.log("hello .....");

    const sparkData={
      "appResource":this.sparkSelectorForm.controls.appResource.value,
      "appMainClass":this.sparkSelectorForm.controls.appMainClass.value,
      "appMasterUrl":this.sparkSelectorForm.controls.masterUrl.value,
      "appHome":this.sparkSelectorForm.controls.appHome.value,
    }
    this.saveSparkLauncher.emit(sparkData);
  }

}
