import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { StressKeyMetricsModel } from "../models/stressKeyMetrics.model";

@Injectable({
    providedIn: 'root'
  })
export class StressKeyMetricsService {
  
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  saveOrUpdate(data){
    return this.http.post<StressKeyMetricsModel[]>(environment.baseUrl + "/apis/createStressKeyMetrics",data);
} 


getAllStressKeyMetricsList(){
  return this.http.get<StressKeyMetricsModel[]>(environment.baseUrl + "/apis/getAllStressKeyMatrics");
}

deleteStressKeyMetricsById(id) {
    return this.http.delete<StressKeyMetricsModel[]>(environment.baseUrl + "/apis/deleteStressKeyMetrics/"+id);
  }




  }

