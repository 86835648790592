import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-glHead-selector',
  templateUrl: './glHead-selector.component.html',
  styleUrls: ['./glHead-selector.component.scss']
})
export class GlHeadSelectorComponent implements OnInit {

  @Output() save=new EventEmitter();
 
  @Output() closePanel=new EventEmitter();
  glHeadForm: FormGroup = null;
  @Input() editData:any[];
  constructor(private toast:ToastService,private cookieService: CookieService,private datePipe: DatePipe) {
    this.glHeadForm=new FormGroup({
      glHeadId:new FormControl(null),
      glHeadNname: new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(100)]),
      liveToDate: new FormControl(null),
      liveFromDate: new FormControl(null),
      glHeadTypeList:new FormControl(null),
      glAcct:new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(15)]),
      glHeadType:new FormControl(null),
      glHeadShortName:new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(40)]),
      gl_schedule:new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(40)]),
      glGroupingHead:new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(40)]),
      parent:new FormControl(null),
      modifiedBy:new FormControl(null),
      modifiedAt:new FormControl(null),
      isHQLA: new FormControl(false)
    });
  
   }

  ngOnInit(): void {
   
    
   
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     //this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
     console.log("rowData  ",rowData);
     this.glHeadForm.get("glHeadType").setValue({

      key:rowData.glHeadType,

      value:rowData.glHeadType

     });
      this.glHeadForm.patchValue(
        {glHeadId:rowData.glheadId,
          
          glHeadNname:rowData.glHeadNname,
          glAcct:rowData.glAcct,
          //glHeadType:rowData.glHeadType,
          glHeadShortName:rowData.glHeadShortName,
          gl_schedule:rowData.gl_schedule,
          glGroupingHead:rowData.glGroupingHead,
          parent:rowData.parent,
          remarks:rowData.remarks,
          isHQLA:rowData.isHQLA,
          liveFromDate: this.datePipe.transform(rowData.liveFromDate,"yyyy-MM-dd"),
          liveToDate: this.datePipe.transform( rowData.liveToDate,"yyyy-MM-dd"),
          createdBy:rowData.createdBy,
          modifiedBy:rowData.modifiedBy,
          modifiedAt:rowData.modifiedAt
        });
    }
  
  }


  getGlHeadType(){
    const glHeadTypeList=[
      "Financial Assets",
      "Non-financial Assets",
      "Financial liabilites",
      "Non-Financial liabilities",
      "Equity",
      "Directly linked to Balance sheet",
      
    ];
    return glHeadTypeList.map((t) => ({ key: t, value: t }));

  }
  saveGlHead(flag){
    if(this.glHeadForm.invalid){
      if(this.glHeadForm.controls.glHeadNname.invalid){
        this.toast.showWarn('GL Head Name is mandatory','error');
        this.closePanel.emit();
        return;
      }
      if(this.glHeadForm.controls.glHeadShortName.invalid){
        this.toast.showWarn('GL Head Short Name is mandatory','error');
        this.closePanel.emit();
        return;
      }
      if(this.glHeadForm.controls.glGroupingHead.invalid){
        this.toast.showWarn('GL Grouping Head Name is mandatory','error');
        this.closePanel.emit();
        return;
      }
      if(this.glHeadForm.controls.gl_schedule.invalid){
        this.toast.showWarn('GL Head Schedule Name is mandatory','error');
        this.closePanel.emit();
        return;
      }
    }
    
    if(flag=='onLoad'){
      let validate=this.validationOnDate(this.glHeadForm.controls.liveFromDate.value,this.glHeadForm.controls.liveToDate.value);
      if(validate===true){
        console.log("this.glHeadform on save.value ",this.glHeadForm.value)
        if(this.glHeadForm.valid){ 
         
      this.save.emit(this.glHeadForm.value);
        }
          else{
            this.toast.showError("Please Fill Required Fields","error");
        }
       // this.closePanel.emit();
      }
      if(validate===false){
        this.toast.showWarn('error','date range incorrect')
        
      }
    this.closePanel.emit();
  }

 
    }

    formReset(){
      this.glHeadForm.reset();
    }

    

    validationOnDate(liveFromDate,liveToDate){
      if(liveFromDate && liveToDate){
      if(new Date(liveFromDate)>new Date(liveToDate)){
      
          return false;
        }
        return true;
        
      }else{
        return false;
      }
    }



}
