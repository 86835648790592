import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormsModel } from 'src/app/models/forms.model';
import { AuthService } from 'src/app/user/services/auth.service';
import { UserService } from 'src/app/user/services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  ChangePasswordForm: FormGroup;
  addPasswodPopup=false;
  changePasswordModel: FormsModel = {
    controls: [
      {
        name: "oldPassword",
        type: "password",
        validators: [Validators.required],
        label: "Old Password",
      },
      {
        name: "newPassword",
        type: "password",
        validators: [Validators.required,Validators.minLength(3),Validators.maxLength(15)],
        label: "New Password",
      },
      {
        name: "confirmPassword",
        type: "password",
        validators: [Validators.required],
        label: "Confirm Password",
      },
    ],
  };
  state:string;
  constructor(   private authService: AuthService,
    private userService: UserService,
    private toast: ToastService,
    private cookieService: CookieService) {

      this.ChangePasswordForm=new FormGroup({
        oldPassword:new FormControl(null,{validators:[Validators.required],updateOn: "blur"}),
        newPassword:new FormControl(null,{validators:[Validators.required],updateOn: "blur"}),
        confirmPassword:new FormControl(null,{validators:[Validators.required],updateOn: "blur"})
      })

      this.state=localStorage.getItem("stateflag");
     }

  ngOnInit(): void {
  }

  getForm(form: FormGroup) {
    this.ChangePasswordForm = form;
  }

  changePassword(){
    console.log("this.ChangePasswordForm.valid ",this.ChangePasswordForm.valid)
    console.log("this.ChangePasswordForm.controls.newPassword.value ",this.ChangePasswordForm.controls.newPassword.value)
    console.log("this.ChangePasswordForm.controls.oldPassword.value ",this.ChangePasswordForm.controls.oldPassword.value)
    if(this.ChangePasswordForm.valid){
    if(this.ChangePasswordForm.controls.newPassword.value!==this.ChangePasswordForm.controls.confirmPassword.value){
      this.toast.showError("Confirm password must be same as New Password","error");
    }else{
    const userData={
      "newPassword":this.ChangePasswordForm.controls.newPassword.value,
      "oldPassword":this.ChangePasswordForm.controls.oldPassword.value,
    }
    this.userService.changePassword(userData).subscribe(
      (data) => {
       this.addPasswodPopup=false;
       this.toast.showSuccess("Password Change SuccesFully", "success");
       this.ChangePasswordForm.reset();
       this.signOut();
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }
}
}

signOut(){
  this.cookieService.deleteAll();
  this.authService.signOut();
}

}
