import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { ReportDashboardService } from '../../services/report-dashboard.service';

@Component({
  selector: 'app-main-export-report',
  templateUrl: './main-export-report.component.html',
  styleUrls: ['./main-export-report.component.scss'],
})
export class MainExportReportComponent implements OnInit {
  getReportList:any=[];
  saveReportList:any=[];
  exportMode: any=[];
  formatType:string=null;
  selectItem:{requestUrl:any; fileName:any; viewName:any; workSpaceName:any}[]=[];
  downloadURL:string;
  buttonName:string;
  channelArray:any[]=[];
  exportModeStatus:boolean=false;
  // searchByCols = [
  //   'Group Name',
  //   'Report Name',
  //   'Sub-Report Name',
  //   'Report View',
  // ];

   searchByCols = [
    { field: "groupName", header: "Group Name" },
    { field: "reportName", header: "Report Name" },
    { field: "zohoViewName", header: "Sub-Report Name" },
    { field: "viewName", header: "Report View" },
  ];
 
  tableHeaders = [
    'Group Name',
    'Report Name',
    'Sub-Report Name',
    'Report View',
    'Action',
  ];
  data = [];
  searchQuery!: any;
  searchCol:String;
  activeRoute$: Subscription;
  state:string;
  constructor(private reportDashboardService:ReportDashboardService,private loader:LoaderService,private toast:ToastService,private activeRoute: ActivatedRoute,private titleService: TitleService) {

    this.state=localStorage.getItem("stateflag");
  }

  ngOnInit(): void {
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );
    this.setTitle();
    this.getAllReportList();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllReportList(){

    this.reportDashboardService.getAllReportList().subscribe(

      res=>{
      this.getReportList=res
      this.saveReportList= this.getReportList;

      },
      err=>{
        this.toast.showError(err.error.message, 'error');
      }
    )
  }

  downloadPDF(data){
    console.log("rowdata  ",data.actionType)
    this.formatType=data.actionType.text;
    // const data1={
    //   requestUrl:data.instanceUrl,
    //   fileName:formatType,
     

    // }
    const data1={
      requestUrl:data.reportData.requestUrl,
      fileName:this.formatType,
      viewName:data.reportData.zohoViewName,
      workSpaceName:data.reportData.workSpaceName,
    }
    this.selectItem.push({ requestUrl: data1.requestUrl, fileName: data1.fileName,viewName:data1.viewName, workSpaceName:data1.workSpaceName });
    
    this.reportDashboardService.downloadPDF(this.selectItem).subscribe(

      res => {
        for(let k in res){
        this.downloadURL=res[k].requestUrl;
        console.log("downloadURL  ",this.downloadURL)
     // this.blob = new Blob([res.requestUrl], {type: 'application/pdf'});

  // var downloadURL = window.URL.createObjectURL(res.requestUrl);
  let link = document.createElement('a');
  link.href = this.downloadURL;
  link.download = "Blank.csv";
  link.click();
  this.loader.hideLoader();
 // this.toast.showSuccess('Success','Download Successfully');
        }

        this.selectItem=[];
      },
      err=>{
        this.toast.showError("Failed to Download", "error");

      }
    )
  
    }


  updateExportSet(value: { data: any; status: boolean }): void {
   if(value.status){
    this.exportMode.push(value.data);
   }else{
    this.exportMode=this.exportMode.filter( h => h.zohoViewName !== value.data.zohoViewName);
    console.log("left over data"+this.exportMode) 
  }
    if(this.exportMode.length>1){
      this.exportModeStatus=true;
    }else{
      this.exportModeStatus=false;
    }
    console.log("listing data "+this.exportMode);
  }

  actionSelected(data,val){
   console.log("main comp"+data.text);
   this.buttonName="Bulk Report PDF";
  }

  onBtnClicked(formatType){

   // this.selectedItems = this.getReportList.filter(x => x.selected);
   console.log(" this.products ",this.exportMode);

    for (var i = 0; i < this.exportMode.length; i++) {
      // if( this.selectedGroup[i].key===this.selectedGroupList.)
       this.selectItem.push({ requestUrl: this.exportMode[i].requestUrl, fileName: formatType,viewName:this.exportMode[i].zohoViewName,workSpaceName:this.exportMode[i].workSpaceName});
      //console.log("selectedDataType Are:", this.selectedGroupList);
    }
    console.log("this.selectItem  ",this.selectItem)

    this.reportDashboardService.downloadPDF(this.selectItem).subscribe(

      res => {
        for(var k in res){
        this.downloadURL=res[k].requestUrl;
        console.log("downloadURL  ",this.downloadURL)

        this.channelArray.push(this.downloadURL)
        
      //this.callLink( this.channelArray);
  
        }
       // console.log("this.channelArray ",this.channelArray)
        
        this.function(this.channelArray);
    
         this.channelArray=[];
        this.selectItem=[];
       // this.exportMode=[];
       // this.exportModeStatus=false;
        
      },
      err=>{
        this.toast.showError("Failed to Download", "error");

      }
    )

           
    };

    function (linkArray) {
      for (var i = 0; i < linkArray.length; i++) { 
        if(i==0){
          var link = document.createElement('a');
          link.href = linkArray[i];
          link.download = "Blank.csv";
          link.click();
        }
        else{
          setTimeout(function (path) { window.location = path; }, 3000 + i * 3000, linkArray[i]);
        }
      }        
    };
}
