import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
//import { DepartmentModel } from 'src/app/dashboard/models/Department.model';

@Component({
  selector: 'app-rwapercent-selector',
  templateUrl: './rwapercent-selector.component.html',
  styleUrls: ['./rwapercent-selector.component.scss']
})
export class RwaPercentSelectorComponent implements OnInit {

  @Output() save=new EventEmitter();
 
  @Output() closePanel=new EventEmitter();
  rwaForm: FormGroup = null;
  @Input() editData:any[];
  constructor(private toast:ToastService,private datePipe: DatePipe) {
    this.rwaForm=new FormGroup({
      stressRiskFactorId:new FormControl(null),
      provision: new FormControl(null,{validators:[Validators.required],updateOn: "blur"}),
      rwaAsPerProvision:new FormControl(null),
      validFrom:new FormControl(null,{validators:[Validators.required]}),
      validTo:new FormControl(null,{validators:[Validators.required]})
    
    });
  
   }

  ngOnInit(): void {


  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);
      this.rwaForm.patchValue(
        {stressRiskFactorId:rowData.stressRiskFactorId,
          provision:rowData.provision,
          rwaAsPerProvision:rowData.rwaAsPerProvision,
          validFrom:this.datePipe.transform(rowData.validFrom,"yyyy-MM-dd"),
          validTo:this.datePipe.transform(rowData.validTo,"yyyy-MM-dd"),
          
        });
        
    }
  
  }
  


  saveRwaPercent(){
  console.log("this.rwaForm.value ",this.rwaForm.value)
   if(this.rwaForm.valid){ 

 let validate=this.validationOnDate(this.rwaForm.controls.validFrom.value,this.rwaForm.controls.validTo.value);
 if(validate===true){
  this.save.emit(this.rwaForm.value);
   
 }
 if(validate===false){
   this.toast.showWarn('error','date range incorrect')
   
 }
   }
     else{
       this.toast.showError("Please Fill Required Fields","error");
   }


  this.closePanel.emit();
    }

    formReset(){
      this.rwaForm.reset();
     
    }

    changeEndDate(){
      // this.headerForm.controls.toDate.setValue(e);
       let validate=this.validationOnDate(this.rwaForm.controls.validFrom.value,this.rwaForm.controls.validTo.value);
       if(validate===true){
         
         this.save.emit(this.rwaForm.value);
       }
       if(validate===false){
         this.toast.showWarn('error','date range incorrect')
         
       }
     }
     
     validationOnDate(validFrom,validTo){
       if(validFrom && validFrom){
       if(new Date(validFrom)>new Date(validTo)){
       
           return false;
         }
         return true;
         
       }else{
         return false;
       }
     }

}
