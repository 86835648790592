import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[LowercaseAlphabetNumbersOnly]'
})
export class LowercaseAlphabetNumbersDirective {

  @Input() validationFieldsType: string;

  constructor(private el: ElementRef) { }
 
 
  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    // Remove non-alphanumeric characters except the first character and single space
    inputElement.value = value.replace(/[^a-z0-9 ]/g, '');

    // Ensure the first character is an alphabet
    if (!/^[a-z]/.test(inputElement.value)) {
      inputElement.value = '';
    }

    // Remove extra spaces and ensure only one space
    inputElement.value = inputElement.value.replace(/\s+/g, ' ');
  }

}






