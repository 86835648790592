import { NgModule } from '@angular/core';
import { ClientPortalListComponent } from './components/client-portal-list/client-portal-list.component';
import { SharedModule } from "../shared/shared.module";
import { ClientPortalParameterComponent } from './client-portal-parameter/client-portal-parameter.component';
import { ClientPortalHeaderComponent } from './client-portal-header/client-portal-header.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { UiModule } from '../ui/ui.module';
import { ClientApplicationFormComponent } from './client-application-form/client-application-form.component';



@NgModule({
declarations: [
  ClientPortalListComponent, 
  ClientPortalParameterComponent,
  ClientPortalHeaderComponent,
  ClientFormComponent,
  ClientApplicationFormComponent
  

],
imports: [SharedModule,UiModule],
exports: [
  ClientPortalListComponent, 
  ClientPortalParameterComponent,
  ClientPortalHeaderComponent,
  ClientFormComponent,
  ClientApplicationFormComponent
],
providers: []
})
export class ClientPortalModule { }
