import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { TransformModel } from '../../models/tranform.model';
import { CustomJobs, IngestJob, WorkflowJobDetails, WorkflowModel } from '../../models/workflow.model';
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { WorkflowService } from '../../services/workflow.service';
import { SetupJobSelectorComponent } from 'src/app/workflows/components/setup-job-selector/setup-job-selector.component';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import {trim} from 'lodash';
import { Store } from '@ngrx/store';
import { JobListSelectorComponent } from 'src/app/workflows/components/job-list-selector/job-list-selector.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { ClientService } from '../../services/client.service';


@Component({
  selector: 'app-setup-job',
  templateUrl: './setup-job.component.html',
  styleUrls: ['./setup-job.component.scss']
})
export class SetupJobComponent implements OnInit {
  @ViewChild(SetupJobSelectorComponent, { read: false, static: false })
  setupJobSelectorComponent: SetupJobSelectorComponent;
  @ViewChild(JobListSelectorComponent, { read: false, static: false })
  jobListSelectorComponent:JobListSelectorComponent;
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;
  @ViewChild("panelForm1", {static: false })
  panelForm1!:SidePanelComponent;
  @Output() closePanel=new EventEmitter();

  searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String | number, header:String }[];

  activeRoute$: Subscription;
  setupJobForm:FormGroup=null;
  workflowData:WorkflowModel[]=[];
  workflowDropDown:any[]=[];
  clientsList:any[];
  company: any;
  
  workflowJobDetails:WorkflowJobDetails[]=[];
  ingestJobData:IngestJob[]=[];
  ingestJobDropDown:any[]=[];
  transformList: TransformModel[] = [];
  ingestJobDetails:WorkflowJobDetails[]=[];
  transformJobDetails:WorkflowJobDetails[]=[];
  customJobDetails:WorkflowJobDetails[]=[];
  outputJobDetails:WorkflowJobDetails[]=[];
  workflowJobDetailsEdit:WorkflowJobDetails=null;
  transformMapData = new Map();
  visible:boolean=false;
  visible1:boolean=false;
  addJobHeader="Add Job";
  modalForm: FormGroup = null;
  clientMasterList:any[]=[];
  isCustomJob:boolean=false;
  customJobsList:CustomJobs[]=[];
 // dataTransferModel:DataTransferModel;
 //popupForm:FormGroup=null;
 paramHeader="Add Job Param";
 dataTransferModel:DataTransferModel;
 jobsequenceData:any[]=[];
 rolePermission:any;
 companyId:any;
 workflowId:any;
 workflowName:String;
 //workflowListByClient:any[]=[];
 adminFlag:String;
 paramMap$: Subscription;
 nevigateFlag:boolean=false;
 @Input() setupHeader='true';
 state:string;
  constructor(private titleService: TitleService,
    private datePipe:DatePipe,
    private activeRoute: ActivatedRoute,
    private workflowService:WorkflowService,
    private toast:ToastService,
    private loader:LoaderService,
    private fileService: FileService,
    private clientFileMasterService:ClientFileMasterService,
    public observalbleDataService:ObservalbleDataService,
    private cookieService: CookieService,
    private clientService:ClientService,
    private router: Router,private storePermission: Store<{ role: any }>) {

    
    this.setupJobForm=new FormGroup({
       client:new FormControl(null),
       workflow:new FormControl(null)
      })
  this.modalForm=new FormGroup({
        jobType:new FormControl(null),
        jobName: new FormControl(null,[Validators.required,Validators.minLength(5),Validators.maxLength(250)]),
        jobDesc: new FormControl(null,[Validators.minLength(5),Validators.maxLength(500)]),
        clientFile: new FormControl(null),
        apiMethod: new FormControl(null),
          
         })
    

         this. searchByCols = [
          { field: "jobName", header: "Job" },
          { field: "jobType", header: "Type" },
          { field: "fileName", header: "File" },
          { field: "description", header: "Desc" },
          { field: "trigger1", header: "Trigger" },
          { field: "enabled", header: "Enabled" },
         // { field: "continueOnError", header: "Continue On Error" },
          
        ];

        this.state=localStorage.getItem("stateflag");

   }

   customData=[
    { key: '1', value: "Yes" },
    { key: '0', value: "No" }
 
  ]

  apiMethodList=[
    { key: "POST", value: "POST" },
    { key: "GET", value: "GET" }
 
  ]


  ngOnInit(): void {
    this.adminFlag=this.cookieService.get("adminFlag");

    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );

   
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {

        if(par.has("clientIdm")  && par.has("workflowIdm")){
          this.nevigateFlag=true;
        }})

    this.paramMap$ = this.activeRoute.queryParamMap.subscribe(
          (par) => {      
            if (par.has("nevigateBoolean")) {      
              
              console.log("par.get",par.get("nevigateBoolean"));
               if(par.get("nevigateBoolean")){
                this.nevigateFlag=true;
               }
               
            }
          },
          (err)=>{}
          );

        
   this.buttonShowHidePermission();

if(!this.nevigateFlag){
   this.fileService.getClientByUserId().subscribe(
    (data) =>{
    
      for(var i=0;i<data.length;i++){
        console.log("data  ",data[i].clientId)
        this.companyId=data[i].clientId
        this.company=data[i].clientName
      }
      this.setupJobForm.get("client").setValue({
        key: this.companyId,
        value: this.company
      });



      const requestModel={
        "clientId":this.setupJobForm.controls.client.value.key
      }

      this.getAllClientMasterData(this.setupJobForm.controls.client.value.key);
      console.log("requestModel  ",requestModel)
      
      this.workflowService.getAllWorkflow(requestModel)
      .subscribe(
        (data)=>{
          
          
          data.forEach(item=>{
            this.workflowDropDown.push({key:item.workflowId,value:item.workflowName})
          })
    
          for(var i=0;i<this.workflowDropDown.length;i++){
            
            this.workflowId=this.workflowDropDown[i].key
            this.workflowName=this.workflowDropDown[i].value
          }
    console.log("id ", this.workflowId,"name ",this.workflowName)
        
    
          this.setupJobForm.get("workflow").setValue({
            key: this.workflowId,
            value: this.workflowName
          });

          console.log("this.setupJobForm.controls.worflow ",this.setupJobForm.controls.workflow.value)
          this.getAllWorkflowJob();

        }, (err) => {
          // this.toast.showError(err.error.message, "error");
         
         }
      )
    
      //this.onClientChange();
      
   
    },
   (err) => {
    // this.toast.showError(err.error.message, "error");
   
   }
   );
  }


  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  onClientChange(){
    console.log("this.setupJobForm.controls.client.value.key  check ",this.setupJobForm.controls.client.value.key)
    this.cookieService.set('clientId',this.setupJobForm.controls.client.value.key);
    this.cookieService.set('clientName',this.setupJobForm.controls.client.value.value);


    this.getAllClientMasterData(this.setupJobForm.controls.client.value.key);
    this.setupJobForm.controls.workflow.setValue(null);
    
    //this.resetForm();
    
    this.getAllWorkflow();
    this.getAllTransformJob(this.setupJobForm.controls.client.value.key,false);
   // this.maintainClientState();
  }

  getAllWorkflow(){
    console.log("getting called");
    console.log("this.setupJobForm.controls.client.value.key",this.setupJobForm.controls.client.value.key);
    this.workflowDropDown=[];
    this.workflowData=[];
    this.ingestJobDetails=[];
    this.transformJobDetails=[];
    this.workflowJobDetails=[];
    this.customJobDetails=[];
    try{
      this.setupJobForm.controls.workflow.reset();
    }catch{
      console.log('exception');
    }
   
    const requestModel={
      "clientId":this.setupJobForm.controls.client.value.key
    }
    console.log("requestModel  ",requestModel)
    
    this.workflowService.getAllWorkflow(requestModel)
    .subscribe(
      (data)=>{
        this.workflowData=data;
        
        this.workflowData.forEach(item=>{
          this.workflowDropDown.push({key:item.workflowId,value:item.workflowName})
        })

       // this.workflowListByClient=this.workflowDropDown;
        console.log("this.workflowDropDown  ",this.workflowDropDown.length)

        if(this.dataTransferModel.isRedirected===1){

          if(this.dataTransferModel.workflowId !== 0 && this.dataTransferModel.workflowName !==null ){
            this.setupJobForm.get("workflow").patchValue({
              key: this.dataTransferModel.workflowId,
              value: this.dataTransferModel.workflowName,
            });
           // this.getAllWorkflowJob();
           this.workflowName=this.setupJobForm.controls.workflow.value.value;
          } 


          this.getAllTransformJob(this.setupJobForm.controls.client.value.key,onchange);
          this.getAllWorkflowJob();
        }

        this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
          par => {
    
            if(par.has("workflowIdm")){

              
              this.setupJobForm.get("workflow").setValue({
                key: par.get("workflowIdm"),
                value: par.get('workflowNamem')
              });
              this.getAllWorkflowJob();
            this.company=this.setupJobForm.controls.client.value.value;
            this.workflowName=this.setupJobForm.controls.workflow.value.value;

            }
            })
      
        
      },
      (err)=>{
       
       // this.toast.showError('error',err.error.message);
       this.toast.showError(err.error.message, "error");
      }
    )

  }

  getAllWorkflowData(){
 
    return this.workflowDropDown;
   

  }

  getAllWorkflowJob(){
   // this.maintainWorkflowState();
    this.ingestJobDetails=[];
    this.transformJobDetails=[];
    this.workflowJobDetails=[];
    this.customJobDetails=[];
    
    const requestModel={
      "workflowId":this.setupJobForm.controls.workflow.value.key
    }
   // console.log("hello workflow job")
    this.workflowService.getAllWorkflowJob(requestModel)
    .subscribe(
      (data)=>{
        this.workflowJobDetails=data;
      //  console.log("hello workflow job")
        if(this.workflowJobDetails.length>0){
          if(this.setupJobForm.controls.client.value.key!==undefined){
             const onchange=false;
            this.getAllTransformJob(this.setupJobForm.controls.client.value.key,onchange);
            console.log("transform map data :"+this.transformMapData)
          }
        }
       // console.log("hello workflow job12")
        this.workflowJobDetails.forEach(item=>{
       //   console.log("hello workflow job1232")
          if(item.jobType==='Ingest'){
            this.ingestJobDetails.push(item)
          }
          if(item.jobType==='Custom'){
           // console.log("hello workflow custom")
            this.customJobDetails.push(item)
          }
          if(item.jobType==='Transform'){
          
            this.ingestJobDetails.push(item);
            
          }
         
        })
     
       // console.log("custom job details ",this.customJobDetails)
       
      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
    )    
  }

  getAllTransformJob(id,onchange){
    this.transformList =[];
    
    this.ingestJobDropDown=[];
    this.fileService.getTransformListByClient(id)
    .subscribe(
      data=>{
        if (data && data["status"] === 200) {
          this.transformList = data["data"];
         
          // if(this.transformList.length>1){
           
          
          //   this.transformList.sort((a, b) => trim(a.job_name.toLowerCase()).localeCompare(trim(b.job_name.toLowerCase())))
          // }
         
          if(onchange===true){
          // this.transformList.forEach(item=>{
           
          //   this.ingestJobDropDown.push({key:item.job_id,value:item.job_name});
          //  // this.transformMapData.set(item.job_id,item.job_name);
          // })

          this.ingestJobDropDown=reMapForAutoComplete(this.transformList,"job_id", "job_name");
         

        }else{
          this.transformList.forEach(item=>{
           this.transformMapData.set(item.job_id,item.job_name);
            })
            console.log("elese portion");
        }
        }
       // this.loader.hideLoader();

      }
    )
  }

  editWorkflowDetails(data){
console.log("Endit job",data);
document.getElementById("setupJob").style.display="";
document.getElementById("createNewJob").style.display="none";
    this.getWorkflowDetailsById(data);
    this.panelForm.toggleSlider();
  }

  deleteWorkflowJob(data){
    this.deleteJobFromWorkflow(data);
 }

 deleteJobFromWorkflow(data){

  const requestModel={
    "workflowDetailId":data.workflowDetailId
  }

  this.workflowService.deleteJob(requestModel)
  .subscribe(
    data=>{
      this.toast.showSuccess('success','Job Deleted SuccessFully');
      this.getAllIngestJobByClientId();
      this.getAllWorkflowJob();
      this.resetForm();
    },
    error=>{
      this.toast.showError('error','error');
    }
    
  )
}

 
 saveIngestParam(data){
    
  const workflowDetailParam={
    "workflowDetailParamId":data.workflowDetailParamId!==null?data.workflowDetailParamId:0,
    "workflowDetailId":data.workflowDetailId,
    // "asofdate":data.asOdDate,
    "parameters":data.parameters,
    "params":data.params,
    "paramList":data.paramList
  }

  this.workflowService.saveWorkflowParam(workflowDetailParam)
  .subscribe(
    data=>{
      this.toast.showSuccess('success','job param saved');
     // this.getAllIngestJobByClientId();
      this.getAllWorkflowJob();

    },
    error=>{
      this.toast.showError('error','error');
    }
  )
}

getWorkflowParam(data){
  // console.log("getworkflowparam ",data);
  // const requestModel={
  //   "workflowDetailId":data.workflowDetailId
  // }

  // this.workflowService.getWorkflowDetailParamByDetailId(requestModel)
  // .subscribe(
  //   data=>{
  //     console.log(data);
  //   }
  // )
}

getWorkflowDetailsById(data){
  const reuestModel={
    "workflowDetailId":data.workflowDetailId
  }

  this.workflowService.getWorkflowDetailsById(reuestModel)
  .subscribe(
    data=>{
        this.workflowJobDetailsEdit=data;
        const jobTypeData={
          "key":this.workflowJobDetailsEdit.jobType
        }
        this.onJobTypeChange(jobTypeData);
    },
    error=>{
      this.toast.showError('error',error.error.message);
    }
  )
}

onJobTypeChange(data){
  console.log('onJobTypeChange ',data)
  if(data.key==='Ingest'){
    this.getAllIngestJobByClientId();
  }
  if(data.key==='Transform'){
    const onchange=true;
    this.getAllTransformJob(this.setupJobForm.controls.client.value.key,onchange);
  }
  if(data.key==='Custom'){
    this.getAllCustomJobByClientId();
  }

}

getAllIngestJobByClientId(){
  this.ingestJobDropDown=[];
  this.ingestJobData=[];
  const requestModel={
    "clientId":this.setupJobForm.controls.client.value.key
  }

  this.workflowService.getAllIngestJobByClientId(requestModel)
  .subscribe(
    (data)=>{
        this.ingestJobData=data;
        this.ingestJobDropDown=reMapForAutoComplete(this.ingestJobData,"ingestjobId", "jobName");
        // this.ingestJobData.forEach(item=>{
        //   this.ingestJobDropDown.push({key:item.ingestjobId,value:item.jobName});
        // })

    },
    (err)=>{     
        this.toast.showError(err.error.message, "error");
    }
  )
}

getAllCustomJobByClientId(){
  this.ingestJobDropDown=[];
  this.customJobsList=[];
  const requestModel={
    "clientId":this.setupJobForm.controls.client.value.key
  }

  this.workflowService.getCustomJobsByClient(requestModel)
  .subscribe(
    (data)=>{
        this.customJobsList=data;
        this.ingestJobDropDown=reMapForAutoComplete(this.customJobsList, "customJobId", "jobName");
        // this.customJobsList.forEach(item=>{
        //   this.ingestJobDropDown.push({key:item.customJobId,value:item.jobName});
        // })

    },
    (err)=>{
      this.toast.showError(err.error.message, "error");
    }
  )
}

saveJobToWorkflow(data){

  if(this.setupJobForm.controls.client.value===null || this.setupJobForm.controls.client.value.key===0){
    return this.toast.showError("Client Not Selected","error");
  }

  if(this.setupJobForm.controls.workflow.value===null || this.setupJobForm.controls.workflow.value.key===0){
   return this.toast.showError("Workflow Not Selected","error");
  }

  const workflowDetail={
    "workflowdetailId":this.workflowJobDetailsEdit!==null?this.workflowJobDetailsEdit.workflowDetailId:0,
    "workflowId":this.setupJobForm.controls.workflow.value.key,
    "jobType":data.jobType,
    "jobId":data.jobId,
    "jobDesc":data.description,
    "trigger1": data.trigger1,
    "dependentUpon":0,
    "nextjobId":0,
    "enabled":data.enabled===true?1:0,
    "target":data.targetApi,
    "custom":0,
    "frequency":"Daily",
    "sequence":this.workflowJobDetailsEdit!==null?this.workflowJobDetailsEdit.sequence:0,
    "continueOnError":data.errorCase===true?1:0,
  }
 // this.panelForm.toggleSlider();
  this.saveWorkflowDetails(workflowDetail);
 
  
}


saveWorkflowDetails(data){

  this.workflowService.saveWorkflowDetails(data)
  .subscribe(
    data=>{
      this.toast.showSuccess('success','data saved');
      this.resetForm();
      this.getAllWorkflowJob();
    },
    error=>{
      this.toast.showError('error',error.error.message);
    }
    
  )
 // this.panelForm.toggleSlider();
}

doAction(data){
  if(data===false){
    this.visible1=false;
    this.visible=false;
  }
  if(data===true){
    this.resetForm();
    this.getAllClientMasterData(this.setupJobForm.controls.client.value.key);
    this.visible1=true;
    this.visible=true;
    
  }
}

onCustomChange(e){
   
  if(this.modalForm.controls.jobType.value.key==='1'){
    console.log("true value :"+e.target.value)
    this.isCustomJob=true;
  }
  if(this.modalForm.controls.jobType.value.key==='0'){
    console.log("false value :"+e.target.value)
    this.isCustomJob=false;
    this.getAllClientMasterData(this.setupJobForm.controls.client.value.key);
  }
  }

  saveJob(){
    if(this.modalForm.invalid){
      if(this.modalForm.controls.jobName.invalid){
        this.toast.showWarn('Job Name is mandatory','error');
        this.panelForm.toggleSlider();
        return;
      }

      if(this.modalForm.controls.jobDesc.invalid){
        this.toast.showWarn('Description should be in Specified Format','error');
        this.panelForm.toggleSlider();
        return;
      }
    }
      this.panelForm.toggleSlider();
      console.log("clientId for savejob ",this.setupJobForm.controls.client.value.key)
    if(this.setupJobForm.controls.client.value===null || this.setupJobForm.controls.client.value===undefined || this.setupJobForm.controls.client.value.key===0){
      this.toast.showError('error','client is mandatory')
      return;
    }

  
    if(this.modalForm.controls.jobName.value===null || this.modalForm.controls.jobName.value===undefined){
      this.toast.showError('error','job name is required')
      return;
    }
    

    if(this.modalForm.controls.jobType.value!==null && this.modalForm.controls.jobType.value.key==='0')
    {

      if(this.modalForm.controls.clientFile.value===null || this.modalForm.controls.clientFile.value.key===null){
        this.toast.showError('error','client file master is required')
        return;
      }
    const ingestJob={
       "ingestjobId":0,
       "jobName":this.modalForm.controls.jobName.value,
       "jobDesc":this.modalForm.controls.jobDesc.value,
       "clientId":this.setupJobForm.controls.client.value.key,
       "clientfileId":this.modalForm.controls.clientFile.value.key,
    }

    this.workflowService.saveIngestJob(ingestJob)
    .subscribe(
      data=>{
        this.toast.showSuccess('success','Job Saved');
        this.getAllIngestJobByClientId();
        this.visible1=false;
        this.visible=false;
        this.modalForm.reset();
      },
      error=>{
        this.toast.showError('error',error.error.message);
      }
    )
    }

    if(this.modalForm.controls.jobName.value===null){
      return this.toast.showError("name is mandatory","error");
    }

    // if(this.modalForm.controls.apiMethod.value===null){
    // return this.toast.showError("api method is mandatory","error");
    // }

    if (
      this.modalForm.controls.jobType.value !== null &&
      this.modalForm.controls.jobType.value.key === "1"
    ) {
      const customJobs = {
        customJobId: 0,
        jobName: this.modalForm.controls.jobName.value,
        description: this.modalForm.controls.jobDesc.value,
        clientId: this.setupJobForm.controls.client.value.key,
        targetApi: "",
        apiMethod: this.modalForm.controls.apiMethod.value.value,
      };
      this.workflowService.saveCustomJob(customJobs).subscribe(
        (data) => {
          this.toast.showSuccess("success", "Job Saved");
          this.getAllIngestJobByClientId();
          this.visible1 = false;
          this.visible = false;
          this.modalForm.reset();
        },
        (error) => {
          this.toast.showError("error", "error saving job");
        }
      );
    }

  }

  getAllClientMasterData(clientId){
    console.log("clientFile master");
    this.clientMasterList=[];
    this.clientFileMasterService.getClientFileMasterListByClient(clientId).subscribe(
      (data) => {
        const clientMasterData=data;
        for(var a=0;a<clientMasterData.length;a++){
         // console.log("type "+clientApplicationFileType[a].type);
          this.clientMasterList.push({ key: clientMasterData[a].clientfilemasterId, value: clientMasterData[a].name });
        }

        // clientFileMasterId:this.cookieService.get("clientFileMasterId"),
        // clientFileMasterName:this.cookieService.get("clientFileMasterName")
        this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
          par => {
    
            if(par.has("clientFileMasterId")  && par.has("clientFileMasterName")){
        
            this.modalForm.get("clientFile").setValue({
              key: par.get("clientFileMasterId"),
              value: par.get('clientFileMasterName')
            });
            }
          }
          )
  },
      (err) => {
      //  this.toast.showSuccess("Status Not Changed", "error");
      this.toast.showError(err.error.message, "error");
      }
    
    );
  
  }

  updateSequence(data){
    this.jobsequenceData=[];
   const wokrData:WorkflowJobDetails[]=data;
    //console.log("in main component ",data)
    
    let i=1;
    wokrData.forEach(item => {
      //console.log("hello ",item.workflowDetailId)
      const workflowDeatil={
        "sequence":i,
        "workflowdetailId":item.workflowDetailId
      }
      this.jobsequenceData.push(workflowDeatil);
      i++;
      
    });

    this.updateWorkflowJobSequence(this.jobsequenceData);
    

  }

  updateWorkflowJobSequence(data){
    this.loader.showLoader();
    this.workflowService.updateWorkflowJobSequence(data)
    .subscribe(
      data=>{
        this.toast.showSuccess('success','job sequence updated');
        this.getAllWorkflowJob();
        this.resetForm();
        this.loader.hideLoader();
      },
      error=>{
        this.loader.hideLoader();
        this.toast.showError(error.error.message, "error");
      }
    )

  }

  resetForm(){
    this.workflowJobDetailsEdit=null;
    this.setupJobSelectorComponent.resetForm();
  }

  getPreviousState() {
    console.log("prevous state")
    this.observalbleDataService.inventoryChanged$.subscribe(
      data => {
        this.dataTransferModel = data;
        if (this.dataTransferModel.clientId !== undefined  ) {
          this.setupJobForm.get("client").patchValue({
            key: this.dataTransferModel.clientId,
            value: this.dataTransferModel.clientName,
          });
          this.company=this.setupJobForm.controls.client.value.value;
          //this.getAllWorkflow();
          this.onClientChange();
         
         
          
          
        }
      });
      console.log("prevous state2 ",this.setupJobForm.controls.client.value);
      if( this.setupJobForm.controls.client.value===null){
          const clientIdC=this.cookieService.get('clientId');
          const clientNameC=this.cookieService.get('clientName');
          console.log(clientIdC);
          console.log(clientNameC);
         
          
          if(clientIdC!==undefined && clientIdC!==null){
            this.setupJobForm.get("client").patchValue({
                     key: clientIdC,
                     value: clientNameC,
                     });
                     this.getAllWorkflow();     
                           
          }
        }
      
    }

    maintainClientState(){
      this.dataTransferModel = {
        clientFileId: 0,
        clientId: this.setupJobForm.controls.client.value.key,
        isRedirected: 1,
        applicationId: 0,
        toDate: '',
        fromDate: '',
        clientName: this.setupJobForm.controls.client.value.value,
        page: '',
        fileName: '',
        workflowName: this.setupJobForm.controls.workflow.value.value,
        workflowId: this.setupJobForm.controls.workflow.value.key,
        dataType: '',
        dataTypeId: 0
      }
      this.observalbleDataService.addToInventory(this.dataTransferModel); 
    }
    
    goToManage(){
      this.router.navigate(['/workflow']);
    }

  // ngOnDestroy() {
  //   this.activeRoute$.unsubscribe();
  // }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.setTitle();
      this.getClients();
      if(this.nevigateFlag){
        this.getPreviousState();
      }
      
    }

    onLoad(){
      console.log("data.key",this.setupJobForm.controls.workflow.value.key);
      console.log("inside get selected");
      console.log("this.setupJobForm.controls.client.value.key ",this.setupJobForm.controls.client.value.key); 
     //// this.getAllClientMasterData(this.jobListForm.controls.client.value.key);
     //this.getAllWorkflow(this.setupJobForm.controls.workflow.value);
      this.getAllWorkflowJob();
      this.panelFilter.toggleSlider();
      this.company=this.setupJobForm.controls.client.value.value;
      this.panelFilter.toggleSlider();
      this.workflowName=this.setupJobForm.controls.workflow.value.value;
      this.panelFilter.toggleSlider();
      }
  


    getClients() {

      //  this.loader.showLoader();

        this.clientService.getAllClients().subscribe(

          (data) => {

           // this.loader.hideLoader();

            this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");

            console.log(this.clientsList)


            this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
              par => {
        
                if(par.has("clientIdm")  && par.has("workflowIdm")){
            
                this.setupJobForm.get("client").setValue({
                  key: par.get("clientIdm"),
                  value: par.get('clientNamem')
                });
                //this.getAllClientMasterData(this.setupJobForm.controls.client.value.key);
                this.onClientChange();
                this.panelFilter.toggleSlider();
                }
              } 
                )

          },

          (err) => {

       //     this.loader.hideLoader();

            this.toast.showError(err.error.message, "error");

          }

        );

      }

      toggleCreateNewJob(){
        document.getElementById("setupJob").style.display="none";
        document.getElementById("header_html").textContent="Create New Job";
        document.getElementById("createNewJob").style.display="";
        
       this.panelForm.toggleSlider()


      }

      toggleSetupJob(){
        document.getElementById("header_html").textContent="Setup Job";
        document.getElementById("setupJob").style.display="";
        document.getElementById("createNewJob").style.display="none";

        this.panelForm.toggleSlider()
      }

      newSetUpJob(){
        document.getElementById("header_html").textContent="Setup Job";
document.getElementById("setupJob").style.display="";
document.getElementById("createNewJob").style.display="none";
         this.resetForm();
         this.panelForm.toggleSlider();
      }

      createNewJob()
      { 
        document.getElementById("setupJob").style.display="none";
        document.getElementById("header_html").textContent="Create New Job";
        document.getElementById("createNewJob").style.display="";
        this.panelForm.toggleSlider();

      }
}
