import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[DecimalNumbersWithOrWithoutPercentOnly]'
})
export class DecimalNumbersWithOrWithoutPercentDirective {

  @Input() validationFieldsType: string;

  constructor(private el: ElementRef) { }
 
 @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    // Allow number with dot and %
    inputElement.value = value.replace(/[^0-9.%]/g, '');

    //Allow only single dot
    const dotIndex = inputElement.value.indexOf('.');
    if (dotIndex !== -1 && inputElement.value.indexOf('.', dotIndex + 1) !== -1) {
      inputElement.value = inputElement.value.slice(0, dotIndex + 1) + inputElement.value.slice(dotIndex + 1).replace(/\./g, '');
    } else if (inputElement.value !== value) {
      inputElement.value = inputElement.value;
    }

     // Allow only one "%" at the end of the value
     if (inputElement.value.endsWith('%')) {
      inputElement.value = inputElement.value.replace(/%/g, '');
      inputElement.value += '%';
    }

   // Disallow numbers after "%" symbol
   if (inputElement.value.includes('%')) {
    const percentIndex = inputElement.value.indexOf('%');
    inputElement.value = inputElement.value.slice(0, percentIndex + 1);
  }

   // Disallow percent just after dot
   const percentIndex = inputElement.value.indexOf('%');
   if (dotIndex !== -1 && percentIndex !== -1 && percentIndex === dotIndex + 1) {
    inputElement.value = inputElement.value.slice(0, dotIndex + 1);
   }
  }

}






