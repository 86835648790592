import { DatePipe } from '@angular/common';
import { Component, OnInit ,SimpleChanges,OnChanges,ViewChild, QueryList, ElementRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { FormBuilderService } from 'src/app/formsy/services/form-builder.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { WorkflowJobTableComponent } from 'src/app/workflows/components/workflow-job-table/workflow-job-table.component';
import { WorkflowResponseModel } from '../../models/workflow.model';
import { SeverityService } from '../../services/severity.service';
import { ShockMasterService } from '../../services/shockMaster.service';
import { WorkflowJobComponent } from '../workflow-job/workflow-job.component';
import { environment } from "src/environments/environment";
import { StressScenarioService } from '../../services/stressScenario.service';
import{
  ViewChildren
}from '@angular/core';

@Component({
  selector: 'app-multifactor-risk',
  templateUrl: './multifactor-risk.component.html',
  styleUrls: ['./multifactor-risk.component.scss'],
  providers: [FormBuilderService],
})
export class MultifactorRiskComponent implements OnInit,OnChanges {
  @ViewChild(WorkflowJobTableComponent, { read: false, static: false })
  workflowJobTableComponent: WorkflowJobTableComponent;

  @ViewChild(WorkflowJobComponent, { read: false, static: false })
  workflowJobComponent:WorkflowJobComponent;

  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  state:string;
 
  multifactorForm:FormGroup=null;
  workflowResponseModel:WorkflowResponseModel[]=[];
  showWorkflowTable:boolean;
  stressShocksList: any[];
  stressScenarioList: any[];
  form:FormGroup=null;

  stressShockData:any;
  shocksForm:any;
  reportUrl:string;
  reportId:number;
  url:string;
  reportCollapse:boolean=false;
  multifactorCollapse:boolean=true;
  transferData:boolean=false;
  workflowUpdatedUrl:string;
  multifactorRiskName:string;
  multifactorRiskCode:string;
  companyName:string=null;
  companyId:any;
  headerForm:FormGroup;
  asofdate:string=null;
  menu:string;
  SelectedMultifactorList:{shocksCode: any; shockName: any; baseline: any; medium: any; severe: any; }[] =[]
  multifactorCombinationList:{multifactorCombinationId:any; multifactorCombinationCode:any ; multifactorCombinationName: any; multifactorRiskList:{}}[] =[]
  multifactorCombinationListAll:{multifactorCombinationId:any; multifactorCombinationName: any; multifactorRiskList:{}}[] =[]
  constructor(private ShockMasterService:ShockMasterService, private stressScenarioService:StressScenarioService,private activeRoute: ActivatedRoute,private toastService:ToastService,
    private titleService: TitleService,private formService: FormBuilderService,private _fb: FormBuilder,
    private SeverityService: SeverityService,private fileService:FileService,private datePipe:DatePipe) {
    
    this.menu=localStorage.getItem("menu");
    this.state=localStorage.getItem("stateflag");  
    this.titleService.setTitle("Multifactor Risk");

    this.headerForm = new FormGroup({
      client: new FormControl(),
    });

    this.multifactorForm = new FormGroup({
      shocksCode: new FormControl(null),
      shockName: new FormControl(null),
      baseline:new FormControl(null),
      medium:new FormControl(null),
      severe:new FormControl(null),
    });

   }

  ngOnInit(): void {
    
   
  //   this.getAllStressScenarioList(); 
   // this.generateMultifactor();

   this.fileService.getClientByUserId().subscribe(
    (data) =>{
    
      for(var i=0;i<data.length;i++){
        console.log("data  ",data[i].clientId)
        this.companyId=data[i].clientId
        this.companyName=data[i].clientName
      }
      this.headerForm.get("client").setValue({
        key: this.companyId,
        value: this.companyName
      });
     console.log("this.headerForm",this.headerForm)
    
     this.getAllMultifactorCombinationList(this.headerForm.controls.client.value.key);
     
    },
    (err)=>{
      this.toastService.showError(err.error.message, "error")
    }
    );
    
    this.getAllShockList();
  }

  
  private buildform(list: any) {
    console.log("buildForm list",list)
    let arr: any = [];
      list.forEach(item => {
        arr.push(this.buildFormDynamic(item))
      });
      this.form = this._fb.group({
       multifactorData: this._fb.array(arr)
      })
      this.shocksForm=this.form.value.multifactorData;
console.log("multifactorArrayForm",this.shocksForm);
  }

  private buildFormDynamic(item): FormGroup {
  //  console.log("buildFormDynamic check",item)
    return this._fb.group({
      // multifactorRiskId:[item.multifactorRiskId],
      shocksCode:[item.key],
      shockName:[item.value],
      baseline: "true1",
      medium: false,
      severe: false,

    })
   
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.rowData && changes.rowData.currentValue) {
      let rowData=changes.rowData.currentValue;
      console.log("NgOnchange rowData  ",rowData);

    }
    }

getAllStressScenarioList(){
    
      this.stressScenarioService.getAllStressScenarioList().subscribe((data)=>{
        this.stressScenarioList=data;
    console.log("stressScenarioNameValueList", this.stressScenarioList);
       
      },
      (err) => {
        this.toastService.showError(err.error.message, "error");
        
      }
      );
    }
    

  getAllShockList(){
    
    this.ShockMasterService.getStressShockData().subscribe(
    (data)=>{
      console.log("shockList Data", data)
      this.stressShockData= data;
      this.stressShocksList = data.filter(e =>e.filterFlag===true);
      for(var i=0;i<this.stressShockData.length;i++){
     if(this.stressShockData[i].stressShocksCode=="STRESS_MF001"){
      this.reportId=this.stressShockData[i].reportId;
      this.reportUrl=this.stressShockData[i].instanceUrl;
      }
     }
     this.url=environment.reportUrl+this.reportUrl;
     console.log("run url id on",this.url)
     this.fileService.getDateByReportId(this.reportId,this.companyId).subscribe(
              
      data=>{
       this.asofdate=data.asOfDate;
      // console.log("asOfDate---",this.asofdate);
       if (this.reportUrl.includes("?")) {
       this.url=environment.reportUrl+this.reportUrl+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
       }
       else{
        this.url=environment.reportUrl+this.reportUrl+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
       }
       console.log("on init url",this.url)
      },
      (err) => {
        this.toastService.showError(err.error.message, "error");
        
      });
      
     this.stressShocksList = reMapForAutoComplete(this.stressShocksList, "stressShocksCode", "shockName");
     console.log("this.stressShocksList",this.stressShocksList);
     this.buildform(this.stressShocksList);   
    console.log("selected value --------",this.multifactorForm.value);

    },
    (err) => {
      this.toastService.showError(err.error.message, "error");
      
    }
    );
  }

  getAllMultifactorCombinationList(data1){
    this.ShockMasterService.getAllMultifactorCombination(data1).subscribe(
      (data)=>{
        console.log("multifactorCombinationList data",data)
        this.multifactorCombinationListAll=data;
        console.log("this.multifactorCombinationListAll list",this.multifactorCombinationListAll)
    
      },
      (err) => {
        this.toastService.showError(err.error.message, "error");
        
      }
      );
  }


  baselineEnable(rowData){
   console.log("multifactorId baseline",rowData);
  
   this.shocksForm.forEach(item =>{
    if(rowData.shocksCode===item.shocksCode){
      item.baseline="true1",
      item.medium=false,
      item.severe=false
    }
  })

   console.log("medium set form",this.shocksForm)
  }

  mediumEnable(rowData){
    console.log("multifactorId medium",rowData);

    this.shocksForm.forEach(item =>{
      if(rowData.shocksCode===item.shocksCode){
        item.baseline=false,
        item.medium="true2",
        item.severe=false
      }
    })
    console.log("medium set form", this.shocksForm)

  }

  severeEnable(rowData){
    console.log("multifactorId severe",rowData);
    this.shocksForm.forEach(item =>{
      if(rowData.shocksCode===item.shocksCode){
        item.baseline=false,
        item.medium=false,
        item.severe="true3"
      }
    })

    console.log("severe set form",this.shocksForm)
  }

  emitSelected(data: any, event: any,rowData){
   
    console.log("rowData",rowData)
    console.log("event",event)
    console.log("data",data)
  
  if(event.target.checked){
   
    this.shocksForm.forEach(item =>{
      if(rowData.shocksCode===item.shocksCode){
        this.SelectedMultifactorList.push({
        // multifactorRiskId:item.multifactorRiskId,
        shocksCode:item.shocksCode,
        shockName:item.shockName,
        baseline:item.baseline,
        medium:item.medium,
        severe:item.severe,
        })
      }
    });
    console.log("Selected Multifactors Are:", this.SelectedMultifactorList);

    
  }else{
    
    this.SelectedMultifactorList.forEach(item =>{
      let index = this.SelectedMultifactorList.indexOf(item)
      if(item.shocksCode==rowData.shocksCode){
      //  delete this.SelectedMultifactorList[index]
       this.SelectedMultifactorList.splice(index,1);
       console.log("index: ",index)
       
       return;
      }
    
    })
   

    console.log("else rowData", rowData)
   
 
    console.log("Else Selected Multifactors Are:", this.SelectedMultifactorList);
  }
    
  }

  addCombination(){
       
   const data = this.multifactorCombinationList;

    this.ShockMasterService.saveMultifactorCombination(data).subscribe(
      (data)=>{ 
        console.log("generate multifactor data ",data);
        
        this.getAllMultifactorCombinationList(this.headerForm.controls.client.value.key);
       
        console.log("this.multifactorCombinationListAll on addCombination", this.multifactorCombinationListAll)
        this.toastService.showSuccess("Multifactor Combination Added", "Successfully");
        this.uncheckAll();
       
         
        
          }
      )
     
       
   }

  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.SelectedMultifactorList.length=0;
    console.log("uncheck  this.SelectedMultifactorList.splice", this.SelectedMultifactorList)
  }

  addSelectedData(){
    this.transferData=true;
  }

  addTodoView(event) {
    console.log("event ",event);
    console.log("event ",event.target.value);
    if(this.SelectedMultifactorList.length>=2){
   
    this.multifactorRiskCode= '';
    this.multifactorRiskName= '';

    this.SelectedMultifactorList.forEach(item =>{
      this.multifactorRiskCode=this.multifactorRiskCode + item.shocksCode
      this.multifactorRiskName=this.multifactorRiskName + item.shockName

      if((item.baseline==="true1")){
        this.multifactorRiskCode=this.multifactorRiskCode + " (Baseline)";
        this.multifactorRiskName=this.multifactorRiskName + " (Baseline)";
    }
    if((item.medium==="true2")){
      this.multifactorRiskCode=this.multifactorRiskCode + " (Medium)";
      this.multifactorRiskName=this.multifactorRiskName + " (Medium)";
    }
    if((item.severe==="true3")){
      this.multifactorRiskCode=this.multifactorRiskCode + " (Severe)";
      this.multifactorRiskName=this.multifactorRiskName + " (Severe)";
    }
    this.multifactorRiskCode=this.multifactorRiskCode + ' + '
    this.multifactorRiskName = this.multifactorRiskName + ' + '
      
    })
    this.multifactorRiskCode=this.multifactorRiskCode.substring(0, this.multifactorRiskCode.length - 3);
    this.multifactorRiskName = this.multifactorRiskName.substring(0, this.multifactorRiskName.length - 3);

    console.log("selected addVariable Name",this.multifactorRiskName)
    console.log("selected addVariable Code",this.multifactorRiskCode)
    this.SelectedMultifactorList.forEach(item=>{
      if(item.baseline==="true1"){
        item.baseline=true;
      }
      if(item.medium==="true2"){
        item.medium=true;
        }
        if(item.severe==="true3"){
          item.severe=true;
        }
    });

    let multifactorRiskList = this.SelectedMultifactorList;
    this.multifactorCombinationList.length=0;
    this.multifactorCombinationList.push({
      multifactorCombinationId:0,
      multifactorCombinationCode:this.multifactorRiskCode,
      multifactorCombinationName:this.multifactorRiskName,
      multifactorRiskList:multifactorRiskList
     
    });
    console.log("multifactorCombinationList",this.multifactorCombinationList)
    this.addCombination();
   
  
      }else{
        this.toastService.showWarn("Select Atleast two multifactor","error");
        return;
      }
    }

deleteMultifactorComb(rowData){
    
    const data = rowData.multifactorCombinationId;
    console.log("deleted multifactor data ",data);
     this.ShockMasterService.deleteMultifactorCombination(data).subscribe(
       (data)=>{ 
        
         this.toastService.showSuccess("Multifactor Combination Deleted", "Successfully");
         this.getAllMultifactorCombinationList(this.headerForm.controls.client.value.key);    
           }
       )
      
}
   
deleteTodoView(rowData) {
        console.log("rowData", rowData)
        this.deleteMultifactorComb(rowData);
       
     this.multifactorRiskName=null;
     
        console.log("delete multifactorCombinationList ",this.multifactorCombinationList)
      }

onTabOpen(e){
   this.reportCollapse=false; 
   this.multifactorCollapse=true;
}

onReportTabOpen(e){
   this.multifactorCollapse=false; 
   this.reportCollapse=true; 
   console.log("onReportTabOpen e",e);
 
}

// generateMultifactor(){
    
//   const data = null;
 
//    this.ShockMasterService.generateMultifactorReport(data).subscribe(
//      (res)=>{ 
//       if(res.url!=null){
//         this.reportUrl=environment.reportUrl+(res.url).replace('false','true'); 
//         this.reportId = res.appId;
//       }else{
//         this.toastService.showWarn("Report url is null", "Error")
//       }
//       //  this.toastService.showSuccess("Report Generated", "Successfully");
//        this.getAllMultifactorCombinationList();   
//          }
//      )
    
// }

runReport(){
  console.log("run report id",this.reportId)
 
  this.getreportMappedWorkflows(this.reportId);
  
  //  this.generateMultifactor();
  // this.onReportTabOpen('e');
}  


refresh(){
        //document.getElementById('FrameID').contentWindow.location.reload(true);
       }            
  
    
getreportMappedWorkflows(reportId){
   //console.log("appId  ",this.reportId)
      this.fileService.getWorkflowListByReportId(reportId).subscribe(
      data =>{
   // this.loader.hideLoader();
    console.log("reportmappedworkflows ",data)
              
   this.workflowResponseModel=data
        
   if(this.workflowResponseModel.length==0){
   console.log("toast messege",this.workflowResponseModel.length);
   this.workflowResponseModel=[];
   this.toastService.showWarn('no Workflow is linked ','error');
   return;
}
              
this.workflowResponseModel.forEach(item=>{
// console.log("item",item.isActive===1);
              
item.isActiveDisplay=item.isActive===1?'Yes':'No'
item.workflowTypeDisplay=item.workflowType===1?'System':'Custom'
item.asOfDate=item.asOfDate!==null?this.datePipe.transform(item.asOfDate,'MM-dd-yyyy'):""
              
 })
                                              
 this.workflowJobTableComponent.executeWorkflow(this.workflowResponseModel[0]);
        
                                                     
 },
 error => {
 this.toastService.showError(error.error.message, "error");
 }
 
 )
 }
 executeworkflow(data){

  console.log("data ",data)
  this.workflowJobComponent.executeWorkflow(data);
  this.reportCollapse=true;
  this.multifactorCollapse=false;        
}


dateEmit(date){
  this.asofdate=date;
  this.workflowUpdatedUrl=environment.reportUrl+this.reportUrl;
  if (this.reportUrl!=null) {

    
    if (this.reportUrl.includes("?")) {

      
      this.url = this.workflowUpdatedUrl+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
         
    }
    else{
     
      this.url= this.workflowUpdatedUrl+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
      
    }
    console.log("report url ",this.reportUrl);

    if(!this.reportUrl.includes("open-view/")){

    }

  }
   }


}
