import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { ClientFileFormComponent } from './components/client-file-form/client-file-form.component';
import { ClientFileListComponent } from './components/client-file-list/client-file-list.component';
import { ClientFileHeaderComponent } from './components/client-file-header/client-file-header.component';
import { UiModule } from "../ui/ui.module";



@NgModule({
    declarations: [
      ClientFileFormComponent,
      ClientFileListComponent,
      ClientFileHeaderComponent
    
    ],
    imports: [SharedModule,UiModule],
    exports: [
        ClientFileFormComponent,
        ClientFileListComponent,
        ClientFileHeaderComponent
    ],
    providers: []
  })
  export class ClientFileMasterModule {}
  