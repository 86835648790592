import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[DecimalNumbersOnly]'
})
export class DecimalNumbersDirective {

  @Input() validationFieldsType: string;

  constructor(private el: ElementRef) { }
 
 @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    // Allow with number and dot
    inputElement.value = value.replace(/[^0-9.-]/g, '');

    const dotIndex = inputElement.value.indexOf('.');
    if (dotIndex !== -1 && inputElement.value.indexOf('.', dotIndex + 1) !== -1) {
      inputElement.value = inputElement.value.slice(0, dotIndex + 1) + inputElement.value.slice(dotIndex + 1).replace(/\./g, '');
    } else if (inputElement.value !== value) {
      inputElement.value = inputElement.value;
    }

        // Allow only one negative symbol at the start
        if ((inputElement.value.match(/-/g) || []).length > 1) {
          inputElement.value = inputElement.value.replace(/-/g, '');
        } else if (inputElement.value.startsWith('-')) {
          inputElement.value = inputElement.value;
        } else {
          inputElement.value = inputElement.value.replace(/-/g, '');
        }
  }

}






