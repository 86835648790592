import { NgModule } from "@angular/core";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { PanelMenuModule } from "primeng/panelmenu";
import { MenuComponent } from "./components/menu/menu.component";
import { SidebarModule } from "primeng/sidebar";
import { HeaderComponent } from "./components/header/header.component";
import { LogoComponent } from "./components/logo/logo.component";
import { BlueHeaderComponent } from "./components/blue-header/blue-header.component";
import { SharedModule } from "../shared/shared.module";
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { MainSidebarComponent } from './components/main-sidebar/main-sidebar.component';
import { LayoutMasterComponent } from './components/layout-master/layout-master.component';
import { SidePanelComponent } from "./components/side-panel/side-panel.component";

@NgModule({
  declarations: [
    SidebarComponent,
    MenuComponent,
    HeaderComponent,
    LogoComponent,
    BlueHeaderComponent,
    MainHeaderComponent,
    SubHeaderComponent,
    MainSidebarComponent,
    LayoutMasterComponent,
    SidePanelComponent
  ],
  imports: [SharedModule, SidebarModule, PanelMenuModule],
  exports: [
    SidebarComponent,
    HeaderComponent,
    BlueHeaderComponent,
    LogoComponent,
    MainSidebarComponent,
    MainHeaderComponent,
    SubHeaderComponent,
    LayoutMasterComponent,
    SidePanelComponent

  ]
})
export class UiModule {}
