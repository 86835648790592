import { Component, OnInit, ViewChild } from '@angular/core';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { DatePipe } from "@angular/common";
import { NotificationSelectorComponent }  from 'src/app/notificationComp/components/notification-selector/notification-selector.component';
import { NotificationModel } from '../../models/notification.model';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { UserService } from 'src/app/user/services/user.service';
import { reMapForAutoCompleteMultiSelect } from 'src/app/utils/re-mapper-multiselect';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @ViewChild(NotificationSelectorComponent, { read: false, static: false })
  notificationSelectorComponent:NotificationSelectorComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  activeRoute$: Subscription;
  notificationList:any[];
  editNotificationList:NotificationModel=null;
  rolePermission:any;
  usersList: any[];
  reportsList: any[];
  state:string;
  menu:string;
  submenu:string;
  constructor(private fileService: FileService, 
    private toast:ToastService,
    private loader:LoaderService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private datePipe: DatePipe,
    private userService: UserService,
    private applicationService: SspApplicationService) {

      this.menu=localStorage.getItem("menu");
      this.submenu=localStorage.getItem("submenu");
      this.state=localStorage.getItem("stateflag");
    }

  ngOnInit(): void {

    this.buttonShowHidePermission();

    

    // console.log("editNotificationList   ",this.editNotificationList)
    // console.log("editNotificationList.notificationId  =",this.editNotificationList.notificationId)

  }


  getAllNotifications(){
   
    this.fileService.getAllNotificationType().subscribe(
      (data) => {
        console.log("notification",data);
        this.notificationList=data;
        this.notificationList.forEach(item=>{
          item.notification.createdAt=this.datePipe.transform(item.notification.createdAt,"MM/dd/yyyy")
          item.notification.modifiedAt=this.datePipe.transform(item.notification.modifiedAt,"MM/dd/yyyy")
        
         })
         console.log("All Notification List: ",this.notificationList)
       // this.loader.hideLoader();
        
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      }  

    )

  }

  getAllUesrs(){
    this.userService.getAllUserList().subscribe(
      (data) => {
        this.usersList = this.reMapForUserAutoCompleteMultiSelect(data, "userId", "userName");
      console.log("User List:",this.usersList);
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    )
  }

  getAllReports(){
    this.applicationService.getAppDataForReportPage().subscribe(
      (data) => {
        this.reportsList = this.reMapForReportAutoCompleteMultiSelect(data, "appId", "appName");
        console.log("Report List:",this.reportsList);
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    )

  }

  reMapForUserAutoCompleteMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { userId: string | number; userName: string }[] {
    return data.map(d => ({ userId: d[keyCol], userName: d[valCol] }));
  }

  reMapForReportAutoCompleteMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { reportId: string | number; reportName: string }[] {
    return data.map(d => ({ reportId: d[keyCol], reportName: d[valCol] }));
  }


  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }



  addNotification(data){

    let userStatus=0;
    if(data.statusMap.key==="Active"){
      userStatus=1;
    }
    let userSelectList = data.users;
    let reportSelectList = data.reports;

    let notification = {
      notificationId:this.editNotificationList!==null?this.editNotificationList.notification.notificationId:0,
      notificationName:data.notificationName,
      status:userStatus
    }

  const notificationData={
    "notification": notification,
    "notificationUsersList": userSelectList,
    "notificationReportsList": reportSelectList

  }
  console.log("NotificationData Model: ",notificationData);
  this.fileService.saveAllNotification(notificationData).subscribe(
    (data) => {
      
     // this.loader.hideLoader();
     this.toast.showSuccess('saved successfully','success');
     this.notificationSelectorComponent.resetForm();
     this.editNotificationList=null;
     this.getAllNotifications();
    },
    (err) =>{
      this.toast.showError(err.error.message, "error");
    }  


  )

  console.log("notificationData123  ",notificationData)


  }


  addNewNotification(){
this.panelForm.toggleSlider();
this.notificationSelectorComponent.resetForm();

  }



  editNotificationData(data){
this.panelForm.toggleSlider();
    this.fileService.getNotificationById(data.notification.notificationId).subscribe(

      (data :NotificationModel) => {
        
      this.editNotificationList=data;  
      console.log("editData    ",data)
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      }  
    );
  }

  deleteNotification(data){
    const notificationModel ={
      "notificationId": data.notificationId
    }

    this.fileService.deleteNotification(notificationModel).subscribe(
      data => {
        // this.loader.hideLoader();
        this.toast.showSuccess('Success', 'Notification Data Deleted successfully');
        // this.resetForm();
        this.notificationSelectorComponent.resetForm();
        this.getAllNotifications();
      },
      err => {
        // this.loader.hideLoader();
        this.toast.showError('Error', 'Error Deleting Notification data');
      }
    );
  }

  resetNotification(){

    this.notificationSelectorComponent.resetForm();
    this.editNotificationList=null;

  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};

    callNgOnInitMethods(){
      this.setTitle();
      this.getAllNotifications();
      this.getAllUesrs();
      this.getAllReports();
    }


}
