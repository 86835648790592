import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppWithInstanceModel, InstanceModel, SspApplicationModel } from '../models/selfServicePortal.model';

@Injectable({
  providedIn: 'root'
})
export class SspApplicationService {

  /**
 * @param  {HttpClient} privatehttp
 */
  constructor(private http: HttpClient) { }

  getApplicationData(data) {
    const genericRequestData={
      "appId":0
    }
    return this.http.get<SspApplicationModel[]>(environment.baseUrl + "/apis/ssp/getApplicationData");
  }

  getAppDataWithInstance(data) {
    const genericRequestData={
      "appId":0
    }
    return this.http.post<AppWithInstanceModel[]>(environment.baseUrl + "/apis/ssp/getAppDataWithInstance",genericRequestData);
  }

  getAppDataForReportPage() {
    return this.http.get<AppWithInstanceModel[]>(environment.baseUrl + "/apis/ssp/getAllApplicationData");
  }

  getReportDataWithGroup(groupId) {
    return this.http.post<AppWithInstanceModel[]>(environment.baseUrl + "/apis/ssp/getReportDataWithGroup",groupId);
  }


  createApplicationData(data) {
    return this.http.post(`${environment.baseUrl}/apis/ssp/createApplicationData`, data);
  }

createGroupData(data) {
  return this.http.post(`${environment.baseUrl}/apis/ssp/saveAppGroup`, data);
}

deleteById(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/ssp/deleteApplicationById`,data
      );
  }

  getAllInstance(data) {
    return this.http.post<InstanceModel[]>(environment.baseUrl + "/apis/ssp/getAllInstance1",data);
  }

  getInstanceByAppId(data) {
    return this.http.post<InstanceModel[]>(
      `${environment.baseUrl}/apis/ssp/getInstanceByAppId`,data
    );
  }

  getAllReports(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getApplicationData");
  }
  
  getAllGroupData(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getAllReportGroupForUser");
  }

  getAllGroupDataForGrouppage(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getAllReportGroup");
  }

  getAllGroupDataWithStatus(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getAllReportGroupWithStatus");
  }

  deleteGroup(data) {
    return this.http.post<any>(
      `${environment.baseUrl}/apis/ssp/deleteGroup`,data
      );
  }

  getApplicationList(){
  return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getAllApplicationDataForUser");
   }

   getAllGenericDropDownData(data) {
    return this.http.post<any>(environment.baseUrl + "/apis/ssp/getGenericDropdownData",data);
  }

  getZOHOCriteria(app_id) {
    return this.http.get<any[]>(environment.baseUrl + "/apis/ssp/getZOHOCRITERIA?app_id="+app_id);
  }

  setHome(app_id) {
     return this.http.get<any>(environment.baseUrl + "/apis/users/setHomeScreen?app_id="+app_id);
  }

  getInstanceforHome() {
    return this.http.get<any>(environment.baseUrl + "/apis/users/getHomeScreen");
  }
  computeReportData(data) {
    return this.http.post<boolean>(`${environment.baseUrl}/apis/dnbs/compute`, data);
  }
  isView(data) {
    return this.http.post(`${environment.baseUrl}/apis/ssp/report/isView`, data);
  }

  getReportinfoByReportId(data){
    return this.http.post(`${environment.baseUrl}/apis/report/getReportInfoByReportId`, data);
  }
 
}
