import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { ReportHeadModel } from "../models/reportHead.model";

//import { DepartmentModel } from '../models/Department.model';

@Injectable({
    providedIn: 'root'
  })
export class ReportHeadService {
  
  
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  saveOrUpdate(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/dnbs/saveReportHead",data);
}


getAllReportHeadList(clientId){
  return this.http.get<any[]>(environment.baseUrl + "/apis/dnbs/getAllReportHeads/"+clientId);
}

deleteReportHeadId(reportHeadId) {
    return this.http.delete<ReportHeadModel[]>(environment.baseUrl + "/apis/dnbs/deleteReportHead/"+reportHeadId);
    
  }


  getParticulars(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/particulars",data);
  }


  getLiquidityStatementByClient(clientId){
    return this.http.get<any[]>(environment.baseUrl + "/apis/getGroupedLiquidityStatementsByClientId/"+clientId);
  }



  saveLiquidityStatementMapping(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/saveParticularLiquidityMapping",data);
  }

  getLiquidityStatementMaster(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/getLiquidityStatementMaster");
  }


  //FOR RATE SENSITIVITY

  getparticularsForRateSensitivity(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/getparticularsForRateSensitivity",data);
  }

  saveRateSensitivityMapping(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/saveRateSensitivityMapping",data);
  }

  getRateRiskStatementMaster(){
    return this.http.get<any[]>(environment.baseUrl + "/apis/getRateRiskStatementMaster");
  }


  }

