import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestTableComponent } from '../request-table/request-table.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-request-logs-table',
  templateUrl: './request-logs-table.component.html',
  styleUrls: ['./request-logs-table.component.scss']
})
export class RequestLogsTableComponent implements OnInit {
// @Input() data1;
@Input() hidebutton:boolean;
@Input() res;
@Input() userRequestRow;

@Output() reply= new EventEmitter();

  cols: { field: string; header: string }[];
  userId: any;

  constructor(private cookieService: CookieService) { 

    this.cols = [
      //{ field: "createdBy", header: "User Id" },
      // { field: "clientId", header: "Client Id" },
      { field: "sender", header: "Sender" },
      { field: "receiver", header: "Receiver" },
      { field: "message", header: "Message" },
      { field: "datetime", header: "Date & Time" },
      
    ];

    this.userId=this.cookieService.get("userId");
  }

  ngOnInit(): void {
  }

  showMessage(){
    console.log("content on reply hit: ",this.userRequestRow);
    this.userRequestRow.messageContains=false;
    this.reply.emit(this.userRequestRow);
    this.userRequestRow.messageContains=true;
    
  }

}
