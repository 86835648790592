import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
//import { DepartmentModel } from 'src/app/dashboard/models/Department.model';

@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss']
})
export class CategorySelectorComponent implements OnInit {

  @Output() save=new EventEmitter();
 
  @Output() closePanel=new EventEmitter();
  categoryForm: FormGroup = null;
  @Input() editData:any[];
  constructor(private toast:ToastService,private datePipe: DatePipe) {
    this.categoryForm=new FormGroup({
      categoryId:new FormControl(null),
      categoryName: new FormControl(null,[Validators.required,Validators.minLength(5),Validators.maxLength(250)]),
      statusMap: new FormControl(null,{validators:[Validators.required]}),
      description:new FormControl(null,[Validators.maxLength(500)]),
      validFromDate:new FormControl(null),
      validToDate:new FormControl(null),
    
    });
  
   }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.categoryForm.get('statusMap').setValue(defaultMap);
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);
      this.categoryForm.patchValue(
        {categoryId:rowData.categoryId,
          categoryName:rowData.categoryName,
           description:rowData.descriptions,
           validFromDate: this.datePipe.transform(rowData.validFromDate,"yyyy-MM-dd"),
           validToDate:this.datePipe.transform(rowData.validToDate,"yyyy-MM-dd")
           
          
        });
        
          const map1 = {
            key: "Active",
            value: "Active",
          };
        
          const map2 = {
            key: "Inactive",
            value: "Inactive",
          };
        
        rowData.status==true? this.categoryForm.controls.statusMap.setValue(map1):this.categoryForm.controls.statusMap.setValue(map2);

          
            

          
    }
  
  }
  


  saveCategory(){
  console.log("this.departmentForm.value ",this.categoryForm.value);
  if(this.categoryForm.invalid){
    if(this.categoryForm.controls.categoryName.invalid){
      this.toast.showWarn('Category Name is mandatory','error');
        this.closePanel.emit();
        return;
    }
  }
//   if(this.categoryForm.valid){ 
// this.save.emit(this.categoryForm.value);
//   }
//     else{
//       this.toast.showError("Please Fill Required Fields","error");
//   }

  let validate=this.validationOnDate(this.categoryForm.controls.validFromDate.value,this.categoryForm.controls.validToDate.value);
  if(validate===true){
    console.log("this.glHeadform on save.value ",this.categoryForm.value)
     
     
        let statusMap=this.categoryForm.controls.statusMap.value
        console.log("statusMap",statusMap)
        if (statusMap=="Select Status *") {
          this.toast.showError("Please Select Status of Report Category","error");
        }
        if(statusMap!=="Select Status *"){
          this.save.emit(this.categoryForm.value);
             }   
     
        
          else{
            this.toast.showError("Please Fill Required Fields","error");
        }

  }
  if(validate===false){
    this.toast.showWarn('error','date range incorrect')
    
  }

  this.closePanel.emit();
    }

    formReset(){
      this.categoryForm.reset();
     
      
      const defaultMap = {
       key: "Active",
       value: "Active",
     };
    this.categoryForm.get('statusMap').setValue(defaultMap);
    }

    


    get status() {
      const status = [
        "Active",
        "Inactive",
      ];
     return status.map((t) => ({ key: t, value: t }));
    }

    validationOnDate(validFromDate,validToDate){
      if(validFromDate && validToDate){
      if(new Date(validFromDate)>new Date(validToDate)){
      
          return false;
        }
        return true;
        
      }else{
        return false;
      }
    }



}
