import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/core/services/toast.service';
import { DepartmentModel } from 'src/app/dashboard/models/department.model';
import { RequestService } from 'src/app/dashboard/services/request.service';
import { RequestHeaderComponent } from '../request-header/request-header.component';

@Component({
  selector: 'app-request-selector',
  templateUrl: './request-selector.component.html',
  styleUrls: ['./request-selector.component.scss']
})
export class RequestSelectorComponent implements OnInit {

  @Input() selectedRow;
  @Input() previousList;
  @Input() newList;
  @Input() approveRejectVisible;
  @Input() messageBoxVisible;

  @Output() closePanel=new EventEmitter();
  @Output() approveReject = new EventEmitter();
  @Output() getAllMessage = new EventEmitter();

  messageContent: string = '';
  sendMessageDaga: {messageId:number,userRequestId:number, senderId:number, message:string, recieverId:number}
  userId: any;

  constructor(private toast:ToastService, private cookieService: CookieService, private requestService:RequestService) {
    //console.log("more info selector: ",this.moreInfo);
   
    console.log("On selector file this.previousList: ",this.previousList);
    console.log("On selector file this.newList: ",this.newList)

    this.userId=this.cookieService.get("userId");

    console.log("userId: ",this.userId);
  
   }

  ngOnInit(): void {
    //console.log("more info selector: ",this.moreInfo);
      
  }

  reject(selectedRow){
    console.log("this.selecteRow: ",selectedRow);
    const model = {
      "userRequestId" : selectedRow.userRequestId,
      "requestStatusTypeId" : 3
    }
    this.approveReject.emit(model);
    this.approveRejectVisible=false;
  }

  approve(selectedRow){
    console.log("this.selecteRow: ",selectedRow);
    const model = {
      "userRequestId" : selectedRow.userRequestId,
      "requestStatusTypeId" : 2
    }
    this.approveReject.emit(model);
    this.approveRejectVisible=false;
  }

  sendMessage(selectedRow: any){
    console.log("selected content: ",selectedRow);
    let num = 0;
    if (this.messageContent.trim()) {
      const data={
        messageId:0,
        userRequestId: selectedRow.userRequestId,
        senderId: Number(this.userId),
        message: this.messageContent,
        receiverId: selectedRow.userId,
      }
      if(data.senderId!=1){
       data.receiverId=1;   //now its hardcoded
      }
      console.log('Message sent:', data);
      this.requestService.sendMessage(data).subscribe(
        (res:any) => {
          this.closePanel.emit(true);
          this.toast.showSuccess('Success', "Message Sent Successfully");
          this.getAllMessage.emit(selectedRow.userRequestId);
        },
        (err) => {
          console.log("errorResponse",err);
          this.closePanel.emit(true);
          this.toast.showError('Error', err.error.message);
          this.getAllMessage.emit(selectedRow.userRequestId);
        }
      );
      
      this.messageContent = '';
      

    } else {
      console.warn('Cannot send an empty message.');
    }
  }



}
