import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { LookupSelectorComponent } from '../lookup-selector/lookup-selector.component';

@Component({
  selector: 'app-lookup-header',
  templateUrl: './lookup-header.component.html',
  styleUrls: ['./lookup-header.component.scss']
})
export class LookupHeaderComponent implements OnInit {

  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;


  @ViewChild(LookupSelectorComponent, { read: false, static: false })
  lookUpSelectorComponent: LookupSelectorComponent;

  @Input() lookUpTable;
  @Input() lookUpField;
  @Output() saveLookUp=new EventEmitter();
  @Input() columnDropDownData;
  @Input() columnDropDownData2;
  @Input() rolePermission;

  constructor() { }

  ngOnInit(): void {
  }

  saveLookUpData(data){
  this.saveLookUp.emit(data);

  }

  addLookup(){
    this.resetSelectForm();
    this.panelForm.toggleSlider();
  }


  resetSelectForm(){
    this.lookUpSelectorComponent.lookUpForm.reset();   
    if(this.lookUpSelectorComponent.extraRulesForm!==null){
    this.lookUpSelectorComponent.extraRulesForm.reset();
    }

  }

}
