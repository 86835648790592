import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./user/containers/login/login.component";
import { ErrorPageComponent } from "./error/components/error-page/error-page.component";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { OrganizationOpsComponent } from "./dashboard/pages/organization-ops/organization-ops.component";
import { ClientFileComponent } from "./dashboard/pages/client-file/client-file.component";
import { MappingComponent } from "./dashboard/pages/mapping/mapping.component";
import { RulesComponent } from "./dashboard/pages/rules/rules.component";
import { TransformListComponent } from "./dashboard/pages/transform-list/transform-list.component";
import { FileLayoutComponent } from "./dashboard/pages/file-layout/file-layout.component";
import { TransformComponent } from "./dashboard/pages/transform/transform.component";
import { ClientComponent } from "./dashboard/pages/client/client.component";
import { LayoutFileListComponent } from './dashboard/pages/layout-file-list/layout-file-list.component';
import { AuthGuard } from "./user/services/auth.guard";
import { UserComponent } from './dashboard/pages/user/user.component';
import { LookupComponent } from './dashboard/pages/lookup/lookup.component';
import { RolesComponent } from './dashboard/pages/roles/roles.component';
import { ChangeLogComponent } from './dashboard/pages/change-log/change-log.component';
import { LookupActionsComponent } from './dashboard/pages/lookup-actions/lookup-actions.component';
import {WorkflowSetupComponent} from './dashboard/pages/workflow-setup/workflow-setup.component'
import { SetupCustomComponent } from './dashboard/pages/setup-custom/setup-custom.component';
import { WorkflowComponent } from './dashboard/pages/workflow/workflow.component';
import {WorkflowJobComponent} from './dashboard/pages/workflow-job/workflow-job.component';
import { ListingComponent } from './dashboard/pages/listing/listing.component'
import { LookupColumnComponent } from './dashboard/pages/lookup-column/lookup-column.component';
import { ClientFileMasterComponent } from './dashboard/pages/client-file-master/client-file-master.component';
import { SetupJobComponent } from './dashboard/pages/setup-job/setup-job.component';
import { DeleteDataComponent } from './dashboard/pages/delete-data/delete-data.component';
import { ClientPortalDataComponent } from './dashboard/pages/client-portal-data/client-portal-data.component';
import { ClientPortalFormDataComponent } from './dashboard/pages/client-portal-form-data/client-portal-form-data.component';
import { ArchiveFileComponent } from './dashboard/pages/archive-file/archive-file.component';
import { CashflowListComponent } from './dashboard/pages/cashflow-list/cashflow-list.component';
import { ValidationRulePageComponent } from './dashboard/pages/validation-rule-page/validation-rule-page.component';
import { AuditHomeComponent } from './dashboard/pages/audit-home/audit-home.component';
import { CleanDataConfigComponent } from './dashboard/pages/clean-data-config/clean-data-config.component';
import { TransformOutputFilesComponent } from "./dashboard/pages/transform-output-files/transform-output-files.component";
import { StatisticsComponent } from "./dashboard/pages/statistics/statistics.component";
import { WorkflowQueueComponent } from "./dashboard/pages/workflow-queue/workflow-queue.component";
import { WorkflowJobListComponent } from "./dashboard/pages/workflow-job-list/workflow-job-list.component";
import { AddonUnmatchedAcctFilesComponent } from "./dashboard/pages/addon-unmatched-acct-files/addon-unmatched-acct-files.component";
import { SchedulerComponent } from './dashboard/pages/scheduler/scheduler.component';
import { RoleListComponent } from './dashboard/pages/role-list/role-list.component';
import { SchedulerListComponent } from './dashboard/pages/scheduler-list/scheduler-list.component';
import { NotificationsComponent } from './dashboard/pages/notifications/notifications.component';
import {ReportDashboardComponent} from './dashboard/pages/report-dashboard/report-dashboard.component';
import { ZohoAnalyticsComponent } from './dashboard/pages/zoho-analytics/zoho-analytics.component'
import { GenericFileUploadComponent } from "./dashboard/pages/generic-file-upload/generic-file-upload.component";
import { ExportReportComponent } from "./dashboard/pages/export-report/export-report.component";
import { DbconnectionMasterComponent } from "./dashboard/pages/dbconnection-master/dbconnection-master.component";
import { SparkLauncherComponent } from "./dashboard/pages/spark-launcher/spark-launcher.component";
import { MainDashboardComponent } from "./dashboard/pages/main-dashboard/main-dashboard.component";
import { HomeComponent } from "./dashboard/pages/home/home.component";
import { MainReportDashboardComponent } from './dashboard/pages/main-report-dashboard/main-report-dashboard.component';
import { MainExportReportComponent } from './dashboard/pages/main-export-report/main-export-report.component';
import { ChangePasswordComponent } from "./dashboard/pages/change-password/change-password.component";
import { WorkflowTabbedComponent } from "./dashboard/pages/workflow-tabbed/workflow-tabbed.component";
import { TableViewerComponent } from "./dashboard/pages/table-viewer/table-viewer.component";
import { CreateGroupComponent } from "./selfServicePortal/pages/create-group/create-group.component"; 
import { DataTransferComponent } from "./dashboard/pages/data-transfer/data-transfer.component";
import { IngestTabbedComponent } from "./dashboard/pages/ingest-tabbed/ingest-tabbed.component";
import {HomeScreenComponent} from "./dashboard/pages/home-screen/home-screen.component";
import { DepartmentComponent } from "./dashboard/pages/department/department.component";
import { CategoryComponent } from "./dashboard/pages/category/category.component";
import { GlHeadComponent } from "./dashboard/pages/gl_head/glHead.component";
import { ReportHeadComponent } from "./dashboard/pages/report-head/reportHead.component";
import { DisplayReportComponent } from "./dashboard/pages/display-report/display-report.component";
import { GlMappingComponent } from "./dashboard/pages/gl-mapping/gl-mapping.component";
import { ReportConfigurationComponent } from "./dashboard/pages/report-configuration/reportConfigurationComponent.component";
import { VideoUploadPortalComponent } from "./dashboard/pages/video-upload-portal/video-upload-portal.component";
import { HelpComponent } from "./dashboard/pages/help/help.component";
import { VideoCategoryComponent } from "./dashboard/pages/video-category/videocategory.component";
import { StressTestingComponent } from "./dashboard/pages/stress-testing/stress-testing.component";
import { HQLAComponent } from "./dashboard/pages/hqla/hqla.component";
import { StressScenarioComponent } from "./dashboard/pages/stress-scenario/stressScenario.component";
import { YeildAdvancesComponent } from "./dashboard/pages/yeild-advances/yeild-advances.component";
import { RwapercentComponent } from "./dashboard/pages/rwa/rwapercent.component";
import { StressScenarioValuesComponent } from "./dashboard/pages/stress-scenario-Values/stressScenarioValues.component";
import { ShockMasterComponent } from "./dashboard/pages/shock-master/shockMaster.component";
import { StressKeyMetricsComponent } from "./dashboard/pages/stress-key-metrics/stressKeyMetrics.component";
import { SeverityComponent } from "./dashboard/pages/severity/severity.component";
import { ShockConfigurationComponent } from "./dashboard/pages/shock-configuration/shock-configuration.component";
import { InterestRateRiskScenarioComponent } from "./dashboard/pages/interest-rate-risk-scenario/interestRateRiskScenario.component";
import { MultifactorRiskComponent } from "./dashboard/pages/multifactor-risk/multifactor-risk.component";
import { FinancialAccountingComponent } from "./dashboard/pages/financial-accounting/financial-accounting.component";
import { ConfigureFiltersComponent } from "./dashboard/pages/configure-filters/configure-filters.component";
import { ReportAnalysisComponent } from "./dashboard/pages/report-analysis/report-analysis.component";
import { CreateVariablesComponent } from "./regression/pages/create-variables/create-variables.component";
import { CreateModelComponent } from "./regression/pages/create-model/create-model.component";
import { HeadLiquidityStatmentMapping } from "./dashboard/pages/head-liquidity-statment-mapping/head-liquidity-statment-mapping";
import { RateSensitivityStatmentMapping } from "./dashboard/pages/rate-sensitivity-statment-mapping/rate-sensitivity-statment-mapping";
import { RequestComponent } from "./dashboard/pages/request/request.component";



const routes: Routes = [

  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  { path: "login", component: LoginComponent, data: { title: "Login" } },
  {
    path: "org-ops",
    component: OrganizationOpsComponent,
    canActivate: [AuthGuard],
    data: { title: "Organization Ops" },
  },
  {
    path: "data/ingest/data-file",
    component: ClientFileComponent,
    canActivate: [AuthGuard],
    data: { title: "Data Files" },
  },
  {
    path: "data/manage/file-master",
    component: ClientFileMasterComponent,
    canActivate: [AuthGuard],
    data: { title: "File Master" },
  },
  {
    path: "data/manage/mapping",
    component: MappingComponent,
    canActivate: [AuthGuard],
    data: { title: "Mapping" },
  },
  {
    path: "data/manage/rules",
    component: RulesComponent,
    canActivate: [AuthGuard],
    data: { title: "Rules" },
  },
  {
    path: "data/transform/design",
    component: TransformComponent,
    canActivate: [AuthGuard],
    data: { title: "Design" },
  },
  {
    path: "data/transform/job",
    component: TransformListComponent,
    canActivate: [AuthGuard],
    data: { title: "Job" },
  },
  {
    path: "data/manage/file-layout",
    component: FileLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: "File Layouts" },
  },
  {
    path: "admin/company",
    component: ClientComponent,
    canActivate: [AuthGuard],
    data: { title: "Company" },
  },
  {
    path: "admin/users",
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { title: "Users" },
  },

  {
    path: "data/manage/layout-files",
    canActivate: [AuthGuard],
    component: LayoutFileListComponent,
    data: { title: "Layout Files" },
  },

  {
    path: "data/manage/lookup",
    canActivate: [AuthGuard],
    component: LookupComponent,
    data: { title: "Lookup" },
  },
  {
    path: "data/manage/import-export-lookup",
    canActivate: [AuthGuard],
    component: LookupActionsComponent,
    data: { title: "Import/Export Lookup" },
  },
  // {
  //   path: "dashboard",
  //   canActivate: [AngularFireAuthGuard],
  //   loadChildren: () =>
  //     import("./dashboard/dashboard.module").then(m => m.DashboardModule)
  // },
  {
    path: "sample-pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },

  {
    path: "role",
    component: RolesComponent,
    canActivate: [AuthGuard],
    data: { title: "Role" },
  },
  {
    path: "audit/log",
    component: ChangeLogComponent,
    canActivate: [AuthGuard],
    data: { title: "Logs" },
  },
  {
    path: "workflow/setup",
    component: SetupJobComponent,
    canActivate: [AuthGuard],
    data: { title: "Setup" },
  },
  {
    path: "clean-data-config",
    component: CleanDataConfigComponent,
    canActivate: [AuthGuard],
    data: { title: "Clean Data Config" },
  },
  {
    path: "setup-custom",
    component: SetupCustomComponent,
    canActivate: [AuthGuard],
    data: { title: "Setup Custom" },
  },
  {
    path: "workflow/logs",
    component: WorkflowComponent,
    canActivate: [AuthGuard],
    data: { title: "Logs" },
  },
  {
    path: "workflow",
    component: WorkflowJobComponent,
    canActivate: [AuthGuard],
    data: { title: "Manage" },
  },
  {
    path: "listing",
    component: ListingComponent,
    canActivate: [AuthGuard],
    data: { title: "Listing" },
  },
  {
  path: "data/manage/lookuplist",
  component: LookupColumnComponent,
  canActivate: [AuthGuard],
  data: { title: "Lookup List" },
  },
  {
    path: "clean-data",
    component: DeleteDataComponent,
    canActivate: [AuthGuard],
    data: { title: "Clean Data" },
    },

    {
      path: "data/ingest/upload-formatted-files",
      component: ClientPortalDataComponent,
      canActivate: [AuthGuard],
      data: { title: "Upload Files" },
    },

    {
      path: "client-portal-form-data",
      component: ClientPortalFormDataComponent,
      canActivate: [AuthGuard],
      data: { title: "Upload Files" },
    },

    {
      path: "archive-file",
      component: ArchiveFileComponent,
      canActivate: [AuthGuard],
      data: { title: "Archived Files" },
    },

    {
      path: "scheduler",
      component: SchedulerComponent,
      canActivate: [AuthGuard],
      data: { title: "Schedule" },
    },

    {
      path: "workflow/schedule",
      component: SchedulerListComponent,
      canActivate: [AuthGuard],
      data: { title: "Schedule" },
    },

    {
      path: "admin/rolelist",
      component: RoleListComponent,
      canActivate: [AuthGuard],
      data: { title: "Role-List" },
    },

    {
      path: "admin/notification-group",
      component: NotificationsComponent,
      canActivate: [AuthGuard],
      data: { title: "Notification Group" },
    },


    {
      path: "report-dashboard",
      component: ReportDashboardComponent,
      canActivate: [AuthGuard],
      data: { title: "Dashboard" },
    },



    {
      path: "zoho-analytics",
      component: ZohoAnalyticsComponent,
      canActivate: [AuthGuard],
      data: { title: "Zoho Analytics" },
    },

    // {
    //   path: "data/export-report/export-report",
    //   component: ExportReportComponent,
    //   canActivate: [AuthGuard],
    //   data: { title: "EXPORT-REPORT" },
    // },

    {path:"change-password",
      component: ChangePasswordComponent,
       canActivate : [AuthGuard],
      data: { title: "Change-Password" },
    },


    {
      path: "cashflow-list",
      component: CashflowListComponent,
      canActivate: [AuthGuard],
      data: { title: "Cashflow Master" },
    },

    {
      path: "validation-rule",
      canActivate: [AuthGuard],
      component: ValidationRulePageComponent,
      data: { title: "Validation Rules" },
    },

    {
      path: "audit/audit",
      canActivate: [AuthGuard],
      component: AuditHomeComponent,
      data: { title: "Audit" },
    },

    {
      path: "transform-output-files",
      canActivate: [AuthGuard],
      component: TransformOutputFilesComponent,
      data: { title: "Files" },
    },
    {
      path:"statistics",
      canActivate:[AuthGuard],
      component:StatisticsComponent,
      data:{ title: "Statistics"}
    },
    {
      path:"addon-unmatched-acct-files",
      canActivate:[AuthGuard],
      component:AddonUnmatchedAcctFilesComponent,
      data:{ title: "Addon unmatched acct number"}
    },
    {
      path: "regression",
      loadChildren: () =>
        import("./regression/regression.module").then((m) => m.RegressionModule),
    },
    {
      path: "",
      loadChildren: () =>
      import("./selfServicePortal/selfServicePortal.module").then((m) => m.SelfServicePortalModule),
      canActivate: [AuthGuard]

    },

    // { 
    //   path: "admin/report-group", 
    //   component: CreateGroupComponent,
    //   data: { title: "Report Group" },
    //   canActivate:[AuthGuard],
    //  },

    {
      path: "workflow/queue",
      component: WorkflowQueueComponent,
      data: { title: "Queue" },
      canActivate: [AuthGuard],
    },
    {
      path: "workflow-job-list",
      component: WorkflowJobListComponent,
      data: { title: "Job List" },
      canActivate: [AuthGuard],
    },
    {
      path: "data/ingest/generic-file-upload",
      component: IngestTabbedComponent,
      data: { title: "Generic File Upload" },
      canActivate: [AuthGuard],
    },
    {
      path: "admin/databaseconnection",
      component: DbconnectionMasterComponent,
      data: { title: "Database Connection" },
      canActivate: [AuthGuard],
    },
    {
      path: "spark-launcher",
      component: SparkLauncherComponent,
      data: { title: "Spark Launcher" },
      canActivate: [AuthGuard],
    },
    {
      path: "report/dashboard",
      component: MainDashboardComponent,
      data: { title: "DashBoard" },
      canActivate: [AuthGuard],
    },

    {
      path: "report-admin/report",
      component: HomeComponent,
      data: { title: "Report" },
      canActivate: [AuthGuard],
    },
    {
      path: "report/report-dashboard/view",
      component: MainReportDashboardComponent,
      data: { title: "Export Report Dashboard" },
     // canActivate: [AuthGuard],
    },
    {
      path: "reports/export-report/export-report",
      component: MainExportReportComponent,
      data: { title: "Export Report" },
      canActivate: [AuthGuard],
    },
    {
      path: "workflow/manage/workflow",
      component: WorkflowTabbedComponent,
      data: { title: "Workflow Tabbed" },
      canActivate: [AuthGuard],
    },
    {
      path: "data/viewdata/table-viewer",
      component: TableViewerComponent,
      data: { title: "Table Viewer" },
      canActivate: [AuthGuard],
    },
    // {path:"ingest-tabbed",
    // component: IngestTabbedComponent,
    //  canActivate : [AuthGuard],
    // data: { title: "Ingest-Tabbed" },
    // },
    {
      path: "data/ingest/data-transfer",
      component: DataTransferComponent,
      data: { title: "Data Transfer" },
      canActivate: [AuthGuard],
    },
    {
      path: "home-screen",
      component: HomeScreenComponent,
      data: { title: "Home Screen" },
      canActivate: [AuthGuard],
    },
    {
      path: "admin/department",
      component: DepartmentComponent,
      data: { title: "Department" },
      canActivate: [AuthGuard],
    },  
 {
  path: "report-admin/report-category",
  component: CategoryComponent,
  canActivate: [AuthGuard],
  data: { title: "Category" },  
},

{
  path: "master-Data/glHead",
  component: GlHeadComponent,
  canActivate: [AuthGuard],
  data: { title: "GL-Head" },  
},
{
  path: "master-Data/hqla",
  component: HQLAComponent,
  canActivate: [AuthGuard],
  data: { title: "HQLA" },  
},

{
  path: "report-admin/report-Head",
  component: ReportHeadComponent,
  canActivate: [AuthGuard],
  data: { title: "Report Head" },  
},

{ path: "error", 
component: ErrorPageComponent },

  { path: "display", 
  component: DisplayReportComponent, 
  data: { title: "Display" } },

  {
    path: "master-data/glMapping",
    component: GlMappingComponent,
    canActivate: [AuthGuard],
    data: { title: "GL-Mapping" },  
  },

  
  {
    path: "admin/help-video",
    component:VideoUploadPortalComponent ,
    canActivate: [AuthGuard],
    data: { title: "Video Upload" },  
  },
  {
    path: "report-admin/report-configuration",
    component: ReportConfigurationComponent,
    canActivate: [AuthGuard],
    data: { title: "ReportConfiguration" },  
  },

  {
    path: "help",
    component: HelpComponent,
    data: { title: "Help Section" },  
  },

  {
    path: "admin/videocategory",
    component: VideoCategoryComponent,
    canActivate: [AuthGuard],
    data: { title: "VideoCategory" },  
  },
  {
    path: "stress-testing",
    component: StressTestingComponent,
    canActivate: [AuthGuard],
    data: { title: "stress-testing" },  
  },
  {
    path: "manage/scenarios",
    component: StressScenarioComponent,
    canActivate: [AuthGuard],
    data: { title: "Stress Scenario" },  
  },
  {
    path: "manage/stressVariables",
    component: YeildAdvancesComponent,
    canActivate: [AuthGuard],
    data: { title: "yeild-advances" },  
  },

  // {
  //   path: "manage/riskFactors",
  //   component: RwapercentComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "RWA % As Per Provision" },  
  // },
  {
    path: "manage/stressSeverity",
    component: StressScenarioValuesComponent,
    canActivate: [AuthGuard],
    data: { title: "Stress Scenario Values" },  
  },

  {
    path: "manage/riskFactors",
    component: ShockMasterComponent,
    canActivate: [AuthGuard],
    data: { title: "Shock Master" },  
  },
  {
    path: "manage/keyMetrics",
    component: StressKeyMetricsComponent,
    canActivate: [AuthGuard],
    data: { title: "Stress Key Metrics" },  
  },

  { path: "view-rawData", 
  component: DisplayReportComponent, 
  data: { title: "view-rawData" } },

  {
    path: "manage/keyMetrics-stressSeverity",
    component: SeverityComponent,
    canActivate: [AuthGuard],
    data: { title: "Severity" },
  },

  {
    path: "configure-scenario",
    component: ShockConfigurationComponent,
    canActivate: [AuthGuard],
    data: { title: "Configure Scenario"},  
  },
  {
    path: "financial-accounting",
    component: FinancialAccountingComponent,
    canActivate: [AuthGuard],
    data: { title: "Financial Accounting"},  
  },


  {
    path: "manage/interestRateRiskScenario",
    component: InterestRateRiskScenarioComponent,
    canActivate: [AuthGuard],
    data: { title: "Interest Rate Risk Scenario"},  
  },

  {
    path: "multifactor-risk",
    component: MultifactorRiskComponent,
    canActivate: [AuthGuard],
    data: { title: "Multifactor Risk"},  
  },
  {
    path: "historical-analysis",
    component: ReportAnalysisComponent,
    canActivate: [AuthGuard],
    data: { title: "Historical Analysis"},  
  },
  
  {
    path: "manage/reportFilters",
    component: ConfigureFiltersComponent,
    canActivate: [AuthGuard],
    data: { title: "Report Filters"},  
  },



   {
    path: "admin/headliquidity-mapping",
    component: HeadLiquidityStatmentMapping,
    canActivate: [AuthGuard],
    data: { title: "Head Liquidity Mapping"},  
  },

  //analytics path set
  // {
  //   path: "analytics/variables",
  //   component: CreateVariablesComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "Analytics Variables"}, 
  // },
   
  // {
  //   path: "analytics/models",
  //   component: CreateModelComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "Analytics Models"},
  // },

  {
    path: "admin/ratesensitivity-mapping",
    component: RateSensitivityStatmentMapping,
    canActivate: [AuthGuard],
    data: { title: "Rate Sensitivity Mapping"},  
  },

  {
    path: "admin/request",
    component: RequestComponent,
    data: { title: "Request" },
    canActivate: [AuthGuard],
  },

  { path: '**', redirectTo: "error", pathMatch: 'full' },

  
     
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
