import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { FormsModel } from 'src/app/models/forms.model';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientModel } from '../../models/client.model';
import { Binary } from '@angular/compiler';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { map } from 'rxjs/operators';
import { LayoutFilesModel } from '../../models/files.model';
import { getCurrentDate,formatDate } from "src/app/utils/dates";
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { DatePipe } from '@angular/common';
import { TransformPermission } from '../../models/transformPermission.model';
import { Store } from '@ngrx/store';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { ClientSelectorComponent } from 'src/app/clientComp/components/client-selector/client-selector.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { DepartmentHeaderComponent } from 'src/app/departmentComp/components/department-header/department-header.component';
import { DepartmentService } from '../../services/department.service';
import { DepartmentModel } from '../../models/department.model';



@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {

  // @ViewChild(ClientSelectorComponent, { read: false, static: false })
  // clientSelectorComponent: ClientSelectorComponent;

  @ViewChild(DepartmentHeaderComponent, { read: false, static: false })
  departmentHeaderComponent: DepartmentHeaderComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;
// @ViewChild('fromCal')
// fromCalVariable: ElementRef;
  departmentList:DepartmentModel[];
  editData:DepartmentModel[];
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  state:string;
  menu:string;
  submenu:string;
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService,private fileService:FileService,private toast:ToastService,
    private router: Router,private departmentService:DepartmentService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
      this.titleService.setTitle("Department");
     

    //  this.modalForm = new FormGroup({
    //   file:new FormControl(null, [Validators.required]),
    //   asOfDate:new FormControl(null, [Validators.required]),
    //   clientFieMaster:new FormControl(null)
    //  });
      
    //   this.clientApplicationFileForm = new FormGroup({
    //     file:new FormControl(null),
    //     fileType:new FormControl(null, [Validators.required]),
    //     fileDataType:new FormControl(null, [Validators.required]),
    //     layoutFieldId:new FormControl(null),
    //     dependentUpon:new FormControl(null),
    //     fieldSeparator:new FormControl(null),
    //     schedule:new FormControl(null)
  

    // });

    this. searchByCols = [
     
      { field: "departmentName", header: "Department Name" },
      { field: "description", header: "Description" },
      
    ];
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag"); 

    }
  ngOnInit(): void {
   //this.buttonShowHidePermission();
this.getAllDepartmentList();
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllDepartmentList(){
    
    this.departmentService.getAllDepartmentList().subscribe((data)=>{
      this.departmentList=data;
  
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }
  
  

  saveData(deptForm){
    
    const model={
      departmentId:deptForm.departmentId!==null?deptForm.departmentId:0,
      departmentName:deptForm.departmentName,
      status:deptForm.statusMap.key=='Active'?true:false,
      description:deptForm.description,
      validFromDate:deptForm.validFromDate,
      validToDate:deptForm.validToDate
      

    }
    
   
   this.departmentService.saveOrUpdate(model).subscribe(
     (data) => {
  
    this.toast.showSuccess("Department Saved Successfully", "success");
    this.departmentHeaderComponent.reset();
    this.getAllDepartmentList();
   
  
   
  },
  (err) => {
  
    this.toast.showError(err.error.message, "error");
  }

);
   
 
}


editDepartment(rowData){
    this.editData=rowData;
    this.departmentHeaderComponent.openEdit();
    
  }

deleteDepartmentId(data) {
    const genericModel ={
      "departmentId": data.departmentId
    }

    //this.loader.showLoader();
    this.departmentService.deleteDepartmentId(genericModel).subscribe(
      data => {
          //this.loader.showLoader();
          console.log("deleteResponse",data);
          
          this.toast.showSuccess('Success', data.message);
          //this.resetForm();
          this.getAllDepartmentList();
        },
        err => {
          //this.loader.hideLoader();
          console.log("errorResponse",err);
          this.toast.showWarn('Error', err.error);
        });
  }

}
