import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
//import { DepartmentModel } from 'src/app/dashboard/models/Department.model';

@Component({
  selector: 'app-videocategory-selector',
  templateUrl: './videocategory-selector.component.html',
  styleUrls: ['./videocategory-selector.component.scss']
})
export class VideoCategorySelectorComponent implements OnInit {

  @Output() save=new EventEmitter();
 
  @Output() closePanel=new EventEmitter();
  categoryForm: FormGroup = null;
  @Input() editData:any[];
  constructor(private toast:ToastService,private datePipe: DatePipe) {
    this.categoryForm=new FormGroup({
      videoCategoryId:new FormControl(null),
      categoryName: new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(250)]),
      statusMap: new FormControl(null,{validators:[Validators.required]}),
    
    });
  
   }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.categoryForm.get('statusMap').setValue(defaultMap);
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);
      this.categoryForm.patchValue(
        {videoCategoryId:rowData.videoCategoryId,
          categoryName:rowData.categoryName,
           
          
        });
        
          const map1 = {
            key: "Active",
            value: "Active",
          };
        
          const map2 = {
            key: "Inactive",
            value: "Inactive",
          };
        
        rowData.status==true? this.categoryForm.controls.statusMap.setValue(map1):this.categoryForm.controls.statusMap.setValue(map2);

          
            

          
    }
  
  }
  


  saveCategory(){
  console.log("this.departmentForm.value ",this.categoryForm.value)
   if(this.categoryForm.valid){ 
    let statusMap=this.categoryForm.controls.statusMap.value
    console.log("statusMap",statusMap)
    if (statusMap=="Select Status *") {
      this.toast.showError("Please Fill Required Fields","error");
    }
    if(statusMap!=="Select Status *"){
 this.save.emit(this.categoryForm.value);
    }   
}
     else{
       this.toast.showError("Please Fill Required Fields","error");
   }

  // let validate=this.validationOnDate(this.categoryForm.controls.validFromDate.value,this.categoryForm.controls.validToDate.value);
  // if(validate===true){
  //   console.log("this.glHeadform on save.value ",this.categoryForm.value)
  //   if(this.categoryForm.valid){ 
  //     this.save.emit(this.categoryForm.value);
  //       }
  //         else{
  //           this.toast.showError("Please Fill Required Fields","error");
  //       }

  // }
  // if(validate===false){
  //   this.toast.showWarn('error','date range incorrect')
    
  // }

  this.closePanel.emit();
    }

    formReset(){
      this.categoryForm.reset();
     
      
      const defaultMap = {
       key: "Active",
       value: "Active",
     };
    this.categoryForm.get('statusMap').setValue(defaultMap);
    }

    


    get status() {
      const status = [
        "Active",
        "Inactive",
      ];
     return status.map((t) => ({ key: t, value: t }));
    }

}
