import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output,EventEmitter, ViewChild,SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { TransformModel } from 'src/app/dashboard/models/tranform.model';
import { FormsModel } from 'src/app/models/forms.model';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import {trim} from 'lodash';
import { WorkflowService } from 'src/app/dashboard/services/workflow.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-setup-table-new',
  templateUrl: './setup-table-new.component.html',
  styleUrls: ['./setup-table-new.component.scss']
})
export class SetupTableNewComponent implements OnInit {

  @ViewChild("panelForm2", {static: false })
  panelForm2!:SidePanelComponent;

  @Input() data:any= [];
  @Input() transformJobDetailsData:any=[];
  @Input() customJobDetailsData:any=[];
  @Output() editWorkflowJobDetailsData=new EventEmitter();
  @Output() deleteJob=new EventEmitter();
  @Output() paramEmit=new EventEmitter();
  @Output() rowChangeEmit=new EventEmitter();
  @Input() transformList: TransformModel[] = [];
  @Input() permission:any;
  modalFormModel: FormsModel = null;
  modalForm1: FormGroup = null;
  modalForm: FormGroup = null;
  

  cols: { field: string; header: string }[];
  transformcols: { field: string; header: string }[];
  customcols:{ field: string; header: string }[];
  visible:boolean=false;
  workflowDetailId:any=null;
  workflowDetailParamId:any=null;
  paramHeader="Add Param";
  fromDt:string="";
  selectedDate:any=null;
  selectedJob: any;
  visibleTransform:boolean=false;
  jobData:any;
  paramTypeList:{key:string,value:string}[];

  paramList:{paramName:string,paramValue:string,completed:boolean,paramType:string,paramTypeValue:string,paramFlag:boolean,parameterLabel:string}[]=[];
  todoObjview:any;
  paramPlaceHolder:string;
  showParamField:boolean;
  paramToPass:string;

  column:{key:any;value:string}[];
  showDropDown:boolean=false;
  tableData:any[];
  industriesList:string[];
  nameOfAccount:string[];

  columnDistinctValues:any[]=[];

  selectedIndutries: { key: any; value: any }[] = [];
  list:string[]=[];
  onList:boolean=false;
  paramFlag:boolean=true;
  workflowId: Number;
  constructor(private datePipe:DatePipe,
    private toast:ToastService, private workflowService:WorkflowService) { 

    this.modalForm1 = new FormGroup({
      asOfDate: new FormControl(null),
      paramName:new FormControl(null),
      paramType:new FormControl(null),
      paramValue:new FormControl(null),
      paramList: new FormControl(null),
      column:new FormControl(null),
      paramTypeValue:new FormControl(null),
      parameterLabel:new FormControl(null)
      
    });
    this.cols = [
      { field: "jobName", header: "Job" },
      { field: "jobType", header: "Type" },
      { field: "fileName", header: "File" },
      { field: "description", header: "Desc" },
      { field: "trigger1", header: "Trigger" },    
      { field: "enabled", header: "Enabled" },
      { field: "continueOnError", header: "Continue On Error" }

    ];

    this.transformcols = [
      { field: "jobName", header: "Transform Job" },
      { field: "jobType", header: "Type" },
      { field: "description", header: "Desc" },
      { field: "trigger1", header: "Trigger" },
     
      { field: "enabled", header: "Enabled" },
      { field: "continueOnError", header: "Continue On Error" }
    ];

    this.customcols = [
      { field: "jobName", header: "Custom Job" },
      { field: "target", header: "Target Api" },
      { field: "description", header: "Desc" },
      { field: "trigger1", header: "Trigger" },    
      { field: "enabled", header: "Enabled" },
      { field: "continueOnError", header: "Continue On Error" }
    ];


    this.paramTypeList=[{key:"Text",value:"String"},
                        {key:"Number",value:"Numeric"},
                        {key:"Date",value:"Date"},
                        {key:"List",value:"List"}]

    
  }

  ngOnInit(): void {
  
  }


  // ngOnChanges(changes: SimpleChanges) {
  //   console.log("job data...........",this.jobData)
  //   if (changes && changes.jobData && changes.jobData.currentValue) {
  //      console.log("job data",changes.jobData.currentValue)

      
  //   }
  
  // }

  editWorkflowJobDetails(data){
    console.log(data)
    this.editWorkflowJobDetailsData.emit(data);
  }

  deleteIngestJob(data){
    console.log(data)
    this.deleteJob.emit(data);
  }

  doAction(data){
    console.log('inside do action1222');
    this.visible=false;
    this.workflowDetailId=null;
    this.fromDt=""
    this.selectedDate=null;
    this.visibleTransform=false;
    this.jobData=null;
  }

  onDateChange(e){
    console.log('on date change ',e)
    const valt=this.datePipe.transform(e,'yyyy-MM-dd')
    console.log('on date changegfgf ',valt)
    this.selectedDate=valt;
  }

  onDateBlur(e){
    console.log('on date change ',e.target.value)
    const valt=this.datePipe.transform(e.target.value,'yyyy-MM-dd')
    console.log('on date changegfgf ',valt)
    this.selectedDate=valt;
  }

  showParamValue(data){
    this.resetOnaddParam();
    if(data.paramList!=null || data.paramList!=undefined){
      
     

      for(var i=0;i<data.paramList.length;i++){
        // if(data.paramList[i].paramType!=="List"){
        this.paramList.push({
          paramName: trim(data.paramList[i].paramName),
          paramValue: data.paramList[i].paramValue,
          completed: data.paramList[i].completed,
          paramType:trim(data.paramList[i].paramType),
          paramTypeValue:data.paramList[i].paramTypeValue,
          paramFlag:data.paramList[i].paramFlag,
          parameterLabel:trim(data.paramList[i].parameterLabel)
        }
        )
      // }
      // else{
      //   this.paramList.push({
      //     paramName: trim(data.paramList[i].paramName),
      //     paramValue: JSON.parse(data.paramList[i].paramValue),
      //     completed: data.paramList[i].completed,
      //     paramType:trim(data.paramList[i].paramType),
      //     paramTypeValue:data.paramList[i].paramTypeValue
      //   }
      //   )

      // }
      }
    }
    this.panelForm2.toggleSlider();
    console.log("data",data);
    this.workflowId=data.workflowId;
    this.jobData=data;
    this.visibleTransform=false;
    this.visible=false;
    this.workflowDetailId=data.workflowDetailId;
    this.workflowDetailParamId=data.workflowDetailParamId;
    //console.log("hello 2",this.jobData);
    //this.modalForm.controls.asOfDate.setValue(null);
    if(data.jobType==='Ingest'){
    this.fromDt="";
     //console.log("hello 2",this.jobData);
    if(data.workflowDetailParamId!==null && data.workflowDetailParamId>0){
     // console.log("hellooo "+data);
     // this.fromDt="Current AsOfDate :"+this.datePipe.transform(data.asOfDate,'MM-dd-yyyy');
     this.fromDt="Current AsOfDate :"+this.datePipe.transform(data.asOfDate,'MM/dd/yyyy');
     
      console.log(this.fromDt);
      
    }
    
    if(data.asOfDate!==null){
     // console.log("controls :",this.modalForm1.controls)
      this.modalForm1.controls.asOfDate.setValue(new Date(data.asOfDate));
    }
    this.visible=true;
   
  }
  if(data.jobType==='Transform'){
  //  console.log(this.transformList);
   // console.log(data);
   // this.visible=true;
   this.transformList.forEach(item=>{
     if(item.job_id===data.jobId){
       if(this.jobData.transformParams!==null){
        // console.log("this.jobData.transformParams ",JSON.stringify(this.jobData.transformParams));
        // console.log("this.jobData.job_parameter ",item.job_parameter)
        this.selectedJob=JSON.stringify(this.jobData.transformParams);
       }else{
        //console.log("item.job_parameter ",item.job_parameter)
        this.selectedJob=item.job_parameter;
       }
     
     }
     
   })
   this.modalFormModel = null;
  
   const params = JSON.parse(this.selectedJob);
   const keys = Object.keys(params);
   if (keys && keys.length <= 0) {
     this.toast.showInfo("info","no parameter available")
   } else {
     this.createModelFromParams(params,data.asOfDate);
     this.visibleTransform = true;
   }
  }
  }

  createModelFromParams(params,asOfDate) {
    this.modalFormModel = { controls: [] };
    for (let k in params) {
      //console.log('vvvvvvv ',params[k]);
      let valu=params[k];
      if(k==="asofdate" && asOfDate!==null){
        valu=this.datePipe.transform(asOfDate,'yyyy-MM-dd')
        //valu=this.datePipe.transform(asOfDate,'MM/dd/yyyy');
      }
      this.modalFormModel.controls.push({
        name: k,
        type: k === "asofdate" ? "date" : "text",
        label: k,
        defaultValue: valu,
      });
    }
  }

  addParamToJob(data){
   this.panelForm2.toggleSlider();
    let paramValue=null;
    let parameters = new Map<string,any>()
    let x="";
    console.log(JSON.stringify(this.jobData));
    if(this.jobData.jobType==='Ingest'){
     // console.log(" here ");
     // console.log(" here ",this.modalForm.controls.asOfDate.value);
      //  if(this.modalForm1.controls.asOfDate && this.modalForm1.controls.asOfDate.value!==null){
      //  this.selectedDate=this.datePipe.transform(this.modalForm1.controls.asOfDate.value,'yyyy-MM-dd');
      
      //  }

      // if(this.selectedDate===null || this.selectedDate===undefined){
      //   this.toast.showInfo('error','asOfDate is required');
      //   return;
      // }
      paramValue={
        "clientid":this.jobData.clientId
      };
    }

    if(this.jobData.jobType==='Transform'){
     paramValue=this.modalForm.value;
    // console.log("param value ",paramValue)
     if(paramValue===null || paramValue===undefined){
       paramValue={
         "clientid":this.jobData.clientId
       };
     }
    }

    if (this.jobData.jobType === "Custom") {

      // if(this.modalForm1.controls.paramValue.value.value==="Date"){
      // if (this.modalForm1.controls.paramValue && this.modalForm1.controls.paramValue.value !== null) {
         
      //   this.modalForm1.get('paramValue').setValue(this.datePipe.transform(
      //     this.modalForm1.controls.paramValue.value,
      //     "yyyy-MM-dd"
      //   ));
      // }
      // if (this.selectedDate === null || this.selectedDate === undefined) {
      //   this.toast.showInfo("error", "asOfDate is required");
      //   return;
      // }
    //}
      console.log("final paramList :",this.paramList)
      this.modalForm1.patchValue({
        paramList:this.paramList
      })
      if (paramValue === null || paramValue === undefined) {
        paramValue = {
          clientid: this.jobData.clientId,
        };
      }
      // parameters.set("clientid",this.jobData.clientId);
      // for(var i=0;i<this.modalForm1.controls.paramList.value.length;i++){
      //   parameters.set(this.modalForm1.controls.paramList.value[i].paramName,this.modalForm1.controls.paramList.value[i].paramValue);
      // }
     
      // for(var i=0;i<this.modalForm1.controls.paramList.value.length;i++){
      //  if(this.modalForm1.controls.paramList.value[i].paramType==="Text" || this.modalForm1.controls.paramList.value[i].paramType==="Date"){
      //  if(i<this.modalForm1.controls.paramList.value.length-1){
      //     x=x+"\""+this.modalForm1.controls.paramList.value[i].paramName+"\""+":"+"\""+this.modalForm1.controls.paramList.value[i].paramValue+"\""+",";
      //        }
      //     else{
      //         x=x+"\""+this.modalForm1.controls.paramList.value[i].paramName+"\""+":"+"\""+this.modalForm1.controls.paramList.value[i].paramValue+"\"";
      //         }
      //       }

      //  if(this.modalForm1.controls.paramList.value[i].paramType==="Number"){
      //   if(i<this.modalForm1.controls.paramList.value.length-1){
      //     x=x+"\""+this.modalForm1.controls.paramList.value[i].paramName+"\""+":"+this.modalForm1.controls.paramList.value[i].paramValue+",";
      //        }
      //     else{
      //         x=x+"\""+this.modalForm1.controls.paramList.value[i].paramName+"\""+":"+this.modalForm1.controls.paramList.value[i].paramValue;
      //         }

      //  }
       
      //  if(this.modalForm1.controls.paramList.value[i].paramType==="List"){
      //   let input=this.modalForm1.controls.paramList.value[i].paramValue;
      //   let y="";
      //   for(var j=0;j<input.length;j++){
      //     if(j<input.length-1){
      //      y=y+"\""+input[j]+"\""+",";
      //     }
      //     else{
      //       y=y+"\""+input[j]+"\"";
      //     }
       
      //   }
      //   let arrayString="["+y+"]";
      //   console.log("arrayString "+arrayString);
      //   console.log("this.modalForm1.controls.paramList.value[i]"+this.modalForm1.controls.paramList.value[i]);
      //   if(i<this.modalForm1.controls.paramList.value.length-1){
      //     x=x+"\""+this.modalForm1.controls.paramList.value[i].paramName+"\""+":"+arrayString+",";
      //        }
      //     else{
      //         x=x+"\""+this.modalForm1.controls.paramList.value[i].paramName+"\""+":"+arrayString;
      //         }

      //  } 
      //        }
       
      }
      // if(parameters.size>0){
      //   // for(var i=0;i<parameters.size;i++){
      //     for (let entry of parameters.entries()) {
      //          r=r+1;
      //          let mapKey = entry[0];
      //          let mapValue = entry[1];
      //          if(r<parameters.size){
      //            x=x+"\""+mapKey+"\""+":"+mapValue+",";
      //               }
      //            else{
      //                x=x+"\""+mapKey+"\""+":"+mapValue;
      //                }
      //               }
      //            }
    
    //this.paramToPass="{"+x+"}";
   // console.log("parameters----",this.paramToPass)
     
   if(this.modalForm1.valid){

   
   const paramVal={
     "workflowDetailParamId":this.workflowDetailParamId!==null?this.workflowDetailParamId:0,
     "workflowDetailId":this.workflowDetailId,
    //  "asOdDate":this.selectedDate,
     "parameters":this.paramToPass,
     "params":paramValue,
     "paramList":this.modalForm1.controls.paramList.value
   }
   console.log("data 1234   :"+this.jobData.clientId);
   console.log("Param values: "+JSON.stringify(paramVal));
   this.paramEmit.emit(paramVal);
  // parameters.clear();
  }
  else{

    this.toast.showInfo("Please Add parameters","For Save")
  }

   //this.doAction(data);
  }

//   arrayToString = function(string data){
//     return string.join(", ");
// };

  onRowChange(e,type){

    if(type==='System'){
      this.rowChangeEmit.emit(this.data);
    }
    if(type==='Custom'){
      this.rowChangeEmit.emit(this.customJobDetailsData);
    }

    
    console.log(this.data);
  }

  getModalObject(form) {
    this.modalForm = form;
  }


  addTodoParam(event) {
    console.log("this.modalForm1.get('paramTypeValue').value ",JSON.stringify(this.modalForm1.get('paramTypeValue').value));
   
    console.log("this.modalForm1.get('paramValue').value ",this.modalForm1.get('paramValue').value);
    console.log("paramValue ",this.modalForm1.get('paramValue').value)
    let paramName= this.modalForm1.get('paramName').value;
    let paramType= this.modalForm1.get('paramType').value.key;
    let paramValue= this.modalForm1.get('paramValue').value;
    let paramFlag=this.paramFlag;
    let parameterLabel=this.modalForm1.get('parameterLabel').value;
    console.log("paramValue1 ",paramValue);
    if(paramValue==null || paramValue==""){
      paramValue=null;
      paramFlag=false;
    }
    if(paramValue!=null && paramValue!="" && paramType=="List"){
      paramValue=JSON.stringify(paramValue);
      
    }
    
    let paramTypeValue=this.modalForm1.get('paramTypeValue').value;
    if(paramTypeValue==null || paramTypeValue==""){
      paramTypeValue=null;
    }
    if(paramTypeValue!=null && paramTypeValue!="" && paramType=="List"){
      paramTypeValue=JSON.stringify(paramTypeValue)
    }
console.log("paramValue ",paramValue);

    if(paramType==="Number"){
    var pattern = /^\d+\.?\d*$/;
    if(!pattern.test(paramValue) && paramValue!=null && paramValue!=""){
      
      this.toast.showInfo('Please Enter Number ','error');
      return;

    }
    }
    console.log("moved----");
    if(trim(paramName).length>0){
    this.todoObjview = {
      paramName: trim(paramName),
      paramValue: paramValue,
      completed: false,
      paramType:trim(paramType),
      paramTypeValue:paramTypeValue,
      paramFlag:paramFlag,
      parameterLabel:trim(parameterLabel),
    }
      // completed: false
      let compare = this.paramList.filter(x=>x.paramName.toUpperCase() === this.todoObjview.paramName.toUpperCase());
      //  console.log("compare: ",compare);
      if(compare.length===0){
        this.paramList.push(this.todoObjview);
        this.modalForm1.patchValue({
          paramName: '',
          paramValue: ''
        });
        this.modalForm1.patchValue({
          paramList: this.paramList
        });
        event.preventDefault();
      }else{
        this.toast.showError("This instance is already added", "error");
        this.modalForm1.patchValue({
          paramName: '',
          paramValue: ''
        });
      }
    }
    console.log("this.paramList ",this.paramList)
      }


      deleteTodoParam(index){
        this.paramList.splice(index, 1);
        console.log("this.paramList ",this.paramList)
      }



      onTypeChange(event, workflowId:Number){
        console.log("working---",this.modalForm1);
        if(this.modalForm1.controls.paramType.value.value!=null){
          if(this.modalForm1.controls.paramType.value.key=="Text"){
            this.showParamField=true;
            this.showDropDown=false;
            this.columnDistinctValues=[];
            this.selectedIndutries=[];
            this.onList=false;

            this.paramPlaceHolder="Enter String"
            this.modalForm1.patchValue({
              paramValue: ''
            });
            this.modalForm1.patchValue({
              paramTypeValue:null
            })


           }
          if(this.modalForm1.controls.paramType.value.key=="Number"){
            this.showParamField=true;
            this.showDropDown=false;
            this.columnDistinctValues=[];
            this.selectedIndutries=[];
            this.onList=false;

            this.paramPlaceHolder="Enter Number"
            this.modalForm1.patchValue({
              paramValue: ''
            });
            this.modalForm1.patchValue({
              paramTypeValue:null
            })

          }
          if(this.modalForm1.controls.paramType.value.key=="Date"){
            this.showParamField=false;
            this.showDropDown=false;
            this.columnDistinctValues=[];
            this.selectedIndutries=[];
            this.modalForm1.patchValue({
              paramValue: ''
            });
            this.modalForm1.patchValue({
              paramTypeValue:null
            })
          }

          if(this.modalForm1.controls.paramType.value.key=="List"){
            this.showParamField=true;
            this.showDropDown=true;
            this.onList=true;
            this.modalForm1.patchValue({
              paramTypeValue:null
            })
            // this.workflowService.getColumnDistintValues(this.jobData.clientId).subscribe(
              this.workflowService.getColumns(workflowId).subscribe(
            data=>{
              this.tableData=data;
              this.column=reMapForAutoComplete(data,"column","column")

             })
          
          }

        }
      }


     onValuesChange(event){
      this.columnDistinctValues=[];
      this.selectedIndutries=[];
      let columnName=this.modalForm1.controls.column.value.key;
     
      let tableName = this.tableData[0].tableName 
      console.log("tableName: ",tableName);
      // for(var i=0;i<this.tableData.length;i++){
      //    if(this.tableData[i].group===group){
      //     this.industriesList=this.tableData[i].industries
      //    }

      this.workflowService.getColumnDistintValues(this.jobData.clientId,columnName,tableName).subscribe(
        data=>{
          //this.tableData=data;
          this.columnDistinctValues=reMapForAutoComplete(data,"columnName","columnName")
          this.modalForm1.patchValue({
            paramTypeValue:this.columnDistinctValues
          })
         })

      
      // for(var i=0;i<this.industriesList.length;i++){
      //   this.industriesMap.push({key:this.industriesList[i],value:this.industriesList[i]});
      // }
      }

      multiIndustriesSelectChange(eventData){
        this.list=[];
          this.selectedIndutries = eventData.value;
          console.log("selectedIndutries Is:", this.selectedIndutries);
          for(var i=0;i<this.selectedIndutries.length;i++){
            this.list.push(this.selectedIndutries[i].key)       
              }
              console.log("list Is:", this.list);

          this.modalForm1.patchValue({
                paramValue:this.list
          });
      }


      // reMapForclientCompleteMultiSelect(
      //   data: any[],
      //   keyCol: string,
      //   valCol: string
      // ): { groupKey: string | number; groupValue: string }[] {
      //   return data.map(d => ({ groupKey: d[keyCol], groupValue: d[valCol] }));
      // }

      

      resetOnaddParam(){

        this.modalForm1.reset();
        this.paramList=[];
        this.modalForm1.get('paramType').setValue({
         key:"Text",
         value:"String"
        })
        this.onTypeChange(event,this.workflowId);
        this.paramPlaceHolder="Enter String"
        this.showParamField=true;
      }

  

  
}


