import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { ReportConfigurationModel } from "../models/reportConfiguration.model";

@Injectable({
    providedIn: 'root'
  })
export class ReportConfigurationService {
  
  
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  saveOrUpdate(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/createReportConfiguration",data);
} 


getAllReportConfigurationList(){
  return this.http.get<any[]>(environment.baseUrl + "/apis/getAllReportsConfiguration");
}

deleteReportConfigurationId(reportConfigurationId) {
    return this.http.delete<ReportConfigurationModel[]>(environment.baseUrl + "/apis/deleteReportConfiguration/"+reportConfigurationId);
  }




  }

