import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SspApplicationService } from '../../services/ssp-application.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { stringify } from '@angular/compiler/src/util';
import { TitleService } from 'src/app/core/services/title.service';
//import { ApplicationStateService } from '../../services/application-state.service';

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss']
})
export class HomeScreenComponent implements OnInit {

  env:string;
  state:string;
  reportHeadingName:string;
 // private subscription_state$: any;

  constructor(private sspapplicationService:SspApplicationService,private toast:ToastService,private titleService:TitleService) {

    this.titleService.setTitle("Home Screen");
    
    // this.subscription_state$ = this._applicationStateService.execChange.subscribe(
    //   value => {
    //     this.stateBoolean= value;
    //   }
    // );
    // this._applicationStateService.stateChange(true);
    this.state=localStorage.getItem("stateflag");
   }


  // ngOnInit(): void {
  //   const tempURL = environment.reportUrl;
  //   this.sspapplicationService.getInstanceforHome().subscribe(
  //     data=>{
  //       if(data!==null && data.length>0){
  //         this.env=tempURL+data;   
                 
  //         }
  //       });
  
    
  // }

  ngOnInit() {
    const tempURL = environment.reportUrl;
  //  instanceUrl:String
    this.sspapplicationService.getInstanceforHome().subscribe(
      (data) => {
        console.log("DAta "+data.reportUrl);
        if(typeof data.reportUrl!='undefined' && data.reportUrl != null){
          this.env=tempURL+data.reportUrl;
          this.reportHeadingName=data.reportName;
         }
        else{
          console.log("Error message:",data);
          this.toast.showError(data.error, "error");
        }
                    
       },
      // (err) => {
      //    this.toast.showError(err.error.message, "error");
      // }
      
      );
      //this.env=tempURL+this.instanceUrl;
  }


}
