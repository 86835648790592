import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from "rxjs";
import { FileService } from 'src/app/files/services/file.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { ClientService } from "src/app/dashboard/services/client.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { delay } from 'rxjs/operators';
import { __await } from 'tslib';


@Component({
  selector: 'app-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss']
})
export class ExportReportComponent implements OnInit {
  activeRoute$: Subscription;
  groupList:any=[];
  downloadURL:string;
  channelArray:Array<string>=[];
  reportListByGroup:any=[];
  reportList:{embeddedUrl:any; reportName:any}[]=[];
  RequestGroupViewModel:any=[];
  selectedGroup: { key: any; value: any }[] = [];
  selectedGroupList:{ groupId: any; groupName: any }[] = [];
  popUpButton:{ groupId: any; groupName: any }[] = [];
  // selectedItems:{ groupName: any; instanceUrl: any;reportName: any; viewName: any }[] = [];
  selectedItems:{fileName: any;
  groupName: any;
  reportName: any;
  requestUrl: any;
  viewId:any;
  viewName: any;
  viewType: any;
  zohoViewName:any}[]=[];
  selectItem:{requestUrl:any; fileName:any; viewName:any}[]=[];
  getReportList:any=[];
  blob :any;
  selectedData:any=[];
  selectedRows:{}
  checkBoxCounter:number=-1;
  saveReportList:any=[];
  headerForm:FormGroup=null;
  constructor(
    private titleService: TitleService,
    private fileService: FileService, 
    private reportDashboardService:ReportDashboardService,
    private  toast: ToastService,
    private activeRoute: ActivatedRoute,
    private clientService: ClientService,
    private loader:LoaderService,
    private http: HttpClient,
    
  ) {
    this.headerForm = new FormGroup({
      GroupData: new FormControl(null),
      

    });

   }

  ngOnInit(): void {

    this.setTitle();
    this.getAllGroups();
    this.getAllReportList();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

    getAllGroups(){

  this.reportDashboardService.getAllGroups().subscribe(
    res => {
      // this.loader.hideLoader();
     
      this.groupList=res;
      this.groupList=reMapForAutoComplete( this.groupList,"groupId","groupName");
        console.log("all gropus  ", this.groupList);

      },
      err => {
       // console.log('url',err);
       this.toast.showError("failed to get groups", "error");

      }
  )

  }

  // onGroupChange(data){

  // }

  // callReports(data){

  //   console.log("groupId ",data)

  //  // this.headerForm.controls.ReportData.reset();
  //   this.reportDashboardService.getReportByGroupId(data).subscribe(

  //     res => {
  //       // this.loader.hideLoader();
        
  //       this.reportList=res;
  //       console.log("this.reportList  ",this.reportList);
  //       this.reportList=reMapForAutoComplete( this.reportList,"embeddedUrl","appName");
  //        console.log("all report  ", this.reportList);


  
  //       },
  //       err => {
  //        // console.log('url',err);
  //        this.toast.showError("failed to get groups", "error");
  
  //       }
  //   )



  // }

  

  multiGroupSelectChange(eventData){
   
    //console.log("eventData", eventData);
      this.selectedGroup = eventData.value;
    
   // this.updateReportDate(this.reportList,this.selectedGroup);

   for (var i = 0; i < this.selectedGroup.length; i++) {
    // if( this.selectedGroup[i].key===this.selectedGroupList.)
    this.selectedGroupList.push({ groupId: this.selectedGroup[i].key, groupName: this.selectedGroup[i].value });
    console.log("selectedDataType Are:", this.selectedGroupList);
  }

  //  for(var i = 0; i <this.selectedGroup.length; i++){
    

  //   const data1 = {
     
  //     groupId: this.selectedGroup[i].key,
  //     groupName: this.selectedGroup[i].value,
      
  //   };
    this.reportDashboardService.getReportsByGroupData(this.selectedGroupList).subscribe(
      res => {
        console.log("this.RequestGroupViewModel  ",this.RequestGroupViewModel)
        this.RequestGroupViewModel=res;

      },
      err => {

      }
    )
    //this.selectedGroup=[]
    this.selectedGroupList=[];
  //  }

    // )
  //   if(this.selectedGroup.length!=0){
  //   for (var i = 0; i < this.selectedGroup.length; i++) {

  //     this.reportDashboardService.getReportByGroupId(this.selectedGroup[i].key).subscribe(

  //       res => {
  //         // this.loader.hideLoader();
          
  //         this.reportListByGroup=res;
  //         console.log("this.reportListByGroup  ",this.reportListByGroup);
  //         this.reportListByGroup=reMapForAutoComplete( this.reportListByGroup,"embeddedUrl","appName");
  //          console.log("all report  ", this.reportListByGroup);
  //          for(var i = 0; i < this.reportListByGroup.length; i++){

  //           this.reportList.push({ embeddedUrl: this.reportListByGroup[i].key, reportName: this.reportListByGroup[i].value });
  //           }
           
    
  //         },
  //         err => {
  //          // console.log('url',err);
  //          this.toast.showError("failed to get groups", "error");
    
  //         }
  //     )
      
        
  //     // this.selectedNotificationType.push({ notificationId: notificationEditList[i].notificationId, notificationName: notificationEditList[i].notificationName });
  //     // console.log("selectedNotificationType Are:", this.selectedNotificationType);
  //   }
  //   console.log("reportList Are:", this.reportList);
  // }
  // else{
  //   this.reportList=[];

  // }
    
   

    
  }

  downloadPDF(data,formatType){

    console.log("rowdata  ",data)
    // const data1={
    //   requestUrl:data.instanceUrl,
    //   fileName:formatType,
     

    // }
    const data1={
      requestUrl:data.requestUrl,
      fileName:formatType,
      viewName:data.zohoViewName
    }
    this.selectItem.push({ requestUrl: data1.requestUrl, fileName: data1.fileName,viewName:data1.viewName });
    
    this.reportDashboardService.downloadPDF(this.selectItem).subscribe(

      res => {
        for(var k in res){
        this.downloadURL=res[k].requestUrl;
        console.log("downloadURL  ",this.downloadURL)
     // this.blob = new Blob([res.requestUrl], {type: 'application/pdf'});

  // var downloadURL = window.URL.createObjectURL(res.requestUrl);
   var link = document.createElement('a');
  link.href = this.downloadURL;
  link.download = "Blank.csv";
  link.click();
  this.loader.hideLoader();
 // this.toast.showSuccess('Success','Download Successfully');
        }

        this.selectItem=[];
      },
      err=>{
        this.toast.showError("Failed to Download", "error");

      }
    )
  

  }

 

  onBtnClicked(dataFormat){

    this.selectedItems = this.getReportList.filter(x => x.selected);
    console.log(" this.products ",this.selectedItems);

    for (var i = 0; i < this.selectedItems.length; i++) {
      // if( this.selectedGroup[i].key===this.selectedGroupList.)
       this.selectItem.push({ requestUrl: this.selectedItems[i].requestUrl, fileName: dataFormat,viewName:this.selectedItems[i].zohoViewName });
      //console.log("selectedDataType Are:", this.selectedGroupList);
    }
    console.log("this.selectItem  ",this.selectItem)

    this.reportDashboardService.downloadPDF(this.selectItem).subscribe(

      res => {
        for(var k in res){
        this.downloadURL=res[k].requestUrl;
        console.log("downloadURL  ",this.downloadURL)

        this.channelArray.push(this.downloadURL)
        
      //this.callLink( this.channelArray);
  
        }
        console.log("this.channelArray ",this.channelArray)
        
       this.function(this.channelArray);
    
        this.channelArray=[];
        this.selectItem=[];
        
      },
      err=>{
        this.toast.showError("Failed to Download", "error");

      }
    )

           
    };

  //    delay(ms: number) {
  //     return new Promise( cneckAll => setTimeout(cneckAll, ms) );
  // }

  function (linkArray) {
    for (var i = 0; i < linkArray.length; i++) { 
      if(i==0){
        var link = document.createElement('a');
        link.href = linkArray[i];
        link.download = "Blank.csv";
        link.click();
      }
      else{
        setTimeout(function (path) { window.location = path; }, 3000 + i * 3000, linkArray[i]);
      }
    }        
  };

    
   
    // async cneckAll(){
    //   console.log("hi rajan")
    //   await delay(900000);
    //   console.log("hi aman")
    // }

    downloadAll(urls) {
      var link = document.createElement('a');
    
      link.setAttribute('download', null);
      link.style.display = 'none';
    
      document.body.appendChild(link);
    
      for (var i = 0; i < urls.length; i++) {
        link.setAttribute('href', urls[i]);
        link.click();
      }
    
      document.body.removeChild(link);
    }
  


  getAllReportList(){

    this.reportDashboardService.getAllReportList().subscribe(

      res=>{
      this.getReportList=res
      this.saveReportList= this.getReportList

      },
      err=>{

      }
    )
  }

  multiGroupChange(eventData){
    this.selectedGroup = eventData.value;
    if(this.selectedGroup.length==0){
      this.getAllReportList();
    }
    
    // this.updateReportDate(this.reportList,this.selectedGroup);
 
    for (var i = 0; i < this.selectedGroup.length; i++) {
     // if( this.selectedGroup[i].key===this.selectedGroupList.)
     this.selectedGroupList.push({ groupId: this.selectedGroup[i].key, groupName: this.selectedGroup[i].value });
     console.log("selectedDataType Are:", this.selectedGroupList);
   }
   

  for (var i = 0; i < this.selectedGroupList.length; i++){

     for (var j = 0; j < this.saveReportList.length; j++){

     if(this.selectedGroupList[i].groupId==this.saveReportList[j].groupId){

        this.selectedData.push(this.saveReportList[j])

      }
   
  }
 
   }

if(this.selectedData.length==0){
    this.getReportList=[];
  }
  this.getReportList = this.selectedData
  this.selectedGroupList=[];
  this.selectedData=[];
  console.log(" this.getReportList  ", this.getReportList)
}



callfunc(e){
  // let checkBoxCounter=-1;
  if(e.target.checked){
    console.log( "e.target.checked ",e.target.checked)
    this.checkBoxCounter=this.checkBoxCounter+1;
  
  }
  else{

    this.checkBoxCounter=this.checkBoxCounter-1;
  }
  console.log( "checkBoxCounter  ",this.checkBoxCounter)
  

}

}
