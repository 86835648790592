import { Component, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { FileService } from 'src/app/files/services/file.service';
import { UserService } from 'src/app/user/services/user.service';
import { UserModel } from '../../models/user.model';
import { UserOpsComponent } from 'src/app/user/components/user-ops/user-ops.component';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { UserSelectorComponent } from 'src/app/userComp/components/user-selector/user-selector.component';
import { reMapForAutoCompleteMultiSelect } from 'src/app/utils/re-mapper-multiselect';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { SspApplicationModel } from 'src/app/selfServicePortal/model/selfServicePortal.model';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { UserHeaderComponent } from 'src/app/userComp/components/user-header/user-header.component';
import { ReportDashboardService } from '../../services/report-dashboard.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
   @ViewChild(UserOpsComponent) userOpsComponent;
   @ViewChild(UserSelectorComponent, { read: false, static: false })
   userSelectorComponent: UserSelectorComponent;

   @ViewChild(UserHeaderComponent, { read: false, static: false })
   userHeaderComponent:UserHeaderComponent;

   @ViewChild("panelForm", {static: false })
   panelForm!:SidePanelComponent;

  userList: UserModel[] = [];
  editUserValue:UserModel=null;
  activeRoute$:Subscription;
  roleLists:any=[];
  clientLists:any=[];
  applicationLists:any=[];
  clientApplicationDataTypeList:any=[];
  notificationList:any=[];
  roleId:number;
  applicationData: any=[];
  activeNotifications:{notificationId:any;notificationName:any}[]=[];
  allGroupData:any;
  applicationDataValues:any;
  rolePermission:any;
  data: any;
  allReportViewData:any;
  allReportViewDataList:any;
  allStatusData: {key: any; value: any }[]=[];
  searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String | number, header:String }[];
  workSpaceList:{key: any; value: any }[]=[];
  locationList: any[];
  departmentList: any[];
  menu:string;
  submenu:string;
 // locationList: {locationId:string; locationName:string} [] = [];
  //departmentList: {departmentId:string; departmentName:string} [] = [];
  state:string;
  constructor(private userService: UserService,private toast:ToastService,private loader:LoaderService,
     private activeRoute: ActivatedRoute, private titleService: TitleService,private fileService: FileService,private sspapplicationService:SspApplicationService,private reportDashboardService:ReportDashboardService) {

      this. searchByCols = [
        { field: "userName", header: "User Name" },
        { field: "firstName", header: "First Name" },
        { field: "lastName", header: "Last Name" }, 
        { field: "roleName", header: "Role Name" },  
      ];
      this.menu=localStorage.getItem("menu");
      this.submenu=localStorage.getItem("submenu");
      this.state=localStorage.getItem("stateflag");
      }
  ngOnInit() {

    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );
    this.buttonShowHidePermission();
  
  }
  getAllUserList() {
    this.loader.showLoader();
    this.userService.getAllUserList().subscribe((data) => {
      this.userList = data;
      this.loader.hideLoader();
    }
    ,(err) => {
       this.loader.hideLoader();
      this.toast.showError(err.error.message, "error");
     
    }
    
    );
  }
 editUser(data){
  this.getUserById(data.userId);
  this.userHeaderComponent.panelForm.toggleSlider();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
addUser(data){
  let userStatus=0;
  if(data.statusMap.key==="Active"){
    userStatus=1;
  }

  console.log("Location ::",data.location);
  console.log("Location ::",data.department);
  
  let clientsSelectedList = data.clients;
  let applicationSelectedList = data.applications;
  let fileDataTypeSelectList = data.fileDataType;
  let groupSelectList = data.group;
  let reportSelectList = data.report;
  let reportViewSelectList = data.reportView;
  let notificationSeletedList =data.noticationType;
  let departmentSelectedList = data.department;
  let locationSelectedList = data.location;
  let tablesList = data.tables;

  const userData = {
        userId:this.editUserValue!==null?this.editUserValue.userId:0,
        userName:data.userName,
        firstName:data.firstName,
        lastName:data.lastName,
        status:userStatus,
        phone:this.editUserValue!==null?this.editUserValue.phone:null,
        department:this.editUserValue!==null?this.editUserValue.department:null,
       // createdBy:this.editUserValue!==null?this.editUserValue.createdBy:0,
        createdAt:this.editUserValue!==null?this.editUserValue.createdAt:null,
        //modifiedBy:this.editUserValue!==null?this.editUserValue.modifiedBy:0,
        modeifiedAt:this.editUserValue!==null?this.editUserValue.modeifiedAt:null,
        roleId:data.roleId!==null?data.roleId.key:null,
        roleName:data.roleId!==null?data.roleId.value:null,
        // clientId:0,
        clientsMappingList:clientsSelectedList,
        applicationMappingList:applicationSelectedList,
        datatypeMappingList:fileDataTypeSelectList,
        groupMappingList:groupSelectList,
        reportMappingList:reportSelectList,
        reportViewMappingList:reportViewSelectList,
        //assignAll:data.userFlag,
        assignAllClients:data.clientFlag,
        assignAllApplications:data.applicationFlag,
        assignAllDataTypes:data.fileTypeFlag,
        assignAllGroups:data.groupFlag,
        assignAllReports:data.reportFlag,
        assignAllReportViews:data.reportViewFlag,
        notificationMappingList:notificationSeletedList,
        workspace:data.workspace.value,
        userLocationMapping:locationSelectedList,
        userDepartmentMapping:departmentSelectedList,
        tables: tablesList

  };
  console.log("userData ",userData);
  this.userService.saveOrUpdateUser(userData).subscribe((data) => {
      // this.userSelectorComponent.userForm.reset();
     // this.userSelectorComponent.resetForm();
     // this.userSelectorComponent.resetCheckedFlag();
      this.editUserValue=null;
      this.toast.showSuccess('saved successfully','success');
      this.getAllUserList();
    },
    (err) =>{
      //this.toast.showWarn('All fields are mandatory ','error');
      this.toast.showError(err.error.message, "error");
    } 
    ); 
 
}

getUserById(id){
  this.userService.getUserById(id)
  .subscribe(
    data=>{
      this.editUserValue =data;  
    },
    error => {
      this.toast.showError(error.error.message, "error");
    }
    )
  }

getRoleList(){
  this.userService.getRoleListData().subscribe(
    (data) => {
      this.roleLists=data;
      this.roleLists=this.formatFields(this.roleLists);
      //console.log("user comp role list" +this.roleLists);
    },
    (err) =>{
      this.toast.showError(err.error.message, "error");
    } 
  );
}

 formatFields(roleList) {
    return reMapForAutoComplete(roleList, "roleId", "roleName");
  }

  // getClientList(){
  //   this.userService.getClientListData().subscribe(
  //     (data) => {
        
  //       this.clientLists=data;
  //       this.clientLists=this.formatClientFields(this.clientLists);
  //       //console.log("clientLists comp role list" +this.clientLists);
      
  //     },
  //     (err) =>{
  //       this.toast.showError(err.error.message, "error");
  //     } 
  //   );
  // }


  formatClientFields(clientLists) {
    return reMapForAutoComplete(clientLists, "clientId", "clientName");
  }

  getClientList(){
    this.userService.getClientListData().subscribe(
      (data) => {
     //   this.clientLists=data;
    //    this.clientLists=this.formatClientFields(this.clientLists);
        this.loader.hideLoader();
        this.clientLists = this.reMapForclientCompleteMultiSelect(data, "clientId", "clientName");
        
        //console.log("clientLists comp role list" +this.clientLists);
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  reMapForclientCompleteMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { clientId: string | number; clientName: string }[] {
    return data.map(d => ({ clientId: d[keyCol], clientName: d[valCol] }));
  }
  

  getAllApplications(){
    this.fileService.getAllApplications().subscribe(
      (data) => {
        console.log("Applications",data);
        this.loader.hideLoader();
        this.applicationLists = this.reMapForApplicationMultiSelect(data, "applicationId", "name");
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  reMapForApplicationMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { applicationId: string | number; name: string }[] {
    return data.map(d => ({ applicationId: d[keyCol], name: d[valCol] }));
  }

  getAllApplicationFileDataType(){
    this.fileService.getAllApplicationFileDataType().subscribe(
      (data) => {
        console.log("DataTypes",data);
        this.loader.hideLoader();
        this.clientApplicationDataTypeList = this.reMapForFileDataTypeMultiSelect(data, "clientApplicationFileDataType", "type");
        
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  reMapForFileDataTypeMultiSelect(
    data: any[],
    keyCol: string,
    valCol: string
  ): { clientapplicationfiledatatypeId: string | number; type: string }[] {
    return data.map(d => ({ clientapplicationfiledatatypeId: d[keyCol], type: d[valCol] }));
  }


  getAllNotification(){


    this.fileService.getActiveNotificationList().subscribe(
      (data) => {
        console.log("notificationList",data);
       this.notificationList=data;
       // this.loader.hideLoader();
        this.notificationList = this.reMapForNotificationTypeMultiSelect( this.notificationList, "notificationId", "notificationName");
        
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      }  
  
    )

    // this.fileService.getAllNotificationType().subscribe(
    //   (data) => {
    //     console.log("notification",data);
    //     for(var k in data){
    //       if(data[k].status==true){
    //        this.activeNotifications.push({ notificationId: data[k].notificationId, notificationName: data[k].notificationName })
    //       }
          
    //      }
    //     this.loader.hideLoader();
    //     this.notificationList = this.reMapForNotificationTypeMultiSelect(this.activeNotifications, "notificationId", "notificationName");
        
    //   },
    //   (err) =>{
    //     this.toast.showError(err.error.message, "error");
    //   }  

    // )
  }


  reMapForNotificationTypeMultiSelect(

    data: any[],
    keyCol: string,
    valCol: string
  ): {notificationId: string | number; notificationName: string }[] {
    return data.map(d => ({ notificationId: d[keyCol], notificationName: d[valCol] }));


  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};


    callNgOnInitMethods(){

      this.setTitle();
      this.getAllUserList();
      this.getRoleList();
      // this.getClientList();
      this.getClientList();
      this.getAllApplications();
      this.getAllApplicationFileDataType();
      //this.getAllGroupData();
      this.getApplicationData();
      this.getAllNotification();
      this.getApplicationDataValue();
      this.getAllReportViewData();
      this.getStatusData();
      this.getAllWorkspace();
      this.getAllDepartments();
      this.getAllLocations();
    }



    getApplicationData() {
      this.loader.showLoader();
      this.sspapplicationService.getApplicationList().subscribe(
        (data) => {
          this.loader.hideLoader();
          const appData=data;
          this.applicationData = this.reMapForReportMultiSelect( appData, "appId", "appName");
         
          console.log("App Data Is:", this.applicationData);
          // this.applicationData.forEach(item => {
          //   item.createdAt = this.datePipe.transform(item.createdAt, "MM/dd/yyyy");
          // });
        },
        (err) => {
          this.loader.hideLoader();
          this.toast.showError(err.error.message, "error");
        });
    }
  

    getAllGroupData(){

      this.sspapplicationService.getAllGroupData().subscribe((data)=>{
        const groupData=data;
        this.allGroupData = this.reMapForGroupeMultiSelect( groupData, "groupId", "groupName");
        
         },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
      );
    }
    
    reMapForGroupeMultiSelect(

      data: any[],
      keyCol: string,
      valCol: string
    ): {groupId: string | number; groupName: string }[] {
      return data.map(d => ({ groupId: d[keyCol], groupName: d[valCol] }));
  
  
    }

     reMapForReportMultiSelect(

      data: any[],
      keyCol: string,
      valCol: string
    ): {appId: string | number; appName: string }[] {
      return data.map(d => ({ appId: d[keyCol], appName: d[valCol] }));
  
  
    }


    reMapForReportViewMultiSelect(

      data: any[],
      keyCol: string,
      valCol: string
    ): {instanceId: string | number; instanceName: string }[] {
      return data.map(d => ({ instanceId: d[keyCol], instanceName: d[valCol] }));
  
  
    }


    getApplicationDataValue() {
      this.loader.showLoader();
      this.sspapplicationService.getApplicationList().subscribe(
        (data) => {
          this.loader.hideLoader();
          this.applicationDataValues=data;
          // this.applicationData.forEach(item => {
          //   item.createdAt = this.datePipe.transform(item.createdAt, "MM/dd/yyyy");
          // });
        },
        (err) => {
          this.loader.hideLoader();
          this.toast.showError(err.error.message, "error");
        });
    }

    getAllReportViewData(){
     const reportView={
       "instanceId":0
     }
      this.sspapplicationService.getAllInstance(reportView).subscribe((data)=>{
        const reportViewData=data;
        this.allReportViewData = this.reMapForReportViewMultiSelect(reportViewData, "instanceId", "instanceName");
        this.allReportViewDataList=reportViewData;
         },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
      );
    }

    getStatusData(){
      let type="status";
      const dataVal={
        "type" :type
       }
         this.sspapplicationService.getAllGenericDropDownData(dataVal)
         .subscribe(
        (data)=> {
           this.allStatusData = reMapForAutoComplete(data,"itemValue", "itemText");
           //console.log("generic data"+this.allGenericData);
        },
       (err) => {
         this.toast.showError(err.error.message, "error");
       }
       );
     }

    //  addNewUser(){
    //   this.userSelectorComponent.resetForm();
    //   this.userSelectorComponent.resetCheckedFlag();
    //   this.editUserValue=null;
    //   this.panelForm.toggleSlider();
    //  }
    nullEdit(){
      this.editUserValue=null;
    }


    getAllWorkspace(){

      this.reportDashboardService.getWorkspaceList().subscribe(
      (data)=> {
       this.workSpaceList= reMapForAutoComplete(data,"workSpaceName", "workSpaceName");
    //console.log("generic data"+this.allGenericData);
           },
      (err) => {
            this.toast.showError(err.error.message, "error");
            }
           )
      
    }

    getAllLocations(){
      this.userService.getLocationList().subscribe(
      (data)=> {
        console.log("In getAllLocations :",data);
        
       this.locationList=reMapForAutoComplete(data,"locationId", "locationName");
    //console.log("generic data"+this.allGenericData);
           },
      (err) => {
            this.toast.showError(err.error.message, "error");
            }
           )
      
    }

    getAllDepartments(){

      this.userService.getDepartmentList().subscribe(
      (data)=> {
        console.log("In getDepartmentList :",data);
        
       this.departmentList= reMapForAutoComplete(data,"departmentId", "departmentName");
    //console.log("generic data"+this.allGenericData);
           },
      (err) => {
            this.toast.showError(err.error.message, "error");
            }
           )
      
    }

}
