import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { CategorySelectorComponent } from './components/category-selector/category-selector.component';
import { CategoryHeaderComponent } from './components/category-header/category-header.component';
import { CategoryTableComponent } from './components/category-table/category-table.component';


@NgModule({
  declarations: [
    CategorySelectorComponent,
    CategoryTableComponent,
    CategoryHeaderComponent
    

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    CategorySelectorComponent,
    CategoryTableComponent,
    CategoryHeaderComponent
    ]
})
export class CategoryCompModule { }
