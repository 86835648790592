import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientModel } from '../../models/client.model';
import { Binary } from '@angular/compiler';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { map } from 'rxjs/operators';
import { LayoutFilesModel } from '../../models/files.model';
import { getCurrentDate,formatDate } from "src/app/utils/dates";
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { DatePipe } from '@angular/common';
import { TransformPermission } from '../../models/transformPermission.model';
import { Store } from '@ngrx/store';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { ClientSelectorComponent } from 'src/app/clientComp/components/client-selector/client-selector.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { CategoryHeaderComponent } from 'src/app/categoryComp/components/category-header/category-header.component';
import { CategoryService } from '../../services/category.service';
import { CategoryModel } from '../../models/category.model';
import { ReportConfigurationHeaderComponent } from 'src/app/reportConfigurationComp/components/reportConfiguration-header/reportConfiguration-header.component';
import { ReportConfigurationModel } from '../../models/reportConfiguration.model';
import { ReportConfigurationService } from '../../services/reportConfiguration.service';
import { LookupService } from '../../services/lookup.service';
import { LookUpListModel } from '../../models/lookup.model';

@Component({
  selector: 'app-reportConfiguration',
  templateUrl: './reportConfigurationComponent.component.html',
  styleUrls: ['./reportConfigurationComponent.component.scss']
})
export class ReportConfigurationComponent implements OnInit {

  // @ViewChild(ClientSelectorComponent, { read: false, static: false })
  // clientSelectorComponent: ClientSelectorComponent;
  @ViewChild(ReportConfigurationHeaderComponent, { read: false, static: false })
  ReportConfigurationHeaderComponent: ReportConfigurationHeaderComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;
// @ViewChild('fromCal')
// fromCalVariable: ElementRef;
reportConfigurationList:ReportConfigurationModel[];
  editData:ReportConfigurationModel[];
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  currencyData: {key: any; value: any }[]=[];
  reportLists:any=[];
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  state:string;
  frequencyList: {key: any; value: any }[]=[];
  lookUpModelList: LookUpListModel[] = [];
  menu:string;
  submenu:string; 
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService, private sspapplicationService: SspApplicationService,private fileService:FileService,private toast:ToastService,
    private router: Router,private reportConfigurationService:ReportConfigurationService,  private applicationService: SspApplicationService,
    private datePipe:DatePipe, private lookupService: LookupService,private storePermission: Store<{ role: any }>) {
     
    this.titleService.setTitle("Report Configuration");
    this. searchByCols = [
     
      { field: "reportName", header: "Report" },
      { field: "reportShortCode", header: "Report Short Code" },
      { field: "reportingFrequency", header: "Reporting Frequency" },
    ];
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag");

    }
  ngOnInit(): void {
   //this.buttonShowHidePermission();
this.getAllReportConfigurationList();
this.getAllReports();
this.getAllFrequencyType();
this.getCurrencyData();
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllReports(){
  
    this.sspapplicationService.getAllReportsForConfig().subscribe(
      (data) => {        
       // console.log("Applications",data);
        this.reportLists = reMapForAutoComplete(data, "appId", "appName");
        console.log("Applications",this.reportLists);
      },
      (err) =>{
      //  this.toast.showError(err.error.message, "error");
      } 
    );
  }


  getAllReportConfigurationList(){
    
    this.reportConfigurationService.getAllReportConfigurationList().subscribe((data)=>{
      this.reportConfigurationList=data;
  
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }
  
  

  saveReportConfiguration(reportConfigurationForm){
    console.log("test",reportConfigurationForm)
    const model={
      reportConfigurationId:reportConfigurationForm.reportConfigurationId!==null?reportConfigurationForm.reportConfigurationId:0,
      clientId:reportConfigurationForm.clientId.key,
      //clientId:reportConfigurationForm.controls.client.value.key,
      reportId:reportConfigurationForm.reportId.key,
      returnName:reportConfigurationForm.returnName,
      returnCode:reportConfigurationForm.returnCode,
      nameOfReportingInstitution:reportConfigurationForm.nameOfReportingInstitution,
      bank_fICode:reportConfigurationForm.bank_fICode,
      institutionType:reportConfigurationForm.institutionType,
      reportingFrequency:reportConfigurationForm.reportingFrequency.value,
      reportingStartDate:reportConfigurationForm.reportingStartDate,
      reportingEndDate:reportConfigurationForm.reportingEndDate,
      
      reportingCurrency:reportConfigurationForm.reportingCurrency.key,
      reportingScale:reportConfigurationForm.reportingScale,
      scaleValue:reportConfigurationForm.scaleValue,
      taxonomyVersion:reportConfigurationForm.taxonomyVersion,
      toolName:reportConfigurationForm.toolName,
      toolVersion:reportConfigurationForm.toolVersion,
      reportStatus:reportConfigurationForm.reportStatus,
      dateOfAudit:reportConfigurationForm.dateOfAudit,
      generalRemarks:reportConfigurationForm.generalRemarks
    }

   this.reportConfigurationService.saveOrUpdate(model).subscribe(
     (data) => {
    this.reportLists=[];
    this.toast.showSuccess("Report Configuration Saved Successfully", "success");
    this.ReportConfigurationHeaderComponent.reset();

    this.getAllReportConfigurationList();
    this.getAllReports();
   
  },
  (err) => {
  
    this.toast.showError(err.error.message, "error");
    // this.toast.showWarn("Report Configuration already set", "error");

  }

);
   
 
}


editReportConfiguration(rowData){

  this.reportLists.push({ key: rowData.reportId, value: rowData.reportName });
    this.editData=rowData;
    this.ReportConfigurationHeaderComponent.openEdit();
    
  }


  deleteReportConfigurationId(data) {
      //   const genericModel ={
      //    "reportConfigurationId": data.reportConfigurationId
      //  }
    
        //this.loader.showLoader();
        this.reportConfigurationService.deleteReportConfigurationId(data.reportConfigurationId).subscribe(
          data => {
              this.toast.showSuccess('Success', 'Report Configuration deleted successfully');
              this.getAllReportConfigurationList();
            },
            err => {
              //this.toast.showWarn('This Category is already linked with Reports','error');
              this.toast.showError(err.error.message, "error");
            });
      }

      getCurrencyData(){
        let type="currency";
        const dataVal={
          "type" :type
         }
           this.sspapplicationService.getCurrencyCodeData()
           .subscribe(
          (data)=> {
            //  this.allGenericCurrencyData = reMapForAutoComplete(data,"itemValue", "itemText");
            this.currencyData = reMapForAutoComplete(data,"currencyCode", "currencyName");
            // console.log("generic data"+this.allGenericData);
          },
         (err) => {
           this.toast.showError(err.error.message, "error");
         }
         );
       }

       getAllFrequencyType(){
        const lookupModel = {
          "tableName":"frequency",
          "clientId": 139
        }
        this.lookupService.getLookUpDataByTableAndClient(lookupModel)
         .subscribe(
           data=>{
             this.lookUpModelList=data;
            this.frequencyList=reMapForAutoComplete(this.lookUpModelList,"description","description");
            console.log("Inside File Master",this.frequencyList);
           },
           err=>{
            this.toast.showError(err.error.message, "error");
            //this.loader.hideLoader();
          }
         )
        
       }

}
