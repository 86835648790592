import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { StressScenarioHeaderComponent } from 'src/app/stressScenarioComp/components/stressScenario-header/stressScenario-header.component';
import { StressScenarioModel } from '../../models/stressScenario.model';
import { StressScenarioService } from '../../services/stressScenario.service';
import { StressScenarioValuesHeaderComponent } from 'src/app/stressScenarioValuesComp/components/stressScenarioValues-header/stressScenarioValues-header.component';
import { StressScenarioValuesComponent } from '../stress-scenario-Values/stressScenarioValues.component';
import { StressScenarioValuesModel } from '../../models/stressScenarioValues.model';
import { InterestRateRiskScenarioHeaderComponent } from 'src/app/interestRateRiskScenarioComp/components/interestRateRiskScenario-header/interestRateRiskScenario-header.component';
import { InterestRateRiskScenarioModel } from '../../models/interestRateRiskScenario.model';
import { InterestRateRiskScenarioService } from '../../services/interestRateRiskScenario.service';

@Component({
  selector: 'app-interestRateRiskScenario',
  templateUrl: './interestRateRiskScenario.component.html',
  styleUrls: ['./interestRateRiskScenario.component.scss']
})
export class InterestRateRiskScenarioComponent implements OnInit {

  @ViewChild(InterestRateRiskScenarioHeaderComponent, { read: false, static: false })
  InterestRateRiskScenarioHeaderComponent: InterestRateRiskScenarioHeaderComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;
// @ViewChild('fromCal')
// fromCalVariable: ElementRef;
interestRateRiskScenarioList:InterestRateRiskScenarioModel[];
  editData:InterestRateRiskScenarioModel[];
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  state:string;
  setValue: boolean = false;
  menu:string;
  submenu:string;

  @ViewChild(StressScenarioValuesComponent, { read: false, static: false })
  stressScenarioValuesComponent: StressScenarioValuesComponent;

  editSetValueData:StressScenarioValuesModel[];
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService, private sspapplicationService: SspApplicationService,private fileService:FileService,private toast:ToastService,
    private router: Router,private interestRateRiskScenarioService:InterestRateRiskScenarioService,  private applicationService: SspApplicationService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
     
    this.titleService.setTitle("Interest Rate Risk Scenario");
    this. searchByCols = [
     
      { field: "stressScenarioName", header: "Stress Scenario Name" },
      { field: "description", header: "Description" },
      // { field: "status", header: "Status" }
      
    ];
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag");

    }
  ngOnInit(): void {
   //this.buttonShowHidePermission();
this.getAllInterestRateRiskScenarioList();
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }


  getAllInterestRateRiskScenarioList(){
    
    this.interestRateRiskScenarioService.getAllInterestRateRiskScenarioList().subscribe((data)=>{
      this.interestRateRiskScenarioList=data;
  console.log("interestRateRiskScenarioList", this.interestRateRiskScenarioList);
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }
  
  

  saveInterestRateRiskScenario(interestRateRiskScenarioForm){
    console.log("test",interestRateRiskScenarioForm)
    const model={
      irr_scenario_id:interestRateRiskScenarioForm.irr_scenario_id!==null?interestRateRiskScenarioForm.irr_scenario_id:0,
      clientId:null,
      scenario:interestRateRiskScenarioForm.scenario,
      type:interestRateRiskScenarioForm.interestRateRiskType,
      status:interestRateRiskScenarioForm.statusMap.key=='Active'?true:false,
      bucket1:interestRateRiskScenarioForm.bucket1,
    bucket2:interestRateRiskScenarioForm.bucket2,
    bucket3:interestRateRiskScenarioForm.bucket3,
    bucket4:interestRateRiskScenarioForm.bucket4,
    bucket5:interestRateRiskScenarioForm.bucket5,
    bucket6:interestRateRiskScenarioForm.bucket6,
    bucket7:interestRateRiskScenarioForm.bucket7,
    bucket8:interestRateRiskScenarioForm.bucket8,
    bucket9:interestRateRiskScenarioForm.bucket9,
    bucket10:interestRateRiskScenarioForm.bucket10,

    }

    
   this.interestRateRiskScenarioService.saveOrUpdate(model).subscribe(
     (data) => {
  
    //this.toast.showSuccess("Stress Scenario Saved Successfully", "success");
    this.toast.showSuccess('Your Change Request Sent for Approval','');
    this.getAllInterestRateRiskScenarioList();
    this.InterestRateRiskScenarioHeaderComponent.reset();
    
    this.getAllInterestRateRiskScenarioList();
   
  },
  (err) => {
  
    this.toast.showError(err.error.message, "error");

  }

);
   
 
}


editInterestRateRiskScenario(rowData){
    this.editData=rowData;
    this.InterestRateRiskScenarioHeaderComponent.openEdit();
    
  }

 


  deleteInterestRateRiskScenarioId(data) {
        this.interestRateRiskScenarioService.deleteInterestRateRiskScenarioById(data.irr_scenario_id).subscribe(
          data => {
              this.toast.showSuccess('Success', 'Stress Scenario deleted successfully');
              this.getAllInterestRateRiskScenarioList();
            },
            err => {
              //this.toast.showWarn('This Category is already linked with Reports','error');
              this.toast.showError(err.error.message, "error");
            });
      }



      editInterestRateRiskScenarioValues(rowData){
        console.log("Child Called")
          this.editSetValueData=rowData;
          this.InterestRateRiskScenarioHeaderComponent.openSetValues();
          
        }

}
