import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormsModel } from 'src/app/models/forms.model';


import { trim } from 'lodash';
import { WorkflowService } from 'src/app/dashboard/services/workflow.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';


@Component({
  selector: 'app-workflow-job-table',
  templateUrl: './workflow-job-table.component.html',
  styleUrls: ['./workflow-job-table.component.scss']
})
export class WorkflowJobTableComponent implements OnInit {
 
  @Input() data;/////@Input() data:LookupColumnListModel[] = [];
  @Output() editWorkflowCol=new EventEmitter();
  @Input() hidebutton:boolean;
  @Output() viewworkflowLog=new EventEmitter();
  @Output() execute=new EventEmitter();
  @Output() setupJobData=new EventEmitter();
  @Output() resumeEmit=new EventEmitter();
  @Input() permission:any;
  @Input() worklogDataInworkflowId:any;
  @Output() jobParameter= new EventEmitter();
 allJobParameter:{workflowDetailId:string | number;controls:any[]}[]=[];

  @ViewChild("panelForm3", {static: false })
  panelForm3!:SidePanelComponent;

  selectedJob: any;
  modalFormModel: FormsModel = null;
  modalForm: FormGroup = null;
  paramForm: FormGroup = null;
  selectedIndex = -1;  
  @ViewChild('fromCal') calendarFrom: Calendar
  visible:boolean=false;
  paramHeader="Execute Job";
  asofdate:any=null;
  exeData:any=null;
  viewAsOfDate:string="";
  workFlowType:string;
  workFlowName:any;
  cvar:string;
  asDate:any=null;
  asOfDate:any=null;
  currdate:boolean=false;
  value:string;
  isChecked:boolean;
  form:any=null; 
  startDate:any=null;
  endDate:any=null;

  cols: { field: string; header: string }[];
  paramsConst:{ key: string; value: string }[];
@Input() showWorkflowTable:boolean=true;
dateform:FormGroup=null;
//errorMessege:string;
errorList:String[]=[];
  isVarReport: boolean;
  constructor(private datePipe:DatePipe,
    private toast:ToastService,
    private workflowService: WorkflowService
    ) { 
    this.cols = [
      { field: "workflowName", header: "Workflow" },
      { field: "isActiveDisplay", header: "Active" },
      { field: "workflowTypeDisplay", header: "Type" },
      { field: "status", header: "Status" },
      { field: "lastStatus", header: "Last Job Status" },
      
    ];

    this.form = new FormGroup({
      Date: new FormControl('', Validators.required)
    });

    this.dateform = new FormGroup({
      asOfDate: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl('')
    });
  
  }

  ngOnInit(): void {
  }

  editWorkflowTable(data){
    console.log("edit workflow job",data);
    this.editWorkflowCol.emit(data);

  }

  vieworkflowLogs(data){
    this.viewworkflowLog.emit(data);

  }

  // executeWorkflow(data){
  //   console.log("executeWorkflow in job-table: ",data);
  //   this.visible=true;
  //   this.exeData=data;
  //   this.paramHeader="Execute Job "+this.exeData.workflowName;
  //   this.viewAsOfDate=data.asOfDate;
  //   if(data.transformParams!==null){
      
  //     this.modalFormModel = null;
     
  //    const params = JSON.parse(data.transformParams);
  //    const keys = Object.keys(params);
  //   if (keys && keys.length <= 0) {
  //  //  this.toast.showInfo("info","no parameter available")
  //  } else {
  //    this.createModelFromParams(params,data.asOfDate);

  //    console.log("wf-job-table executeWorkflow(): createMoelFromParam- ", this.createModelFromParams(params,data.asOfDate));
  //  }
  //   }
   
    
  //   // this.execute.emit(data);

  // }


  executeWorkflow(data){​​​​​​​  
    this.modalFormModel=null;
    this.allJobParameter=null;
    this.errorList=[];
    this.asofdate=null;
    console.log("this.exeData.workflowName  ",data)
    this.dateform.reset();
    this.visible=true;
   

  
      this.workflowService.getWorkflowJobsParameterByWorkflowId(data.workflowId).subscribe(
        (data) => {
          this.allJobParameter=data;
          console.log(" this.allJobParameter.controls", this.allJobParameter)
          //this.allJobParameter.forEach(item=>{item.options=JSON.parse(item.options)})
          this.allJobParameter.forEach(item=>{item.controls.forEach(item=>{item.options=JSON.parse(item.options)})})
          console.log("List",this.allJobParameter);
          console.log("this.allJobParameter[0]",this.allJobParameter[0])
          this.modalFormModel=this.allJobParameter[0];
        },
        (err) => {
        // this.toast.showSuccess("Status Not Changed", "error");
         this.toast.showError(err.error.message, "error");
        }
       );
   
    //this.jobParameter.emit(data);
    
    
    

    this.panelForm3.toggleSlider();
    this.form.patchValue({
      Date: 'currDate',
    });
    this.exeData=data;
    console.log("exeData  ",this.exeData);
    this.paramHeader="Execute Job "+this.exeData.workflowName;
    this.viewAsOfDate=data.asOfDate;
    this.workFlowType=this.exeData.workflowTypeDisplay;
    this.workFlowName=this.exeData.workflowName;
    this.workFlowName=this.workFlowName.toLowerCase(); 
    console.log("workflowtype ",this.workFlowType)
    this.cvar=null;

    if(this.exeData.workflowId===484 || this.exeData.workflowId===485 || this.exeData.workflowId===486){
      this.isVarReport = true;
    }else{
      this.isVarReport = false;
    }
    
    if(data.transformParams!==null){​​​​​​​
      
      this.modalFormModel = null;
     
     const params = JSON.parse(data.transformParams);
     const keys = Object.keys(params);
    if (keys && keys.length <= 0) {​​​​​​​
   //  this.toast.showInfo("info","no parameter available")
   }​​​​​​​ else {​​​​​​​
     this.createModelFromParams(params,data.asOfDate, data.clientId);
     
   }​​​​​​​
    }​​​​​​​
  
   
    
    // this.execute.emit(data);

  }​​​​​​​
 
  doAction(data){
    this.visible=false;
    this.exeData=null;
    this.modalForm.reset();
    this.modalForm=null;
    this.modalFormModel=null;
    this.paramHeader="Execute Job";
  }
  executeJobPopup(data){
    console.log("this.asofdate",this.asofdate)
    this.errorList=[];
    if(this.asofdate==null && this.asOfDate==undefined){
      // console.log(" null or empty not accepted");
      // this.panelForm3.toggleSlider();
      //  this.toast.showInfo("asofdate is required ","error");
      this.errorList.push("asofdate field is mandatory");
       //return ;
     }
    //this.panelForm3.toggleSlider();
   // console.log("Data selected in paramForm",this.paramForm.value);
    console.log("Data ",data);
    
    let overrideValue=false;
    if(this.modalForm && this.modalForm.value!==null){
     let paramsConst1=this.modalForm.value;
     
      const retrVal=paramsConst1.override;
      console.log("boolVal ",retrVal);
      if(retrVal===true){
        overrideValue=true;
       
        for(let result in paramsConst1){
          console.log("result",result);
           if(paramsConst1[result]===null || paramsConst1[result]===undefined || trim(paramsConst1[result]).length===0 ){
              
              this.toast.showInfo("Tarnsform params are mandatory","error");
              return ;
           }
           console.log("After null check");
        }
        const hasAsofDateVal=this.exeData.hasAsOfDate;
        if(hasAsofDateVal===true){
         // console.log("this.asofdate ",this.asofdate);
          if(this.asofdate===null && this.asOfDate===undefined){
           // console.log(" null or empty not accepted");
            this.toast.showInfo("asofdate is required for transform","error");
            return ;
          }
        }
      }
     
    
    }

    if(this.form.controls.Date.value ==="dateRange"){
    if(this.startDate!==null && this.endDate!==null){
      let validate=this.validationOnDate(this.startDate,this.endDate);
      if(validate===false){
        this.toast.showWarn('error','date range incorrect');
        this.visible=false;
        this.startDate=null;
        this.endDate=null;
        return;
      }
   }

     if((this.startDate==null && this.endDate!==null) || (this.startDate!==null && this.endDate==null) ){
      this.toast.showWarn("Both start and end dates are required","error");
      this.visible=false;
      this.startDate=null;
      this.endDate=null;
      return;
     }
    }else{
      this.startDate=null;
      this.endDate=null;
    }

    const tempAsOfDate=this.datePipe.transform(this.asofdate, 'yyyy-MM-dd');
    let weightOfSpy=0;
    let weightOfBnd=0;
    let days=0;
    let differenceInDays = 0;

      console.log("this.allJobParameter ",this.allJobParameter)
    if(this.allJobParameter[0].controls!=null && this.allJobParameter[0].controls!=undefined && this.allJobParameter[0].controls.length>0){
    for(var i=0;i<this.allJobParameter[0].controls.length;i++){
     
      let name=this.allJobParameter[0].controls[i].name;
      console.log("typeof ((this.paramForm.get(name).value)) ",this.paramForm.get(name).value)
      if(this.paramForm.get(name).value==null || this.paramForm.get(name).value=="" || this.paramForm.get(name).value==undefined){
      
       this.errorList.push(this.allJobParameter[0].controls[i].label+"  field is mandatory");
      }

      if(this.paramForm.get(name).value=="0" && name.includes("equityexposure")){
        this.errorList.push("For "+this.allJobParameter[0].controls[i].label+"  field Zero Is not Allowed");
       }

      if(name.includes("enddate_parametric") || name.includes("enddate_montecarlo") || name.includes("enddate_historical")){
        if(this.paramForm.get(name).value>tempAsOfDate){
          // Assuming tempAsOfDate is already defined and has a value
          
          const endDate = new Date(this.paramForm.get(name).value);
          const tempAsOfDateObj = new Date(tempAsOfDate);
  
          // Calculate the difference in milliseconds
          const differenceInMs = endDate.getTime() - tempAsOfDateObj.getTime();
  
          // Convert milliseconds to days (optional)
          differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
          
  
          //if(this.paramForm.controls.days_parametric>differenceInDays)
  
          console.log("Inside the Block ");
         } else{
          console.log("Inside the Else Block ");
          this.toast.showError("Start Date Should Be smaller than the End Date", "error");
          this.panelForm3.toggleSlider();
          return;
         }
        }

        if(name.includes("weightofspy_parametric") || name.includes("weightofspy_montecarlo") || name.includes("weightofspy_historical")){
          weightOfSpy = this.paramForm.get(name).value;
        }
        if(name.includes("weightofbnd_parametric") || name.includes("weightofbnd_montecarlo") || name.includes("weightofbnd_historical")){
          weightOfBnd = this.paramForm.get(name).value;
        }

        if(name.includes("days_parametric") || name.includes("days_montecarlo") || name.includes("days_historical")){
          days = this.paramForm.get(name).value;
        }

       
      if(typeof ((this.paramForm.get(name).value))  === 'object'){

        let list=[];
        this.allJobParameter[0].controls[i].options=JSON.stringify(this.allJobParameter[0].controls[i].options);
        if(this.paramForm.get(name).value!=null && this.paramForm.get(name).value!="" && this.paramForm.get(name).value!=undefined){
        for(var j=0;j<this.paramForm.get(name).value.length;j++){
          console.log("Valuee   ",this.paramForm.get(name).value[j].value);
        list.push(this.paramForm.get(name).value[j].value)
        }
      }
        console.log("List 1   ",JSON.stringify(list));
         this.allJobParameter[0].controls[i].paramValue=JSON.stringify(list);
      }
      else{
        this.allJobParameter[0].controls[i].paramValue=this.paramForm.get(name).value;
      }
      console.log("typeof ((this.paramForm.get(name).value)) ",this.paramForm.get(name).value)
    }

    // if(Number(weightOfBnd)+Number(weightOfSpy)>100 || Number(weightOfBnd)+Number(weightOfSpy)<100){
    //   this.toast.showError("The Sum of Weight of SPY & BND is not less/greater than 100", "error");
    //   this.panelForm3.toggleSlider();
    //   return;
    // }

    if(Number(days)>Number(differenceInDays)){
      this.toast.showError("Day's Should be in Between Start Date & End Date", "error");
      this.panelForm3.toggleSlider();
      return;
    }

    // if(this.paramForm.invalid){
    //   let invalidFields=this.findInvalidControls(this.paramForm);
    //   for(var e=0;e<invalidFields.length;e++){
    //   let label=this.allJobParameter[0].controls.filter(x=>x.name.includes(invalidFields[e]))[0].label;
    //   this.errorList.push(label+" field Contains Invalid Values");
    //   }
    //   console.log("invalidFields ",invalidFields);
    
    

    
    if(this.errorList.length>0){
      return;
    }

   
      console.log("paramForm  ",this.paramForm.value);
      console.log("this.asofdate",this.asofdate)
    this.workflowService.updateDetailParamId(this.allJobParameter[0].controls).subscribe(
        data=>{
          //this.loader.hideLoader();
          this.toast.showSuccess('Success','Check Logs for status')
           
        },
        error=>{
          this.toast.showError(error.error.message, "error");
        }
      )
  }
  if(this.errorList.length>0){
    return
  }

    
  this.panelForm3.toggleSlider();  
    const fwdData={
      "data":this.exeData,
      "asofDate":this.asofdate,
      "startDate":this.startDate,
      "endDate":this.endDate,
      "transformParams":this.modalForm&&this.modalForm.value!==null?JSON.stringify(this.modalForm.value):null,
      "singleDate":new Date(),
      "overrideParams":overrideValue,
      "menu":null,
      "submenu":null

    }
    console.log("fwdData   ",fwdData);
    
    console.log("jobs pop up value",fwdData)
    setTimeout(() => {  this.execute.emit(fwdData); }, 5000);
  
    //this.execute.emit(fwdData);
    //this.execute.emit(updateData);
    this.visible=false;
    this.exeData=null;
    //this.calendarFrom.value = null;
    if(this.modalForm!==null){
    this.modalForm.reset();
    }
    this.modalForm=null;
    this.form.patchValue({
      Date: 'currDate',
    });
    this.modalFormModel=null;
    this.asofdate=null;
    this.startDate=null;
    this.endDate=null;
    this.calendarFrom.updateInputfield();
  }

//   async delay(ms: number) {
//     return new Promise( resolve => setTimeout(resolve, ms) );
// }

  changeAsoFdate(e){
    this.asofdate=new Date(e);
    console.log("Child Asof Date",new Date(e));
  }

  findInvalidControls(f: FormGroup) {
    const invalid = [];
    const controls = f.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  changeasoFdate(){

    this.changeAsoFdate(this.dateform.controls.asOfDate.value);

  }

  onDateBlur(e){
    this.asofdate=e.target.value;
   console.log("Blur",e.target.value)
  }

  setUpJobs(data,pageUrl){

    const emitData={
      "data":data,
      "pageUrl":pageUrl
    }
    this.setupJobData.emit(emitData);


  }

  resumeWorkflow(data){
    //console.log("table com ",data)
      this.resumeEmit.emit(data);
  }

  // createModelFromParams(params,asOfDate) {
  //   console.log("")
  //   this.modalFormModel = { controls: [] };
  //   this.modalFormModel.controls.push({
  //     name: "override",
  //     type:"radio",
  //     label:"Override" ,
  //     defaultValue: true,
  //   });
  //   for (let k in params) {
  //     //console.log('vvvvvvv ',params[k]);
  //     let valu=params[k];
      
  //     let dataType="text";
  //     if(k.includes('date')){
  //       dataType="date";
  //     }
      
  //     this.modalFormModel.controls.push({
  //       name: k,
  //       type: dataType === "date" ? "date" : "text",
  //       label: k,
  //       defaultValue: valu,
  //     });
  //   }
  // }

  // getModalObject(form) {
    
  //   this.modalForm = form;
  //   console.log("form",form );
  //   console.log("getModalObject- this.modalForm",this.modalForm);
  // }
  createModelFromParams(params,asOfDate,client) {
    this.modalFormModel = { controls: [] };
    this.modalFormModel.controls.push({
      name: "override",
      type:"radio",
      label:"Override" ,
      defaultValue: true,
    });
    for (let k in params) {
      let valu;
      //console.log('vvvvvvv ',params[k]);
      if(k==="clientid"){
       valu=client;
      }else{
       valu=params[k];
      }
      let dataType="text";
      if(k.includes('date')){
        dataType="date";
      }
      
      this.modalFormModel.controls.push({
        name: k,
        type: dataType === "date" ? "date" : "text",
        label: k,
        defaultValue: valu,
      });
  }
  }

  getModalObject(form) {
    this.modalForm = form;
    this.paramForm=form;
  }

  getDate(exeData){
   this.cvar=null;
   this.currdate=true;
   
  // console.log(" Value of Data is : ",this.currdate);
   
   
 }

 getDateRange(){
  this.cvar='1';
  this.currdate=false;
  return this.cvar;

 }

 
// buidform(){
//   this.form = new FormGroup({
//     Date: new FormControl('', Validators.required)
//   });}

  changeFromDate(event){
    this.startDate=new Date(event);
  }

  changefromDate()
  {
    this.changeFromDate(this.dateform.controls.startDate.value);
  }

  changeToDate(event){
  this.endDate=new Date(event);
  }
  changetoDate(){
    this.changeToDate(this.dateform.controls.startDate.value);
  }
 
  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }

  showLogTable(index: number, event: Event,rowData:any) {
    const ele = event.target as HTMLInputElement;
    if (ele.checked) {
      this.selectedIndex = index;
      this.vieworkflowLogs(rowData);
    } else {
      this.selectedIndex = -1;
    }
  }

  resetPanelForm(){
    this.panelForm3.toggleSlider();
  }

  

}
