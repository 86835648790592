import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ToastService } from "src/app/core/services/toast.service";
import {
  FieldLayoutModel,
  FileLayoutModel,
} from "src/app/dashboard/models/file-layout.model";
import { FileService } from "src/app/files/services/file.service";

@Component({
  selector: 'app-generic-field-layout-form',
  templateUrl: './generic-field-layout-form.component.html',
  styleUrls: ['./generic-field-layout-form.component.scss']
})
export class GenericFieldLayoutFormComponent implements OnInit {

  
  fieldLayoutForm: FormGroup = null;
  @Output() create = new EventEmitter();
  // @Output() firstRow = new EventEmitter();
  @Output() closePanel=new EventEmitter();
  constructor(private fileService: FileService,private toast:ToastService) {
    this.initalizeForm();
  }

  initalizeForm() {
    this.fieldLayoutForm = new FormGroup({
      fieldFormat: new FormControl(null),
    });
  }
  // get fieldTypes() {
  //   const types = ["STRING", "NUMERIC", "Date"];
  //   return types.map((t) => ({ key: t, value: t }));
  // }
  get dateFormats() {
    const formats = [
      "MM/dd/yy",
      "dd/MM/yy",
      "MMddyyyy",
      "MM/dd/yyyy",
      "MM-dd-yyyy",
      "yyyyMMdd",
      "yyyyMd",
      "MMddyy",
      "Mddyy",
      "Mddyyyy",
      "dd-MMM-yyyy",
      "yyyyDDD",
      "MM/dd/yyyy HH:mm:ss",
      "MM-dd-yyyy HH:mm:ss",
      "MMM dd yyyy h:mma",
      "yyyy-MM-dd HH:mm:ss",
      "yyyy-MM-dd",
      "MM/dd/yyyy HH:mm",
      "dd-MMM-yyyy","dd MMM yyyy","dd-MM-yyyy","dd-MM-yyyy HH:mm:ss"
    ];
    return formats.map((t) => ({ key: t, value: t }));
  }
  // get dateFieldChecked() {
  //   return (
  //     !!this.fieldLayoutForm.get("fieldType").value &&
  //     this.fieldLayoutForm.get("fieldType").value.key === "Date"
  //   );
  // }
  ngOnInit(): void {
    // const data$ = this.fieldLayoutForm.valueChanges.subscribe((data) => {
    //   this.firstRow.emit({
    //     fieldName: data.fieldName,
    //     fieldType: data.fieldType ? data.fieldType.key : null,
    //     fieldFormat: data.fieldFormat ? data.fieldFormat.key : null,
    //     fieldSize: data.fieldSize,
    //   });
    //   // data$.unsubscribe();
    // });
  }
  ngOnChanges(changes: SimpleChanges) {
    // if (changes && changes.value && changes.value.currentValue) {
    //   const d: FieldLayoutModel = changes.value.currentValue;
    //   this.fieldLayoutForm.patchValue({
    //     fieldName: d.fieldName,
    //     fieldType: { key: d.fieldType, value: d.fieldType },
    //     fieldFormat: { key: d.fieldFormat, value: d.fieldFormat },
    //     fieldSize: d.fieldSize,
    //   });
    // }
  }
  addField() {
    this.closePanel.emit();
   if(!this.fieldLayoutForm.valid){
     return this.toast.showError("Please fill the mandatory field","error");
   }
    const  fieldFormat = this.fieldLayoutForm.controls.fieldFormat.value;
    this.create.emit({
      fieldFormat: fieldFormat ? fieldFormat.key : null,
    });

 
  }

  resetForm(){
    this.fieldLayoutForm.reset();
    //this.cleanDataConfigListEdit=null;
  }

}
