import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
//import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
//@UntilDestroy()
@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() searchBy: string[] = [];
  @ViewChild('reportButton') reportButton!: ElementRef;
  filterBy: string = 'Search';
  search = new FormControl();
  isOpened: boolean = false;
  handler: any;
  isSuggestionsOpen: boolean = false;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}
  closeDroplist = (event: Event) => {
    const ele = event.target as HTMLElement;
    if (
      !ele.innerHTML.toLowerCase().includes('search') &&
      !ele.innerHTML.toLowerCase().includes('keyboard_arrow_down') &&
      this.isOpened
    ) {
      this.isOpened = false;
      this.cdr.detectChanges();
    }
  };
  ngAfterViewInit(): void {
    this.handler = this.renderer.listen(
      'document',
      'click',
      this.closeDroplist
    );
  }
  ngOnDestroy(): void {
    this.handler ? this.handler() : null;
  }
  ngOnInit(): void {
    this.search.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((v) => {
        this.router.navigate([], {
          relativeTo: this.activeRoute,
          queryParams: { q: v },
          queryParamsHandling: 'merge',
        });
      });

      this.router.navigate([], {
        relativeTo: this.activeRoute,
        queryParams: { filter: '',q:'' },
        queryParamsHandling: 'merge',
      });
      
  }
  updateFilterType(data) {
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: { filter: data.field },
      queryParamsHandling: 'merge',
    });
    this.filterBy = data.header;
    this.toggleDroplist();
  }
  toggleDroplist() {
    this.isOpened = !this.isOpened;
    this.cdr.detectChanges();
  }
  toggleSuggestions() {
    this.isSuggestionsOpen = !this.isSuggestionsOpen;
    this.cdr.detectChanges();
  }
}
