import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TitleService } from 'src/app/core/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowService } from '../../services/workflow.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { Subscription } from 'rxjs';
import { FileService } from 'src/app/files/services/file.service';
import { WorkflowResponseModel ,WorkflowModel, WorkflowTableField, WorkflowLogsTableField} from '../../models/workflow.model';
import { WorkflowJobSelectorComponent } from 'src/app/workflows/components/workflow-job-selector/workflow-job-selector.component';
import {isEmpty,trim} from 'lodash';
import { DatePipe } from '@angular/common';
import { environment } from "src/environments/environment"
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { analytics } from 'firebase';
import { Store } from '@ngrx/store';
import { ClientService } from '../../services/client.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { WorkflowHeaderComponent } from 'src/app/workflows/components/workflow-header/workflow-header.component';
import { SspApplicationService } from '../../services/ssp-application.service';
import { WorkflowJobTableComponent } from 'src/app/workflows/components/workflow-job-table/workflow-job-table.component';
import { timingSafeEqual } from 'crypto';
import { error } from 'console';
import { MenuService } from 'src/app/ui/services/menu.service';


@Component({
  selector: 'app-workflow-job',
  templateUrl: './workflow-job.component.html',
  styleUrls: ['./workflow-job.component.scss']
})
export class WorkflowJobComponent implements OnInit {
  @ViewChild(WorkflowJobSelectorComponent, { read: false, static: false })  
  workflowJobSelectorComponent: WorkflowJobSelectorComponent;
  @ViewChild(WorkflowHeaderComponent, { read: false, static: false })  
  workflowHeaderComponent: WorkflowHeaderComponent;

  // @ViewChild(WorkflowJobTableComponent, { read: false, static: false }) 
  // workflowJobTableComponent:WorkflowJobTableComponent;

  activeRoute$: Subscription;

  
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  fileDataTypeList: any = [];
  workflowResponseModel:WorkflowResponseModel[]=[];
  workflowLogsResponseModel:WorkflowLogsTableField[]=[];
  workflowJobListEdit:WorkflowTableField;
  workflowModelListEdit:WorkflowModel;
  workflowJobForm:  FormGroup = null;

  workflowlogDataInworkflow:any=[];
  worklogDataInworkflowId:any=[];
  id:any;
  logData:any=null;
  dataTransferModel:DataTransferModel;
  applicationList: { key: string | number; value: string }[];
  clientsList1: { key: string | number; value: string }[];
  rolePermission:any;
  activeNotifications:{notificationId:any;notificationName:any}[]=[];
  notificationList:any=[];
  @Input() headerForm:any;
  application:String=null;
  client:string=null;
  allGroupData: {key: any; value: any }[]=[];
  adminFlag:String;
  searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String | number, header:String }[];
  @Input() showWorkflowTable:boolean=true;
  @Input() showWorkflowInterFace:boolean=true;
  state:string;
  menu:string;
  submenu:string;
  allJobParameter:{workflowDetailId:string | number;controls:any[]}[]=[];
  clientId:any;
  @Output() dateEmit=new EventEmitter();
  constructor( private titleService: TitleService,
    private datePipe:DatePipe,
    private activeRoute: ActivatedRoute,
    private workflowService: WorkflowService,
    private toast:ToastService,
    private loader:LoaderService,
    private fileService:FileService,
    public observalbleDataService:ObservalbleDataService,
    private router: Router,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>,
    private clientService:ClientService,private sspapplicationService:SspApplicationService) { 
    this.workflowJobForm = new FormGroup({
      client: new FormControl(null),
      // clientApplicationFileDataType: new FormControl(null),
      isActive: new FormControl(null),
      
       });

       this.searchByCols = [
        { field: "workflowName", header: "Workflow" },
       
        
      ];
      this.state=localStorage.getItem("stateflag");
      this.menu=localStorage.getItem("menu");
      this.submenu=localStorage.getItem("submenu");
  }

  ngOnInit(): void {
    this.adminFlag=this.cookieService.get("adminFlag");

    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );
    this.buttonShowHidePermission();

  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }





//display all data19
getAllWorkflow(dataVal){
  //this.worklogDataInworkflowId=[]; 
  console.log("dataVal   ",dataVal)
  const workflowResponseModel1={
    "clientId":dataVal.client.key,
    //"clientApplicationFileDataType":dataVal.clientApplicationFileDataType.key
    "clientApplicationFileDataType":0
  }
  this.workflowService.getWorkflowByClientNameDataType(workflowResponseModel1).subscribe(
   data=>{
    this.loader.hideLoader();
    this.workflowResponseModel=data;
    
    this.workflowResponseModel.forEach(item=>{
      // console.log("item",item.isActive===1);
      item.clientId=dataVal.client.key;
      item.isActiveDisplay=item.isActive===1?'Active':'Inactive'
      item.workflowTypeDisplay=item.workflowType===1?'System':'Custom'
      item.asOfDate=item.asOfDate!==null?this.datePipe.transform(item.asOfDate,'MM-dd-yyyy'):""
    
    })
   },
   error => {
    this.toast.showError(error.error.message, "error");
  }
  )
}

//datatype dropdown value
getApplicationFileType(){
  this.fileService.getApplicationDataTypeByUser().subscribe(
 (data) => {
   const fileDataType=data;
   for(var a=0;a<fileDataType.length;a++){

     this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
   }
 },
 (err) => {
 // this.toast.showSuccess("Status Not Changed", "error");
  this.toast.showError(err.error.message, "error");
 }
);
}


//edit code
getWorkflowOnEdit(data){
  this.getWorkflowById(data);
}
getWorkflowById(data){
 // this.loader.showLoader();
  const workflowModelConst={ 
    "workflowId":data.workflowId,
    "isActive":data.isActive,
    "workflowType":data.workflowType
  }
  this.workflowService.getWorkflowById(workflowModelConst)
  .subscribe(
    data=>{
  //    this.loader.hideLoader();
       this.workflowJobListEdit=data;
       this.workflowHeaderComponent.panelForm.toggleSlider();
    },
    err=>{
      this.workflowJobListEdit=data;
    }
  )
}


saveData(data){
//this.loader.showLoader();

  if(this.headerForm.client.value===null || this.headerForm.client.value===undefined || trim(this.headerForm.client.value).length<1){
    this.toast.showWarn('error','Client Name mandatory ')
    return;
     
  }

//   if(this.headerForm.clientApplicationFileDataType.value===null || this.headerForm.clientApplicationFileDataType.value===undefined || trim(this.headerForm.clientApplicationFileDataType.value).length<1)
//   { this.toast.showError('error','Application Name mandatory ')
//   //this.loader.hideLoader();
//   return;
   
// }
  if(data.workflowName===null ||data.workflowName===undefined ||trim(data.workflowName).length<1){
    this.toast.showWarn('error','Workflow Name mandatory ')
   // this.loader.hideLoader();
    return;
  }
  if(data.isActive===null ||data.isActive===undefined ||trim(data.isActive).length<1){
    this.toast.showWarn('error','Status mandatory ')
    //this.loader.hideLoader();
    return;
  }
  if(data.workflowType===null ||data.workflowType===undefined ||trim(data.workflowType).length<1){
    this.toast.showWarn('error','Workflow Type mandatory ')
   // this.loader.hideLoader();
    return;
  }
  if(data.group.key===null || data.group.key===undefined ||trim(data.group.key).length<1){
    this.toast.showWarn('error',"Group is mandatory")
  return;
  }
  if(data.reports===null || data.reports===undefined ||trim(data.reports).length<1){
  //  data.keyValueModel=data.reports;
    this.toast.showWarn('error',"Reports is mandatory")
  return;
  }
 
  console.log("checkingSave ############"+data);
  console.log("checkingSave ############"+this.workflowJobListEdit);
  const workflowData={
    "workflowId":this.workflowJobListEdit!==null&&this.workflowJobListEdit!==undefined?this.workflowJobListEdit.workflowId:0,
    "workflowName":data.workflowName,
    "clientId":this.headerForm.client.key,
    // "datatypeId":this.headerForm.clientApplicationFileDataType.key,
    "datatypeId":0,
    "isActive":data.isActive,
    "workflowType":data.workflowType,
    "asofdate":data.asofdate,
    "notificationMappingList":data.notificationType,
    "groupData":data.group.key,
   // "reportData":data.reports,
    "keyValueModel":data.reports
    
  }
                           
  this.workflowService.saveWorkflowById(workflowData)
  .subscribe(
    res=>{
      this.loader.hideLoader();
      this.toast.showSuccess('success','data saved successfully');
      //this.resetForm();
      this.workflowJobListEdit=null;
      
      if(!this.showWorkflowInterFace){
      this.cookieService.set("workflowIdm",(res.workflowId).toString());
      this.cookieService.set("workflowNamem",res.workflowName);
      this.cookieService.set("clientIdm",this.headerForm.client.key);
      this.cookieService.set("clientNamem",this.headerForm.client.value);

      
      

      console.log("workflowId ",this.cookieService.get("workflowId"));
      console.log("clientId-- ",this.cookieService.get("clientId1"));
      
      const sessionModel={
        workflowIdm:this.cookieService.get("workflowIdm"),
        workflowNamem:this.cookieService.get("workflowNamem"),
        clientIdm:this.cookieService.get("clientIdm"),
        clientNamem: this.cookieService.get("clientNamem"),
        clientFileMasterId:this.cookieService.get("clientFileMasterId"),
        clientFileMasterName:this.cookieService.get("clientFileMasterName")
      }
      
      let page = '/workflow/setup';
           this.router.navigate([page],{
            queryParams: {...sessionModel},
          } );
        }
      if(this.showWorkflowInterFace){
      console.log("after save  ",this.headerForm)
      this.getAllWorkflow(this.headerForm);
      this.getApplicationFileType();
      this.getAllApplication();
        }
    },
    error=>{
      this.toast.showError(error.error.message, "error");
    //  this.loader.hideLoader();
    }
  )
}

// viewworkflowTableLog(){}



viewworkflowTableLog(data){
// viewGroupVariable(data){
  this.workflowlogDataInworkflow=[];
  this.logData=data;
  //this.createGroupHeader=" Add Variable to group : "+data.groupName;
  this.getWorkflowlogInworkflow(data);
  
}

getWorkflowlogInworkflow(data){
  // this.groupDisplayName=data.groupName;
 // this.workflowlogDataInworkflow=[];
 
  
  this.workflowService.getWorkflowLogsByWorkflowId(this.logData)
  .subscribe(
    data=>{
    // this.viewGrpVar=true;
    //  // this.hideDelete=false;

     if(data.length===0){
        this.toast.showInfo('no Log present','info');
     }
       this.worklogDataInworkflowId=data;
    //     // this.worklogDataInworkflowId.forEach(item=>{
    //       console.log("item",item.dateOfEventRun);
          
    //     item.dateOfEventRun==this.datePipe.transform(item.dateOfEventRun,"MM-dd-yyyy");
    //     item.lastrunDate==this.datePipe.transform(item.lastrunDate,"MM-dd-yyyy");
        
    // })
           console.log("in main getWorkflowlogInworkflow",this.worklogDataInworkflowId);
     
    },
    error=>{
      this.toast.showError(error.error.message, "error");
    }
  )
}


executeWorkflow(data){
  console.log('worflow data :',data);
  let dateVal=null;
  let startDate=null;
  let endDate=null;
  if(data.menu===null){
    data.menu=this.menu;
  }
  if(data.submenu===null){
    data.submenu=this.submenu;
  }
  if(data.asofDate!==null || data.data.asofDate!==undefined){
    console.log(data.asofDate)
    dateVal=this.datePipe.transform(data.asofDate,'yyyy-MM-dd');
  }
  if(data.startDate!==null || data.data.startDate!==undefined){
    console.log(data.startDate)
    startDate=this.datePipe.transform(data.startDate,'yyyy-MM-dd');
  }
  if(data.endDate!==null || data.data.endDate!==undefined){
    console.log(data.endDate)
    endDate=this.datePipe.transform(data.endDate,'yyyy-MM-dd');
  }
  // console.log("Transform param ",data.data.transformParams);
  // console.log("Transform param ",data.transformParams)
  //  const updateDataModel={
  //    "workflowId":data.data.workflowId,
  //    "paramForm":data.paramForm
  //  }
  const requestModel={
"requestUrl":environment.baseUrl,
  //"requestUrl":"http://localhost:8080",
  "workflowId":data.data.workflowId,
  "fromDate":dateVal,
  "startDate":startDate,
  "endDate":endDate,
  "genericTransformParams":data.transformParams,
  "overrideParams":data.overrideParams,
  "clientId":this.clientId,
  "asofdate":dateVal,
  "lable":data.menu

  }
  if(data.submenu.length>1){
    requestModel.lable=data.submenu;
    if(requestModel.lable.includes("Manage")){
      requestModel.lable="Workflow";
    }
  }
  this.loader.showLoader();
  console.log('request...Model :',requestModel);
  
  // this.workflowService.updateDetailParamId(data.paramForm).subscribe(
  //   data=>{
  //     this.loader.hideLoader();
  //     this.toast.showSuccess('Success','Check Logs for status')
  //   },
  //   error=>{
  //     this.toast.showError(error.error.message, "error");
  //   }
  // )
  this.workflowService.executeWorkflow(requestModel)
  .subscribe(
    data=>{
      //this.workflowJobTableComponent.dateform.reset();
      this.loader.hideLoader();
      this.toast.showInfo('Success','Check logs for status');
      
      this.dateEmit.emit(dateVal);
    },
    error=>{
      this.loader.hideLoader();
     // this.toast.showInfo('Success','Added to queue,Check logs for status');
     this.toast.showError(error.error.message, "error");
    }
  )
}

resetForm(){
  console.log("this.workflowJobSelectorComponent.selectorForm",);
  this.workflowJobSelectorComponent.selectorForm.reset();
  this.workflowJobListEdit=null;
}


resetWorkflowData(data){
  this.workflowJobListEdit=null;
}

onClientChange(event){
  this.cookieService.set('clientId',this.workflowJobForm.controls.client.value.key);
  this.cookieService.set('clientName',this.workflowJobForm.controls.client.value.value);
  //this.maintainClientState(event);

  this.worklogDataInworkflowId=[];
	
  this.workflowResponseModel=[];
  this.resetOnChange(); 
  this.getAllWorkflow(this.headerForm);
}

onDataTypeChange(data){
 this.headerForm=data;
 console.log("data values  ",this.headerForm)
 this.client=data.client.value;
 this.clientId=data.client.key;
// this.application=data.clientApplicationFileDataType.value;
  this.worklogDataInworkflowId=[];
	
  this.workflowResponseModel=[];
  this.cookieService.set('dataTypeName','Generic');
 // this.cookieService.set('dataTypeId',data.clientApplicationFileDataType.key);
  this.cookieService.set('dataTypeId','0');
  this.resetOnChange();
  this.getAllWorkflow(this.headerForm);
}


getreportMappedWorkflows(data){


this.adminFlag='false';
this.worklogDataInworkflowId=[];
this.fileService.getWorkflowListByReportId(data).subscribe(
  data =>{
   this.loader.hideLoader();
console.log("reportmappedworkflows ",data)

this.workflowResponseModel=data

this.workflowResponseModel.forEach(item=>{
// console.log("item",item.isActive===1);

 item.isActiveDisplay=item.isActive===1?'Active':'Inactive'
 item.workflowTypeDisplay=item.workflowType===1?'System':'Custom'
 item.asOfDate=item.asOfDate!==null?this.datePipe.transform(item.asOfDate,'MM-dd-yyyy'):""

                                       })

                                       
    },
  error => {
        this.toast.showError(error.error.message, "error");
            }

  )

}


setupJob(data){
this.goToPage(data);
}

goToPage(data){

  console.log("data clientid  ",data.data.clientId)
  console.log("this.headerForm",this.headerForm)
 if(data.data.clientName!=null){

  this.dataTransferModel={
    clientFileId:0,
    clientId: data.data.clientId,
         isRedirected:1,
         applicationId: 0,
         toDate: '',
         fromDate: '',
         clientName:data.data.clientName ,
         page:"workflow/setup",
         fileName: '',
        workflowName: data.data.workflowName,
        workflowId:data.data.workflowId,
        // dataType: this.headerForm.clientApplicationFileDataType.value,
        // dataTypeId: this.headerForm.clientApplicationFileDataType.key
        dataType: 'Generic',
        dataTypeId: 0
  }
  console.log("data dataTransferModel2  ",this.dataTransferModel)
 }
 if(data.data.clientName==null){
  this.dataTransferModel={
    clientFileId:0,
    clientId: this.headerForm.client.key,
         isRedirected:1,
         applicationId: 0,
         toDate: '',
         fromDate: '',
         clientName: this.headerForm.client.value,
         page:"workflow/setup",
         fileName: '',
        workflowName: data.data.workflowName,
        workflowId:data.data.workflowId,
        // dataType: this.headerForm.clientApplicationFileDataType.value,
        // dataTypeId: this.headerForm.clientApplicationFileDataType.key
        dataType: 'Generic',
        dataTypeId: 0
  }
  console.log("data dataTransferModel3  ",this.dataTransferModel)
}
  
  this.observalbleDataService.addToInventory(this.dataTransferModel);
  const nevigateFlag={
    nevigateBoolean:true
  };
  if(data.pageUrl.includes('setup')){
    this.router.navigate(['workflow/setup'],{
      queryParams: { ...nevigateFlag },
    });
  }
  // if(data.pageUrl.includes('workflow')){
  //   this.router.navigate(['/workflow-job']);
  // }
  if(data.pageUrl.includes('logs')){
    this.router.navigate(['workflow/logs'],{
      queryParams: { ...nevigateFlag },
    });
  }
  


}

resumeWorkflow(data){
  console.log(data)
  const requestModel={
     "requestUrl":environment.baseUrl,     
      "workflowId":data.workflowId,
      "workflowLogId":data.workflowLogId
      }

      this.workflowService.resumeWorkflow(requestModel)
      .subscribe(
        data=>{
          this.toast.showSuccess('Success','Check Logs for status')
        },
        error=>{
          this.toast.showError('error','error resuming workflow execution');
        }
      )
}

resetOnChange(){
  try{
    this.workflowJobSelectorComponent.selectorForm.reset();
  }catch{
    console.log('exception');
  }
  
  this.workflowJobListEdit=null;
}

ngOnDestroy() {
 // this.activeRoute$.unsubscribe();
  if (this.id) {
    clearInterval(this.id);
  }
}

getPreviousState() {
  const clientIdC=this.cookieService.get('clientId');
  const clientNameC=this.cookieService.get('clientName');
  const dataTypeIdC=this.cookieService.get('dataTypeId');
  const dataTypeNameC=this.cookieService.get('dataTypeName');
  console.log(clientIdC);
  console.log(clientNameC);
  
  if(clientIdC!==undefined && clientIdC!==null){
    this.workflowJobForm.get("client").patchValue({
             key: clientIdC,
             value: clientNameC,
             });
             //this.getAllWorkflow();                 
  }

  if(dataTypeIdC!==undefined && dataTypeNameC!==null){
    this.workflowJobForm.get("clientApplicationFileDataType").patchValue({
             key: dataTypeIdC,
             value: dataTypeNameC,
             });
             this.getAllWorkflow(this.workflowJobForm.value);                 
  }


  
    this.worklogDataInworkflowId=[];
    this.workflowResponseModel=[];
    this.resetOnChange(); 
    //this.getAllWorkflow();
}

maintainClientState(client){
  this.dataTransferModel = {
    clientFileId: 0,
    clientId: this.workflowJobForm.controls.client.value.key,
    isRedirected: 1,
    applicationId: 0,
    toDate: '',
    fromDate: '',
    clientName: this.workflowJobForm.controls.client.value.value,
    page: '',
    fileName: '',
    workflowName: '',
    workflowId: 0,
    dataType: null,
    dataTypeId: 0
  }
  this.observalbleDataService.addToInventory(this.dataTransferModel); 
}

getAllApplication(){
  this.fileService
  .getAllApplicationByUser()
  .subscribe(
    (data) =>
      (this.applicationList = reMapForAutoComplete(
        data,
        "applicationId",
        "name"
      )),
      (err) => {
        this.toast.showError(err.error.message, "error");
      
      }
  );
  }


  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getApplicationFileType();
      this.getAllApplication();
      this.setTitle();
     // this.getPreviousState();
      this.getAllNotification();
      this.getClients();
      this.getAllGroupData();
      
      // this.id=setInterval(() => {
      //   //this.getAllWorkflow(this.workflowJobForm.value); 
      //   this.getAllWorkflow(this.headerForm); 
      //   if(this.logData!==null){
      //   this.getWorkflowlogInworkflow(this.logData)
      //   }
      // }, 30000);//30 sec
    }


   getAllNotification(){


    this.fileService.getActiveNotificationListByUser().subscribe(
      (data) => {
       this.notificationList=data;
       // this.loader.hideLoader();
        this.notificationList = this.reMapForNotificationTypeMultiSelect( this.notificationList, "notificationId", "notificationName");
        
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      }  
  
    )
   }

    reMapForNotificationTypeMultiSelect(

      data: any[],
      keyCol: string,
      valCol: string
    ): {notificationId: string | number; notificationName: string }[] {
      return data.map(d => ({ notificationId: d[keyCol], notificationName: d[valCol] }));
  
  
    }
  
    getClients() {
      this.loader.showLoader();
      this.clientService.getAllClients().subscribe(
        (data) => {
          this.loader.hideLoader();
          this.clientsList1 = reMapForAutoComplete(data, "clientId", "clientName");
        },
        (err) => {
          this.loader.hideLoader();
          this.toast.showError(err.error.message, "error");
        }
      );
    }

    callManageToggle(){
      console.log("call toggle----------")
      this.workflowJobListEdit=null;
      this.workflowHeaderComponent.manageToggle();
    }

    
     getAllGroupData() {

    this.sspapplicationService.getAllGroupDataForGrouppage().subscribe((data) => {
      // this.allGroupData=data;
      this.allGroupData = reMapForAutoComplete(data, 'groupId', 'groupName');
    },
      (err) => {
        this.toast.showError(err.error.message, 'error');
      }
    );
  }

  // getWorkflowJobsParameterByWorkflowId(data){
    

  //   this.workflowService.getWorkflowJobsParameterByWorkflowId(data.workflowId).subscribe(
  //     (data) => {
  //       this.allJobParameter=data;
  //       console.log(" this.allJobParameter.controls", this.allJobParameter)
  //       //this.allJobParameter.forEach(item=>{item.options=JSON.parse(item.options)})
  //       this.allJobParameter.forEach(item=>{item.controls.forEach(item=>{item.options=JSON.parse(item.options)})})
  //     },
  //     (err) => {
  //     // this.toast.showSuccess("Status Not Changed", "error");
  //      this.toast.showError(err.error.message, "error");
  //     }
  //    );
  // }
}
