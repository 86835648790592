import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientPortalService {
/**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http:HttpClient,
    private datePipe:DatePipe) { }
  getAllclientPortalData(clientData){
    return this.http.post<any[]>(environment.baseUrl + "/apis/client-portal/getAllclientPortalData",clientData);
  }

    saveClientFile(file,clientFile,state){
      //console.log(file);
      console.log(file);
      const formData = new FormData();
      for(let i=0;file.length>i;i++){
      formData.append('file',file[i]);
      }
      formData.append('isTransposeOnUploadFile',clientFile.controls.isTransposeOnUploadFile.value);
     // formData.append('fileData',file[1]);
     if(clientFile.controls.client.value>0){
      formData.append('clientId',clientFile.controls.client.value);
    }else{
      formData.append('clientId',clientFile.controls.client.value.key);
    }
      formData.append('asOfDate',this.datePipe.transform(clientFile.controls.asOfDate.value,'yyyy-MM-dd'));
      formData.append('applicationId',state);

      return this.http.post(
        `${environment.baseUrl}/apis/client-portal/save`,formData
      );

    }

    saveZipClientFile(file,clientFile,state){
      //console.log(file);
      //console.log(file);
       const formData = new FormData();
      for(let i=0;file.length>i;i++){
      formData.append('file',file[i]);
      }
     // formData.append('fileData',file[1]);
    // formData.append('file',file)
     if(clientFile.controls.client.value>0){
      formData.append('clientId',clientFile.controls.client.value);
    }else{
      formData.append('clientId',clientFile.controls.client.value.key);
    }
      formData.append('asOfDate',this.datePipe.transform(clientFile.controls.asOfDate.value,'yyyy-MM-dd'));
      formData.append('applicationId',state);
      return this.http.post(
        `${environment.baseUrl}/apis/client-portal/saveZip`,formData
      );

    }


    updateClientFile(data){
      return this.http.post(
        `${environment.baseUrl}/apis/client-portal/updateClientFile`,data
      );
    }

    updateClientFilMaster(data){
      return this.http.post(
        `${environment.baseUrl}/apis/client-portal/updateClientFileMaster`,data
      );
    }

    deleteFileLayout(data){
     
      return this.http.post(
        `${environment.baseUrl}/apis/client-portal/deleteLayoutAndMaster`,data
      );
    }



    saveVideoFile(file,modelForm){
      //console.log(file);
      console.log(file);
      const formData = new FormData();
      if(file!=null){
      for(let i=0;file.length>i;i++){
      formData.append('file',file[i]);
      }
    }
    else{
      formData.append('file',null);
    }
      

      formData.append('title',modelForm.title)
      //formData.append('videoId',modelForm.videoId)
    
      formData.append('status',modelForm.statusMap.value);
      formData.append('categoryId',modelForm.category.key);
      formData.append('applicationId',modelForm.application.key);
      formData.append('description',modelForm.description);
      console.log("modelForm title  ",modelForm.title);
      console.log("statusMap value  ",modelForm.statusMap.value);
      return this.http.post(
        `${environment.baseUrl}/apis/video/save`,formData
      );

    }


    getAllVideosList(){
      return this.http.get<any[]>(environment.baseUrl + "/apis/video/getAll");
    }

    getAllACtiveVideosList(flag){
      return this.http.get<any[]>(environment.baseUrl + "/apis/video/getAllActiveList?applicationId="+flag);
    }


    setStatusData(data){
      return this.http.post<any[]>(environment.baseUrl + "/apis/video/setStatus",data);
  } 



  deleteUploadedData(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/video/deleteFile",data);
}



getAllCategoryList(){
  return this.http.get<any[]>(environment.baseUrl + "/apis/video/getAllVideoCategory");
}


getAllActiveCategoryList(flag){
  return this.http.get<any[]>(environment.baseUrl + "/apis/video/getAllActiveVideoCategory?applicationId="+flag);
}



getVideoCategoryByName(modelForm,flag){
  //console.log(file);
  
  const formData = new FormData();
  
  formData.append('categoryId',modelForm.category.key);
  console.log("modelForm.category.key  ",modelForm.category.key);

  return this.http.post<any[]>(
    `${environment.baseUrl}/apis/video/getVideoCategoryByName?applicationId=`+flag,formData
  );

}


getVideoFilesById(id){
  //console.log(file);
  
  const formData = new FormData();
  
  formData.append('id',id);

  return this.http.post<any[]>(
    `${environment.baseUrl}/apis/video/getVideoFilesById`,formData
  );

}


saveOnEdit(file,modelForm,editVideoData){
  //console.log(file);
  console.log(file);
  const formData = new FormData();

 if(file!=null){
      for(let i=0;file.length>i;i++){
      formData.append('file',file[i]);
      }
    }
    else{
      formData.append('file',null);
    }
  

  formData.append('title',modelForm.title)
  formData.append('videoId',modelForm.videoId)
  formData.append('vid',editVideoData.id)
  formData.append('status',modelForm.statusMap.value);
  formData.append('categoryId',modelForm.category.key);
  formData.append('applicationId',modelForm.application.key);
  formData.append('description',modelForm.description);
  formData.append('url',editVideoData.url);
  formData.append('videoName',editVideoData.name);
  console.log("modelForm title  ",modelForm.title);
  console.log("statusMap value  ",modelForm.statusMap.value);
  return this.http.post(
    `${environment.baseUrl}/apis/video/saveOnEdit`,formData
  );

}


getVideoFilesListById(id){
  //console.log(file);
  
  const formData = new FormData();
  
  formData.append('id',id);

  return this.http.post<any[]>(
    `${environment.baseUrl}/apis/video/getVideoFilesListById`,formData
  );

}



}
