import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { CategoryModel } from "../models/category.model";
//import { DepartmentModel } from '../models/Department.model';

@Injectable({
    providedIn: 'root'
  })
export class VideoCategoryService {
  
  
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  saveOrUpdate(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/videoCategory/createVideoCategory",data);
} 


getAllVideoCategoryList(){
  return this.http.get<any[]>(environment.baseUrl + "/apis/getVideoCategoryAll");
}

deleteVideoCategoryId(data) {
    return this.http.delete<CategoryModel[]>(environment.baseUrl + "/apis/deleteVideoCategoryId/"+data);
  }




  }

