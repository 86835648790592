import { DatePipe } from '@angular/common';
import { Component, OnInit ,ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { VideoUploadSelectorComponent } from 'src/app/videoUploadComp/components/video-upload-selector/video-upload-selector.component';
import { ClientPortalService } from '../../services/client-portal.service';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-video-upload-portal',
  templateUrl: './video-upload-portal.component.html',
  styleUrls: ['./video-upload-portal.component.scss']
})
export class VideoUploadPortalComponent implements OnInit {


  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;


  @ViewChild(VideoUploadSelectorComponent, { read: false, static: false })
  videoUploadSelectorComponent: VideoUploadSelectorComponent;


  activeRoute$: Subscription;

  currentFileUpload:FileList;
  currentFileUploadList:FileList;
  fileList:any=[];
  isZipUpload:boolean=false;

  allStatusData: {key: any; value: any }[]=[];
  fileDataList:any[];
  
  categoryVideoList:any[];
  editVideoData:any[]=null;
  state:string;
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  appListData: any [];
  menu:string;
  submenu:string;
  constructor(private titleService: TitleService,
    private activeRoute: ActivatedRoute,private router: Router,private clientService:ClientService
    ,private toast:ToastService,private clientPortalService:ClientPortalService,private sspapplicationService:SspApplicationService,
    private datePipe: DatePipe,private loader:LoaderService,private fileService: FileService) {

      this. searchByCols = [
        { field: "name", header: "Name" },
       
      ];
      this.menu=localStorage.getItem("menu");
      this.submenu=localStorage.getItem("submenu");
      this.state=localStorage.getItem("stateflag");
     }

  ngOnInit(): void {
    this.setTitle();
    this.getStatusData();
    this.getAllVideos();
    this.getAllVideoCategory();
    this.getAllAppsData();

   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }



  uploadFile(){
    console.log("working1")
    this.currentFileUpload=null;
    this.videoUploadSelectorComponent.resetForm();
    this.editVideoData=null;
    this.panelForm.toggleSlider();
    console.log("working")

  }


  uploadFileInBucket(data){


    this.currentFileUpload=data.file;
  
if(this.editVideoData==null){
    if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
      this.toast.showWarn('no file selected','error');
      return;
    }
  }
    if(data.form.category.key===null ||data.form.category.key===undefined){
      this.toast.showWarn('no Category selected','error');
      return;
    }
    if(data.form.application.key===null ||data.form.application.key===undefined){
      this.toast.showWarn('no Application selected','error');
      return;
    }

    if(data.form.description===null ||data.form.description===undefined){
      this.toast.showWarn('Please Enter Description','error');
      return;
    }
   
     //this.showLoading=true;

  if(this.isZipUpload===false){
    if(data.form.videoId==null){
     this.clientPortalService.saveVideoFile(this.currentFileUpload,data.form)
     .subscribe(
       data=>{
        //this.loader.showLoader();
         this.toast.showSuccess('Success','File Uploaded Successfully');
         //this.clientId=null;
         this.currentFileUpload=null;
         this.videoUploadSelectorComponent.resetForm();
         this.editVideoData=null;
         this.getAllVideos();
         //this.visible=false;
        
        //  this.showLoading=false;
        //  this.showClientFilePopUp=false;
        //  this.fetchListing.emit(this.headerForm.value);
        
       },
       error=>{
        this.editVideoData=null;
         this.toast.showError(error.error.message, "error");
      //   this.loader.hideLoader();
        //  this.visible=false;
        //  this.showLoading=false;
       },
      
     );
      }
    if(data.form.videoId!=null){

      this.clientPortalService.saveOnEdit(this.currentFileUpload,data.form,this.editVideoData)
     .subscribe(
       data=>{
        //this.loader.showLoader();
         this.toast.showSuccess('Success','File Uploaded Successfully');
         //this.clientId=null;
         this.currentFileUpload=null;
         this.videoUploadSelectorComponent.resetForm();
         this.editVideoData=null;
         this.getAllVideos();
         //this.visible=false;
        
        //  this.showLoading=false;
        //  this.showClientFilePopUp=false;
        //  this.fetchListing.emit(this.headerForm.value);
        
       },
       error=>{
         this.toast.showError(error.error.message, "error");
      //   this.loader.hideLoader();
        //  this.visible=false;
        //  this.showLoading=false;
       },
      
     );

       }
      }


  }


  editFile(data){
this.editVideoData=data;
console.log("editVideoData",this.editVideoData);
this.panelForm.toggleSlider();

  }

  getStatusData(){
    let type="status";
    const dataVal={
      "type" :type
     }
       this.sspapplicationService.getAllGenericDropDownData(dataVal)
       .subscribe(
      (data)=> {
         this.allStatusData = reMapForAutoComplete(data,"itemValue", "itemText");
         //console.log("generic data"+this.allGenericData);
         const defaultMap = {
          key: "Active",
          value: "Active",
        };
       this.videoUploadSelectorComponent.modelForm.get('statusMap').setValue(defaultMap);
      },
     (err) => {
       this.toast.showError(err.error.message, "error");
     }
     );
   }


   getAllVideos(){
    this.clientPortalService.getAllVideosList().subscribe(

      data=>{
        this.fileDataList=data;
        this.fileDataList.forEach(item=>{
          item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
          item.modifiedAt=this.datePipe.transform(item.modifiedAt,"MM/dd/yyyy")
         })
      },
       err=>{
        this.toast.showError(err.error.message, "error");
       }
    )

   }

   setStatusForData(data){

    const model={
      videoId:data.videoId,
      status:data.status
    }
     
    this.clientPortalService.setStatusData(model).subscribe(

      res=>{

        if(data.status){
          this.toast.showSuccess('Successfully','Deactivate');
          }
          else{
            this.toast.showSuccess('Successfully','Activate');
          }

      this.getAllVideos();
        
      },
       err=>{
        this.toast.showError(err.error.message, "error");
       }
    )


   }



   deleteUploadFile(data){

    const model1={
      name:data.name,
      videoId:data.videoId
    }
     
    this.clientPortalService.deleteUploadedData(model1).subscribe(

      res=>{

       
         this.toast.showSuccess('Successfully','Deleted');
          this.getAllVideos();
        
      },
       err=>{
        this.toast.showError(err.error.message, "error");
       }
    )


   }


   getAllVideoCategory(){

    this.clientPortalService.getAllCategoryList().subscribe(

      res=>{

        this.categoryVideoList=reMapForAutoComplete(res,"videoCategoryId","categoryName");
        this.appListData=reMapForAutoComplete(res,"videoApplicationId","applicationName")
        //this.toast.showSuccess('Successfully','Deleted');
          //this.getAllVideos();
        
      },
       err=>{
        this.toast.showError(err.error.message, "error");
       }
    )



   }


   getAllAppsData(){
    this.fileService.getAllApplicationsByUser().subscribe(
      (data) => {
        console.log("Applications",data);
        let tempAppList: any[]=[];
        for(var i=0;i<data.length;i++){
          if(data[i].flag == true){
            tempAppList.push(data[i]);
          }
        }
        this.appListData = reMapForAutoComplete(tempAppList, "applicationId", "name");
        console.log("appListData: ",this.appListData);
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  


}
