import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { FormsModel } from 'src/app/models/forms.model';
import {trim} from 'lodash';
import { ToastService } from 'src/app/core/services/toast.service';
//import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
//@UntilDestroy()
@Component({
  selector: 'app-client-application-form',
  templateUrl: './client-application-form.component.html',
  styleUrls: ['./client-application-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientApplicationFormComponent implements OnInit,OnChanges {
  @Input() fileTypeList:any;
  @Input() fileDataTypeList:any;
  @Input() layoutFieldList:any;
  @Input() fieldSeparatorList:any;
  @Input() clientApplicationFileForm:FormGroup;
  @Output() closePanel=new EventEmitter(); 
  @Output() clientApplicationFileData=new EventEmitter();
  currentFileUpload:FileList;
  constructor(private router: Router, private activeRoute: ActivatedRoute,private toast:ToastService) {
   
    this.clientApplicationFileForm = new FormGroup({
      fileType: new FormControl(null),
      fileDataType: new FormControl(null),
      layoutFieldId: new FormControl(null),
      dependentUpon: new FormControl(null),
      fieldSeparator: new FormControl(null),
      schedule: new FormControl(null),
      isTransposeOnLayout: new FormControl(false)
    })
  }
  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log("enter inside on changes",this.formData,changes);
    // if (changes && changes.formData.currentValue && changes.formData.firstChange) {
    //   const d: any = changes.formData.currentValue;
    //   console.log("enter inside on changes");
    //   this.clientFileForm.patchValue({
    //     client:  { key: d.client.key, value: d.client.value },
    //      fromDate: d.fromDate,
    //      toData:  d.toDate,
    //   });
    // //  console.log("on changes form client-portal-header",this.headerForm.value)
    // }
  }
  selectClientApplicationFile(event){
    this.currentFileUpload = event.target.files;
    console.log("My test log1",this.currentFileUpload[0].name);
    console.log("My test log2",this.currentFileUpload[0].name);
    console.log("My test log3",this.currentFileUpload[0].name);

    if(this.currentFileUpload[0].name.includes('.txt'))
    { 
      document.getElementById("fieldSeparatorValue").style.display=""
    }
    if(!this.currentFileUpload[0].name.includes('.txt')){
      document.getElementById("fieldSeparatorValue").style.display="none"
    }

  }

  saveClientApplicationFile(){
    const data={
      "file":this.currentFileUpload,
      "form":this.clientApplicationFileForm.value
}
console.log("saveClientApplicationFile data",data)
    // this.clientApplicationFileData.emit(data);
    // this.closePanel.emit();
    
    
    if(this.currentFileUpload[0].name.includes(".txt"))
   { 
      var fs;
      //
       console.log("Fields seprated vaoue:",this.clientApplicationFileForm.controls.fieldSeparator.value.key);
      
       if(this.clientApplicationFileForm.controls.fieldSeparator.value.key === undefined || this.clientApplicationFileForm.controls.fieldSeparator.value.key === "" || this.clientApplicationFileForm.controls.fieldSeparator.value.key === null) {
         this.toast.showInfo("All Fields Are Mandatory","please Select Field Separator");
       }    
      else{
        this.clientApplicationFileData.emit(data);
      }
      this.closePanel.emit();
    }
    if(!this.currentFileUpload[0].name.includes(".txt"))
    {
      this.clientApplicationFileData.emit(data);
      this.closePanel.emit();
    }
  }
  }
