import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from "src/app/core/services/title.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { ToastService } from "src/app/core/services/toast.service";
import { FileService } from "src/app/files/services/file.service";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import {
  FileLayoutModel,
  FieldLayoutModel,
} from "../../models/file-layout.model";
import { isEmpty } from "lodash";
import { FieldsLayoutFormComponent } from "src/app/files/components/fields-layout-form/fields-layout-form.component";
import { FieldsLayoutTableComponent } from "src/app/files/components/fields-layout-table/fields-layout-table.component";
import { ObservalbleDataService } from "../../services/observalble-data.service";
import { DataTransferModel } from "../../models/dataTransfer.model";
import { currentMonthRange } from "src/app/utils/dates";
import { CookieService } from "ngx-cookie-service";
import { Store } from '@ngrx/store';
import { SidePanelComponent } from "src/app/ui/components/side-panel/side-panel.component";
import { FileLayoutHeaderComponent } from "src/app/files/components/file-layout-header/file-layout-header.component";

@Component({
  selector: "app-file-layout",
  templateUrl: "./file-layout.component.html",
  styleUrls: ["./file-layout.component.scss"],
})
export class FileLayoutComponent implements OnInit, OnDestroy {
  @ViewChild(FieldsLayoutFormComponent)
  fieldLayoutComp: FieldsLayoutFormComponent;
  @ViewChild(FieldsLayoutTableComponent)
  fieldLayoutTableComp: FieldsLayoutTableComponent;

  @ViewChild(FileLayoutHeaderComponent)
  fileLayoutHeaderComponent:FileLayoutHeaderComponent;
  
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  headerForm: FormGroup = null;
  activeRoute$: Subscription;
  clientId: number = null;
  sheets: { key: string | number; value: string }[] = [];
  fileLayout: FileLayoutModel=null;
  layoutFields: FieldLayoutModel[];
  headerRows = [];
  editFieldValue: FieldLayoutModel = null;
  showEditButton:boolean=false
  endRow:string="End row"
  startRow:string="Start row"
  dataTransferModel:DataTransferModel;
  fromDt: any;
  toDt: any;
  showBackButton:boolean=false;
  rolePermission:any;
  header:string=null;
  fileName:string=null;
  startRowCount:string=null;
  endRowCount:string=null;
  client:string=null;
  headerFormVal:any;
  clientFileLayoutId:number=0;
  companyName:String;
  showFileName:String;
  adminFlag:String;
  searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String | number, header:String }[];
  state:string;
  constructor(
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private loader: LoaderService,
    private fileService: FileService,
    private toast: ToastService,
    private router: Router,
    public observalbleDataService:ObservalbleDataService,
    private cookieService: CookieService,
    private storePermission: Store<{ role: any }>
  ) {
    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      file: new FormControl(null, [Validators.required]),
      header: new FormControl(null, [Validators.required]),
      headerRow: new FormControl(null, [Validators.required]),
      startRowNo:new FormControl(null, [Validators.required]),
      sheet: new FormControl(null, [Validators.required]),
    });

    this. searchByCols = [
      { field: "fieldName", header: "field name" },
      
      
    ];
    this.state=localStorage.getItem("stateflag");
    this.createHeaderRows(11);
  }
  ngOnInit(): void {

    this.adminFlag=this.cookieService.get("adminFlag");

    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );

    this.buttonShowHidePermission();
  
}
  
  // ngAfterContentInit(){
  //   this.getPreviousState();
  // }

  ngOnDestroy() {
    try{
      this.activeRoute$.unsubscribe();
    }catch(ex){
      
    }
   
  }
  get showButton() {
    return this.clientId !== null && this.sheets.length > 0;
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  createHeaderRows(count) {
    for (let i = 0; i < count; i++) {
      this.headerRows.push({ key: i, value: i });
    }
  }
  getFiles(event) {
    this.editFieldValue = null;
    this.sheets = this.layoutFields = [];
    this.fileLayout = null;
    this.fieldLayoutComp.fieldLayoutForm.reset();
    this.clientId = this.headerForm.value.client.key;
    this.headerForm.get("file").reset();
    this.cookieService.set('clientId',event.key);
      this.cookieService.set('clientName',event.value);
    //this.maintainClientState(event);
  }
  getSheets(client, id) {
    this.sheets = [];
    this.loader.showLoader();
    this.fileService.getFileSheets(client.key, id).subscribe(
      (data) => {
        console.log(data);
        this.sheets = reMapForAutoComplete(data, "sheetName", "sheetName");
        if(this.sheets.length>0){
         while(this.sheets.length>1){
           this.sheets.pop();
         }
          this.headerForm.get("sheet").patchValue({
            key: this.sheets[0].key,
            value: this.sheets[0].value,
            });
        }
        this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
  }
  getFileLayout(id) {
    // if(this.dataTransferModel.isRedirected===0){
    //   this.fieldLayoutComp.fieldLayoutForm.reset();
    // }
   
    this.showEditButton=false;
    const { client } = this.headerFormVal;
    this.headerForm.patchValue({
      header: 0,
      headerRow: null,
      startRowNo:null,
      sheet:null,
    });
    
    this.sheets = this.layoutFields = [];
    this.fileLayout = null;
    this.loader.showLoader();
    this.fileService.getFileLayout(client.key, id).subscribe(
      (data) => {
        if (data === null) {
          this.getSheets(client, id);
          this.toast.showInfo("No Layout defined", "Select a sheet");
        } else {
          this.fileLayout = data;
          this.showEditButton=true
          this.headerForm.patchValue({
            header: this.fileLayout.hasHeaders,
            headerRow: {
              key: this.fileLayout.noOfHeaders,
              value: this.fileLayout.noOfHeaders,
            },
            startRowNo: {
              key: this.fileLayout.startRowNo,
              value: this.fileLayout.startRowNo,
            },
          });
          this.getFieldLayoutData(this.fileLayout.clientFileLayoutId);
        }
        this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
  }
  getFieldLayout(dataVal) {
   // this.loader.showLoader();
    this.companyName=dataVal.form.client.value;
    this.showFileName=dataVal.form.file.value;
    this.fileLayout=dataVal.fileLayout;
    console.log("this.fileLayout  ",this.fileLayout)
    this.fileService.getFileLayoutById(dataVal.id).subscribe(
      (data) => {
        this.layoutFields = data;
        this.setValue(dataVal);
        this.clientFileLayoutId=dataVal.id;
        console.log("before" + data);
        console.log(this.layoutFields);
        this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
  }
  getSheetHeaders(dataVal) {
   // if (this.headerForm.valid) {
     // this.loader.showLoader();
     console.log("dataVal.form   ",dataVal.form)
      const { client, file, header, headerRow, startRowNo,sheet } = dataVal.form;
      this.companyName=dataVal.form.client.value;
      this.showFileName=dataVal.form.file.value;
      this.fileService
        .getSheetHeaders({
          clientApplicationFileId: file.key,
          clientId: client.key,
          headerRows: +header,
          noOfHeaders: headerRow.key,
          startRowNo:startRowNo.key,
          sheetName: sheet.key,
        })
        .subscribe(
          (data) => {
            this.layoutFields = data;
            this.setValue(dataVal)
            this.loader.hideLoader();
          },
          (err) => {
            this.toast.showError(err.error.message, "error");
            this.loader.hideLoader();
          }
        );
    // } else {
    //   this.toast.showError("Form Error", "Please select all the fields");
    // }
  }
  editField(data: FieldLayoutModel) {
    this.editFieldValue = data;
   // console.log(data);
   this.fileLayoutHeaderComponent.panelForm.toggleSlider();
  }
  deleteField(data: FieldLayoutModel) {
   this.fileService.deleteFileLayoutFieldById(data.clientFileLayoutFieldId)
   .subscribe(
     res=>{
       this.toast.showSuccess("deleted","success");
       this.editFieldValue = null;
      // this.fieldLayoutComp.fieldLayoutForm.reset();
       this.getFileLayout(this.headerFormVal.file.key);
     },
     (err) => {
      this.toast.showError(err.error.message, "error");
     }
   );
  }
  addField(data) {

    if(this.headerFormVal.client.value===null || this.headerFormVal.client.value.key==='' ){
      return this.toast.showError("Client Not Selected","error");
    }
    
    if(this.headerFormVal.file.value===null || this.headerFormVal.file.value.key===''){
      return this.toast.showError("File Not Selected","error");
    }

    const fileLayoutFieldData = {
      clientFileLayoutFieldId:this.editFieldValue !== null? this.editFieldValue.clientFileLayoutFieldId: 0,
      clientFileLayoutId: this.clientFileLayoutId,
      fieldName: data.fieldName,
      fieldType: data.fieldType,
      createdAt:this.editFieldValue !== null ? this.editFieldValue.createdAt : null,
      sequence: this.editFieldValue !== null ? this.editFieldValue.sequence : 0,
      fieldFormat:  data.fieldFormat !== null ? data.fieldFormat : null,
      fieldSize:data.fieldSize !== null ? data.fieldSize : 0,
    };
    this.fileService
      .saveOrUpdateFileLayoutField(fileLayoutFieldData)
      .subscribe((data) => {
        this.toast.showSuccess("Field Saved", "success");
        this.editFieldValue=null;
        this.fieldLayoutComp.fieldLayoutForm.reset();
       if(fileLayoutFieldData.clientFileLayoutFieldId!=0){ 
        this.forLayoutDataUpdate(data);
       // this.fieldLayoutTableComp.resetSearch();
       }else{
        //this.getFileLayout(this.headerFormVal.file.key);
       }
      },
      err=>{
        this.toast.showError(err.error.message, "error");
      }
      );
      this.getFileLayout(this.headerFormVal.file.key);
  }
  saveLayout() {
    
    const { client, file, header, headerRow, startRowNo,sheet } = this.headerFormVal;
    
    if(client===null || client===undefined || client.key===''){
      this.toast.showError('error','client name is mandatory');
      return;
    }

    if(file===null || file===undefined){
         this.toast.showError('error','file name is mandatory');
         return;
    }
  
    let sheetName="Sheet1"

   if(this.fileLayout!=null || this.fileLayout!=undefined ) {
    sheetName=this.fileLayout.sheetName;
   }

   let layoutFieldsData:FieldLayoutModel[]=[];

   if(this.layoutFields.length>0){
   for(let i=0;i<this.layoutFields.length;i++){

        const fieldData={
          "clientFileLayoutFieldId": this.layoutFields[i].clientFileLayoutFieldId,
          "clientFileLayoutId":this.layoutFields[i].clientFileLayoutId,
          "fieldName": this.layoutFields[i].fieldName,
          "fieldType":this.layoutFields[i].fieldType,
          "comments":this.layoutFields[i].comments,
          "sequence":i+1,
          "modifiedBy":this.layoutFields[i].modifiedBy,
          "modeifiedAt": this.layoutFields[i].modeifiedAt,
          "createdBy": this.layoutFields[i].createdBy,
          "createdAt":this.layoutFields[i].createdAt,
          "fieldFormat": this.layoutFields[i].fieldFormat,
          "fieldSize":this.layoutFields[i].fieldSize,
        }
        layoutFieldsData.push(fieldData);
   }
  }


   if (isEmpty(this.layoutFields)) {
    const fieldData={
      "clientFileLayoutFieldId": null,
      "clientFileLayoutId":null,
      "fieldName": "Account No",
      "fieldType":"STRING",
      "comments":null,
      "sequence":1,
      "modifiedBy":0,
      "modeifiedAt": null,
      "createdBy": 0,
      "createdAt":null,
      "fieldFormat": null,
      "fieldSize":0,
    }
    layoutFieldsData.push(fieldData);
  }
    
  // const { client, file, header, headerRow, sheet } = this.headerForm.value;
  console.log("fileLayout on save ",this.fileLayout)
    const fileLayoutData = {
      clientFileLayoutId:this.fileLayout!== null ? this.fileLayout.clientFileLayoutId : 0,
      clientApplicationFileId: file.key,
      sheetName: sheet !== null ? sheet.key :sheetName,
      hasHeaders: header ? 1 : 0,
      noOfHeaders: headerRow !== null ? headerRow.key : 0,
      startRowNo:startRowNo !==null ? startRowNo.key :0,
      createdBy: 1,
      fileLayoutField: layoutFieldsData,
    };

    this.fileService.saveFileLayout(fileLayoutData).subscribe((data) => {
      this.toast.showSuccess('success','layout saved');
      //this.headerForm.reset();
      // this.getFileLayout(this.headerForm.controls.file.value.key);
      this.getFileLayout(file.key);
      this.sheets = [];
      //this.clientId=null;     
    },
    (err)=>{
      this.toast.showError(err.error.message, "error")
    });
  }
  getFirstField(data) {
    console.log(data);
  }

  forLayoutDataUpdate(data){
    //console.log("console.log data ",data);
    for(let i=0;i<this.layoutFields.length;i++){
    if(this.layoutFields[i].clientFileLayoutFieldId==data.clientFileLayoutFieldId){
      this.layoutFields[i]=data;
      //console.log("Layout field ",this.layoutFields[i])
    } 
    }
  }

  getPreviousState() {
    this.observalbleDataService.inventoryChanged$.subscribe(
      data => {
        this.dataTransferModel = data; 
         if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0) {
          this.headerForm.get("client").patchValue({
            key: this.dataTransferModel.clientId,
            value: this.dataTransferModel.clientName,
          });
          this.clientId=this.dataTransferModel.clientId;

          if(this.dataTransferModel.clientFileId !== undefined && this.dataTransferModel.clientFileId !== 0 && this.dataTransferModel.fileName!==''
          && this.dataTransferModel.fileName!==null){
            this.headerForm.get("file").patchValue({
              key: this.dataTransferModel.clientFileId,
              value: this.dataTransferModel.fileName,
            });

            this.showBackButton=true;
            this.getFileLayout(this.headerForm.controls.file.value.key);
          }

      
        
          
          // if(this.dataTransferModel.clientFileId !== 0 && this.dataTransferModel.clientName !== ''){
          //   this.getFileLayout(this.headerForm.controls.file.value.key);  
          // }
          
        }
      });

      if(this.showBackButton!==true){
        const clientIdC=this.cookieService.get('clientId');
        const clientNameC=this.cookieService.get('clientName');
        console.log(clientIdC);
        console.log(clientNameC);
        
        if(clientIdC!==undefined && clientIdC!==null){
          this.headerForm.get("client").patchValue({
                   key: clientIdC,
                   value: clientNameC,
                   });
          this.clientId= this.headerForm.controls.client.value.key;
        }
       
      }

     
    }

    maintainClientState(client){
      this.dataTransferModel = {
        clientFileId: 0,
        clientId: client.key,
        isRedirected: 1,
        applicationId: 0,
        toDate: '',
        fromDate: '',
        clientName: client.value,
        page: '',
        fileName: '',
        workflowName: '',
        workflowId: 0,
        dataType: '',
        dataTypeId:0
      }
      this.observalbleDataService.addToInventory(this.dataTransferModel); 
    }
 
    buttonShowHidePermission(){
      this.fileService.buttonShowHidePermission().subscribe(
        (res) =>{ 
             this.rolePermission = res  
             this.callNgOnInitMethods();
     }
      )};
    
      callNgOnInitMethods(){
       // this.getPreviousState();
        this.setTitle();
      }

      setValue(data){
       this.headerFormVal=data.form;
        if(data.form.header>0){
        this.header="true";
        }else{
          this.header="false";
        }
        this.fileName=data.form.file.value;
        this.startRowCount=data.form.startRowNo.value;
        this.endRowCount=data.form.headerRow.value;
        this.client=data.form.client.value;

      }

      getFieldLayoutData(id) {
        this.loader.showLoader();
        this.fileService.getFileLayoutById(id).subscribe(
          (data) => {
            this.layoutFields = data;
            //this.setValue(dataVal);
           // this.clientFileLayoutId=dataVal.id;
            console.log("before" + data);
            console.log(this.layoutFields);
            this.loader.hideLoader();
          },
          (err) => {
            this.toast.showError(err.error.message, "error");
            this.loader.hideLoader();
          }
        );
      }

      editValuesNull(){

        this.editFieldValue=null;
      }
}
