import { Component, OnInit } from '@angular/core';
//import { ApplicationStateService } from '../../services/application-state.service';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
//import { storePermission1 } from '../../services/application-state.service';

@Component({
  selector: 'app-stress-testing',
  templateUrl: './stress-testing.component.html',
  styleUrls: ['./stress-testing.component.scss']
})
export class StressTestingComponent implements OnInit {
  state:string;
  private subscription_state$: any;
  dataValue:any;
  constructor(private storePermission: Store<{ state: any }>,private router: Router) {
    this.state=localStorage.getItem("stateflag");
    // this.subscription_state$ = this._applicationStateService
    // .execChange
    // .pipe(
    //   startWith(),
    // );


    // this.subscription_state$ = this._applicationStateService.execChange.subscribe(
    //   value => {
    //     this.stateBoolean= value;
    //   }
    // );
  
   }

  ngOnInit(): void {
   
    // this.storePermission.dispatch(storePermission1({permit: this.stateBoolean})); 
    //        this.storePermission.select(x => this.dataValue = x).subscribe();
    //        console.log("statevalue-----",this.dataValue)

  
      // this._applicationStateService.stateChange(false);
  console.log(" localStorage------",localStorage.getItem("flag"))
  this.router.navigate(['/report/dashboard']);
  }

}
