import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { ShockMasterHeaderComponent } from './components/shockMaster-header/shockMaster-header.component';
import { ShockMasterTableComponent } from './components/shockMaster-table/shockMaster-table.component';
import { ShockMasterSelectorComponent } from './components/shockMaster-selector/shockMaster-selector.component';
import { MultiSelectModule } from 'primeng';

@NgModule({
  declarations: [
    ShockMasterSelectorComponent,
    ShockMasterTableComponent,
    ShockMasterHeaderComponent
    

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule,
    MultiSelectModule
  ],
  exports: [
    ShockMasterSelectorComponent,
    ShockMasterTableComponent,
    ShockMasterHeaderComponent
    ]
})
export class ShockMasterCompModule { }
