export const environment = {
    production: false,
    logo: "/assets/images/logo.jpg",
    baseUrl: "https://nova.uventure.in",
    reportUrl:"https://nova-analytics.uventure.in/",
    novaWorkspaceUrl:"https://nova-analytics.uventure.in/workspace/",
    firebase: {
      apiKey: "AIzaSyBVDVvHifrD241puPr9nXfJPnADqeKEmT8",
      authDomain: "janus-265205.firebaseapp.com",
      databaseURL: "https://janus-265205.firebaseio.com",
      projectId: "janus-265205",
      storageBucket: "janus-265205.appspot.com",
      messagingSenderId: "810550008433",
      appId: "1:810550008433:web:c4c045781277fdb13859d0",
      measurementId: "G-T9LS0G5SJR"
    }
  };