import { DatePipe } from '@angular/common';
import { Component, OnInit ,SimpleChanges,OnChanges, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormBuilderService } from 'src/app/formsy/services/form-builder.service';
import { environment } from "src/environments/environment";
import { currentMonthRange } from 'src/app/utils/dates';
import { WorkflowJobTableComponent } from 'src/app/workflows/components/workflow-job-table/workflow-job-table.component';
import { WorkflowJobComponent } from '../workflow-job/workflow-job.component';
import { FileService } from 'src/app/files/services/file.service';
import { WorkflowResponseModel } from '../../models/workflow.model';
import { ShockMasterService } from '../../services/shockMaster.service';

@Component({
  selector: 'app-report-analysis',
  templateUrl: './report-analysis.component.html',
  styleUrls: ['./report-analysis.component.scss'],
  providers: [FormBuilderService],
})
export class ReportAnalysisComponent implements OnInit,OnChanges {
  @ViewChild(WorkflowJobTableComponent, { read: false, static: false })
  workflowJobTableComponent: WorkflowJobTableComponent;

  @ViewChild(WorkflowJobComponent, { read: false, static: false })
  workflowJobComponent:WorkflowJobComponent;

  state:string;  
  criteria:any;
  reportUrl:string;
  url:string;
  collapseReport:boolean=false;
  reportAnalysisForm:FormGroup=null;
  showWorkflowTable:boolean;
  workflowResponseModel:WorkflowResponseModel[]=[];
  reportId:number;
  stressShockData:any;
  companyId:number=0;
  menu:string;
  constructor(private ShockMasterService:ShockMasterService,private toastService:ToastService,private fileService:FileService,private titleService: TitleService,private datePipe:DatePipe) {
    this.menu=localStorage.getItem("menu");
    this.state=localStorage.getItem("stateflag");  
    this.titleService.setTitle("Historical Analysis");
   
    this.reportAnalysisForm = new FormGroup({
      stressShockCode: new FormControl(null, [Validators.required]),
      fromDate: new FormControl(null, [Validators.required]),
      toDate: new FormControl(null, [Validators.required]),
    });
    this.companyId=Number(localStorage.getItem("companyId"));
   }

  ngOnInit(): void {
  
  this.reportAnalysisForm.get("stressShockCode").setValue("STRESS_SHOCK001");
  let currentDateRange=currentMonthRange();
    console.log(currentDateRange,'  current date range');
    this.reportAnalysisForm.controls.fromDate.setValue(this.datePipe.transform(currentDateRange[0],"yyyy-MM-dd"))
    this.reportAnalysisForm.controls.toDate.setValue(this.datePipe.transform(currentDateRange[1],"yyyy-MM-dd"))

    //this.getAllShockList();
    this.getReportAnalysis();
   
    console.log("reportAnalysisForm",this.reportAnalysisForm);
  
  }

 ngOnChanges(changes: SimpleChanges) {

  }

  getAllShockList(){
    
    this.ShockMasterService.getStressShockData().subscribe(
    (data)=>{
      console.log("shockList Data", data)
      this.stressShockData= data;
     
      for(var i=0;i<this.stressShockData.length;i++){
     if(this.stressShockData[i].stressShocksCode=="STRESS_RA001"){
      this.reportId=this.stressShockData[i].reportId;
      this.reportUrl=this.stressShockData[i].instanceUrl;
      }
     }

     if (this.reportUrl.includes("?")) {
      this.criteria='&ZOHO_CRITERIA="client_id"='+this.companyId+' and '+'"stress_shocks_code"=%27'+this.reportAnalysisForm.controls.stressShockCode.value+'%27%20and%20Date("asofdate")%20between%20%27'+this.reportAnalysisForm.controls.fromDate.value+'%27%20and%20%27'+this.reportAnalysisForm.controls.toDate.value+'%27'
     }
    else{
     this.criteria='?ZOHO_CRITERIA="client_id"='+this.companyId+' and '+'"stress_shocks_code"=%27'+this.reportAnalysisForm.controls.stressShockCode.value+'%27%20and%20Date("asofdate")%20between%20%27'+this.reportAnalysisForm.controls.fromDate.value+'%27%20and%20%27'+this.reportAnalysisForm.controls.toDate.value+'%27'
     }
     this.url=environment.reportUrl+this.reportUrl+this.criteria
     console.log("url onInit",this.url); 
     console.log("run report id on",this.reportId);

    },
    (err) => {
      this.toastService.showError(err.error.message, "error");
      
    }
    );
  }

  onReportTabOpen(e){
   // console.log("e---",e);
    this.collapseReport=false;
    console.log("this.collapseReport",this.collapseReport);
   }
 
    
  resetReport(){
    this.reportAnalysisForm.get("stressShockCode").setValue("STRESS_SHOCK001");
  let currentDateRange=currentMonthRange();
    // console.log(currentDateRange,'  current date range');
    this.reportAnalysisForm.controls.fromDate.setValue(this.datePipe.transform(currentDateRange[0],"yyyy-MM-dd"))
    this.reportAnalysisForm.controls.toDate.setValue(this.datePipe.transform(currentDateRange[1],"yyyy-MM-dd"))
     this.url=null;
     this.collapseReport=false;
  
  }

  runReport(){

  console.log("this.reportAnalysis form on run",this.reportAnalysisForm);
  if(this.reportAnalysisForm.valid){
   this.collapseReport=true;
    
      let validate=this.validationOnDate(this.reportAnalysisForm.controls.fromDate.value,this.reportAnalysisForm.controls.toDate.value);
      if(validate===true){
        // console.log("workflow report Id",this.reportId)
       // this.getreportMappedWorkflows(this.reportId); 
       this.getReportAnalysis();
     
      }
      if(validate===false){
        this.toastService.showWarn('error','date range incorrect')
        
      } 
      
  }else{
      this.toastService.showError("Please Fill Required Fields","error");
  } 
  
  
  }

  validationOnDate(fromDate,toDate){
    if(fromDate && toDate){
    if(new Date(fromDate)>new Date(toDate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }

  // executeworkflow(data){

  //   console.log("data ",data)
  //   this.workflowJobComponent.executeWorkflow(data);
  //   this.url=null;
  //   if (this.reportUrl.includes("?")) {
  //   this.criteria='&ZOHO_CRITERIA="client_id"='+this.companyId+' and '+'"stress_shocks_code"=%27'+this.reportAnalysisForm.controls.stressShockCode.value+'%27%20and%20Date("asofdate")%20between%20%27'+this.reportAnalysisForm.controls.fromDate.value+'%27%20and%20%27'+this.reportAnalysisForm.controls.toDate.value+'%27'
  //   }
  //   else{
  //   this.criteria='?ZOHO_CRITERIA="client_id"='+this.companyId+' and '+'"stress_shocks_code"=%27'+this.reportAnalysisForm.controls.stressShockCode.value+'%27%20and%20Date("asofdate")%20between%20%27'+this.reportAnalysisForm.controls.fromDate.value+'%27%20and%20%27'+this.reportAnalysisForm.controls.toDate.value+'%27'
  //   }
    
   
  //   console.log("this.url run",this.url);
  //   this.collapseReport=false;
  //   setTimeout(() => {
  //     this.url=environment.reportUrl+this.reportUrl+this.criteria;
  //     this.collapseReport=true; }, 1000);
          
  // }

     
// getreportMappedWorkflows(reportId){
//   //console.log("appId  ",this.reportId)
//         this.fileService.getWorkflowListByReportId(reportId).subscribe(
//           data =>{
//           // this.loader.hideLoader();
//         console.log("reportmappedworkflows ",data)
        
//         this.workflowResponseModel=data
  
//         if(this.workflowResponseModel.length==0){
//           console.log("toast messege",this.workflowResponseModel.length);
//           this.workflowResponseModel=[];
//           this.toastService.showWarn('no Workflow is linked ','error');
//           return;
//         }
        
//         this.workflowResponseModel.forEach(item=>{
//         // console.log("item",item.isActive===1);
        
//          item.isActiveDisplay=item.isActive===1?'Yes':'No'
//          item.workflowTypeDisplay=item.workflowType===1?'System':'Custom'
//          item.asOfDate=item.asOfDate!==null?this.datePipe.transform(item.asOfDate,'MM-dd-yyyy'):""
        
//         })
                                        
//         this.workflowJobTableComponent.executeWorkflow(this.workflowResponseModel[0]);
  
                                               
//             },
//           error => {
//                 this.toastService.showError(error.error.message, "error");
//                     }
        
//           )
//   }

getReportAnalysis(){
  const data={
    stressShockCode:this.reportAnalysisForm.controls.stressShockCode.value,
    startDate:this.reportAnalysisForm.controls.fromDate.value,
    endDate:this.reportAnalysisForm.controls.toDate.value
  }
  console.log("report analysis data",data);

  this.ShockMasterService.getReportAnalysis(data).subscribe(
    (res)=>{
      console.log("report analysis response",res);

      this.reportUrl=res.url;
      if (this.reportUrl.includes("?")) {
        this.criteria='&ZOHO_CRITERIA="client_id"='+this.companyId+' and '+'"stress_shocks_code"=%27'+this.reportAnalysisForm.controls.stressShockCode.value+'%27%20and%20Date("asofdate")%20between%20%27'+this.reportAnalysisForm.controls.fromDate.value+'%27%20and%20%27'+this.reportAnalysisForm.controls.toDate.value+'%27'
       }
      else{
       this.criteria='?ZOHO_CRITERIA="client_id"='+this.companyId+' and '+'"stress_shocks_code"=%27'+this.reportAnalysisForm.controls.stressShockCode.value+'%27%20and%20Date("asofdate")%20between%20%27'+this.reportAnalysisForm.controls.fromDate.value+'%27%20and%20%27'+this.reportAnalysisForm.controls.toDate.value+'%27'
       }
       this.url=environment.reportUrl+this.reportUrl+this.criteria
       console.log("url onInit",this.url);
    },
    (err) => {
      this.toastService.showError(err.error.message, "error");
      
    }
    );
}

}
