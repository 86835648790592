import { NgModule } from "@angular/core";
import { RulesTableComponent } from "./components/rules-table/rules-table.component";
import { SharedModule } from "../shared/shared.module";
import { RulesService } from "./services/rules.service";
import { ExcelTemplateTableComponent } from "./components/excel-template-table/excel-template-table.component";
import { RulesSelectorComponent } from "./components/rules-selector/rules-selector.component";
import { ExcelRawTableComponent } from './components/excel-raw-table/excel-raw-table.component';
import { RulesParameterComponent } from "./components/rules-parameter/rules-parameter.component";

@NgModule({
  declarations: [
    RulesTableComponent,
    ExcelTemplateTableComponent,
    RulesSelectorComponent,
    ExcelRawTableComponent,
    RulesParameterComponent
  ],
  imports: [SharedModule],
  exports: [
    RulesTableComponent,
    ExcelTemplateTableComponent,
    RulesSelectorComponent,
    ExcelRawTableComponent,
    RulesParameterComponent
  ],
  providers: [RulesService]
})
export class IngestionRulesModule {}
