import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { GenericFileUploadComponent } from '../generic-file-upload/generic-file-upload.component';
import { MappingComponent } from '../mapping/mapping.component';
import { RulesComponent } from '../rules/rules.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ingest-tabbed',
  templateUrl: './ingest-tabbed.component.html',
  styleUrls: ['./ingest-tabbed.component.scss']
})
export class IngestTabbedComponent implements OnInit {

  @ViewChild(GenericFileUploadComponent, { read: false, static: false })  
  genericFileUploadComponent: GenericFileUploadComponent;

  @ViewChild(RulesComponent, { read: false, static: false })  
  rulesComponent: RulesComponent;
  
  @ViewChild(MappingComponent, { read: false, static: false })
  mappingComponent:MappingComponent;
  
  steps = [
    { text: 'File Upload', link: '' },
    { text: 'Mapping', link: '' },
    { text: 'Rules', link: '' },
    { text: 'Ingest', link: '' },
    
  ];
  fileLayoutPage:boolean=true;
  MappingPage:boolean=false;
  rulesPage:boolean=false;
  showHeader:boolean=false;
  rulesHeader:boolean=false;
  showFileUploadHeader:boolean=false;
  showRuleButton:boolean=false;
  tabbed:number;
  dataFilesPage:boolean=false;
  headerForm:FormGroup=null;
  tabbedBoolean:boolean=true;
  genericMappingFiles:boolean=false;
  genericrulesFiles:boolean=false;
  constructor(private router: Router,
    private activeRoute: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    //this.callUploadFiles();
   // this.fileLayoutPage=true;
  //  await delay(400);
    // this.genericFileUploadComponent.callGenericToggle();
  }

  // ngOnChanges(changes: SimpleChanges) {

  //   this.callUploadFiles()
  // }

  getComponent(data){
    //console.log("data coming->> ",data);
    if(data===1){
      
      this.fileLayoutPage=true;
      this.MappingPage=false;
      this.rulesPage=false;
     this.showFileUploadHeader=false;
     this.dataFilesPage=false;
      document.getElementById("rule_buttons").style.display = 'none';
      document.getElementById("layout_buttons").style.display = '';
      //document.getElementById("header_manage").style.display = 'none';
    
      
       }

    if(data===2){
      this.MappingPage=true;
      this.fileLayoutPage=false;
      this.rulesPage=false;
      this.showHeader=true;
      this.dataFilesPage=false;
      this.genericMappingFiles=true;
      document.getElementById("layout_buttons").style.display = 'none';
      document.getElementById("rule_buttons").style.display = 'none';
    }

    if(data===3){
      this.rulesPage=true;
      this.MappingPage=false;
      this.fileLayoutPage=false;
      this.rulesHeader=false;
      this.dataFilesPage=false;
      this.genericrulesFiles=true;
      document.getElementById("rule_buttons").style.display = '';
      document.getElementById("layout_buttons").style.display = 'none';
     
    }

    if(data===4){
      this.showHeader=true;
      this.dataFilesPage=true;
      this.rulesPage=false;
      this.MappingPage=false;
      this.fileLayoutPage=false;
      document.getElementById("layout_buttons").style.display = 'none';
      document.getElementById("rule_buttons").style.display = 'none';
    }



   
  }
  callUploadFiles()
  {
    this.genericFileUploadComponent.callGenericToggle();
  }


  getFilesDetails(form){
    //const value=form.controls.file.value.key;
    this.headerForm=form.value;
    console.log("headerForm on tabbed ",this.headerForm)
  }

  redirectMappingToRule(){
    this.router.navigate(["data/ingest/generic-file-upload"],{queryParams: {step:3}});
  }

  // gotToMapping(data){
  //  console.log("data ---------------------------------- coming is  ",data)
  //  this.fileLayoutPage=false;
   
  //  console.log("MappingPage  ",this.MappingPage)

  // }

  // toggleManageForm(){

  //   this.GenericFileUploadComponent.callGenericToggle();
    
  //     }

}
