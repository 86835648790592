import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Observable, Subscription } from 'rxjs';
import { ClientFileFormComponent } from 'src/app/client-master/components/client-file-form/client-file-form.component';
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormGroup, FormControl } from '@angular/forms';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { ClientFileMasterModel } from '../../models/clientFileMaster.model';
import { FileService } from 'src/app/files/services/file.service';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { currentMonthRange } from 'src/app/utils/dates';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SspApplicationService } from '../../services/ssp-application.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { ClientService } from '../../services/client.service';
import { ClientFileHeaderComponent } from 'src/app/client-master/components/client-file-header/client-file-header.component';
import { LookUpListModel } from '../../models/lookup.model';
import { LookupService } from '../../services/lookup.service';

@Component({
  selector: 'app-client-file-master',
  templateUrl: './client-file-master.component.html',
  styleUrls: ['./client-file-master.component.scss']
})
export class ClientFileMasterComponent implements OnInit {
  searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String | number, header:String }[];

  @ViewChild(ClientFileFormComponent, { read: false, static: false })
  clientFileFormComponent: ClientFileFormComponent;

  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;

  
  
  @ViewChild(ClientFileHeaderComponent, { read: false, static: false })
  clientFileHeaderComponent:ClientFileHeaderComponent;

  headerForm:FormGroup;
  activeRoute$: Subscription;
  clientMasterList:any;
  clientMasterListWithClient:any;
  editClientMasterValue=null;
  fileDataTypeList: {key: any; value: any }[]=[];
  fileTypeList: {key: any; value: any }[]=[];
  dataTransferModel: DataTransferModel;
  fromDt: any;
  toDt: any;
  showBackButton:boolean=false;
  pageName:string
  state$: Observable<object>;
  rolePermission:any;
  allFrequencyData:{key: any; value: any }[]=[];
  clientsList:{key: any; value: any }[]=[];
  companyName:string=null;
  companyId:any;
  adminFlag:String;
  state:string;
  frequencyList: {key: any; value: any }[]=[];
  lookUpModelList: LookUpListModel[] = [];
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,private clientFileMasterService:ClientFileMasterService,
    private toast:ToastService,
    public observalbleDataService: ObservalbleDataService,
    private loader:LoaderService,
    private fileService:FileService,
    private router: Router,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>,
    private sspapplicationService: SspApplicationService,private clientService:ClientService,
    private lookupService: LookupService) { 
      this. searchByCols = [
        { field: "name", header: "Name" },
        { field: "fileNamePrefix", header: "File Name Prefix" },
        { field: "desc", header: "Description" },
        { field: "fileTypeValue", header: "File Type" },
        { field: "fileDataTypeValue", header: "File Data Type" },
        { field: "frequency", header: "Frequency" },
      ];

      this.headerForm = new FormGroup({
        client: new FormControl(),
      });
      this.state=localStorage.getItem("stateflag"); 
    }

  ngOnInit(): void {
    this.adminFlag=this.cookieService.get("adminFlag");
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );

    this.buttonShowHidePermission();

    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
        for(var i=0;i<data.length;i++){
          console.log("data  ",data[i].clientId)
          this.companyId=data[i].clientId
          this.companyName=data[i].clientName
        }
        this.headerForm.get("client").setValue({
          key: this.companyId,
          value: this.companyName
        });
 
     
 
       console.log("this.headerForm",this.headerForm.value)
       //this.getTransformList(this.headerForm.controls.client.value.key,'onChange');
       this.clientSelected(this.headerForm.controls.client.value,'onLoad','toggleOnInit')
       
      
      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
      );
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  addMaster(data){
  //   if(this.headerForm.value.client==null){
  // this.toast.showError("Please Select The Client First","error")
  //   }else{
    console.log("this.headerForm.controls.client.value.key  ",this.headerForm.controls.client.value.key)
    const clientMasterData = {
      clientfilemasterId:this.editClientMasterValue!==null?this.editClientMasterValue.clientfilemasterId:null,
          clientId:this.headerForm.controls.client.value.key,
          name:data.name,
          fileNamePrefix:data.fileNamePrefix,
          desc:data.desc,
          fileTypeId:data.fileTypeId!==null?data.fileTypeId.key:null,
          fileDataTypeId:data.fileDataTypeId!==null?data.fileDataTypeId.key:null,
          frequency:data.frequency!==null?data.frequency.key:null,
          
    };
    this.clientFileMasterService
      .saveOrUpdateData(clientMasterData)
      .subscribe((data) => {
        //this.clientSelected(this.headerForm.value.client.key, 'onAddMaster');

        this.getClientFileMasterListWithClient();
        this.getClientFileMasterList(this.headerForm.controls.client.value.key);
        
       // this.clientFileFormComponent.clientMasterForm.reset();
       this.clientFileHeaderComponent.resetClientMasterForm();
        this.toast.showSuccess('saved successfully','success');
        this.editClientMasterValue=null;
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
      ); 
  //  }   
  }

  clientSelected(data, loadType,toggleFlag) {
    if (loadType !== 'onLoad') {
     // this.maintainClientState();
    }
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
  //  this.loader.showLoader();
  if(this.headerForm.controls.client.value.key!=undefined || this.headerForm.controls.client.value.key!=null){
    this.companyName=this.headerForm.controls.client.value.value;
    this.getClientFileMasterList(this.headerForm.controls.client.value.key);
  }
    if(toggleFlag=='toggleOnLoad'){
      this.panelFilter.toggleSlider();
    }
  }

  getClientFileMasterList(data) {
    this.clientFileMasterService.getClientFileMasterListByClient(data)
      .subscribe(
        data => {
          this.loader.hideLoader();
          this.clientMasterList = data;
         // this.clientFileFormComponent.clientMasterForm.reset();
         this.clientFileHeaderComponent.resetClientMasterForm();
          // for (var j = 0; j <= this.clientMasterList.length - 1; j++) {
          //   for (var i = 0; i <= this.fileTypeList.length - 1; i++) {
          //     if (this.fileTypeList[i].key === this.clientMasterList[j].fileTypeId)
          //       this.clientMasterList[j].fileTypeId = this.fileTypeList[i].value;
          //   }
          // }
          // for (var j = 0; j <= this.clientMasterList.length - 1; j++) {
          //   for (var i = 0; i <= this.fileDataTypeList.length - 1; i++) {
          //     if (this.fileDataTypeList[i].key === this.clientMasterList[j].fileDataTypeId)
          //       this.clientMasterList[j].fileDataTypeId = this.fileDataTypeList[i].value;
          //   }
          // }
        },
        err => {
          //this.listingListEdit=data;
          this.toast.showError(err.error.message, "error");
        }
      );
  }
    editClientFileMaster(data){
    this.getClientFileMaster(data.clientfilemasterId);
    this.clientFileHeaderComponent.panelForm.toggleSlider();
    }

    getClientFileMaster(id){
      this.clientFileMasterService.getClientFileMasterById(id)
      .subscribe(
        data=>{
          this.editClientMasterValue=data;  
        })
      }


      deleteById(data){
    
        this.loader.showLoader();
        console.log("delete id :",data);
        var clientfilemasterId=data.clientfilemasterId;
        this.clientFileMasterService.deleteClientFileMasterById(clientfilemasterId)
        .subscribe(
          data=>{
           // this.clientSelected(this.headerForm.value.client.key, 'onDelete'); 
           this.getClientFileMasterListWithClient(); 
           this.getClientFileMasterList(this.headerForm.controls.client.value.key);

        //   this.clientFileFormComponent.clientMasterForm.reset();
        this.clientFileHeaderComponent.resetClientMasterForm();
            this.loader.hideLoader();
            this.toast.showSuccess('success','data deleted successfully');
          },
          err=>{
            this.loader.hideLoader();
            this.toast.showError('error','error deleting data');
          }
        )
      }

      getApplicationFileType(){
        this.fileService.getApplicationDataTypeByUser().subscribe(
       (data) => {
         const fileDataType=data;
        // for(var a=0;a<fileDataType.length;a++){
         // console.log("type "+fileDataType[a].type);
         this.fileDataTypeList= reMapForAutoComplete(data,"clientApplicationFileDataType", "type");
       //  }
       },
       (err) => {
       //  this.toast.showSuccess("Status Not Changed", "error");
       this.toast.showError(err.error.message, "error");
       }
     
     );
        
     }
     
     getClientApplicationFileTypeData(){
       this.fileService.getClientApplicationFileType(this.state).subscribe(
         (data) => {
           const clientApplicationFileType=data;
         //  for(var a=0;a<clientApplicationFileType.length;a++){
           //  console.log("type "+clientApplicationFileType[a].type);
           this.fileTypeList=  reMapForAutoComplete(data,"clientApplicationFileTypeId", "type"); 
         //  this.fileTypeList.push({ key: clientApplicationFileType[a].clientApplicationFileTypeId, value: clientApplicationFileType[a].type });
        //   }
         },
         (err) => {
         //  this.toast.showSuccess("Status Not Changed", "error");
         this.toast.showError(err.error.message, "error");
         }
       
       );
     
  }

  maintainClientState(){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: this.headerForm.value.client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: this.headerForm.value.client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    } 
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log("clientIdC   ",clientIdC);
    console.log("clientNameC  ",clientNameC);
    
    if(clientIdC!==undefined && clientIdC!==null &&  clientIdC!==""){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
               this.clientSelected(this.headerForm.controls.client.value.key, 'onLoad','toggleOnInit');
               
                     
    }
    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     console.log("Sahil Ahuja:", this.dataTransferModel);
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.clientSelected(this.dataTransferModel.clientId, 'onLoad');
    //     }
    //     if(this.dataTransferModel.page !=='' && this.dataTransferModel.page !== null && this.dataTransferModel.page !== undefined){
    //         this.showBackButton=true;
    //         this.pageName=this.dataTransferModel.page
    //     }else{
    //       this.showBackButton=false;
    //     }
    //   });
    }


    goToPage(){
      if(this.showBackButton){
      this.dataTransferModel={
             clientFileId:this.dataTransferModel.clientFileId,
             clientId: this.headerForm.controls.client.value.key,
             isRedirected:1,
             applicationId:this.dataTransferModel.applicationId,
             toDate:this.dataTransferModel.toDate,
             fromDate:this.dataTransferModel.fromDate,
             clientName:this.headerForm.controls.client.value.value,
             page:this.dataTransferModel.page,
             fileName:this.dataTransferModel.fileName,
             workflowName:this.dataTransferModel.workflowName,
            workflowId:this.dataTransferModel.workflowId,
            dataType:this.dataTransferModel.dataType,
            dataTypeId:this.dataTransferModel.dataTypeId
      }
     // console.log(this.dataTransferModel,'helooo ',this.pageName);
      this.observalbleDataService.addToInventory(this.dataTransferModel);
      this.router.navigate(['/layout-files']);
    
    }
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getAllFrequencyType();
      this.getApplicationFileType();
      this.getClientApplicationFileTypeData();
      this.setTitle();  
     // this.getPreviousState();
      //this.getClientFileMasterListWithClient();
      // this.getFrequencyData();
      this.getClients();
    }

    getClientFileMasterListWithClient() {
      this.clientFileMasterService.getClientFileMasterListWithClient()
        .subscribe(
          data => {
            this.loader.hideLoader();
            this.clientMasterListWithClient = data;
            //this.clientFileFormComponent.clientMasterForm.reset();
         
          },
          err => {
            this.toast.showError(err.error.message, "error");
          }
        );
    }


    // getFrequencyData(){
    //   let type="frequency";
    //   const dataVal={
    //     "type" :type
    //    }
    //      this.sspapplicationService.getAllGenericDropDownData(dataVal)
    //      .subscribe(
    //     (data)=> {
    //        this.allFrequencyData = reMapForAutoComplete(data,"itemValue", "itemText");
    //        //console.log("generic data"+this.allGenericData);
    //     },
    //    (err) => {
    //      this.toast.showError(err.error.message, "error");
    //    }
    //    );
    //  }
  

 getClients() {

            //  this.loader.showLoader();
      
              this.clientService.getAllClients().subscribe(
      
                (data) => {
      
                 // this.loader.hideLoader();
      
                  this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
      
                  console.log(this.clientsList)
      
                },
      
                (err) => {
      
             //     this.loader.hideLoader();
      
                  this.toast.showError(err.error.message, "error");
      
                }
      
              );
      
            }

            getAllFrequencyType(){
              const lookupModel = {
                "tableName":"frequency",
                "clientId": 139
              }
              this.lookupService.getLookUpDataByTableAndClient(lookupModel)
               .subscribe(
                 data=>{
                   this.lookUpModelList=data;
                  this.frequencyList=reMapForAutoComplete(this.lookUpModelList,"description","description");
                  console.log("Inside File Master",this.frequencyList);
                 },
                 err=>{
                  this.toast.showError(err.error.message, "error");
                  //this.loader.hideLoader();
                }
               )
              
             }
        

  }
  

