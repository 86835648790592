import { DatePipe } from '@angular/common';
import { Component, OnInit ,SimpleChanges,OnChanges,ViewChild, QueryList, ElementRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormBuilderService } from 'src/app/formsy/services/form-builder.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { ShockMasterService } from '../../services/shockMaster.service';

import{
  ViewChildren
}from '@angular/core';

@Component({
  selector: 'app-configure-filters',
  templateUrl: './configure-filters.component.html',
  styleUrls: ['./configure-filters.component.scss'],
  providers: [FormBuilderService],
})
export class ConfigureFiltersComponent implements OnInit,OnChanges {
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  state:string;
  stressShocksList: any[];
  form:FormGroup=null;
  stressShockData:any;
  configureFilterForm:any;
  rawData:any[];
  configureFilterData:any[];
  menu:string;
  submenu:string;

  constructor(private ShockMasterService:ShockMasterService,private toastService:ToastService,private titleService: TitleService,private _fb: FormBuilder,
   ) {
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag");  
    this.titleService.setTitle("Configure Filters");

   }

  ngOnInit(): void {
    //this.getAllShockList();
   this.getAllConfigureFilters();
    
  }

  
  private buildform(list: any) {
    console.log("buildForm list",list)
    let arr: any = [];
      list.forEach(item => {
        arr.push(this.buildFormDynamic(item))
      });
      this.form = this._fb.group({
       formData: this._fb.array(arr)
      })
      this.configureFilterForm=this.form.value.formData;
console.log("configure filter array Form",this.configureFilterForm);
  }

  private buildFormDynamic(item): FormGroup {
  //  console.log("buildFormDynamic check",item)
    return this._fb.group({
     configureFiltersId:item.configureFiltersId,
      shocksCode:item.shocksCode,
      shockName:item.shockName,
      branch: item.branch,
      industry: item.industry,
      creditRating: item.creditRating,

    })
   
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.rowData && changes.rowData.currentValue) {
      let rowData=changes.rowData.currentValue;
      console.log("NgOnchange rowData  ",rowData);

    }
    }

    

  // getAllShockList(){
    
  //   this.ShockMasterService.getStressShockData().subscribe(
  //   (data)=>{
  //     console.log("shockList Data", data)
  //     this.stressShockData= data;
  //     this.stressShocksList = data.filter(e =>e.filterFlag===true);
        
  //    this.stressShocksList = reMapForAutoComplete(this.stressShocksList, "stressShocksCode", "shockName");
  //    console.log("this.stressShocksList",this.stressShocksList);
  //   //  this.buildform(this.stressShocksList);   


  //   },
  //   (err) => {
  //     this.toastService.showError(err.error.message, "error");
      
  //   }
  //   );
  // }

  branchSelected(data: any, event: any,rowData){
    console.log("branchSelected rowData",rowData)
    console.log("branchSelected event",event)
    console.log("branchSelected data",data)

    if(event.target.checked){
   
      this.configureFilterForm.forEach(item =>{
        if(rowData.shocksCode===item.shocksCode){
          item.branch=true;
        }
      });
      console.log("Selected Configure list for configureFilterForm Are:", this.configureFilterForm);
  
      
    }else{
      this.configureFilterForm.forEach(item =>{
        if(rowData.shocksCode===item.shocksCode){
          item.branch=false;
        }
      });
      console.log("Unselected Configure list for configureFilterForm Are:", this.configureFilterForm);
        }
  }

  industrySelected(data: any, event: any,rowData){
    console.log("industrySelected rowData",rowData)
    console.log("industrySelected event",event)
    console.log("industrySelected data",data)

    if(event.target.checked){
   console.log("shocks form on industry",this.configureFilterForm)
      this.configureFilterForm.forEach(item =>{
        if(rowData.shocksCode===item.shocksCode){
          item.industry=true;
        }
      });
      console.log("Selected Configure list for configureFilterForm Are:", this.configureFilterForm);
  
      
    }else{
      this.configureFilterForm.forEach(item =>{
        if(rowData.shocksCode===item.shocksCode){
          item.industry=false;
        }
      });
      console.log("Unselected Configure list for configureFilterForm Are:", this.configureFilterForm);
        }
  }
  creditSelected(data: any, event: any,rowData){
    console.log("creditSelected rowData",rowData)
    console.log("creditSelected event",event)
    console.log("creditSelected data",data)

    if(event.target.checked){
   
      this.configureFilterForm.forEach(item =>{
        if(rowData.shocksCode===item.shocksCode){
          item.creditRating=true;
        }
      });
      console.log("Selected Configure list for configureFilterForm Are:", this.configureFilterForm);
  
      
    }else{
      this.configureFilterForm.forEach(item =>{
        if(rowData.shocksCode===item.shocksCode){
          item.creditRating=false;
        }
      });
      console.log("Unselected Configure list for configureFilterForm Are:", this.configureFilterForm);
        }
  }

  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.configureFilterForm.forEach(item =>{
      item.branch=false,
      item.industry=false,
      item.creditRating=false
    });
    console.log("uncheck  this.configureFilterForm", this.configureFilterForm)
  }



save(){
  console.log("shocks form on save",this.configureFilterForm)
 this.saveConfigureFilters();
 
}  

saveConfigureFilters(){
  const data=this.configureFilterForm;
  this.ShockMasterService.saveUpdateConfigureFilters(data).subscribe(
    (res)=>{
  
      this.toastService.showSuccess("Saved", "Successfully");
      this.getAllConfigureFilters();
    },
    (err) => {
      this.toastService.showError(err.error.message, "error");
      
    }
    );
}      

getAllConfigureFilters(){

  this.ShockMasterService.getConfigureFilterData().subscribe(
    (data)=>{
     
      this.configureFilterData=data;
      console.log("get all shockList rowData", this.configureFilterData)

      this.buildform(this.configureFilterData);


    },
    (err) => {
      this.toastService.showError(err.error.message, "error");
      
    }
    );
}
    

}
