import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent {
  @Input() label!: string;
  @Input() options: { label: string; value: string }[] = [];
  @Input() ctrl: FormControl;
  onChange: any = () => { };
  onTouched: any = () => { };
  value = '';
  disabled = false;

  @Input() showSelectLabel:boolean=true;

  updateValue(event: Event) {
    const ele = event.target as HTMLSelectElement;
    this.value = ele.value;
    this.onChange(this.value);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.key == c2.key : c1 === c2;
  }

  writeValue(value: any) {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
