import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextboxComponent } from "./components/textbox/textbox.component";
import { FormbuilderComponent } from "./containers/formbuilder/formbuilder.component";
import { InputTextModule } from "primeng/inputtext";
import { ReactiveFormsModule,FormsModule } from "@angular/forms";
import { ValidatorsModule } from "../validators/validators.module";
import { CalendarModule } from "primeng/calendar";

import { ButtonComponent } from "./components/button/button.component";
import { AutoCompleteComponent } from "./components/auto-complete/auto-complete.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { DropDownComponent } from "./components/drop-down/drop-down.component";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { RadioComponent } from "./components/radio/radio.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { CheckListComponent } from "./components/check-list/check-list.component";
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { AutoComplete2Component } from './components/auto-complete2/auto-complete2.component';
import { Calendar2Component } from './components/calendar2/calendar2.component';
import { TextBoxComponent } from "./components/text-box/text-box.component";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TwoDigitDecimaNumberDirective } from "./components/number/TwoDigitDecimaNumberDirective";
import { NoDecimalNumberDirective } from "./components/number/NoDecimalNumberDirective";
import { DecimalMask } from "./components/number/decimal-mask.directive";
import { alphabetNumbersDirective } from "./components/validations/alphabetNumbersDirective";
import { alphabetsDirective } from "./components/validations/alphabetsDirective";
import { numbersDirective } from "./components/validations/numbersDirective";
import { upperCaseDirective } from "./components/validations/upperCaseDirective";
import { DecimalNumbersDirective } from "./components/validations/decimalNumbersDirective";
import { DecimalNumbersWithOrWithoutPercentDirective } from "./components/validations/decimalNumbersWithOrWithoutPercentDirective";
import { LowercaseAlphabetNumbersDirective } from "./components/validations/lowercaseAlphabetWithNumbersDirective";
import { alphabetOrNumbersDirective } from "./components/validations/alphabetOrNumbersDirective";

@NgModule({
  declarations: [
    TextboxComponent,
    FormbuilderComponent,
    ButtonComponent,
    AutoCompleteComponent,
    DropDownComponent,
    CalendarComponent,
    RadioComponent,
    CheckboxComponent,
    CheckListComponent,
    DatepickerComponent,
    AutoComplete2Component, 
    Calendar2Component,
    TextBoxComponent,
    TwoDigitDecimaNumberDirective,
    NoDecimalNumberDirective,
    DecimalMask,
    alphabetNumbersDirective,
    alphabetsDirective,
    numbersDirective,
    upperCaseDirective,
    DecimalNumbersDirective,
    DecimalNumbersWithOrWithoutPercentDirective,
    LowercaseAlphabetNumbersDirective,
    alphabetOrNumbersDirective
  ],
  imports: [
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    ValidatorsModule,
    AutoCompleteModule,
    CalendarModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    TextboxComponent,
    FormbuilderComponent,
    ButtonComponent,
    AutoCompleteComponent,
    DropDownComponent,
    CalendarComponent,
    ReactiveFormsModule,
    RadioComponent,
    CheckboxComponent,
    CheckListComponent,
    DatepickerComponent,
    FormsModule,
    AutoComplete2Component,
    Calendar2Component,
    TextBoxComponent,
    TwoDigitDecimaNumberDirective,
    NoDecimalNumberDirective,
    DecimalMask,
    alphabetNumbersDirective,
    alphabetsDirective,
    numbersDirective,
    upperCaseDirective,
    DecimalNumbersDirective,
    DecimalNumbersWithOrWithoutPercentDirective,
    LowercaseAlphabetNumbersDirective,
    alphabetOrNumbersDirective
  ]
})
export class FormsyModule {}
