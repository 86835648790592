import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ClientPortalService } from '../../services/client-portal.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { FileService } from 'src/app/files/services/file.service';
import { GenericService } from '../../services/generic.service';
import { GenericFileModel } from '../../models/genericFile.model';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { GenericDataListCompComponent } from 'src/app/generic-data/generic-data-list-comp/generic-data-list-comp.component';
import { ClientService } from '../../services/client.service';
import { GenericFileHeaderComponent } from 'src/app/generic-data/generic-file-header/generic-file-header.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { GenericFileParameterComponent } from 'src/app/generic-data/generic-file-parameter/generic-file-parameter.component';


@Component({
  selector: 'app-generic-file-upload',
  templateUrl: './generic-file-upload.component.html',
  styleUrls: ['./generic-file-upload.component.scss']
})
export class GenericFileUploadComponent implements OnInit {

  @ViewChild(GenericFileUploadComponent, { read: false, static: false })
  GenericFileUploadComponent: GenericFileUploadComponent;
  clientApplicationFileForm:FormGroup;
  headerForm:FormGroup;
  currentFileUpload:FileList;
  filesList:any;
  tableList:any;
  currentFileUploadList:FileList;
  visibleLayoutPopUp:boolean=false;
  popUpName:string;
  activeRoute$:Subscription;
  showClientAppFilePopUp:boolean=false;
  clientId:number=0;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  fileTypeList: any = [];
  fileDataTypeList: any = [];
  layoutFieldList: any = [];
  dependentUponList: any = [];
  fieldSeparatorList:{ key: string ; value: string }[];
  dataList:GenericFileModel[]=[];
  fieldList:GenericFileModel[]=[];
  editFieldValue:GenericFileModel;
  databaseDataTypeList:{key: any; value: any }[]=[];
  showTableName:boolean=false;
  fileName:string;
  @Output() genericFileDataId: number;
  genericFileData:number;
  genericTableModel:any;
  showTableDropdown:boolean=false;
  hideTableName:boolean=false;
  clientList:{key: any; value: any }[]=[];
  client:any;
  file:any;
  clientIdValue:any;
  value:any;

   @ViewChild(GenericFileHeaderComponent, { read: false, static: false })
   genericFileHeaderComponent: GenericFileHeaderComponent;

   @Input() showFileUploadHeader:boolean=true;
  // @Output() clientModel=new EventEmitter();
  companyId:any;
  companyName:String;
  clientApplicationFileId:any;
  adminFlag:String;
  @Output() fileDetails=new EventEmitter();

  @ViewChild("panelDateSelector", {static: false })
  panelDateSelector!:SidePanelComponent;

  singleRowData:any;
  sheetName:String=null;
  @ViewChild(GenericFileParameterComponent, { read: false, static: false })
  genericFileParameterComponent: GenericFileParameterComponent;
  state:string;
  constructor(private cookieService: CookieService,private activeRoute: ActivatedRoute,private titleService:TitleService,
    private clientPortalService: ClientPortalService,private toast: ToastService,private loader: LoaderService,
    private fileService:FileService,private genericService:GenericService,private clientService:ClientService, private router: Router) {
      this.headerForm = new FormGroup({
        client: new FormControl(null, [Validators.required]),
        file: new FormControl(null, [Validators.required]),
        tableName: new FormControl(null),
        table: new FormControl(null)
      });

      this.clientApplicationFileForm = new FormGroup({
        fileType: new FormControl(null),
        fileDataType: new FormControl(null),
        layoutFieldId: new FormControl(null),
        dependentUpon: new FormControl(null),
        fieldSeparator: new FormControl(null),
        schedule: new FormControl(null)
      })

      this.state=localStorage.getItem("stateflag");
   }

  ngOnInit(): void {

    this.setTitle();
    this.adminFlag=this.cookieService.get("adminFlag");
    this.getDatabaseDataType();
    this.getApplicationFileType();
    this.getClientApplicationFileTypeData();
    this.getAllSeparators();
//  this.getFileHeaders(1148);
    this.getClients();

   // this.getPreviousState();
  }


  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  maintainClientState(client){
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
   }

   saveData(){
   // console.log(this.fileName);
   // console.log(this.dataList);
    for(let i=0;i<this.dataList.length;i++){
      let testString =this.dataList[i].columnName.split(" ");
      if(testString.length>1){
        return this.toast.showError("error","COLUMN NAME DOSEN'T CONTAIN BLANK SPACE");
      }
    }
    const data={
      "tableCreationMappingList":this.dataList,
      "tableName":this.fileName,
      "clientApplicationFileId":this.headerForm.controls.file.value.key,
      "fileHeaderList":this.fieldList,
      "genericFileData":this.genericFileData,
      "modelData":this.genericTableModel,
      "existingTable":(this.headerForm.controls.table.value == null) ? null : this.headerForm.controls.table.value.value,
      "sheetName":this.sheetName
    }
    this.loader.showLoader();
    this.genericService.saveFileData(data).subscribe(
      (data) => {
       // this.layoutFields = data;
        this.genericFileParameterComponent.isDisabled=true;
        console.log("before" + data);
        const genericData=data["genericFileData"];
        this.getFileHeaders(this.headerForm);
        this.getAllExisistingTables();
      
       // this.clientModel.emit(this.headerForm);
        //this.loader.hideLoader();
       if(genericData!==0){
        this.toast.showSuccess("Table Updated Successfully", "success");
       }else{
        this.toast.showSuccess("Table Created Successfully", "success"); 
       }
       this.router.navigate(["data/ingest/generic-file-upload"],{queryParams: {step:2}});
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader();
      }
    );
  }

   updateField(data){
    this.singleRowData=data;
    if(data.layoutFieldId!=268){
    for(let i=0;i<this.dataList.length;i++){
      if(this.dataList[i].headerName===data.headerName){
        this.dataList[i].databaseTypeId=data.layoutFieldId;
        this.dataList[i].isUpdated=1;
        this.dataList[i].headerPattern=null;
      }
    }
  }
  else{
    for(let i=0;i<this.dataList.length;i++){
      if(this.dataList[i].headerName===data.headerName){
        this.dataList[i].databaseTypeId=data.layoutFieldId;
        this.dataList[i].isUpdated=1;
        this.dataList[i].headerPattern=null;
      }
    }
this.panelDateSelector.toggleSlider();
  }
    console.log("update field ",this.dataList);
   }


   updateDate(rowData){
   console.log("date pattern ",rowData.fieldFormat)
   for(let i=0;i<this.dataList.length;i++){
    if(this.dataList[i].headerName===this.singleRowData.headerName){
      this.dataList[i].databaseTypeId=this.singleRowData.layoutFieldId;
      this.dataList[i].isUpdated=1;
      this.dataList[i].headerPattern=rowData.fieldFormat;
    }
  }
  console.log("update field date",this.dataList);
   }

   doAddFileAction(type){
    if (type === "yes") {
      console.log("helloo");
      this.visibleLayoutPopUp = false;
    //  this.modalForm.reset();
    this.clientApplicationFileForm.reset();
    this.currentFileUpload=null;
    this.myInputVariable.nativeElement.value = "";
    }else{
      this.clientApplicationFileForm.reset();
      this.visibleLayoutPopUp = false;
    }
  }

  onDateChange(e){
    // console.log(e);
    // const asOfDate1=this.datePipe.transform(e,'yyyy-MM-dd');
    // console.log(asOfDate1);
    this.headerForm.controls.asOfDate.setValue(e);
  }

  selectClientApplicationFile(event){
    this.currentFileUpload = event.target.files;
  }

  getClientApplicationFileTypeData(){
    this.fileService.getClientApplicationFileType(this.state).subscribe(
      (data) => {
        const clientApplicationFileType=data;
        for(var a=0;a<clientApplicationFileType.length;a++){
         // console.log("type "+clientApplicationFileType[a].type);
          this.fileTypeList.push({ key: clientApplicationFileType[a].clientApplicationFileTypeId, value: clientApplicationFileType[a].type });
        }
      },
      (err) => {
      //  this.toast.showSuccess("Status Not Changed", "error");
      this.toast.showError(err.error.message, "error");
      }

    );

  }

  getApplicationFileType(){
    this.fileService.getApplicationDataTypeByUser().subscribe(
   (data) => {
     const fileDataType=data;
     for(var a=0;a<fileDataType.length;a++){
     // console.log("type "+fileDataType[a].type);
       this.fileDataTypeList.push({ key: fileDataType[a].clientApplicationFileDataType, value: fileDataType[a].type });
       }
   },
   (err) => {
   //  this.toast.showSuccess("Status Not Changed", "error");
   this.toast.showError(err.error.message, "error");
   }

 );

 }

 getLayoutField(clientId){
  this.fileService.getAppFilesByClientId(clientId).subscribe(
    (data) => {
      const layoutFieldFile=data;
      for(var a=0;a<layoutFieldFile.length;a++){
      // console.log("type "+layoutFieldFile[a].fileName);
        this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }

  );

}

getDependentUponData(clientId){
  this.fileService.getDependentUpon(clientId).subscribe(
    (data) => {
      const dependentUpon=data;
      for(var a=0;a<dependentUpon.length;a++){
        console.log("type "+dependentUpon[a].fileName);
        this.dependentUponList.push({ key: dependentUpon[a].clientApplicationFileId, value: dependentUpon[a].fileName });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }

  );

}
getAllSeparators(){
  this.fieldSeparatorList= [
   { key: ',', value: "Comma" },
   { key: 'space', value: "Space" },
   { key: '|', value: "Pipe(|)" },
   { key: '"', value: "Quotes" },
   { key: ';', value: "Semicolon" },
   { key: ':', value: "Colon"},
   { key: '\t', value: "Tab" }
 ];
}

 saveClientApplicationFile(){
  if(this.clientApplicationFileForm.valid){
  if(this.currentFileUpload===null ||this.currentFileUpload===undefined){
    this.toast.showWarn('no file selected','error');
    return;
  }
  // if(this.clientId===0){
  //   this.clientId=this.headerForm.controls.client.value.key;
  //    }

    const ClientAppliactionFileConst = {
     "clientId": this.clientId,
     "clientApplicationFileTypeId": this.clientApplicationFileForm.controls.fileType.value.key,
     "dependentUpon":this.clientApplicationFileForm.controls.dependentUpon.value,
     "schedule":this.clientApplicationFileForm.controls.schedule.value,
     "fieldSeparator":this.clientApplicationFileForm.controls.fieldSeparator.value,
     "clientApplicationFileDataTypeId":this.clientApplicationFileForm.controls.fileDataType.value.key,
     "layoutFieldId":this.clientApplicationFileForm.controls.layoutFieldId.value
   };
   this.fileService.saveClientApplicationFile(this.currentFileUpload,ClientAppliactionFileConst,this.state) .subscribe(
    data=>{
     // this.getFileHeaders(data);
      this.toast.showSuccess('Success','File Uploaded Successfully');
      this.currentFileUpload=null;
      this.myInputVariable.nativeElement.value = "";
      this.visibleLayoutPopUp=false;
      this.clientApplicationFileForm.reset();
      this.loader.hideLoader();
      window.location.reload();

    },
    error=>{
      this.toast.showError(error.error.message, "error")
      this.loader.hideLoader();
    },

  );
 }else{
  this.toast.showError('Please Fill the Mandatory Fields','Error')
 }
}

showPopupAddFile(data) {
  this.showClientAppFilePopUp=true;
  this.popUpName="Upload Layout File";
  this.visibleLayoutPopUp = true;

  if(this.headerForm.controls.client.value>0){
    this.clientId=this.headerForm.controls.client.value;
     }else{
      this.clientId=this.headerForm.controls.client.value.key;
     }
  this.getLayoutField(this.clientId);
  this.getDependentUponData(this.clientId);
}

getFileHeaders(form){
  console.log("form   ",form);
  this.fileDetails.emit(form);
  this.cookieService.set('tabbedClientId',form.controls.client.value.key);
  this.cookieService.set('tabbedFileId',form.controls.file.value.key);
  this.cookieService.set('tabbedClientValue',form.controls.client.value.value);
  // this.cookieService.set('tabbedForm',form.value);
  const value=form.controls.file.value.key;
  console.log("value   ",form);
  this.headerForm=form;
  this.sheetName=form.controls.sheet.value.value;
  let headerRow = form.controls.headerRow;
  if (headerRow && headerRow.value.value) {
    const value = headerRow.value.value;
    headerRow = value === 'Other' ? (form.value.other > 0 ? form.value.other : 1) : value;
  } else {
    headerRow = 1;
  }
  const model={
    genericapplicationfileId:value,
    tableName:form.controls.tableName.value,
    sheetName:form.controls.sheet.value.value,
    headerRow
  }
  this.genericService.readFileHeaders(model).subscribe(
    data=>{
      this.fieldList=data["fileHeaderList"];
      this.dataList=this.fieldList;
      this.genericFileData=data["genericFileDataId"];
      this.genericTableModel=data["modelData"];
      this.showTableName=true;
      let toArray =  form.controls.file.value.value.split(".");
      this.fileName=data["tableName"];
      this.client=form.controls.client.value.value;
      this.clientIdValue=form.controls.client.value.key;
      this.file=form.controls.file.value.value;
      this.genericFileDataId = this.genericFileData;
      // if(this.fileName===null){
      //   this.fileName=data["exixtingtableName"];
      // }
      console.log("data    ::"+this.clientId,this.value);
      this.getAllExisistingTables();

    },
    error=>{
      this.toast.showError(error.error.message, "error")
     // this.loader.hideLoader();
    },

  );
}


getDatabaseDataType(){
  this.genericService.getAllDatabaseDataTypeList().subscribe(
    data=>{
      const dataValues=data;
      this.databaseDataTypeList = reMapForAutoComplete(dataValues, "databaseDataTypeId", "dataTypeName");


    },
    error=>{
      this.toast.showError(error.error.message, "error")
      this.loader.hideLoader();
    },

  );
}


//  getFieldLayout(id) {
//     this.loader.showLoader();
//     this.fileService.getFileLayoutById(id).subscribe(
//       (data) => {
//         this.layoutFields = data;
//         console.log("before" + data);
//         console.log(this.layoutFields);
//         this.loader.hideLoader();
//       },
//       (err) => {
//         this.toast.showError(err.error.message, "error");
//         this.loader.hideLoader();
//       }
//     );
//   }

 // onClientChange(event){
  //this.getFieldLayout(this.headerForm.controls.client.value.key);
 // this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
  //this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
  //this.maintainClientState(event,this.headerForm.controls.fromDate.value, this.headerForm.controls.toDate.value);
//}

 getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');

    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
                this.companyName=clientNameC
    }
    this.getFiles();
    // this.getFieldLayout(this.headerForm.controls.client.value.key);

}

getFiles() {
    this.clientId = this.headerForm.value.client.key;
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
  }

editField(data: GenericFileModel) {
    this.editFieldValue = data;
   // console.log(data);
  }


checkedExistingTable(event){
  console.log(event.target.value);
 if(this.showTableDropdown){
  this.showTableDropdown=false;
  this.hideTableName=false;
 }else{
  this.showTableDropdown=true;
  this.hideTableName=true;
 }
}

getAllExisistingTables() {
  this.clientId = this.headerForm.value.client.key;
  this.genericService.getAllExisistingTables().subscribe(
    (data) => {
      this.tableList = reMapForAutoComplete(
        data,
        "id",
        "tableName"
      );
      //this.loader.hideLoader();
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
   // this.loader.hideLoader()
    }
  );
}


getAllFiles() {
  this.clientId = this.headerForm.value.client.key;
  this.fileService.getClientFile(this.clientId).subscribe(
    (data) => {
      this.filesList = reMapForAutoComplete(
        data,
        "clientFileId",
        "fileName"
      );
      this.getAllExisistingTables();
      this.loader.hideLoader();
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
    this.loader.hideLoader()
    }
  );
}

getClients() {
    //  this.loader.showLoader();
      this.clientService.getAllClients().subscribe(
        (data) => {
         // this.loader.hideLoader();
          this.clientList = reMapForAutoComplete(data, "clientId", "clientName");
        },
        (err) => {
     //     this.loader.hideLoader();
          this.toast.showError(err.error.message, "error");
        }
      );
    }

    updateClient(event){
      this.headerForm=event;
      this.clientIdValue=event.client.key;
      this.client=event.client.value;
    }

    callGenericToggle(){
      console.log("Form toggle ");
      //this.workflowJobListEdit=null;
      //this.GenericFileUploadComponent;
      this.genericFileHeaderComponent.panelForm.toggleSlider();
    }
    callUploadToggle(){
      this.genericFileHeaderComponent.panelForm.toggleSlider();
    }

}
