import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { FormsyModule } from "../formsy/formsy.module";
import { UiModule } from "../ui/ui.module";
import { PickListModule } from "primeng/picklist";
import { FormsModule }   from '@angular/forms';
import { DbConnListComponent } from "./db-conn-list/db-conn-list.component";
import { DbConnSelectorComponent } from './db-conn-selector/db-conn-selector.component';
import { SparkLauncherSelectorComponent } from './spark-launcher-selector/spark-launcher-selector.component';
import { DbConnHeaderComponent } from './db-conn-header/db-conn-header.component';


@NgModule({
    declarations: [
      DbConnListComponent,
      DbConnSelectorComponent,
      SparkLauncherSelectorComponent,
      DbConnHeaderComponent


    ],
    imports: [SharedModule, FormsyModule, UiModule, PickListModule,FormsModule],
    exports: [
      DbConnListComponent,
      DbConnSelectorComponent,
      SparkLauncherSelectorComponent,
      DbConnHeaderComponent
    ],
  })
  export class DBConnModule {}
