import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DbConnDataModel } from 'src/app/dashboard/models/dbconnection.model';

@Component({
  selector: 'app-db-conn-list',
  templateUrl: './db-conn-list.component.html',
  styleUrls: ['./db-conn-list.component.scss']
})
export class DbConnListComponent implements OnInit {

  @Input() data:DbConnDataModel[];
  @Input() hidebutton:boolean;
  @Output() editData=new EventEmitter();
  @Output() delete= new EventEmitter();
  @Input() permission:any;
  cols: { field: string; header: string }[];
  constructor() {
    this.cols = [
      { field: "serviceName", header: "Service Name" },
      { field: "connectionType", header: "Database" },
      { field: "connectionUrl", header: "Connection Url" }
    ];
  }

  ngOnInit(): void {
  }

  editClick(data){
    console.log("edit data ***********",data);
    this.editData.emit(data);
  }

}
