import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { VideoUploadSelectorComponent } from './components/video-upload-selector/video-upload-selector.component';
import { VideoUploadTableComponent } from './components/video-upload-table/video-upload-table.component';



@NgModule({
  declarations: [
    VideoUploadSelectorComponent,
    VideoUploadTableComponent

  ],
  imports: [
    SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [

    VideoUploadSelectorComponent,
    VideoUploadTableComponent
   
    ]
})
export class VideoUploadCompModule { }
