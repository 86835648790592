import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reportConfiguration-table',
  templateUrl: './reportConfiguration-table.component.html',
  styleUrls: ['./reportConfiguration-table.component.scss']
})
export class ReportConfigurationTableComponent implements OnInit {
 @Input() reportConfigurationList:any[];
 @Input() rolePermission:any;
 @Output() edit = new EventEmitter();
 @Output() delete = new EventEmitter();
 dataValue:any;
 visibleDelete: boolean = false;

 cols: { field: string; header: string }[];
  constructor() {
    this.cols = [
      // { field: "reportConfigurationId", header: "Report Configuration Id" },
      // { field: "clientId", header: "Client Id"},
      // { field: "reportId", header: "Report Id" },
      // { field: "returnName", header: "Return Name" },
      // { field: "returnCode", header: "Return Code" },
      // { field: "nameOfReportingInstitution", header: "Name Of Reporting Institution" },
      // { field: "bank_fICode", header: "Bank FI Code" },
      //{ field: "institutionType", header: "Institution Type" },
      { field: "reportingFrequency", header: "Reporting Frequency" },
      { field: "reportingStartDate", header: "Reporting Start Date" },
      { field: "reportingEndDate", header: "Reporting End Date" },
      { field: "reportingCurrency", header: "Reporting Currency" },
      { field: "reportingScale", header: "Reporting Scale" },
      { field: "scaleValue", header: "Scale Value" },
      // { field: "taxonomyVersion", header: "Taxonomy Version" },
      // { field: "toolName", header: "ToolName" },
      // { field: "toolVersion", header: "Tool Version" },
      //{ field: "reportStatus", header: "Report Status" },
      // { field: "dateOfAudit", header: "Date Of Audit" },
      // { field: "generalRemarks", header: "General Remarks" }
    ];
   }

  ngOnInit(): void {
  }

  editReportConfiguration(rowData){
  this.edit.emit(rowData);
  }


  showPopupDelete(rowData) {
        this.dataValue = rowData;
        this.visibleDelete = true;
      }
    
      hidePopup() {
        debugger;
        this.dataValue = null;
        this.visibleDelete = false;
      }
    
      actionDo(str){
        this.deleteCategoryId(this.dataValue);
        this.dataValue=null;
        this.visibleDelete=false;
      }
    
      deleteCategoryId(rowData){
        this.delete.emit(rowData);
      }
}
