import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-stressScenario-selector',
  templateUrl: './stressScenario-selector.component.html',
  styleUrls: ['./stressScenario-selector.component.scss']
})
export class StressScenarioSelectorComponent implements OnInit {
  @Output() save=new EventEmitter();
  @Output() closePanel=new EventEmitter();
  stressScenarioForm: FormGroup = null;
  @Input() editData:any[];

  scenarioNameError:String=null;
  descriptionNameError:String=null;

  constructor(private toast:ToastService,private datePipe: DatePipe) {

    this.stressScenarioForm=new FormGroup({
      stressScenarioId:new FormControl(null),
      stressScenarioName: new FormControl(null,{validators:[Validators.required,Validators.minLength(5),Validators.maxLength(250)]}),
      statusMap: new FormControl(null,{validators:[Validators.required]}),
      description:new FormControl(null,{validators:[Validators.maxLength(500)]}),    
    });
  
   }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
  this.stressScenarioForm.get('statusMap').setValue(defaultMap);
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);
  
      this.stressScenarioForm.patchValue(
        {
          stressScenarioId:rowData.stressScenarioId,
          stressScenarioName:rowData.stressScenarioName,
          description:rowData.description,
                   
        });

        const map1 = {
          key: "Active",
          value: "Active",
        };
      
        const map2 = {
          key: "Inactive",
          value: "Inactive",
        };
      
      rowData.status==true? this.stressScenarioForm.controls.statusMap.setValue(map1):this.stressScenarioForm.controls.statusMap.setValue(map2);

        
    }
  
  }
  


  saveStressScenario(flag){
  console.log("this.report configuration form value ",this.stressScenarioForm.value)
   if(flag=='onLoad'){

    if(this.stressScenarioForm.valid){
      let statusMap=this.stressScenarioForm.controls.statusMap.value
      console.log("statusMap",statusMap)
      if (statusMap=="Select Status *") {
        this.toast.showError("Please Fill Required Fields","error");
      }
      if(statusMap!=="Select Status *"){
      console.log("stressScenarioFormValid",this.stressScenarioForm.valid);

           this.save.emit(this.stressScenarioForm.value);
      }
             }
               else{
                 this.toast.showError("Please Fill Required Fields","error");
             }
   
  this.closePanel.emit();
    }

    }

    formReset(){
      this.stressScenarioForm.reset();
      const defaultMap = {
        key: "Active",
        value: "Active",
      };
     this.stressScenarioForm.get('statusMap').setValue(defaultMap);
    }

    get status() {
      const status = [
        "Active",
        "Inactive",
      ];
     return status.map((t) => ({ key: t, value: t }));
    }
 
}
