import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { FileService } from 'src/app/files/services/file.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { ClientService } from 'src/app/dashboard/services/client.service';
import { CookieService } from 'ngx-cookie-service';
import { Console, error } from 'console';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { FieldLayoutModel } from 'src/app/dashboard/models/file-layout.model';

@Component({
  selector: 'app-generic-file-parameter',
  templateUrl: './generic-file-parameter.component.html',
  styleUrls: ['./generic-file-parameter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericFileParameterComponent {
  //@Input() groupList: {key: any; value: any }[]=[];
  reportList:{key: any; value: any }[]=[];
   @Input() clientList:{key: any; value: any }[]=[];
   @Output() getHeaders = new EventEmitter();
   @Input() dataList:any;
   @Input() genericFileData:any;
   @Input() genericFileDataId:any;
   @Input() tableName:any;
   @Input() tableList:any;
   @Output() closePanel=new EventEmitter();
   @Output() clientData=new EventEmitter();
  parameterForm:FormGroup;
  url:string=null;
  env:any;
  viewList:any=[];
  headerRows = [];
  filesList:{ key: any; value: any }[] = [];
  clientsList:{ key: any; value: any }[] = [];
  showTableDropdown:Boolean=false;
  hideTableName:Boolean=false;
  hideOtherField:Boolean=true;
  companyId:any;
  companyName:String;
  clientApplicationFileId:any;
  adminFlag:String;
  fileName:string;
  enableEdit:string='';
  alias: string = '';
  genericFileIdVar: number;
  layoutFields: FieldLayoutModel[];
  sheets: { key: string | number; value: string }[] = [];
  isDisabled :boolean=true;
  constructor(private reportDashboardService:ReportDashboardService,
    private sspApplicationService: SspApplicationService,private fileService:FileService,private toast:ToastService,private cookieService: CookieService,
    private loader: LoaderService) {
    this.parameterForm = new FormGroup({
      client:new FormControl(null, [Validators.required]),
      file:new FormControl(null, [Validators.required]),
      sheet: new FormControl(null),
      table:new FormControl(null, [Validators.required]),
      tableName:new FormControl(null, [Validators.required]),
      headerRow: new FormControl(null, [Validators.required]),
      other: new FormControl(null, [Validators.pattern('^[0-9]$')])
     });
     this.createHeaderRows(11);
  }

  ngOnInit(): void {
   
    this.adminFlag=this.cookieService.get("adminFlag");
    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
           for(var i=0;i<data.length;i++){
              console.log("data  ",data[i].clientId)
              this.companyId=data[i].clientId
              this.companyName=data[i].clientName
               }
           this.parameterForm.get("client").setValue({
              key: this.companyId,
              value: this.companyName
              });
        this.cookieService.set('genericClientId',this.companyId);
    
      // console.log("this.headerForm",this.headerForm.value)
       this.fileService.getGenericClientFile(this.parameterForm.controls.client.value.key).subscribe(
            (data) => {
                     this.filesList = reMapForAutoComplete(
                     data,
                    "clientFileId",
                    "fileName"
                               );
                   //  this.showType=true;
                  //   this.loader.hideLoader();
                     for(var i=0;i<this.filesList.length;i++){
                        console.log("this.fileList  ",this.filesList[i])
                       this.clientApplicationFileId=this.filesList[i].key
                       this.fileName=this.filesList[i].value
                       }
  
             this.parameterForm.get("file").setValue({
                  key:this.clientApplicationFileId,
                  value:this.fileName
                  })
        
         console.log("this.headerForm.file.value  ",this.parameterForm.value)
         this.getFileLayout(this.parameterForm.controls.file.value.key);
        // this.getFileHeaders("onInit");
        },
        (err) => {
          //this.showType=false;
          this.toast.showError(err.error.message, "error");
          //this.loader.hideLoader()
        }
      );
      
      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
  
      );
      console.log("TableName: ", this.tableName)
  }

  ngOnChanges(changes: SimpleChanges) {
   this.genericFileIdVar = this.genericFileDataId;
   this.parameterForm.get('tableName').setValue(null);
   console.log("genericFileIdVar: ",this.genericFileIdVar);
  }


  // getAllFiles() {
  //   if(this.parameterForm.controls.client.value===null){
  //     return this.toast.showWarn("select Client first","warn");
  //   }
  //   this.fileService.getFilesByClientId(this.parameterForm.controls.client.value.key).subscribe(
  //     (data) => {
  //       this.filesList = reMapForAutoComplete(
  //         data,
  //         "clientApplicationFileId",
  //         "fileName"
  //       );
  //    //   this.loader.hideLoader();
  //   this.clientData.emit(this.parameterForm.value); 
  //   },
  //     (err) => {
  //       this.toast.showError(err.error.message, "error");
  //    // this.loader.hideLoader()
  //     }
  //   );
  // }


  getAllFiles() {
    if(this.parameterForm.controls.client.value===null){
      return this.toast.showWarn("select Client first","warn");
    }
    this.fileService.getGenericClientFile(this.parameterForm.controls.client.value.key).subscribe(
      (data) => {
               this.filesList = reMapForAutoComplete(
               data,
              "clientFileId",
              "fileName"
                         );
     //   this.loader.hideLoader();
    this.clientData.emit(this.parameterForm.value); 
    },
      (err) => {
        this.toast.showError(err.error.message, "error");
     // this.loader.hideLoader()
      }
    );
  }

  getFileHeaders(flag){
    if(flag=='onLoad'){
    this.closePanel.emit();
    }
    this.getHeaders.emit(this.parameterForm);
    console.log("Headers: ", this.getHeaders);
  }

  checkedExistingTable(event){
    console.log("On Select:",event.target.value);
   if(this.showTableDropdown){
    this.showTableDropdown=false;
    this.hideTableName=false;
   }else{
    this.showTableDropdown=true;
    this.hideTableName=true;
   }
  }

  update(type,alias,tableName) {
    // console.log("data "+i);
    console.log("data "+tableName);
    console.log("data "+alias);
    console.log("Table list: "+this.tableList)
   if(!this.enableEdit && type!='save'){
    this.enableEdit = tableName;
   }
   else if(this.enableEdit && type==='save'){
  //  for(let i=0;i<this.data.length;i++){
     if(tableName===this.enableEdit){
       
       var format = /[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
       if(format.test(alias)){
        this.enableEdit = '';
        this.closePanel.emit();
        return this.toast.showError("You can not use special character except  _(underscore) ", "error"); 
       }else
       {
        if(alias.substring(0,1)=='_' || (alias.substring(0,1)=='0 '||  
        alias.substring(0,1)=='1' ||
        alias.substring(0,1)=='2' ||
        alias.substring(0,1)=='3' ||
        alias.substring(0,1)=='4' ||
        alias.substring(0,1)=='5' ||
        alias.substring(0,1)=='6' ||
        alias.substring(0,1)=='7' ||
        alias.substring(0,1)=='8' ||
        alias.substring(0,1)=='9' )){
        this.enableEdit = '';
        this.closePanel.emit();
        return this.toast.showError("Table name should be start only from a to z alphabets", "error");
        }
        this.tableName=alias;
        this.parameterForm.get('tableName').setValue(alias);
       }
      // this.tableName.isUpdated=1;
      }
      console.log("save  data" +alias);
   }else{
    this.enableEdit = '';
   }
  }



  getFileLayout(id) {
  
    const { client,file } = this.parameterForm.value;
    this.parameterForm.get('sheet').setValue({
      key:null,
      value:null
    });
    
    this.sheets = this.layoutFields = [];
    this.fileService.getClientFileByClientFileId(file.key).subscribe(
      (res) => {
        console.log("Clinet File data ",res);
        this.fileService.getFileLayout(client.key,res.clientApplicationFileId).subscribe(
          (data) => {
            console.log("file layout data ",data)
            if (data === null) {
              this.getSheets(client.key,res.clientApplicationFileId);
             
              //this.toast.showInfo("No Layout defined", "Select a sheet");
            }
          else{
           // this.isDisabled=true;
           document.getElementById("sheetShow").style.display = 'none';
            this.getFileHeaders("onInit");
            
          }},
            (err)=>{
              
            })
      },
      (err)=>{}
      )
  }


        getSheets(client, id) {
          this.sheets = [];
          // this.loader.showLoader();
          this.fileService.getFileSheets(client, id).subscribe(
            (data) => {
              console.log(data);
              
              this.sheets = reMapForAutoComplete(data, "sheetName", "sheetName");
              console.log("sheets.length ",this.sheets.length)
               
              this.parameterForm.get('sheet').setValue({
                  key: this.sheets[0].key,
                  value: this.sheets[0].value,
                  });
                  //this.isDisabled=false;
                  document.getElementById("sheetShow").style.display = '';

              console.log("this.parameterForm  ",this.parameterForm.value)
              this.getFileHeaders("onInit");
            },
            (err) => {
              this.toast.showError(err.error.message, "error");
        //      this.loader.hideLoader();
            }
          );
        }
  
  createHeaderRows(count) {
    for (let i = 1; i < count; i++) {
      this.headerRows.push({ key: i, value: i });
    }
    this.headerRows.push({ key:'other', value: 'Other'});
  }

  headerHandler() {
    const headRow = this.parameterForm.controls.headerRow;
    if (headRow && headRow.value.value === 'Other') {
      this.hideOtherField = false;
    } else {
      this.hideOtherField = true;
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
