import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from 'rxjs';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { TableViewerService } from '../../services/table-viewer.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-table-viewer',
  templateUrl: './table-viewer.component.html',
  styleUrls: ['./table-viewer.component.scss']
})
export class TableViewerComponent implements OnInit {
  dataTransformationModel:any;
  tableColumns:any;
  tableData:any;
  databaseName:string=null;
  tableName:string=null;
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  activeRoute$:Subscription;
  dataValues:any;
  headerForm:FormGroup;
  searchQuery:any;
  pagenationCount:any;
  selectedReportType:any;
  displayFilters:boolean=true;
  state:string;
  constructor(private activeRoute:ActivatedRoute,
    private tableViewerService:TableViewerService,private toast :ToastService){
    this.headerForm = new FormGroup({
      pageNumber:new FormControl(null),
      pageSize:new FormControl(null)
     });
     this.state=localStorage.getItem("stateflag");
  }

  ngOnInit(){
    // this.activeRoute.queryParams
    //   .subscribe(params => {
    //     console.log(params); // { orderby: "price" }
    //     this.searchQuery = params.page;
    //     console.log(this.searchQuery); // price
    //     if(this.searchQuery>1){
    //       this.searchQuery=Number(this.searchQuery)*10-10;
    //       this.getDataValues(this.searchQuery,10)
    //     }else{
    //       this.getDataValues(this.searchQuery,10)
    //     }
    //   }
    // );


    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
        if (par.has("tableName")) {
          this.displayFilters=false;}

})
  }

  // navigateToPage(data){
  //   console.log(data);
  //  // this.dataTransformationModel=data;
  //   const dataValue={
  //    "roleId": data.roleId,
  //    "roleName":data.roleName
  //   }
  //   let page = '/role';
  //       this.router.navigate([page], {
  //        queryParams: {...dataValue},
  //      });
  //  }
   
  getData(data){
    this.databaseName=data.form.databaseName.value.value;
    this.tableName=data.form.tableName.value.value;
    this.tableColumns=data.tableColumns;
    this.tableData=data.tableData;
  }

  getDataValues(pageNumber:Number,pageSize:Number){
   if(this.tableName===null){
    return;
   }
    const data={
      "tableName":this.tableName,
      "databaseName":this.databaseName,
      "pageNumber":pageNumber,
      "pageSize":pageSize
    }
    this.tableViewerService.getData(data).subscribe(
      data =>{
        this.dataValues=data["tableColumn"];
        this.tableData=data["tableData"];
        this.pagenationCount=data["countSet"];
        console.log(this.dataValues)
        // const dataValues={
        //     "form":this.parameterForm.controls,
        //     "tableColumns":this.dataValues,
        //     "tableData":this.tableData
        // }
        // this.fetch.emit(dataValues);
      },
      error=>{
           this.toast.showInfo("NO VALUE PRESENT","info");
      }

    )
  }
}
