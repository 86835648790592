import { Component, OnInit, Output, ViewChild,EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from 'src/app/core/services/title.service';
import { UserService } from 'src/app/user/services/user.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { RoleService } from 'src/app/dashboard/services/role.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { Subscription } from 'rxjs';
import { RoleMenuType } from 'src/app/dashboard/pages/role-menu-permission/role-menu';
import { ActionModelForRole } from 'src/app/dashboard/models/action.model';

@Component({
  selector: 'app-rolelist-table',
  templateUrl: './rolelist-table.component.html',
  styleUrls: ['./rolelist-table.component.scss']
})
export class RolelistTableComponent implements OnInit {

  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  activeRoute$: Subscription;
  @Input() roleLists:any;
  cols: { field: string; header: string }[];
  visible:boolean=false;
  headerData:any;
  roleMenuType: RoleMenuType[];
  targetData: { key: string | number; value: string }[];
  roleId:number
  actionModelForRole:ActionModelForRole[];
  @Output() redirectToRolePage=new EventEmitter();
 
  constructor(private titleService:TitleService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private toast:ToastService,
    private rolesService: RoleService) {



      this.cols = [
        { field: "roleName", header: "Role Name" },
        { field: "roleDescription", header: "Description" },
        { field: "roleStatus", header: "Status" },
    
      ];




     }

  ngOnInit(): void {
    this.setTitle();
   // this.getRoles();
  }



  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }


  getRoles(){
    this.userService.getRoleListData().subscribe(
      (data) => {
        this.roleLists=data;
        console.log("rolefrombackend ",data)
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  
  actionDo(data){
    
    
    console.log("roleData",data)
    this.rolesService.setRoleStatus(data).subscribe(
      (data) => {

      
        this.toast.showSuccess("Done", "successfully");
    
       this.getRoles();
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );  
  }

  getRolePageMenuData(data) {
    // this.roleMenuType=[];
    // let clientId = data.roleId;
    // this.rolesService.getRolePageMenuData(clientId).subscribe(
    //   (response: RoleMenuType[]) => {
    //     if (response.length > 0) {
    //       this.roleMenuType = response;
    //       this.panelForm.toggleSlider()
    //       console.log('RoleMenuType-', response);
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    this.redirectToRolePage.emit(data);
  }

getAllRules(data) {
    console.log(data);
    // this.http.get(`${environment.baseUrl}/users/getAllRoles`);
   this.roleId=data.roleId;
   const role={
    "roleId":this.roleId
   }

   this.rolesService.getRoleActions(role)
   .subscribe(
     (data : ActionModelForRole[]) => {
       console.log("data" +data)
       this.actionModelForRole=data;
      // data.get("action");
       //this.targetData = reMapForAutoComplete(data as any[],"pageName","actionGroup");
       this.getRolePageMenuData(data);
     },
     (err) => {
       this.toast.showError(err.error.message, 'error');
     }
   );
}
 

saveRoleData(data) {
  console.log("role id" ,data);
  const actionListData=data;
  
 const PageMenu = this.getSelectedPageId();
 // console.log("pageMenu-", PageMenu);
  // const ActionData={
  //  "roleId":this.roleId,
  //  "actionGroup":this.actionList

  // }

  const rolePageRequestModel={
    //"actionsModel":ActionData,
    "roleMenuRequestModelList":PageMenu
  }
  this.rolesService.saveAllActions(rolePageRequestModel).subscribe(
    (data) => {
     // this.actionList=[];
      this.toast.showSuccess("Action SuccesFully Saved", "success");
      // this.store.select('user').pipe(take(1))
      // .subscribe(u => this.user = u);
      //   this.getUserDetails()
      
      //   this.buttonShowHidePermission("onChange");
         window.location.reload();
       
         
    },
    (err) =>{
      this.toast.showError(err.error.message, "error");
    } 
  );
}


getSelectedPageId() {
  const PageMenu = [];
  if (this.roleMenuType && this.roleMenuType.length > 0) {
    //this.roleMenuType.forEach((x) => {
    for(let i=0;i<this.roleMenuType.length;i++){
      if (this.roleMenuType[i].submenu && this.roleMenuType[i].submenu.length > 0) {
        for(let j=0;j<this.roleMenuType[i].submenu.length;j++){
        //x.submenu.forEach((a) => {
        if(this.roleMenuType[i].submenu[j].submenu===null){
          if (this.roleMenuType[i].submenu[j].assinged) {
            PageMenu.push({ 'pageName': this.roleMenuType[i].submenu[j].pageName, 'pageId': this.roleMenuType[i].submenu[j].pageId })
          }
        } else{
          for(let k=0;k<this.roleMenuType[i].submenu[j].submenu.length;k++){
            if (this.roleMenuType[i].submenu[j].submenu[k].assinged && this.roleMenuType[i].submenu[j].submenu[k].pageId>0) {
              PageMenu.push({ 'pageName': this.roleMenuType[i].submenu[j].submenu[k].pageName, 'pageId': this.roleMenuType[i].submenu[j].submenu[k].pageId })
            }
          }
        }
      }
      }
      // else {
      //   x.subMenuAssigList.forEach((a) => {
      //     if (a.menuPageAssignList && a.menuPageAssignList.length > 0) {
      //       a.menuPageAssignList.forEach((b) => {
      //         if (b.assinged) {
      //           PageMenu.push({ 'pageName': b.pageName, 'pageId': b.pageId })
      //         }
      //       })
      //     }
      //   })
      //   }
      }
    }
  return PageMenu;
}
}
