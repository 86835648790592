import { Component, OnInit, Input ,EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-gl-mapping-table',
  templateUrl: './gl-mapping-table.component.html',
  styleUrls: ['./gl-mapping-table.component.scss']
})
export class GlMappingTableComponent implements OnInit {

  @Input() data:any[];

  @Output() edit=new EventEmitter();

  @Output() delete=new EventEmitter();
  dataValue:any;
  visibleDelete: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }



  editMappinpData(data){
   this.edit.emit(data);
  }

  showPopupDelete(rowData) {
    this.dataValue = rowData;
    this.visibleDelete = true;
  }

  hidePopup() {
    debugger;
    this.dataValue = null;
    this.visibleDelete = false;
  }

  actionDo(str){
    this.deleteMapping(this.dataValue);
    this.dataValue=null;
    this.visibleDelete=false;
  }

  deleteMapping(data){
    this.delete.emit(data);
  }

}
