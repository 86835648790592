import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class YeildAdavancesService {
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}

  saveOrUpdate(data) {
    return this.http.post<any>(
      environment.baseUrl + "/apis/createStressVariables",
      data
    );
  }

  getAllStressVariables() {
    return this.http.get<any[]>(
      environment.baseUrl + "/apis/fetchAllStressVariables"
    );
  }

  deleteStressVariables(id) {
    return this.http.delete<any>(
      environment.baseUrl + "/apis/deleteStressVariables?id="+id
    );
  }

  getAllIrrMduration(){
    return this.http.get<any[]>(
      environment.baseUrl + "/apis/fetchAllIrrMduration"
    );
  }

  saveOrUpdateIrrMduration(data) {
    return this.http.post<any>(
      environment.baseUrl + "/apis/updateIrrMduration",
      data
    );
  }
}