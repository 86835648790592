import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SspGroupModel } from '../models/group.model';

@Injectable({
  providedIn: 'root'
})
export class ReportDashboardService {

  constructor(private http: HttpClient) { }



  getAllGroups(){

    return this.http.get<any>(
      `${environment.baseUrl}/apis/ssp/getAllReportGroup`
    );
  }
  
  getReportByGroupId(data){
  
    return this.http.post<any>(
      `${environment.baseUrl}/apis/ssp/getByGroupId`,data
    );
  }

  getReportsByGroupData(data){

     //return this.http.post<SspGroupModel[]>(environment.baseUrl + "/apis/ssp/getReportsByGroup",data);
    
      // return this.http.post<SchedulerWorkFlow[]>(
      //   environment.baseUrl + "/apis/schedulersByClient",
      //   data
      // );
      return this.http.post<SspGroupModel[]>(environment.baseUrl + "/apis/ssp/getReportViewByGroup",data);
  
  

  }

  downloadPDF(rowdata){
    return this.http.post<SspGroupModel[]>(environment.baseUrl + "/apis/downloadPDFView",rowdata);

  }


  getAllReportList(){

    return this.http.get<any[]>(
      `${environment.baseUrl}/apis/viewReportList`
    );
  }

getGroupReportData(applicationId,clientId){
  return this.http.get<any[]>(
    `${environment.baseUrl}/apis/ssp/getGroupReportDataValues/${clientId}?applicationId=`+applicationId
  ); 
}

getFilterGroupReportData(applicationId,clientId){
  return this.http.get<any[]>(
    `${environment.baseUrl}/apis/ssp/getFilterGroupReportDataValues/${clientId}?applicationId=`+applicationId
  ); 

}

getFilterGroupReportDataByCategoryId(categoryId, applicationId,clientId) {
  return this.http.get<any[]>(
    `${environment.baseUrl}/apis/ssp/getFilterGroupReportDataValuesByCategoryId/${categoryId}/${clientId}?applicationId=`+applicationId
  );
}

getFullReportByCategoryId(categoryId, applicationId,clientId) {
  return this.http.get<any[]>(
    `${environment.baseUrl}/apis/ssp/getFullReportByCategoryId/${categoryId}/${clientId}?applicationId=`+applicationId
  );
}

getWorkflowReportByGroupId(data){
  
  return this.http.post<any>(
    `${environment.baseUrl}/apis/workflow/getByGroupId`,data
  );
}


getWorkspaceList(){
return this.http.get<any[]>(
    `${environment.baseUrl}/apis/dashboard/getWorkspaceList`
  ); 
}

showSetHomeScreen(){
  return this.http.get<boolean>(
    `${environment.baseUrl}/apis/menu/checkSetHomeScreen`
  ); 

}
}