import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { currentMonthRange, formatDate, getCurrentDate } from 'src/app/utils/dates';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { TableStatisticService } from '../../services/table-statistic.service';
import { TransformService } from '../../services/transform.service';
import { ClientService } from '../../services/client.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;

  activeRoute$:Subscription;
  headerForm:FormGroup;
  fileDataTypeList:any[];
  showDataType:boolean=false;
  tableStatisticsList:any;
  uniqueDatesList:any[]=[];
  showDate:boolean=false;
  data:any;
  dataTransferModel: DataTransferModel;
  rolePermission:any;
  clientsList:{key: any; value: any }[]=[];
  adminFlag:String;
  companyName:string=null;
  searchQuery!: any;
  searchCol:String;
  companyId:any;
  state:string;
  
  databaseType= [
    { key: 1, value: "DataLake" },
    { key: 2, value: "DataMart" },
    
  ];

  
  constructor(private activeRoute:ActivatedRoute,
    private titleService:TitleService,
    public observalbleDataService: ObservalbleDataService,
    private fileService:FileService,
    private statisticsService:TableStatisticService,
    private toast:ToastService,
    private loader:LoaderService,
    private clientService:ClientService,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>) { 
    
    this.headerForm = new FormGroup({
      fileType: new FormControl(null,[Validators.required]),
      asOfDate:new FormControl(null,[Validators.required]),
      client:new FormControl(null,[Validators.required])
    });

    this.state=localStorage.getItem("stateflag");
  }

  ngOnInit(): void {
    this.adminFlag=this.cookieService.get("adminFlag");
   this.buttonShowHidePermission();
   this.getClients();

   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );

  this.fileService.getClientByUserId().subscribe(
    (data) =>{
   
      for(var i=0;i<data.length;i++){
        console.log("data  ",data[i].clientId)
        this.companyId=data[i].clientId
        this.companyName=data[i].clientName
      }
      this.headerForm.get("client").setValue({
        key: this.companyId,
        value: this.companyName
      });

  

     console.log("this.headerForm",this.headerForm)
     this.getAllStatistics();
    // this.getAllCustomGroup(this.headerForm.controls.client.value,'toggleOnInit')
    
   
    },
    (err)=>{
      this.toast.showError(err.error.message, "error");
    }
    );
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getApplicationFileType(){
    this.fileDataTypeList=[];
    this.fileService.getApplicationDataTypeByUser().subscribe(
   (data) => {
     const fileDataType=data;
     for(var a=0;a<fileDataType.length;a++){
     // console.log("type "+fileDataType[a].type);
       this.fileDataTypeList.push({ key: fileDataType[a].datalakeTable, value: fileDataType[a].type });
     
     }
   },
   (err) => {
   //  this.toast.showSuccess("Status Not Changed", "error");
   this.toast.showError(err.error.message, "error");
   }
 
 );
    
 }

 getDataType(event){
  this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
  this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
  this.showDataType=true;
  this.tableStatisticsList=[];
  this.uniqueDatesList=[];
  this.headerForm.controls.fileType.reset();
  this.headerForm.controls.asOfDate.reset();
  }


     loadDate(){
      let currentDateRange=currentMonthRange();
      //console.log(currentDateRange,'  current date range');
      this.headerForm.controls.asOfDate.setValue(currentDateRange[0]);
    }

    onDateChange(event){
      this.loader.showLoader();
      if(!this.headerForm.valid){
this.toast.showError('please Select client and Data type first','error');
      }
      const data={
        "asofdate":formatDate(this.headerForm.controls.asOfDate.value.key),
        "connectionId":1,
        "tableName":this.headerForm.controls.fileType.value.key,
        "client":this.headerForm.controls.client.value.key
      }
      this.statisticsService.tableStatistics(data).subscribe(
        (data) => {
         this.tableStatisticsList=data;
         this.loader.hideLoader();
         if(this.tableStatisticsList.length<=0){
         this.toast.showSuccess('No Statistics present','success');
         } 
        },
        (err) => {
          this.loader.hideLoader();
        //  this.toast.showSuccess("Status Not Changed", "error");
        }
      
      );
         
      }

      getTableStatistics(event){
        this.getDistinctDate();
        this.panelFilter.toggleSlider();
      }

      getDistinctDate(){
       this.loader.showLoader();
        this.uniqueDatesList=[];
      const data={
        "connectionId":1,
        "tableName":this.headerForm.controls.fileType.value.key,
        "clientId":this.headerForm.controls.client.value.key
      }
        this.statisticsService.distiinctAsOfDate(data).subscribe(
          (data) => {
            const distinctDates=data;
            if(distinctDates.length<=0){
              this.showDate=false;
              this.loader.hideLoader();
              this.toast.showSuccess('No data present','success'); 
            }else{
            for(var a=0;a<distinctDates.length;a++){
              // console.log("type "+fileDataType[a].type);
                this.uniqueDatesList.push({ key: distinctDates[a], value: distinctDates[a] });
              
              }
              this.loader.hideLoader();
              this.showDate=true;
           //   this.toast.showWarn('Please select As Of Date','warn');
            }
            },
          (err) => {
            this.loader.hideLoader();
          //  this.toast.showSuccess("Status Not Changed", "error");
          }
        
        );
      }

      generateStatistics(event){
      if(!this.headerForm.valid){
        this.toast.showError('please Select client and Data type first','error');

      }
        this.loader.showLoader();
        const data={
        "asofdate":formatDate(this.headerForm.controls.asOfDate.value.key),
        "connection_id":1,
        "table_name":this.headerForm.controls.fileType.value.key,
        "client_id":this.headerForm.controls.client.value.key
      }
        this.statisticsService.generateStatistic(data).subscribe(
          (data) => {
            const dataSaved=data;
            this.data=data;
            if(this.data.status==200){        
            this.toast.showSuccess('Statistics generated','success')
            this.onDateChange(event);
            this.loader.hideLoader();
            }else{
              this.toast.showError(this.data.msg,'error');
            }
          },
          (err) => {
            this.loader.hideLoader();
          //  this.toast.showSuccess("Status Not Changed", "error");
          }
        
        );
        }
  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
   
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
              this.getDataType(null);      
    }
  }
       
  maintainClientState(client){ 
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: '',
      fromDate: '',
      clientName: client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel);
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getPreviousState();
      this.setTitle();
      this.loadDate();
      this.getApplicationFileType();
    }



    getClients() {  
      this.clientService.getAllClients().subscribe(
  
        (data) => {  
          this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
  
          console.log("client list:",this.clientsList)
  
        },
  
        (err) => {
  
          this.toast.showError(err.error.message, "error");
  
        }
  
      );
  
    }

    getAllStatistics(){
        this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
        this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
       
    }
  
}
