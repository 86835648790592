import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { CategoryModel } from "../models/category.model";
//import { DepartmentModel } from '../models/Department.model';

@Injectable({
    providedIn: 'root'
  })
export class CategoryService {
  
  
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  saveOrUpdate(data){
    return this.http.post<any[]>(environment.baseUrl + "/apis/category/createCategory",data);
} 


getAllCategoryList(){
  return this.http.get<any[]>(environment.baseUrl + "/apis/getCategoryAll");
}

deleteCategoryId(data) {
    return this.http.post<CategoryModel[]>(environment.baseUrl + "/apis/category/deleteCategoryId", data);
  }




  }

