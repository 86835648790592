import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TitleService } from "src/app/core/services/title.service";
import { ToastService } from "src/app/core/services/toast.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { SidePanelComponent } from "src/app/ui/components/side-panel/side-panel.component";
import { YeildAdavancesService } from "../../services/yeild-advances.service";
import { error } from "console";

@Component({
  selector: "yeild-advances",
  templateUrl: "./yeild-advances.component.html",
  styleUrls: ["./yeild-advances.component.scss"],
})
export class YeildAdvancesComponent implements OnInit {
  

  tableData: any[];
  searchByCols: { field: String; header: String }[];
  state: string;
  createForm: FormGroup = null;
  visibleDelete: boolean = false;
  deleteValue:any;
  searchQuery!: any;
  searchCol:String;
  irrtableData: any[];
  createForm1: FormGroup = null;
  menu:string;
  submenu:string;

  variableNameError:String=null;
  variableNameMsg:String=null;
  variableCodeError:String=null;
  variableValueError:String=null;
  particularError:String=null;
  bucket1Error:String=null;
  bucket2Error:String=null;
  bucket3Error:String=null;
  bucket4Error:String=null;
  bucket5Error:String=null;
  bucket6Error:String=null;
  bucket7Error:String=null;
  bucket8Error:String=null;
  bucket9Error:String=null;
  bucket10Error:String=null;


  constructor(  private titleService: TitleService,private datePipe: DatePipe,
    private toast: ToastService,
    private activeRoute: ActivatedRoute,
    private yeildAdavancesService: YeildAdavancesService,
    private loader:LoaderService
  ) {
    this.titleService.setTitle("Stress Variables");
    this.searchByCols = [
      { field: "stressVariableName", header: "Variable Name" },
      { field: "value", header: "Variable Value" },
    ];
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state = localStorage.getItem("stateflag");
    this.createForm = new FormGroup({
      stressVariableId: new FormControl(null),
      stressVariableCode: new FormControl(null, {validators: [Validators.required,Validators.minLength(4),Validators.maxLength(10)],}),
      stressVariableName: new FormControl(null, {
        validators: [Validators.required,Validators.minLength(5),Validators.maxLength(250)],
      }),
      value: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
      validFrom: new FormControl(null, { validators: [Validators.required] }),
      validTo: new FormControl(null, { validators: [Validators.required] }),
      createdBy: new FormControl(null),
      createdAt: new FormControl(null),
      modifiedAt: new FormControl(null),
      modifiedBy: new FormControl(null)
    });


    this.createForm1 = new FormGroup({
      id: new FormControl(null),
      particulars: new FormControl(null, {
        validators: [Validators.required],
      }),
      bucket_1: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
      bucket_2: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
      bucket_3: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
      bucket_4: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
      bucket_5: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
      bucket_6: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
      bucket_7: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
      bucket_8: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
      bucket_9: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] }),
      bucket_10: new FormControl(null, { validators: [Validators.required,Validators.maxLength(50)] })
    });
  }

  ngOnInit(): void {
    this.loader.showLoader();
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );
    this.getTableData();
    this.getIrrMdurationTableData();
  }

  getTableData() {
    this.yeildAdavancesService.getAllStressVariables().subscribe(
      (data) => {
        console.log("Table Data size: " + data.length);
        this.tableData = data;
        console.log("this.tableData",this.tableData);
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
  }

  openForm(): void {
    this.createForm.reset();
    document.getElementById("variableCreate").style.display="";
    document.getElementById("variableEdit").style.display="none";
    this.panelForm.toggleSlider();
  }

  closeForm(): void {
    this.createForm.reset();
    this.panelForm.toggleSlider();
  }

  save(): void {
    console.log("stress variable form:",this.createForm);
    let status = this.createForm.status;
   
    this.panelForm.toggleSlider();
    if (status.toUpperCase() == "INVALID") {
      this.toast.showError("All Fields are mandatory...!", "error");
    } else {
      let request = this.createForm.value;
      console.log("variable form:",request);
      
      let { validFrom, validTo } = request;
      if (new Date(validFrom).getTime() > new Date(validTo).getTime() - 1) {
        this.toast.showError("Incorrect Date Range...!", "error");
      } else {
        this.yeildAdavancesService.saveOrUpdate(request).subscribe(
          (data) => {
            console.log(data);
            this.getTableData();
            this.createForm.reset();
            //this.toast.showSuccess("Stress Variable Saved Successfully", "success");
            this.toast.showSuccess('Your Change Request Sent for Approval','');
          },
          (err) => {
            console.log("Saving Yeild Advances Error: " + JSON.stringify(err));
            this.toast.showError(err.error.message, "error");
            
          }
        );
      }
    }
  }

  edit(rowData) {
    document.getElementById("variableEdit").style.display="";
    document.getElementById("variableCreate").style.display="none";
    console.log("edit row Data", rowData);
    this.createForm.patchValue({
      stressVariableId: rowData.stressVariableId,
      stressVariableCode:rowData.stressVariableCode,
      stressVariableName: rowData.stressVariableName,
      value: rowData.value,
      // validFrom: rowData.validFrom,
      // validTo: rowData.validTo,
      createdBy: rowData.createdBy,
      createdAt: rowData.createdAt,
      modifiedAt: rowData.modifiedAt,
      modifiedBy: rowData.modifiedBy,
      validFrom:this.datePipe.transform(rowData.validFrom,"yyyy-MM-dd"),
      validTo:this.datePipe.transform(rowData.validTo,"yyyy-MM-dd"),
    });
    console.log("rowData patch",rowData);
    this.panelForm.toggleSlider();
 
  }

  showPopupDelete(rowData) {
    this.deleteValue = rowData.stressVariableId;
    this.visibleDelete = true;
  }

  cancelHandler() {
    this.deleteValue = null;
    this.visibleDelete = false;
  }

  confirmHandler() {
    this.delete();
    this.deleteValue = null;
    this.visibleDelete = false;
  }

  delete() {
    this.yeildAdavancesService.deleteStressVariables(this.deleteValue).subscribe(
      (data) => {
        console.log(data);
        if (data) {
          this.toast.showSuccess('Success', 'Stress Variable deleted successfully');
          this.getTableData();
        } else {
          this.toast.showError("Deletion Failed...!", "error");
         
        }
      }, (err) => {
        console.log("Saving Yeild Advances Error: " + JSON.stringify(err));
        // this.toast.showError("Deletion Failed...!", "error");
        this.toast.showWarn(err.error.message, "error");
      }
    );
    this.deleteValue = null;
  }

  getIrrMdurationTableData(){
    this.yeildAdavancesService.getAllIrrMduration().subscribe(
      (data) => {
        console.log("Table Data size: " + data.length);
        this.irrtableData = data;
        console.log("this.irrtableData",this.irrtableData);
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
      }
    );
  }

  saveIrrMduration(): void {
    let status = this.createForm1.status;
    //this.panelForm1.toggleSlider();
    
      let request = this.createForm1.value;
      console.log("mduration form:",request);
      
      this.panelForm1.toggleSlider();
        this.yeildAdavancesService.saveOrUpdateIrrMduration(request).subscribe(
          (data) => {
            console.log(data);
            this.getTableData();
            this.getIrrMdurationTableData();
            this.createForm1.reset();
            //this.toast.showSuccess("IRR Mduration Saved Successfully", "success");
            this.toast.showSuccess('Your Change Request Sent for Approval','');
          },
          (err) => {
            console.log("Saving IRR Mduration Error: " + JSON.stringify(err));
            this.toast.showError("Creation Failed...!", "error");
          }
        );
        
  }

  editIrrMduration(rowData1) {
    this.createForm1.patchValue({
      id: rowData1.id,
      particulars: rowData1.particulars,
      bucket_1:rowData1.bucket_1,
      bucket_2:rowData1.bucket_2,
      bucket_3:rowData1.bucket_3,
      bucket_4:rowData1.bucket_4,
      bucket_5:rowData1.bucket_5,
      bucket_6:rowData1.bucket_6,
      bucket_7:rowData1.bucket_7,
      bucket_8:rowData1.bucket_8,
      bucket_9:rowData1.bucket_9,
      bucket_10:rowData1.bucket_10,
     
    });
    console.log("rowData1 patch",rowData1);
    this.panelForm1.toggleSlider();
 
  }

  closeForm1(): void {
    this.createForm.reset();
    this.panelForm1.toggleSlider();
  }





  ///////////////////////Flicker Reason

  @ViewChild("panelForm", { static: false })
  panelForm!: SidePanelComponent;

  @ViewChild("panelForm1", { static: false })
  panelForm1!: SidePanelComponent;
}
