import { DatePipe } from '@angular/common';
import { Component, OnInit ,SimpleChanges,OnChanges,ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { FormBuilderService } from 'src/app/formsy/services/form-builder.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { WorkflowJobTableComponent } from 'src/app/workflows/components/workflow-job-table/workflow-job-table.component';
import { WorkflowResponseModel } from '../../models/workflow.model';
import { SeverityService } from '../../services/severity.service';
import { ShockMasterService } from '../../services/shockMaster.service';
import { WorkflowJobComponent } from '../workflow-job/workflow-job.component';
import { environment } from "src/environments/environment";
import { ClientPortalHeaderComponent } from 'src/app/client-portal/client-portal-header/client-portal-header.component';
import { formatDate } from '@fullcalendar/core';
import * as moment from 'moment';
import { WorkflowService } from '../../services/workflow.service';

@Component({
  selector: 'app-shock-configuration',
  templateUrl: './shock-configuration.component.html',
  styleUrls: ['./shock-configuration.component.scss'],
  providers: [FormBuilderService],
})
export class ShockConfigurationComponent implements OnInit,OnChanges {
  @ViewChild(WorkflowJobTableComponent, { read: false, static: false })
  workflowJobTableComponent: WorkflowJobTableComponent;

  @ViewChild(WorkflowJobComponent, { read: false, static: false })
  workflowJobComponent:WorkflowJobComponent;


  @ViewChild(ClientPortalHeaderComponent, { read: false, static: false })
  clientPortalHeaderComponent:ClientPortalHeaderComponent;

  

  state:string;
  shockForm:FormGroup=null;
  stressShocksList: any[];
  stressShockDetail: any;
  form:FormGroup=null;
  formFieldType="Text";
  groupInline: boolean = false;
   inlineClass: string = "";
   variableForm:FormGroup=null;
   tableData: any[]=[];
   tableData1: any[]=[];
   stressKeyMetrics:{stressKeyMetricsId: number; status: boolean; colName:string}[]=[];
  stressSeverity:{stressSeverityId: number; status: boolean; colName:string}[]=[]; 
  
  workflowResponseModel:WorkflowResponseModel[]=[];
  showWorkflowTable:boolean;

  reportId:any;
  mappedColumnName:string=null;
  storeShockData:any;

  stressKeyMetricsStatus:number=0;
  stressKeyMetricsId:number=null;
  stressSevereStatus:number=0;
  stressSevereId:number=null;
  reportUrl:string;
  collapseReport:boolean=false;
  configureCollapse:boolean=true;

  scenarioValues:any[]=[];
  shocksVariables:any[]=[];
  portFolioCollapse:boolean=false;
  rawUrl:string;
  hideLogo:boolean=true;
  headerForm:FormGroup=null;
  workflowName:String=null;
  workflowId:any;
  asofdate:string=null;
  companyId: number=0; 
  workflowUpdatedUrl:string;
  filterList:any;
  branchDropDown: any[] = [];
  creditRatingDropDown: any[] = [];
  industryDropDown: any[] = [];
  branchList:any;
  creditRatingList:any;
  industryList:any;
  filterForm:FormGroup=null;
  zohocriteriaURL:string='';
  code:String=null;
  menu:string;
  errorList:String;
  visibleCreditRiskGroup: boolean=false;
  visible_STRESS_SHOCK001: boolean=false;
  visible_STRESS_SHOCK002: boolean=false;
  visible_STRESS_SHOCK003: boolean=false;
  visible_STRESS_SHOCK004: boolean=false;
  visible_STRESS_SHOCK005A: boolean=false;
  visible_STRESS_SHOCK005B: boolean=false;
  visible_STRESS_SHOCK006: boolean=false;
  visible_STRESS_SHOCK007: boolean=false;
  visible_STRESS_SHOCK008: boolean=false;
  visible_STRESS_SHOCK009: boolean=false;

  visibleMarketRiskGroup: boolean=false;
  visible_STRESS_MRT5001: boolean=false;
  visible_STRESS_MR003: boolean=false;
  visible_STRESS_IRBB006: boolean=false;

  visibleLiquidityRiskGroup: boolean=false;
  visible_STRESS_LR001: boolean=false;

  visibleVaRGroup: boolean=false;
  visible_VAR_001: boolean=false;
  visible_VAR_002: boolean=false;
  visible_VAR_003: boolean=false;

  visibleOtherRiskGroup: boolean=false;
  visible_STRESS_ISR001: boolean=false;
  visible_STRESS_REP001: boolean=false;
  visible_STRESS_SR001: boolean=false;

  configureFilterSaveForm:FormGroup=null;
  stressShockCodeforParam: string;
  constructor(private ShockMasterService:ShockMasterService,private activeRoute: ActivatedRoute,private toastService:ToastService,
    private titleService: TitleService,private formService: FormBuilderService,private _fb: FormBuilder,
    private SeverityService: SeverityService,private fileService:FileService,private datePipe:DatePipe,private workflowService:WorkflowService) {

    this.menu=localStorage.getItem("menu");
    this.state=localStorage.getItem("stateflag");  
    this.companyId=Number(localStorage.getItem("companyId"));

    const storedMenuItems = JSON.parse(localStorage.getItem("allActiveMenuList"));
    console.log("all menu items: ",storedMenuItems);

    if(storedMenuItems!=undefined || storedMenuItems.length!=0){
      storedMenuItems.forEach(menu => {
        if(menu.label.includes("Credit Risk")){
          this.visibleCreditRiskGroup=true;

          this.visible_STRESS_SHOCK001=true;
          this.visible_STRESS_SHOCK002=true;
          this.visible_STRESS_SHOCK003=true;
          this.visible_STRESS_SHOCK004=true;
          this.visible_STRESS_SHOCK005A=true;
          this.visible_STRESS_SHOCK005B=true;
          this.visible_STRESS_SHOCK006=true;
          this.visible_STRESS_SHOCK007=true;
          this.visible_STRESS_SHOCK008=true;
          this.visible_STRESS_SHOCK009=true;
        }
        if(menu.label.includes("Market Risk")){
          this.visibleMarketRiskGroup=true;
          menu.submenu.forEach(submenu => {
            if(submenu.label.includes("Interest Rate Risk")){
              //display
              this.visible_STRESS_IRBB006=true;
            }
            if(submenu.label.includes("Foreign Exchange Risk")){
              this.visible_STRESS_MRT5001=true;
            }
            if(submenu.label.includes("Equity Risk")){
              this.visible_STRESS_MR003=true;
            }
          });
        }
        if(menu.label.includes("Liquidity Risk")){
          this.visibleLiquidityRiskGroup=true;
          this.visible_STRESS_LR001=true;
        }
        if(menu.label.includes("Value at Risk")){
          this.visibleVaRGroup=true;
          menu.submenu.forEach(submenu => {
            if(submenu.label.includes("Parametric Method")){
              this.visible_VAR_001=true;
            }
            if(submenu.label.includes("Monte Carlo Method")){
              this.visible_VAR_002=true;
            }
            if(submenu.label.includes("Historical Method")){
              this.visible_VAR_003=true;
            }
          });
        }
        if(menu.label.includes("Other Risks")){
          this.visibleOtherRiskGroup=true;
          this.visible_STRESS_ISR001=true;
          this.visible_STRESS_REP001=true;
          this.visible_STRESS_SR001=true;
        }
        
      });
    }

    this.shockForm = new FormGroup({
      stressShockCode: new FormControl(null, [Validators.required])
    });

    // this.rawUrl=environment.reportUrl+"open-view/24185/3ef9a0d6c398ada759eba71fad5dc501"
    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      //dateRange: new FormControl(currentMonthRange(), [Validators.required]),
      fromDate: new FormControl(null, [Validators.required]),
      toDate: new FormControl(null, [Validators.required]),
    });

    this.filterForm = new  FormGroup({
      branch: new FormControl(null, [Validators.required]),
      industry: new FormControl(null, [Validators.required]),
      creditRating: new FormControl(null, [Validators.required]),
    });

    this.configureFilterSaveForm= new FormGroup({
      clientId: new FormControl(null, [Validators.required]),
      reportId: new FormControl(null, [Validators.required]),
      industry: new FormControl(null),
      branch: new FormControl(null),
      creditRating: new FormControl(null)
    })

   }

  ngOnInit(): void {
    // this.getAllStressSevere();
    // this.getAllKeyStressSevere();
    // if(this.asofdate==null){
    //   this.asofdate=localStorage.getItem("asofdate");
    // }
    this.selectFilterList();

this.onPortfolioTabOpen();
this.shockForm.get("stressShockCode").setValue("STRESS_SHOCK001")

    document.getElementById("portfolioEnable").style.display="";

    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
        for(var i=0;i<data.length;i++){
          console.log("data  ",data[i].clientId)
        this.headerForm.get("client").setValue({
          key: data[i].clientId,
          value: data[i].clientName
        });
      }
      this.clientPortalHeaderComponent.headerForm.get("client").setValue({
        key:this.headerForm.controls.client.value.key,
        value:this.headerForm.controls.client.value.value
      });
      console.log("this.headerForm.controls.client.value.key ",this.headerForm.controls.client.value.key)

      this.getAllShockList();
      //this.detailsByCode(event);

      //this section is not used for save file
      let currentDateRange=this.currentMonthRange();
      console.log("currentDateRange[0] ",currentDateRange[0]);
      console.log("currentDateRange[1] ",currentDateRange[1]);
      this.headerForm.controls.fromDate.setValue(this.datePipe.transform(formatDate(currentDateRange[0]),"yyyy-MM-dd"));
      this.headerForm.controls.toDate.setValue(this.datePipe.transform(formatDate(currentDateRange[1]),"yyyy-MM-dd"));
      this.clientPortalHeaderComponent.headerForm.get("toDate").setValue(this.headerForm.controls.toDate.value);
      this.clientPortalHeaderComponent.headerForm.get("fromDate").setValue(this.headerForm.controls.fromDate.value);


      }
      )

      this.getShockDetailsByShockCode('STRESS_SHOCK001');

      // this.filterForm.get("branch").setValue("Select Branch");
      // this.filterForm.get("creditRating").setValue("Select Credit Rating");
      // this.filterForm.get("industry").setValue("Select Industry");

  }


  currentMonthRange = () => {
    const startOfMonth = moment()
      .startOf("year")
      .toDate();
    const endOfMonth = moment()
      .endOf("year")
      .toDate();
    return [startOfMonth, endOfMonth];
  };



  getAllShockList(){
    
    this.ShockMasterService.getStressShockData().subscribe(
    (data)=>{
      console.log("shockList Data", data)
      this.storeShockData= data;
      this.stressShocksList = data;
      let shockCode;
      let shockName;
    //   for(var i=0;i<this.stressShocksList.length;i++){
    //   // console.log("data  ",data[i].shockName)
    //    if(i==0){
    //    shockCode=this.stressShocksList[i].stressShocksCode
    //    shockName= this.stressShocksList[i].shockName
    //    }
    //  }
     
    //  this.shockForm.get("stressShockCode").setValue({
    //    key:shockCode,
    //    value:shockName
    //  }); 
    //  this.stressShocksList = reMapForAutoComplete(this.stressShocksList, "stressShocksCode", "shockName");
    //  console.log("this.stressShocksList",this.stressShocksList);
    
    console.log("selected value --------",this.shockForm.value);
    this.detailsByCode(event);
    },
    (err) => {
      this.toastService.showError(err.error.message, "error");
      
    }
    );
  }



  getShockDetailsByShockCode(code){ 
    this.code=code;
  this.asofdate=localStorage.getItem("asofdate");
  this.ShockMasterService.getShockDetailsByShockCode(code).subscribe(
  (data)=>{ 
    console.log("Dropdown data",data);

   
    if(data.configureFilters!=null){
        document.getElementById("selectFilterEnable").style.display="";
        this.filterForm.get("branch").setValue('');
        this.filterForm.get("industry").setValue('');
        this.filterForm.get("creditRating").setValue('');
        console.log("this.filter form on dropdown",this.filterForm);
      }else{
        document.getElementById("selectFilterEnable").style.display="none";
      }
    if(data.stressShocksCode==="STRESS_ISR001" || data.stressShocksCode==="STRESS_MR003" || data.stressShocksCode==="STRESS_REP001" || data.stressShocksCode==="STRESS_SR001"){
      document.getElementById("portfolioEnable").style.display="none";
      this.onTabOpen('e');
    }
    else{
      document.getElementById("portfolioEnable").style.display="";
      
      this.onPortfolioTabOpen();

      console.log("configure filter data",data.configureFilters);
    try {
      if(data.configureFilters.branch===true){
        document.getElementById("branchEnable").style.display=""
        
      }
      if(data.configureFilters.creditRating===true){
        document.getElementById("creditRatingEnable").style.display=""
      }
      if(data.configureFilters.industry===true){
        document.getElementById("industryEnable").style.display=""
      }
  
      if(data.configureFilters.branch===false){
        document.getElementById("branchEnable").style.display="none"
      }
      if(data.configureFilters.creditRating===false){
        document.getElementById("creditRatingEnable").style.display="none"
      }
      if(data.configureFilters.industry===false){
        document.getElementById("industryEnable").style.display="none"
      }
      if(data.configureFilters.branch===false && data.configureFilters.creditRating===false && data.configureFilters.industry===false){
        document.getElementById("selectFilterEnable").style.display="none";
      }
    } catch (error) {
      
    }  
   
    }  
          this.stressShockDetail = data;
          this.reportId=this.stressShockDetail.applicationId;
          this.asofdate=this.stressShockDetail.asOfDate;
          if(this.stressShockDetail.reportUrl!=null){

            if(this.stressShockDetail.stressShocksCode.includes("VAR_001") || this.stressShockDetail.stressShocksCode.includes("VAR_002") || this.stressShockDetail.stressShocksCode.includes("VAR_003")){
              this.reportUrl = environment.reportUrl+this.stressShockDetail.reportUrl;
            }else{

          this.reportUrl=environment.reportUrl+(this.stressShockDetail.reportUrl).replace('false','true');
          if (this.stressShockDetail.reportUrl.includes("?")) {
      
            this.reportUrl = this.reportUrl+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";

          }
          else{
           
            this.reportUrl= this.reportUrl+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
            
          }
          console.log("report url ",this.reportUrl);

          if(!this.reportUrl.includes("open-view/")){

          }
        }
          }
          if(this.stressShockDetail.rawUrl!=null){
            this.rawUrl=environment.reportUrl+(this.stressShockDetail.rawUrl).replace('false','true');
            if (this.stressShockDetail.rawUrl.includes("?")) {
              this.rawUrl = this.rawUrl+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId;
            }
            else{
              this.rawUrl= this.rawUrl+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId;
            }
            if(!this.rawUrl.includes("open-view/")){
            }
            }else{
              this.rawUrl=null;
            }
            console.log("raw url ",this.rawUrl);
          console.log("this.stressShockDetail----",this.stressShockDetail);
          this.scenarioValues=this.stressShockDetail.stressShockValues;
          this.shocksVariables=this.stressShockDetail.stressVariables
          this.buildform(this.scenarioValues);
          this.buildVarriableform(this.shocksVariables);
          this.stressShockCodeforParam=data.configureFilters.shocksCode;
          },

  (err) => {
      this.toastService.showError(err.error.message, "error"); 
           }
           );


              
         if(code.includes("STRESS_SHOCK00")){
         this.workflowName="Stress Shocks"
         }
         else if(code.includes("STRESS_MRT5001")){
         this.workflowName="Market Risk"
         }
         else{
         this.workflowName=null;
         this.workflowId=0;
         this.workflowResponseModel=[];
        // this.toastService.showWarn('Workflow Is Not Available','error');
         return;
          }
 

           const model={
            clientId:this.headerForm.controls.client.value.key,
            workflowName:this.workflowName
           }
           if(this.workflowName!=null){
           this.workflowService.getWorkflowByName(model).subscribe(
            (data)=>{
              if(data==null){
                 this.workflowId=0;
                 return;  
              } 
              this.workflowId=data.workflowId
             
            },
            (err) => {
              this.workflowId=0;
              this.toastService.showError(err.error.message, "error"); 
                   }
           )}
           
        }



   detailsByCode(event){
if(this.shockForm.valid){
  this.getShockDetailsByShockCode(this.shockForm.controls.stressShockCode.value);
  this.mappedColumnName=this.storeShockData.filter(x=>x.stressShocksCode===this.shockForm.controls.stressShockCode.value)[0].colName;
  if(this.mappedColumnName!=null && this.mappedColumnName!=undefined){
    this.getStressKeyMetricsData(this.mappedColumnName);
    this.getStressSeverityData(this.mappedColumnName);
  }
  else{
    this.stressKeyMetrics=[];
    this.stressSeverity=[]; 
    this.tableData=[];
    this.tableData1=[];
   // this.toastService.showError("No Matrics & Severity For This Shock", "error");
  }
}
 }


 runReport(){
  const model={
    stressShockValues:this.form.value.ScenarioValue,
    stressVariables:this.variableForm.value.ScenarioVarriable
  }
  console.log("model ",model);
  try {
// form validation code    
     for (let index = 0; index < model.stressVariables.length; index++) {
      let value = model.stressVariables[index].value;

    var numWithPercent="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz <>@!#$^&*()_+[]{}?:;|'\"\\,/~`="
    for(let i=0; i<numWithPercent.length; i++){
     if(value.indexOf(numWithPercent[i])>-1){
       this.errorList="For Stress Variable values, It should allow only Numerical values and '%'.";
       return;
     }
    }
    let num = value.length-1;
   for(let i = 0; i < value.length; i++){
     if(value[i+1]==="-"){
       this.errorList="For Stress Variable values, It should allow only Numerical values and '%'.";
       return;
     }
     if(value[num]==="%" || value[num]!="%"){
       for(let j=0; j<num; j++){
       if(value[j]==="%"){
         this.errorList="For Stress Variable values, It should allow only Numerical values and '%'.";
         return;
       }
     }
     }
     else{
       this.errorList="For Stress Variable values, It should allow only Numerical values and '%'.";
       return;
     }
   }
    if(value>=0 || value<0){}
    
    if(value.includes("%")){
     let v=value.substring(0,value.length-1);
     if(v==""){
      this.errorList="For Stress Variable values, It should allow only Numerical values and '%'.";
      return;
    }
     if(v>=0 || v<0){}
     else{
        this.errorList="For Stress Variable values, It should allow only Numerical values and '%'.";
       return;
     }
    }
}      
  } catch (error) {}

  this.ShockMasterService.saveShockDetails(model).subscribe(
    (data)=>{
      this.errorList=null; 
      if(this.mappedColumnName!=null && this.mappedColumnName!=undefined){
      this.saveData();
      }
      if(this.reportId!=null && this.reportId!=undefined && this.reportId!=0){
      this.getreportMappedWorkflows(this.reportId);
      }
      else{
        this.toastService.showError("No Report Is Attached With this Shock", "error");
        this.errorList=null;
        return;
      }
      
        },
        (err) => {
          this.toastService.showError("Updation Failed...!", "error");
          this.errorList=null;
        }
    )
 }


 ngOnChanges(changes: SimpleChanges) {
  // if (changes)
  }
   
 private buildform(list: any) {
      let arr: any = [];
        list.forEach(item => {
          arr.push(this.buildFormDynamic(item))
        });
        this.form = this._fb.group({
         ScenarioValue: this._fb.array(arr)
        })
  
    }

 private buildVarriableform(list: any) {
      let arr: any = [];
        list.forEach(item => {
          arr.push(this.buildVarriableFormDynamic(item))
        });
        this.variableForm = this._fb.group({
         ScenarioVarriable: this._fb.array(arr)
        })
  
    }


    private buildFormDynamic(item): FormGroup {
      return this._fb.group({
        stressScenarioValueId: [item.stressScenarioValueId],
        stressScenarioName:[item.stressScenarioName],
        baseline: [item.baseline],
        medium: [item.medium],
        severe: [item.severe],
      })
    }

    private buildVarriableFormDynamic(item): FormGroup {
      return this._fb.group({
        stressVariableId: [item.stressVariableId],
        stressVariableName:[item.stressVariableName],
        value: [item.value],
      })
    }

    resetReport(){
      this.errorList=null;
      for(let item of this.variableForm.controls.ScenarioVarriable['controls']){
        item.get('value').setValue(null);
      }
      for(let item1 of this.form.controls.ScenarioValue['controls']){
        item1.get('baseline').setValue(0);
        item1.get('medium').setValue(0);
        item1.get('severe').setValue(0);
      }
  
    }



    // getAllStressSevere() {
    //   this.SeverityService.getAllStressSevere().subscribe(
    //     (data) => {
    //      // console.log("Table Data size: " + data.length);
    //      // console.log("table Data",data)
    //       this.tableData = data;
    //       for(var i=0;i<this.tableData.length;i++){
    //         this.stressSeverity.push({stressSeverityId: this.tableData[i].stressSeverityId,status: this.tableData[i].status,colName:this.tableData[i].colName})
    //         }
    //       console.log("tableData getAllStressSevere()",this.tableData);
    //       console.log("stresskeyMetricsList getAllStressSevere()",this.stressSeverity);
    //     },
    //     (err) => {
    //       this.toastService.showError(err.error.message, "error");
    //     }
    //   );
    // }
  
    // getAllKeyStressSevere() {
    //   this.SeverityService.getAllKeyStressSevere().subscribe(
    //     (data) => {
    //       console.log("Table Data size: " + data.length);
    //       console.log("table Data",data)
    //       this.tableData1 = data;
    //       for(var i=0;i<this.tableData1.length;i++){
    //         this.stressKeyMetrics.push({stressKeyMetricsId:this.tableData1[i].stressKeyMetricsId,status:this.tableData1[i].status,colName:this.tableData1[i].colName})
    //         }
    //       console.log("tableData1  getAllKeyStressSevere()",this.tableData1);
    //       console.log("stressSeverityList getAllKeyStressSevere()",this.stressKeyMetrics);
    //     },
    //     (err) => {
    //       this.toastService.showError(err.error.message, "error");
    //     }
    //   );
    // }


    getStressKeyMetricsData(mappedColumnName){
      this.stressKeyMetrics=[];
      this.SeverityService.getStressKeyMetricsDataByShocks(mappedColumnName).subscribe(
        data => {
          this.tableData1 = data;
          for(var i=0;i<this.tableData1.length;i++){
            this.stressKeyMetrics.push({stressKeyMetricsId:this.tableData1[i].stressKeyMetricsId,status:this.tableData1[i].status,colName:this.tableData1[i].colName})
            }
          console.log("getStressKeyMetricsData: " + data);
          // this.toast.showSuccess(this.shockSelectKey, "success");      
          // this.getAllKeyStressSevere();
          // this.getAllStressSevere();
        },
        err => {
          this.toastService.showError("failed to get shocks", "error");
        }
      );

  }
  
    getStressSeverityData(mappedColumnName){
        // this.shockSelect = this.shockForm.controls.stressShocksList.value.value;
        // this.shockSelectKey = this.shockForm.controls.stressShocksList.value.key;
      this.stressSeverity=[];
      this.SeverityService.getStressSeverityDataByShocks(mappedColumnName).subscribe(
        data => {
          this.tableData = data;
          for(var i=0;i<this.tableData.length;i++){
            this.stressSeverity.push({stressSeverityId: this.tableData[i].stressSeverityId,status: this.tableData[i].status,colName:this.tableData[i].colName})
            }
          console.log("getStressSeverityData: " + data);
          // this.toast.showSuccess(this.shockSelectKey, "success");      
          // this.getAllKeyStressSevere();
          // this.getAllStressSevere();
        },
        err => {
          this.toastService.showError("failed to get shocks", "error");
        }
      );
  
    }


    
getreportMappedWorkflows(reportId){
  //console.log("appId  ",this.reportId)
        this.fileService.getWorkflowListByReportId(reportId).subscribe(
          data =>{
          // this.loader.hideLoader();
        console.log("reportmappedworkflows ",data)
        
        this.workflowResponseModel=data
  
        if(this.workflowResponseModel.length==0){
          console.log("toast messege",this.workflowResponseModel.length);
          this.workflowResponseModel=[];
          this.toastService.showWarn('no Workflow is linked ','error');
          return;
        }
        
        this.workflowResponseModel.forEach(item=>{
        // console.log("item",item.isActive===1);
        
         item.isActiveDisplay=item.isActive===1?'Yes':'No'
         item.workflowTypeDisplay=item.workflowType===1?'System':'Custom'
         item.asOfDate=item.asOfDate!==null?this.datePipe.transform(item.asOfDate,'MM-dd-yyyy'):""
        
                                               })
                                        
        this.workflowJobTableComponent.executeWorkflow(this.workflowResponseModel[0]);
  
                                               
            },
          error => {
                this.toastService.showError(error.error.message, "error");
                    }
        
          )
                  }



   runWorkflow(){
    this.workflowService.getWorkflowListById(this.workflowId).subscribe(
      data =>{
        // this.loader.hideLoader();
      console.log("reportmappedworkflows ",data)
      
      this.workflowResponseModel=data

      if(this.workflowResponseModel.length==0){
        console.log("toast messege",this.workflowResponseModel.length);
        this.workflowResponseModel=[];
        this.toastService.showWarn('Workflow Is Not Available','error');
        return;
      }
      
      this.workflowResponseModel.forEach(item=>{
      // console.log("item",item.isActive===1);
      
       item.isActiveDisplay=item.isActive===1?'Yes':'No'
       item.workflowTypeDisplay=item.workflowType===1?'System':'Custom'
       item.asOfDate=item.asOfDate!==null?this.datePipe.transform(item.asOfDate,'MM-dd-yyyy'):""
      
                                             })
                                      
      this.workflowJobTableComponent.executeWorkflow(this.workflowResponseModel[0]);

                                             
          },
        error => {
              this.toastService.showError(error.error.message, "error");
                  }
      
        
    )

     }              



         
        executeworkflow(data){

        console.log("data ",data)
        this.workflowJobComponent.executeWorkflow(data);
        this.collapseReport=true;
        this.configureCollapse=false;        
      }



      saveData(){
        let stresskeyrequired=true;
        let stressSeverityrequired = true;
    
        for(var i=0;i<this.stressKeyMetrics.length;i++){
          if(this.stressKeyMetrics[i].status==true){
            stresskeyrequired = false;
          }
          }
          for(var i=0;i<this.stressSeverity.length;i++){
            if(this.stressSeverity[i].status==true){
              stressSeverityrequired = false;
            }
            }
    
        if(stresskeyrequired){
          this.toastService.showWarn("Al least one value must be true from Key Metrics ", "Info");
          
        }
        if(stressSeverityrequired){
          this.toastService.showWarn("Al least one value must be true from Stress Severity ", "Info");
          
        }
        
        if(stresskeyrequired || stressSeverityrequired){
          return;
        }
        console.log("status On saveData",this.stressKeyMetrics);     
        const stressKeyMetricsSeverity={
          stressKeyMetricsSeverityList:this.stressKeyMetrics,      
          stressSeverityList: this.stressSeverity,
          lable:localStorage.getItem("menu")
         
        }
     
         
         this.SeverityService.updateStressSeverityStatus(stressKeyMetricsSeverity).subscribe(
    
          (data) => {
            console.log("Table Data size: " + data.length);
            console.log("table Data",data)
            console.log("tableData1",this.tableData1);
            //this.toastService.showSuccess("Key Metrics & Stress Severity Saved Successfully", "success");       
            // this.getAllKeyStressSevere();
            // this.getAllStressSevere();
            //this.stressKeyMetrics=[];
            //this.stressSeverity=[]; 
            this.getStressKeyMetricsData(this.mappedColumnName);
            this.getStressSeverityData(this.mappedColumnName);
          },
            err=>{
             this.toastService.showError(err.error.message, "error");
            }
         );
    
        
    
    
      }
    
      
    
    
      setStatusForData(event,rowData){
        let status;
        console.log("event",event);
        console.log("rowData",rowData);
        this.stressKeyMetricsId=rowData.stressKeyMetricsId;
        
        if(event.target.checked){
          console.log("True");
          console.log("stressKeyMetricsId",this.stressKeyMetricsId)
          this.stressKeyMetricsStatus=1;
        }
        else{
          console.log("False");
          console.log("stressKeyMetricsId",this.stressKeyMetricsId)
          this.stressKeyMetricsStatus=0;
        }
        if(this.stressKeyMetrics != null){
          for(var i=0;i<this.stressKeyMetrics.length;i++){
            if(this.stressKeyMetrics[i].stressKeyMetricsId===rowData.stressKeyMetricsId){
              this.stressKeyMetrics[i].status=event.target.checked;
            }
            }
        }else{
          // this.stressKeyMetrics.push({stressKeyMetricsId:rowData.stressKeyMetricsId,status:event.target.checked,colName:rowData.colName});
        }
    
        console.log("this.stressKeyMetricsStatus",this.stressKeyMetrics);
    
    
       }
    
       setStatusForSevereData(event,rowData){
        console.log("rowData",rowData);
        this.stressSevereId=rowData.stressSeverityId;
        this.stressSevereStatus;
     
        if(event.target.checked){
          console.log("True");
          console.log("stressSevereId",this.stressSevereId)
          this.stressSevereStatus=1;
        }
        else{
          console.log("False");
          console.log("stressSevereId",this.stressSevereId)
          this.stressSevereStatus=0;
        }
        if(this.stressSeverity != null){
          for(var i=0;i<this.stressSeverity.length;i++){
            if(this.stressSeverity[i].stressSeverityId===rowData.stressSeverityId){
              this.stressSeverity[i].status=event.target.checked;
            }
            }
        }else{
          // this.stressSeverity.push({stressSeverityId:rowData.stressSeverityId,status:event.target.checked,colName:rowData.colName})
        }
        console.log("this.stressSeverity",this.stressSeverity);
       }
       

       onTabOpen(e){
        this.collapseReport=false; 
        this.configureCollapse=true; 
        this.portFolioCollapse=false;
       }

       onReportTabOpen(e){
        this.configureCollapse=false; 
        this.collapseReport=true; 
        this.portFolioCollapse=false;
       }

       onPortfolioTabOpen(){
        this.collapseReport=false; 
        this.configureCollapse=false; 
        this.portFolioCollapse=true;
       }
      
       refresh(){
        //document.getElementById('FrameID').contentWindow.location.reload(true);
       } 
       
       downloadFileFormat(){
        let downloadSuccess:boolean=false;
        var link=document.createElement('a');
        // this.ShockMasterService.downloadFileFormat(this.workflowName).subscribe(
        //   (data)=>{
        //     this.toastService.showSuccess('success','Download Begin Shortly');
        //   },
        //   (err) => {
        //     this.toastService.showError(err.error.message, "error");
            
        //   }
        // );
        let code;
        code = this.shockForm.get('stressShockCode').value;
        let fileFormatName;
        if(code.includes("STRESS_SHOCK00")){
          fileFormatName="Stress Shock"
          }
          else if(code.includes("STRESS_MR")){
            fileFormatName="Market Risk"
          }
          else if(code.includes("STRESS_LR")){
            fileFormatName="Liquidity Risk"
          }
          else if(code.includes("STRESS_IR")){
            fileFormatName="Interest Rate Risk"
          }
        if(fileFormatName==="Stress Shock" || fileFormatName==="Market Risk" || fileFormatName==="Liquidity Risk" || fileFormatName==="Interest Rate Risk"){
          link.href=environment.baseUrl+"/apis/stressTesting/downloadFileFormat/"+fileFormatName;      
          link.click();
          this.clientPortalHeaderComponent.panelForm1.toggleSlider();
          this.toastService.showSuccess('success','Download Begin Shortly');
        }else{
          this.clientPortalHeaderComponent.panelForm1.toggleSlider();
          this.toastService.showError('error','Requested document is not available!');
        }
        
       }


       dateEmit(date){
        this.asofdate=date;
        this.workflowUpdatedUrl=environment.reportUrl+(this.stressShockDetail.reportUrl).replace('false','true');
        if (this.stressShockDetail.reportUrl!=null) {

          
          if (this.stressShockDetail.reportUrl.includes("?")) {

            
            this.reportUrl = this.workflowUpdatedUrl+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
               
          }
          else{
           
              this.reportUrl= this.workflowUpdatedUrl+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
            
          }
          console.log("report url ",this.reportUrl);

          if(!this.reportUrl.includes("open-view/")){

          }

        }
         }

 selectFilterList(){
  this.ShockMasterService.getBranchIndustryCreditRatingList().subscribe(
    (res)=>{
  
    this.branchList=res["branch"]
    this.creditRatingList=res["creditRating"]
    this.industryList=res["idustry"]

    console.log("branchList",this.branchList);
    console.log("creditRatingList",this.creditRatingList);
    console.log("industryList",this.industryList);
   this.branchList.forEach(item =>{
    this.branchDropDown.push({ key: item, value: item})
   })
   this.creditRatingList.forEach(item =>{
    this.creditRatingDropDown.push({ key: item, value: item})
   })
   this.industryList.forEach(item =>{
    this.industryDropDown.push({ key: item, value: item})
   })
   console.log("branchDropDown",this.branchDropDown);
   console.log("creditRatingDropDown",this.creditRatingDropDown);
   console.log("industryDropDown",this.industryDropDown);
   
    },
    (err) => {
      this.toastService.showError(err.error.message, "error");
      
    }
    );
}
  
filterApply(){
  console.log("this.filterForm on apply",this.filterForm)
  this.zohocriteriaURL='';
    
    if(this.stressShockDetail.rawUrl!=null){
      this.rawUrl=environment.reportUrl+(this.stressShockDetail.rawUrl).replace('false','true');

  if((this.filterForm.controls.branch.value.value!=null) || this.filterForm.controls.branch.value.value!=undefined){
    this.zohocriteriaURL=this.zohocriteriaURL+" and %22branch%22='"+this.filterForm.controls.branch.value.value+"'"
  }
  if((this.filterForm.controls.industry.value.value!=null) || this.filterForm.controls.industry.value.value!=undefined){
    this.zohocriteriaURL=this.zohocriteriaURL+" and %22industry%22='"+this.filterForm.controls.industry.value.value+"'"
  }
  if((this.filterForm.controls.creditRating.value.value!=null) || this.filterForm.controls.creditRating.value.value!=undefined){
    this.zohocriteriaURL=this.zohocriteriaURL+" and %22credit_rating%22='"+this.filterForm.controls.creditRating.value.value+"'"
  }
  
  if (this.stressShockDetail.rawUrl.includes("?")){
    this.rawUrl = this.rawUrl+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId+this.zohocriteriaURL;
    console.log("rawUrl on filter Apply if",this.rawUrl)
  }
  else{
    this.rawUrl= this.rawUrl+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId+this.zohocriteriaURL;
    console.log("rawUrl on filter Apply else",this.rawUrl)
  }

  this.saveFiltersinWorkflowParam();
  }else{
    this.rawUrl=null;
  }
} 


saveFiltersinWorkflowParam(){
  const data={
    clientId:this.headerForm.controls.client.value.key,
    stressShockCode:this.stressShockCodeforParam,
    industry:this.filterForm.controls.industry.value.value,
    branch:this.filterForm.controls.branch.value.value,
    creditRating:this.filterForm.controls.creditRating.value.value,
    app_id: this.stressShockDetail.applicationId,
  }
 console.log("data for save in parameter",data);
 this.ShockMasterService.saveUpdateFiltersforClient(data).subscribe(
   (res)=>{
 
     this.toastService.showSuccess("Saved", "Successfully");
    
   },
   (err) => {
     this.toastService.showError(err.error.message, "error");
     
   }
   );
}
  

}
