import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reportHead-table',
  templateUrl: './reportHead-table.component.html',
  styleUrls: ['./reportHead-table.component.scss']
})
export class ReportHeadTableComponent implements OnInit {
 @Input() reportHeadList:any[];
 @Input() rolePermission:any;
 @Output() edit = new EventEmitter();
 @Output() delete = new EventEmitter();
 dataValue:any;
 visibleDelete: boolean = false;


  constructor() { }

  ngOnInit(): void {
  }

  editReportHead(rowData){
  this.edit.emit(rowData);
  }


  showPopupDelete(rowData) {
        this.dataValue = rowData;
        this.visibleDelete = true;
      }
    
      hidePopup() {
        debugger;
        this.dataValue = null;
        this.visibleDelete = false;
      }
    
      actionDo(str){
        this.deleteReportHeadId(this.dataValue);
        this.dataValue=null;
        this.visibleDelete=false;
      }
    
      deleteReportHeadId(rowData){
        this.delete.emit(rowData);
      }
}
