import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from 'src/app/core/services/title.service';
import { UserService } from 'src/app/user/services/user.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { RoleService } from '../../services/role.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {
  dataTransformationModel:any;
  roleLists:any;
   searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String | number, header:String }[];
  state:string;
  menu:string;
  submenu:string;
  constructor(private router:Router,private rolesService:RoleService,private userService:UserService,private toast:ToastService,private activeRoute: ActivatedRoute){

    this. searchByCols = [
      { field: "roleName", header: "Role Name" },
      { field: "roleDescription", header: "Description" },
      
    ];
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag");
  }

  ngOnInit(){
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );
   this.getRoles();
  }

  

  navigateToPage(data){
    console.log(data);
   // this.dataTransformationModel=data;
    const dataValue={
     "roleId": data.roleId,
     "roleName":data.roleName
    }
    let page = '/role';
        this.router.navigate([page], {
         queryParams: {...dataValue},
       });
   }
   
   addRole(data){
    console.log(data)
    const roleData={
      "roleName":data.roleName,
      "roleDescription":data.roleDescription
    }
    this.rolesService.saveRole(roleData).subscribe(
      (data) => {
        //this.addRolePopup = false;
        //this.roleForm.reset();
        this.getRoles();
        this.toast.showSuccess("Role SuccesFully Saved", "success");
      },
      (err) =>{
        console.log("error message: ",err);
        if(err.error.text!=null){
          this.toast.showError(err.error.text, "error");
        }
        else{
          this.toast.showError(err.error, "error");
        }
        
      } 
    );
  }

  getRoles(){
    this.userService.getRoleListData().subscribe(
      (data) => {
        this.roleLists=data;
        // this.roleLists.forEach(item=>{
        //   if(item.roleDescription==null){
        //     item.roleDescription=''
        //   }
        
        //  })
        console.log("rolefrombackend ",data)
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }


}
