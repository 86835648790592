import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LookupColumnListModel } from 'src/app/dashboard/models/lookupColumn.model';
import { extraColumnFields, LOOKUP_COLUMN_FIELDS } from 'src/app/lookup-tables/model/lookup-column-fields';
import { FormsModel } from 'src/app/models/forms.model';


@Component({
  selector: 'app-lookup-column-selector',
  templateUrl: './lookup-column-selector.component.html',
  styleUrls: ['./lookup-column-selector.component.scss']
})
export class LookupColumnSelectorComponent implements OnInit {

  lookupColumnListEdit:LookupColumnListModel;
  selectorForm: FormGroup = null;
  @Input() editLookupColumns;
  @Input() tableName;
  @Output() saveLookupColumn = new EventEmitter();
  @Input() permission:any;
  @Output() closePanel=new EventEmitter();
  // @Output() saveLookUp = new EventEmitter();
  enableEdit=false;
  extraFormModel: FormsModel = null;
  extraRulesForm: FormGroup = null;
  lookUpColumnForm: FormGroup = null;
  extraFormFields: any[];
  

  constructor() { 

    // this.selectorForm = new FormGroup({
    //   field1: new FormControl(null),
    //    });
  }

  ngOnInit(): void {
    this.extraFormFields = extraColumnFields();
    console.log(this.editLookupColumns,' child')
  }


    ngOnChanges(changes: SimpleChanges) {
      console.log("changes.tableName ",changes.tableName );
      if (changes && changes.tableName && changes.tableName.currentValue) {
        console.log(changes.tableName.currentValue,' indise table changes') 
         // const lookUp=changes.editLookUp.currentValue;
         this.extraFormModel=null;
        //  this.lookUpColumnForm.reset();
          this.checkType(changes.tableName.currentValue);
         // this.enableEdit = true;
         // this.checkForEdit() 
       }
     
      if (changes && changes.editLookupColumns && changes.editLookupColumns.currentValue) {
       console.log(changes.editLookupColumns.currentValue,' indise changes') 
         const lookUp=changes.editLookupColumns.currentValue;
         this.checkType(lookUp.tableName);
         this.enableEdit = true;
         this.checkForEdit() 
      }
      // if (changes && changes.newRule && changes.newRule.currentValue > 1) {
      //   this.resetForm();
      // }
    }
  
    checkForEdit() {
      if (this.editLookupColumns && this.enableEdit) {

        console.log("tableName",this.editLookupColumns.tableName);
      
        
        this.getExtraFieldsToPatch(this.editLookupColumns.tableName);
       // this.enableEdit = false;
       
      }
    }
  
    checkType(t) {
      switch (t) {
        // compare

        case 'accrualbasis_accrualbasiscode_list':
          this.extraFormModel = LOOKUP_COLUMN_FIELDS["accrualbasis_accrualbasiscode_list"] as FormsModel;
          break;

        case 'call_report_code_callreportcode_list':
          this.extraFormModel = LOOKUP_COLUMN_FIELDS["call_report_code_callreportcode_list"] as FormsModel;
          break;

        case 'reprice_index_code_repriceindex_list':
          this.extraFormModel = LOOKUP_COLUMN_FIELDS["reprice_index_code_repriceindex_list"] as FormsModel;
          break;

        case 'product_type_callreportcode_list':
          this.extraFormModel = LOOKUP_COLUMN_FIELDS["product_type_callreportcode_list"] as FormsModel;
          break;

        case 'rate_change_frequency_ratechangefrequencycode_list':
          this.extraFormModel = LOOKUP_COLUMN_FIELDS["rate_change_frequency_ratechangefrequencycode_list"] as FormsModel;
          break;

          case 'state_statecode_list':
          this.extraFormModel = LOOKUP_COLUMN_FIELDS["state_statecode_list"] as FormsModel;
          break;
       
      }
    }
  
    getForm(form: FormGroup) {
      this.extraRulesForm = form;
     
    }
  
    getExtraFieldsToPatch(type) {
      let data = null;
      switch (type) {

        case 'accrualbasis_accrualbasiscode_list':
          data = this.findField("accrualbasis_accrualbasiscode_list");
          break;

        case 'call_report_code_callreportcode_list':
          data = this.findField("call_report_code_callreportcode_list");
          break;

          case 'reprice_index_code_repriceindex_list':
          data = this.findField("reprice_index_code_repriceindex_list");
          break;

        case 'product_type_callreportcode_list':
          data = this.findField("product_type_callreportcode_list");
          break;

        case 'rate_change_frequency_ratechangefrequencycode_list':
          data = this.findField("rate_change_frequency_ratechangefrequencycode_list");
          break;

          case 'state_statecode_list':
            data = this.findField("state_statecode_list");
            break;
       
      }
      console.log("check1 ",data);
      console.log("type ",type);
  
       if(type!=='branch_code'){
        console.log("checking code");
      data = this.mapValuesToObject(data);
      console.log("check2"+data);
   
      this.extraRulesForm.patchValue(data);
      }
      // }
    }
  
    mapValuesToObject(obj) {
      let data = {};
    
      for (let o in obj) {
       
          data[o] = this.editLookupColumns[o];
      
      }
     
      return data;
    }
  
   
    reFormatValues(value) {
      return { key: value, value };
    }
  
    
    findField(t) {
      const { fields } = this.extraFormFields.find(d => d.key === t);
      return this.convertArrayToField(fields);
    }
    convertArrayToField(field) {
      let obj = {};
      for (let f of field) {
        obj[f] = null;
      }
      return obj;
    }
  
    
    saveData() {
     
     // console.log("Inside Save this.extraRulesForm" ,this.extraRulesForm.value);
      const extraRules=this.extraRulesForm!==null?this.extraRulesForm.value:'';
      console.log("Inside Save");
// console.log("this.lookUpColumnForm.value",this.lookUpColumnForm);

      let data = {
        // ...this.removeKeyValueFormat(this.lookUpColumnForm.value),
        
          ...this.removeKeyValueFormat(extraRules)
    
      };  
      //console.log(data)
     this.saveLookupColumn.emit(data);
      this.closePanel.emit();
    
     
    }

    resetForm(){
      this.resetData();
      this.lookupColumnListEdit=null;
    }
    
  
    removeKeyValueFormat(data) {
      let result = {};
      try {
        for (let o in data) {
          if(data[o]==null){
            result[o] = null;
          }
          else if (data[o].key && data[o]!=undefined && data[o]!=null) {        
            result[o] = data[o].key;   
          } else {         
            result[o] = data[o];         
          }
        }
        
        return result;
      } catch {
        return data;
      }
    }
  
    resetData(){
      this.enableEdit = false;
      this.editLookupColumns=null;
          if(this.extraRulesForm!==null){
          this.extraRulesForm.reset();
          }
    }
   
  



}
