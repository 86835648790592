import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService } from 'src/app/dashboard/services/client.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-role-parameter',
  templateUrl: './role-parameter.component.html',
  styleUrls: ['./role-parameter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleParameterComponent implements OnChanges{
  @Input() roleList: {key: any; value: any }[]=[];
 // reportList:{key: any; value: any }[]=[];
 // @Input() clientList:{key: any; value: any }[]=[];
  @Output() fetch = new EventEmitter();
  @Output() closePanel=new EventEmitter();
  @Input() reportForm:any;
  parameterForm:FormGroup;
  url:string=null;
  env:any;
  viewList:any=[];
  constructor(private reportDashboardService:ReportDashboardService,
    private sspApplicationService: SspApplicationService) {
    this.parameterForm = new FormGroup({
      role:new FormControl(null, [Validators.required]),
     });

  }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.reportForm && changes.reportForm.currentValue && changes.reportForm.firstChange) {
      const d: any = changes.reportForm.currentValue;
      this.parameterForm.patchValue({
        client:  { key: d.client.key, value: d.client.value },
         groupData: { key: d.GroupData.key, value: d.GroupData.value },
        // reportData: { key: d.ReportData.key, value: d.ReportData.value },
        // viewData:  { key: d.viewData.key, value: d.viewData.value },
      });
    //  this.enableEdit = true;
      console.log("on changes form",this.parameterForm.value)
    }
  }
  
  fetchRoleData() {
   // this.closePanel.emit();
    if(this.parameterForm.valid){
      console.log("inside fetch")
      this.fetch.emit(this.parameterForm.controls);
  } else{
   // this.toast.showError("All Fields Are Mandatory", "error");
  }

  this.closePanel.emit()
  
}

// onGroupChange(event){

//   console.log("evetData  ",event.target.value)
//  // this.headerForm.controls.ReportData.reset();
//   //this.headerForm.controls.viewData.reset();
//   //this.env=null;
//   //this.viewList=[];
//   const dataVal={
//     "groupId":this.parameterForm.controls.groupData.value.key,
//     "reportMode":"readytouse"
//   }

//   this.reportDashboardService.getReportByGroupId(dataVal).subscribe(

//     res => {
//       // this.loader.hideLoader();
      
//       this.reportList=res;
//       console.log("this.reportList  ",this.reportList);
//       this.reportList=reMapForAutoComplete( this.reportList,"appId","appName");
//        console.log("all report  ", this.reportList);

//       },
//       err => {
//        // console.log('url',err);
//       // this.toast.showError("failed to get groups", "error");

//       }
//   )


// }

// getViewList(data){

     
//   console.log("data  ",data)
//   //this.headerForm.controls.viewData.reset();
//   this.env=null;
//   const reportId={
//     appId:this.parameterForm.controls.reportData.value.key

//   };
//   this.sspApplicationService.getInstanceByAppId(reportId).subscribe(

//     data=>{
//     this.viewList=data
//     console.log("this.viewList  ",this.viewList)
//     this.viewList=reMapForAutoComplete( this.viewList,"instanceUrl","instanceName");
//        console.log("all viewList  ", this.viewList);

//        console.log("viewList[0]  ",this.viewList[0])
//   this.parameterForm.patchValue({
//     viewData: {key:this.viewList[0].key ,value:"Default"}

//   })
//  // this.getreport(this.viewList[0]);

//     },
//     err=>{
//         // console.log('url',err);
//     //    this.toast.showError("failed to get views", "error");
 
//        }
   
//   )
//   // console.log("viewList  ",this.viewList[1])
//   // this.headerForm.patchValue({
//   //   viewData: this.viewList[1]

//   // })

// }


// getreport(data){
//   this.url=this.viewList[0].key;
//   console.log("client id is",this.parameterForm.controls.client.value.key)
//   this.env= this.viewList[0].key+"?INCLUDETITLE=false";
  
//   // if(this.clientId==0 || this.clientId!=undefined || this.clientId!=null){
//   //   this.env= data.key+"?INCLUDETITLE=false&ZOHO_CRITERIA=%22company_id%22%3D"+this.clientId;
//   // }
 
//   if (!this.env.includes('https://zoho-server.uventure.in/open-view/')) { 
//     this.env=null;
  
//   }
//   this.parameterForm.controls.url.setValue(this.env);
//   console.log('url last value',this.env);
  
// }



}
