import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { StressScenarioValuesSelectorComponent } from './components/stressScenarioValues-selector/stressScenarioValues-selector.component';
import { StressScenarioValuesHeaderComponent } from './components/stressScenarioValues-header/stressScenarioValues-header.component';
import { StressScenarioValuesTableComponent } from './components/stressScenarioValues-table/stressScenarioValues-table.component';

@NgModule({
  declarations: [
    StressScenarioValuesSelectorComponent,
    StressScenarioValuesTableComponent,
    StressScenarioValuesHeaderComponent
    
  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    StressScenarioValuesSelectorComponent,
    StressScenarioValuesTableComponent,
    StressScenarioValuesHeaderComponent
    ]
})
export class StressScenarioValuesCompModule { }
