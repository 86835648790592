import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import {LookupColumnListComponent} from "../lookupColumn/components/lookup-column-list/lookup-column-list.component";
import { LookupColumnSelectorComponent } from './components/lookup-column-selector/lookup-column-selector.component';
import { LookupColumnHeaderComponent } from './components/lookup-column-header/lookup-column-header.component';
import { UiModule } from "../ui/ui.module";


@NgModule({
  declarations: [

    LookupColumnListComponent,
    LookupColumnSelectorComponent,
    LookupColumnHeaderComponent
  ],
  imports: [SharedModule,UiModule],
  exports: [
    LookupColumnListComponent,
    LookupColumnSelectorComponent,
    LookupColumnHeaderComponent 
  ],
  providers: []
})
export class LookupColumnModule {}
