import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
//import { DepartmentModel } from 'src/app/dashboard/models/Department.model';

@Component({
  selector: 'app-reportConfiguration-selector',
  templateUrl: './reportConfiguration-selector.component.html',
  styleUrls: ['./reportConfiguration-selector.component.scss']
})
export class ReportConfigurationSelectorComponent implements OnInit {
  @Input() reportLists: any[];
  headerForm: FormGroup = null;
  companyName:any[];
  companyId:any;
  @Input() currencyData: {key: any; value: any }[]=[];
  @Output() save=new EventEmitter();
  selectedReports: { reportId: any; reportName: any }[] = [];
 
  @Output() closePanel=new EventEmitter();
  reportConfigurationForm: FormGroup = null;
  @Input() editData:any[];
  @Input() frequencyList: {key: any; value: any }[]=[];
  constructor(private toast:ToastService, private cookieService: CookieService, private fileService: FileService,private datePipe: DatePipe) {

    // this.headerForm = new FormGroup({
    //   clientId: new FormControl(null, [Validators.required]),
    // });

    this.reportConfigurationForm=new FormGroup({
      reportConfigurationId:new FormControl(null),
      clientId: new FormControl(null,{validators:[Validators.required],updateOn: "blur"}),
      // reportId: new FormControl(null,{validators:[Validators.required]}),
      reportId:new FormControl(null,{validators:[Validators.required]} ),
      returnName: new FormControl(null),
      returnCode:new FormControl(null),
      nameOfReportingInstitution:new FormControl(null),
      bank_fICode:new FormControl(null),
     
      institutionType:new FormControl(null),
      reportingFrequency:new FormControl(null),
      frequencyList:new FormControl(null),

      reportingStartDate:new FormControl(null,{validators:[Validators.required]}),
      reportingEndDate:new FormControl(null,{validators:[Validators.required]}),

      reportingCurrency: new FormControl(null,[Validators.required]),
     
      reportingScale:new FormControl(null, {validators:[Validators.maxLength(15)],updateOn: "blur"}),
      scaleValue:new FormControl(null, {validators:[Validators.maxLength(10)],updateOn: "blur"}),
      taxonomyVersion:new FormControl(null),
      toolName:new FormControl(null),
      toolVersion:new FormControl(null),
      reportStatus:new FormControl(null),
      dateOfAudit:new FormControl(null),
      generalRemarks:new FormControl(null),
    
    });
  
   }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
  // this.reportConfigurationForm.get('statusMap').setValue(defaultMap);

  this.fileService.getClientByUserId().subscribe(
    (data) =>{
    
      for(var i=0;i<data.length;i++){
        console.log("data  ",data[i].clientId)
        this.companyId=data[i].clientId
        this.companyName=data[i].clientName
      }
      this.reportConfigurationForm.get("clientId").setValue({
        key: this.companyId,
        value: this.companyName
      });

       this.cookieService.set('clientId',this.reportConfigurationForm.controls.client.value.key);
       this.cookieService.set('clientName',this.reportConfigurationForm.controls.client.value.value);

    },
    (err)=>{
      this.toast.showError(err.error.message, "error");
    }
    );

  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);
     this.reportConfigurationForm.get("reportId").setValue({
      key:rowData.reportId,
      value:rowData.reportId
     });
     this.reportConfigurationForm.get("clientId").setValue({
      key:rowData.clientId,
      value:rowData.clientId
     });

     this.reportConfigurationForm.get("reportingFrequency").setValue({
      key:rowData.reportingFrequency,
      value:rowData.reportingFrequency
     });
     this.reportConfigurationForm.get("reportingCurrency").setValue({
      key:rowData.reportingCurrency,
      value:rowData.reportingCurrency
    });
     
      this.reportConfigurationForm.patchValue(
        {
          reportConfigurationId:rowData.reportConfigurationId,
          returnName:rowData.returnName,
          returnCode:rowData.returnCode,
          nameOfReportingInstitution:rowData.nameOfReportingInstitution,
          bank_fICode:rowData.bank_fICode,
          institutionType:rowData.institutionType,
          
          reportingStartDate: this.datePipe.transform(rowData.reportingStartDate,"yyyy-MM-dd"),
          reportingEndDate:this.datePipe.transform(rowData.reportingEndDate,"yyyy-MM-dd"),
          //reportingCurrency:rowData.reportingCurrency,
          reportingScale:rowData.reportingScale,
          scaleValue:rowData.scaleValue,
          taxonomyVersion:rowData.taxonomyVersion,
          toolName:rowData.toolName,
          toolVersion:rowData.toolVersion,
          reportStatus:rowData.reportStatus,
          dateOfAudit: this.datePipe.transform(rowData.dateOfAudit,"yyyy-MM-dd"),
          generalRemarks:rowData.generalRemarks

           
          
        });
    }
  
  }
  


  saveReportConfiguration(flag){
  console.log("this.report configuration form value ",this.reportConfigurationForm.value)
   // console.log("headerForm ",this.headerForm.value)
    //this.companyName=this.headerForm.controls.client.value.key;
    //this.getTransformList(this.headerForm.controls.client.value.key,'onChange');
   // this.getTransformList(0,'onChange');
   //this.panelFilter.toggleSlider()
   if(flag=='onLoad'){
    if(this.reportConfigurationForm.valid){
      console.log("reportConfigurationFormValid",this.reportConfigurationForm.valid);
          
           let validate=this.validationOnDate(this.reportConfigurationForm.controls.reportingStartDate.value,this.reportConfigurationForm.controls.reportingEndDate.value);
           if(validate===true){
            this.save.emit(this.reportConfigurationForm.value);
             
           }
           if(validate===false){
             this.toast.showWarn('error','date range incorrect')
             
           }
             }
               else{
                 this.toast.showError("Please Fill Required Fields","error");
             }
   
  this.closePanel.emit();
    }


//   if(this.reportConfigurationForm.valid){ 
// this.save.emit(this.reportConfigurationForm.value);
//   }
//     else{
//       this.toast.showError("Please Fill Required Fields","error");
//   }

  // this.closePanel.emit();
    }
    cancelButton(){
      this.formReset();
      this.closePanel.emit()
    }

    formReset(){
      this.reportConfigurationForm.reset();
      //this.headerForm.reset();
     
      this.fileService.getClientByUserId().subscribe(
        (data) =>{
        
          for(var i=0;i<data.length;i++){
            console.log("data  ",data[i].clientId)
            this.companyId=data[i].clientId
            this.companyName=data[i].clientName
          }
          this.reportConfigurationForm.get("clientId").setValue({
            key: this.companyId,
            value: this.companyName
          });
    
           this.cookieService.set('clientId',this.reportConfigurationForm.controls.client.value.key);
           this.cookieService.set('clientName',this.reportConfigurationForm.controls.client.value.value);
    
        },
        (err)=>{
          this.toast.showError(err.error.message, "error");
        }
        );
    //   const defaultMap = {
    //    key: "Active",
    //    value: "Active",
    //  };
    // this.reportConfigurationForm.get('statusMap').setValue(defaultMap);
    }

  //   get status() {
  //     const status = [
  //       "Active",
  //       "Inactive",
  //     ];
  //    return status.map((t) => ({ key: t, value: t }));
  //   }

  changeEndDate(){
    // this.headerForm.controls.toDate.setValue(e);
     let validate=this.validationOnDate(this.reportConfigurationForm.controls.reportingStartDate.value,this.reportConfigurationForm.controls.reportingEndDate.value);
     if(validate===true){
       
       this.save.emit(this.reportConfigurationForm.value);
     }
     if(validate===false){
       this.toast.showWarn('error','date range incorrect')
       
     }
   }
   
   validationOnDate(reportingStartDate,reportingEndDate){
     if(reportingStartDate && reportingEndDate){
     if(new Date(reportingStartDate)>new Date(reportingEndDate)){
     
         return false;
       }
       return true;
       
     }else{
       return false;
     }
   }
    
  // multiSelectReportChange(eventData) {
  //   this.selectedReports = eventData.value;
  //   console.log("Value Updated Is:", this.selectedReports);
  // }

  // frequencyType(){
  //   const frequencyList=[
  //     "Daily",
  //     "Weekly",
  //     "Monthly",
  //     "Quaterly",
  //     "Half Yearly",
  //     "Yearly",
      
  //   ];
  //   return frequencyList.map((t) => ({ key: t, value: t }));

  // }



}
