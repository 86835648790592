import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericFileModel } from '../models/genericFile.model';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor(private http: HttpClient) { }

  
//   readFileHeaders(clientApplicationFileId){
//     return this.http.get<GenericFileModel[]>(environment.baseUrl + "/apis/generic/readFile/"+clientApplicationFileId);
// }

  readFileHeaders(data){
    return this.http.post<GenericFileModel[]>(environment.baseUrl + "/apis/generic/readFile",data);
  }

getAllDatabaseDataTypeList(){
  return this.http.get<any[]>(environment.baseUrl +"/apis/generic/getAllDatabaseDataType");
}

saveFileData(data){
  return this.http.post<GenericFileModel[]>(environment.baseUrl + "/apis/generic/createTable",data);
}

getAllExisistingTables(){

  return this.http.get<any[]>(environment.baseUrl +"/apis/generic/getAllExisistingTables");
  
  }


  getGenericDataByGenericAppId(clientApplicationFileId){
    return this.http.get<any>(environment.baseUrl + "/apis/generic/getFileDataByGenericAppId/"+clientApplicationFileId);
  }

}
