import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ReportHeadModel } from 'src/app/dashboard/models/reportHead.model';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';

@Component({
  selector: 'app-reportHead-selector',
  templateUrl: './reportHead-selector.component.html',
  styleUrls: ['./reportHead-selector.component.scss']
})
export class ReportHeadSelectorComponent implements OnInit {

  @Output() save=new EventEmitter();
 
  @Output() closePanel=new EventEmitter();
  reportHeadForm: FormGroup = null;
  @Input() editData:any[];
  @Input() reportLists: any[];
  radioRiskWeight: string;
  radioConversionFactor: string;
  // radioRiskWeightEnable: string;
  // riskWeight: String="yes";
  // radioScheduleWorkflowSelected:string="yes";
  constructor(private toast:ToastService,private cookieService: CookieService, private sspapplicationService: SspApplicationService,private datePipe: DatePipe) {
    this.reportHeadForm=new FormGroup({
      reportHeadId: new FormControl(null),
      reportHeadName: new FormControl(null,[Validators.required,Validators.minLength(5),Validators.maxLength(500)]),
      reportShortCode:new FormControl(null,[Validators.required,Validators.minLength(2),Validators.maxLength(10)]),
      report:new FormControl(null),
      riskWeightList:new FormControl(null),
      conversionFactorList:new FormControl(null),
      riskWeight:new FormControl(null),
      riskWieghtRequired:new FormControl(null),
      conversionFactorFlag:new FormControl(null),
      conversionFactor:new FormControl(null),
      parent:new FormControl(null),
      levelNo:new FormControl(null),
      liveToDate: new FormControl(null),
      liveFromDate: new FormControl(null), 
      modifiedBy:new FormControl(null),
      modifiedAt:new FormControl(null)
    
    });
  
   }

  ngOnInit(): void {
   
this.callRadioForDisable();
   
  }

callRadioForDisable(){
    this.radioRiskWeight='No';
    // this.radioRiskWeightEnable='No';
   this.riskWeightDisable();

   this.radioConversionFactor='No';
   this.conversionFactorDisable();
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);
     this.reportHeadForm.get("report").setValue({
      key:rowData.appId,
      value:rowData.appId
     });
     this.reportHeadForm.get("riskWeight").setValue({
      key:rowData.riskWeight,
      value:rowData.riskWeight
     });
     this.reportHeadForm.get("conversionFactor").setValue({
      key:rowData.conversionFactor,
      value:rowData.conversionFactor
     });
     if(rowData.riskWieghtRequired){
   //  this.radioRiskWeightDisable='No';
      this.radioRiskWeight='Yes';
      this.riskWeightEnable();
    }
    if(!rowData.riskWieghtRequired){
      
      this.riskWeightDisable();
    this.radioRiskWeight='No';
  }

  if(rowData.conversionFactorFlag){
       this.radioConversionFactor='Yes';
       this.conversionFactorEnable();
     }
     if(!rowData.conversionFactorFlag){
       
       this.conversionFactorDisable();
     this.radioConversionFactor='No';
   }
         
      this.reportHeadForm.patchValue(
        {
          reportHeadId:rowData.reportheadId,
          
          reportHeadName:rowData.reportHeadName,
          reportShortCode:rowData.reportShortCode,
          // radioRiskWeight:rowData.radioRiskWeight,
         
          // riskWeight:rowData.riskWeight,
          // conversionFactor:rowData.conversionFactor,
          // riskWieghtRequired:rowData.riskWieghtRequired,
          parent:rowData.parent,
          levelNo:rowData.levelNo,
          liveFromDate:this.datePipe.transform(rowData.liveFromDate,"yyyy-MM-dd"),
          liveToDate:this.datePipe.transform(rowData.liveToDate,"yyyy-MM-dd"),
          // createdBy:rowData.createdBy,
          modifiedBy:rowData.modifiedBy,
          modifiedAt:rowData.modifiedAt
        });
    }
  
  }

    getReportLists(){
  // this.reportLists;
 }
 
 riskWeightData(){
  const riskWeightList=[
    "0.00",
    "10.00",
    "20.00",
    "30.00",
    "40.00",
    "50.00",
    "60.00",
    "70.00",
    "80.00",
    "90.00",
    "100.00",
  ];
  return riskWeightList.map((t) => ({ key: t, value: t }));

}
conversionFactorData(){
  const conversionFactorList=[
    "0.00",
    "10.00",
    "20.00",
    "30.00",
    "40.00",
    "50.00",
    "60.00",
    "70.00",
    "80.00",
    "90.00",
    "100.00",
  ];
  return conversionFactorList.map((m) => ({ key: m, value: m }));

}



  saveReportHead(flag){
    
  console.log("this.reportHeadform on save.value ",this.reportHeadForm.value)
//   if(this.reportHeadForm.valid){ 
   
// this.save.emit(this.reportHeadForm.value);
//   }
//     else{
//       this.toast.showError("Please Fill Required Fields","error");
//   }

if(this.reportHeadForm.invalid){
  if(this.reportHeadForm.controls.reportHeadName.invalid){
    this.toast.showWarn('Report Head Name is mandatory','error');
        this.closePanel.emit();
        return;
  }
  if(this.reportHeadForm.controls.reportShortCode.invalid){
    this.toast.showWarn('Report Head Short Code is mandatory','error');
        this.closePanel.emit();
        return;
  }
}
  if(flag=='onLoad'){
    let validate=this.validationOnDate(this.reportHeadForm.controls.liveFromDate.value,this.reportHeadForm.controls.liveToDate.value);
    if(validate===true){
      if(this.reportHeadForm.valid){ 
   console.log("reportHeadValid",this.reportHeadForm.valid);
        this.save.emit(this.reportHeadForm.value);
          }
            else{
              this.toast.showError("Please Fill Required Fields","error");
          }
      
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
  this.closePanel.emit();
    }
  }

    formReset(){
      this.reportHeadForm.reset();
    }

    changeToDate(){
      // this.headerForm.controls.toDate.setValue(e);
       let validate=this.validationOnDate(this.reportHeadForm.controls.liveFromDate.value,this.reportHeadForm.controls.liveToDate.value);
       if(validate===true){
         
         this.save.emit(this.reportHeadForm.value);
       }
       if(validate===false){
         this.toast.showWarn('error','date range incorrect')
         
       }
     }
     
     validationOnDate(liveFromDate,liveToDate){
       if(liveFromDate && liveToDate){
       if(new Date(liveFromDate)>new Date(liveToDate)){
       
           return false;
         }
         return true;
         
       }else{
         return false;
       }
     }
     
    
//  showHideOccursOnceRadioButton(data: ReportHeadModel) {
  
    
      
//        this.radioRiskWeight='yes';
      
  
  
//  }
riskWeightEnable() {
  document.getElementById("riskWeightValue").style.display=""
  this.reportHeadForm.patchValue({
    riskWieghtRequired:true
    
  })

}
riskWeightDisable() {
  document.getElementById("riskWeightValue").style.display="none"
  this.reportHeadForm.patchValue({
    riskWieghtRequired:false
  })

}

conversionFactorEnable() {
  document.getElementById("conversionFactorValue").style.display=""
  this.reportHeadForm.patchValue({
    conversionFactorFlag:true
    
  })

}
conversionFactorDisable() {
  document.getElementById("conversionFactorValue").style.display="none"
  this.reportHeadForm.patchValue({
    conversionFactorFlag:false
  })

}

}
