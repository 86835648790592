import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService } from 'src/app/dashboard/services/client.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-report-info',
  templateUrl: './report-info.component.html',
  styleUrls: ['./report-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportInfoComponent {
  @Input() reportList:any;
  status:any;
  constructor() {}
  ngOnInit(): void {}

  getStatusReport(status:any){
    return status === true ? 'Active' : 'Inactive'
  }
 
}

