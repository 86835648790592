import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { RequestModel } from "../models/request.model";

@Injectable({
    providedIn: 'root'
  })
export class RequestService {
  
  
  /**
   * @param  {HttpRequest} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  userRequest(data){
    return this.http.post<RequestModel[]>(environment.baseUrl + "/apis/userRequest",data);
} 

getAllUserRequest(){
  return this.http.get<any>(environment.baseUrl + "/apis/allUserRequest");
}

getRequestByUserId() {
  return this.http.get<any>(environment.baseUrl + "/apis/requestByUserId");
}

sendMessage(data){
  return this.http.post<any>(environment.baseUrl + "/apis/sendMessage",data);
}

fetchMessage(userRequestId){
  return this.http.get<any[]>(environment.baseUrl + "/apis/messageByRequestId/"+userRequestId);
}

}

