import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges , OnChanges  } from '@angular/core';
import { FormGroup,FormBuilder, FormControl,Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';


@Component({
  selector: 'app-notification-selector',
  templateUrl: './notification-selector.component.html',
  styleUrls: ['./notification-selector.component.scss']
})
export class NotificationSelectorComponent implements OnInit,OnChanges{
  
  notificationForm: FormGroup=null;
  @Output() saveNotification = new EventEmitter();
  @Output() resetNotification = new EventEmitter();
  @Input()  editNotificationList;
  @Output() closePanel=new EventEmitter();
  @Input() rolePermission;
  @Input() usersList;
  @Input() reportsList;
  selectedUsers: { userId: any; userName: any }[] = [];
  selectedReports: { reportId: any; reportName: any }[] = [];
  

  constructor(private toast: ToastService) {

    this.notificationForm= new FormGroup({
      notificationId:new FormControl(null),
      notificationName: new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(50)]),
      statusMap: new FormControl(null,Validators.required),
      users: new FormControl(null),
      reports: new FormControl(null),
    });
   }

  ngOnInit(): void {

  //   const defaultMap = {
  //     key: "Active",
  //     value: "Active",
  //   };
  //  this.notificationForm.get('statusMap').setValue(defaultMap); 
  }




  ngOnChanges(changes: SimpleChanges) {

    if (changes && changes.editNotificationList && changes.editNotificationList.currentValue) {
      let currentChangeValue = changes.editNotificationList.currentValue;
      let usersList = currentChangeValue.notificationUsersList;
      this.selectedUsers = [];
      for (var i = 0; i < usersList.length; i++) {
        this.selectedUsers.push({ userId: usersList[i].userId, userName: usersList[i].userName });
        console.log("Selected users Are:", this.selectedUsers);
      }

      let reportList = currentChangeValue.notificationReportsList;
      this.selectedReports = [];
      for (var i = 0; i < reportList.length; i++) {
        this.selectedReports.push({ reportId: reportList[i].reportId, reportName: reportList[i].reportName });
        console.log("Selected reports Are:", this.selectedReports);
      }

      const statusActive = {
        key: "Active",
        value:"Active",
      };
      const statusInactive = {
        key: "Inactive",
        value:"Inactive",
      };
   
        //console.log("edit user data"+this.editUser)
       if(currentChangeValue.notification.status===false){
        this.notificationForm.patchValue({
          
          notificationName: currentChangeValue.notification.notificationName,
          
          statusMap:statusInactive,

          users: this.selectedUsers,

          reports: this.selectedReports
          
        });
       }else{
        this.notificationForm.patchValue({
        
          notificationName: currentChangeValue.notification.notificationName,
        
          statusMap:statusActive,

          users: this.selectedUsers,

          reports: this.selectedReports
         
        });
      }




  }

}


  get status() {
    const status = [
      "Active",
      "Inactive",
    ];
   return status.map((t) => ({ key: t, value: t }));
  }


  saveNotificationValues(){
    this.closePanel.emit();
    if(this.notificationForm.controls.notificationName.value==null || this.notificationForm.controls.notificationName.value==undefined || this.notificationForm.controls.notificationName.value==""){
      this.toast.showWarn('Enter Notification Name ','error');
      return;
    }

    // if(this.notificationForm.controls.statusMap.value==null || this.notificationForm.controls.statusMap.value==undefined || this.notificationForm.controls.statusMap.value==""){
    //   this.toast.showWarn('Select Status ','error');
    //   return;
    // }
    this.notificationForm.patchValue({
      users:this.selectedUsers
    });
    this.notificationForm.patchValue({
      reports:this.selectedReports
    });

    if (this.notificationForm.valid) {
      let statusMap =this.notificationForm.controls.statusMap.value
      console.log("statusMap",statusMap)
    if (statusMap=="Select Status *") {
      this.toast.showError("Please Fill Required Fields","error");
    }
    if(statusMap!=="Select Status *"){
      console.log("saving values ",this.notificationForm.value);
      this.saveNotification.emit(this.notificationForm.value);
    }   
}
     else{
       this.toast.showError("Please Fill Required Fields","error");
   }

    
   

  }

  resetForm(){

    this.notificationForm.reset();
    this.selectedUsers = [];
    this.selectedReports = [];
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.notificationForm.get('statusMap').setValue(defaultMap); 
  }

  resetFormData(){
    this.closePanel.emit();
    this.notificationForm.reset();
    this.selectedUsers = [];
    this.selectedReports = [];
  //   const defaultMap = {
  //     key: "Active",
  //     value: "Active",
  //   };
  //  this.notificationForm.get('statusMap').setValue(defaultMap);
    // const statusReset = {
    //   key: "null",
    //   value:"null",
    // };
    // this.notificationForm.patchValue({
    //   statusMap:statusReset

    // })

    this.resetNotification.emit("reset");
  }

  multiSelectUserChange(eventData) {
    this.selectedUsers = eventData.value;
    console.log("Value Updated Is:", this.selectedUsers);
  }

  multiSelectReportChange(eventData) {
    this.selectedReports = eventData.value;
    console.log("Value Updated Is:", this.selectedReports);
  }

}
