import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { StressScenarioModel } from "../models/stressScenario.model";

@Injectable({
    providedIn: 'root'
  })
export class InterestRateRiskScenarioService {
  
  
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  saveOrUpdate(data){
    return this.http.post<StressScenarioModel[]>(environment.baseUrl + "/apis/createIRRScenario",data);
} 


getAllInterestRateRiskScenarioList(){
  return this.http.get<StressScenarioModel[]>(environment.baseUrl + "/apis/getAllIRRScenarios");
  // return this.http.get<DepartmentModel[]>(environment.baseUrl + "/apis/department/getDepartmentAll");
}

deleteInterestRateRiskScenarioById(irr_scenario_id) {
    return this.http.delete<StressScenarioModel[]>(environment.baseUrl + "/apis/deleteIRRScenarioId/"+irr_scenario_id);
  }




  }

