import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { DepartmentModel } from "../models/department.model";

@Injectable({
    providedIn: 'root'
  })
export class DepartmentService {
  
  
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  saveOrUpdate(data){
    return this.http.post<DepartmentModel[]>(environment.baseUrl + "/apis/department/createDepartment",data);
} 

// getDepartmentList(data){
//     return this.http.get<DepartmentModel[]>(environment.baseUrl + "/apis/getDepartmentAll"+data);
// }
getAllDepartmentList(){
  return this.http.get<DepartmentModel[]>(environment.baseUrl + "/apis/department/getDepartmentAll");
}

// deleteDepartmentId(data){
//    return this.http.delete<DepartmentModel[]>(environment.baseUrl + "/apis/department/deleteDepartmentId", data);
//  }

 deleteDepartmentId(data) {
  return this.http.post<any>(environment.baseUrl + "/apis/department/deleteDepartmentId", data);
}
// getDepartmentFileMasterListWithClient(){
//   return this.http.get<DepartmentFileMasterModel[]>(environment.baseUrl + "/apis/files/getClientMasterListWithClient");
// }

}

