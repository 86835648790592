import { Component, EventEmitter, Input, OnInit, Output,ViewChild } from '@angular/core';
import { InterestRateRiskScenarioHeaderComponent } from '../interestRateRiskScenario-header/interestRateRiskScenario-header.component';

@Component({
  selector: 'app-interestRateRiskScenario-table',
  templateUrl: './interestRateRiskScenario-table.component.html',
  styleUrls: ['./interestRateRiskScenario-table.component.scss']
})
export class InterestRateRiskScenarioTableComponent implements OnInit {

  @ViewChild(InterestRateRiskScenarioHeaderComponent, { read: false, static: false })
  InterestRateRiskScenarioHeaderComponent: InterestRateRiskScenarioHeaderComponent;

 @Input() interestRateRiskScenarioList:any[];
 @Input() rolePermission:any;
 @Output() edit = new EventEmitter();
 @Output() setValueScenario = new EventEmitter();
 @Output() delete = new EventEmitter();
 dataValue:any;
 visibleDelete: boolean = false;

 cols: { field: string; header: string }[];
  constructor() {
    this.cols = [
      
    ];
   }

  ngOnInit(): void {
  }

  editInterestRateRiskScenario(rowData){
  this.edit.emit(rowData);
  }

  showPopupDelete(rowData) {
        this.dataValue = rowData;
        this.visibleDelete = true;
      }
    
      hidePopup() {
        debugger;
        this.dataValue = null;
        this.visibleDelete = false;
      }
    
      actionDo(str){
        this.deleteStressScenarioId(this.dataValue);
        this.dataValue=null;
        this.visibleDelete=false;
      }
    
      deleteStressScenarioId(rowData){
        this.delete.emit(rowData);
        console.log("deleteStressScenarioID", rowData);
      }


      editInterestRateRiskScenarioValues(rowData){
        
        this.setValueScenario.emit(rowData);
        console.log("Parent Called",rowData)
       // this.InterestRateRiskScenarioHeaderComponent.openSetValues();
        }
}
