import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { ReportHeadSelectorComponent } from '../reportHead-selector/reportHead-selector.component';


@Component({
  selector: 'app-reportHead-header',
  templateUrl: './reportHead-header.component.html',
  styleUrls: ['./reportHead-header.component.scss']
})
export class ReportHeadHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(ReportHeadSelectorComponent, { read: false, static: false })
  ReportHeadSelectorComponent: ReportHeadSelectorComponent;

  
  @Input() editData;
  @Input() reportLists;
  @Output() save=new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }


  saveReportHead(data){
this.save.emit(data);

  }

 

   addNewReportHead(){

     this.ReportHeadSelectorComponent.formReset();
     this.ReportHeadSelectorComponent.callRadioForDisable();
     this.panelForm.toggleSlider();

    }

    reset(){

     this.ReportHeadSelectorComponent.formReset();
     //this.panelForm.toggleSlider();
    }

 openEdit(){
  this.panelForm.toggleSlider();
 }

}
