import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { VideoCategorySelectorComponent } from './components/videocategory-selector/videocategory-selector.component';
import { VideoCategoryTableComponent } from './components/videocategory-table/videocategory-table.component';
import { VideoCategoryHeaderComponent } from './components/videocategory-header/videocategory-header.component';


@NgModule({
  declarations: [
    VideoCategorySelectorComponent,
    VideoCategoryTableComponent,
    VideoCategoryHeaderComponent
    

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    VideoCategorySelectorComponent,
    VideoCategoryTableComponent,
    VideoCategoryHeaderComponent
    ]
})
export class VideoCategoryCompModule { }
