import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-stressScenarioValues-selector',
  templateUrl: './stressScenarioValues-selector.component.html',
  styleUrls: ['./stressScenarioValues-selector.component.scss']
})
export class StressScenarioValuesSelectorComponent implements OnInit {
  @Output() save=new EventEmitter();
  @Output() closePanel=new EventEmitter();
  stressScenarioValuesForm: FormGroup = null;
  @Input() editData:any[];

  baselineError:String=null;
  mediumError:String=null;
  severeError:String=null;

  constructor(private toast:ToastService,private datePipe: DatePipe) {

    this.stressScenarioValuesForm=new FormGroup({
      stressScenarioValueId:new FormControl(null),
      stressScenarioId: new FormControl(null,{validators:[Validators.required],updateOn: "blur"}),
      stressScenarioName:new FormControl(null,{validators:[Validators.required],updateOn: "blur"}),
      statusMap: new FormControl(null,{validators:[Validators.required]}),
      baseline:new FormControl(null),
      medium:new FormControl(null),  
      severe:new FormControl(null),  
      validFromDate:new FormControl(null,{validators:[Validators.required]}),
      validToDate:new FormControl(null,{validators:[Validators.required]}),
    });
  
   }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
  this.stressScenarioValuesForm.get('statusMap').setValue(defaultMap);
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData",rowData);
  
      this.stressScenarioValuesForm.patchValue(
        {
          stressScenarioValueId:rowData.stressScenarioValueId,
          stressScenarioId:rowData.stressScenarioId,
          stressScenarioName:rowData.stressScenarioName,
          baseline:rowData.baseline,
          medium:rowData.medium,
          severe:rowData.severe,
          validFromDate:this.datePipe.transform(rowData.validFromDate,"yyyy-MM-dd"),
          validToDate:this.datePipe.transform(rowData.validToDate,"yyyy-MM-dd")

        });

        const map1 = {
          key: "Active",
          value: "Active",
        };
      
        const map2 = {
          key: "Inactive",
          value: "Inactive",
        };
      
      rowData.status==true? this.stressScenarioValuesForm.controls.statusMap.setValue(map1):this.stressScenarioValuesForm.controls.statusMap.setValue(map2);

        
    }
  
  }

  saveStressScenarioValues(flag){
    let request = this.stressScenarioValuesForm.value;
  console.log("stress values form ",this.stressScenarioValuesForm.value)
   if(flag=='onLoad'){
    if(this.stressScenarioValuesForm.valid){
      console.log("stressScenarioFormValid",this.stressScenarioValuesForm.valid);
      
      let statusMap=this.stressScenarioValuesForm.controls.statusMap.value
      console.log("statusMap",statusMap)
      if (statusMap=="Select Status *") {
        this.toast.showError("Please Fill Required Fields","error");
      }
      if(statusMap!=="Select Status *"){
      let validate=this.validationOnDate(this.stressScenarioValuesForm.controls.validFromDate.value,this.stressScenarioValuesForm.controls.validToDate.value);
      if(validate===true){
              
       this.save.emit(this.stressScenarioValuesForm.value);

     }
      if(validate===false){
        this.toast.showWarn('error','date range incorrect')
      }
    }
        }
          else{
            this.toast.showError("Please Fill Required Fields","error");
        }   
  this.closePanel.emit();
    }

    }

    formReset(){
      this.stressScenarioValuesForm.reset();
      const defaultMap = {
        key: "Active",
        value: "Active",
      };
     this.stressScenarioValuesForm.get('statusMap').setValue(defaultMap);
    }

    get status() {
      const status = [
        "Active",
        "Inactive",
      ];
     return status.map((t) => ({ key: t, value: t }));
    }

    changeEndDate(){
      // this.headerForm.controls.toDate.setValue(e);
       let validate=this.validationOnDate(this.stressScenarioValuesForm.controls.validFromDate.value,this.stressScenarioValuesForm.controls.validToDate.value);
       if(validate===true){
         
         this.save.emit(this.stressScenarioValuesForm.value);
       }
       if(validate===false){
         this.toast.showWarn('error','date range incorrect')
         
       }
     }
     
     validationOnDate(validFromDate,validToDate){
       if(validFromDate && validToDate){
       if(new Date(validFromDate)>new Date(validToDate)){
       
           return false;
         }
         return true;
         
       }else{
         return false;
       }
     }
 
}
