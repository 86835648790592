import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personalize-form',
  templateUrl: './personalize-form.component.html',
  styleUrls: ['./personalize-form.component.scss']
})
export class PersonalizeFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
