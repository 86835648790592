import { NgModule } from '@angular/core';


import { SharedModule } from 'src/app/shared/shared.module';
import { ReportTableComponent } from './containers/report-table/report-table.component';
import { UiModule } from 'src/app/ui/ui.module';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { ExportTableComponent } from './containers/export-table/export-table.component';
import { ExportActionsComponent } from './components/export-actions/export-actions.component';
import { ReportFormComponent } from './containers/report-form/report-form.component';
import { HomeParameterComponent } from './containers/home-parameter/home-parameter.component';
import { ReportInfoComponent } from './containers/report-info-parameters/report-info.component';
import { HomeHeaderComponent } from './containers/home-header/home-header.component';
import { ReportGroupParameterComponent } from './containers/report-group-parameter/report-group-parameter.component';
import { MultiSelectModule } from 'primeng';

@NgModule({
  declarations: [
    ReportTableComponent,
    ExportTableComponent,
    ExportActionsComponent,
    SearchBoxComponent,
    ReportFormComponent,
    HomeParameterComponent,
    ReportInfoComponent,
    HomeHeaderComponent,
    ReportGroupParameterComponent
  ],
  imports: [SharedModule, UiModule, MultiSelectModule],
  exports: [
    ReportTableComponent,
    ExportTableComponent,
    ExportActionsComponent,
    SearchBoxComponent,
    ReportFormComponent,
    HomeParameterComponent,
    ReportInfoComponent,
    HomeHeaderComponent,
    ReportGroupParameterComponent
  ],
})
export class ReportsModule {}
