import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { LoaderService } from "src/app/loader/services/loader.service";
import { TitleService } from "src/app/core/services/title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { currentMonthRange, dateBetween, formatDate } from "src/app/utils/dates";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastService } from "src/app/core/services/toast.service";
import { FileLogModel } from "../../models/filelog.model";
import { FileService } from "src/app/files/services/file.service";
import { ObservalbleDataService} from 'src/app/dashboard/services/observalble-data.service';
import {DataTransferModel} from 'src/app/dashboard/models/dataTransfer.model';
import { exportCSVFile } from "src/app/utils/excel";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import {isEmpty,trim} from 'lodash';
import { DatePipe } from "@angular/common";
import { Store } from '@ngrx/store';
import { SidePanelComponent } from "src/app/ui/components/side-panel/side-panel.component";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { ClientService } from "../../services/client.service";
import { GenericService } from "../../services/generic.service";
import { Console } from "console";

import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { WorkflowJobComponent } from "../workflow-job/workflow-job.component";
import { WorkflowService } from "../../services/workflow.service";


@Component({
  selector: "app-client-file",
  templateUrl: "./client-file.component.html",
  styleUrls: ["./client-file.component.scss"],
})
export class ClientFileComponent implements OnInit, OnDestroy {
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  
  @ViewChild(WorkflowJobComponent, { read: false, static: false })
  workflowJobComponent: WorkflowJobComponent;

  headerForm: FormGroup = null;
  clientsList: { key: number | string; value: string }[] = [];
  restoreClientFiles:any[];
  activeRoute$: Subscription;
  clientFiles: any[];
  clientFilesCount: any[];
  columns: string[] = [
    "serial.",
    "file name",
    "layout file",
  ];
  filesLog: FileLogModel[];
  dateRange$: Subscription;
  dataTransferModel:DataTransferModel;
  fromDt:any;
  toDt:any;
  currentPage:string='/data/ingest/data-file'
  dataValue:any;
  visibleDelete=false;
  visibleCleanData=false;
  modalForm: FormGroup = null;
  visible:boolean=false;
  fileName:string=null;
  layoutFieldList: any = [];
  clientId:number=null;
  clientFileId:number=null;
  clientFileMasterListVal:any=[];
  clientFileMasterList:any=[];
  cols: { field: string; header: string }[];
  status; type;
  ingestedAt:any;
  comment:any;
  rolePermission:any;
  clientsList1:any[];
  companyName:String;
  fromDateValue:String;
  toDateValue:String;
  searchByCols:{field:String, header:String }[];
  paramMap$:Subscription;
  filterColumn:any;
  searchQuery!: any;
  searchCol:String;
  companyId:any;
  adminFlag:String;
  @Input() showHeader:Boolean=false;
  @Input() tabbedBoolean:boolean=false;
  //@Input() formDetails;
  showWorkflow:boolean=false;
  showWorkflowInterFace:boolean=true;
  clientData:any;
  workflowExist:boolean;
  state:string;
  constructor(
    private fileService: FileService,
    private loader: LoaderService,
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private toast: ToastService,
    private router: Router,
    public observalbleDataService:ObservalbleDataService,
    private cookieService: CookieService,
    private clientService: ClientService,
    private datePipe: DatePipe,
    private genericService:GenericService,
    private clientFileMasterService:ClientFileMasterService,
    private workflowService: WorkflowService,
    private storePermission: Store<{ role: any }>
  ) {
    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      //dateRange: new FormControl(currentMonthRange(), [Validators.required]),
      fromDate: new FormControl(null, [Validators.required]),
      toDate: new FormControl(null, [Validators.required]),
    });
    

    this.modalForm = new FormGroup({
      clientFieMaster:new FormControl(null),
      layoutFile:new FormControl(null),
      isActive:new FormControl(null),
      comment:new FormControl(null, [Validators.required,Validators.minLength(5),Validators.maxLength(500)])
     });

    //  this.cols = [
    //   { field: "fileName", header: "File Name" },
    //   { field: "applicationFileName", header: "Layout File" },
    // ];
  
    this.status=["Ingested","Not Ingested"].map((d)=> ({key:d,value:d}));
    this.type=["Core","Addon"].map((d)=> ({key:d,value:d}));
    
  this. searchByCols = [
    { field: "fileName", header: "File Name" },
    { field: "type", header: "Type" },
    { field: "status", header: "Status" },
    { field: "applicationFileName", header: "Layout File" },
  ];
    
  this.state=localStorage.getItem("stateflag"); 
  }
  ngOnInit(): void {
   
    console.log("searchByCols  ",this.searchByCols)

    this.adminFlag=this.cookieService.get("adminFlag");

//     this.paramMap$ = this.activeRoute.queryParamMap.subscribe(
// (par)=>{
  
//   this.filterColumn=par.get('filter');
 
// },
// err=>{}
//     );

    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );


if(!this.tabbedBoolean){
    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
        for(var i=0;i<data.length;i++){
          console.log("data  ",data[i].clientId)
          this.companyId=data[i].clientId
          this.companyName=data[i].clientName
        }
        this.headerForm.get("client").setValue({
          key: this.companyId,
          value: this.companyName
        });

       // this.getAllDbCoonByComapnyId( this.headerForm.controls.company.value.key);
       this.headerForm.controls.fromDate.setValue(formatDate(new Date()))
       this.headerForm.controls.toDate.setValue(formatDate(new Date()))
       this.fromDateValue=this.headerForm.controls.fromDate.value;
       this.toDateValue=this.headerForm.controls.toDate.value;

       console.log("this.headerForm.controls.fromDate.value ",this.headerForm.value)

       //this.onClientChange(this.headerForm.controls.client.value);
       this.getFilesData(this.headerForm.controls.client.value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);

      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
      );
    }

    if(this.tabbedBoolean){
      var date1 = new Date();
      date1.setDate(date1.getDate() + 1);
      console.log("date1 ",date1)

      var date2= new Date();
      date2.setDate(date2.getDate()-365);
      console.log("date2 ",date2)
   
      this.headerForm.controls.fromDate.setValue(formatDate(date2))
      this.headerForm.controls.toDate.setValue(formatDate(date1))
   //   console.log("this.formDetails.client.key  ",this.formDetails.client)
    //  console.log("this.formDetails.file.key ",this.formDetails.file.key)
    this.companyId=this.cookieService.get('tabbedClientId');
    this.companyName=this.cookieService.get('tabbedClientValue');

    this.headerForm.get("client").setValue({
      key: this.companyId,
      value: this.companyName
          });

      // this.headerForm.get("client").setValue({
      //   key: this.cookieService.get('tabbedClientId'),
      //   value: this.cookieService.get('tabbedClientValue')
      // });
      // this.headerForm.get("client").setValue({
      //   key: this.formDetails.client.key,
      //   value: this.formDetails.client.value
      // });
      this.getFilesData(this.headerForm.controls.client.value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    // this.clientFiles=this.clientFiles.filter(x=>x.clientApplicationFileId=this.formDetails.file.key);

    }
  
    this.buttonShowHidePermission();
    //this.filterByColumn(this.searchColValue)
    //console.log(this.dataTransferModel,' datat transfer model')
    
    // this.dateRange$ = this.headerForm
    //   .get("dateRange")
    //   .valueChanges.subscribe((data) => {
    //     if (data[0] !== null && data[1] !== null) {
    //      // this.getFilesData(this.headerForm.get("client").value, data);
    //     }
    //   });
   // this.storePermission.select(x => this.rolePermission = x).subscribe();
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  // getSeachItem(data){
  //  console.log("filterColumn ",this.filterColumn);
  // if(this.filterColumn!==null || this.filterColumn!==undefined){
  //    this.filterByColumn(data,this.filterColumn);
  // }
  // }


//   filterByColumn(searchBy,filterColumn){
//     this.clientFiles=this.restoreClientFiles;
//   //  console.log("this.filterColumn ",filterColumn);
//   //  this.clientFiles=this.clientFiles.filter(x=>x.filterColumn.toLowerCase().includes(searchBy.toLowerCase()));
//   this.clientFiles=this.clientFiles.filter((d) => {
//     const dt: any =d[filterColumn as keyof any[]];switch ('search') {
//       case 'search':
//         return dt.toLowerCase().includes(searchBy.toLowerCase());

//   }

//   }
//   );
// }


 
  getFilesData(client,fromDate,toDate) {
    this.clientFiles = this.clientFilesCount = []; // reset data
   // this.loader.showLoader();
    this.filesLog=[]; 
    //const { dateRange } = this.headerForm.value;
    //console.log(fromDate," client file ",toDate);
    const files = this.fileService
      .getFilesByClient(client.key, fromDate,toDate,this.state)
      .pipe(catchError((err) => of(err.status)));
    const filesCount = this.fileService
      .getFilesCountByClient(client.key, fromDate,toDate)
      .pipe(catchError((err) => of(err.status)));
    forkJoin([files, filesCount]).subscribe(
      (res) => {
       if(res[0] ===500 && res[1]===500){
        this.toast.showError("User is not Authorised to perform this action. Please contact to Admin", "error")
       }else{
        this.clientFiles = res[0];
        console.log("clientfileOn ",this.clientFiles)
        this.restoreClientFiles=this.clientFiles;
        this.clientFilesCount = res[1];
        this.clientFiles.forEach(item=>{
          item.createdAt=this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
          item.asofDate=this.datePipe.transform(item.asofDate,"MM/dd/yyyy")
          item.ingestedAt=this.datePipe.transform(item.ingestedAt,"MM/dd/yyyy")
        
         })
         if(this.tabbedBoolean){
          console.log("filtered..."+this.cookieService.get('tabbedFileId'))
          // this.clientFiles=this.clientFiles.filter(x=>x.clientApplicationFileId==this.formDetails.file.key);
          this.clientFiles=this.clientFiles.filter(x=>x.clientfileid == this.cookieService.get('tabbedFileId'));
        
          console.log("this.clientFiles",this.clientFiles)
          console.log("this.clientFiles.clientFileMasterId",this.clientFiles[0].clientFileMasterId)
          if(this.clientFiles[0].clientFileMasterId==0){
            this.workflowExist=false;

          }
          else{
          const model={
            clientId:this.companyId,
            clientFileMasterId:this.clientFiles[0].clientFileMasterId

          }
           this.workflowService.getWorkflowByFileMasterId(model).subscribe(
            res=>{
            console.log("existBoolean ",res);
              this.workflowExist=res;

           },
           err=>{

            this.toast.showError(err.error.message, "error");
           })
         }
         }
      }
    },
      () => this.toast.showError("Server Error", "Unable to fetch data"),
      //() => this.loader.hideLoader()
    );
    this.layoutFieldList=[];
    this.clientFileMasterList=[];
    this.clientFileMasterListVal=[];

    if(this.tabbedBoolean){
      this.getClientFileMaster(this.cookieService.get('tabbedClientId'));
      this.getClientAppFile(this.cookieService.get('tabbedClientId')); 
    }
else{
    if(this.headerForm.controls.client.value.key!=undefined || this.headerForm.controls.client.value.key!=null){
    console.log("this.headerForm.controls.client.value.key ",this.headerForm.controls.client.value.key)
    this.getClientFileMaster(this.headerForm.controls.client.value.key);
    this.getClientAppFile(this.headerForm.controls.client.value.key);
    }
  }
   // this.dataTransferModel.isRedirected=0;
  }
  navigateToPage(data: any) {
   
  this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
  this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
  this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
  this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    const fileData = {
      clientId: data.dataItem.clientId,
      clientFile: data.dataItem.clientApplicationFileId,
      fileName: data.dataItem.fileName,
      clientName: this.headerForm.value.client.value,
      fromDate:this.headerForm.controls.fromDate.value,
      toDate:this.headerForm.controls.toDate.value,
      pageName:this.currentPage
    };
    this.router.navigate([data.pageItem], {
      queryParams: { ...fileData },
    });
  }
  getLog(id) {
    this.loader.showLoader();
  
    this.fileService.getLogByClientFieldId(id).subscribe(
      (data) => {
        this.filesLog = data;
        
        this.loader.hideLoader();
      },
      () => this.loader.showLoader()
    );
  }
  ngOnDestroy() {
    try{
      this.activeRoute$.unsubscribe();
      this.paramMap$.unsubscribe();
    }catch(ex){
      
    }
    
   // this.dateRange$.unsubscribe();
  }

  ingestRuleBySequence(data: any) {
    const clientFileModel = {
      clientId: data.clientId,
      clientApplicationFileId: data.clientApplicationFileId,
      clientFileId: data.clientfileid,
      logflag: true,
    };

    this.loader.showLoader();
    this.fileService.ingestRuleBySequence(clientFileModel).subscribe(
      (data) => {
        this.toast.showSuccess('success','Ingested success');
        this.loader.hideLoader();
        this.clientFiles=[];
        this.headerForm.get("client").setValue({
          key: this.companyId,
          value: this.companyName
              });
        this.getFilesData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
      },
      (err) =>{
       // console.log(err.error.message,' errorororoor');
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader()
      } 
    );
  }

  testAllRules(data: any) {
    console.log("insie testAllRules data", data);
    console.log("filename: ",data.fileName);
    let fileName=data.fileName;
    const clientFileModel = {
      clientId: data.clientId,
      clientApplicationFileId: data.clientApplicationFileId,
      clientFileId: data.clientfileid,
      logflag: false,
      isTestAll:true
    };

    this.loader.showLoader();
    this.fileService.testAllRulesBySequence(clientFileModel).subscribe(
      (data) => {
        this.toast.showSuccess('success','Test Success');
        this.loader.hideLoader();
        this.downloadExcel(fileName);
       
        
      },
      (err) =>{
       // console.log(err.error.message,' errorororoor');
        this.toast.showError(err.error.message, "error");
        this.loader.hideLoader()
      } 
    );
  }

  downloadExcel(fileName){
    let downloadSuccess:boolean=false;
    var link=document.createElement('a');
    link.href=environment.baseUrl+"/ingestRule/download/"+fileName;
   // document.body.appendChild(link);
    link.download="TestResult.xlsx";
    link.click();
  }
   onLoad(){
    console.log("this.headerForm.controls.fromDate.value ",this.headerForm.value)
    this.companyId=this.headerForm.controls.client.value.key;
    this.companyName=this.headerForm.controls.client.value.value;
    this.fromDateValue=this.headerForm.controls.fromDate.value;
    this.toDateValue=this.headerForm.controls.toDate.value;
    
   
    this.headerForm.get("client").setValue({
      key: this.companyId,
      value: this.companyName
          });

    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
  
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
//this.onClientChange(this.headerForm.controls.client.value);
if(this.headerForm.controls.client.value.key!=undefined || this.headerForm.controls.client.value.key!=null){
this.getFilesData(this.headerForm.controls.client.value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
}
this.panelFilter.toggleSlider();
  }

  changeToDate(){
   // console.log("e date ",e);
    
   // this.headerForm.controls.toDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
   //   this.getFilesData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //console.log(e,'  todate');
    //this.maintainStateOnDateChange(e,'toDateChange');
  }

//   changetoDate(){
// console.log("New Validation");
// this.headerForm.controls.fromDate.value;
// this.headerForm.controls.toDate.value;

// if(this.fromDateValue > this.toDateValue){
//   this.toast.showWarn('error','date range incorrect')
// }
// else{
//   this.toast.showSuccess('success','List Loaded Successfully'); 
// }
//   }

  changeFromDate(){

   // this.headerForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
      this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
  //    this.getFilesData(this.headerForm.get("client").value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //this.maintainStateOnDateChange(e,'fromDateChange');
  }

  loadDate(){
    let currentDateRange=currentMonthRange();
    //console.log(currentDateRange,'  current date range');
    this.headerForm.controls.fromDate.setValue(currentDateRange[0]);
    this.headerForm.controls.toDate.setValue(currentDateRange[1]);
   //console.log(this.headerForm.controls.fromDate.value,'  current date range ',this.headerForm.controls.toDate.value);
    this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
  }


  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }

  downloadAnyFile(clientFileId){
 // this.loader.showLoader();
  let downloadSuccess:boolean=false;
  var link=document.createElement('a');
  link.href=environment.baseUrl+"/apis/files/downloadClientFile/"+clientFileId;      
  link.click();
  this.toast.showSuccess('success','Download Begin Shortly'); 
  //this.loader.hideLoader();
}

showDeletePopup(data){
  this.dataValue=data;
  this.visibleDelete=true;
}

hidePopup(){
  this.dataValue=null;
  this.visibleDelete=false;
}

showCleanDataPopup(data){
  this.dataValue=data;
  this.visibleCleanData=true;
}

hideCleanDataPopup(){
  this.dataValue=null;
  this.visibleCleanData=false;
}

onClientChange(event){
  this.getFilesData(event, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
  this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
  //this.maintainClientState(event,this.headerForm.controls.fromDate.value, this.headerForm.controls.toDate.value);
}

deleteClientFile(clientFileId){
  const requestModel={
    "clientFileId":this.dataValue
  }
  this.loader.showLoader();
  this.fileService.deleteClientFile(requestModel)
  .subscribe(
    data=>{
      this.loader.hideLoader();
      this.toast.showSuccess('success','Client File deleted');
      this.visibleDelete=false;
      this.getFilesData(this.headerForm.get("client").value,this.headerForm.value.fromDate,this.headerForm.value.toDate);
    },
    err=>{
      this.loader.hideLoader();
      this.visibleDelete=false;
      this.toast.showError('error',err.error.message);
    }
    
  )

}

getLayoutField(clientId){
  console.log(this.clientId);
  this.fileService.getAppFilesByClientId(clientId).subscribe(
    (data) => {
      const layoutFieldFile=data;
      for(var a=0;a<layoutFieldFile.length;a++){
      // console.log("type "+layoutFieldFile[a].fileName);
        this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
      }
    },
    (err) => {
    //  this.toast.showSuccess("Status Not Changed", "error");
    }
  
  );

}

doAction(type) {
  if (type === "yes") {
    console.log("helloo");
    this.visible = false;
   this.modalForm.reset();
       }else{
    this.modalForm.reset();
  }
}


showEditFile(clientFile){
  this.panelForm.toggleSlider();
  this.visible = true;
 // this.layoutFieldList=[];
  this.fileName=clientFile.fileName;
 // this.getClientAppFile(clientFile.clientId);
  //this.getClientFileMaster(clientFile.clientId);
  this.clientId=clientFile.clientId;
 // this.clientName=this.modalForm.controls.client.va
  this.clientFileId=clientFile.clientfileid;
  const defaultMap = {
    key: clientFile.clientApplicationFileId,
    value: clientFile.applicationFileName,
  };
  console.log("clientFile  ",clientFile)
  this.defaultMapFormasterFile(clientFile);

  // this.modalForm.get("layoutFile").patchValue({
  //   key: clientFile.clientApplicationFileId,
  //   value: clientFile.fileName,
  // });
 this.modalForm.controls.layoutFile.setValue(defaultMap);  
 this.modalForm.controls.comment.setValue(clientFile.comment);
 this.modalForm.controls.isActive.setValue(clientFile.active);  
 //console.log(this.modalForm.controls);
}

// editClientFile(){
// console.log(this.modalForm.controls)
// }

editClientFile(){
  this.panelForm.toggleSlider();
   const clientFile={
     "clientFileId":this.clientFileId,
     "client_id":this.clientId,
     "fileName":null,
     "clientApplicationFileId":this.modalForm.controls.layoutFile.value.key,
     "createdBy":1, 
     "clientFileMasterId":this.modalForm.controls.clientFieMaster.value!==null ? this.modalForm.controls.clientFieMaster.value.key:0,
    "isActive":this.modalForm.controls.isActive.value,
    "comment":this.modalForm.controls.comment.value
  }
   this.loader.showLoader();

   this.fileService.editClientFile(clientFile)
   .subscribe(
     data=>{
       this.toast.showSuccess('Updated Successfully','Success');
       this.clientId=null;
       this.visible=false;
       this.modalForm.reset();
       this.loader.hideLoader();
       this.headerForm.get("client").setValue({
        key: this.companyId,
        value: this.companyName
            });
       this.getFilesData(this.headerForm.controls.client.value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
       //  this.fromCalVariable.nativeElement.value = "";
      
     },
     error=>{
       this.toast.showError(error.error.message, "error");
       this.loader.hideLoader();
     },
    
   );
   
 }


 getClientAppFile(clientId){
  console.log("client file data ",clientId);
  this.fileService.getclientAppFile(clientId).subscribe(
    (data) => {
      const layoutFieldFile=data;
      for(var a=0;a<layoutFieldFile.length;a++){
      // console.log("type "+layoutFieldFile[a].fileName);
        this.layoutFieldList.push({ key: layoutFieldFile[a].clientApplicationFileId, value: layoutFieldFile[a].fileName });
      }
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
    }
  
  );

}


getClientFileMaster(clientId){
  console.log(this.clientId);
  this.fileService.getclientFileMaster(clientId).subscribe(
    (data) => {
      const clientFileMasterLitData=data;
      for(var a=0;a<clientFileMasterLitData.length;a++){
      // console.log("type "+layoutFieldFile[a].fileName);
      this.clientFileMasterListVal.push({ key: clientFileMasterLitData[a].clientfilemasterId, value: clientFileMasterLitData[a].name ,fileDataTypeId:clientFileMasterLitData[a].fileDataTypeId,fileTypeId:clientFileMasterLitData[a].fileTypeId});
      }
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
    }
  
  );

}
  defaultMapFormasterFile(clientFile){
 //   const values=this.clientFileMasterList;
 this.clientFileMasterList=[];
for(var a=0;a<this.clientFileMasterListVal.length;a++){
console.log(" id "+this.clientFileMasterList)

if(this.clientFileMasterListVal[a].fileDataTypeId=== clientFile.fileDataTypeId && this.clientFileMasterListVal[a].fileTypeId===clientFile.fileTypeId){
  this.clientFileMasterList.push({ key: this.clientFileMasterListVal[a].key, value: this.clientFileMasterListVal[a].value ,fileDataTypeId:this.clientFileMasterListVal[a].fileDataTypeId});
}
}
for(var a=0;a<this.clientFileMasterList.length;a++){
if(this.clientFileMasterList[a].key===clientFile.clientFileMasterId){
  const defaultMasterMap = {
    key: this.clientFileMasterList[a].key,
    value: this.clientFileMasterList[a].value,
  };
  this.modalForm.get("clientFieMaster").setValue(defaultMasterMap);
}
}
  }

  onLayoutChange(event){
    this.modalForm.controls.clientFieMaster.reset();
  }

  maintainClientState(client, fromDate, toDate){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: toDate,
      fromDate: fromDate,
      clientName: client.value,
      page: '',
      fileName:'',
      workflowName:'',
      workflowId:0,
      dataType:'',
      dataTypeId:0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  /*maintainStateOnDateChange(newDate,typeOfRequest){
    if(typeOfRequest==='fromDateChange'){
      this.dataTransferModel.fromDate = newDate;
      this.observalbleDataService.addToInventory(this.dataTransferModel);
    }
    if(typeOfRequest==='toDateChange'){
      this.dataTransferModel.toDate = newDate;
      this.observalbleDataService.addToInventory(this.dataTransferModel);
    }
  }*/

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    const fromDatec=this.cookieService.get('fromDate');
    const toDatec=this.cookieService.get('toDate');
    //console.log("cookies check ",this.cookieService.get('toDaaaate')===null)
    //console.log("cookies check ",this.cookieService.get('toDaaaate'))
    // console.log(clientIdC);
    // console.log(clientNameC);
    // console.log("from date ",fromDatec);
    // console.log("todate ",toDatec);
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
              this.companyName=clientNameC
    }
    if(fromDatec!==undefined && fromDatec!==null && toDatec!==undefined && toDatec!==null && 
      !isEmpty(fromDatec) && !isEmpty(toDatec)){
       // console.log("hello")
        let frm=fromDatec;
        let toDt=toDatec;
       this.headerForm.controls.fromDate.setValue(formatDate(fromDatec))
       this.headerForm.controls.toDate.setValue(formatDate(toDatec))

       this.fromDateValue=formatDate(fromDatec);
       this.toDateValue=formatDate(toDatec);
    }else{
      this.loadDate();
    }

   // this.loadDate();

  //console.log("client Value ",this.headerForm.get("client").value);
  if(!isEmpty(this.headerForm.get("client").value.key)){
    this.getFilesData(this.headerForm.get("client").value, this.headerForm.value.fromDate, this.headerForm.value.toDate);
  }
   
    // if(fromDatec!==null && toDatec!==null){
    //   this.fromDt = new Date(fromDatec);
    //   this.toDt = new Date(toDatec);
    //   this.headerForm.patchValue({
    //             fromDate: this.fromDt,
    //             toDate: this.toDt,
    //           });
    // this.getFilesData(this.headerForm.get("client").value, this.headerForm.value.fromDate, this.headerForm.value.toDate);
    // }else{
    //   this.loadDate();
    // }
    
    
    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     this.fromDt = new Date(this.dataTransferModel.fromDate);
    //     this.toDt = new Date(this.dataTransferModel.toDate);
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && isNaN(this.fromDt)===false && isNaN(this.toDt)===false) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.headerForm.patchValue({
    //         fromDate: this.fromDt,
    //         toDate: this.toDt,
    //       });
    //       this.getFilesData(this.headerForm.get("client").value, this.headerForm.value.fromDate, this.headerForm.value.toDate);
    //     }

    //     else if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && (isNaN(this.fromDt)===true || isNaN(this.toDt)===true)) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.loadDate();
    //       this.getFilesData(this.headerForm.get("client").value, this.headerForm.value.fromDate, this.headerForm.value.toDate);
    //     }

    //     else {
    //       this.loadDate();
    //     }
    //   });
    }

    cleanData(dataValue){
      console.log("clean data "+this.dataValue);
      const requestModel={
        "clientFileId":this.dataValue.clientfileid,
        "clientId":this.dataValue.clientId,
        "clientFileDataTypeId":this.dataValue.fileDataTypeId,
      }
      this.fileService.cleanData(requestModel) .subscribe(
        data=>{
          this.loader.hideLoader();
          this.toast.showSuccess('success','Client File Data Deleted');
          this.visibleCleanData=false;
          this.getFilesData(this.headerForm.get("client").value,this.headerForm.value.fromDate,this.headerForm.value.toDate);
        },
        err=>{
          this.loader.hideLoader();
          this.visibleCleanData=false;
          this.toast.showError('error',err.error.message);
        }
        
      )
    
    }
 
    downloadFileUnmatchedAcct(clientFileId){
      this.loader.showLoader();
      let downloadSuccess:boolean=false;
      var link=document.createElement('a');
      link.href=environment.baseUrl+"/ingestRule/downloadUnmachedAcctAddonFile/"+clientFileId;      
      link.click();
      this.toast.showSuccess('success','Download Begin Shortly'); 
      this.loader.hideLoader();
    }



    buttonShowHidePermission(){
      this.fileService.buttonShowHidePermission().subscribe(
        (res) =>{ 
             this.rolePermission = res  
             this.callNgOnInitMethods();
     }
      )};



      callNgOnInitMethods(){

  //  this.getPreviousState();
    this.setTitle();
    if(!this.tabbedBoolean){
    this.getClients();
    }
    
      }


      getClients() {
        this.loader.showLoader();
        this.clientService.getAllClients().subscribe(
          (data) => {
            this.loader.hideLoader();
            this.clientsList1 = reMapForAutoComplete(data, "clientId", "clientName");
          },
          (err) => {
            this.loader.hideLoader();
            this.toast.showError(err.error.message, "error");
          }
        );
      }

      tableViewPage(data){
      this.genericService.getGenericDataByGenericAppId(data.clientApplicationFileId).subscribe(
         res=>{
        console.log("res  ",res);
        let a = "true";
        let page = 'data/viewdata/table-viewer';
           this.router.navigate([page],{
            queryParams: {...res,a},
          } );
  
         },
         error=>{
          this.toast.showError(error.error.message, "error");
         } 
        )

      }



      createMasterAndSave(data){
        this.showWorkflow=true; 
        this.showWorkflowInterFace=false;
        //   if(this.headerForm.value.client==null){
        // this.toast.showError("Please Select The Client First","error")
        //   }else{
          this.headerForm.get("client").setValue({
            key: this.companyId,
            value: this.companyName
                });
          console.log("this.headerForm.controls.client.value.key  ",this.headerForm.controls.client.value.key)
          var str = data.fileName;
 
          // use of String split() Method
          var fileName = str.split(".");
          console.log("data.clientFileMasterId  ",data.clientFileMasterId)
          if(data.clientFileMasterId==0){
          const clientMasterData = {
            clientfilemasterId:null,
                clientId:this.headerForm.controls.client.value.key,
                name:fileName[0],
                fileNamePrefix:fileName[0],
                desc:"its genericFile",
                fileTypeId:1,
                fileDataTypeId:15,
                frequency:'Monthly',
                
          };
          this.clientFileMasterService
            .saveOrUpdateData(clientMasterData)
            .subscribe((res) => {

              console.log("fileMatserData ",res);
              this.attachedFileMaster(res,data);
              //this.clientSelected(this.headerForm.value.client.key, 'onAddMaster');
      
              // this.getClientFileMasterListWithClient();
              // this.getClientFileMasterList(this.headerForm.controls.client.value.key);
              
        
             // this.toast.showSuccess('saved successfully','success');
             // this.editClientMasterValue=null;
            },
            (err) =>{
              this.toast.showError(err.error.message, "error");
            } 
            ); 
         } 
         
         else{

          
          this.cookieService.set("clientFileMasterId",data.clientFileMasterId);
          const obj={
          clientId:this.companyId,
          clientFileMasterId:data.clientFileMasterId
          }
           
          this.fileService.getFileMasterByClientAndId(obj).subscribe(

            (res) => {
              console.log("fileMatserData ",res);
             
              this.cookieService.set("clientFileMasterName",res.name);
              console.log("fileMatserData ",res);
              this.workflowJobComponent.callManageToggle();
             
            },
            (err) =>{
              this.toast.showError(err.error.message, "error");
            } 
          )
          

         }
        
        // else{
        //   console.log("data.clientFileMasterId  ",data.clientFileMasterId)
        //   const model={
        //     clientId:this.headerForm.controls.client.value.key,
        //     clientFileMasterId:data.clientFileMasterId

        //   }
        //    this.workflowService.getWorkflowByFileMasterId(model).subscribe(
        //     res=>{
        //     console.log("existBoolean ",res);
        //     if(res){


        //     }
        //     else{
             

        //     }

        //    },
        //    err=>{

        //     this.toast.showError(err.error.message, "error");
        //    })

        // }

      }


        attachedFileMaster(res,data){

          const clientFile={
            "clientFileId":data.clientfileid,
            "client_id":this.headerForm.controls.client.value.key,
            "fileName":null,
            "clientApplicationFileId":data.clientApplicationFileId,
            "createdBy":1, 
            "clientFileMasterId":res.clientfilemasterId,
           "isActive":true,
           "comment":null
         }
          this.loader.showLoader();
       
          this.fileService.editClientFile(clientFile)
          .subscribe(
            data=>{
             // this.toast.showSuccess('Updated Successfully','Success');
             
              this.getFilesData(this.headerForm.controls.client.value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value); 
    
              
              //document.getElementById("header_manage").style.display = 'none';
              //this.headerForm=this.headerForm.value;
              this.workflowJobComponent.callManageToggle();
              this.cookieService.set("clientFileMasterId",res.clientfilemasterId);
              this.cookieService.set("clientFileMasterName",res.name);
            },
            error=>{
              this.toast.showError(error.error.message, "error");
              this.loader.hideLoader();
            },
           
          );



        }


        redirectToWorkflowPage(data){
          let page = '/workflow/manage/workflow';
          this.router.navigate([page],{
           queryParams: {},
         } );

        }

}