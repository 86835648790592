import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientModel } from '../../models/client.model';
import { Binary } from '@angular/compiler';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { map } from 'rxjs/operators';
import { LayoutFilesModel } from '../../models/files.model';
import { getCurrentDate,formatDate } from "src/app/utils/dates";
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { DatePipe } from '@angular/common';
import { TransformPermission } from '../../models/transformPermission.model';
import { Store } from '@ngrx/store';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { ClientSelectorComponent } from 'src/app/clientComp/components/client-selector/client-selector.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { CategoryHeaderComponent } from 'src/app/categoryComp/components/category-header/category-header.component';
import { CategoryService } from '../../services/category.service';
import { CategoryModel } from '../../models/category.model';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  // @ViewChild(ClientSelectorComponent, { read: false, static: false })
  // clientSelectorComponent: ClientSelectorComponent;

  @ViewChild(CategoryHeaderComponent, { read: false, static: false })
  CategoryHeaderComponent: CategoryHeaderComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;
// @ViewChild('fromCal')
// fromCalVariable: ElementRef;
  categoryList:CategoryModel[];
  editData:CategoryModel[];
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  state:string;
  menu:string;
  submenu:string;
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService,private fileService:FileService,private toast:ToastService,
    private router: Router,private categoryService:CategoryService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
     
    this.titleService.setTitle("Category");
    this. searchByCols = [
     
      { field: "categoryName", header: "Category Name" },
      { field: "descriptions", header: "Description" },
      
    ];
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag");

    }
  ngOnInit(): void {
   //this.buttonShowHidePermission();
this.getAllCategoryList();
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllCategoryList(){
    
    this.categoryService.getAllCategoryList().subscribe((data)=>{
      this.categoryList=data;
  
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }
  
  

  saveData(categoryForm){
    
    const model={
      categoryId:categoryForm.categoryId!==null?categoryForm.categoryId:0,
      categoryName:categoryForm.categoryName,
      status:categoryForm.statusMap.key=='Active'?true:false,
      descriptions:categoryForm.description,
      validFromDate:categoryForm.validFromDate,
      validToDate:categoryForm.validToDate
    }
    
   
   this.categoryService.saveOrUpdate(model).subscribe(
     (data) => {
  
    this.toast.showSuccess("Category Saved Successfully", "success");
    this.CategoryHeaderComponent.reset();
    this.getAllCategoryList();
   
  
   
  },
  (err) => {
  
    this.toast.showError(err.error.message, "error");
  }

);
   
 
}


editCategory(rowData){
    this.editData=rowData;
    this.CategoryHeaderComponent.openEdit();
    
  }


  deleteCategoryId(data) {
        const genericModel ={
         "categoryId": data.categoryId
       }
    
        //this.loader.showLoader();
        this.categoryService.deleteCategoryId(genericModel).subscribe(
          data => {
              this.toast.showSuccess('Success', 'Category deleted successfully');
              this.getAllCategoryList();
            },
            err => {
              this.toast.showWarn('This Category is already linked with Reports','error');
            });
      }

}
