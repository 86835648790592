import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notification-list-table',
  templateUrl: './notification-list-table.component.html',
  styleUrls: ['./notification-list-table.component.scss']
})
export class NotificationListTableComponent implements OnInit {

  @Input() notificationList;
  @Output() editNotification = new EventEmitter();
  @Output() deleteNotification = new EventEmitter();
  @Input() rolePermission;
  dataValue:any;
  visibleDelete: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }


  editRowNotification(data){

  this.editNotification.emit(data);
    
  }

  deleteNotificationGroup(data){

    this.deleteNotification.emit(data.notification);

  }

  showPopupDelete(data) {
    this.dataValue = data;
    this.visibleDelete = true;
  }

  hidePopup() {
    debugger;
    this.dataValue = null;
    this.visibleDelete = false;
  }

  actionDo(str){
    this.deleteNotificationGroup(this.dataValue);
    this.dataValue=null;
    this.visibleDelete=false;
  }

}
