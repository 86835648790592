import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { FormsModel } from 'src/app/models/forms.model';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientModel } from '../../models/client.model';
import { Binary } from '@angular/compiler';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { map } from 'rxjs/operators';
import { LayoutFilesModel } from '../../models/files.model';
import { getCurrentDate,formatDate } from "src/app/utils/dates";
import { ClientFileMasterService } from '../../services/clientFileMaster.service';
import { DatePipe } from '@angular/common';
import { TransformPermission } from '../../models/transformPermission.model';
import { Store } from '@ngrx/store';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { ClientSelectorComponent } from 'src/app/clientComp/components/client-selector/client-selector.component';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { DepartmentService } from '../../services/department.service';
import { DepartmentModel } from '../../models/department.model';
import { RequestService } from '../../services/request.service';
import { RequestHeaderComponent } from 'src/app/requestComp/components/request-header/request-header.component';
import { RequestSelectorComponent } from 'src/app/requestComp/components/request-selector/request-selector.component';
import { RequestTableComponent } from 'src/app/requestComp/components/request-table/request-table.component';



@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  @ViewChild(RequestHeaderComponent, { read: false, static: false })
  requesttHeaderComponent: RequestHeaderComponent;

  @ViewChild(RequestSelectorComponent, { read: false, static: false })
  requestSelectorComponent: RequestSelectorComponent;

  @ViewChild(RequestTableComponent, { read: false, static: false })
  requestTableComponent: RequestTableComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;
  requestList:any[];
  searchQuery!: any;
  searchCol:String;
  searchByCols:{field:String , header:String }[];
  selectedRow:any;
  previousList:any;
  newList:any;
  messageList: any;
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  
  
  state:string;
  menu:string;
  submenu:string;
  approveRejectVisible: boolean = false;
  messageBoxVisible: boolean = false;
  pollingInterval: any;
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService,private fileService:FileService,private toast:ToastService,
    private router: Router,private requestService:RequestService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
    
    this.titleService.setTitle("Request");
    
    this. searchByCols = [
     
      { field: "userName", header: "User" },
      { field: "requestPageName", header: "Request Page Name" },
      
    ];

    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag"); 

    }
  ngOnInit(): void {
   this.startRequestStatusPolling();
   //this.getAllRequestList();
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );

   
  }

  ngOnDestroy(): void {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }

  startRequestStatusPolling() {
    this.getAllRequestList(); // Run immediately

    this.pollingInterval = setInterval(() => {
      this.getAllRequestList();
    }, 5000); // Run every 5 seconds
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  

  getAllRequestList(){
    
    this.requestService.getAllUserRequest().subscribe((data)=>{
      this.requestList=data;
      console.log("this.requestList on parent: ",this.requestList);
    
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }

  approveRejectFun(data){
    
    this.requestService.userRequest(data).subscribe(
      (res:any) => {
        const result = res.status;
        this.toast.showSuccess('Success', "Request is "+result);
        this.getAllRequestList();
      },
      (err) => {
        //this.loader.hideLoader();
        console.log("errorResponse",err);
        this.toast.showError('Error', err.error.message);
        this.approveRejectVisible=false;
      }
    );

  }

  showMoreData(rowData){
    this.selectedRow=rowData;
    console.log("more info on parent: ",this.selectedRow);
    this.previousList=JSON.parse(rowData.previousValue);
    this.newList=JSON.parse(rowData.newValue);

    if(rowData.approvedBy==null && rowData.rejectedBy==null){
      this.approveRejectVisible=true;
    }else{
      this.approveRejectVisible=false;
    }
    if(this.selectedRow.messageContains){
      this.messageBoxVisible=false;
    }else{
      this.messageBoxVisible=true;
    }
    console.log("approveReject flag: ",this.approveRejectVisible);
    console.log("On parent file this.previousList: ",this.previousList);
    console.log("On parent file this.newList: ",this.newList)
    this.requesttHeaderComponent.openForm();
    
  }

  getAllMessageFun(userRequestId){
    this.requestService.fetchMessage(userRequestId).subscribe(
      (res:any) => {
        console.log("response messagelist: ",res);
        this.messageList=res;
        console.log("response messagelist: ",res);
        //this.toast.showSuccess('Success', "Request is "+result);
       
      },
      (err) => {
        //this.loader.hideLoader();
        console.log("errorResponse",err);
        //this.toast.showError('Error', err.error.message);
        //this.approveRejectVisible=false;
      }
    );
 
  }

}
