import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { ClientService } from '../../services/client.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { ReportHeadService } from '../../services/reportHead.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';

@Component({
  selector: 'app-head-liquidity-statment-mapping',
  templateUrl: './head-liquidity-statment-mapping.html',
  styleUrls: ['./head-liquidity-statment-mapping.scss']
})
export class HeadLiquidityStatmentMapping implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;
  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;

  activeRoute$: Subscription;
  modalForm: FormGroup = null;
  clientsList:{key: any; value: any }[]=[];
  companyName:string=null;
  adminFlag:String;
  state:string;
  menu:string;
  submenu:string;
  companyId:any;
  particulars:any[];
  layoutFields: any[];
  mapData = new Map();
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  flowTypeList:{key: any; value: any }[]=[];
  flowType:string=null;
  constructor(private activeRoute: ActivatedRoute,private titleService: TitleService,private cookieService: CookieService,
    private clientService:ClientService,private toast:ToastService,private fileService:FileService,private reportHeadService:ReportHeadService,
    private sspapplicationService:SspApplicationService) {

  this.searchByCols = [
     
        { field: "particularName", header: "particularName" },
      ];

   this.modalForm = new FormGroup({
        client: new FormControl(),
        flowType: new FormControl(null,Validators.required)
      });

    this.adminFlag=this.cookieService.get("adminFlag");
    this.menu=localStorage.getItem("menu");
    this.submenu=localStorage.getItem("submenu");
    this.state=localStorage.getItem("stateflag");
   }

  ngOnInit(): void {
    this.setTitle();
    this.getClients();
    this.getFlowType();
    this.getLiquidityStatementMaster();
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
    );
    this.activeRoute.queryParamMap.subscribe(
      (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
    );
  
 
   this.fileService.getClientByUserId().subscribe(
     (data) =>{
    
       for(var i=0;i<data.length;i++){
         console.log("data  ",data[i].clientId)
         this.companyId=data[i].clientId
         this.companyName=data[i].clientName
       }
       this.modalForm.get("client").setValue({
         key: this.companyId,
         value: this.companyName
       });

   

    //  console.log("this.headerForm",this.modalForm.value)
     this.clientSelected(this.modalForm.value,'toggleOnInit')
     
    
     },
     (err)=>{
       this.toast.showError(err.error.message, "error");
     }
     );
  }


  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getClients() {  
    this.clientService.getAllClients().subscribe(

      (data) => {  
        this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");

        console.log(this.clientsList)

      },

      (err) => {

        this.toast.showError(err.error.message, "error");

      }

    );

  }

  clientSelected(data,toggleFlag) {
    if(toggleFlag=='toggleOnLoad'){
      this.panelFilter.toggleSlider();
    }
    let clientId=data.client.key;
    console.log("on hit..........",clientId)
    if(clientId==null || clientId==undefined || clientId==""){
        //this.glHeadList=[];
        this.companyName="";
        this.toast.showInfo("Info","Please Select Company")
        return;
    }
    if(data.flowType.key==null || data.flowType.key==undefined || data.flowType.key==""){
      //this.glHeadList=[];
      this.companyName="";
      this.toast.showInfo("Info","Please Select Flow Type")
      return;
  }

    this.cookieService.set('clientId',this.modalForm.controls.client.value.key);
    this.cookieService.set('clientName',this.modalForm.controls.client.value.value);
  if(this.modalForm.controls.client.value.key!=undefined || this.modalForm.controls.client.value.key!=null){
    this.companyName=this.modalForm.controls.client.value.value;
    this.flowType=this.modalForm.controls.flowType.value.value;
   //this.getLiquidityStatements(this.modalForm.controls.client.value.key);
   this.getParticulars();
  }
    
  }


  getParticulars(){
    const model={
      flowType:this.modalForm.controls.flowType.value.key,
      clientId:this.modalForm.controls.client.value.key,
      reportCode:"DNBS4BSL"
    }
    this.reportHeadService.getParticulars(model).subscribe(

      (data) => {  
      this.particulars=data;
      },

      (err) => {

        this.toast.showError(err.error.message, "error");

      }

    );


  }

  getLiquidityStatements(clientId){
    this.reportHeadService.getLiquidityStatementByClient(clientId).subscribe(

      (data) => {  
        this.layoutFields = data.map((d) => ({
          key: d.liquidityStatementId,
          value: `${d.description} (${d.flowType})`,
        }));
        this.layoutFields.splice(0,0,{key:null,value:'Select Liquidity Statement'})
       // this.loader.hideLoader();
      },

      (err) => {

        this.toast.showError(err.error.message, "error");

      }

    );


  }


  getLiquidityStatementMaster(){
    this.reportHeadService.getLiquidityStatementMaster().subscribe(

      (data) => {  
        this.layoutFields = data.map((d) => ({
          key: d.liquidityStatementMasterId,
          value: `${d.liquidityStatement} (${d.flowType})`,
        }));
        this.layoutFields.splice(0,0,{key:null,value:'Select Liquidity Statement'})
       // this.loader.hideLoader();
      },

      (err) => {

        this.toast.showError(err.error.message, "error");

      }

    );


  }


  updateField(data) {
    const arry = [
      data.particularName,
      data.liquidityStatementMasterId,
      data.particularsId,
    ];

var array=Array.from(this.mapData.values());
  for(let a=0;a<array.length;a++){
    var arr=Array.from(array[a]); 
    if(arr[1]===data.liquidityStatementId && arr[1]!=null){
     this.toast.showWarn("Already mapped with "+arr[0],"warn");
    }
  }  
    this.mapData.set(data.particularName, arry);
    console.log("update", this.mapData);
  }


  saveMapping() {
    const dataToSave = [];
      for (let entry of this.mapData.entries()) {
        const saveData = entry[1];
        const dataMapping = {
          particularsId: saveData[2],
          liquidityStatementMasterId: saveData[1],
          particularName: saveData[0],
          clientId:this.modalForm.controls.client.value.key
        };
        dataToSave.push(dataMapping);
    }
    
    console.log("dataToSave---",dataToSave)
   //this.loader.showLoader();
    this.reportHeadService.saveLiquidityStatementMapping(dataToSave).subscribe(
      (res) => {
        this.toast.showSuccess("success", "Data Saved");
        // this.loader.hideLoader();
        // this.fieldMappings = [];
        // this.layoutFields = [];
        // this.fieldMappingsTableComponent.fields = [];
        this.getParticulars();
        this.mapData.clear();
        // this.fieldMappingsTableComponent.addArrayFields(0);
        // this.getFieldLayout
        // this.getFieldMapping(this.headerForm.controls.file.value.key);
        // const fileData = {
        //   key: this.headerForm.controls.file.value.key,
        //   value: this.headerForm.controls.file.value.value,
        // };
        // this.getFileLayout(fileData);
        // this.mappingDataSaved.emit("");
      },
      (err) => {
        this.toast.showError(err.error.message,"error")
        //this.loader.hideLoader();
      }
    );
  }



  getFlowType(){
    let type="flow_type";
    const dataVal={
      "type" :type
     }
       this.sspapplicationService.getAllGenericDropDownData(dataVal)
       .subscribe(
      (data)=> {
         this.flowTypeList = reMapForAutoComplete(data,"itemValue", "itemText");
         //console.log("generic data"+this.allGenericData);
         const defaultMap = {
          key: "Outflow",
          value: "Outflow",
        };
       this.modalForm.get('flowType').setValue(defaultMap);
      },
     (err) => {
       this.toast.showError(err.error.message, "error");
     }
     );
   }



}
