import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientPortalService } from 'src/app/dashboard/services/client-portal.service';

@Component({
  selector: 'app-video-upload-table',
  templateUrl: './video-upload-table.component.html',
  styleUrls: ['./video-upload-table.component.scss']
})
export class VideoUploadTableComponent implements OnInit {

  @Input() data:any[];

  @Output() setStatus=new EventEmitter();
  @Output() deleteFile=new EventEmitter();
  @Output() editFile=new EventEmitter();
  
  dataValue:any;
  visibleDelete: boolean = false;

  constructor(private clientPortalService:ClientPortalService,private toast:ToastService) { }

  ngOnInit(): void {
    console.log("data",this.data)
  }

  setStatusData(data){

    this.setStatus.emit(data);
  }

 deleteRow(data){
    this.dataValue = data;
    this.visibleDelete = true;
  }

  hidePopup() {
    debugger;
    this.dataValue = null;
    this.visibleDelete = false;
  }

  actionDo(str){

   this.deleteFile.emit(this.dataValue);
   this.dataValue=null;
    this.visibleDelete=false;
  }


  editRow(rowData){
    console.log("API gets call",rowData);
    this.clientPortalService.getVideoFilesListById(rowData.id).subscribe(
          
      data=>{
        console.log("value------- ",data[0]);
       
        this.editFile.emit(data[0]);
        
      },
       err=>{
        this.toast.showError(err.error.message, "error");
       }
    )


  }


}
