import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { FormsModel } from 'src/app/models/forms.model';
import {trim} from 'lodash';
import { ToastService } from 'src/app/core/services/toast.service';
import { TableViewerService } from 'src/app/dashboard/services/table-viewer.service';
import { DbconnectionService } from 'src/app/dashboard/services/dbconnection.service';
import { Console } from 'console';
//import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
//@UntilDestroy()
@Component({
  selector: 'app-data-transfer-form',
  templateUrl: './data-transfer-form.component.html',
  styleUrls: ['./data-transfer-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTransferFormComponent implements OnInit {
  @Output() closePanel=new EventEmitter();
  headerForm: FormGroup = null;
  @Input() dbConnectionDataList:any[];
  @Output() getdetailByService=new EventEmitter();
  @Input() databaseNameList;
  @Input() destinationDatabaseNameList;
  @Output() getTableNames=new EventEmitter();
  @Output() callDestinationTableColumnsData=new EventEmitter();
  @Input() destColumns:any;

  @Input()  tableNameList
@Input()  destinationTableNameList
@Output() transferToSaveData=new EventEmitter();
viewMode:boolean=false;
disable:boolean=true;
isMapping:boolean=false;
labelList:any[]=null;

@Output() dataDumpInNewTb=new EventEmitter();
selectedColumns:any=null;
@Input() columnsList:any;
@Output() callTableColumns=new EventEmitter();
columnNameList:any[]; 
rowLimit:number;
enableEdit:string='';
alias: string = '';
destTableName: string='';
  constructor(private router: Router, private activeRoute: ActivatedRoute,private toast:ToastService,private tableViewerService:TableViewerService, private dbconnectionService:DbconnectionService) {
    this.headerForm = new FormGroup({
      sourceServiceName:new FormControl(null,[Validators.required]),
      sourceDatabaseName:new FormControl(null,[Validators.required]),
      sourceTableName:new FormControl(null,[Validators.required]),
      destinationServiceName:new FormControl(null,[Validators.required]),
      destinationDatabaseName:new FormControl(null,[Validators.required]),
      destinationTableName:new FormControl(null,[Validators.required]),
      sourcerowLimit:new FormControl(null,[Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      isMap:new FormControl(false),
      selectSourceColumnList:new FormControl(null,[Validators.required]),
      cols:new FormControl(null),
    });
    
    
   
  }
  ngOnInit(): void { 
   
  }
  
  ngOnChanges(changes: SimpleChanges) {
    console.log("sourceTableName",this.headerForm.controls.sourceTableName.value.value);
    console.log("callTableColumns  ",this.columnsList);
  }

  getDatabaseName(flagData){
    if(flagData=="source"){

    if(this.databaseNameList!=null){
      this.databaseNameList=null;
      this.tableNameList=null;
      this.headerForm.patchValue({
        sourceDatabaseName:null,
        sourceTableName:null
      })
    }
    console.log('formvalue',this.headerForm.controls.sourceServiceName.value.value)
    const reqModel={
      serviceName:this.headerForm.controls.sourceServiceName.value.value,
      flag:flagData
    } 
    this.getdetailByService.emit(reqModel);
    }
    if(flagData=="destination"){

      if(this.destinationDatabaseNameList!=null){
        this.destinationDatabaseNameList=null;
        this.destinationTableNameList=null;
        this.headerForm.patchValue({
          destinationDatabaseName:null,
          desitinationTableName:null
        })
      }

      console.log('formvalue',this.headerForm.controls.destinationServiceName.value.value)
      const reqModel={
        serviceName:this.headerForm.controls.destinationServiceName.value.value,
        flag:flagData
      } 
      this.getdetailByService.emit(reqModel);
    }
    
  }




  getTableName(flagData){
    
    if(flagData=="source"){
      this.disable=false;
      const dbModel={
        databaseName:this.headerForm.controls.sourceDatabaseName.value.value,
        flag:flagData
      } 
this.getTableNames.emit(dbModel);
    }
    if(flagData=="destination"){
      
      const dbModel={
        databaseName:this.headerForm.controls.destinationDatabaseName.value.value,
        flag:flagData
      } 
this.getTableNames.emit(dbModel);
    }

  }




  transferData(){
    console.log("tableNameList: ",this.tableNameList)
    console.log("destinationTableNameList: ",this.destinationTableNameList)
    console.log("this.headerForm  ",this.headerForm)
    console.log("this.headerForm  ",this.headerForm.value)
    
    this.closePanel.emit();
    console.log("DT Name:",this.headerForm.controls.destinationTableName.value.value)
    ///////Validation for new table//////////////////
    if(this.viewMode){
      for(var a=0; a<this.destinationTableNameList.length; a++){
        if(this.destinationTableNameList[a].value === this.headerForm.controls.destinationTableName.value.value){
          return this.toast.showError("Destination Table name already exist", "error");
        }
      }
    }

  if(this.headerForm.valid){
    if(this.headerForm.controls.selectSourceColumnList.value){
      console.log("Updated Form Value: ", this.headerForm);
      if (!this.viewMode) {
        this.headerForm.patchValue({isMap: this.isMapping});
      }
    this.transferToSaveData.emit(this.headerForm.value);
    }
    else{
      this.toast.showWarn("Select  Columns","error");
    }
  }
  if(!this.headerForm.valid && !this.viewMode){
    this.toast.showWarn("All fields are mandatory","error")
  }
  if(!this.headerForm.controls.selectSourceColumnList.value) {
    this.toast.showWarn("Please select  atleast one column","error");
  }
  this.isMapping = false;
  this.labelList=null;

  // if(this.viewMode){
  //   if(this.headerForm.controls.sourcerowLimit.valid){
  //   console.log("this.headerForm.value  ",this.headerForm.value)
  //   if(this.headerForm.controls.selectSourceColumnList.value){
  //   this.dataDumpInNewTb.emit(this.headerForm.value);
  //   }
  //   else{
  //     this.toast.showWarn("Select  Columns","error");
  //   }
  // }else{
  //   this.toast.showWarn("Enter Limit as Number","error");

  // }

  // }
}


  
  resetTransferForm(){
    this.headerForm.reset();
    var element = <HTMLInputElement> document.getElementById("IDOfCheckbox");
    element.checked = false;
    this.viewMode=false;
    this.databaseNameList=null;
    this.tableNameList=null;
    this.destinationDatabaseNameList=null;
    this.destinationTableNameList=null;
    this.selectedColumns=[];
  }

  getSelectedMapping(event){
    if(event.target.checked){
      this.isMapping=true;
      for(var i=0; i<this.labelList.length; i++) {
        this.headerForm.addControl(this.labelList[i], new FormControl(null));
      }
    }
    if(!event.target.checked){
      this.isMapping=false;
      for(var i=0; i<this.labelList.length; i++) {
        this.headerForm.removeControl(this.labelList[i]);
      }
    }
  }
 
  getSelected(event){
    if(event.target.checked){
      this.viewMode=true;
      console.log("this.viewMode ",this.viewMode)
      this.headerForm.patchValue({destinationTableName:this.headerForm.controls.sourceTableName.value})
    }
    if(!event.target.checked){
      this.viewMode=false;
      console.log("this.viewMode ",this.viewMode)
    }

  }


  multiColumnSelectChange(eventData){
    this.selectedColumns=[];
    this.selectedColumns = eventData.value;
   console.log("Datatype Value Updated Is:", this.selectedColumns);
   this.labelList=new Array();
   for(var i=0; i<this.selectedColumns.length; i++) {
      this.labelList.push(this.selectedColumns[i].column);
   }
   console.log("this.labelList: "+this.labelList);
   this.headerForm.patchValue({
    selectSourceColumnList:this.selectedColumns
   });
  }
  
  getColumns(data1) {
    if (data1 == "source") {
      const data = {
        "tableName": this.headerForm.controls.sourceTableName.value.value,
        "databaseName": this.headerForm.controls.sourceDatabaseName.value.value,

      };
      this.destTableName = data.tableName;
      console.log("destTableName123: ", this.destTableName);
      this.callTableColumns.emit(data);
    } else if(data1 == "destination") {
      const data = {
        "tableName": this.headerForm.controls.destinationTableName.value.value,
        "databaseName": this.headerForm.controls.destinationDatabaseName.value.value,
      };
      this.callDestinationTableColumnsData.emit(data);
    }
  }


  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes && changes.editAppData && changes.editAppData.currentValue) {
  //     console.log("Selector Changes Are:", changes);
  //     const currentChangeValue = changes.editAppData.currentValue;
  //     let status = {};
  //     currentChangeValue.isActive === true ? status = { key: 'Active', value: 'Active' } : status = { key: 'Inactive', value: 'Inactive' };
  //     const selectedGroupData = {
  //       key: currentChangeValue.groupData.groupId,
  //       value:currentChangeValue.groupData.groupName,
  //     };
  //     const selectedReportMode={
  //       key: currentChangeValue.genericDropDownDataVal.itemValue,
  //       value:currentChangeValue.genericDropDownDataVal.itemText,
  //     }

  //     this.myArray =currentChangeValue.instance;
  //     this.viewList =[];
  //     this.myArray.forEach(x => {
  //       if(x.length !== 0){
  //       this.viewList.push({"viewName": x.instanceName,"viewUrl":x.instanceUrl,"completed": true})
  //       }
  //    });
  //     console.log("this.viewList: ", this.viewList);

  //     this.createAppForm.patchValue({
  //       appId: currentChangeValue.appId,
  //       appName: currentChangeValue.appName,
  //       appStatus: status,
  //       viewList: this.viewList,
  //       groupData:selectedGroupData,
  //       reportUrl:currentChangeValue.embeddedUrl,
  //       reportMode:selectedReportMode,
  //       description:currentChangeValue.appDesc
  //     });

  //     console.log("edit  ",this.createAppForm.controls.reportMode.value)
      
     
  //     this.enableEdit = true;
  //   }
 // }

 
/////////Edit the table Name//////////////////////////////
 update(type,alias,tableName) {
  // console.log("data "+i);
    console.log("tableNameList: ",this.tableNameList)
    console.log("destinationTableNameList: ",this.destinationTableNameList)
  console.log("data "+tableName);
  console.log("data "+alias);
 if(!this.enableEdit && type!='save'){
  this.enableEdit = tableName;
 }
 else if(this.enableEdit && type==='save'){
   if(tableName===this.enableEdit){
     
     var format = /[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
     if(format.test(alias)){
      this.enableEdit = '';
      this.closePanel.emit();
      return this.toast.showError("You can not use special character except  _(underscore) ", "error"); 
     }else
     {
      if(alias.substring(0,1)=='_' || (alias.substring(0,1)=='0 '||  
      alias.substring(0,1)=='1' ||
      alias.substring(0,1)=='2' ||
      alias.substring(0,1)=='3' ||
      alias.substring(0,1)=='4' ||
      alias.substring(0,1)=='5' ||
      alias.substring(0,1)=='6' ||
      alias.substring(0,1)=='7' ||
      alias.substring(0,1)=='8' ||
      alias.substring(0,1)=='9' )){
      this.enableEdit = '';
      this.closePanel.emit();
      return this.toast.showError("Table name should be start only from a to z alphabets", "error");
      }
      console.log("List: ",this.destinationTableNameList)
      for(var a=0; a<this.destinationTableNameList.length; a++){
        if(this.destinationTableNameList[a].value=== alias){
          this.closePanel.emit();
          return this.toast.showError("Destination Table name already exist", "error");
        }
      }
      this.destTableName=alias;
      console.log("destTableName: ", this.destTableName);
       this.headerForm.get('destinationTableName').setValue({
        key: this.destTableName,
        value: this.destTableName
       });
       console.log("formData: ", this.headerForm);
     }
    // this.tableName.isUpdated=1;
    }
    console.log("save  data" +alias);
 }else{
  this.enableEdit = '';
 }
}
  
      
}
