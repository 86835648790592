import { Component, OnInit, SimpleChanges ,EventEmitter, Input, Output} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';

@Component({
  selector: 'app-generic-data-list-comp',
  templateUrl: './generic-data-list-comp.component.html',
  styleUrls: ['./generic-data-list-comp.component.scss']
})
export class GenericDataListCompComponent implements OnInit {

  alias: string = '';
  @Input() data: any[] = [];
  @Input() clientApplicationFile: any;
  @Input() fields: { key: number | string; value: string }[] = [];
  @Input() fileMapping: any[] = [];
  @Output() onSelect = new EventEmitter();
  @Output() savingData=new EventEmitter();
  @Input() isRecomedation;
  @Input() recommendationSize;
  cols = [{ header: "System Fields"},{ header: "Column Name"},{ header: "Data Type"}];
  fieldsArray = new FormGroup({ fields: new FormArray([]) });
  loadPage: boolean = false;
  filteredData: any[];
  editStatus = [];
  enableEdit:string='';
  constructor(private loader: LoaderService,private toast:ToastService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue) {
      this.loader.showLoader();
      this.loadPage = false;
      this.addArrayFields(changes.data.currentValue.length);
    }
  }
  get fieldArray() {
    return this.fieldsArray.get("fields") as FormArray;
  }
  findField(id) {
    return this.fields.find((d) => d.key === id);
  }
  filterData(event) {
    let query = event.query;
    this.filteredData = this.fields.filter(
      (d) => d.value.toLowerCase().indexOf(query.toLowerCase()) === 0
    );
  }
  addArrayFields(length) {
    this.fieldArray.clear();
    for (let i = 0; i < length; i++) {
      const arr = this.fieldArray;
      const data = this.findField(this.data[i].databaseTypeId);
      arr.push(new FormControl(data));
      this.editStatus.push(false);
    }
    this.loadPage = true;
    this.loader.hideLoader();
  }
  updateField(id, headerName, coulumnName2,coulumnName) {
    if (coulumnName2 === undefined) {
      coulumnName2 = null;
    }
     let testString =coulumnName.split(" ");
      if(testString.length>1){
        return this.toast.showError("error","COLUMN NAME DOSEN'T CONTAIN BLANK SPACE");
      }
    console.log("hello update filed");
    console.log("hello update filed",id," ",headerName, " ",coulumnName2," ",coulumnName);
    this.onSelect.emit({
      layoutFieldId: id,
      headerName: headerName,
      primaryKey: coulumnName2,
      alias:coulumnName
    });
  }

  update(i,type,alias,dataVal) {
    //console.log("data "+i);
   // console.log("data "+dataVal.databaseTypeId);
    console.log("type ",type);
   if(!this.enableEdit && type!='save'){
    this.enableEdit = i;
   }else if(this.enableEdit && type==='save'){
   for(let i=0;i<this.data.length;i++){
     if(this.data[i].headerName===this.enableEdit){
       this.data[i].columnName=alias;
      this.data[i].isUpdated=1;
      }
      console.log("Enable EDIT vALUE",this.enableEdit);
   }
   this.enableEdit = '';
  // this.save(dataVal);
   //console.log("save  data" +alias);
   }else{
console.log("else part of update function");
    this.enableEdit = '';
   }
  }

  save(dataVal){
    console.log("data"+this.clientApplicationFile);

    console.log("data "+dataVal);
    const data={
      "alias": dataVal.alias,
      "headerName": dataVal.headerName,
      "clientApplicationFileId":this.clientApplicationFile.clientApplicationFileId,
      "clientFileLayoutFieldId":dataVal.clientFileLayoutFieldId,
      "clientFileMappingId":dataVal.clientFileMappingId,
      "createdAt":dataVal.createdAt,
      "createdBy":dataVal.createdBy,
      "modifiedAt":dataVal.modifiedAt,
      "modifiedBy":dataVal.modifiedBy,
      "tableName":dataVal.tableName!=null? dataVal.tableName:"alias"
    }
    this.savingData.emit(data);
  }

}
