import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { FileService } from 'src/app/files/services/file.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { StressKeyMetricsHeaderComponent } from 'src/app/stressKeyMetricsComp/components/stressKeyMetrics-header/stressKeyMetrics-header.component';
import { StressKeyMetricsModel } from '../../models/stressKeyMetrics.model';
import { StressKeyMetricsService } from '../../services/stressKeyMetrics.service';

@Component({
  selector: 'app-stressKeyMetrics',
  templateUrl: './stressKeyMetrics.component.html',
  styleUrls: ['./stressKeyMetrics.component.scss']
})
export class StressKeyMetricsComponent implements OnInit {

  @ViewChild(StressKeyMetricsHeaderComponent, { read: false, static: false })
  StressKeyMetricsHeaderComponent: StressKeyMetricsHeaderComponent;

  @ViewChild('myInput')
myInputVariable: ElementRef;

@ViewChild("panelForm", {static: false })
panelForm!:SidePanelComponent;
// @ViewChild('fromCal')
// fromCalVariable: ElementRef;
stressKeyMetricsList:StressKeyMetricsModel[];
  editData:StressKeyMetricsModel[];
  
  activeRoute$: Subscription;
 
  modalForm: FormGroup = null;
 
  fieldSeparatorList:{ key: string | number; value: string }[];
  searchByCols:{field:String , header:String }[];
  searchQuery!: any;
  searchCol:String;
  state:string;
  
  constructor( private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private loader:LoaderService, private sspapplicationService: SspApplicationService,private fileService:FileService,private toast:ToastService,
    private router: Router,private stressKeyMetricsService:StressKeyMetricsService,  private applicationService: SspApplicationService,
    private datePipe:DatePipe,private storePermission: Store<{ role: any }>) {
     
    this.titleService.setTitle("Stress Key Metrics");
    this. searchByCols = [
     
      { field: "summary", header: "Summary" },
      { field: "baseline", header: "Baseline" },
      { field: "medium", header: "Medium" },
      { field: "severe", header: "Severe" },
      
    ];

    this.state=localStorage.getItem("stateflag");

    }
  ngOnInit(): void {
   //this.buttonShowHidePermission();
this.getAllStressKeyMetricsList();
   this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
  );
  this.activeRoute.queryParamMap.subscribe(
    (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
  );
  }
  
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }


  getAllStressKeyMetricsList(){
    
    this.stressKeyMetricsService.getAllStressKeyMetricsList().subscribe((data)=>{
      this.stressKeyMetricsList=data;
  console.log("stressKeyMetricsList", this.stressKeyMetricsList );
     
    },
    (err) => {
      this.toast.showError(err.error.message, "error");
      
    }
    );
  }
  
  

  saveStressKeyMetrics(stressKeyMetricsForm){
    console.log("test",stressKeyMetricsForm)
    const model={
      id:stressKeyMetricsForm.id!==null?stressKeyMetricsForm.id:0,
      summary:stressKeyMetricsForm.summary,
      status:stressKeyMetricsForm.statusMap.key=='Active'?true:false,
      baseline:stressKeyMetricsForm.baseline,
      medium:stressKeyMetricsForm.medium,
      severe:stressKeyMetricsForm.severe
    }

   this.stressKeyMetricsService.saveOrUpdate(model).subscribe(
     (data) => {
  
    this.toast.showSuccess("Stress Key Metrics Saved Successfully", "success");
    this.StressKeyMetricsHeaderComponent.reset();
    
    this.getAllStressKeyMetricsList();
   
  },
  (err) => {
  
    this.toast.showError(err.error.message, "error");

  }

);

}


editStressKeyMetrics(rowData){
    this.editData=rowData;
    this.StressKeyMetricsHeaderComponent.openEdit();
    
  }


  deleteStressKeyMetricsId(data) {
        this.stressKeyMetricsService.deleteStressKeyMetricsById(data.id).subscribe(
          data => {
              this.toast.showSuccess('Success', 'Stress Key Metrics deleted successfully');
              this.getAllStressKeyMetricsList();
            },
            err => {
              this.toast.showError(err.error.message, "error");
            });
      }

}
