import { Component, OnInit, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.css"]
})
export class CheckboxComponent implements OnInit {
  @Input() label: string;
  @Input() class: string;
  @Input() ctrl: FormControl;
  @Input() checkedBox:boolean;
  constructor() {}

  ngOnInit(): void {}
}
