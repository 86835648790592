import { DatePipe } from '@angular/common';
import { Component, OnInit,ViewChild } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientPortalService } from '../../services/client-portal.service';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ActivatedRoute } from '@angular/router';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { FormControl, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  @ViewChild("panelFilter", {static: false })
  panelFilter!:SidePanelComponent;

  fileDataList:{key: any; value: any}[]=[];
  activeRoute$: Subscription;
  categoryVideoList:{key: any; value: any}[]=[];
  headerForm:FormGroup=null;
  categorySelect:string;
  visible:boolean=true;
  state:string;
  constructor(private clientPortalService:ClientPortalService,private datePipe: DatePipe,private toast:ToastService,
    private titleService: TitleService,private activeRoute: ActivatedRoute,private cookieService: CookieService) {

      this.headerForm = new FormGroup({
        category:new FormControl(null)
      });

      this.state=localStorage.getItem("stateflag");

     }

  ngOnInit(): void {
    this.setTitle();
    
   
    this.setTitle();
    console.log("working init--------------");
      this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
        par => {
          if (par.has("videoId")) {
            console.log("working--------------");
            this.visible=false;
            document.getElementById("sidebar").style.display="none";
            let id=par.get("videoId");

            
              this.clientPortalService.getVideoFilesById(id).subscribe(
          
                data=>{
                  this.fileDataList=data;
                  
                },
                 err=>{
                  this.toast.showError(err.error.message, "error");
                 }
              )
          
            
           
            //this.env = environment.reportUrl + url;
          }
          else{

            this.getAllVideos();
            this.getAllActiveVideoCategory();
          }
        
        }
      );

 
 


  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }


  getAllVideos(){
    this.clientPortalService.getAllACtiveVideosList(this.state).subscribe(

      data=>{
        this.fileDataList=data;
       
      },
       err=>{
        this.toast.showError(err.error.message, "error");
       }
    )

   }


   getAllActiveVideoCategory(){
    this.clientPortalService.getAllActiveCategoryList(this.state).subscribe(

      res=>{

        this.categoryVideoList=reMapForAutoComplete(res,"videoCategoryId","categoryName");
        //this.toast.showSuccess('Successfully','Deleted');
          //this.getAllVideos();
        
      },
       err=>{
        this.toast.showError(err.error.message, "error");
       }
    )



   }



  

   onLoad(){
    this.panelFilter.toggleSlider();
    console.log("working3",this.headerForm.value);
    if(this.headerForm.controls.category.value.key!=null && this.headerForm.controls.category.value.key!=undefined && this.headerForm.controls.category.value.key!=0){
      console.log("working");
      this.categorySelect=this.headerForm.controls.category.value.value;
      this.clientPortalService.getVideoCategoryByName(this.headerForm.value,this.state).subscribe(

        data=>{
          this.fileDataList=data;
          
         
        },
         err=>{
          this.toast.showError(err.error.message, "error");
         }
      )
     
      
    }
    else{
      console.log("working2");
      this.toast.showInfo("Please Select Category", "error");
    }
   }

   reset(){
    this.getAllVideos();
    this.headerForm.reset();
    this.headerForm.get('category').setValue("");
    this.categorySelect="";
    this.panelFilter.toggleSlider()
   }

   

}
