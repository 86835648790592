import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { GenericDataListCompComponent } from './generic-data-list-comp/generic-data-list-comp.component';
import { GenericFileParameterComponent } from './generic-file-parameter/generic-file-parameter.component';
import { GenericFileHeaderComponent } from './generic-file-header/generic-file-header.component';
import { UiModule } from '../ui/ui.module';
import { ClientPortalModule } from '../client-portal/client-portal.module';
import { GenericFieldLayoutFormComponent } from './generic-field-layout-form/generic-field-layout-form.component';



@NgModule({
  declarations: [ GenericDataListCompComponent,GenericFileParameterComponent,GenericFileHeaderComponent, GenericFieldLayoutFormComponent],
  imports: [SharedModule,CommonModule,UiModule,ClientPortalModule],//;
  exports: [
    GenericDataListCompComponent,GenericFileParameterComponent,GenericFileHeaderComponent,GenericFieldLayoutFormComponent
  ],
  providers: []
})
export class GenericDataModule { }
