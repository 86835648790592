import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientService } from 'src/app/dashboard/services/client.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { FileService } from 'src/app/files/services/file.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-report-group-parameter',
  templateUrl: './report-group-parameter.component.html',
  styleUrls: ['./report-group-parameter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportGroupParameterComponent implements OnInit,OnChanges {
  //@Input() groupList: {key: any; value: any }[]=[];
  reportList:{key: any; value: any }[]=[];
  @Input() clientList:{key: any; value: any }[]=[];
  @Output() fetch = new EventEmitter();
  @Output() closePanel=new EventEmitter();
  @Input() passFormData:any;
  enableEdit:boolean=false;
  parameterForm:FormGroup;
  url:string=null;
  env:any;
  viewList:any=[];
  activeRoute$: Subscription;
  clientId:any;
  company:String;
  reportId:any;
  reportName:String;
  allGroupData: {key: any; value: any }[]=[];
  storeViewList:any=[];
  constructor(private reportDashboardService:ReportDashboardService,private clientService:ClientService ,private fileService:FileService,
    private activeRoute: ActivatedRoute,private sspApplicationService: SspApplicationService,private sspapplicationService:SspApplicationService, private toast:ToastService) {
    this.parameterForm = new FormGroup({
      client:new FormControl(null,[Validators.required]),
      groupData:new FormControl(null, [Validators.required]),
      reportData:new FormControl(null,[Validators.required]),
      viewData:new FormControl(null,[Validators.required]),
      url:new FormControl(null),
      instanceHeadName:new FormControl(null)
     });

  }

  ngOnInit(): void {
    this.getAllGroupData();

  this.fileService.getClientByUserId().subscribe(
    (data) =>{
      
      for(var i=0;i<data.length;i++){
        console.log("data  ",data[i].clientId)
        this.clientId=data[i].clientId
        this.company=data[i].clientName
      }
      this.parameterForm.get("client").setValue({
        key: this.clientId,
        value: this.company
      });
      // this.onGroupChange('onInit');
      
    },
   (err) => {
     //this.toast.showError(err.error.message, "error");
   
   } 
  );
  console.log("client :", this.parameterForm.get("client"));

  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes && changes.passFormData && changes.passFormData.currentValue && changes.passFormData.firstChange) {
    //   const d: any = changes.passFormData.currentValue;
    //   this.parameterForm.patchValue({
    //     client:  { key: d.client.key, value: d.client.value },
    //      groupData: { key: d.GroupData.key, value: d.GroupData.value },
    //     // reportData: { key: d.ReportData.key, value: d.ReportData.value },
    //     // viewData:  { key: d.viewData.key, value: d.viewData.value },
    //   });
    //   this.enableEdit = true;
    //   console.log("on changes form",this.parameterForm.value)
    // }
   
   // console.log("on changes form",this.parameterForm.value)
  }


  
  fetchReportGroupData() {
    if(this.parameterForm.valid){
      console.log("inside fetch")
      this.fetch.emit(this.parameterForm.value);
      console.log("testDashboardData",this.parameterForm.value);
  } else{
   // this.toast.showError("All Fields Are Mandatory", "error");
  }
  this.closePanel.emit();
}

onGroupChange(event){

  // console.log("evetData  ",event.target.value)
 // this.headerForm.controls.ReportData.reset();
  //this.headerForm.controls.viewData.reset();
  //this.env=null;
  //this.viewList=[];
  
 
  const dataVal={
    "groupId":this.parameterForm.controls.groupData.value.key,
    "reportMode":"readytouse"
  }

  this.reportDashboardService.getReportByGroupId(dataVal).subscribe(

    res => {
      // this.loader.hideLoader();
      
      this.reportList=res;
      console.log("this.reportList  ",this.reportList);
      this.reportList=reMapForAutoComplete( this.reportList,"appId","appName");
       console.log("all report  ", this.reportList);
       this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
        par => {

          if (par.has("appId") && par.has("appName")) {
            if((par.get("groupId")==this.parameterForm.controls.groupData.value.key)){
            this.parameterForm.get("reportData").setValue({
              key: par.get("appId"),
              value: par.get("appName")
            });
            this.getViewList(this.parameterForm.controls.viewData.value)
          }
          else{this.parameterForm.get("reportData").patchValue({key:null,value:null});
        this.viewList=null;
        this.parameterForm.get("viewData").setValue({key:null,value:null});
       // this.parameterForm.get("url").setValue(null)
        //this.parameterForm.controls.url.setValue(null);}
        //     console.log("appName: ", this.parameterForm.value.reportData);
      console.log("parameter value: ", this.parameterForm.value);
          }

        }});

      },
      err => {
       // console.log('url',err);
      // this.toast.showError("failed to get groups", "error");

      }
  )


}

getViewList(data){

     
 // console.log("data  ",data)
  //this.headerForm.controls.viewData.reset();
  this.env=null;
  const reportId={
    appId:this.parameterForm.controls.reportData.value.key

  };
  this.sspApplicationService.getInstanceByAppId(reportId).subscribe(

    data=>{
    this.viewList=data
   this.storeViewList = data;
    console.log("this.viewList  ",this.viewList)
    this.viewList=reMapForAutoComplete( this.viewList,"instanceUrl","instanceName");
    this.parameterForm.get("instanceHeadName").setValue(this.storeViewList[0].reportHeading);
  //      console.log("all viewList  ", this.viewList);

  //      console.log("viewList[0]  ",this.viewList[0])
  // this.parameterForm.patchValue({
  //   viewData: {key:this.viewList[0].key ,value:"Default"}
  this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
    par => { 
      if (par.has("instanceId") && par.has("instanceName")) {

        if((par.get("groupId")==this.parameterForm.controls.groupData.value.key) && (par.get("appId")==this.parameterForm.controls.reportData.value.key)){
        this.parameterForm.get("viewData").setValue({
          key: par.get("instanceUrl"),
          value: par.get("instanceName")
        });
      }
      else{this.parameterForm.get("viewData").setValue({key:null,value:null})}
        console.log("viewData value: ", this.parameterForm.value);
      }
    }
  );
  

 // this.getreport(this.viewList[0]);

    },
    err=>{
        // console.log('url',err);
    //    this.toast.showError("failed to get views", "error");
 
       }
   
  )
  // console.log("viewList  ",this.viewList[1])
  // this.headerForm.patchValue({
  //   viewData: this.viewList[1]

  // })

}


getreport(data){
  //this.url=this.viewList[0].key;
 // console.log("client id is",this.parameterForm.controls.client.value.key)
 //this.env= this.viewList[0].key+"?INCLUDETITLE=false"; 
 this.env=this.parameterForm.controls.viewData.value.key;
 
//  for(var i=0;i<this.storeViewList.length;i++){

//   if(this.parameterForm.controls.viewData.value.value===this.storeViewList[i].instanceName){
//     this.parameterForm.get("instanceHeadName").setValue(this.storeViewList[i].reportHeading);
//   }
// }
 
  // if(this.clientId==0 || this.clientId!=undefined || this.clientId!=null){
  //   this.env= data.key+"?INCLUDETITLE=false&ZOHO_CRITERIA=%22company_id%22%3D"+this.clientId;
  // }
 
  // if (!this.env.includes('https://zoho-server.uventure.in/open-view/')) { 
  //   this.env=null;
  
  // }
  this.parameterForm.controls.url.setValue(this.env);
  console.log('url last value',this.env);
  
}

getAllGroupData(){

  this.sspapplicationService.getAllGroupDataForGrouppage().subscribe((data)=>{
   // this.allGroupData=data;
    this.allGroupData = reMapForAutoComplete(data, "groupId", "groupName");
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
  
        console.log("parameter value: ", par);
        if (par.has("clientId") && par.has("clientName")) {
        //   this.headerForm.get("client").setValue({
        //     key: par.get("clientId"),
        //     value: par.get("clientName")
        //   });
        //  this.getAllWor kflow();
        this.parameterForm.get("client").setValue({
          key: par.get("clientId"),
          value: par.get("clientName")
        });
        console.log("parameterForm value: ", this.parameterForm);
       }
  
        if (par.has("groupId") && par.has("groupName")) {
          this.parameterForm.get("groupData").setValue({
            key: par.get("groupId"),
            value: par.get("groupName")
          });
        console.log("groupName value: ", par.get('groupName'));
        console.log("groupName.get value: ", this.parameterForm.get('groupData'));
        this.onGroupChange(this.parameterForm.controls.groupData.value);
        }
      })
     },
  (err) => {
    this.toast.showError(err.error.message, "error");
  }
  );
}



}
