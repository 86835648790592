import { Pipe, PipeTransform } from '@angular/core';
import { isAfter, isBefore, isWithinInterval } from 'date-fns';

@Pipe({
  name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
  transform<T>(
    arr: T[],
    column: string,
    value: any,
    comparator: string = '=',
    value2 = ''
  ): T[] {
    if (!arr || arr.length <= 0) {
      return [];
    } else if (column === '' || value === '' || comparator === '') {
      return arr;
    } 
    else {
      return arr.filter((d) => {
       // console.log('typeof(column)  ',typeof(column),column)
        const dt: any = d[column as keyof T];
       // console.log('dt  ',typeof(dt),dt)
        if(typeof(dt) === 'number'){
          switch (comparator) {
                   case 'search':
                   return dt==(value);

        }
      }
       else if(dt==null){
         
          switch (comparator) {
                   case 'search':
                   return "null".includes(value.toLowerCase());

        }
      }
      else{
        switch (comparator) {
          case '!=':
            return dt !== value;
          case '>':
            return dt > value;
          case '<':
            return dt < value;
          case '<=':
            return dt <= value;
          case '>=':
            return dt >= value;
          case 'in':
            return value.includes(dt);
          case 'search':
            return dt.toLowerCase().includes(value.toLowerCase());
          case 'contain':
            return dt.toLowerCase().includes(value);
          case 'start':
            return dt.toLowerCase().startsWith(value);
          case 'end':
            return dt.toLowerCase().endsWith(value);
          case 'after':
            return isAfter(new Date(dt), value);
          case 'before':
            return isBefore(new Date(dt), value);
          case 'term':
            return this.filterByTerm(arr, value, []);
          case 'between':
            try {
              return isWithinInterval(new Date(dt), {
                start: new Date(value),
                end: new Date(value2),
              });
            } catch {
              return true;
            }
          default:
            return dt === value;
        }
      }
      });
    }
  }
  filterByTerm(
    items: Array<{ [key: string]: any }>,
    term: string,
    excludes: any
  ): Array<{ [key: string]: any }> {
    const toCompare = term.toLowerCase();

    function checkInside(item: any, term: string) {
      if (
        typeof item === 'string' &&
        item.toString().toLowerCase().includes(toCompare)
      ) {
        return true;
      }

      for (let property in item) {
        if (
          item[property] === null ||
          item[property] == undefined ||
          excludes.includes(property)
        ) {
          continue;
        }
        if (typeof item[property] === 'object') {
          if (checkInside(item[property], term)) {
            return true;
          }
        } else if (
          item[property].toString().toLowerCase().includes(toCompare)
        ) {
          return true;
        }
      }
      return false;
    }

    return items.filter(function (item) {
      return checkInside(item, term);
    });
  }
}
