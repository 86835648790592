import { Component, OnInit,Input,Output,EventEmitter,SimpleChanges} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';


@Component({
  selector: 'app-gl-mapping-selector',
  templateUrl: './gl-mapping-selector.component.html',
  styleUrls: ['./gl-mapping-selector.component.scss']
})
export class GlMappingSelectorComponent implements OnInit {

  @Input() glHeadList;
  @Input() keyMeasureHeadList;

  @Input() calculatedList;
  @Input() KeymeasuresDataList;
  @Input() getcalculationType;

  glMappinForm:FormGroup=null;
  iscalculatedList=[]

  @Output() save=new EventEmitter();
  @Output() closePanel=new EventEmitter();

  @Input() editGlMappings;
  isKeyMeaseureData:boolean=true;
  selectedGLHeadType: {key: any;value: any}[]=[];

  selectedKeyMeasureHeadType: {key: any;value: any}[]=[];
  measureExp:string=null;
  isCalculationType:boolean=true;
  label="Expression *"
  constructor(private toast:ToastService) { 

    this.glMappinForm=new FormGroup({
      glMappingId:new FormControl(null),
      glHead:new FormControl(null),
      keyMeasureHead:new FormControl(null,{validators:[Validators.required]}),
      iscalculated:new FormControl(null,{validators:[Validators.required]}),
      isKeymeasuresData:new FormControl(null,{validators:[Validators.required]}),
      calculationType:new FormControl(null,{validators:[Validators.required]}),
      calculation:new FormControl(null,{validators:[Validators.required]})
    })
  //   const defaultMap = {
  //     key: "Y",
  //     value: "Y",
  //   };
  //  this.glMappinForm.get('iscalculated').setValue(defaultMap);

  //  const defaultKeyMap = {
  //   key: "Y",
  //   value: "Y",
  //        };
  //  this.glMappinForm.get('isKeymeasuresData').setValue(defaultKeyMap);


  //  const calculatedTypeMap = {
  //     key: "EXPR",
  //     value: "EXPR",
  //          };
  //  this.glMappinForm.get('calculationType').setValue(calculatedTypeMap);


  }

  ngOnInit(): void {



  }


  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editGlMappings && changes.editGlMappings.currentValue) {
     let rowData=changes.editGlMappings.currentValue;
     //this.datePipe.transform(item.createdAt,"MM/dd/yyyy")
     console.log("rowData  ",rowData);
     let keMeasureList=rowData.keyMeasureList;
     let gLHeadList=rowData.gLHeadList;
     this.selectedKeyMeasureHeadType=[];
     this.selectedGLHeadType=[];

     this.glMappinForm.patchValue(
      {glMappingId:rowData.glMappingId,
        calculation:rowData.calculation
      });

     this.glMappinForm.get("glHead").setValue({
        key:rowData.glHeadId,
        value:rowData.glHeadName
       });
     
       this.glMappinForm.get("keyMeasureHead").setValue({
      key:rowData.keymeasuresheadId,
      value:rowData.keymeasuresheadName
     });

     this.glMappinForm.get("iscalculated").setValue({
      key:rowData.isCalculated,
      value:rowData.isCalculated
     });


     this.glMappinForm.get("isKeymeasuresData").setValue({
      key:rowData.iskeymeasuresData,
      value:rowData.iskeymeasuresData
     });


     this.glMappinForm.get("calculationType").setValue({
      key:rowData.calculationType,
      value:rowData.calculationType
     });

if(rowData.calculationType==="EXPR"){
     this.isCalculationType=true;
     if(rowData.iskeymeasuresData==='Y'){
      this.isKeyMeaseureData=true;
      if(keMeasureList!=null){

      for (var i = 0; i < keMeasureList.length; i++) {
          this.selectedKeyMeasureHeadType.push({ key: keMeasureList[i].keymeasuresheadId, value: keMeasureList[i].name });
          console.log("selectedKeyMeasureHeadType Are:", this.selectedKeyMeasureHeadType);
           }
           }

     }
     if(rowData.iskeymeasuresData==='N'){
      this.isKeyMeaseureData=false;
      if(gLHeadList!=null){
      for (var i = 0; i < gLHeadList.length; i++) {
        this.selectedGLHeadType.push({ key: gLHeadList[i].glheadId, value: gLHeadList[i].glHeadNname });
        console.log("selectedGLHeadType Are:", this.selectedGLHeadType);
         }
         }
        }

    }
 else{
  this.isCalculationType=false;
 }
  }

     
    



    // if (changes && changes.keyMeasureExp && changes.keyMeasureExp.currentValue) {
      

    // }
  
  }



  saveGlMapping(){
    this.closePanel.emit();
    console.log("this.glMappinForm  ",this.glMappinForm.value)
    if(this.glMappinForm.controls.isKeymeasuresData.value.key=='Y' && this.glMappinForm.controls.calculationType.value.key=="EXPR" && this.glMappinForm.controls.iscalculated.value.key=='N') {

      if(this.selectedKeyMeasureHeadType.length>1){
         this.toast.showWarn("Please Select Only One Key Measure Head","error");
           return;
      }


    }
    if(this.glMappinForm.controls.isKeymeasuresData.value.key=='N' && this.glMappinForm.controls.calculationType.value.key=="EXPR" && this.glMappinForm.controls.iscalculated.value.key=='N') {

      if(this.selectedGLHeadType.length>1){
        this.toast.showWarn("Please Select Only One GL Head","error");
           return;
      }


    }
    if(this.glMappinForm.valid){
     this.save.emit(this.glMappinForm.value);
    }
    else{
      this.toast.showWarn('All Fields are mandatory','error');
    }
  }

  resetForm(flag){
this.glMappinForm.reset();
this.isKeyMeaseureData=true;
this.isCalculationType=true;
this.measureExp=null;
this.selectedKeyMeasureHeadType=[];
this.selectedGLHeadType=[];
const defaultMap = {
  key: "EXPR",
  value: "EXPR",
};
 this.glMappinForm.get('calculationType').setValue(defaultMap);
 const defaultMap1 = {
  key: "Y",
  value: "Yes",
};
 this.glMappinForm.get('isKeymeasuresData').setValue(defaultMap1);
 const defaultMap2 = {
  key: "Y",
  value: "Yes",
};
 this.glMappinForm.get('iscalculated').setValue(defaultMap2);
 this.label="Expression *"

 if(flag==="onCancel"){
    this.closePanel.emit();
   }
  }



  isKeymeasuresData(){

    if(this.glMappinForm.controls.isKeymeasuresData.value.key==='N'){

      this.isKeyMeaseureData=false;
      this.measureExp=null;
      this.glMappinForm.get('calculation').setValue(null);
      this.selectedKeyMeasureHeadType=[];
      //this.label="Expression *"
      

    }
    if(this.glMappinForm.controls.isKeymeasuresData.value.key==='Y'){
      this.isKeyMeaseureData=true;
      this.measureExp=null;
      this.glMappinForm.get('calculation').setValue(null);
      this.selectedGLHeadType=[];
      //this.label="Expression *"
    }

  }

  multiGLHeadSelectChange(eventData){
    this.selectedGLHeadType = eventData.value;
    console.log("selectedGLHeadType Value Updated Is:", this.selectedGLHeadType);

    if(this.selectedGLHeadType.length>1){
   
      for (var i = 0; i < this.selectedGLHeadType.length; i++) {
      
           let add="("+this.selectedGLHeadType[i].key+")";
           if(i==0){
           this.measureExp=add;
           }
           else{
            this.measureExp=this.measureExp+"+"+add;
           }
      
      
          }
        }

      else if(this.selectedGLHeadType.length==1){
          this.measureExp=null;
      
            this.measureExp=this.selectedGLHeadType[0].key;
          
          }
        else{
          this.measureExp=null;
  
        }
  
          console.log("this.selectedGLHeadType  ", this.measureExp);
  
          this.glMappinForm.get('calculation').setValue(this.measureExp);
          this.glMappinForm.get('glHead').setValue(this.selectedGLHeadType[0]);

  }


  multiKeyMeasureHeadSelectChange(eventData){
    this.selectedKeyMeasureHeadType = eventData.value;
    console.log("selectedKeyMeasureHeadType Value Updated Is:", this.selectedKeyMeasureHeadType);
    if(this.selectedKeyMeasureHeadType.length>1){
   
    for (var i = 0; i < this.selectedKeyMeasureHeadType.length; i++) {
    
         let add="("+this.selectedKeyMeasureHeadType[i].key+")";
         if(i==0){
         this.measureExp=add;
         }
         else{
          this.measureExp=this.measureExp+"+"+add;
         }
    
    
        }
      }

     else if(this.selectedKeyMeasureHeadType.length==1){
        this.measureExp=null;
    
          this.measureExp=this.selectedKeyMeasureHeadType[0].key;
        
        
     
     
         }
      else{
        this.measureExp=null;

      }

        console.log("this.keyMeasureExp  ", this.measureExp);

        this.glMappinForm.get('calculation').setValue(this.measureExp);
        //this.glMappinForm.get('glHead').setValue(this.selectedKeyMeasureHeadType[0].key);

  }


  calculationType(){
    if(this.glMappinForm.controls.calculationType.value.key==='EXPR'){

    this.isCalculationType=true;
      this.measureExp=null;
      this.selectedKeyMeasureHeadType=[];
      this.selectedGLHeadType=[];
      this.glMappinForm.get('calculation').setValue(null);
      this.label="Expression *"

    }
    if(this.glMappinForm.controls.calculationType.value.key==='SP'){

      this.isCalculationType=false;
      this.measureExp=null;
      this.selectedKeyMeasureHeadType=[];
      this.selectedGLHeadType=[];
      this.glMappinForm.get('calculation').setValue(null);
      this.label="SP Name *"
    }

  }

  
  

   

}
