import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stressKeyMetrics-table',
  templateUrl: './stressKeyMetrics-table.component.html',
  styleUrls: ['./stressKeyMetrics-table.component.scss']
})
export class StressKeyMetricsTableComponent implements OnInit {
 @Input() stressKeyMetricsList:any[];
 @Input() rolePermission:any;
 @Output() edit = new EventEmitter();
 @Output() setValue = new EventEmitter();
 @Output() delete = new EventEmitter();
 dataValue:any;
 visibleDelete: boolean = false;

 cols: { field: string; header: string }[];
  constructor() {
    this.cols = [
      
    ];
   }

  ngOnInit(): void {
  }

  editStressKeyMetrics(rowData){
  this.edit.emit(rowData);
  }

  showPopupDelete(rowData) {
        this.dataValue = rowData;
        this.visibleDelete = true;
      }
    
      hidePopup() {
        debugger;
        this.dataValue = null;
        this.visibleDelete = false;
      }
    
      actionDo(str){
        this.deleteStressKeyMetricsId(this.dataValue);
        this.dataValue=null;
        this.visibleDelete=false;
      }
    
      deleteStressKeyMetricsId(rowData){
        this.delete.emit(rowData);
        console.log("deleteStressKeyMetricsId", rowData);
      }
}
