import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { StressScenarioSelectorComponent } from '../stressScenario-selector/stressScenario-selector.component';

@Component({
  selector: 'app-stressScenario-header',
  templateUrl: './stressScenario-header.component.html',
  styleUrls: ['./stressScenario-header.component.scss']
})
export class StressScenarioHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(StressScenarioSelectorComponent, { read: false, static: false })
  StressScenarioSelectorComponent: StressScenarioSelectorComponent;

  
  @Input() editData;
  @Output() save=new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }


  saveStressScenario(data){
    console.log("data",data);
this.save.emit(data);

  }

 

  addNewStressScenario(){

     this.StressScenarioSelectorComponent.formReset();
     this.panelForm.toggleSlider();

    }

    reset(){

     this.StressScenarioSelectorComponent.formReset();
     //this.panelForm.toggleSlider();
    }

 openEdit(){
  this.panelForm.toggleSlider();
 }

}
