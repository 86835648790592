import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
//import { EventEmitter } from 'protractor';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { DataTransferFormComponent } from '../data-transfer-form/data-transfer-form.component';

@Component({
  selector: 'app-data-transfer-header',
  templateUrl: './data-transfer-header.component.html',
  styleUrls: ['./data-transfer-header.component.scss']
})
export class DataTransferHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(DataTransferFormComponent, {static: false })
  dataTransferFormComponent:DataTransferFormComponent;

@Output() detailByService=new EventEmitter();
@Input() dbConnectionDataList;
@Input() databaseNameList;
@Input() destinationDatabaseNameList;
@Output() getTableNames=new EventEmitter();
@Input()  tableNameList
@Input()  destinationTableNameList
@Output() transferToData=new EventEmitter();
@Output() callDestinationTableColumnsData=new EventEmitter();
@Output() dataDumpInNewTable=new EventEmitter();
@Output() callTableColumnsData=new EventEmitter();
@Input() columnsList:any;
@Input() destColumns:any;
@Input()  editData;
  constructor(private fileService:FileService,private toast:ToastService) { 
  }

  ngOnInit(): void {
  }

  getdetailByService(data){
this.detailByService.emit(data)
  }

  getTableNameList(data){

    this.getTableNames.emit(data);
  }



  openDataTranferForm(){
    this.panelForm.toggleSlider()
    this.dataTransferFormComponent.resetTransferForm();  
  }



  transferToSaveData(data){
this.transferToData.emit(data);
  }

  dataDumpInNewTb(data){
    console.log("data  ",data)

    this.dataDumpInNewTable.emit(data);
  }
  callDestinationTableColumns(data) {
    this.callDestinationTableColumnsData.emit(data);
  }

  callTableColumns(data){

    this.callTableColumnsData.emit(data);
  }

  toggled(){
    console.log("Toggled call");
    this.panelForm.toggleSlider();
  }

 }
