import { Component, EventEmitter, Input, OnInit, Output ,ViewChild,ElementRef,SimpleChanges} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-video-upload-selector',
  templateUrl: './video-upload-selector.component.html',
  styleUrls: ['./video-upload-selector.component.scss']
})
export class VideoUploadSelectorComponent implements OnInit {

  @Output() fileData=new EventEmitter;
  @Output() closePanel=new EventEmitter;

  @Input() allStatusData;
  currentFileUpload:FileList=null;
  fileList:any=[];
  currentFileUploadList:FileList;
  modelForm:FormGroup=null;

  @ViewChild('myInput') myInput: ElementRef;

  @Input() categoryVideoList;
  @Input() appListData;
  @Input() editVideoData;
  constructor(private toast:ToastService) { 

    this.modelForm = new FormGroup({
      videoId:new FormControl(null),
      statusMap: new FormControl(null),
      title:new FormControl(null,[Validators.minLength(5),Validators.maxLength(250)]),
      category:new FormControl(null),
      description:new FormControl(null,[Validators.minLength(5),Validators.maxLength(500)]),
      application:new FormControl(null)
    });
  }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.modelForm.get('statusMap').setValue(defaultMap);

   //this.myInput.nativeElement.value="demo12_with - Copy.mp4"
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editVideoData && changes.editVideoData.currentValue) {
     console.log(changes.editVideoData.currentValue,' indise changes') 
       const edit=changes.editVideoData.currentValue;
      
       if(edit.status){
      this.modelForm.get('statusMap').setValue({
         key: "Active",
        value: "Active"
        });
       }
       if(!edit.status){

        this.modelForm.get('statusMap').setValue({
          key: "Inactive",
         value: "Inactive"
         });

       }

       if(edit.videoCategoryId!=null){
        this.modelForm.get('category').setValue({
          key: edit.videoCategoryId,
         value: edit.videoCategoryId
         });
       }
       console.log("applications................",edit);
    

       console.log("............modelform before application",this.modelForm);
       if(edit.applicationId !=null){
        this.modelForm.get('application').setValue({
          key: edit.applicationId,
         value: edit.applicationId

         });
       }
       
       console.log("............modelform after application",this.modelForm);

       this.modelForm.patchValue({
        videoId:edit.videoId,
        title:edit.title,
        description:edit.description
      //  appListData:edit.appListData

       })
      
      }
      
      }




  selectFile(event) {
    // this.currentFileUpload=null;
    this.currentFileUploadList = event.target.files;
    console.log(" currentFileUploadList :",this.currentFileUploadList);

    if( this.currentFileUploadList[0].name.includes(".txt") || 
        this.currentFileUploadList[0].name.includes(".pdf") || 
        this.currentFileUploadList[0].name.includes(".xlsx")||
        this.currentFileUploadList[0].name.includes(".mp3") ){
          this.currentFileUploadList=null;
          this.myInput.nativeElement.value = null;
          this.toast.showInfo("Only Mp4 Format Can Be Upload", "error");
          this.closePanel.emit();
          return;
    }
    
      //console.log("this.fileList: ",this.fileList);
      let compare=[];
      if(this.fileList!=null){
      compare =  this.fileList.filter(x=>x.name.toUpperCase() === this.currentFileUploadList[0].name.toUpperCase());
      }
      //console.log("compare: ",compare);
      if(compare.length===0){
        for(let i=0;i<this.currentFileUploadList.length;i++){
          this.fileList.push(this.currentFileUploadList[i]);
          }
         this.currentFileUpload=this.fileList;
          }
      else{
        this.closePanel.emit();
        this.toast.showError("'"+this.currentFileUploadList[0].name+"'Name of File Is Already Added", "error");
        this.myInput.nativeElement.value = null;
        //this.currentFileUploadList=null;
      }
    console.log(" file 1 :",this.currentFileUpload);

  }


  cancelForm(){
    this.modelForm.reset();
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
    this.modelForm.get('statusMap').setValue(defaultMap);
    this.currentFileUpload=null;
    this.currentFileUploadList=null;
    this.fileList=[];
    this.myInput.nativeElement.value = null;
    this.closePanel.emit();
  }

  resetForm(){
    this.modelForm.reset();
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
   this.modelForm.get('statusMap').setValue(defaultMap);
    this.currentFileUpload=null;
    this.currentFileUploadList=null;
    this.fileList=[];
    this.myInput.nativeElement.value = null;
  }


  saveFile(){
    let statusMap=this.modelForm.controls.statusMap.value
    console.log("statusMap",statusMap)
    if (statusMap=="Select Status *") {
      this.toast.showError("Please Fill Required Fields","error");
      this.closePanel.emit();
    }

    if(this.modelForm.invalid){
      if(this.modelForm.controls.title.invalid){
        this.toast.showWarn('Title is mandatory','error');
        this.closePanel.emit();
        return;
      }
      if(this.modelForm.controls.description.invalid){
        this.toast.showWarn('Description is mandatory','error');
        this.closePanel.emit();
        return;
      }
    }
    if(statusMap!=="Select Status *"){
  const data={
    "file":this.currentFileUpload,
    "form":this.modelForm.value
}
  this.fileData.emit(data);
  this.closePanel.emit();
}
  }


removeFile(index){
  console.log(index);
  
  this.fileList=[];
  if(index===0 && this.currentFileUpload.length===1){
    event=null;
    return this.currentFileUpload=null;
  }
  for(let i=0;i<this.currentFileUpload.length;i++){
    this.fileList.push(this.currentFileUpload[i]);
    }
  console.log("before list "+this.fileList)
 // this.fileList = this.fileList.filter(obj => obj !== this.fileList[index]);
 this.fileList.splice(index,1);
 console.log("after list "+this.fileList)
 this.currentFileUpload=this.fileList;

 console.log("this.currentFileUpload ",this.currentFileUpload);

}



}
