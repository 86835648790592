import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { database } from 'firebase';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { ClientService } from 'src/app/dashboard/services/client.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { TableViewerService } from 'src/app/dashboard/services/table-viewer.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-table-viewer-parameter',
  templateUrl: './table-viewer-parameter.component.html',
  styleUrls: ['./table-viewer-parameter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableViewerParameterComponent {
  @Output() closePanel=new EventEmitter();
  @Output() fetch=new EventEmitter();
  parameterForm:FormGroup;
  tableKey:any=null;
  databaseKey:any=null;
  url:string=null;
  env:any;
  viewList:any=[];
  databaseNameList:{key: any; value: any }[]=[];
  tableViewersList:any;
  dataValues:any;
  tableData:any;
  paginationCount:any;
  selectedColumns:any;
  columnsList:any;
  activeRoute$:Subscription;
  loadButton:boolean=false;

  constructor(private tableViewerService:TableViewerService,private loader:LoaderService,
    private sspapplicationService:SspApplicationService,private toast:ToastService,private activeRoute:ActivatedRoute) {
    this.parameterForm = new FormGroup({
      databaseName:new FormControl(null, [Validators.required]),
      tableName:new FormControl(null, [Validators.required]),
      pageNumber:new FormControl(null),
      pageSize:new FormControl(null),
      redirect:new FormControl(null)
     });

  }

  ngOnInit(): void {
    // const defaultMap={
    //   key:"nova_datamart_dev",
    //   value:"nova_datamart_dev"
    // }
    // this.databaseNameList.push(defaultMap);
    // console.log(this.databaseNameList)
    this.getDatabaseData();
    
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {

        if(par.has("createStatement")){
          if(par.get("createStatement")!=null){
                 if (par.has("tableName")) {
                  
                console.log("table Name ",par.get("tableName"))
                this.parameterForm.get("tableName").setValue({
                key:par.get("tableName"),
                value:par.get("tableName")
                    }
                    )
                    // this.parameterForm.get("databaseName").setValue({
                    // key:environment.dataLakeDb,
                    // value:"Nova Datalake"
                    //  }
                     
                    //   )
                      this.parameterForm.get("redirect").setValue(par.get("a"));
                      //     this.selectedColumns=[];
                //     this.columnsList=[];
                //  this.getData();
                this.tableKey=this.parameterForm.controls.tableName.value.key;
                //this.databaseKey=this.parameterForm.controls.databaseName.value.key;
                
                this.getColumns("Generic");
                 }
      }
      if(par.get("createStatement")==null){
        if (par.has("existingTable")) {
          
          console.log("table Name ",par.get("existingTable"))
          this.parameterForm.get("tableName").setValue({
          key:par.get("existingTable"),
          value:par.get("existingTable")
              }
              )
              // this.parameterForm.get("databaseName").setValue({
              // key:environment.dataLakeDb,
              // value:"Nova Datalake"
              //  }
              //   )
              this.parameterForm.get("redirect").setValue(par.get("a"));
              // this.selectedColumns=[];
              // this.columnsList=[];
              this.getColumns("Generic");
           }

      }
     
       }
})
  }


  getTables(data){
       this.tableViewerService.getTables(this.parameterForm.controls.databaseName.value)
       .subscribe(
         data=>{
        this.tableViewersList=data;
         this.tableViewersList=this.tableViewersList.map((t) => ({ key: t, value: t }));
         console.log("tableViewersList",this.tableViewersList)
      },
         error=>{
        //   this.toast.showError('error','error resuming workflow execution');
         }
       )
  }
  getData(tableKey,databaseKey){
    if(tableKey===null && databaseKey ===null){
      this.tableKey=this.parameterForm.controls.tableName.value.key;
    if(this.parameterForm.controls.databaseName.value.key!=null){
      this.databaseKey=this.parameterForm.controls.databaseName.value.key;
    }
    }
    console.log("tableKey"+tableKey);
    console.log("databaseKey"+databaseKey);
    let data:any=null;
    this.loader.showLoader();
    if(this.selectedColumns.length==0){
       data={
        "tableName":this.tableKey,
        "databaseName":this.databaseKey,
        "pageNumber":0,
        "pageSize":10,
        "columnsListing":this.columnsList, 
        "redirect":this.parameterForm.controls.redirect.value
      }
    }else{
     data={
      "tableName":this.tableKey,
      "databaseName":this.databaseKey,
      "pageNumber":0,
      "pageSize":10,
      "columnsListing":this.selectedColumns 
    }
  }
    this.tableViewerService.getData(data).subscribe(
      data =>{
        this.dataValues=data["tableColumn"];
        this.tableData=data["tableData"];
        this.paginationCount=data["countSet"];
        console.log(this.dataValues)
        if(this.dataValues.length===0){
          this.toast.showInfo("info","No Data Found");
        }
        const dataValues={
            "form":this.parameterForm.controls,
            "tableColumns":this.dataValues,
            "tableData":this.tableData,
            "paginationCount":this.paginationCount
        }
        this.fetch.emit(dataValues);
        this.toast.showSuccess("success","Data Fetched Successfully");
      },
      error=>{
        this.toast.showError("error","No Data Present");
      }

    )
    this.loader.hideLoader();
   if(tableKey===null){
    this.closePanel.emit();
   }
  }

getDatabaseData() {
  this.sspapplicationService.getTableDropDownbyId()
    .subscribe(
      (data) => {
        this.databaseNameList = reMapForAutoComplete(data, 'itemValue', 'itemText');
        console.log('generic data' + this.databaseNameList);
      },
      (err) => {
        this.toast.showError(err.error.message, 'error');
      }
    );
}

multiColumnSelectChange(eventData){
  this.selectedColumns=[];
  this.selectedColumns = eventData.value;
 console.log("Datatype Value Updated Is:", this.selectedColumns);

}

getColumns(dataVal){
  this.columnsList=[];
  if(this)
  console.log("form123"+this.parameterForm.controls +"and"+this.tableKey+this.databaseKey);
  const data={
    "tableName":this.parameterForm.controls.tableName.value.key,
    "databaseName":this.parameterForm.controls.databaseName.value!==null? this.parameterForm.controls.databaseName.value.key:null,
  }
  this.tableViewerService.getColumns(data).subscribe(
    data =>{
      this.columnsList=data["columnListing"];
      if(this.columnsList.length>=0){
        this.loadButton=true;
      }
      console.log(this.columnsList);
      this.selectedColumns=[];
      if(dataVal==='Generic'){
        console.log("form"+this.parameterForm.controls +"and"+this.tableKey+this.databaseKey);
        this.getData(this.tableKey,this.databaseKey);
      }
    },
    error=>{

    }

  )
}

}

