import { DatePipe } from "@angular/common";
import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";
import { ToastService } from "src/app/core/services/toast.service";
import { FileService } from "src/app/files/services/file.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { ReportGroupParameterComponent } from "src/app/reports/containers/report-group-parameter/report-group-parameter.component";
import { SidePanelComponent } from "src/app/ui/components/side-panel/side-panel.component";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { environment } from "src/environments/environment";
//import { GroupReportParameterComponent } from '../../components/group-report-parameter/group-report-parameter.component';
import { ReportGroupModel } from "../../models/report-group.model";
import { SspApplicationModel } from "../../models/selfServicePortal.model";
import { ClientService } from "../../services/client.service";
import { ReportDashboardService } from "../../services/report-dashboard.service";
import { SspApplicationService } from "../../services/ssp-application.service";
//import { ReportGroupParameterComponent } from 'src/app/reports/containers/report-group-parameter/report-group-parameter.component';

@Component({
  selector: "app-main-report-dashboard",
  templateUrl: "./main-report-dashboard.component.html",
  styleUrls: ["./main-report-dashboard.component.scss"],
})
export class MainReportDashboardComponent implements OnInit {
  // @ViewChild(GroupReportParameterComponent, { read: false, static: true })
  // groupReportParameterComponent:GroupReportParameterComponent;

  @ViewChild("panelForm", { static: false })
  panelForm!: SidePanelComponent;

  // @Output() passFormData=new EventEmitter();

  searchByCols = [
    "Group Name",
    "Report Name",
    "Sub-Report Name",
    "Report View",
  ];
  exportMode = false;
  tableHeaders = [
    "Group Name",
    "Report Name",
    "Sub-Report Name",
    "Report View",
    "",
  ];
  data = [];
  headerForm: FormGroup;
  applicationData: { key: any; value: any }[] = [];
  allGroupData: { key: any; value: any }[] = [];
  clientsListData: { key: any; value: any }[] = [];
  env: string = null;
  report: string = null;
  group: string = null;
  view: string = null;
  activeRoute$: Subscription;
  isApply: boolean = false;
  zohoData: any[];
  viewList: any = [];
  url: any;
  zohoCriteria: string = "";
  reportHeadingName: String;
  state: string;
  asofdate:string=null;
  companyId: number=0;
  companyName:string=null
  constructor(
    private sspapplicationService: SspApplicationService,
    private loader: LoaderService,
    private toast: ToastService,
    private datePipe: DatePipe,
    private reportDashboardService: ReportDashboardService,
    private activeRoute: ActivatedRoute,
    private sspApplicationService: SspApplicationService,
    private cookieService: CookieService,
    private clientService: ClientService,
    private fileService: FileService
  ) {
    this.headerForm = new FormGroup({
      GroupData: new FormControl(null),
      ReportData: new FormControl(null),
      client: new FormControl(null),
      viewData: new FormControl(null),
      url: new FormControl(null),
    });
    this.state = localStorage.getItem("stateflag");
  }

  ngOnInit(): void {
    //this.getApplicationData();
    // this.getAllGroupData();
    this.getClients();
    this.companyId=Number(localStorage.getItem("companyId"));
    //this.getLastClientOfUser();
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe((par) => {
      // if (par.has("clientId") && par.has("clientName")) {
      //   this.headerForm.get("client").setValue({
      //     key: par.get("clientId"),
      //     value: par.get("clientName")
      //   });
      //  this.getAllWorkflow();
      //  }
      if (par.has("groupId") && par.has("groupName")) {
        this.headerForm.get("GroupData").setValue({
          key: par.get("groupId"),
          value: par.get("groupName"),
        });
        //  this.getAllWorkflow();
      }
      if (par.has("isApply") && par.has("zohoData")) {
        this.isApply = JSON.parse(par.get("isApply"));
        this.zohoData = JSON.parse(par.get("zohoData"));
        console.log("isApply and zohoData: " + this.isApply, this.zohoData);
      }
      if (par.has("reportHeading")) {
        this.reportHeadingName = par.get("reportHeading");
        console.log("reportHeading : " + par.get("reportHeading"));
      }
      if (par.has("appId") && par.has("appName")) {
        this.headerForm.get("ReportData").setValue({
          key: par.get("appId"),
          value: par.get("appName"),
        });
        //   this.updateListingDataNavigate(this.headerForm);
      }
      if (par.has("instanceId") && par.has("instanceName")) {
        this.headerForm.get("viewData").setValue({
          key: par.get("instanceId"),
          value: par.get("instanceName"),
        });
        this.headerForm.get("url").setValue(par.get("instanceUrl"));
        this.updateListingDataNavigate(this.headerForm);
      } else {
        this.reportDashboardService.getGroupReportData(this.state,this.companyId).subscribe(
          (res) => {
            const key = this.cookieService.get("groupName");
            const name = this.cookieService.get("reportName");
            const report = res[key].filter((item) => item.appName === name)[0];
            console.log("Group data: " + JSON.stringify(report));
            this.headerForm.get("GroupData").setValue({
              key: report.groupId,
              value: report.groupName,
            });
            this.headerForm.get("ReportData").setValue({
              key: report.appId,
              value: report.appName,
            });
            this.headerForm.get("viewData").setValue({
              key: report.instanceId,
              value: report.instanceName,
            });
            this.headerForm.get("url").setValue(report.instanceUrl);
            this.updateListingDataNavigate(this.headerForm);
          },
          (err) => {
            this.toast.showError("Unable to View report", "error");
          }
        );
      }


      if(par.has("asOfDate")){
        this.asofdate=par.get("asOfDate");
      }
      // else{
      //   this.asofdate=localStorage.getItem("asofdate");
      // }
      console.log("asOfdate-----------",this.asofdate);
      //console.log("client-----------",this.companyId);
    });
  }

  getApplicationData() {
    // const appData={
    //   "appId":0
    // }
    this.loader.showLoader();
    this.sspapplicationService.getAppDataForReportPage().subscribe(
      (data) => {
        this.loader.hideLoader();
        // this.applicationData = data;
        this.applicationData = reMapForAutoComplete(data, "appId", "appName");
      },
      (err) => {
        this.loader.hideLoader();
        this.toast.showError(err.error.message, "error");
      }
    );
  }

  updateExportSet(value: { data: any; status: boolean }): void {
    this.exportMode = value.status;
  }

  updateListingData(data) {
    console.log("url data method" + data);
    this.reportHeadingName = null;
    this.group = data.groupData.value;
    this.report = data.reportData.value;
    this.view = data.viewData.value;
    const tempURL = environment.reportUrl + data.viewData.key;
    this.env =
      this.zohoCriteria.length > 0 ? tempURL + this.zohoCriteria : tempURL;
    console.log("report head Name::", data);
    this.reportHeadingName = data.instanceHeadName;
    console.log("url in update listing data: " + this.env);
  }

  updateListingDataNavigate(headerForm) {
    console.log("url data values" + JSON.stringify(headerForm.value));
    this.group = headerForm.controls.GroupData.value.value;
    this.report = headerForm.controls.ReportData.value.value;
    this.view = headerForm.controls.viewData.value.value;
    this.getreport(headerForm);
    //this.env=environment.zohoUrl+this.env;
    //  //this.reportGroupParameterComponent.parameterForm
    // this.groupReportParameterComponent.parameterForm.get("client").setValue({
    //   key: headerForm.controls.client.value.key,
    //   value: headerForm.controls.client.value.value
    // });

    // this.groupReportParameterComponent.parameterForm.get("groupData").setValue({
    //   key: headerForm.controls.GroupData.value.key,
    //   value: headerForm.controls.GroupData.value.value
    // });
    // this.groupReportParameterComponent.parameterForm.get("reportData").setValue({
    //   key: headerForm.controls.ReportData.value.key,
    //   value: headerForm.controls.ReportData.value.value
    // });
    // this.groupReportParameterComponent.parameterForm.get("viewData").setValue({
    //   key: headerForm.controls.viewData.value.key,
    //   value: headerForm.controls.viewData.value.value
    // });
    // console.log(this.groupReportParameterComponent.parameterForm.value);
    //   this.passFormData=headerForm.value;
  }

  getClients() {
    //  this.loader.showLoader();
    this.clientService.getAllClients().subscribe(
      (data) => {
        //   this.loader.hideLoader();
        this.clientsListData = reMapForAutoComplete(
          data,
          "clientId",
          "clientName"
        );
      },
      (err) => {
        //    this.loader.hideLoader();
        //  this.toast.showError(err.error.message, "error");
      }
    );
  }

  getLastClientOfUser(){
    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
        for(var i=0;i<data.length;i++){
          console.log("data  ",data[i].clientId)
          this.companyId=data[i].clientId
          this.companyName=data[i].clientName
        }
 },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
      );
  }

  getreport(headerForm) {
    this.getZohoCriteria(headerForm);

    // if (this.zohoCriteria.length > 0) {
    //   this.env=this.env+"ZOHO_CRITERIA="+this.zohoCriteria;
    //   console.log("URL with ZOHO_CRITERIA: "+this.env);
    // }

    // if(this.clientId==0 || this.clientId!=undefined || this.clientId!=null){
    //   this.env= data.key+"?INCLUDETITLE=false&ZOHO_CRITERIA=%22company_id%22%3D"+this.clientId;
    // }

    // if (!this.env.includes('https://zoho-server.uventure.in/open-view/')) {
    //   this.env=null;

    // }
    // this.parameterForm.controls.url.setValue(this.env);
    // console.log('url last value',this.env);
  }

  getZohoCriteria(headerForm) {
    console.log("is Apply: " + this.isApply);
    console.log("zohoCriteria", headerForm);
    if (this.isApply) {
      this.url = headerForm.controls.url.value;
      //console.log("client id is",this.parameterForm.controls.client.value.key)
      if (this.zohoData.length > 0) {
        const formatedData = this.zohoData.map((item) =>
          item.columnName.concat("=", "'", item.value12, "'")
        );
        this.zohoCriteria = formatedData.join(" AND ");
        // Need to replace & with %26, if present
        this.zohoCriteria = this.zohoCriteria.replace("&", "%26");
        if (this.zohoCriteria.includes("?") || this.url.includes("?")) {
          this.zohoCriteria = "&ZOHO_CRITERIA=" + this.zohoCriteria;
        } else {
          this.zohoCriteria = "?ZOHO_CRITERIA=" + this.zohoCriteria;
        }
        console.log("ZOHO Criteria: " + this.zohoCriteria);
       
        
        if(this.state=="7"){
          this.env = environment.reportUrl + this.url + this.zohoCriteria+" and "+"%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
        }
        else{
          this.env = environment.reportUrl + this.url + this.zohoCriteria;
          }
      } 
      // else {
      //   //this.env = environment.reportUrl + this.url;
      //   if(this.asofdate!=null){
      //     this.env = environment.reportUrl + this.url+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
      //     }
      //     else{
      //       this.env = environment.reportUrl + this.url+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId;  
      //     }
      // }
      console.log("env_URL from getZohoCriteria: " + this.env);
    } else {
      console.log("app id: " + headerForm.controls.ReportData.value.key);
      this.sspApplicationService
        .getZOHOCriteria(headerForm.controls.ReportData.value.key)
        .subscribe(
          (data) => {
            this.url = headerForm.controls.url.value;
            //console.log("client id is",this.parameterForm.controls.client.value.key)
            if (data.length > 0) {
              const formatedData = data.map((item) =>
                item.columnName.concat("=", "'", item.valueq, "'")
              );
              this.zohoCriteria = formatedData.join(" AND ");
              // Need to replace & with %26, if present
              this.zohoCriteria = this.zohoCriteria.replace("&", "%26");
              if (this.zohoCriteria.includes("?") || this.url.includes("?")) {
                this.zohoCriteria = "&ZOHO_CRITERIA=" + this.zohoCriteria;
              } else {
                this.zohoCriteria = "?ZOHO_CRITERIA=" + this.zohoCriteria;
              }
              console.log("ZOHO Criteria: " + this.zohoCriteria); 
              
              if(this.state=="7"){  
              this.env = environment.reportUrl + this.url + this.zohoCriteria+" AND "+"%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
              }else{
                this.env = environment.reportUrl + this.url + this.zohoCriteria;
              } 
    
            } else {
              
              if(this.state=="7"){
              if (this.zohoCriteria.includes("?") || this.url.includes("?")) {
                this.env = environment.reportUrl + this.url+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
              }
              else{
                this.env = environment.reportUrl + this.url+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
              }
            }else{
              this.env = environment.reportUrl + this.url;
              }
              
            }
            console.log("this.url",this.url);
            console.log("this.companyId",this.companyId);
            console.log("this.asofdate",this.asofdate);
            console.log("env_URL from getZohoCriteria: " + this.env);
          },
          (err) => {
            console.log(err);
          }
        );
      // const data = await this.sspApplicationService.getZOHOCriteria(app_id).toPromise();
    }
    console.log("this.url123 ",this.url);
  }
}
