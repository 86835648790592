import { Component, OnInit, Input } from "@angular/core";
import { environment } from "src/environments/environment";
import { Store } from '@ngrx/store';
import { MenuService } from "../../services/menu.service";
import { take } from "rxjs/operators";

@Component({
  selector: "app-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"]
})
export class LogoComponent implements OnInit {
  items: any;
  logo: string;
  user: any;
  constructor(private menuService: MenuService, private store: Store<{ user: any }>) {
    //this.logo = environment.logo;
  }

  ngOnInit(): void {
  this.getCompanyLogo();
  }

  getCompanyLogo(){
    this.store.select('user').pipe(take(1))
    .subscribe(u => this.user = u)
  
 //   if(this.user.clientId===0 || this.user.clientId===null){
    this.menuService.getCompanyLogo().subscribe(
      (data) => {
       this.items=data;
       this.logo=this.items.companyLogo;
       console.log("logo "+this.logo);
      },
      (err) => {
        
      }
    
    );
    
  }
  
}
