import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TitleService } from "src/app/core/services/title.service";
import { ActivatedRoute } from '@angular/router';
import { Subscription } from "rxjs";
import { UserService } from 'src/app/user/services/user.service';
import { currentMonthRange } from 'src/app/utils/dates';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import * as moment from 'moment';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { CookieService } from 'ngx-cookie-service';
import {isEmpty,trim} from 'lodash';
import { Store } from '@ngrx/store';
import { FileService } from 'src/app/files/services/file.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { ClientService } from '../../services/client.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { formatDate } from '@fullcalendar/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {
  @ViewChild("panelQueueFilter", {static: false })
  panelQueueFilter!:SidePanelComponent;
  
  activeRoute$: Subscription;
  changeLogs:any[]=[];
  showDiff:boolean=false
  previousList:any;
  newList:any;
  changeModel1:any;
  changeName:any;
  headerForm: FormGroup = null;
  dataTransferModel: DataTransferModel;
  fromDt:any;
  @Input() tabbedBoolean:boolean=false;
  toDt:any;
  companyName:string;
  fromDateValue:String;
  toDateValue:String;
  rolePermission:any
  clientsList : {key: any; value: any }[]=[];
  companyId:any;
  adminFlag:String;
  today: number=Date.now();
  searchQuery!:any;
  searchCol:String;
  state:string;
  searchByCols: { field: string , header: string; }[];
  constructor(
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private userService: UserService,
    private toast: ToastService,
    public observalbleDataService: ObservalbleDataService,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>,
    private fileService: FileService,
    private clientService:ClientService, private datePipe:DatePipe) { 
    
    this.headerForm = new FormGroup({    
      fromDate: new FormControl(null),
      toDate: new FormControl(null),
      client:new FormControl(null,[Validators.required])  
    });

     this.searchByCols= [
       { field: "changeTypeName", header: "Page Name" },
     

    
     ];

    this.state=localStorage.getItem("stateflag");
  }

  ngOnInit(): void {
  
if(!this.tabbedBoolean){
  this.fileService.getClientByUserId().subscribe(
    (data) =>{
    
      for(var i=0;i<data.length;i++){
        console.log("data  ",data[i].clientId)
        this.companyId=data[i].clientId
        this.companyName=data[i].clientName
      }
      this.headerForm.get("client").setValue({
        key: this.companyId,
        value: this.companyName
      });
    //   console.log("from Date----------",this.datePipe.transform(formatDate(new Date()),"yyyy-MM-dd"))
    //  this.headerForm.controls.fromDate.setValue(this.datePipe.transform(formatDate(new Date()),"yyyy-MM-dd"));
    //  this.headerForm.controls.toDate.setValue(this.datePipe.transform(formatDate(new Date()),"yyyy-MM-dd"));
    let currentDateRange=currentMonthRange();
    //console.log(currentDateRange,'  current date range');
    this.headerForm.controls.fromDate.setValue(this.datePipe.transform(currentDateRange[0],"yyyy-MM-dd"))
    this.headerForm.controls.toDate.setValue(this.datePipe.transform(currentDateRange[1],"yyyy-MM-dd"))
    this.getAllChangeLogs(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value)
     //this.onLoad("onInit");
     this.activeRoute.queryParamMap.subscribe(

      (params) => (this.searchQuery = params.has('q') ? params.get('q') : '')
        );
       this.activeRoute.queryParamMap.subscribe(
       (params) => (this.searchCol = params.has('filter') ? params.get('filter') : '')
        );

    },
    (err)=>{
      this.toast.showError(err.error.message, "error");
    }
    );
  }

  if(this.tabbedBoolean){
    var date1 = new Date();
    date1.setDate(date1.getDate() + 1);
    console.log("date1 ",date1)

    var date2= new Date();
    date2.setDate(date2.getDate()-365);
    console.log("date2 ",date2)
 
    // this.headerForm.controls.fromDate.setValue(formatDate(date2))
    // this.headerForm.controls.toDate.setValue(formatDate(date1))
    this.headerForm.controls.fromDate.setValue(this.datePipe.transform(formatDate(date2),"yyyy-MM-dd"));
    this.headerForm.controls.toDate.setValue(this.datePipe.transform(formatDate(date1),"yyyy-MM-dd"));
    
 //   console.log("this.formDetails.client.key  ",this.formDetails.client)
  //  console.log("this.formDetails.file.key ",this.formDetails.file.key)
  this.companyId=this.cookieService.get('tabbedClientId');
  this.companyName=this.cookieService.get('tabbedClientValue');

  this.headerForm.get("client").setValue({
    key: this.companyId,
    value: this.companyName
        });

    // this.headerForm.get("client").setValue({
    //   key: this.cookieService.get('tabbedClientId'),
    //   value: this.cookieService.get('tabbedClientValue')
    // });
    // this.headerForm.get("client").setValue({
    //   key: this.formDetails.client.key,
    //   value: this.formDetails.client.value
    // });
    // this.getChangeLogsByClientId(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value,this.headerForm.controls.client.value.key);
  // this.clientFiles=this.clientFiles.filter(x=>x.clientApplicationFileId=this.formDetails.file.key);

  }

  this.buttonShowHidePermission();
  //this.filterByColumn(this.searchColValue)
  //console.log(this.dataTransferModel,' datat transfer model')
  
  // this.dateRange$ = this.headerForm
  //   .get("dateRange")
  //   .valueChanges.subscribe((data) => {
  //     if (data[0] !== null && data[1] !== null) {
  //      // this.getFilesData(this.headerForm.get("client").value, data);
  //     }
  //   });
 // this.storePermission.select(x => this.rolePermission = x).subscribe();

    this.adminFlag=this.cookieService.get("adminFlag");

  }
  

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  getAllChangeLogs(fromDate,toDate){
    this.changeLogs=[];
    let toDatePlus1 = moment(toDate).add(1, 'days');
    const changeLogData={
      "fromDate":fromDate,
      "toDate":toDatePlus1
    }
    
    this.userService.getAllChangeLog(changeLogData)
    .subscribe(
      data=>{
        this.changeLogs=data;
        this.changeLogs.forEach(item=>{
          var dateUTC = new Date(item.createdTime);
          var dateUTC1 = dateUTC.getTime() 
          var dateIST = new Date(dateUTC1);
          //date shifting for IST timezone (+5 hours and 30 minutes)
          dateIST.setHours(dateIST.getHours() + 5); 
          dateIST.setMinutes(dateIST.getMinutes() + 30);

          item.createdTime=dateIST})
        if(this.changeLogs.length===0){
          this.toast.showInfo("info","No data present");
          
        }
      },
      error=>{
        this.toast.showError(error.error.message,'error');
      }
    )
  }

  showPopUp(rowData){
    console.log(rowData);
    this.previousList=null;
    this.newList=null;
     this.showDiff=true;
     this.previousList=JSON.stringify(rowData.previousValue);
     this.newList=JSON.stringify(rowData.newValue);
     this.changeModel1=rowData.changeTypeName+' ' +rowData.changeName;
     //console.log(this.changeModel1);
     //this.changeName=rowData.changeName;


  }

  changeToDate1(e){
    this.headerForm.controls.toDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      this.getAllChangeLogs(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    //console.log(e,'  todate');
  }

  changeFromDate1(e){

    this.headerForm.controls.fromDate.setValue(e);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===true){
      //console.log(this.headerForm.controls.fromDate.value,'   ',this.headerForm.controls.toDate.value)
      this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
      this.getAllChangeLogs(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
  }


  loadDate(){
    let currentDateRange=currentMonthRange();
    //console.log(currentDateRange,'  current date range');
    this.headerForm.controls.fromDate.setValue(this.datePipe.transform(currentDateRange[0],"yyyy-MM-dd"))
    this.headerForm.controls.toDate.setValue(this.datePipe.transform(currentDateRange[1],"yyyy-MM-dd"))
    // this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    // this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
    // this.headerForm.controls.fromDate.setValue(this.datePipe.transform(currentDateRange[0],"yyyy-MM-dd"));
    // this.headerForm.controls.toDate.setValue(this.datePipe.transform(currentDateRange[1],"yyyy-MM-dd"));
  }

  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }


  onClientChange(data){
    // console.log("onClientChange(data) :",data);
    this.getChangeLogsByClientId(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value,data.key);
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    //this.maintainClientState(data,this.headerForm.controls.fromDate.value, this.headerForm.controls.toDate.value);
    // this.cleanDataConfigSelectorComponent.selectorForm.reset();
      }

  getChangeLogsByClientId(fromDate,toDate,clientId){
    this.changeLogs=[];
    //console.log("from date ",fromDate," ",toDate)
    let toDatePlus1 = moment(toDate).add(1, 'days');
    const changeLogData={
      "fromDate":fromDate,
      "toDate":toDatePlus1,
      "clientId":clientId
      // "clientId":this.headerForm.controls.client.value.key
    }
    this.userService.getAllChangeLogByClientId(changeLogData)
    .subscribe(
      data=>{
        this.changeLogs=data;
        this.changeLogs.forEach(item=>{
          var dateUTC = new Date(item.createdTime);
          var dateUTC1 = dateUTC.getTime() 
          var dateIST = new Date(dateUTC1);
          //date shifting for IST timezone (+5 hours and 30 minutes)
          dateIST.setHours(dateIST.getHours() + 5); 
          dateIST.setMinutes(dateIST.getMinutes() + 30);

          item.createdTime=dateIST})
        if(this.changeLogs.length===0){
          this.toast.showInfo("info","No data present");
        }
      },
      error=>{
        this.toast.showError(error.error.message,'error');
      }
    )

    
  }

  changeToDate(){
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    const clientIdC=this.headerForm.controls.client.value.key;
    if(validate===true){
      if(clientIdC!=="" && clientIdC!==null && clientIdC!==undefined){
      this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
    }

  }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
      
    }
    
  }

  changeFromDate(){
    console.log("date selected ",this.headerForm.controls.fromDate.value);
    let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    const clientIdC=this.headerForm.controls.client.value.key;
    if(validate===true){
      if(clientIdC!=="" && clientIdC!==null && clientIdC!==undefined){
      this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    }
  }
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')
    }
  }
    
  getPreviousState() {
    const fromDatec=this.cookieService.get('fromDate');
    const toDatec=this.cookieService.get('toDate');
    //console.log(clientIdC);
    //console.log(clientNameC);
    
    // if(clientIdC!==undefined && clientIdC!==null){
    //   this.headerForm.get("client").patchValue({
    //            key: clientIdC,
    //            value: clientNameC,
    //            });
      
                                   
    // }
    if(fromDatec!==undefined && fromDatec!==null && toDatec!==undefined && toDatec!==null && 
      !isEmpty(fromDatec) && !isEmpty(toDatec)){
        let frm=new Date(fromDatec);
        let toDt=new Date(toDatec);
      //  this.headerForm.controls.fromDate.setValue(frm)
      //  this.headerForm.controls.toDate.setValue(toDt)
       this.headerForm.controls.fromDate.setValue(this.datePipe.transform(frm,"yyyy-MM-dd"));
       this.headerForm.controls.toDate.setValue(this.datePipe.transform(toDt,"yyyy-MM-dd"));

    }else{
      this.loadDate();
    }

    // if(!isEmpty(this.headerForm.get("client").value.key)){
    // this.getChangeLogsByClientId(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value,this.headerForm.controls.client.value.key);
    // }
    
    // this.observalbleDataService.inventoryChanged$.subscribe(
    //   data => {
    //     this.dataTransferModel = data;
    //     this.fromDt=new Date(this.dataTransferModel.fromDate);
    //     this.toDt=new Date(this.dataTransferModel.toDate);
    //     if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && isNaN(this.fromDt)===false && isNaN(this.toDt)===false) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.headerForm.patchValue({
    //         fromDate: this.fromDt,
    //         toDate: this.toDt,
    //       });
    //       this.getChangeLogsByClientId(this.fromDt, this.toDt, this.dataTransferModel.clientId);
    //     }

    //     else if (this.dataTransferModel.clientId !== undefined && this.dataTransferModel.clientId !== 0 && (isNaN(this.fromDt)===true || isNaN(this.toDt)===true)) {
    //       this.headerForm.get("client").patchValue({
    //         key: this.dataTransferModel.clientId,
    //         value: this.dataTransferModel.clientName,
    //       });
    //       this.loadDate();
    //       this.getChangeLogsByClientId(this.headerForm.controls.fromDate.value, this.headerForm.controls.toDate.value, this.dataTransferModel.clientId);
    //     }

    //     else{
    //       this.loadDate();
    //     }
    //   });
  }

  maintainClientState(client, fromDate, toDate){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: toDate,
      fromDate: fromDate,
      clientName: client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  getAllClientData(){
    this.headerForm.controls.client.setValue(null);
    this.getAllChangeLogs(this.headerForm.value.fromDate,this.headerForm.value.toDate);
  }

  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      //this.getPreviousState();
      this.setTitle();
      //this.loadDate();
      this.getClients();  
    }

    onLoad(flag){
      if(flag==="onLoad"){
        this.panelQueueFilter.toggleSlider();
       }
      let clientId=this.headerForm.controls.client.value.key;
        console.log("on hit..........",clientId)
      if(clientId==null || clientId==undefined || clientId==""){
            this.changeLogs=[];
            this.companyName="";
            this.toast.showInfo("Info","Please Select Company")
            return;
        }

      let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
      if(validate===true){
        if(this.headerForm.controls.client.value.key!=="" && this.headerForm.controls.client.value.key!==null){
        this.companyName=this.headerForm.controls.client.value.value;
        this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
        this.getChangeLogsByClientId(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value,this.headerForm.controls.client.value.key);
      }
        }
      else{
      this.toast.showInfo("Info","Please Select Correct Date Range") 
       }
    }


    getClients() {

      //  this.loader.showLoader();
  
        this.clientService.getAllClients().subscribe(
  
          (data) => {
  
           // this.loader.hideLoader();
  
            this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
  
            console.log(this.clientsList)
  
          },
  
          (err) => {
  
       //     this.loader.hideLoader();
  
            this.toast.showError(err.error.message, "error");
  
          }
  
        );
  
  }
}
