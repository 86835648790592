import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { DataTransferModel } from 'src/app/dashboard/models/dataTransfer.model';
import { FieldLayoutModel, FileLayoutModel } from 'src/app/dashboard/models/file-layout.model';
import { ClientService } from 'src/app/dashboard/services/client.service';
import { ReportDashboardService } from 'src/app/dashboard/services/report-dashboard.service';
import { FileService } from 'src/app/files/services/file.service';
import { SspApplicationService } from 'src/app/selfServicePortal/services/ssp-application.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-rules-parameter',
  templateUrl: './rules-parameter.component.html',
  styleUrls: ['./rules-parameter.component.scss'],
})
export class RulesParameterComponent {
  @Output() fetch=new EventEmitter();
  filesList:{ key: any; value: any }[] = [];
  headerForm:FormGroup=null;
  clientsList:{ key: any; value: any }[] = [];
  @Output() closePanel=new EventEmitter();
  activeRoute$: Subscription;
  companyId:any;
  companyName:String;
  clientApplicationFileId:any;
  fileName:String;
  adminFlag:String;
  @Input() showBackButton:boolean;
  @Input() genericrulesFiles:boolean;
  constructor(private fileService:FileService,private toast:ToastService, private clientService:ClientService, private activeRoute: ActivatedRoute, private cookieService: CookieService) {
      this.headerForm = new FormGroup({
        client: new FormControl(null, [Validators.required]),
        file: new FormControl(null, [Validators.required]),
      });

  }

  ngOnInit(): void {
  //  this.getAllFiles();
  // const clientIdC=this.cookieService.get('clientId');
  // const clientNameC=this.cookieService.get('clientName');
  // if(clientIdC!==undefined && clientIdC!==null){
  //   this.headerForm.get("client").patchValue({
  //            key: clientIdC,
  //            value: clientNameC,
  //            });}
  //   console.log("cookieClient  ",this.headerForm.value)
  this.adminFlag=this.cookieService.get("adminFlag");
    this.getClients();
    this.updateNavigate();

    if(!this.showBackButton){

    this.fileService.getClientByUserId().subscribe(
      (data) =>{
      
           for(var i=0;i<data.length;i++){
              console.log("data  ",data[i].clientId)
              this.companyId=data[i].clientId
              this.companyName=data[i].clientName
               }
           this.headerForm.get("client").setValue({
              key: this.companyId,
              value: this.companyName
              });
  
    
      // console.log("this.headerForm",this.headerForm.value)
       this.fileService.getFilesByClientId(this.headerForm.controls.client.value.key).subscribe(
            (data) => {

              if(this.genericrulesFiles){
              for(var a=0;a<data.length;a++){
                // console.log("type "+fileDataType[a].type);
                if(data[a].clientApplicationFileDataTypeId==15){
                 this.filesList.push({ key: data[a].clientApplicationFileId, value: data[a].fileName });
                }}
              }
              else{
      
                     this.filesList = reMapForAutoComplete(
                     data,
                    "clientApplicationFileId",
                    "fileName"
                               );
                     }
                   //  this.showType=true;
                  //   this.loader.hideLoader();
                     for(var i=0;i<this.filesList.length;i++){
                        console.log("this.fileList  ",this.filesList[i])
                       this.clientApplicationFileId=this.filesList[i].key
                       this.fileName=this.filesList[i].value
                       }
  
             this.headerForm.get("file").setValue({
                  key:this.clientApplicationFileId,
                  value:this.fileName
                  })
        
         console.log("this.headerForm.file.value  ",this.headerForm.value)
         this.getFilesData('onInit');
        },
        (err) => {
          //this.showType=false;
          this.toast.showError(err.error.message, "error");
          //this.loader.hideLoader()
        }
      );
      
      },
      (err)=>{
        this.toast.showError(err.error.message, "error");
      }
  
      );
  
   

    console.log("initheaderForm ",this.headerForm.value)
    }
  }

  


  updateNavigate(){

 this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
        if (par.has("clientId") && par.has("clientName")) {
          this.headerForm.get("client").setValue({
            key: par.get("clientId"),
            value: par.get("clientName")
          });
          this.getAllFiles();
        }
        if (par.has("fileName") && par.has("clientFile")) {
          const fileData = {
            key: par.get("clientFile"),
            value: par.get("fileName")
          };
          this.headerForm.get("file").setValue(fileData);
       }
       //this.getFilesData(); 
      },
      err => {}
    );

  }


  getAllFiles() {
  //  console.log("this.headerForm.value1  ",this.headerForm.value)
    if(this.headerForm.controls.client.value.key!=null || this.headerForm.controls.client.value.key!= undefined){
    this.fileService.getFilesByClientId(this.headerForm.controls.client.value.key).subscribe(
      (data) => {
        // for(var a=0;a<data.length;a++){
        //   // console.log("type "+fileDataType[a].type);
        //   if(data[a].clientApplicationFileDataTypeId==15){
        //    this.filesList.push({ key: data[a].clientApplicationFileId, value: data[a].fileName });
        //   }}

        this.filesList = reMapForAutoComplete(
          data,
          "clientApplicationFileId",
          "fileName"
        );
     //   this.loader.hideLoader();
      },
      (err) => {
        this.toast.showError(err.error.message, "error");
     // this.loader.hideLoader()
      }
    );
  }else{
    return this.toast.showError("Please select the company","error");
  }
  }


  getFilesData(flag) {
    console.log("this.headerForm.valid2 ",this.headerForm.value)
    if (this.headerForm.valid) {
     // this.loader.showLoader();
     if(this.headerForm.controls.file.value.key!=null || this.headerForm.controls.file.value.key!= undefined){
      this.fetch.emit(this.headerForm.value);
     }else{
      this.toast.showError("Please select any file","error");
     }
      }
      if(flag=='onLoad'){
        this.closePanel.emit();
      }

    }

  getClients() {
  //  this.loader.showLoader();
    this.clientService.getAllClients().subscribe(
      (data) => {
       // this.loader.hideLoader();
        this.clientsList = reMapForAutoComplete(data, "clientId", "clientName");
      },
      (err) => {
   //     this.loader.hideLoader();
        this.toast.showError(err.error.message, "error");
      }
    );
  }
}
