import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { ReportFormComponent } from '../report-form/report-form.component';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {
  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(ReportFormComponent, {static: false })
  reportFormComponent:ReportFormComponent;

  @Input() editAppDataValue;
  @Input() allGroupData;
  @Input() appListData;
  @Input() allGenericData;
  @Input() allStatusData;
  @Output() save=new EventEmitter();
  @Input() categoryList: {key: any; value: any }[]=[];
  @Input() notificationList: {key: any; value: any }[]=[];
  @Input() frequencyList: {key: any; value: any }[]=[];
  constructor() { }

  ngOnInit(): void {
  }

  createApplicationData(data){
this.save.emit(data);
  }

  newReport(){
this.panelForm.toggleSlider();
this.reportFormComponent.resetForm();
  }

}
