import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FileService } from 'src/app/files/services/file.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { WorkflowJobTableComponent } from 'src/app/workflows/components/workflow-job-table/workflow-job-table.component';
import { environment } from 'src/environments/environment';
import { GroupModel } from '../../models/report-group.model';
import { WorkflowResponseModel } from '../../models/workflow.model';
import { SspApplicationService } from '../../services/ssp-application.service';
import { WorkflowJobComponent } from '../workflow-job/workflow-job.component';
@Component({
  selector: 'app-display-report',
  templateUrl: './display-report.component.html',
  styleUrls: ['./display-report.component.scss']
})
export class DisplayReportComponent implements OnInit {
  activeRoute$: Subscription;
  env:string=null;
  state:string;
  breadCrumb:boolean=false;

  @ViewChild(WorkflowJobTableComponent, { read: false, static: false })
  workflowJobTableComponent: WorkflowJobTableComponent;

  @ViewChild(WorkflowJobComponent, { read: false, static: false })
  workflowJobComponent:WorkflowJobComponent;

  workflowResponseModel:WorkflowResponseModel[]=[];
  showWorkflowTable:boolean;
  dataTransformationModel:GroupModel;

  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  reportInfodata:any;
  showActions:boolean=false;
  reportId:number;
  element: HTMLElement; 
  activeToggle:boolean=false;
  asofdate:string=null;
  companyId: number=0; 
  workflowUpdatedUrl:string; 
  url:string; 
  constructor(private activeRoute: ActivatedRoute, private titleService: TitleService,private fileService:FileService,
    private toast:ToastService,private loader:LoaderService,private datePipe:DatePipe,private router: Router,private sspapplicationService: SspApplicationService) { 
    this.titleService.setTitle(localStorage.getItem("submenu"));

    this.state=localStorage.getItem("stateflag");
    this.companyId=Number(localStorage.getItem("companyId"));
  }

  ngOnInit(): void {
    this.showRunReport();
    // if(this.asofdate==null){
    //   this.asofdate=localStorage.getItem("asofdate");
    // }
  // this.setTitle();
  // this.setBreadCrumb();
  console.log("Report Id on init--------------",localStorage.getItem("menuAddedreportId"));
  console.log("pageView init--------------",localStorage.getItem("pageView"));
  console.log("runWorkflow init--------------",localStorage.getItem("runWorkflow"));
  console.log("working init--------------",localStorage.getItem("menu"));
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      par => {
        if (par.has("reportUrl")) {
          console.log("working--------------");
         
          this.url=par.get("reportUrl");
          console.log("reportUrl  ",this.url)
          //this.env = environment.reportUrl + this.url;
          console.log("env",this.env);

          this.asofdate=null;
        //  if(this.asofdate==null || this.asofdate==undefined){
            if(Number(localStorage.getItem("menuAddedreportId"))!=0 && Number(localStorage.getItem("menuAddedreportId"))!=undefined){
            
              this.fileService.getDateByReportId(Number(localStorage.getItem("menuAddedreportId")),this.companyId).subscribe(
              
                data=>{
                 this.asofdate=data.asOfDate;
                 this.showActions=data.showReportActions;
                 console.log("asOfDate---",this.asofdate);
                 console.log("this.showActions comes from reportWorkflow---",this.showActions);
                 if (this.url.includes("?")) {
                   if(this.asofdate!=null){
                  this.env = environment.reportUrl + this.url+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
                   }
                   else{
                    this.env = environment.reportUrl + this.url+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId;  
                   }
                }
                else{

                    if(localStorage.getItem("menuAddedreportId")=="146" || localStorage.getItem("menuAddedreportId")=="147" || localStorage.getItem("menuAddedreportId")=="148" || localStorage.getItem("menuAddedreportId")=="350" || localStorage.getItem("menuAddedreportId")=="351" || localStorage.getItem("menuAddedreportId")=="352"){
                      console.log("Inside the Block");
                      this.env = environment.reportUrl + this.url+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId;
                    }else{
                      if(this.asofdate!=null){
                        this.env = environment.reportUrl + this.url+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
                         }
                         else{
                          this.env = environment.reportUrl + this.url+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId;  
                         }
                    }
                 
                }
                console.log("this.env ",this.env);
                   },
                error => {
                      this.toast.showError(error.error.message, "error");
                          })
                  }
              else{
                    //this.asofdate=this.datePipe.transform(new Date(),"yyyy-MM-dd");
                    if (this.url.includes("?")) {
                      if(this.asofdate!=null){
                        this.env = environment.reportUrl + this.url+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
                         }
                         else{
                          this.env = environment.reportUrl + this.url+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId;  
                         }
                      
                    }
                    else{
                      
                      if(this.asofdate!=null){
                        this.env = environment.reportUrl + this.url+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27";
                         }
                         else{
                          this.env = environment.reportUrl + this.url+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId;  
                         }
                      
                    }
                    console.log("this.env1 ",this.env);
                    }
         // }
         
        }

        // if(localStorage.getItem("applicationId")=="9"){
        //   if(localStorage.getItem("menuAddedreportId")=="356" || localStorage.getItem("menuAddedreportId")=="358" || localStorage.getItem("menuAddedreportId")=="359" || localStorage.getItem("menuAddedreportId")=="149" || localStorage.getItem("menuAddedreportId")=="150" || localStorage.getItem("menuAddedreportId")=="151" || localStorage.getItem("menuAddedreportId")=="ignore"){
        //     localStorage.setItem("runWorkflow","false");
        //   }
          
        //   this.env = environment.reportUrl + this.url;

        // }
          if(localStorage.getItem("pageView").includes("true")){
            this.breadCrumb=true;
          }
        
          if(localStorage.getItem("pageView")==='false'){
            this.breadCrumb=false;
            // document.getElementById("sidebar").style.display="none";
            // this.showActions=false;
          }
     
          // if(localStorage.getItem("runWorkflow").includes("true")){
          //    this.showActions=true;
          // }
          // if(localStorage.getItem("runWorkflow").includes("false")){
          //   this.showActions=false;
          // }
          console.log("this.showActions------------",this.showActions)

          
          console.log("asOfdate-----------",this.asofdate);
          console.log("Report Id on init--------------",localStorage.getItem("menuAddedreportId"));
  console.log("pageView init--------------",localStorage.getItem("pageView"));
  console.log("runWorkflow init--------------",localStorage.getItem("runWorkflow"));
  console.log("working init--------------",localStorage.getItem("menu"));
      }
    );


   



  }


  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

setBreadCrumb(){
  document.getElementById("menu").innerHTML = localStorage.getItem("menu")
  document.getElementById("submenu").innerHTML = localStorage.getItem("submenu")
}


showRunReport(){

  this.showWorkflowTable=false;
      }



getreportMappedWorkflows(){
  this.reportId=Number(localStorage.getItem("menuAddedreportId"));
  console.log("appId  ",this.reportId)
        this.fileService.getWorkflowListByReportId(this.reportId).subscribe(
          data =>{
          // this.loader.hideLoader();
        console.log("reportmappedworkflows ",data)
        
        this.workflowResponseModel=data
  
        if(this.workflowResponseModel.length==0){
          console.log("toast messege",this.workflowResponseModel.length);
          this.workflowResponseModel=[];
          this.toast.showWarn('no Workflow is linked ','error');
          return;
        }
        
        this.workflowResponseModel.forEach(item=>{
        // console.log("item",item.isActive===1);
        
         item.isActiveDisplay=item.isActive===1?'Yes':'No'
         item.workflowTypeDisplay=item.workflowType===1?'System':'Custom'
         item.asOfDate=item.asOfDate!==null?this.datePipe.transform(item.asOfDate,'MM-dd-yyyy'):""
        
                                               })
                                        
        this.workflowJobTableComponent.executeWorkflow(this.workflowResponseModel[0]);
  
                                               
            },
          error => {
                this.toast.showError(error.error.message, "error");
                    }
        
          )
                  }
  
        executeworkflow(data){
          
          data.menu=document.getElementById("menu").innerHTML;
          data.submenu=document.getElementById("submenu").innerHTML;
          console.log("data on execute workflow:",data)
          this.workflowJobComponent.executeWorkflow(data);
                  }


        
    navigateToLogs(){
      this.getReportInfo("Logs");
    }

    getInfo(){
      this.activeToggle=true;
      this.getReportInfo("Info");
    }

    getReportInfo(action){

     const data={
      "appId":Number(localStorage.getItem("menuAddedreportId"))
      }

      console.log("data ---",data.appId)
      this.sspapplicationService.getReportinfoByReportId(data).subscribe(
        (data) => {
          this.reportInfodata = data;
          console.log('App Data Is:', this.reportInfodata);
          if(action==="Info"){
            this.panelForm.toggleSlider();
          }
          if(action==="Logs"){
            this.dataTransformationModel=this.reportInfodata;
            let page = '/workflow/logs';
               this.router.navigate([page],{
                queryParams: {...this.dataTransformationModel},
              } );
          }
        },
        (err) => {
          this.toast.showError(err.error.message, 'error');
        });

    }

    dateEmit(date){
      this.asofdate=date;
      this.workflowUpdatedUrl=environment.reportUrl+this.url;
      if(this.url!=null){
        if (this.url.includes("?")) {
          if(this.asofdate!=null){
          this.env = this.workflowUpdatedUrl+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27 or%20Date(%22asofdate%22)=null";
          }
          else{
            this.env = this.workflowUpdatedUrl+"&ZOHO_CRITERIA=%22client_id%22="+this.companyId;  
          }
        }
        else{
          if(this.asofdate!=null){
          this.env = this.workflowUpdatedUrl+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId+"%20and%20Date(%22asofdate%22)=%27"+this.asofdate+"%27 or%20Date(%22asofdate%22)=null";
          }
          else{
            this.env = this.workflowUpdatedUrl+"?ZOHO_CRITERIA=%22client_id%22="+this.companyId;  
          }
        }
        console.log("report url ",this.env);

        if(!this.env.includes("open-view/")){

        }
      }

      
       }






}
