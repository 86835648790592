import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { StressScenarioModel } from "../models/stressScenario.model";
import { StressScenarioValuesModel } from "../models/stressScenarioValues.model";

@Injectable({
    providedIn: 'root'
  })
export class StressScenarioValuesService {
  
  
  /**
   * @param  {HttpDepartment} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
  saveOrUpdate(data){
    return this.http.post<StressScenarioValuesModel[]>(environment.baseUrl + "/apis/saveUpdateStressScenarioValues",data);
} 


getAllStressScenarioValuesList(){
  return this.http.get<StressScenarioValuesModel[]>(environment.baseUrl + "/apis/getAllStressScenarioValues");
  // return this.http.get<DepartmentModel[]>(environment.baseUrl + "/apis/department/getDepartmentAll");
}

// deleteStressScenarioValuesById(stressScenarioId) {
//     return this.http.delete<StressScenarioValuesModel[]>(environment.baseUrl + "/apis/deleteStressScenarioId/"+stressScenarioId);
//   }




  }

