import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ClientModel } from "../models/client.model";
import { formatDate } from "src/app/utils/dates";

@Injectable()
export class ClientService {
  /**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http: HttpClient) {}
  getAllClients() {
    return this.http.get<ClientModel[]>(environment.baseUrl + "/apis/client/getAllByUser");
  }

  getClientsById(clientId) {
    return this.http.get<ClientModel[]>(environment.baseUrl + "/apis/client/getById/"+clientId);
  }
}
