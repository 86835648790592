import { Component, OnInit, Output, ViewChild,EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from 'src/app/core/services/title.service';
import { UserService } from 'src/app/user/services/user.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { RoleService } from 'src/app/dashboard/services/role.service';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { Subscription } from 'rxjs';
import { RoleMenuType } from 'src/app/dashboard/pages/role-menu-permission/role-menu';
import { ActionModelForRole } from 'src/app/dashboard/models/action.model';

@Component({
  selector: 'app-table-viewer-list',
  templateUrl: './table-viewer-list.component.html',
  styleUrls: ['./table-viewer-list.component.scss']
})
export class TableViewerListComponent implements OnInit {

  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  activeRoute$: Subscription;
  @Input() tableColumns:any;
  @Input() tableData:any;
  @Output() redirectToRolePage=new EventEmitter();
  
  constructor(private titleService:TitleService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private toast:ToastService,
    private rolesService: RoleService) {}

  ngOnInit(): void {
    this.setTitle();
   }



  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }


  }
