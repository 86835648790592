import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { DepartmentSelectorComponent } from './components/department-selector/department-selector.component';
import { DepartmentTableComponent } from './components/department-table/department-table.component';
import { DepartmentHeaderComponent } from './components/department-header/department-header.component';


@NgModule({
  declarations: [
    DepartmentSelectorComponent,
    DepartmentTableComponent,
    DepartmentHeaderComponent
    

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    DepartmentSelectorComponent,
    DepartmentTableComponent,
    DepartmentHeaderComponent
    ]
})
export class DepartmentCompModule { }
