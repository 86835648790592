import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-menu-set-switch-item',
  templateUrl: './menu-set-switch-item.component.html',
  styleUrls: ['./menu-set-switch-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSetSwitchItemComponent {
  @Input() header!: string;
  @Input() data!: any;
  @Input() headerColor!: string;
  status = false;
  constructor(private cdr: ChangeDetectorRef) {}
  toggleAccordion() {
    this.status = !this.status;
    this.cdr.detectChanges();
  }
}
