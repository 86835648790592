import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-stressKeyMetrics-selector',
  templateUrl: './stressKeyMetrics-selector.component.html',
  styleUrls: ['./stressKeyMetrics-selector.component.scss']
})
export class StressKeyMetricsSelectorComponent implements OnInit {
  @Output() save=new EventEmitter();
  @Output() closePanel=new EventEmitter();
  stressKeyMetricsForm: FormGroup = null;
  @Input() editData:any[];
  constructor(private toast:ToastService,private datePipe: DatePipe) {

    this.stressKeyMetricsForm=new FormGroup({
      id:new FormControl(null),
      summary: new FormControl(null,{validators:[Validators.required],updateOn: "blur"}),
      statusMap: new FormControl(null,{validators:[Validators.required]}),
      baseline:new FormControl(null),    
      medium:new FormControl(null),    
      severe:new FormControl(null),    
    });
  
   }

  ngOnInit(): void {
    const defaultMap = {
      key: "Active",
      value: "Active",
    };
  this.stressKeyMetricsForm.get('statusMap').setValue(defaultMap);
  }

  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);
  
      this.stressKeyMetricsForm.patchValue(
        {
          id:rowData.id,
          summary:rowData.summary,
          baseline:rowData.baseline,
          medium:rowData.medium,
          severe:rowData.severe,
                   
        });

        const map1 = {
          key: "Active",
          value: "Active",
        };
      
        const map2 = {
          key: "Inactive",
          value: "Inactive",
        };
      
      rowData.status==true? this.stressKeyMetricsForm.controls.statusMap.setValue(map1):this.stressKeyMetricsForm.controls.statusMap.setValue(map2);

        
    }
  
  }
  


  saveStressKeyMetrics(flag){
  console.log("Stress Key Metrics Form value data",this.stressKeyMetricsForm.value)
   if(flag=='onLoad'){
    if(this.stressKeyMetricsForm.valid){
      console.log("stressKeyMetricsFormValid",this.stressKeyMetricsForm.valid);
      let statusMap=this.stressKeyMetricsForm.controls.statusMap.value
      console.log("statusMap",statusMap) 
      if (statusMap=="Select Status *") {
        this.toast.showError("Please Fill Required Fields","error");
      }
      if(statusMap!=="Select Status *"){     
           this.save.emit(this.stressKeyMetricsForm.value);
             }
            }
               else{
                 this.toast.showError("Please Fill Required Fields","error");
             }
   
  this.closePanel.emit();
    }

    }

    formReset(){
      this.stressKeyMetricsForm.reset();
      const defaultMap = {
        key: "Active",
        value: "Active",
      };
     this.stressKeyMetricsForm.get('statusMap').setValue(defaultMap);
    }

    get status() {
      const status = [
        "Active",
        "Inactive",
      ];
     return status.map((t) => ({ key: t, value: t }));
    }
 
}
