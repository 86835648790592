import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgChanges } from 'src/app/types';
// import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
// @UntilDestroy()
@Component({
  selector: 'app-paginate-buttons',
  templateUrl: './paginate-buttons.component.html',
  styleUrls: ['./paginate-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginateButtonsComponent implements OnChanges, OnInit {
  @Input('count') pageCount: number = 0;
  @Input('display') displayButtons: number = 5;
  currentPage: number = 1;
  buttons: number[] = [];
  constructor(private activeRoute: ActivatedRoute, private router: Router) {}
  ngOnInit(): void {
    this.activeRoute.queryParamMap
      .pipe()
      .subscribe((par) => (this.currentPage = Number(par.get('page')) || 1));
  }
  ngOnChanges(changes: NgChanges<PaginateButtonsComponent>): void {
    if (changes && changes.pageCount && changes.pageCount.currentValue) {
      if (changes.pageCount.currentValue <= this.displayButtons) {
        this.buttons = this.fillArrayWithNumbers(
          changes.pageCount.currentValue
        );
      } else {
        this.buttons = this.fillArrayWithNumbers(this.displayButtons);
      }
    }
  }
  changePage(page: number) {
   console.log(page)
    this.router.navigate([], {
      queryParams: { page },
      relativeTo: this.activeRoute,
    });
   // windows.reload;
  }
  fillArrayWithNumbers(n: number) {
    var arr = Array.apply(null, Array(n));
    return arr.map((x, i) => i + 1);
  }
}
