import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { GlHeadSelectorComponent } from './components/glHead-selector/glHead-selector.component';
import { GlHeadTableComponent } from './components/glHead-table/glHead-table.component';
import { GlHeadHeaderComponent } from './components/glHead-header/glHead-header.component';


@NgModule({
  declarations: [
    GlHeadSelectorComponent,
    GlHeadTableComponent,
    GlHeadHeaderComponent
    

  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    GlHeadSelectorComponent,
    GlHeadTableComponent,
    GlHeadHeaderComponent
    ]
})
export class GlHeadCompModule { }
