import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SidePanelComponent } from 'src/app/ui/components/side-panel/side-panel.component';
import { ClientFileFormComponent } from '../client-file-form/client-file-form.component';

@Component({
  selector: 'app-client-file-header',
  templateUrl: './client-file-header.component.html',
  styleUrls: ['./client-file-header.component.scss']
})
export class ClientFileHeaderComponent implements OnInit {

  @ViewChild("panelForm", {static: false })
  panelForm!:SidePanelComponent;

  @ViewChild(ClientFileFormComponent, { read: false, static: false })
  clientFileFormComponent: ClientFileFormComponent;

  @Input()  editClientMasterValue;
  @Output() create=new EventEmitter();
  @Input()  fileDataTypeList;
  @Input()  fileTypeList;
  @Input()  rolePermission;
  @Input() frequencyList: {key: any; value: any }[]=[];

  constructor() { }

  ngOnInit(): void {
  }


  addMaster(data){

this.create.emit(data);
  }

  addClientFileMaster(){
this.resetClientMasterForm();
    this.panelForm.toggleSlider();
  }

  resetClientMasterForm(){
    this.clientFileFormComponent.clientMasterForm.reset();
  }

}
