import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isTuesday } from 'date-fns';
import { ToastService } from 'src/app/core/services/toast.service';
import { StressScenarioModel } from 'src/app/dashboard/models/stressScenario.model';
import { StressScenarioService } from 'src/app/dashboard/services/stressScenario.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';



@Component({
  selector: 'app-shockMaster-selector',
  templateUrl: './shockMaster-selector.component.html',
  styleUrls: ['./shockMaster-selector.component.scss']
})
export class ShockMasterSelectorComponent implements OnInit {

  @Output() save=new EventEmitter();
  //stressScenarioList:StressScenarioModel[];
  @Output() closePanel=new EventEmitter();
  shockForm: FormGroup = null;
  @Input() editData:any[];
  // @Input() stressScenarioList: any[];
  @Input() reportLists: any[];
  @Input() stressScenarioList;
  selectedStressScenarioList: { stressScenarioId: any; stressScenarioName: any }[] = [];

  shockNameError:String=null;
  shockName:String=null;
  shockCodeError:String=null;
  descriptionError:String=null;
  
  constructor(private toast:ToastService,private datePipe: DatePipe) {
    this.shockForm=new FormGroup({
      update: new FormControl(null),
      stressShocksId:new FormControl(null),
      statusMap: new FormControl(null,{validators:[Validators.required]}),
      shockName: new FormControl(null,{validators:[Validators.required,Validators.minLength(5),Validators.maxLength(250)]}),
      shockCode: new FormControl(null,{validators:[Validators.required,Validators.minLength(5),Validators.maxLength(20)]}),
      report:new FormControl(null),
      description:new FormControl(null,{validators:[Validators.maxLength(500)]}),
      validFrom:new FormControl(null,{validators:[Validators.required]}),
      validTo:new FormControl(null,{validators:[Validators.required]}),
      stressScenarioList: new FormControl(null)
      // stressScenario:new FormControl(null,{validators:[Validators.required]})
    
    });
    console.log("shockform",this.shockForm);
  
   }

  ngOnInit(): void {
   

    const defaultMap = {
      key: "Active",
      value: "Active",
    };
  this.shockForm.get('statusMap').setValue(defaultMap);


  }

  ngOnChanges(changes: SimpleChanges) {
    
    if (changes && changes.editData && changes.editData.currentValue) {
     let rowData=changes.editData.currentValue;
     console.log("rowData  ",rowData);  
     console.log("LOad update from rowData  ",rowData);  
     
     this.shockForm.get("update").setValue("true");
    //  this.shockForm.get('shockCode').disable();
     this.shockForm.get("report").setValue({
      key:rowData.stressShocks.reportId,
      value:rowData.stressShocks.reportId
     });
    
     let stressScenarioList = rowData.stressShocksScenarioList;
     console.log("stressScenarioList",stressScenarioList)
     this.selectedStressScenarioList = [];
     for (var i = 0; i < stressScenarioList.length; i++) {
       this.selectedStressScenarioList.push({ stressScenarioId: stressScenarioList[i].stressScenarioId, stressScenarioName: stressScenarioList[i].stressScenarioName });
       console.log("Selected Scenario List Are:", this.selectedStressScenarioList);
     }

      this.shockForm.patchValue(
        {
          shockCode:rowData.stressShocks.stressShocksCode,
          shockName:rowData.stressShocks.shockName,
          description:rowData.stressShocks.description,
          validFrom:this.datePipe.transform(rowData.stressShocks.validFrom,"yyyy-MM-dd"),
          validTo:this.datePipe.transform(rowData.stressShocks.validTo,"yyyy-MM-dd"),

          
        });
  // console.log("shockForm in selector",this.shockForm);

        const map1 = {
          key: "Active",
          value: "Active",
        };
      
        const map2 = {
          key: "Inactive",
          value: "Inactive",
        };
      
      rowData.stressShocks.status==true? this.shockForm.controls.statusMap.setValue(map1):this.shockForm.controls.statusMap.setValue(map2);
     
      if(rowData.status==0){
        this.shockForm.patchValue({

          stressShocksScenarioList: this.selectedStressScenarioList
          
        });
       }else{
        this.shockForm.patchValue({

          stressShocksScenarioList: this.selectedStressScenarioList
         
        });
      }
        
    }
  
  }
 



  saveShockMaster(){
  console.log("this.shockForm.value ",this.shockForm.value)
   if(this.shockForm.valid){ 
    let statusMap=this.shockForm.controls.statusMap.value
      console.log("statusMap",statusMap)
      if (statusMap=="Select Status *") {
        this.toast.showError("Please Fill Required Fields","error");
      }
      if(this.shockForm.value.report=="Select Reports *"){
        this.toast.showError("Please Fill Required Fields","error");
        this.closePanel.emit();
        return;
      }
      if(statusMap!=="Select Status *"){

        let validate=this.validationOnDate(this.shockForm.controls.validFrom.value,this.shockForm.controls.validTo.value);
 if(validate===true){
  this.shockForm.patchValue({
    stressScenarioList:this.selectedStressScenarioList
  });

 
//console.log("Final Shock Form:",this.shockForm.value);
  this.save.emit(this.shockForm.value);
 }
 if(validate===false){
   this.toast.showWarn('error','date range incorrect')
   
 }
 

      }  
}
     else{
       this.toast.showError("Please Fill Required Fields","error");
   }


  this.closePanel.emit();
    }

    formReset(){
      this.shockForm.reset();
      // this.shockForm.get('shockCode').enable();
      this.selectedStressScenarioList = [];
     
    }

    get status() {
      const status = [
        "Active",
        "Inactive",
      ];
     return status.map((t) => ({ key: t, value: t }));
    }

    changeEndDate(){
      // this.headerForm.controls.toDate.setValue(e);
       let validate=this.validationOnDate(this.shockForm.controls.validFrom.value,this.shockForm.controls.validTo.value);
       if(validate===true){
         
         this.save.emit(this.shockForm.value);
       }
       if(validate===false){
         this.toast.showWarn('error','date range incorrect')
         
       }
     }
     
     validationOnDate(validFrom,validTo){
       if(validFrom && validFrom){
       if(new Date(validFrom)>new Date(validTo)){
       
           return false;
         }
         return true;
         
       }else{
         return false;
       }
     }
   
     multiSelectReportChange(eventData) {
      this.selectedStressScenarioList = eventData.value;
      console.log("Value Updated Is:", this.selectedStressScenarioList);
    }

}
