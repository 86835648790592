import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UiModule } from '../ui/ui.module';
import { StressKeyMetricsSelectorComponent } from './components/stressKeyMetrics-selector/stressKeyMetrics-selector.component';
import { StressKeyMetricsTableComponent } from './components/stressKeyMetrics-table/stressKeyMetrics-table.component';
import { StressKeyMetricsHeaderComponent } from './components/stressKeyMetrics-header/stressKeyMetrics-header.component';

@NgModule({
  declarations: [
    StressKeyMetricsSelectorComponent,
    StressKeyMetricsTableComponent,
    StressKeyMetricsHeaderComponent
    
  ],
  imports: [SharedModule,
    CommonModule,
    UiModule
  ],
  exports: [
    StressKeyMetricsSelectorComponent,
    StressKeyMetricsTableComponent,
    StressKeyMetricsHeaderComponent
    ]
})
export class StressKeyMetricsCompModule { }
