import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import {SetupTableComponent } from './components/setup-table/setup-table.component';
import { TransformJobComponent } from './components/transform-job/transform-job.component';
import { OutputJobsComponent } from './components/output-jobs/output-jobs.component';
import { SetupSelectorComponent } from './components/setup-selector/setup-selector.component';
import { SetupCustomTableComponent } from './components/setup-custom-table/setup-custom-table.component';
import { SetupCustomSelectorComponent } from './components/setup-custom-selector/setup-custom-selector.component';
import { WorkflowTableComponent } from './components/workflow-table/workflow-table.component';
import { WorkflowJobTableComponent } from './components/workflow-job-table/workflow-job-table.component';
import { WorkflowJobSelectorComponent } from './components/workflow-job-selector/workflow-job-selector.component';
import { WorkflowLogsTableComponent } from './components/workflow-logs-table/workflow-logs-table.component';
import { WorkflowJobLogsTableComponent } from './components/workflow-job-logs-table/workflow-job-logs-table.component';
import { WorkflowJoblogsListComponent } from './components/workflow-joblogs-list/workflow-joblogs-list.component';
import { WorkflowIngestJobLogListComponent } from './components/workflow-ingest-job-log-list/workflow-ingest-job-log-list.component';
import { FormsyModule } from "../formsy/formsy.module";
import { SetupJobSelectorComponent } from './components/setup-job-selector/setup-job-selector.component';
import { SetupTableNewComponent } from './components/setup-table-new/setup-table-new.component';
import { QueueTableComponent } from './components/queue-table/queue-table.component';
import { JobListTableComponent } from './components/job-list-table/job-list-table.component';
import { JobListSelectorComponent } from './components/job-list-selector/job-list-selector.component';
import { MultiSelectModule } from "primeng";
import { WorkFlowParameterComponent } from "./components/workflow-parameter/workflow-parameter.component";
import { WorkflowHeaderComponent } from "./components/workflow-header/workflow-header.component";
import { UiModule } from "../ui/ui.module";



@NgModule({
    declarations: [
        SetupTableComponent,
        TransformJobComponent,
        OutputJobsComponent,
        SetupSelectorComponent,
        SetupCustomTableComponent,
        SetupCustomSelectorComponent,
        WorkflowTableComponent,
        WorkflowJobTableComponent,
        WorkflowJobSelectorComponent,
        WorkflowLogsTableComponent,
        WorkflowJobLogsTableComponent,
        WorkflowJoblogsListComponent,
        WorkflowIngestJobLogListComponent,
        SetupJobSelectorComponent,
        SetupTableNewComponent,
        QueueTableComponent,
        JobListTableComponent,
        JobListSelectorComponent,
        WorkFlowParameterComponent,
        WorkflowHeaderComponent
        
     
    
    ],
    imports: [SharedModule,FormsyModule,MultiSelectModule,UiModule],
    exports: [
        SetupTableComponent,
        TransformJobComponent,
        OutputJobsComponent,
        SetupSelectorComponent,
        SetupCustomTableComponent,
        SetupCustomSelectorComponent,
        WorkflowTableComponent,
        WorkflowJobTableComponent,
        WorkflowJobSelectorComponent,
        WorkflowLogsTableComponent,
        WorkflowJoblogsListComponent,
        WorkflowIngestJobLogListComponent,
        SetupJobSelectorComponent,
        SetupTableNewComponent,
        QueueTableComponent,
        JobListTableComponent,
        JobListSelectorComponent,
        WorkFlowParameterComponent,
        WorkflowHeaderComponent
    ],
    providers: []
  })
  export class WorkflowModule {}